export default function getTableColumns() {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      width: 200,
      render: (value: string | string[]) => {
        if (Array.isArray(value)) {
          return value.filter((item) => item !== null).join(', ')
        } else {
          return value
        }
      }
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      width: 200,
      render: (value: string | string[]) => {
        if (Array.isArray(value)) {
          return value.filter((item) => item !== null).join(', ')
        } else {
          return value
        }
      }
    }
  ]
}
