import { FC, useEffect, useState } from 'react'
import {
  Modal,
  Checkbox,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Select,
  message
} from 'antd'
import { Rule } from 'antd/lib/form'
import moment from 'moment'
import {
  getFxRateAmount,
  getLookupTransactionCurrency,
  overrideTransaction
} from '../services/TxnManagerAPI'
import {
  getBackendDataType,
  clean,
  parseDataIntoType
} from '../utils/txn/form_utils'
import { callGetApi } from '../services/GenericAPI'

import type OverrideModalProps from '../types/OverrideModalProps'
import type { AxiosError } from 'axios'

import TxnLineCalculationEnum from '../utils/enums/TxnLineCalculationEnum'
import TextArea from 'antd/lib/input/TextArea'
import filterOption from '../utils/filterOption'
import filterSort from '../utils/filterSort'

const { Option } = Select

const OverrideModal: FC<OverrideModalProps> = ({
  showModal,
  onCancel,
  transaction,
  getLinePropertyActionDetails,
  setShowOverride,
  getTransactionLinesList,
  pageSize,
  formFields,
  thresholdAmount,
  displayThresholdSupport,
  reportingCurrency,
  filters,
  currentPage
}): JSX.Element => {
  const [groupedChoices, setGroupedChoices] = useState<any>([])
  const [numberInputs, setNumberInputs] = useState<any>({})
  const [form] = Form.useForm()
  const [picklistValues, setPicklistValues] = useState<any>({}) // selected choices are stored here
  const [hiddenValues, setHiddenValues] = useState<any>({}) // for the hidden fields

  const [isThresholDocsRequired, setIsThresholDocsRequired] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    formFields &&
      formFields.forEach((element: any) => {
        let key = element.api_name
        if (element.field_type === 'date') {
          form.setFieldsValue({
            [key]:
              transaction[key] === null || transaction[key] === undefined
                ? undefined
                : moment(transaction[element.api_name])
          })
        } else {
          form.setFieldsValue({
            [key]: transaction[key]
          })
        }
      })
  }, [form, formFields, transaction])

  const onFinish = async (values: any): Promise<void> => {
    let formattedValues: any = {}

    // for regular fields...
    Object.keys(values).forEach((apiName: any) => {
      formattedValues[apiName] = parseDataIntoType(
        values[apiName],
        getBackendDataType(formFields, apiName)!
      )
    })

    let payload = {
      ...formattedValues,
      ...picklistValues,
      ...hiddenValues
    }

    // for g_calculation
    if (Object.keys(payload).includes('g_calculation')) {
      payload.g_calculation = parseInt(payload.g_calculation, 10)
    }

    // send api request...
    try {
      setIsLoading(true)
      await overrideTransaction(
        getLinePropertyActionDetails(
          parseInt(`${transaction.id}`, 10),
          'override'
        ).url,
        {
          id: transaction.id,
          g_vendor_id: transaction.g_vendor_id,
          ...clean(payload)
        }
      )
      getTransactionLinesList({
        page: currentPage,
        paginate_count: parseInt(`${pageSize}`, 10),
        ...filters
      })
      form.resetFields(['override_reason'])
      setShowOverride(false)
      message.success('Success.')
    } catch (error: any) {
      let dictApiName: any = {}
      let errorStringArray = `${
        Object.values(error.response.data.ERROR)[0]
      }`.split(' ')

      Object.values(formFields).map((e: any) => {
        return (dictApiName[e.api_name] = e.label)
      })

      let parsedErrorMessage = errorStringArray
        .map((word) =>
          Object.keys(dictApiName).includes(word) ? dictApiName[word] : word
        )
        .join(' ')

      message.error(parsedErrorMessage)
    } finally {
      setIsLoading(false)

      /**
       * ? BUGFIX: https://gappify.atlassian.net/browse/ENG-7459
       * ? Resets dropdown states used by the payload after successful form submit
       * ? This prevents copying the states of the previous successful dropdown selections
       */
      setPicklistValues({})
      setHiddenValues({})
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(JSON.stringify(errorInfo.errorFields[0].errors[0]))
  }

  const renderFormInput = (element: any) => {
    return element.field_type === 'checkbox' && !element.hidden ? (
      <Form.Item
        label={null}
        name={element.api_name}
        valuePropName='checked'
        initialValue={transaction[element.api_name]}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Checkbox
          data-testid={`override-modal-${element.api_name}-checkbox-field`}
          data-cy={`override-modal-${element.api_name}-checkbox-field`}
          defaultChecked={transaction[element.api_name]}
          disabled={element.read_only}
        >
          {element.label}
        </Checkbox>
      </Form.Item>
    ) : element.field_type === 'date' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        initialValue={
          transaction[element.api_name] === null ||
          transaction[element.api_name] === undefined
            ? undefined
            : moment(transaction[element.api_name])
        }
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        {transaction[element.api_name] === null ? (
          <DatePicker
            data-testid={`override-modal-${element.api_name}-date-picker-field`}
            data-cy={`override-modal-${element.api_name}-date-picker-field`}
            style={{ width: '100%' }}
            format={'MM/DD/YYYY'}
          />
        ) : (
          <DatePicker
            data-testid={`override-modal-${element.api_name}-date-picker-field`}
            data-cy={`override-modal-${element.api_name}-date-picker-field`}
            value={moment(transaction[element.api_name])}
            style={{ width: '100%' }}
            disabled={element.read_only}
          />
        )}
      </Form.Item>
    ) : element.field_type === 'long_text' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        initialValue={transaction[element.api_name]}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          defaultValue={transaction[element.api_name]}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'numeric_accounting' && !element.hidden ? (
      <Form.Item
        initialValue={transaction[element.api_name]}
        label={element.label}
        name={element.api_name}
        rules={accountingNumericRules(element)}
      >
        <InputNumber
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          value={
            Object.keys(numberInputs).includes(element.api_name)
              ? numberInputs[element.api_name]
              : transaction[element.api_name]
          }
          style={{ width: '100%' }}
          onChange={async (value: any) => {
            let copyNumberInputs: any = { ...numberInputs }
            copyNumberInputs[element.api_name] = value
            setNumberInputs(copyNumberInputs)
            if (element.api_name === 'g_accrual_amount' && value > 0) {
              await setFxRateAmount()
            }
          }}
          step='0.01'
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'numeric_standard' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        initialValue={transaction[element.api_name]}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <InputNumber
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          value={
            Object.keys(numberInputs).includes(element.api_name)
              ? numberInputs[element.api_name]
              : transaction[element.api_name]
          }
          style={{ width: '100%' }}
          onChange={(e: any) => {
            let copyNumberInputs: any = { ...numberInputs }
            copyNumberInputs[element.api_name] = e
            setNumberInputs(copyNumberInputs)
          }}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'picklist_single' && !element.hidden ? (
      <Form.Item
        initialValue={
          element.api_name === 'g_calculation'
            ? TxnLineCalculationEnum.getLabelFromId(
                Number(transaction[element.api_name])
              )
            : element.data_type === 'boolean'
            ? getBooleanDesc(transaction[element.api_name])
            : transaction[element.api_name]
        }
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        {element.api_name === 'g_reporting_currency' ? (
          <Select
            data-testid={`override-modal-${element.api_name}-picklist-field`}
            data-cy={`override-modal-${element.api_name}-picklist-field`}
            defaultValue={reportingCurrency!}
            disabled
          />
        ) : (
          <Select
            data-testid={`override-modal-${element.api_name}-picklist-field`}
            data-cy={`override-modal-${element.api_name}-picklist-field`}
            onClick={async () => {
              if (
                element.options_source.url &&
                !Object.keys(groupedChoices).includes(element.api_name)
              ) {
                /**
                 * ENG-8839 - Add additional params to exclude inactive options when fetching data source
                 * This is applicable when performing override on transaction (TSD)
                 * Ref: https://gappify.atlassian.net/browse/ENG-8839
                 *
                 * params: exclude_inactive=1
                 */
                await callGetApi(
                  element.options_source.url + '&exclude_inactive=1'
                ).then((res) => {
                  let copyGroupedChoices = { ...groupedChoices }
                  copyGroupedChoices[element.api_name] = res.data
                  setGroupedChoices(copyGroupedChoices)
                })
              }
            }}
            // defaultValue={transaction[element.api_name]}
            value={transaction[element.api_name]}
            style={{ width: '100%' }}
            showSearch
            placeholder='Search...'
            optionFilterProp='children'
            filterOption={filterOption}
            filterSort={filterSort}
            onChange={async (value, { hidden_value }: any) => {
              let copyPicklistValues = picklistValues
              copyPicklistValues[element.api_name] = value
              setPicklistValues(copyPicklistValues)

              let copyHiddenValues = hiddenValues
              copyHiddenValues[`${element.api_name}_id`] = hidden_value
              setHiddenValues(copyHiddenValues)

              if (element.api_name === 'g_name') {
                const payload = {
                  vendor: value
                }

                try {
                  const { data } = await getLookupTransactionCurrency(payload)
                  if (data) {
                    form.setFieldValue('g_transaction_currency', data)
                  }
                } catch (error) {
                  const { response } = error as AxiosError
                  console.log(response)
                }
                await setFxRateAmount()
              }

              if (element.api_name === 'g_subsidiary') {
                const payload = {
                  subsidiary: value
                }

                try {
                  const { data } = await getLookupTransactionCurrency(payload)
                  console.log(data)
                  if (data) {
                    form.setFieldValue('g_subsidiary_currency', data)
                  }
                } catch (error) {
                  const { response } = error as AxiosError
                  console.log(response)
                }
              }

              if (
                element.api_name === 'g_transaction_currency' ||
                element.api_name === 'g_reporting_currency'
              ) {
                await setFxRateAmount()
              }
            }}
            disabled={element.read_only}
          >
            {
              // check first for g_calculation
              element.api_name === 'g_calculation' ? (
                TxnLineCalculationEnum.getIdValueList().map((e: any) => {
                  return <Option key={e.id}>{e.value}</Option>
                })
              ) : element.api_name === 'g_close_po' ? (
                element.options_source.map(
                  (
                    item: { id: number; value: 'Yes' | 'No' },
                    index: number
                  ) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.value}
                      </Option>
                    )
                  }
                )
              ) : Array.isArray(element.options_source) ? (
                element.options_source.map((e: string) => {
                  return (
                    <Option key={e} value={e}>
                      {e}
                    </Option>
                  )
                })
              ) : Object.keys(groupedChoices).includes(element.api_name) ? (
                groupedChoices[element.api_name].map(
                  (option: any, index: any) => (
                    <Option
                      key={index}
                      value={option.value}
                      hidden_value={
                        option.g_source_system_id
                          ? option.g_source_system_id
                          : option.id
                      }
                    >
                      {option.value}
                    </Option>
                  )
                )
              ) : (
                <Option disabled>Search...</Option>
              )
            }
            <Option value={''} hidden_value={''}>
              {''}
            </Option>
          </Select>
        )}
      </Form.Item>
    ) : element.field_type === 'short_text' &&
      !element.hidden &&
      element.api_name !== 'g_threshold_support_document' ? (
      <Form.Item
        initialValue={transaction[element.api_name]}
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          // defaultValue={transaction[element.api_name]}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.api_name === 'g_threshold_support_document' &&
      !element.hidden ? (
      <Form.Item
        initialValue={transaction[element.api_name]}
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: !element.read_only
              ? element.required ||
                isThresholDocsRequired ||
                parseFloat(transaction['g_reporting_amount']) >=
                  parseFloat(thresholdAmount.toString())
              : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          // defaultValue={transaction[element.api_name]}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : !element.hidden ? (
      <Form.Item
        initialValue={transaction[element.api_name]}
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: !element.read_only ? element.required : false,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`override-modal-${element.api_name}-input-field`}
          data-cy={`override-modal-${element.api_name}-input-field`}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : null
  }

  const accountingNumericRules = (element: any) => {
    const rule: Rule = {
      required: !element.read_only ? element.required : false,
      message: `${element.label} is a required field.`
    }
    const rules: Rule[] = []

    if (element.api_name === 'g_accrual_amount') {
      const gAccrualAmountRule: Rule = {
        message: 'Please enter amount greater than or equal to 0',
        validator: (_: any, value: any) => {
          if (value < 0 || value === null) {
            return Promise.reject()
          } else {
            return Promise.resolve()
          }
        }
      }
      rules.push(gAccrualAmountRule)
    } else {
      rules.push(rule)
    }
    return rules
  }

  const getBooleanDesc = (value: string | number) => {
    if (value === '1' || value === 1) {
      return 'Yes'
    }
    if (value === '0' || value === 0) {
      return 'No'
    }
    return value
  }

  const setFxRateAmount = async (): Promise<void> => {
    const gTransactionCurrency = form.getFieldValue('g_transaction_currency')
    const gSubsidiaryCurrency = form.getFieldValue('g_subsidiary_currency')
    const gAccrualAmount = form.getFieldValue('g_accrual_amount')
    const gReportingCurrency = form.getFieldValue('g_reporting_currency')

    if (gTransactionCurrency === gSubsidiaryCurrency) {
      form.setFieldValue('g_subsidiary_amount', gAccrualAmount)
    } else {
      const payload = {
        fromCurrency: gTransactionCurrency,
        toCurrency: gSubsidiaryCurrency,
        accrualAmount: gAccrualAmount
      }

      try {
        const { data } = await getFxRateAmount(payload)
        if (data) {
          form.setFieldValue(
            'g_subsidiary_amount',
            data.toLocaleString('en-US', { maximumFractionDigits: 2 })
          )
        } else {
          form.setFieldValue('g_subsidiary_amount', gAccrualAmount)
        }
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }

    if (gTransactionCurrency === gReportingCurrency) {
      form.setFieldValue('g_reporting_amount', gAccrualAmount)
      if (
        displayThresholdSupport &&
        form.getFieldValue('g_reporting_amount') >= thresholdAmount
      ) {
        setIsThresholDocsRequired(true)
      } else {
        setIsThresholDocsRequired(false)
      }
    } else {
      const payload = {
        fromCurrency: gTransactionCurrency,
        toCurrency: gReportingCurrency,
        accrualAmount: gAccrualAmount
      }

      try {
        const { data } = await getFxRateAmount(payload)
        if (data) {
          form.setFieldValue(
            'g_reporting_amount',
            data.toLocaleString('en-US', { maximumFractionDigits: 2 })
          )
        } else {
          form.setFieldValue(
            'g_reporting_amount',
            gAccrualAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })
          )
        }
        if (
          displayThresholdSupport &&
          parseFloat(
            form.getFieldValue('g_reporting_amount').replace(',', '')
          ) >= parseFloat(thresholdAmount.toString())
        ) {
          setIsThresholDocsRequired(true)
        } else {
          setIsThresholDocsRequired(false)
        }
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
  }

  return (
    <Modal
      data-testid='override-modal'
      data-cy='override-modal'
      title='Override'
      open={showModal}
      onCancel={onCancel}
      onOk={form.submit}
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round',
        loading: isLoading,
        disabled: isLoading
      }}
    >
      <Form
        data-testid='override-modal-form'
        data-cy='override-modal-form'
        layout='vertical'
        name='override'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {formFields
          ? formFields.map((element: any) => {
              return renderFormInput(element)
            })
          : null}
        <Form.Item
          label='Override Reason'
          name='override_reason'
          rules={[
            {
              required: true,
              message: `Override Reason is a required field.`
            }
          ]}
        >
          <TextArea
            data-testid='add-more-modal-override-reason-textarea-field'
            data-cy='add-more-modal-override-reason-textarea-field'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default OverrideModal
