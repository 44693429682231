// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Type Imports
interface UserActivationProps {
  isTokenValid: boolean | undefined
  invalidTokenMessage: string | undefined
  errorMessages: string[]
  responseMessage: any
  isSSO: boolean
}

// =================================================================
// Define the initial state
const initialState: UserActivationProps = {
  isTokenValid: undefined,
  invalidTokenMessage: undefined,
  errorMessages: [],
  responseMessage: {},
  isSSO: false
}

// =================================================================
const UserActivationSlice = createSlice({
  name: 'userActivationForm',
  initialState,
  reducers: {
    setIsTokenValid: (state, action: PayloadAction<boolean | undefined>) => {
      state.isTokenValid = action.payload
    },
    setInvalidTokenMessage: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invalidTokenMessage = action.payload
    },
    setErrorMessages: (state, action: PayloadAction<string[]>) => {
      state.errorMessages = action.payload
    },
    setResponseMessage: (state, action: PayloadAction<any>) => {
      state.responseMessage = action.payload
    },
    setIsSSO: (state, action: PayloadAction<boolean>) => {
      state.isSSO = action.payload
    }
  }
})

export const {
  setIsTokenValid,
  setInvalidTokenMessage,
  setErrorMessages,
  setResponseMessage,
  setIsSSO
} = UserActivationSlice.actions

export default UserActivationSlice.reducer
