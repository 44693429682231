/** zustand store imports */
import useResetPasswordModalStore from '../../../components/ResetPasswordModal/manager/resetpassword-store'

// =================================================================
export default function LoginResetPasswordBtn() {
  /** zustand store */
  const { setIsResetPasswordModalOpen } = useResetPasswordModalStore()

  return (
    <div
      data-testid='open-resetpassword-modal'
      className='login-reset'
      onClick={() => {
        setIsResetPasswordModalOpen(true)
      }}
      style={{ cursor: 'pointer' }}
    >
      Reset your Password
    </div>
  )
}
