import styles from './../styles/settingsMenuLabel.module.css'

interface SettingsMenuLabelProps {
  label: string
}

export default function SettingsMenuLabel({ label }: SettingsMenuLabelProps) {
  return (
    <div className={styles.container}>
      <span>{label}</span>
      <span className={styles.badge}>Beta</span>
    </div>
  )
}
