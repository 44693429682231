import { Space, Typography } from 'antd'
import { FC } from 'react'
import styles from '../styles/collapsible.module.css'
import useSettingsStore from '../manager/settings-store'

const SettingsTitle: FC = (): JSX.Element => {
  const { collapsed } = useSettingsStore()
  return (
    <Space
      direction='horizontal'
      size={'large'}
      className={collapsed ? styles.hidden : styles.visible}
    >
      <Typography.Title level={2}>Settings</Typography.Title>
    </Space>
  )
}

export default SettingsTitle
