// ** AntD Imports
import { Form, Input, Table } from 'antd'

// ** Data Imports
import ExecuteActionsData from '../../mock/ExecuteActionsData'

// ** Third Party Imports
import ReactQuill from 'react-quill'

// ** Style Imports
import styles from '../../styles/email-vendor.module.css'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// ** Type/Enum Imports
import { SuggestedActions } from '../../constants/NonCompliant'
import { SuggestedActions as SuggestedActionsIncreasing } from '../../constants/IncreasingSpend'

type TProps = {
  selectedTab: string | null
}

// =================================================================
// React Quill custom toolbar options
const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // heading text sizes

  ['bold', 'italic', 'underline'], // toggled buttons

  [{ list: 'ordered' }, { list: 'bullet' }], // bullets, numbered

  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme

  [{ align: [] }] // left, center, right alignments
]

const modules = {
  toolbar: toolbarOptions
}

// =================================================================
const ExecuteActionsTable = ({ selectedTab }: TProps) => {
  // ** Data ** (Note: Dismiss: Policy Exemption and Dismiss: Other have the same columns)
  const {
    dismissPolicyExemptionColumns,
    violationStandardColumnsWithStyles,
    dismissColumns,
    alertStandardColumnsWithStyles
  } = ExecuteActionsData()

  // ** Zustand **
  const {
    nonCompliantFirstTabData,
    nonCompliantSecondTabData,
    nonCompliantThirdTabData,
    increasingSpendFirstTabData,
    increasingSpendSecondTabData,
    emailTemplate
  } = useAnalyticsStore()

  /* determines the values of the `columns`,`rows`, and `totalPages` 
  variables based on the value of the `selectedTab` variable. */
  let columns, dataSource
  switch (selectedTab) {
    case SuggestedActions.DismissPolicyExemption:
      columns = dismissPolicyExemptionColumns
      dataSource = nonCompliantFirstTabData
      break
    case SuggestedActions.DismissOther:
      columns = dismissPolicyExemptionColumns
      dataSource = nonCompliantSecondTabData
      break
    case SuggestedActions.ViolationStandard:
      columns = violationStandardColumnsWithStyles
      dataSource = nonCompliantThirdTabData
      break
    case SuggestedActionsIncreasing.Dismiss:
      columns = dismissColumns
      dataSource = increasingSpendFirstTabData
      break
    case SuggestedActionsIncreasing.AlertStandard:
      columns = alertStandardColumnsWithStyles
      dataSource = increasingSpendSecondTabData
      break
    default:
      break
  }

  // Keep track if 3rd tab is empty, make subject & body fields not required
  const isThirdTabEmpty = !!!nonCompliantThirdTabData.length

  // Keep track if 2nd tab is empty for Increasing Spend, make subject & body fields not required
  const isSecondTabEmpty = !!!increasingSpendSecondTabData.length

  // Custom WYSIWYG validator to check if body is empty excluding the tags & whitespaces
  const validateQuillEditor = (rule: any, value: any) => {
    // Validate values only if final tabs are not empty
    if (!(isThirdTabEmpty && isSecondTabEmpty)) {
      const trimmedValue = value.replace(/\s/g, '')
      if (
        !trimmedValue ||
        trimmedValue === '<p><br></p>' ||
        trimmedValue === '<p></p>'
      ) {
        return Promise.reject()
      }
    }

    // Validate true by default
    return Promise.resolve()
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 'max-content' }}
        bordered
      />
      {(selectedTab === SuggestedActions.ViolationStandard ||
        selectedTab === SuggestedActionsIncreasing.AlertStandard) && (
        <>
          <Form.Item
            name='subject'
            rules={[
              {
                required: !(isThirdTabEmpty && isSecondTabEmpty),
                message: 'Subject is required'
              }
            ]}
            style={{ margin: 0 }}
            initialValue={emailTemplate?.subject || ''}
          >
            <Input
              className={styles.inputExecuteActions}
              placeholder='Email Subject Line'
              disabled={isThirdTabEmpty && isSecondTabEmpty}
            />
          </Form.Item>
          <Form.Item
            name='body'
            rules={[
              {
                required: !(isThirdTabEmpty && isSecondTabEmpty),
                validator: validateQuillEditor,
                message: 'Body is required'
              }
            ]}
            initialValue={emailTemplate?.body || ''}
          >
            <ReactQuill
              className={styles.textEditorExecuteActions}
              theme='snow'
              modules={modules}
              style={{ height: '200px' }}
              readOnly={isThirdTabEmpty && isSecondTabEmpty}
            />
          </Form.Item>
        </>
      )}
    </>
  )
}

export default ExecuteActionsTable
