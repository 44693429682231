export default function LoginAzureIcon() {
  return (
    <img
      src='./assets/images/azure-logo.svg'
      className='img img-responsive'
      width='25'
      height='auto'
      alt='azure-logo'
    />
  )
}
