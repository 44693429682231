// ** React Imports
import { useEffect } from 'react'

// ** AntD Imports
import { Table, Form, FormInstance } from 'antd'

// ** Data Imports
import StandardData from '../../../mock/StandardData'

// ** Zustand Imports
import useScheduleStore from '../../../manager/schedule-store'

// ** React Query imports
import { QueryClient, useQueryClient } from '@tanstack/react-query'

// ** Type Imports
import DataType from '../../../types/StandardDataType'

// ** Hook Imports
import useScheduleManager from '../../../hooks/useScheduleManager'
import useTxnEnableFeatures from 'src/pages/Settings/pages/TxnEnableFeatures/hooks/useTxnEnableFeatures'

// =================================================================
const StandardTable = ({ form }: { form: FormInstance }) => {
  // ** Data **
  const { columns, rowsFromBackend, editableRowsFromBackend } = StandardData()

  // ** Store **
  const { setStandardData, editStandard } = useScheduleStore()

  // ** TanStack Query **
  const queryClient: QueryClient = useQueryClient()
  const content =
    (queryClient?.getQueryData(['schedule-manager']) as { content: DataType[] })
      ?.content || []
  const { getScheduleManagerStandard } = useScheduleManager()
  const { isLoading, isRefetching } = getScheduleManagerStandard
  const { getTxnEnableFeatures } = useTxnEnableFeatures()
  const {
    isLoading: txnEnableFeaturesIsLoading,
    isRefetching: txnEnableFeaturesIsRefetching
  } = getTxnEnableFeatures

  useEffect(() => {
    setStandardData(
      content.filter((item: DataType) => {
        return item.workType !== 'VendorTask'
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form form={form} component={false}>
      <Table
        style={{ paddingTop: 20, paddingBottom: 20 }}
        dataSource={editStandard ? editableRowsFromBackend : rowsFromBackend}
        columns={columns}
        rowKey='key'
        pagination={false}
        loading={
          isLoading ||
          isRefetching ||
          txnEnableFeaturesIsLoading ||
          txnEnableFeaturesIsRefetching
        }
      />
    </Form>
  )
}

export default StandardTable
