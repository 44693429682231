import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import getBrowserDetails from './utils/getBrowserDetails'
import getDateAndTime from './utils/getDateAndTime'

const AuthError: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const error: string | null = searchParams.get('error')
  const errorDescription: string | null = searchParams.get('error_description')

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/login'
    }, 5000) // 5 seconds
  }, [])

  return (
    <div
      className='main-error-page'
      data-testid='auth-error-panel'
      data-cy='auth-error-panel'
    >
      <img
        src='./assets/images/gappify-logo-black.svg'
        alt='gpfy-logo'
        style={{
          marginBottom: '50px'
        }}
        data-testid='auth-error-gappify-logo'
        data-cy='auth-error-gappify-logo'
      />

      <h1
        className='error-title'
        data-testid='auth-error-title'
        data-cy='auth-error-title'
      >
        Whoops! <br />
        We encountered an error :(
      </h1>
      {errorDescription && (
        <h2 data-testid='auth-error-message' data-cy='auth-error-message'>
          {errorDescription}
        </h2>
      )}
      <section
        data-testid='auth-error-details-section'
        data-cy='auth-error-details-section'
      >
        <span
          data-testid='auth-error-status-code'
          data-cy='auth-error-status-code'
        >
          Status code: 403
        </span>
        <br />
        {error && (
          <>
            <span
              data-testid='auth-error-description'
              data-cy='auth-error-description'
            >
              Description: {error}
            </span>
            <br />
          </>
        )}
        <span data-testid='auth-error-date' data-cy='auth-error-date'>
          Date: {getDateAndTime()}
        </span>
        <br />
        <span
          data-testid='auth-error-user-agent'
          data-cy='auth-error-user-agent'
        >
          User agent: {getBrowserDetails()}
        </span>
      </section>
    </div>
  )
}

export default AuthError
