// ** AntD Imports
import { Button } from 'antd'

// ** Custom Component Imports
import GButton from '../../../../../components/gappify/GButton'

// ** Style Imports
import styles from '../styles/analytics-settings-header.module.css'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../manager/analytics-settings-store'

interface IProps {
  handleReorderSave?: () => void
  handleReorderCancel?: () => void
  patchReorderRuleLoading?: boolean
}

// =================================================================
const AnalyticsSettingsHeader = ({
  handleReorderSave,
  handleReorderCancel,
  patchReorderRuleLoading
}: IProps) => {
  // ** Store **
  const { setOpenModal, showHeader, setShowHeader } =
    useAnalyticsSettingsStore()

  return (
    <div
      className={styles.wrapper}
      style={{ justifyContent: showHeader ? 'right' : 'center' }}
    >
      {showHeader ? (
        <>
          <GButton btnText='New Rule' onClick={() => setOpenModal(true)} />
          <GButton btnText='Reorder' onClick={() => setShowHeader(false)} />
        </>
      ) : (
        <>
          <Button
            shape='round'
            onClick={() => {
              handleReorderCancel && handleReorderCancel()
              setShowHeader(true)
            }}
            disabled={patchReorderRuleLoading}
          >
            Cancel
          </Button>
          <GButton
            btnText='Save'
            onClick={() => {
              handleReorderSave && handleReorderSave()
            }}
            disabled={patchReorderRuleLoading}
            style={{ width: 80 }}
          />
        </>
      )}
    </div>
  )
}

export default AnalyticsSettingsHeader
