// ** Third Party Imports
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";

// to have a delay in fetching data to see the loading component
function sleep(ms = 500) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

// =================================================================
const request = async function <T>(
    client: AxiosInstance,
    options: AxiosRequestConfig,
    processData?: (response: AxiosResponse) => T
) {
    // ** Success handler **
    const onSuccess = async function (response: AxiosResponse) {
        const { data } = response;

        if (processData) {
            return processData(response);
        }

        await sleep(); // for delaying the return of data

        return data;
    };

    // ** Error handler **
    const onError = function (error: AxiosError | Error) {
        if (axios.isAxiosError(error)) {
            // handle axios error (add lines...)
            console.log(error.response)
            return Promise.reject(error.response);
        }
        // handle general error (add lines...)
        console.log(error.message)
        return Promise.reject(error.message);
    };

    //adding success and error handler to client
    return client(options).then(onSuccess).catch(onError);
};

export default request;
