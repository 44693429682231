import React from 'react'
import ReviewCenterHelper from '../../helpers/review-center-helper'
import useTxnEnableFeatureStore from '../../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

const Columns: React.FC<any> = ({
  values,
  fields,
  subsidiary_id,
  department_id,
  glaccount_id,
  vendor_id,
  clickHandler
}) => {
  const { consolidatedTxnView } = useTxnEnableFeatureStore()
  const handleClickEvent = (vendor_name: any, key: any, type: any) => {
    // ReviewCenterEvent.handleClickEvent(subsidiary_id, department_id, glaccount_id, vendor_id, vendor_name, key, type)
    clickHandler({
      subsidiary_id,
      department_id,
      glaccount_id,
      vendor_id,
      vendor_name,
      key,
      type
    })
  }
  const clickableAmount = (values: any, key: any) => {
    let value = ReviewCenterHelper.getValue(values, key)

    switch (key) {
      case 'Bill':
      case 'CalculatedAccrual':
      case 'Journal':
      case 'Other':
      default:
        return value
    }
  }

  /**
   * ! BUGFIX
   * * Added condition for columns that are not clickable regardless if consolidated view or not
   * * Field keys are from the API - please verify with the BE developer for other keys to add/modify
   * * As per BE (Paolo) and QA (Carmi),
   * * MtdTotal column & all columns under Analysis (Other, Total, Budget, Variance) should not be clickable always
   * ? Reference: https://gappify.atlassian.net/browse/ENG-7488
   *
   * nonClickableColumns: string[] - columns that should be set as not clickable
   * isNotClickable: boolean - returns whether the column is clickable or not
   * getAmountClass: string - returns the amount class which sets a cell as hoverable w/ mouse pointer
   */
  const nonClickableColumns = [
    'MtdTotal',
    'AnalysisTotal',
    'Variance',

    // Need to revisit if below keys are wrong, no data to test theese columns
    'Other',
    'Budget'
  ]

  const isNotClickable = (key: string) => {
    return nonClickableColumns.includes(key)
  }

  const getAmountClass = (isConsolidatedView: boolean, key: string) => {
    return isConsolidatedView && !isNotClickable(key) ? ' amount ' : ' '
  }

  const td =
    fields &&
    Object.entries(fields).map(([type, columns]: any) =>
      columns.map((v: any, i: any) => {
        const maxIndex = columns.length - 1
        const borderRight = i === maxIndex ? 'border-r' : ''
        return (
          <td
            data-testid={`rc-cell-${
              subsidiary_id === 0 ? '' : `subsidiary${subsidiary_id}`
            }-${department_id === 0 ? '' : `department${department_id}`}-${
              glaccount_id === 0 ? '' : `glaccount${glaccount_id}`
            }-${vendor_id === 0 ? '' : `vendor${vendor_id}`}-index${i}`}
            data-cy={`rc-cell-${
              subsidiary_id === 0 ? '' : `subsidiary${subsidiary_id}`
            }-${department_id === 0 ? '' : `department${department_id}`}-${
              glaccount_id === 0 ? '' : `glaccount${glaccount_id}`
            }-${vendor_id === 0 ? '' : `vendor${vendor_id}`}-index${i}`}
            onClick={(e) =>
              // ** If consolidated, this should always be true **
              // ** Added condition to check for not clickable columns **
              ((consolidatedTxnView && !isNotClickable(v.key)) ||
                v.classes === 'amount') &&
              ReviewCenterHelper.getValue(values, v.key)
                ? handleClickEvent(values.name, v.key, type)
                : undefined
            }
            className={
              // ** If consolidated, always include 'amount' **
              // ** Added custom function that conditionally returns the amount class **
              v.classes +
              `${getAmountClass(consolidatedTxnView, v.key)} ${borderRight}`
            }
            key={i}
          >
            {clickableAmount(values, v.key)}
          </td>
        )
      })
    )

  return <>{td}</>
}

export default Columns
