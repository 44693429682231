import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Button,
  InputNumber,
  Popconfirm
} from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import GButton from '../../gappify/GButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import GIcon from '../../gappify/GIcon'
import {
  editFilter,
  deleteFilter,
  getFilterById,
  previewFilter
} from '../../../services/ReviewCenterAPI'
import { applyFilter } from '../../../utils/rc/rc_utils'
import filterSort from '../../../utils/filterSort'
import filterOption from '../../../utils/filterOption'
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

interface UpdateFilterModalProps {
  showModal: boolean
  onCancel: any
  setIsShowModal: (value: SetStateAction<boolean>) => void
  setCustomFilters?: (value: SetStateAction<any>) => any
  period?: any
  setIsPreviewMode: any
  data: any
  setPreviewTriggeredFrom: any
  settings: any
  loadingSettings?: any
  setDataPreviewed: any
  reloadAllFilters: any
  setActiveFilter: any
  filterDetails: any
  activeFilter: any
  view: string
  dashboardData: any
  updateFields: (arg1: any) => any
  setData: (arg1: any) => any
}

const UpdateFilterModal: FC<UpdateFilterModalProps> = ({
  showModal,
  onCancel,
  setIsShowModal,
  setCustomFilters,
  period,
  setIsPreviewMode,
  data,
  setPreviewTriggeredFrom,
  settings,
  loadingSettings,
  setDataPreviewed,
  reloadAllFilters,
  setActiveFilter,
  filterDetails,
  activeFilter,
  dashboardData,
  view,
  updateFields,
  setData
}: UpdateFilterModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [newFilters, setNewFilters] = useState<any[]>([])
  const [countNewFilters, setCountNewFilters] = useState(0)

  const RP = useRolesAndPermissions()

  const handleChangeFilter = (filter: any, newField: any) => {
    setNewFilters(
      newFilters.map((newFilter) => {
        if (filter.key === newFilter.key) {
          form.setFieldValue(`${filter.key}-value`, [])
          return { ...newFilter, field: newField, value: [] }
        } else return newFilter
      })
    )
  }

  const renderAppropriateInputField = (type: string, filter: any) => {
    switch (type) {
      case 'numeric_accounting':
        return (
          <InputNumber
            data-cy={`rc-update-filter-input-${filter.key}`}
            data-testid={`rc-update-filter-input-${filter.key}`}
            step={0.01}
            style={{ width: '90%' }}
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'numeric_standard':
        return (
          <InputNumber
            data-cy={`rc-update-filter-input-${filter.key}`}
            data-testid={`rc-update-filter-input-${filter.key}`}
            style={{ width: '90%' }}
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'picklist_single':
        return (
          <Select
            data-cy={`rc-update-filter-input-${filter.key}`}
            data-testid={`rc-update-filter-input-${filter.key}`}
            showSearch
            style={{ width: '90%' }}
            placeholder='Please select'
            defaultValue={''}
            optionLabelProp='label'
            optionFilterProp='label'
            options={
              Object.keys(settings).length > 0
                ? settings[filter.field]?.options
                    .map((e: any, i: number) => {
                      return {
                        key: i,
                        value: e.gsid,
                        label: e.value
                      }
                    })
                    .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
                : []
            }
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'short_text':
        return (
          <Input
            data-cy={`rc-update-filter-input-${filter.key}`}
            data-testid={`rc-update-filter-input-${filter.key}`}
            style={{ width: '90%' }}
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'picklist_multiple':
        return (
          <Select
            data-cy={`rc-update-filter-input-${filter.key}`}
            data-testid={`rc-update-filter-input-${filter.key}`}
            mode='multiple'
            style={{ width: '90%' }}
            placeholder='Please select'
            defaultValue={[]}
            optionLabelProp='label'
            optionFilterProp='label'
            options={
              settings &&
              Object.keys(settings).length > 0 &&
              settings[filter.field]?.options &&
              settings
                ? settings[filter.field]?.options
                    .map((e: any, i: number) => {
                      return {
                        key: i,
                        value: e.gsid,
                        label: e.value
                      }
                    })
                    .sort((a: any, b: any) => (a.label > b.label ? -1 : 1))
                : []
            }
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
            onInputKeyDown={async (e: any) => {
              if (
                [e.key, e.code].includes('Enter') &&
                !['', null, undefined].includes(e.target.value)
              ) {
                await form.setFieldValue(`${filter.key}-value`, [
                  ...form.getFieldValue(`${filter.key}-value`),
                  e.target.value
                ])
                await setNewFilters(
                  newFilters.map((f: any) => {
                    return filter.key === f.key
                      ? {
                          ...f,
                          value: form.getFieldValue(`${filter.key}-value`)
                        }
                      : f
                  })
                )
                e.target.value = ''
              }
            }}
          />
        )
    }
  }

  const renderFilterInputComponents = (filter: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {loadingSettings ? (
          <Form.Item
            initialValue={'Loading...'}
            name={'loading'}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Input style={{ width: '90%' }} disabled />
          </Form.Item>
        ) : (
          <Form.Item
            data-cy={`rc-update-filter-field-form-item${filter.key}`}
            data-testid={`rc-update-filter-field-form-item${filter.key}`}
            initialValue={filter.field}
            name={`${filter.key}-field`}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Select
              data-cy={`rc-update-filter-field-select${filter.key}`}
              data-testid={`rc-update-filter-field-select${filter.key}`}
              placeholder='Please select'
              onChange={(e) => handleChangeFilter(filter, e)}
              style={{ width: '90%' }}
              optionFilterProp='children'
              filterOption={filterOption}
              filterSort={filterSort}
            >
              {settings &&
                Object.keys(settings).map((key: any, i: number) => {
                  return (
                    <Select.Option
                      data-cy={`rc-update-filter-field-option${key}`}
                      data-testid={`rc-update-filter-field-option${key}`}
                      value={key}
                      key={i}
                      disabled={newFilters.map((f) => f.field).includes(key)}
                    >
                      {settings[key].label}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          data-cy={`rc-update-filter-field-value-form-item${filter.key}`}
          data-testid={`rc-update-filter-field-value-form-item${filter.key}`}
          initialValue={filter.value}
          name={`${filter.key}-value`}
          label='Value'
          rules={[{ required: true, message: 'Enter value.' }]}
          style={{ width: '90%' }}
        >
          {renderAppropriateInputField('picklist_multiple', filter)}
        </Form.Item>

        <GButton
          dataCy={`rc-update-filter-remove-field${filter.key}`}
          dataTestId={`rc-update-filter-remove-field${filter.key}`}
          type='text'
          className='g-btn-icon'
          onClick={() => {
            form.setFieldValue(`${filter.key}-value`, [])
            form.setFieldValue(`${filter.key}-field`, null)
            setNewFilters(newFilters.filter((f: any) => f.key !== filter.key))
          }}
          icon={<GIcon icon={faTrash} />}
        />
      </div>
    )
  }

  const onSubmit = async (e?: any, previewMode?: boolean) => {
    let payload: any = {}
    if (newFilters.length === 0) {
      message.error('You need to add at least one filter.')
    } else {
      if (!previewMode && e) {
        payload.name = e.name
      }
      if (previewMode) {
        setPreviewTriggeredFrom('update')
        payload.preview_mode = previewMode
        payload.view_name = view
        payload.period = period
      }
      payload.filter_fields = newFilters.map((filter) => {
        return { column: filter.field, value: filter.value }
      })

      try {
        const { data } = previewMode
          ? await previewFilter(payload)
          : await editFilter(parseInt(activeFilter, 10), payload)
        setIsShowModal(false)
        setIsPreviewMode(previewMode || false)

        if (previewMode) {
          updateFields(data)
          setDataPreviewed(data.data)
        } else {
          await reloadAllFilters()
          await setActiveFilter(data.id)
          await dashboardData()
          message.success('Filter saved.')
        }
      } catch (error) {
        message.error('An error occurred.')
      }
    }
  }

  // eslint-disable-next-line
  const onPreview = () => {
    if (newFilters.length === 0) {
      message.error('You need to add at least one filter.')
    } else {
      setPreviewTriggeredFrom('update')
      setIsPreviewMode(true)

      // create filter dictionary
      let filterDictionary: any = {}
      newFilters.forEach((filter: any) => {
        if (Object.keys(filterDictionary).includes(filter.field)) {
          filterDictionary[filter.field] = [
            ...filterDictionary[filter.field],
            ...filter.value
          ]
        } else {
          filterDictionary[filter.field] = [...filter.value]
        }
      })

      // generate filtered data
      applyFilter(filterDictionary, view, period, settings).then(
        (filteredData: any) => setDataPreviewed(filteredData)
      )
      setIsShowModal(false)
    }
  }

  // eslint-disable-next-line
  const onFinish = async (e: any) => {
    let payload: any = {}
    payload.name = e.name
    payload.filter_fields = newFilters.map((filter) => {
      return { column: filter.field, value: filter.value }
    })
    if (newFilters.length === 0)
      message.error('You need to add at least one filter.')
    else {
      try {
        await editFilter(parseInt(activeFilter, 10), payload)
        setIsShowModal(false)
        await reloadAllFilters()
        await setActiveFilter(activeFilter)
        await dashboardData()
        message.success('Filter saved.')
        setIsShowModal(false)
        setIsPreviewMode(false)
      } catch (error) {
        message.error('An error occurred.')
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    console.log('Failed:', errorInfo)
  }

  const handleDelete = async () => {
    try {
      await deleteFilter(activeFilter).then(() => {
        message.success('Filter deleted.')
      })
      await reloadAllFilters()
      setActiveFilter('all')
    } catch (err) {
      message.error('An error occurred.')
      console.log('Error:', err)
    } finally {
      setIsShowModal(false)
    }
  }

  const resetFields = (mode: string | number, clearTitle?: boolean) => {
    setCountNewFilters(0)
    setNewFilters([])
    if (mode === 'all') {
      Array.from(Array(countNewFilters), (_, index: number) => index).forEach(
        (index: number) => {
          form.setFieldValue(`${index}-field`, null)
          form.setFieldValue(`${index}-operator`, null)
          form.setFieldValue(`${index}-value`, null)
        }
      )
      setNewFilters([])
      setCountNewFilters(0)
    } else {
      form.setFieldValue(`${mode}-field`, null)
      form.setFieldValue(`${mode}-operator`, null)
      form.setFieldValue(`${mode}-value`, null)
    }

    if (clearTitle) {
      form.setFieldValue('name', null)
    }
  }
  useEffect(() => {
    form.resetFields()
    Array.apply(null, Array(countNewFilters)).forEach((_, i: any) => {
      form.setFieldValue(`${i}-value`, [])
      form.setFieldValue(`${i}-field`, null)
    })
    setNewFilters(
      filterDetails?.filter_fields.map((e: any, i: number) => {
        return {
          key: i,
          field: e.column,
          value: e.value
        }
      })
    )

    // for name
    form.setFieldValue('name', filterDetails?.name)

    // for form fields
    let filter_fields = filterDetails?.filter_fields
    filter_fields.forEach((e: any, i: any) => {
      form.setFieldValue(`${i}-field`, e.column)
      form.setFieldValue(`${i}-value`, e.value)
    })

    // for numbering of filter fields
    setCountNewFilters(filter_fields.length)
    // eslint-disable-next-line
  }, [activeFilter])

  const applyFilterToFields = async () => {
    resetFields('all', true)
    await getFilterById(activeFilter).then((res: any) => {
      setCountNewFilters(res.data.filter_fields.length)
      let extractedFilters = res.data.filter_fields.map(
        (filterField: any, index: number) => {
          return {
            field: filterField.column,
            value: filterField.value,
            key: index
          }
        }
      )

      setNewFilters(extractedFilters)

      form.setFieldValue('name', res.data.name)
      Array.from(
        Array(res.data.filter_fields.length),
        (_, index: number) => index
      ).forEach((index: number) => {
        form.setFieldValue(
          `${index}-field`,
          res.data.filter_fields[index].column
        )
        form.setFieldValue(
          `${index}-value`,
          res.data.filter_fields[index].value
        )
      })
    })
  }

  return (
    <Modal
      data-cy={`rc-update-filter-modal`}
      data-testid={`rc-update-filter-modal`}
      title='Update Filter'
      open={showModal}
      onCancel={async () => {
        onCancel()
        applyFilterToFields()
      }}
      onOk={form.submit}
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round'
      }}
      maskClosable={false}
      footer={[
        <Button
          data-cy={`rc-update-filter-modal-save-view-btn`}
          data-testid={`rc-update-filter-modal-save-view-btn`}
          shape='round'
          key='save-view'
          type='primary'
          onClick={form.submit}
          disabled={!RP.USER_HAS_REVIEW_CENTER_UPDATE_FILTER}
        >
          Update View
        </Button>,
        <Popconfirm
          data-cy={`rc-update-filter-modal-delete-popconfirm`}
          data-testid={`rc-update-filter-modal-delete-popconfirm`}
          title='Are you sure to delete this filter?'
          onConfirm={() => handleDelete()}
          okText='Yes'
          cancelText='No'
          disabled={!RP.USER_HAS_REVIEW_CENTER_DELETE_FILTER}
        >
          <Button
            data-cy={`rc-update-filter-modal-delete-btn`}
            data-testid={`rc-update-filter-modal-delete-btn`}
            shape='round'
            key='preview'
            type='default'
          >
            Delete View
          </Button>
        </Popconfirm>,
        <Button
          data-cy={`rc-update-filter-modal-preview-btn`}
          data-testid={`rc-update-filter-modal-preview-btn`}
          shape='round'
          key='preview'
          type='default'
          onClick={() => onSubmit(undefined, true)}
        >
          Preview
        </Button>,
        <Button
          data-cy={`rc-update-filter-modal-cancel-btn`}
          data-testid={`rc-update-filter-modal-cancel-btn`}
          shape='round'
          key='cancel'
          type='default'
          onClick={async () => {
            onCancel()
            applyFilterToFields()
          }}
        >
          Cancel
        </Button>
      ]}
      width={900}
    >
      <Form
        data-cy={`rc-update-filter-form`}
        data-testid={`rc-update-filter-form`}
        form={form}
        layout='vertical'
        name='create-new-filter'
        initialValues={{ remember: true }}
        autoComplete='off'
        onFinish={(e) => onSubmit(e)}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          data-cy={`rc-update-filter-name-form-item`}
          data-testid={`rc-update-filter-name-form-item`}
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Enter name of the filter.' }]}
        >
          <Input
            maxLength={30}
            data-cy={`rc-update-filter-name-form-input`}
            data-testid={`rc-update-filter-name-form-input`}
          />
        </Form.Item>

        {newFilters.map((filter: any) => {
          return renderFilterInputComponents(filter)
        })}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <GButton
            dataCy={`rc-update-filter-name-form-add-filter-btn`}
            dataTestId={`rc-update-filter-name-form-add-filter-btn`}
            btnText='Add Filter'
            onClick={() => {
              setNewFilters([
                ...newFilters,
                {
                  key: countNewFilters,
                  field: null,
                  value: []
                }
              ])
              setCountNewFilters(countNewFilters + 1)
            }}
          />
          {newFilters.length > 0 ? (
            <GButton
              dataCy={`rc-update-filter-name-form-remove-all-btn`}
              dataTestId={`rc-update-filter-name-form-remove-all-btn`}
              btnText='Remove All'
              onClick={() => {
                resetFields('all')
              }}
            />
          ) : null}
        </div>
      </Form>
    </Modal>
  )
}

export default UpdateFilterModal
