// ** Custom Component Imports
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useCompanySettings from '../../../../hooks/useCompanySettings'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'
import useAnalyticsStore from '../../../AccrualAnalytics/manager/analytics-store'
import AnalyticsSettingsModal from './components/Modals/AnalyticsSettingsModal'
import AnalyticsSettingsTable from './components/Table'

// =================================================================
const AnalyticsSettings = () => {
  const navigate = useNavigate()

  // ** Store **
  const { isAnalytics } = useAnalyticsStore()

  // ** Hooks **
  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const { isLoading: isCompanySettingsLoading } = useCompanySettings()
  const RP = useRolesAndPermissions()

  useEffect(() => {
    if (isFeatureFlagsLoading || isCompanySettingsLoading) return
    if (!isAnalytics || !RP.USER_HAS_GLOBAL_AA_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AnalyticsSettingsTable />
      <AnalyticsSettingsModal />
    </>
  )
}

export default AnalyticsSettings
