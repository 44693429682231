/** antd imports */
import { Typography } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** hooks imports */
import useCompanySettings from 'src/hooks/useCompanySettings'
import useTxnEnableFeatures from '../hooks/useTxnEnableFeatures'

/** zustand store imports */
import useTxnEnableFeatureStore from '../manager/useTxnEnableFeatureStore'

/** styles imports */
import styles from './../index_q3.module.css'

/** custom component imports */
import CheckboxRow from './CheckboxRow'

// =================================================================
export default function IntegrationsSection() {
  /** hooks */
  const { data: companySettings } = useCompanySettings()
  const { getTxnEnableFeatures } = useTxnEnableFeatures()
  const { data, isSuccess, isLoading, isRefetching } = getTxnEnableFeatures

  /** zustand states */
  const extractEnabled = useTxnEnableFeatureStore(
    (state) => state.extractEnabled
  )
  const setExtractEnabled = useTxnEnableFeatureStore(
    (state) => state.setExtractEnabled
  )

  /** side effects */
  useEffect(() => {
    if (!isLoading && !isRefetching && isSuccess) {
      setExtractEnabled(data?.extract_enabled!)
    }
  }, [
    data?.extract_enabled,
    isLoading,
    isRefetching,
    isSuccess,
    setExtractEnabled
  ])

  if (!companySettings.api_enabled) {
    return null
  }

  return (
    <div
      data-testId='integrations-extract-je-checkbox'
      data-cy='integrations-extract-je-checkbox'
    >
      <Typography.Title level={4} className={styles.section}>
        Integrations
      </Typography.Title>
      <div className={styles.content}>
        <div className={styles.group}>
          <p>
            <CheckboxRow
              disabled={!companySettings.api_enabled}
              label='JE Extract via API'
              defaultChecked={data?.extract_enabled}
              checked={extractEnabled}
              onChange={() => {
                setExtractEnabled(!extractEnabled)
              }}
              dataTestId='integrations-extract-je-checkbox'
              dataCy='integrations-extract-je-checkbox'
            />
          </p>
        </div>
      </div>
    </div>
  )
}
