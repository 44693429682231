import ActionTypes from '../constants/action-types'

interface Params {
  type: string
  payload: {
    visible: boolean
  }
}

const initialState = {
  visible: false
}

export default function forbiddenErrorReducer(
  state = initialState,
  params: Params
) {
  switch (params.type) {
    case ActionTypes.SET_FORBIDDEN_ERROR:
      return params.payload
    default:
      return state
  }
}
