/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** api services imports */
import useAuthService from '../services/useAuthService'

/** hooks imports */
import useApiService from './useApiService'

// =================================================================
export default function useLogout() {
  /** api service */
  const { authAPI } = useAuthService()

  /** hooks */
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['logout-user'],
    queryFn: () =>
      authAPI({
        options: {
          baseURL: msUrl.idms,
          endpoint: '/api/logout',
          method: 'GET'
        }
      }),
    enabled: false
  })
}
