import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'
import { setSelectedLineGroups as setGlobalSelectedLineGroups } from '../../context/actions/qjeAction'
import { useDispatch, useSelector } from 'react-redux'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

interface HeaderLineGroupCheckboxProps {
  // refs...
  selectedLineGroupsRef?: any
  selectableLineGroups?: any
  selectableStatus?: any
}

const HeaderLineGroupCheckbox: FC<HeaderLineGroupCheckboxProps> = ({
  selectableLineGroups,
  selectedLineGroupsRef,
  selectableStatus = null
}: HeaderLineGroupCheckboxProps): JSX.Element => {
  const dispatch = useDispatch()
  const reviewJeData = useSelector((state: any) => state.allQjes.qjeData)
  const selectedLineGroups = useSelector(
    (state: any) => state.allQjes.selectedLineGroups
  )

  const RP = useRolesAndPermissions()

  const handleOnCheck = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked
    if (!isChecked) {
      selectedLineGroupsRef.current = []
      dispatch(setGlobalSelectedLineGroups([]))
    } else {
      selectedLineGroupsRef.current = getSelectable()
        .map((data: { line_group: string[] }) => {
          return data.line_group
        })
        .filter(
          (
            value: any,
            index: any,
            self: { indexOf: (currentValue: any) => number }
          ) => {
            // only get unique values
            return self.indexOf(value) === index
          }
        )
      // selectedLineGroupsRef.current = selectedLineGroups
      dispatch(
        // setGlobalSelectedLineGroups(
        //   getSelectable()
        //     .map((data: { line_group: string[] }) => {
        //       return data.line_group
        //     })
        //     .filter(
        //       (
        //         value: any,
        //         index: any,
        //         self: { indexOf: (currentValue: any) => number }
        //       ) => {
        //         // only get unique values
        //         return self.indexOf(value) === index
        //       }
        //     )
        // )
        setGlobalSelectedLineGroups(selectableLineGroups)
      )
    }
  }

  const getSelectable = (): any[] => {
    if (selectableStatus)
      return reviewJeData.filter((e: any) =>
        selectableStatus.includes(e.g_status)
      )
    else return reviewJeData
  }
  const isAllCheckboxSelected =
    selectedLineGroups.length === 0
      ? false
      : selectedLineGroups.length === selectableLineGroups.length

  return (
    <Checkbox
      className='qje-column'
      data-testid='qje-review-je-select-all'
      data-cy='qje-review-je-select-all'
      disabled={
        !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
        !RP.USER_HAS_REJECT_QJE_PERMISSION ||
        !RP.USER_HAS_REVIEWED_FLAG_QJE_PERMISSION
      }
      checked={
        // !getSelectable().some(
        //   (e: any) => !selectedLineGroups.includes(e.line_group)
        // ) &&
        // reviewJeData.length > 0 &&
        // selectedLineGroups.length > 0
        // selectedLineGroups.length === selectableLineGroups.length
        isAllCheckboxSelected
      }
      onChange={handleOnCheck}
    />
  )
}

export default HeaderLineGroupCheckbox
