import { RouteObject } from 'react-router-dom'
import Base from '../layouts/Base'
import AddLineScreenManager from '../pages/AddLineScreenManager'
import AuthError from '../pages/AuthError'
import ComponentsOverview from '../pages/ComponentsOverview'
import Error from '../pages/Error'
import Login from '../pages/Login/login'
// import Login from '../pages/Login'
import LoginOkta from '../pages/LoginOkta'
import LogoutOkta from '../pages/LogoutOkta'
import MultiFactorAuth from '../pages/MultiFactorAuth'
import SuperGridPage from '../pages/SuperGridPage'
import TransactionColumnManager from '../pages/TransactionColumnManager'
import { ProtectedRoute } from './ProtectedRoute'
import privateRoutes from './privateRoutes'
import UserActivationForm from '../pages/UserActivationForm'
import HardResetPasswordForm from '../pages/HardResetPasswordForm'
import LoginToolsSSO from 'src/pages/Login/login-tools-sso'

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/login-tools-sso',
    element: <LoginToolsSSO />
  },
  {
    path: '/multi-factor-auth',
    element: <MultiFactorAuth />
  },
  {
    path: '/components-overview',
    element: <ComponentsOverview />
  },
  {
    path: '/super-grid',
    element: <SuperGridPage />
  },
  {
    path: '',
    element: (
      <ProtectedRoute>
        <Base />
      </ProtectedRoute>
    ),
    children: privateRoutes
  },
  {
    path: '/error',
    element: <Error />
  },
  {
    path: '*',
    element: <Error />
  },
  {
    path: '/transaction-column-manager',
    element: <TransactionColumnManager />
  },
  {
    path: '/add-line-screen-manager',
    element: <AddLineScreenManager />
  },
  {
    path: '/login/okta/callback',
    element: <LoginOkta />
  },
  {
    path: '/logout/okta',
    element: <LogoutOkta />
  },
  {
    path: '/auth/error',
    element: <AuthError />
  },
  {
    path: `/signup/invitation/:token`,
    element: <UserActivationForm />
  },
  {
    path: `/password/:token`,
    element: <HardResetPasswordForm />
  }
]

export default publicRoutes
