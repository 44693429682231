import { useState, SetStateAction } from 'react'
import { getRoles } from '../../../../../services/AuthApi'

export default function useGetRolesOptions() {
  const [roleOptions, setRoleOptions] = useState([])

  const getRoleOptions = async (
    setIsLoading: (value: SetStateAction<boolean>) => void
  ) => {
    try {
      let response = await getRoles()
      setIsLoading(true)

      if (response && response.data) {
        setRoleOptions(response.data.data)
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return { roleOptions, getRoleOptions }
}
