import { TableProps } from 'antd'
import moment from 'moment'
import TableTag from '../../../components/TableTag'
import TransactionLine from '../../../types/TransactionLine'
import CalculationTypeEnum from '../../../utils/enums/CalculationTypeEnum'
import VendorTaskTypeEnum from '../../../utils/enums/VendorTaskTypeEnum'
import OptionsRestriction from '../../../utils/OptionsRestriction'
import formatIfDecimal from '../../../utils/task-manager/formatIfDecimal'
import { getDefaultCyAttributes } from '../../Qje2/utils/qje-utils'

// ** Consts **

// Note: this currently returns READ ONLY columns (no inline editing yet, etc.)
const getColumns = ({
  columnHeaders,
  enableSorting,
  featureFlags,
  readOnly,
  externalList,
  internalList,
  userTimezone,
  userDateTimeFormat
}: Record<string, any>) => {
  let dynamicColumns: TableProps<TransactionLine>['columns'] =
    columnHeaders.map((item: Record<string, any>) => {
      // ** Default column props for all columns **
      let column: Record<string, any> = {
        title: item.label,
        dataIndex: item.api_name,
        key: item.api_name,
        width: 200,
        onCell: (record: any) => ({
          ...getDefaultCyAttributes({
            apiName: item.api_name,
            recordId: record?.id
          })
        })
      }

      // ** Apply custom renderings per api_name **
      // api names omitted because of default renderings:
      // email, internal_email, cc_email, internal_cc_email, subsidiary, subsidiary_currency, tx_name, calculated_accrual_form, external_accrual_form, internal_accrual_form, po_number, po_line_description, po_date
      if (
        [
          'task_inactive',
          'is_active',
          'g_generate_accruals',
          'reviewed',
          'active'
        ].includes(item.api_name)
      ) {
        column.render = (value: any, record: TransactionLine, index: number) =>
          `${value}` === '1' ? 'Yes' : 'No'
      } else if (item.api_name === 'name') {
        column.dataIndex = ['vendor_task_details', 'name']
        column.key = 'vendor_name'
        column.width = 400
      } else if (item.api_name === 'external_accrual') {
        column.render = (value: any, record: TransactionLine, index: number) =>
          OptionsRestriction.getValueFromKey(value, externalList)
      } else if (item.api_name === 'internal_accrual') {
        column.render = (value: any, record: TransactionLine, index: number) =>
          OptionsRestriction.getValueFromKey(value, internalList)
      } else if (item.api_name === 'calculation') {
        column.render = (value: any, record: TransactionLine, index: number) =>
          CalculationTypeEnum.getDscpFromKey(value)
      } else if (
        [
          'glaccount_debit',
          'glaccount_credit',
          'department_debit',
          'department_credit',
          'company_debit',
          'company_credit',
          'intercompany_debit',
          'intercompany_credit',
          'channel_debit',
          'channel_credit',
          'location_debit',
          'location_credit',
          'class_debit',
          'class_credit',
          'product_debit',
          'product_credit',
          'project_debit',
          'project_credit',
          'region_debit',
          'region_credit',
          'transaction_currency',
          'po_owner',
          'po_header_description',
          'po_header_amount',
          'po_line_id',
          'po_id',
          'po_line_status'
        ].includes(item.api_name)
      ) {
        column.dataIndex = ['vendor_task_details', item.api_name]
      } else if (
        ['service_start_date', 'service_end_date'].includes(item.api_name)
      ) {
        column.dataIndex = ['vendor_task_details', item.api_name]
        column.render = (value: any, record: TransactionLine, index: number) =>
          value && moment(value).format('YYYY-MM-DD')
      } else if (
        [
          'po_line_amount',
          'invoiced_amount',
          'po_line_amount_cancelled',
          'po_balance',
          'po_line_amount_usd',
          'po_balance_usd'
        ].includes(item.api_name)
      ) {
        column.align = 'right'
        column.dataIndex = ['vendor_task_details', item.api_name]
        column.render = (value: any, record: TransactionLine, index: number) =>
          formatIfDecimal(value)
      } else if (item.api_name === 'task_type') {
        column.render = (
          value: any,
          record: TransactionLine,
          index: number
        ) => (
          <TableTag
            color={VendorTaskTypeEnum.getColorFromKey(value)}
            background={VendorTaskTypeEnum.getBgColorFromKey(value)}
            name={value}
          />
        )
      } else if (['created_at', 'updated_at'].includes(item.api_name)) {
        column.render = (value: any, record: TransactionLine, index: number) =>
          moment(value).tz(userTimezone).format(userDateTimeFormat)
      } else if (item.api_name === 'g_prepaid_account') {
        column.dataIndex = ['vendor_task_details', 'prepaid_account']
        column.key = 'prepaid_account'
      } else {
        if (item.source === 'vendor_task_details') {
          column.dataIndex = ['vendor_task_details', item.api_name]
        }
      }

      return column
    })

  return dynamicColumns
}
export { getColumns }
