import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select
} from 'antd'
import { FC, useEffect, useState } from 'react'

import { callGetApi } from '../services/GenericAPI'
import {
  getFxRateAmount,
  getLookupTransactionCurrency,
  postTransaction
} from '../services/TxnManagerAPI'
import {
  clean,
  getBackendDataType,
  parseDataIntoType
} from '../utils/txn/form_utils'

import moment from 'moment'
import TxnLineCalculationEnum from '../utils/enums/TxnLineCalculationEnum'

import type { AxiosError } from 'axios'
import type AddMoreModalProps from '../types/AddMoreModalProps'
import type AppType from '../types/AppType'
import AccrualType from '../types/AccrualType'
import filterSort from '../utils/filterSort'
import filterOption from '../utils/filterOption'

// ** Zustand Store Imports
import useTxnEnableFeatureStore from '../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

const { Option } = Select

const AddMoreModal: FC<AddMoreModalProps> = ({
  showModal,
  onCancel,
  setIsAddMoreModalVisible,
  transactionId,
  getTransactionLinesList,
  pageSize,
  formFields,
  thresholdAmount,
  displayThresholdSupport,
  reportingCurrency,
  appType,
  appKey,
  accrualType,
  period,
  isTrd,
  lineType
}): JSX.Element => {
  const [form] = Form.useForm()
  const [groupedChoices, setGroupedChoices] = useState<any>({})
  const [numberInputs, setNumberInputs] = useState<any>({})
  const [picklistValues, setPicklistValues] = useState<any>({}) // selected choices are stored here
  const [hiddenValues, setHiddenValues] = useState<any>({}) // for the hidden fields
  const [isThresholDocsRequired, setIsThresholDocsRequired] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // ** Zustand Store
  const isPrepaidEntryFeatureFlagEnabled = useTxnEnableFeatureStore(
    (state) => state.negativeAccrualAmountToPrepaidEntry
  )

  const onFinish = async (values: any): Promise<void> => {
    let formattedValues: any = {}

    // for regular fields...
    Object.keys(values).forEach((apiName: any) => {
      formattedValues[apiName] = parseDataIntoType(
        values[apiName],
        getBackendDataType(formFields, apiName)!
      )
    })

    Object.keys(formattedValues).forEach((apiName: string) => {
      if (apiName === 'g_period') {
        formattedValues[apiName] = moment(formattedValues[apiName]).format(
          'YYYY-MM-DD'
        )
      }
    })

    let payload = {
      ...formattedValues,
      ...picklistValues,
      ...hiddenValues
    }

    // for g_po_line_amount
    if (Object.keys(payload).includes('g_po_line_amount')) {
      if (payload.g_po_line_amount === 0) {
        payload.g_po_line_amount = null
      }
    }

    // for g_calculation
    if (Object.keys(payload).includes('g_calculation')) {
      payload.g_calculation = parseInt(payload.g_calculation, 10)
    }

    // for g_accrual_source
    if (Object.keys(payload).includes('g_accrual_source')) {
      payload.g_accrual_source = appKey
    }

    // for g_accrual_type
    if (Object.keys(payload).includes('g_accrual_type')) {
      payload.g_accrual_type = accrualType
    }

    // send api request

    try {
      setIsLoading(true)
      await postTransaction(
        parseInt(`${transactionId}`, 10),
        clean(payload),
        isTrd
      )
      setGroupedChoices({})
      setNumberInputs({})
      setPicklistValues({})
      setHiddenValues({})
      setIsThresholDocsRequired(false)
      setIsAddMoreModalVisible(false)
      let params: { [name: string]: any } = {
        page: 1,
        paginate_count: parseInt(`${pageSize}`, 10)
      }

      if (period) {
        params.g_period = period
      }
      if (appType) {
        params.app_type = appType
      }

      getTransactionLinesList(params)
      message.success('Success.')
      form.resetFields()
    } catch (error: any) {
      let dictApiName: any = {}
      let errorStringArray = `${
        Object.values(error.response.data.ERROR)[0]
      }`.split(' ')

      Object.values(formFields).map((e: any) => {
        return (dictApiName[e.api_name] = e.label)
      })

      let parsedErrorMessage = errorStringArray
        .map((word) =>
          Object.keys(dictApiName).includes(word) ? dictApiName[word] : word
        )
        .join(' ')

      message.error(parsedErrorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
  }

  const renderFormInput = (element: any) => {
    return element.field_type === 'checkbox' && !element.hidden ? (
      <Form.Item
        label={null}
        name={element.api_name}
        valuePropName='checked'
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Checkbox
          data-testid={`add-more-modal-${element.api_name}-checkbox-field`}
          data-cy={`add-more-modal-${element.api_name}-checkbox-field`}
          disabled={element.read_only}
        >
          {element.label}
        </Checkbox>
      </Form.Item>
    ) : element.field_type === 'date' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
        initialValue={
          element.api_name === 'g_period' ? moment(period) : undefined
        }
      >
        <DatePicker
          data-testid={`add-more-modal-${element.api_name}-date-picker-field`}
          data-cy={`add-more-modal-${element.api_name}-date-picker-field`}
          style={{ width: '100%' }}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'long_text' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'numeric_accounting' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={accountingNumericRules(element)}
      >
        <InputNumber
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          min={element.api_name === 'g_percentage_of_completion' ? 0 : null}
          style={{ width: '100%' }}
          onChange={async (value: any) => {
            let copyNumberInputs: any = { ...numberInputs }
            copyNumberInputs[element.api_name] = value
            setNumberInputs(copyNumberInputs)
            if (element.api_name === 'g_accrual_amount') {
              await setFxRateAmount()
            }
            if (element.api_name === 'g_percentage_of_completion') {
              form.setFieldValue(
                'g_percentage_of_completion',
                Math.round(value * 100) / 100
              )
            }
          }}
          max={element.api_name === 'g_percentage_of_completion' ? 100 : null}
          step='0.01'
          value={
            Object.keys(numberInputs).includes(element.api_name)
              ? numberInputs[element.api_name]
              : null
          }
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'numeric_standard' && !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <InputNumber
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          style={{ width: '100%' }}
          onChange={(e: any) => {
            let copyNumberInputs: any = { ...numberInputs }
            copyNumberInputs[element.api_name] = e
            setNumberInputs(copyNumberInputs)
          }}
          value={
            Object.keys(numberInputs).includes(element.api_name)
              ? numberInputs[element.api_name]
              : null
          }
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.field_type === 'picklist_single' && !element.hidden ? (
      <Form.Item
        initialValue={
          element.api_name === 'g_reporting_currency'
            ? reportingCurrency!
            : null
        }
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        {element.api_name === 'g_reporting_currency' ? (
          <Select
            data-testid={`add-more-modal-${element.api_name}-picklist-field`}
            data-cy={`add-more-modal-${element.api_name}-picklist-field`}
            defaultValue={reportingCurrency!}
            disabled
          />
        ) : (
          <Select
            data-testid={`add-more-modal-${element.api_name}-picklist-field`}
            data-cy={`add-more-modal-${element.api_name}-picklist-field`}
            onClick={async () => {
              if (
                element.options_source.url &&
                !Object.keys(groupedChoices).includes(element.api_name)
              ) {
                /**
                 * ENG-8839 - Add additional params to exclude inactive options when fetching data source
                 * This is applicable when performing add new line on transaction (TSD/TRD/Conso)
                 * Ref: https://gappify.atlassian.net/browse/ENG-8839
                 *
                 * params: exclude_inactive=1
                 */
                await callGetApi(
                  element.options_source.url + '&exclude_inactive=1'
                ).then((res) => {
                  let copyGroupedChoices = { ...groupedChoices }
                  copyGroupedChoices[element.api_name] = res.data
                  setGroupedChoices(copyGroupedChoices)
                })
              }
            }}
            style={{ width: '100%' }}
            showSearch
            placeholder='Search'
            optionFilterProp='children'
            filterOption={filterOption}
            filterSort={filterSort}
            onChange={async (value, { hidden_value }: any) => {
              let copyPicklistValues = picklistValues
              copyPicklistValues[element.api_name] = value
              setPicklistValues(copyPicklistValues)

              let copyHiddenValues = hiddenValues
              copyHiddenValues[`${element.api_name}_id`] = hidden_value
              setHiddenValues(copyHiddenValues)

              if (element.api_name === 'g_name') {
                const payload = {
                  vendor: value
                }

                try {
                  const { data } = await getLookupTransactionCurrency(payload)
                  if (data) {
                    form.setFieldValue('g_transaction_currency', data)
                  }
                } catch (error) {
                  const { response } = error as AxiosError
                  console.log(response)
                }
                await setFxRateAmount()
              }

              if (element.api_name === 'g_subsidiary') {
                const payload = {
                  subsidiary: value
                }

                try {
                  const { data } = await getLookupTransactionCurrency(payload)
                  console.log(data)
                  if (data) {
                    form.setFieldValue('g_subsidiary_currency', data)
                  }
                } catch (error) {
                  const { response } = error as AxiosError
                  console.log(response)
                }
              }

              if (
                element.api_name === 'g_transaction_currency' ||
                element.api_name === 'g_reporting_currency' ||
                element.api_name === 'g_subsidiary'
              ) {
                await setFxRateAmount()
              }
            }}
            disabled={element.read_only}
          >
            {
              // check first for g_calculation
              element.api_name === 'g_calculation' ? (
                TxnLineCalculationEnum.getIdValueList().map((e: any) => {
                  return <Option key={e.id}>{e.value}</Option>
                })
              ) : element.api_name === 'g_close_po' ? (
                element.options_source.map(
                  (
                    item: { id: number; value: 'Yes' | 'No' },
                    index: number
                  ) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.value}
                      </Option>
                    )
                  }
                )
              ) : element.api_name === 'g_accrual_source' ? (
                element.options_source.map(
                  (item: { id: AppType; value: AppType }, index: number) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.value}
                      </Option>
                    )
                  }
                )
              ) : element.api_name === 'g_accrual_type' ? (
                element.options_source.map(
                  (
                    item: { id: AccrualType; value: AccrualType },
                    index: number
                  ) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.value}
                      </Option>
                    )
                  }
                )
              ) : Array.isArray(element.options_source) ? (
                element.options_source.map(
                  (e: { id: boolean; value: string }, index: number) => {
                    return (
                      <Option key={index} value={e.id}>
                        {e.value}
                      </Option>
                    )
                  }
                )
              ) : Object.keys(groupedChoices).includes(element.api_name) ? (
                groupedChoices[element.api_name].map(
                  (option: any, index: any) => (
                    <Option
                      key={index}
                      value={option.value}
                      hidden_value={
                        option.g_source_system_id
                          ? option.g_source_system_id
                          : option.id
                      }
                    >
                      {option.value}
                    </Option>
                  )
                )
              ) : (
                <Option disabled>Loading options...</Option>
              )
            }
            <Option value={''} hidden_value={''}>
              {''}
            </Option>
          </Select>
        )}
      </Form.Item>
    ) : element.field_type === 'short_text' &&
      !element.hidden &&
      element.api_name !== 'g_threshold_support_document' ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : element.api_name === 'g_threshold_support_document' &&
      !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required || isThresholDocsRequired,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : !element.hidden ? (
      <Form.Item
        label={element.label}
        name={element.api_name}
        rules={[
          {
            required: element.required,
            message: `${element.label} is a required field.`
          }
        ]}
      >
        <Input
          data-testid={`add-more-modal-${element.api_name}-input-field`}
          data-cy={`add-more-modal-${element.api_name}-input-field`}
          placeholder={element.label}
          disabled={element.read_only}
        />
      </Form.Item>
    ) : null
  }

  const accountingNumericRules = (element: any) => {
    let rules: any[] = [
      {
        required: !element.read_only ? element.required : false,

        message: `${element.label} is a required field.`
      }
    ]
    if (
      element.api_name === 'g_accrual_amount' &&
      !isPrepaidEntryFeatureFlagEnabled
    ) {
      rules.push({
        type: 'number',
        min: 0,
        message: 'Please enter amount greater than or equal to 0'
      })
    }

    return rules
  }

  // const isThresholdDocsRequired = (): boolean => {
  //   if (!displayThresholdSupport) {
  //     return false
  //   }

  //   const gReportingAmount = form.getFieldValue('g_reporting_amount')
  //     ? form.getFieldValue('g_reporting_amount')
  //     : 0

  //   const gAccrualAmount = form.getFieldValue('g_accrual_amount')
  //     ? form.getFieldValue('g_accrual_amount')
  //     : 0

  //   if (gReportingAmount >= thresholdAmount) {
  //     return true
  //   }

  //   if (gAccrualAmount >= thresholdAmount) {
  //     return true
  //   }

  //   return false
  // }

  const setFxRateAmount = async (): Promise<void> => {
    const gAccrualAmount =
      isPrepaidEntryFeatureFlagEnabled &&
      form.getFieldValue('g_accrual_amount') < 0
        ? Math.abs(form.getFieldValue('g_accrual_amount'))
        : form.getFieldValue('g_accrual_amount')

    const gTransactionCurrency = form.getFieldValue('g_transaction_currency')
    const gSubsidiaryCurrency = form.getFieldValue('g_subsidiary_currency')
    const gReportingCurrency = form.getFieldValue('g_reporting_currency')

    if (gTransactionCurrency === gSubsidiaryCurrency) {
      form.setFieldValue('g_subsidiary_amount', gAccrualAmount)
    } else {
      const payload = {
        fromCurrency: gTransactionCurrency,
        toCurrency: gSubsidiaryCurrency,
        accrualAmount: gAccrualAmount
      }

      try {
        const { data } = await getFxRateAmount(payload)
        if (data) {
          form.setFieldValue(
            'g_subsidiary_amount',
            data.toLocaleString('en-US', { maximumFractionDigits: 2 })
          )
        } else {
          form.setFieldValue('g_subsidiary_amount', gAccrualAmount)
        }
      } catch (error) {
        //if fxrate has error set reporting amount to gAccrual amount
        form.setFieldValue(
          'g_subsidiary_amount',
          gAccrualAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })
        )
        const { response } = error as AxiosError
        console.log(response)
      }
    }

    if (gTransactionCurrency === gReportingCurrency) {
      form.setFieldValue('g_reporting_amount', gAccrualAmount)
      if (
        displayThresholdSupport &&
        form.getFieldValue('g_reporting_amount') >= thresholdAmount
      ) {
        setIsThresholDocsRequired(true)
      } else {
        setIsThresholDocsRequired(false)
      }
    } else {
      const payload = {
        fromCurrency: gTransactionCurrency,
        toCurrency: gReportingCurrency,
        accrualAmount: gAccrualAmount
      }

      try {
        const { data } = await getFxRateAmount(payload)
        if (data) {
          form.setFieldValue(
            'g_reporting_amount',
            data.toLocaleString('en-US', { maximumFractionDigits: 2 })
          )
        } else {
          form.setFieldValue('g_reporting_amount', gAccrualAmount)
        }
        if (
          displayThresholdSupport &&
          parseFloat(
            form.getFieldValue('g_reporting_amount').replace(',', '')
          ) >= parseFloat(thresholdAmount.toString())
        ) {
          setIsThresholDocsRequired(true)
        } else {
          setIsThresholDocsRequired(false)
        }
      } catch (error) {
        //if fxrate has error set reporting amount to gAccrual amount
        form.setFieldValue(
          'g_reporting_amount',
          gAccrualAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })
        )
        const { response } = error as AxiosError
        console.log(response)
      }
    }
  }

  useEffect(() => {
    if (reportingCurrency) {
      form.setFieldValue('g_reporting_currency', reportingCurrency)
    }
  }, [form, reportingCurrency])

  // set g_accrual_source & g_accrual_type fields
  useEffect(() => {
    if (showModal) {
      form.setFieldValue('g_accrual_source', appKey)
      form.setFieldValue('g_accrual_type', accrualType)
    }

    return () => {
      form.setFieldValue('g_accrual_source', undefined)
      form.setFieldValue('g_accrual_type', undefined)
    }
  }, [accrualType, appKey, form, showModal])

  return (
    <Modal
      data-testid='add-more-modal'
      data-cy='add-more-modal'
      title='Add Vendor'
      open={showModal}
      onCancel={onCancel}
      onOk={form.submit}
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round',
        loading: isLoading,
        disabled: isLoading
      }}
      destroyOnClose
    >
      <Form
        data-testid='add-more-modal-form'
        data-cy='add-more-modal-form'
        form={form}
        layout='vertical'
        name='add-more'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {formFields
          ? formFields.map((element: any) => {
              return renderFormInput(element)
            })
          : null}
        {formFields &&
        formFields.find((item: any) => item.api_name === 'g_period') ? null : (
          <Form.Item
            hidden
            label='Period'
            name='g_period'
            initialValue={period}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

AddMoreModal.defaultProps = {
  isTrd: false
}

export default AddMoreModal
