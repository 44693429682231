import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import useApiService from 'src/hooks/useApiService'
import usePeriodManagerStore from '../manager/usePeriodManagerStore'
import usePeriodManagerService from '../services/PeriodManagerService'
import Period from '../types/Period'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

export default function usePeriodManager() {
  const { msUrl } = useApiService()
  const { PeriodManagerAPI } = usePeriodManagerService()
  const { period } = usePeriodManagerStore()
  const isFlagEnabled = useIsFlagEnabled()
  // ** TanStack Query **
  const queryClient = useQueryClient()

  const { mutate: putPeriodManager, isSuccess: isSuccessPut } = useMutation(
    PeriodManagerAPI,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['period-status'])

        message.success(response.message)
      },
      onError: ({ data }) => {}
    }
  )

  const getPeriodManager = useQuery<Period[]>({
    queryKey: ['period-manager'],
    queryFn: () =>
      PeriodManagerAPI({
        data: [],
        options: {
          baseURL: msUrl.settings,
          endpoint: '/api/periods',
          method: 'GET'
        },
        params: {
          year: period ? `${period}` : undefined
        }
      }),
    enabled: isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management)
  })

  const handleClickAction = (period: Period) => {
    putPeriodManager({
      data: period,
      options: {
        baseURL: msUrl.settings,
        endpoint: '/api/periods/update', // TODO :  will add endpoint name here
        method: 'PUT',
        customHeaders: {
          'User-Token': `${localStorage?.getItem('authToken')}`
        }
      }
    })
  }

  return { getPeriodManager, handleClickAction, isSuccessPut }
}
