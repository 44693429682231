import * as FE_URL from './../helpers/constants/fe-urls'

const withOverlap = (firstString: string, secondString: string) => {
  return (
    firstString === secondString ||
    firstString.includes(secondString) ||
    secondString.includes(firstString)
  )
}

export const getInstance = (pathname: string) => {
  if (withOverlap(pathname, FE_URL.DEV)) {
    return 'dev'
  }

  if (withOverlap(pathname, FE_URL.QA)) {
    return 'qa'
  }

  if (withOverlap(pathname, FE_URL.STAGING)) {
    return 'staging'
  }

  if (withOverlap(pathname, FE_URL.STAGING_MSB)) {
    return 'staging'
  }

  if (withOverlap(pathname, FE_URL.UAT)) {
    return 'uat'
  }

  if (withOverlap(pathname, FE_URL.PRE_PROD)) {
    return 'prod'
  }

  if (withOverlap(pathname, FE_URL.PROD_NEXT)) {
    return 'prod'
  }

  if (withOverlap(pathname, FE_URL.PROD)) {
    return 'prod'
  }

  if (withOverlap(pathname, FE_URL.DOCKER_BE)) {
    return 'dev'
  }

  return 'qa'
}
