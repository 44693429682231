// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'

// ** Type Imports
interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData | TData[]
  options: TOptions
}

interface TxnEnableParams {}

export interface TxnEnableData {}

interface TxnEnableOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function useTxnEnableFeaturesService() {
  const { apiRequest } = useApiService()

  const TxnEnableFeaturesAPI = (
    props: BaseProps<TxnEnableParams, TxnEnableData, TxnEnableOptions>
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    TxnEnableFeaturesAPI
  }
}
