/** Third Party Imports */
import axios, { AxiosResponse, ResponseType } from 'axios'

/** Helper Imports */
import { AUTH_TOKEN } from '../../../helpers/constants/Auth'

/** Library Imports */
import request from '../../../lib/request'

/** Util Imports */
import getMsUrl from '../../../utils/getMsUrl'

// =================================================================
export default function useApiService() {
  const hostname = window.location.hostname
  const msUrl = getMsUrl(hostname)

  const apiRequest = <Params = unknown, Data = unknown>(
    baseURL: string,
    url: string,
    method: string,
    customHeaders: Record<string, string> = {},
    params?: Params,
    data?: Data,
    withCredentials?: boolean,
    responseType?: ResponseType
  ) => {
    /** Token */
    const token = localStorage.getItem(AUTH_TOKEN)

    /** Header Hook */
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const headers = { ...defaultHeaders, ...customHeaders }

    const client = axios.create({
      baseURL,
      withCredentials,
      responseType
    })

    return request(
      client,
      {
        headers,
        url,
        method,
        params,
        data
      },
      (response: AxiosResponse) => {
        // Access the entire { response } object here
        const responseData = response.data
        const responseHeaders = response.headers
        return { responseData, responseHeaders }
      }
    )
  }

  return { apiRequest, msUrl }
}
