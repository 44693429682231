import { Collapse, Modal } from 'antd'
import { FC, MouseEventHandler } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'

interface SuccessCreateQjeModalProps {
  visible: boolean
  successQjeCount: number
  failedQjeCount: number
  onOk: MouseEventHandler<HTMLElement>
  createQjeErrors?: string[]
}

const SuccessCreateQjeModal: FC<SuccessCreateQjeModalProps> = ({
  visible,
  successQjeCount,
  failedQjeCount,
  onOk,
  createQjeErrors
}: SuccessCreateQjeModalProps): JSX.Element => {
  const qjeErrorMessage =
    'QJE Error due to data missing in the following fields: '

  const qjeInactiveMessage =
    'QJE Error due to inactive record in the following fields: '

  const qjeInvalidMessage =
    'QJE Error due to invalid record in the following fields: '
  interface ErrorObject {
    systemId?: string
    transactionId?: number
    message?: string
  }

  const getErrors = (): JSX.Element | null => {
    if (!createQjeErrors) return null

    /* Creates a new array called `errorsAsStrings` by
    mapping over the `createQjeErrors` array. */
    const errorsAsStrings = createQjeErrors
      .flat()
      .map((error: string | ErrorObject) =>
        typeof error === 'string' ? error : error.message
      )

    /* Creates a new array called
    `uniqueErrors` that contains only unique elements from the `errorsAsStrings` array. */
    const uniqueErrors = Array.from(new Set(errorsAsStrings))

    const inactiveErrors = uniqueErrors.filter((error) =>
      /inactive/i.test(error as string)
    )

    const invalidErrors = uniqueErrors.filter((error) =>
      /invalid/i.test(error as string)
    )

    const otherErrors = uniqueErrors.filter(
      (error) =>
        !inactiveErrors.includes(error) && !invalidErrors.includes(error)
    )

    return uniqueErrors.length > 0 ? (
      <Collapse bordered={false}>
        <Collapse.Panel
          header='Something went wrong. Expand to see the list'
          key={1}
        >
          {otherErrors.length > 0 && (
            <>
              <p>{qjeErrorMessage}</p>
              <ul>
                {otherErrors.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </>
          )}

          {inactiveErrors.length > 0 && (
            <>
              <p>{qjeInactiveMessage}</p>
              <ul>
                {/* {inactiveErrors.map((item, index) => (
                  <li key={index}>{item}</li>
                ))} */}
                {inactiveErrors
                  .map(
                    (item) =>
                      item && item.replace(/is inactive[ .]/i, '').trim()
                  )
                  .sort()
                  .map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
              </ul>
            </>
          )}

          {invalidErrors.length > 0 && (
            <>
              <p>{qjeInvalidMessage}</p>
              <ul>
                {/* {invalidErrors.map((item, index) => (
                  <li key={index}>{item}</li>
                ))} */}
                {invalidErrors
                  .map(
                    (item) => item && item.replace(/is invalid[ .]/i, '').trim()
                  )
                  .sort()
                  .map((item, index) => (
                    <li key={index} style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </Collapse.Panel>
      </Collapse>
    ) : null
  }

  return (
    <Modal
      data-testid='success-create-qje-modal'
      data-cy='success-create-qje-modal'
      open={visible}
      title={
        <h2>
          <CheckCircleOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} style={{ color: '#52c41a' }} /> QJE Processing
          Completed
        </h2>
      }
      centered
      closable={false}
      cancelButtonProps={{
        hidden: true
      }}
      onOk={onOk}
      style={{ inlineSize: 'max-content' }}
    >
      <>
        <p>
          Success: {successQjeCount}, Failed: {failedQjeCount}, Total:{' '}
          {successQjeCount + failedQjeCount}
        </p>
        {getErrors()}
      </>
    </Modal>
  )
}

export default SuccessCreateQjeModal
