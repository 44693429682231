/** redux imports */
import setError from '../context/actions/errorAction'
import setForbiddenError from '../context/actions/forbiddenErrorAction'
import store from '../context/store'

/** constants imports */
import * as Auth from '../helpers/constants/Auth'
import HttpStatus from '../helpers/constants/HttpStatus'

/** hooks imports */
import useGenerateGuid from './useGenerateGuid'

// =================================================================
export default function useHandleErrorResponse() {
  /** hooks */
  const { guid } = useGenerateGuid()

  return (error: any) => {
    const { status } = error?.request || {}

    let message: string = ''
    if (!error?.data?.message) {
      message = 'Something went wrong'
    } else {
      message = error?.data?.message
    }

    switch (true) {
      case status === HttpStatus.HTTP_UNAUTHORIZED:
        const { config } = error
        if (config.url === '/api/login') {
          return Promise.reject(error)
        }

        localStorage.clear()
        if (!(localStorage.getItem(Auth.SHOW_LOGOUT_MODAL) === 'true')) {
          window.location.href = '/login'
        }
        break
      case status === HttpStatus.HTTP_UNPROCESSABLE_ENTITY:
        return Promise.reject(error)
      case status === HttpStatus.HTTP_FORBIDDEN:
        store.dispatch(setForbiddenError({ visible: true }))
        return Promise.reject(error)
      default:
        store.dispatch(
          setError({
            visible: true,
            guid,
            status,
            message
          })
        )
        return Promise.reject(error)
    }
  }
}
