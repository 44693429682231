/** Hook Imports */
import useApiService from '../hooks/useApiService'

/** Type Imports */
interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface ServiceParams {}

interface ServiceData {}

interface ServiceOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function useGlobalService() {
  const { apiRequest } = useApiService()

  const globalServiceApi = (
    props: BaseProps<ServiceParams, ServiceData, ServiceOptions>
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return { globalServiceApi }
}
