import { useEffect, useState } from 'react'

// ** AntD Imports
import { Button, Form, Input, Modal, Select, Spin, Switch, message } from 'antd'

// ** Constant Imports
import { firstOptions, secondOptions } from '../../constants/modal-constants'

// ** Style Imports
import styles from '../../styles/analytics-settings-modal.module.css'

// ** Hooks Imports
import useAnalyticsSettings from '../../hooks/useAnalyticsSettings'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../../manager/analytics-settings-store'

// ** Type Imports
import {
  IDropdownUI,
  IDropdownApi,
  IFormFields,
  IFormItem
} from '../../types/form-data'

// =================================================================
const success = (action: 'created' | 'updated') => {
  message.success(`Rule ${action} successfully!`)
}

const error = (errorMsg: string) => {
  message.error(errorMsg)
}

const GFormItem = ({
  props,
  child
}: {
  props: IFormItem
  child: JSX.Element
}) => {
  return <Form.Item {...props}>{child}</Form.Item>
}

const rulesRequired = [{ required: true, message: 'This is a required field.' }]

const formItemsRuleConfig = [
  {
    props: {
      label: 'Rule Active',
      name: 'active',
      valuePropName: 'checked'
    },
    child: <Switch />
  },
  {
    props: {
      label: 'Rule Name',
      name: 'name',
      rules: rulesRequired
    },
    child: <Input />
  }
]

const formatDropdownData = (data: IDropdownApi) => {
  return Object.entries(data).map(([index, value]) => {
    return { value: index, label: value }
  })
}

const initializeEditDropdown = (data: IDropdownApi) => {
  return Object.entries(data).map(([index, value]) => {
    return index
  })
}

const AnalyticsSettingsModal = () => {
  const [finalDropdownList, setFinalDropdownList] = useState<IDropdownUI[]>([])
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  // ** AntD Form **
  const [form] = Form.useForm()

  // ** Store **
  const {
    openModal,
    setOpenModal,
    selectedRecord,
    setSelectedRecord,
    errorMsg,
    selectedDropdown,
    setSelectedDropdown
  } = useAnalyticsSettingsStore()

  // ** Hooks **
  const {
    handleSubmitRule,
    postRuleLoading,
    postRuleSuccess,
    postRuleError,
    handleUpdateRule,
    patchRuleLoading,
    patchRuleSuccess,
    patchRuleError,
    getDropdownList
  } = useAnalyticsSettings()
  const { isLoading, data: dropdownList } = getDropdownList

  const containerClass = `${styles.container} ${
    (postRuleLoading || patchRuleLoading) && styles.formLoader
  }`

  const onDropdownChange = (value: string) => {
    setFinalDropdownList([])
    setSelectedDropdown(value)
    form.setFieldValue('values', [])
  }

  const formItemsRuleCriteria = [
    {
      props: {
        name: 'field',
        className: styles.criteriaForm,
        rules: rulesRequired
      },
      child: (
        <Select
          placeholder='Please select'
          options={firstOptions}
          onChange={onDropdownChange}
        />
      )
    },
    {
      props: {
        name: 'operator',
        className: styles.criteriaForm,
        rules: rulesRequired
      },
      child: <Select placeholder='Please select' options={secondOptions} />
    },

    {
      props: {
        name: 'values',
        className: styles.criteriaForm,
        rules: rulesRequired
      },
      child: (
        <Select
          mode='multiple'
          placeholder='Please select'
          options={finalDropdownList}
          disabled={!!selectedDropdown && isLoading}
          loading={!!selectedDropdown && isLoading}
        />
      )
    }
  ]

  const onFinish = (values: IFormFields) => {
    const ruleData = {
      name: values.name,
      active: values.active ? 1 : 0,
      rules: {
        field: values.field,
        operator: values.operator,
        values: values.values
      }
    }

    // ** Perform update action when selectedRecord state is not null
    if (selectedRecord) handleUpdateRule({ ruleData, id: selectedRecord.id })
    else handleSubmitRule({ ruleData })
  }

  const onReset = () => {
    form.resetFields()
  }

  const handleCancel = () => {
    setOpenModal(false)
    setSelectedRecord(null)
    setSelectedDropdown('')
    setFinalDropdownList([])
    setHasLoaded(false)
    onReset()
  }

  const modalMode = !selectedRecord ? 'Create' : 'Update'

  const ModalFooter = () => {
    return (
      <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type='primary'
          htmlType='submit'
          onClick={() => form.submit()}
          disabled={postRuleLoading || patchRuleLoading}
        >
          Save
        </Button>
      </>
    )
  }

  // ** Component updates if Edit action is clicked
  useEffect(() => {
    // Trigger Get Dropdown List API
    setSelectedDropdown(selectedRecord?.rules.field || '')

    const initialValuesObj = {
      active: selectedRecord?.active ? true : false,
      name: selectedRecord?.name,
      field: selectedRecord?.rules.field,
      operator: selectedRecord?.rules.operator
    }

    form.setFieldsValue(initialValuesObj)
    // eslint-disable-next-line
  }, [selectedRecord])

  // ** Component updates after calling Create Rule API
  useEffect(() => {
    if (postRuleSuccess) {
      success('created')
      onReset()
      handleCancel()
    }

    if (postRuleError) error(errorMsg)
    // eslint-disable-next-line
  }, [postRuleSuccess, postRuleError])

  // ** Component updates after calling Update Rule API
  useEffect(() => {
    if (patchRuleSuccess) {
      success('updated')
      onReset()
      handleCancel()
    }

    if (patchRuleError) error(errorMsg)
    // eslint-disable-next-line
  }, [patchRuleSuccess, patchRuleError])

  // ** Component updates after calling Get Dropdown List API
  useEffect(() => {
    if (dropdownList?.data) {
      const formattedData = formatDropdownData(dropdownList?.data)
      setFinalDropdownList(formattedData)

      // Detects if Edit action is selected, select initial dropdown list values
      // Used in setting default value ONLY when Edit action is initially selected
      if (selectedRecord?.rules.values && !hasLoaded) {
        const editDropdownData = initializeEditDropdown(
          selectedRecord?.rules.values
        )
        form.setFieldValue('values', editDropdownData)
        setHasLoaded(true)
      }
    }
    // eslint-disable-next-line
  }, [dropdownList])

  return (
    <Form
      form={form}
      component={false}
      onFinish={onFinish}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 20 }}
      className={styles.formWrapper}
    >
      <Modal
        title={`${modalMode} Invoice Rule`}
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={handleCancel}
        width={1000}
        bodyStyle={{ padding: 10, margin: 0 }}
        footer={<ModalFooter />}
      >
        <div className={styles.modalContainer}>
          {(postRuleLoading || patchRuleLoading) && (
            <div className={styles.loadingSpinner}>
              <Spin size='large' />
            </div>
          )}
          <div className={containerClass}>
            {formItemsRuleConfig.map((value, index) => (
              <GFormItem
                key={index}
                props={value.props}
                child={value.child as JSX.Element}
              />
            ))}

            <Form.Item
              label='Rule Criteria'
              className={styles.noMargin}
            ></Form.Item>
            <div className={styles.selectWrapper}>
              <div className={styles.criteriaLabel}>IF</div>
              {formItemsRuleCriteria.map((value, index) => (
                <GFormItem
                  key={index}
                  props={value.props}
                  child={value.child as JSX.Element}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </Form>
  )
}

export default AnalyticsSettingsModal
