import { SetStateAction } from 'react'
import { AUTH_TOKEN, ROLES_AND_PERMISSIONS } from '../helpers/constants/Auth'
import RolesPermissions from '../types/RolesPermissions'

export default function checkAuthTxnAddLineColMan(
  username: string | null,
  setIsAuth: (value: SetStateAction<boolean>) => void
): void {
  if (localStorage.getItem(AUTH_TOKEN)) {
    const rolesPersmissions: RolesPermissions = JSON.parse(
      localStorage.getItem(ROLES_AND_PERMISSIONS)!
    )

    if (rolesPersmissions.username === username) {
      setIsAuth(true)
    } else {
      localStorage.clear()
      setIsAuth(false)
    }
  } else {
    localStorage.clear()
    setIsAuth(false)
  }
}
