import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'
import UserPayload from '../types/UserPayload'
import { AUTH_TOKEN } from '../../../../../helpers/constants/Auth'

export default function updateUser(id: number, payload: UserPayload) {
  const token = localStorage.getItem(AUTH_TOKEN)
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    ContentType: 'application/json'
  }

  try {
    return axiosIdentityManagerInstance.put(`/api/admin/user/${id}`, payload, {
      headers
    })
  } catch (error) {
    throw error
  }
}
