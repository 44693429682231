// ** Redux Imports
import { useSelector } from 'react-redux'
import { RootState } from '../../../context/store'

// ** Style Imports
import styles from '../styles/login.module.css'

// =================================================================
const InvalidToken = () => {
  // ** Redux **
  const { invalidTokenMessage } = useSelector(
    (state: RootState) => state.userActivationForm
  )
  return invalidTokenMessage ? (
    <div
      className={styles.errorMessage}
      style={{
        color: invalidTokenMessage.toLowerCase().includes('activated')
          ? '#237804'
          : '#cf1322',
        background: invalidTokenMessage.toLowerCase().includes('activated')
          ? '#f6ffed'
          : '#fff1f0',
        border: `1px solid ${
          invalidTokenMessage.toLowerCase().includes('activated')
            ? '#b7eb8f'
            : '#ffa39e'
        }`
      }}
    >
      {invalidTokenMessage}
    </div>
  ) : (
    <></>
  )
}
export default InvalidToken
