// ** Zustand Imports
import { create } from 'zustand'

// ** Type Imports
import AnalyticsProps from '../types/AnalyticsStore'
import TableRecord from '../types/TableRecord'
import { INonCompliantInvoices } from '../types/NonCompliantDataType'
import { TIncreasingSpend } from '../types/IncreasingSpendDataType'
import { EReportTypes, TEmailTemplate } from '../constants/Globals'

// =================================================================
const useAnalyticsStore = create<AnalyticsProps>((set) => ({
  // Define initial state
  banner: [],
  collapsed: false,
  selectedItem: null,
  internalPage: 1,
  vendorPage: 1,
  timingPage: 1,
  volumePage: 1,
  accuracyPage: 1,
  completenessPage: 1,
  header: '',
  increasingSpendHeader: '',
  title: '',
  internalSort: 'desc',
  vendorSort: 'desc',
  timingSort: 'desc',
  volumeSort: 'desc',
  accuracySort: 'desc',
  completenessSort: 'desc',
  hovered: false,
  selectedRowKeys: {},
  sort: '0:asc',
  sortInternal: '0:asc',
  sortVendor: '0:asc',
  sortSpendDepartments: '0:asc',
  sortSpendEmployees: '0:asc',
  sortIncreasingSpend: '0:asc',
  sortState: [],
  buttonClicked: '',
  isAnalytics: undefined,
  openModal: false,
  modalType: '',
  responseType: undefined,
  period: undefined,
  permNotesVendorName: undefined,
  showDatePicker: false,
  accrualData: [],
  permNoteVendorId: undefined,
  permNoteMessage: '',
  modifiedUpdateAccrualData: [],
  updateAccrualEditingKey: null,
  updateAccrualChanges: {},
  updateAccrualVendorTaskId: null,
  monthsToShow: 6,
  internalMonthsToShow: 6,
  vendorMonthsToShow: 6,
  showInternalCount: 0,
  showVendorCount: 0,
  showInternalNonResponsive: 0,
  showVendorNonResponsive: 0,
  activeFilter: 'all',
  showCreateFilterModal: false,
  showPreview: false,
  permNotesEditingKey: null,
  page: 1,
  pageIncreasingSpend: 1,
  selectedRows: [],
  showEmailVendor: false,
  additionalEmails: [],
  selectedRowsToEmail: [],
  selectedRowsToEmailKeys: [],
  permNotesErrorMsg: null,
  valuesPopupVendorId: '',
  valuesPopupDuration: '',
  costCuttingTabView: '',
  nonCompliantTableEditingKey: null,
  nonCompliantTablePage: 1,
  nonCompliantTableSort: '0:desc',
  nonCompliantSelectedRowKeys: [],
  nonCompliantSelectedRows: [],
  enableExecutionActionButton: false,
  increasingSpendTableEditingKey: null,
  increasingSpendTablePage: 1,
  increasingSpendTableSort: 'desc',
  increasingSpendSelectedRowKeys: [],
  increasingSpendSelectedRows: [],
  executeActionsTableEditingKey: null,
  errorMsg: '',
  editedSuggestedActionKey: null,
  nonCompliantFirstTabData: [],
  nonCompliantSecondTabData: [],
  nonCompliantThirdTabData: [],
  increasingSpendFirstTabData: [],
  increasingSpendSecondTabData: [],
  isAnalyticsLoading: true,
  reportType: null,
  emailTemplate: null,

  // Define actions
  setBanner: (banner) => set({ banner }),
  setCollapsed: (collapsed) => set({ collapsed }),
  setSelectedItem: (selectedItem) => set({ selectedItem }),
  setInternalPage: (internalPage) => set({ internalPage }),
  setVolumePage: (volumePage) => set({ volumePage }),
  setVendorPage: (vendorPage) => set({ vendorPage }),
  setTimingPage: (timingPage) => set({ timingPage }),
  setAccuracyPage: (accuracyPage) => set({ accuracyPage }),
  setCompletenessPage: (completenessPage) => set({ completenessPage }),
  setHeader: (header) => set({ header }),
  setIncreasingSpendHeader: (increasingSpendHeader) =>
    set({ increasingSpendHeader }),
  setTitle: (title) => set({ title }),
  setInternalSort: (internalSort) => set({ internalSort }),
  setVendorSort: (vendorSort) => set({ vendorSort }),
  setTimingSort: (timingSort) => set({ timingSort }),
  setVolumeSort: (volumeSort) => set({ volumeSort }),
  setAccuracySort: (accuracySort) => set({ accuracySort }),
  setCompletnessSort: (completenessSort) => set({ completenessSort }),
  setHovered: (hovered) => set({ hovered }),
  setSelectedRowKeys: (selectedRowKeys) => set({ selectedRowKeys }),
  setSort: (sort) => set({ sort }),
  setSortInternal: (sortInternal) => set({ sortInternal }),
  setSortVendor: (sortVendor) => set({ sortVendor }),
  setSortSpendDepartments: (sortSpendDepartments) =>
    set({ sortSpendDepartments }),
  setSortSpendEmployees: (sortSpendEmployees) => set({ sortSpendEmployees }),
  setSortIncreasingSpend: (sortIncreasingSpend) => set({ sortIncreasingSpend }),
  setSortState: (sortState) => set({ sortState }),
  setButtonClicked: (buttonClicked) => set({ buttonClicked }),
  setIsAnalytics: (isAnalytics) => set({ isAnalytics }),
  setOpenModal: (value) => set({ openModal: value }),
  setModalType: (value) => set({ modalType: value }),
  setResponseType: (responseType) => set({ responseType }),
  setPeriod: (period) => set({ period }),
  setPermNotesVendorName: (value) => set({ permNotesVendorName: value }),
  setShowDatePicker: (showDatePicker) => set({ showDatePicker }),
  setAccrualData: (newData) => set({ accrualData: newData }),
  setPermNoteVendorId: (permNoteVendorId) => set({ permNoteVendorId }),
  setPermNoteMessage: (permNoteMessage) => set({ permNoteMessage }),
  setModifiedUpdateAccrualData: (modifiedUpdateAccrualData) =>
    set({ modifiedUpdateAccrualData }),
  setUpdateAccrualEditingKey: (updateAccrualEditingKey) =>
    set({ updateAccrualEditingKey }),
  setUpdateAccrualChanges: (updateAccrualChanges) =>
    set({ updateAccrualChanges }),
  setUpdateAccrualVendorTaskId: (updateAccrualVendorTaskId) =>
    set({ updateAccrualVendorTaskId }),
  setMonthsToShow: (monthsToShow) => set({ monthsToShow }),
  setInternalMonthsToShow: (internalMonthsToShow) =>
    set({ internalMonthsToShow }),
  setVendorMonthsToShow: (vendorMonthsToShow) => set({ vendorMonthsToShow }),
  setShowInternalCount: (showInternalCount) => set({ showInternalCount }),
  setShowVendorCount: (showVendorCount) => set({ showVendorCount }),
  setShowInternalNonResponsive: (showInternalNonResponsive) =>
    set({ showInternalNonResponsive }),
  setShowVendorNonResponsive: (showVendorNonResponsive) =>
    set({ showVendorNonResponsive }),
  setActiveFilter: (activeFilter) => set({ activeFilter }),
  setShowCreateFilterModal: (showCreateFilterModal) =>
    set({ showCreateFilterModal }),
  setShowPreview: (showPreview) => set({ showPreview }),
  setPermNotesEditingKey: (permNotesEditingKey) => set({ permNotesEditingKey }),
  setPage: (page) => set({ page }),
  setPageIncreasingSpend: (pageIncreasingSpend) => set({ pageIncreasingSpend }),
  setSelectedRows: (selectedRows: string[]) => set({ selectedRows }),
  setShowEmailVendor: (showEmailVendor) => set({ showEmailVendor }),
  setAdditionalEmails: (additionalEmails: string[]) =>
    set({ additionalEmails }),
  setSelectedRowsToEmail: (selectedRowsToEmail: any[]) =>
    set({ selectedRowsToEmail }),
  setSelectedRowsToEmailKeys: (selectedRowsToEmailKeys: React.Key[]) =>
    set({ selectedRowsToEmailKeys }),
  setPermNotesErrorMsg: (permNotesErrorMsg) => set({ permNotesErrorMsg }),
  setValuesPopupVendorId: (valuesPopupVendorId) => set({ valuesPopupVendorId }),
  setValuesPopupDuration: (valuesPopupDuration) => set({ valuesPopupDuration }),
  setCostCuttingTabView: (costCuttingTabView) => set({ costCuttingTabView }),
  setNonCompliantTableEditingKey: (nonCompliantTableEditingKey) =>
    set({ nonCompliantTableEditingKey }),
  setNonCompliantTablePage: (nonCompliantTablePage) =>
    set({ nonCompliantTablePage }),
  setNonCompliantTableSort: (nonCompliantTableSort) =>
    set({ nonCompliantTableSort }),
  setNonCompliantSelectedRowKeys: (keys) =>
    set({ nonCompliantSelectedRowKeys: keys }),
  setNonCompliantSelectedRows: (nonCompliantSelectedRows) =>
    set({ nonCompliantSelectedRows }),
  setEnableExecutionActionButton: (enableExecutionActionButton) =>
    set({ enableExecutionActionButton }),
  setIncreasingSpendTableEditingKey: (increasingSpendTableEditingKey) =>
    set({ increasingSpendTableEditingKey }),
  setIncreasingSpendTablePage: (increasingSpendTablePage) =>
    set({ increasingSpendTablePage }),
  setIncreasingSpendTableSort: (increasingSpendTableSort) =>
    set({ increasingSpendTableSort }),
  setIncreasingSpendSelectedRowKeys: (keys) =>
    set({ increasingSpendSelectedRowKeys: keys }),
  setIncreasingSpendSelectedRows: (increasingSpendSelectedRows) =>
    set({ increasingSpendSelectedRows }),
  setExecuteActionsTableEditingKey: (executeActionsTableEditingKey) =>
    set({ executeActionsTableEditingKey }),
  setErrorMsg: (errorMsg: string) => set({ errorMsg }),
  setEditedSuggestedActionKey: (editedSuggestedActionKey: number | null) =>
    set({ editedSuggestedActionKey }),
  setNonCompliantFirstTabData: (
    nonCompliantFirstTabData: INonCompliantInvoices[] | []
  ) => set({ nonCompliantFirstTabData }),
  setNonCompliantSecondTabData: (
    nonCompliantSecondTabData: INonCompliantInvoices[] | []
  ) => set({ nonCompliantSecondTabData }),
  setNonCompliantThirdTabData: (
    nonCompliantThirdTabData: INonCompliantInvoices[] | []
  ) => set({ nonCompliantThirdTabData }),
  setIncreasingSpendFirstTabData: (
    increasingSpendFirstTabData: TIncreasingSpend[] | []
  ) => set({ increasingSpendFirstTabData }),
  setIncreasingSpendSecondTabData: (
    increasingSpendSecondTabData: TIncreasingSpend[] | []
  ) => set({ increasingSpendSecondTabData }),
  setIsAnalyticsLoading: (isAnalyticsLoading: boolean) =>
    set({ isAnalyticsLoading }),
  setReportType: (reportType: EReportTypes | null) => set({ reportType }),
  setEmailTemplate: (emailTemplate: TEmailTemplate | null) =>
    set({ emailTemplate }),

  // Functions
  handleMenuClick: (key: string, record: TableRecord, button: string) => {
    set({ buttonClicked: button })
    set({ permNotesVendorName: record[0] })
    console.log('record key', record.key)
    console.log('Menu Click on row: ', record[0])
    console.log('Clicked on Menu Item: ', key)
    console.log('buttonclicked', button)
    switch (key) {
      case '1':
        console.log(`Update Accrual Manager Clicked`)
        set({ openModal: true, modalType: 'update' })
        set({ permNoteVendorId: record.key })
        break
      case '2':
        console.log(`Perm Notes Clicked`)
        set({ openModal: true, modalType: 'perm' })
        set({ permNoteVendorId: record.key })
        break
      case '3':
        console.log(`History Log Clicked`)
        set({ openModal: true, modalType: 'history' })
        set({ permNoteVendorId: record.key })
        break
      default:
        break
    }
  },
  handleUpdateAccrualCellClick: (key: string | null) => {
    set((state) => ({
      updateAccrualEditingKey:
        state.updateAccrualEditingKey === key ? null : key
    }))
  },
  handleUpdateAccrualChange: (key: string, record: any, value: any) => {
    set({ updateAccrualVendorTaskId: key })
    set({ updateAccrualChanges: { [record]: value } })
  },
  handleNonCompliantTableCellClick: (key: string | null) => {
    set((state) => ({
      nonCompliantTableEditingKey:
        state.nonCompliantTableEditingKey === key ? null : key
    }))
  },
  handleIncreasingSpendTableCellClick: (key: string | null) => {
    set((state) => ({
      increasingSpendTableEditingKey:
        state.increasingSpendTableEditingKey === key ? null : key
    }))
  },
  handleExecuteActionsTableCellClick: (key: string | null) => {
    set((state) => ({
      executeActionsTableEditingKey:
        state.executeActionsTableEditingKey === key ? null : key
    }))
  }
}))

export default useAnalyticsStore
