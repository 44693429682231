// ** React Imports **
import { FC, useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'

// ** AntD Imports **
import { Card, Menu, MenuProps } from 'antd'

// ================================================
const QjeReviewPoClosure: FC = (): JSX.Element => {
  // ** Hooks **
  const location = useLocation()
  const navigate = useNavigate()

  const menuItems: MenuProps['items'] = [
    {
      label: 'Unreviewed',
      key: 'unreviewed'
    },
    {
      label: 'Reviewed',
      key: 'reviewed'
    }
  ]

  useEffect(() => {
    if (
      ['/qje2/review-po-closure', '/qje2/review-po-closure/'].includes(
        location.pathname
      )
    )
      navigate('/qje2/review-po-closure/unreviewed')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div data-testid='qje-review-po-closure' data-cy='qje-review-po-closure'>
      <Card bodyStyle={{ paddingTop: 0 }} style={{ borderTopWidth: 0 }}>
        <div style={{ width: '100%' }}>
          <Menu
            onClick={(e) => navigate(`/qje2/review-po-closure/${e.key}`)}
            data-testid='qje-review-po-closure-tabs'
            items={menuItems}
            mode='horizontal'
            activeKey={
              [
                '/qje2/review-po-closure/unreviewed',
                '/qje2/review-po-closure/unreviewed/'
              ].includes(location.pathname)
                ? 'unreviewed'
                : 'reviewed'
            }
          />
          <Outlet />
        </div>
      </Card>
    </div>
  )
}

export default QjeReviewPoClosure
