import { FC, useEffect, useState, KeyboardEvent } from 'react'
import { DatePicker, Space } from 'antd'
import {
  PlusOutlined,
  ExportOutlined,
  DownOutlined,
  LockOutlined
} from '@ant-design/icons'
import type { DatePickerProps } from 'antd'
import GButton from './gappify/GButton'
import GDropdownBtn from '../components/gappify/GDropdownBtn'
import moment from 'moment'
import {
  getCurrentPeriodFormatted,
  // getNextPeriod,
  getCurrentPeriod
} from '../utils/Date'
import GQuickSearchBar from './gappify/GQuickSearchBar'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import usePeriodManager from 'src/pages/Settings/pages/PeriodManagement/hooks/usePeriodManager'
import usePeriodDropdownStore from 'src/global/usePeriodDropdownStore'

interface GlobalFilterPanelProps {
  hasAddNewLines: any
  onClickPeriod: any
  onClickExport: any
  leftChild: any
  rightChild: any
  hasExportBtn: any
  hasCSVExport: any
  hasPDFExport: any
  hasExcelExport: any
  hasExportPermission: any
  showTxnFilter: any
  periodFilterLabel: any
  loading: any
  hasQuickSearchBar?: boolean
  quickSearchPlaceHolder?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  hasCSVWithIdExport?: boolean
}

const GlobalFilterPanel: FC<GlobalFilterPanelProps> = ({
  hasAddNewLines,
  onClickPeriod,
  onClickExport,
  leftChild,
  rightChild,
  hasExportBtn,
  hasCSVExport,
  hasPDFExport,
  hasExcelExport,
  hasExportPermission,
  showTxnFilter,
  periodFilterLabel,
  loading,
  hasQuickSearchBar,
  quickSearchPlaceHolder,
  onKeyDown,
  hasCSVWithIdExport
}: GlobalFilterPanelProps): JSX.Element => {
  const [periodLabel, setPeriodLabel] = useState(getCurrentPeriodFormatted())
  const [showPeriodOptions, setShowPeriodOptions] = useState(false)
  const isFlagEnabled = useIsFlagEnabled()
  const { getPeriodManager } = usePeriodManager()
  const periodDropdownData = usePeriodDropdownStore(
    (state) => state.periodDropdownData
  )

  // ** Extract data from getPeriodManager **
  const { data } = getPeriodManager

  const isClosedSelectedPeriod = (period: string) => {
    // Data is loaded
    return (
      data &&
      // Check if there are closed periods and if same if same period
      data.some((obj) => obj.status === 0 && obj.period === period)
    )
  }

  const getMonthsOptions = () => {
    const start = getCurrentPeriod()
    let mos = []

    for (let i = 0; i < 6; i++) {
      let period = start.format('MMMM YYYY')
      let key = start.startOf('month')

      mos.push({
        label: period,
        key: key.format('YYYY-MM-DD')
      })
      start.subtract(1, 'month')
    }

    return mos
  }

  const getTxnMonthsOptions = () => {
    if (!isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management)) {
      return getMonthsOptions()
    }

    let mos = []

    periodDropdownData?.forEach((item) => {
      const momentObj = moment(item.period)
      const label = momentObj.format('MMMM YYYY')
      const key = momentObj.format('YYYY-MM-DD')
      mos.push({
        label,
        key,
        ...(isClosedSelectedPeriod(key) && {
          icon: (
            <LockOutlined
              className='period-filter-icon'
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        })
      })
    })

    mos.push({
      type: 'divider'
    })
    mos.push({
      label: (
        <DatePicker
          className='period-picker'
          picker='month'
          placeholder='Select Period'
          format='MMMM YYYY'
          onChange={onChangeDatePicker}
        />
      ),
      key: 'select'
    })

    return mos
  }

  const onChangeDatePicker: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setPeriodLabel(moment(date, 'MMMM YYYY').format('MMMM YYYY'))
      onClickPeriod &&
        onClickPeriod({
          key: moment(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
        })
    } else {
      //back to current period on click clear
      const start = getCurrentPeriod()
      let period = start.format('MMMM YYYY')
      let key = start.startOf('month')
      setPeriodLabel(period)
      onClickPeriod({
        key: key.format('YYYY-MM-DD')
      })
    }
  }

  const getExportMenu = () => {
    let menu: object[] = []
    if (hasCSVExport) {
      menu.push({
        label: 'CSV',
        key: 'csv',
        ...(hasCSVWithIdExport && {
          children: [
            {
              label: 'Export with IDs',
              key: 'csv_with_id'
            },
            { label: 'Export without IDs', key: 'csv' }
          ]
        })
      })
    }
    if (hasPDFExport) {
      menu.push({
        label: 'PDF',
        key: 'pdf'
      })
    }
    if (hasExcelExport) {
      menu.push({
        label: 'Excel',
        key: 'excel'
      })
    }
    // if (hasCSVWithIdExport) {
    //   menu.push({
    //     label: 'CSV (with IDs)',
    //     key: 'csv_with_id'
    //   })
    // }
    return menu
  }

  const handleOnClickPeriod = (item: any) => {
    let date = moment(item.key)
    setPeriodLabel(moment(date, 'MMMM YYYY').format('MMMM YYYY'))
    onClickPeriod && onClickPeriod(item)
  }

  const handleOnClickTxnPeriod = (item: any) => {
    if (item.key !== 'select') {
      let date = moment(item.key)
      setPeriodLabel(moment(date, 'MMMM YYYY').format('MMMM YYYY'))
      setShowPeriodOptions(false)
      onClickPeriod && onClickPeriod(item)
    }
  }

  const handleVisibleChange = (open: boolean) => {
    setShowPeriodOptions(open)
  }

  useEffect(() => {
    if (periodFilterLabel) setPeriodLabel(periodFilterLabel)
  }, [periodFilterLabel])

  return (
    <div
      data-testid='global-filter-panel'
      data-cy='global-filter-panel'
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', gap: 5 }}>
        {showTxnFilter ? (
          <GDropdownBtn
            dataTestId='global-filter-panel-period-dropdown-btn'
            dataCy='global-filter-panel-period-dropdown-btn'
            menuClassName='period-filter'
            menuItems={getTxnMonthsOptions()}
            btnText={periodLabel}
            children={
              isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management) &&
              isClosedSelectedPeriod(
                moment(periodLabel)
                  .add(1, 'month')
                  .startOf('month')
                  .format('YYYY-MM-DD')
              ) ? (
                <Space style={{ marginLeft: 10 }}>
                  <LockOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                  <DownOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                </Space>
              ) : (
                <DownOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              )
            }
            onClick={handleOnClickTxnPeriod}
            defaultSelectedKeys={[
              getCurrentPeriod().startOf('month').format('YYYY-MM-DD')
            ]}
            visible={showPeriodOptions}
            onVisibleChange={handleVisibleChange}
          />
        ) : (
          <GDropdownBtn
            dataTestId='global-filter-panel-period-dropdown-btn'
            dataCy='global-filter-panel-period-dropdown-btn'
            menuItems={getMonthsOptions()}
            btnText={periodLabel}
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={handleOnClickPeriod}
            defaultSelectedKeys={[
              getCurrentPeriod().startOf('month').format('YYYY-MM-DD')
            ]}
          />
        )}
        {leftChild}
        {hasQuickSearchBar && (
          <GQuickSearchBar
            placeholder={quickSearchPlaceHolder}
            onKeyDown={onKeyDown}
            tooltipTitle={quickSearchPlaceHolder || 'Search'}
          />
        )}
      </div>
      <div style={{ display: 'flex', gap: 5 }}>
        {rightChild}
        {hasAddNewLines && (
          <GButton
            dataTestId='global-filter-panel-add-new-line-btn'
            dataCy='global-filter-panel-add-new-line-btn'
            btnText='Add New Lines'
            icon={
              <PlusOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
          />
        )}

        {hasExportBtn && (
          <GDropdownBtn
            dataTestId='global-filter-panel-export-dropdown-btn'
            dataCy='global-filter-panel-export-dropdown-btn'
            menuItems={getExportMenu()}
            btnText='Export to'
            btnIcon={
              <ExportOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={onClickExport}
            disabled={!hasExportPermission}
            className='g-btn-default'
            loading={loading}
          />
        )}
      </div>
    </div>
  )
}

GlobalFilterPanel.defaultProps = {
  hasCSVExport: true,
  hasPDFExport: true,
  hasExcelExport: true,
  showTxnFilter: false
}

export default GlobalFilterPanel
