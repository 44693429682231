// ** AntD Imports
import { Button, Dropdown, Menu, Modal, message } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import useAnalyticsSettingsActionItems from '../../hooks/useActionItems'

// ** Style Imports
import styles from '../../styles/actions.module.css'

// ** Hooks Imports
import useAnalyticsSettings from '../../hooks/useAnalyticsSettings'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../../manager/analytics-settings-store'

// ** Type Imports
import { IRules } from '../../types/table-record'

interface IProps {
  record: IRules
}

const { confirm } = Modal

// =================================================================
const Actions = ({ record }: IProps) => {
  // ** Store **
  const { setOpenModal, setSelectedRecord } = useAnalyticsSettingsStore()

  // ** Hooks **
  const { deleteRuleAsync } = useAnalyticsSettings()

  // ** Items **
  const { ActionItems } = useAnalyticsSettingsActionItems()

  const handleMenuClick = (key: string) => {
    switch (key) {
      case '1':
        setOpenModal(true)
        setSelectedRecord(record)
        break
      case '2':
        handleDeleteClick()
        break
      default:
        break
    }
  }

  const handleDeleteClick = () => {
    confirm({
      title: 'Are you sure you want to delete this Analytics Rule?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await deleteRuleAsync({ id: record.id })
          message.success('Deleted rule successfully.')
        } catch (err) {
          console.log('error', err)
          message.error('An error occurred. Try again.')
        }
      }
    })
  }

  return (
    <Dropdown
      overlay={
        <Menu items={ActionItems} onClick={({ key }) => handleMenuClick(key)} />
      }
      trigger={['click']}
    >
      <Button
        className={styles.primaryBtn}
        icon={<MoreOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} className ={styles.outlineBtn} />}
      />
    </Dropdown>
  )
}

export default Actions
