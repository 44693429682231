import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'
import { setSelectedLineGroups as setGlobalSelectedLineGroups } from '../../context/actions/qjeAction'
import { useDispatch, useSelector } from 'react-redux'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

interface LineGroupCheckboxProps {
  row?: any
  index: number

  // refs...
  selectedLineGroupsRef?: any
}

const LineGroupCheckbox: FC<LineGroupCheckboxProps> = ({
  row,
  index,
  selectedLineGroupsRef
}: LineGroupCheckboxProps): JSX.Element => {
  const dispatch = useDispatch()
  const selectedLineGroups = useSelector(
    (state: any) => state.allQjes.selectedLineGroups
  )

  const RP = useRolesAndPermissions()

  const handleOnCheck = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked
    if (!isChecked) {
      // Update ref, update global state
      selectedLineGroupsRef.current = selectedLineGroupsRef.current.filter(
        (lineGroup: string) => lineGroup !== row.line_group
      )
      // dispatch(
      //   setGlobalSelectedLineGroups(
      //     selectedLineGroupsRef.current.filter(
      //       (lineGroup: string) => lineGroup !== row.line_group
      //     )
      //   )
      // )
      const removeItem = selectedLineGroups.filter(
        (item: string) => item !== row.line_group
      )
      dispatch(setGlobalSelectedLineGroups(removeItem))
    } else {
      selectedLineGroupsRef.current = [
        ...selectedLineGroupsRef.current,
        row.line_group
      ]
      dispatch(
        setGlobalSelectedLineGroups([...selectedLineGroups, row.line_group])
      )
    }
  }

  return (
    <Checkbox
      data-testid={`qje-review-je-checkbox-linegroup-${index}`}
      data-cy={`qje-review-je-checkbox-linegroup-${index}`}
      defaultChecked={false}
      disabled={
        !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
        !RP.USER_HAS_REJECT_QJE_PERMISSION ||
        !RP.USER_HAS_REVIEWED_FLAG_QJE_PERMISSION
      }
      checked={selectedLineGroups.includes(row.line_group)}
      onChange={handleOnCheck}
    />
  )
}

export default LineGroupCheckbox
