// ** React Imports
import React, { useMemo } from 'react'

// ** AntD Imports
import {
  Button,
  Dropdown,
  Menu,
  MenuProps,
  Skeleton,
  TablePaginationConfig,
  Table as VTable
} from 'vincent-antd'
import { ColumnType } from 'vincent-antd/node_modules/antd/es/table'
import { MoreOutlined } from '@ant-design/icons'

// ** Helper Imports
import capitalize from '../../helper/Capitalize'

// ** Hooks Imports
import useTable from '../../hooks/useTable'
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'

// ** Style Imports
import '../../styles/table.styles.css'
import styles from '../../styles/actions.module.css'

// ** Third Party Imports
import moment from 'moment'

// ** Type Imports
import TableRecord from '../../types/TableRecord'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'
// =================================================================
const AnalyticsTable = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
}) => {
  // ** Zustand **
  const {
    selectedItem,
    responseType,
    setSelectedRowKeys,
    sortInternal,
    sortVendor,
    setOpenModal,
    setModalType,
    setPermNotesVendorName,
    setValuesPopupVendorId,
    setValuesPopupDuration
  } = useAnalyticsStore()
  const { handleMenuClick } = useAnalyticsStore((state) => ({
    handleMenuClick: state.handleMenuClick
  }))

  // ** Hooks **
  const RP = useRolesAndPermissions()
  const { handleTableChange, rowSelection } = useTable()

  const ActionItems: MenuProps['items'] = [
    {
      label: 'Update Accrual Manager',
      key: '1',
      disabled: !RP.USER_HAS_UPDATE_ACCRUAL_MANAGER_AA_PERMISSION
    },
    {
      label: 'Perm Notes',
      key: '2',
      disabled: !RP.USER_HAS_VENDOR_PERM_NOTES_AA_PERMISSION
    },
    {
      label: 'History Log',
      key: '3'
    }
  ]

  // ** State **
  const [hoveredCell, setHoveredCell] = React.useState<string | null>(null)

  const handleTableDataClick = (
    text: string,
    record: TableRecord,
    index: number
  ) => {
    // console.log(
    //   `You clicked on ${text} at index ${index} in record ${JSON.stringify(
    //     record
    //   )}`
    // )
    console.log('record key', record.key)
    console.log('Menu Click on row: ', record[0])
    console.log('Clicked on Menu Item: ', text)
    console.log(`index`, index)
    setPermNotesVendorName(record[0])
    setOpenModal(true)
    setModalType('data-values')
    // `setOpenModal(true)` to open the modal
    // set the `modalType` according to what data was clicked

    // Prepare the period field
    const periodObj = data?.headers[index].split(' ')
    const periodMonth = moment().month(periodObj[0]).format('MM')
    const periodYear = periodObj[1]
    const period = `${periodYear}-${periodMonth}-01`

    console.log('periodObj', periodObj)
    console.log('periodMonth', periodMonth)
    console.log('periodYear', periodYear)
    console.log('period', period)

    setValuesPopupVendorId(record.key)
    setValuesPopupDuration(period)
  }

  const apiTableColumns: ColumnType<any>[] =
    data &&
    data?.headers &&
    data?.headers.map((header: any, columnIdx: any) => {
      const sortSplit =
        responseType === 'internal'
          ? sortInternal.split(':')
          : sortVendor.split(':')
      const sortColumn = Number(sortSplit[0])
      const sortOrder = sortSplit.length ? `${sortSplit[1]}end` : false

      return {
        key: columnIdx,
        width: header === 'Vendor' ? 200 : 140,
        title: header,
        dataIndex: String(columnIdx),
        sorter: true,
        sortOrder: columnIdx === sortColumn && sortOrder,
        render: (text: string, record: any) => {
          const cellId = `${record.key}-${columnIdx}` // row-based key and column index

          return (
            <div
              style={
                columnIdx
                  ? {
                      textDecoration:
                        hoveredCell === cellId ? 'underline' : 'none',
                      cursor: 'pointer',
                      color: hoveredCell === cellId ? '#725bb5' : 'black'
                    }
                  : {}
              }
              onMouseEnter={() => columnIdx && setHoveredCell(cellId)}
              onMouseLeave={() => columnIdx && setHoveredCell(null)}
              onClick={() =>
                columnIdx && handleTableDataClick(text, record, columnIdx)
              }
            >
              {text}
              {header === 'Vendor' && record.perm_notes && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '0',
                    height: '0',
                    borderTop: '10px solid #6652a8',
                    borderLeft: '10px solid transparent',
                    borderRight: '0 solid transparent',
                    borderBottom: '0 solid transparent'
                  }}
                ></div>
              )}
            </div>
          )
        }
      }
    })

  if (apiTableColumns) {
    apiTableColumns.unshift({
      title: 'Action',
      key: 'action',
      width: 80,
      render: (text, record) => {
        const actionLabel = responseType ? capitalize(responseType) : ''

        return (
          <Dropdown
            overlay={
              <Menu
                items={ActionItems}
                onClick={({ key }) => handleMenuClick(key, record, actionLabel)}
              />
            }
            trigger={['click']}
          >
            <Button
              className={styles.primaryBtn}
              icon={<MoreOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} className ={styles.outlineBtn} />}
            />
          </Dropdown>
        )
      }
    })
  }

  const apiTableRows = Object.entries(data?.data?.data || {}).map(
    ([vendorName, vendorData]: [string, any]) => {
      const rowData = {
        key: vendorData.id,
        perm_notes: vendorData.perm_notes,
        0: vendorData.vendor_name,
        ...vendorData.data.reduce(
          (acc: { [key: number]: string }, val: any, idx: any) => {
            acc[idx + 1] = val
            return acc
          },
          {}
        )
      }
      return rowData
    }
  )

  const paginationConfig = useMemo<TablePaginationConfig>(() => {
    return {
      pageSize: data?.data?.per_page,
      total: data?.data?.total,
      showSizeChanger: false,
      current: data?.data?.current_page,
      defaultCurrent: 1,
      showTotal: (total: any, range: any) =>
        `Showing ${range[0]}-${range[1]} of ${total}`
    }
  }, [data?.data?.current_page, data?.data?.per_page, data?.data?.total])

  // ** Used to remove the saved state (checkbox) of the selected rows when the tab is changed **
  React.useEffect(() => {
    setSelectedRowKeys({})
    // eslint-disable-next-line
  }, [selectedItem])

  return (
    <div className='data-table' style={{ marginTop: 0 }}>
      <Skeleton
        data-testid='skeleton-analytics'
        active
        loading={isLoading || isRefetching}
        title={{ width: '100%' }}
        paragraph={{ rows: 15 }}
        style={{ padding: 20 }}
      >
        <VTable
          dataSource={apiTableRows as []}
          columns={apiTableColumns as []}
          pagination={paginationConfig}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          rowSelection={{ ...rowSelection }}
          loading={isLoading || isRefetching}
          className='table'
          scroll={{ x: 'max-content', y: 680 }}
        />
      </Skeleton>
    </div>
  )
}

export default AnalyticsTable
