import _ from 'underscore'

class ActionLogEnum {
  id: number // needs to update. not actual values
  label: string
  api_name: string

  constructor(id: number, label: string, api_name: string) {
    this.id = id
    this.label = label
    this.api_name = api_name
  }
}

const toReturn = {
  SUBMITTED: new ActionLogEnum(3, 'Submitted', 'submit'),
  QJE_CREATED: new ActionLogEnum(6, 'QJE Created', 'qje_created'),
  QJE_REJECTED: new ActionLogEnum(8, 'QJE Rejected', 'qje_rejected'),
  QJE_POSTED: new ActionLogEnum(9, 'QJE Posted', 'qje_posted'),
  QJE_ERROR: new ActionLogEnum(10, 'QJE Error', 'qje_error'),
  OVERRIDE: new ActionLogEnum(11, 'Override', 'override'),
  PASSBACK: new ActionLogEnum(12, 'Passback', 'passback'),
  NO_CHARGES: new ActionLogEnum(13, 'No Charges', 'no_charges'),
  NO_QJE: new ActionLogEnum(13, 'No QJE', 'no_qje')
}

const methods = {
  getLabelById: (id: number) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.id === id) {
        return true
      }
      return false
    })
    return found ? found.label : ''
  },
  getIdValueList: () => {
    let keyList: any = []
    Object.values(toReturn).forEach((o) => {
      keyList.push({ id: o.id, value: o.label })
    })
    return keyList
  },
  getLabelByApiName: (api_name: string) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.api_name === api_name) {
        return true
      }
      return false
    })
    return found ? found.label : api_name
  }
}

export default Object.freeze({ ...toReturn, ...methods })
