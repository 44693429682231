// ** AntD Imports
import { Button, Form, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'

// ** Hook Imports
import useApiService from '../hooks/useApiService'
import useResetPasswordMutation from '../hooks/useResetPasswordMutation'

// ** Style Imports
import styles from '../styles/resetpassword.module.css'

// =================================================================
const ResetPasswordModalForm = () => {
  // ** API Services **
  const { msUrl } = useApiService()

  // ** Hooks **
  const { handleSubmit, isLoading } = useResetPasswordMutation()

  return (
    <Form
      name='basic'
      initialValues={{ remember: true }}
      onFinish={(values) => {
        handleSubmit(values.username, msUrl)
      }}
      autoComplete='on'
      className={styles.formContainer}
    >
      <Form.Item
        name='username'
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input
          placeholder='Username'
          prefix={<UserOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} className='site-form-item-icon' />}
          className={styles.input}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className={styles.button}
          loading={isLoading}
        >
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordModalForm
