import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ReactDragListView from 'react-drag-listview'
import { ApiViewTableData } from '../types/api'

type Props = {
  columns: ColumnsType<any> | undefined
  data: ApiViewTableData[] | undefined
  isLoading: boolean
  isReorderMode: boolean
  reorderedData: ApiViewTableData[] | undefined
  setReorderedData: (data: ApiViewTableData[]) => void
}

const ViewsTable = (props: Props) => {
  const {
    columns,
    data,
    isLoading,
    isReorderMode,
    reorderedData,
    setReorderedData
  } = props

  const handleOnDragEnd = (fromIndex: number, toIndex: number) => {
    /**
     * ! BUG: There's a ghost/hidden row in the current table UI.
     * To work around this, decrease @param fromIndex & @param toIndex by 1
     */
    fromIndex--
    toIndex--

    const items = [...reorderedData!]
    const item = items.splice(fromIndex, 1)[0]
    items.splice(toIndex, 0, item)
    setReorderedData(items)
  }

  return (
    <ReactDragListView
      handleSelector='.drag-handle'
      onDragEnd={handleOnDragEnd}
    >
      <Table
        columns={columns}
        dataSource={isReorderMode ? reorderedData : data}
        rowKey='id'
        loading={isLoading}
        pagination={false}
        data-testid='tbl-review-center-views'
        scroll={{ y: 'calc(60vh - 50px)' }}
      />
    </ReactDragListView>
  )
}

export default ViewsTable
