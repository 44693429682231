export default function LoginHeader() {
  return (
    <>
      <img
        className='bg-img img img-responsive'
        src='/assets/images/MULTICITY-Laptop.jpeg'
        alt='gpfy-bg'
      />

      <div className='login-header'>
        <img src='./assets/images/gappify-logo-black.svg' alt='gpfy-logo' />
      </div>
    </>
  )
}
