/** antd imports */
import { DownOutlined } from '@ant-design/icons'
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'
import { Space, Tooltip } from 'antd'

/** react imports */
import { FC, useEffect, useState } from 'react'

/** services imports */
import { getAllTrdFilters } from '../../services/TrdManagerAPI'

/** custom components imports */
import GButton from '../gappify/GButton'
import GDropdownBtn from '../gappify/GDropdownBtn'
import GIcon from '../gappify/GIcon'
import TrdCreateNewFilterModal from '../txn-manager/TrdCreateNewFilterModal'

interface TrdLinesFilterProps {
  columns?: any
  externalOptions?: any
  internalOptions?: any
  activeFilterRef?: any
  reloadData?: any
  setIsLoading?: boolean
  setTransactionLines?: any
  optionsList?: any
}

// =================================================================
const TrdLinesFilter: FC<TrdLinesFilterProps> = ({
  columns,
  externalOptions,
  internalOptions,
  activeFilterRef,
  reloadData,
  setIsLoading,
  setTransactionLines,
  optionsList
}: TrdLinesFilterProps): JSX.Element => {
  /** react states */
  const [activeFilter, setActiveFilter] = useState(activeFilterRef.current)
  const [allFilters, setAllFilters] = useState<any>({})
  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [previewTriggeredFrom, setPreviewTriggeredFrom] = useState()
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)

  const callGetAllFilters = async () => {
    let fetchedData = {}

    await getAllTrdFilters().then((res) => {
      const response = res.data
      response.forEach((item: any) => {
        fetchedData = {
          ...fetchedData,
          [item.id]: item.name
        }
      })

      setAllFilters({ ...fetchedData, all: 'All Lines' })
    })
    return fetchedData
  }

  useEffect(() => {
    callGetAllFilters()
  }, [])

  const getFilterMenu = () => {
    return [
      ...[
        ...(allFilters &&
          Object.keys(allFilters)
            .filter(
              (filterKey) =>
                !['all', '100000', '100001', '100002', '100003'].includes(
                  filterKey
                )
            )
            .map((filterKey) => {
              return { key: filterKey, label: allFilters[filterKey] }
            }))
      ],
      {
        type: 'divider'
      },
      {
        key: 'all',
        label: 'All Lines'
      },
      {
        key: '100000',
        label: 'Lines with Amount Greater than 0.00'
      },
      {
        key: '100001',
        label: 'Rejected Lines'
      },
      {
        key: '100002',
        label: 'Overridden Lines'
      },
      {
        key: '100003',
        label: 'Lines with Errors'
      },

      {
        type: 'divider'
      },
      {
        key: 'new',
        label: (
          <div onClick={() => setIsVisibleCreateModal(true)}>
            Create New Filter
          </div>
        )
      }
    ]
  }

  const getFilterLabel = (filterKey: string) => {
    return allFilters[filterKey]
  }

  const handleSelectFilter = async (filter: any) => {
    if (filter.key !== 'new') {
      activeFilterRef.current = {
        id: filter.key,
        label: getFilterLabel(filter.key)
      }
      setActiveFilter({
        id: filter.key,
        label: getFilterLabel(filter.key)
      })

      // if (filter.key === 'all')
      await reloadData()
      setIsPreviewMode(false)
      setPreviewTriggeredFrom(() => undefined)
    }
  }

  const getFilterDropDownLabel = (text: string) => {
    const textLength: number = text.length

    if (textLength > 9) {
      return (
        <Tooltip placement='topLeft' title={text}>
          <span>{text.replace(/^(.{9}).{2,}/, '$1…')}</span>
        </Tooltip>
      )
    }

    return text
  }

  return (
    <>
      <Space data-testid='trd-lines-filter' data-cy='trd-lines-filter'>
        <GDropdownBtn
          dataTestId='trd-lines-filter-dropdown'
          dataCy='trd-lines-filter-dropdown'
          menuItems={getFilterMenu()}
          btnText={getFilterDropDownLabel(activeFilter.label)}
          children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
          onClick={handleSelectFilter}
          className='g-btn-default'
          menuClassName='filter-dp'
          // setIsLoading={setIsLoading}
          // callGetAllFilters={callGetAllFilters}
        />

        {!['all', '100000', '100001', '100002', '100003'].includes(
          activeFilter.id
        ) ? (
          isPreviewMode && previewTriggeredFrom === 'new' ? (
            <GButton
              dataTestId='trd-lines-filter-preview-btn'
              dataCy='trd-lines-filter-preview-btn'
              type='text'
              className='g-btn-icon'
              icon={<GIcon icon={faEye} />}
              onClick={() => setIsVisibleCreateModal(true)}
            />
          ) : (
            <TrdCreateNewFilterModal
              overrideLabel={
                <GButton
                  dataTestId='trd-lines-filter-preview-btn'
                  dataCy='trd-lines-filter-preview-btn'
                  type='text'
                  className='g-btn-icon'
                  icon={
                    <GIcon
                      icon={
                        isPreviewMode && previewTriggeredFrom === 'preview'
                          ? faEye
                          : faPencil
                      }
                    />
                  }
                />
              }
              overrideHeader={'Update Filter'}
              overrideFinishLabel={'Update View'}
              filterId={activeFilter.id}
              callGetAllFilters={callGetAllFilters}
              activeFilterRef={activeFilterRef}
              getFilterLabel={getFilterLabel}
              setActiveFilter={setActiveFilter}
              reloadData={reloadData}
              setIsPreviewMode={setIsPreviewMode}
              setPreviewTriggeredFrom={setPreviewTriggeredFrom}
              setTransactionLines={setTransactionLines}
              optionsList={optionsList}
            />
          )
        ) : (
          <></>
        )}
      </Space>
      <TrdCreateNewFilterModal
        overrideLabel={<></>}
        callGetAllFilters={callGetAllFilters}
        activeFilterRef={activeFilterRef}
        getFilterLabel={getFilterLabel}
        setActiveFilter={setActiveFilter}
        reloadData={reloadData}
        setIsPreviewMode={setIsPreviewMode}
        setPreviewTriggeredFrom={setPreviewTriggeredFrom}
        overrideIsVisibleModal={isVisibleCreateModal}
        overrideSetIsVisibleModal={setIsVisibleCreateModal}
        setTransactionLines={setTransactionLines}
        optionsList={optionsList}
      />
    </>
  )
}

export default TrdLinesFilter
