import { FC, useState, SetStateAction, useEffect } from 'react'
import { Modal, Form, Input, Checkbox, Select, message } from 'antd'
import type { AxiosError } from 'axios'
import {
  createAccrualRules,
  updateAccrualRules,
  getAccrualRulesById,
  getSourceCoaOptionsWithInactive
} from '../../../../../services/TaskManagerApi'
import {
  getSourceVendorOptions,
  getFormsOptions
} from '../../../../../services/baseAPI'
import { getPredefinedOptions } from '../../../../../utils/task-manager/getPredefinedOptions'

// ** Zustand Store Imports
import { useGlobalStore } from '../../../../../global/global-store'
import useAccrualRulesStore from '../manager/useAccrualRulesStore'

// ** Custom Hooks Imports
import useFetchSourceCoasDropdownList from '../../../../../hooks/useFetchSourceCoasDropdownList'

// ** Custom Types Imports
import SourceCoa from '../../../../../types/SourceCoa'

// ** Custom Util Imports
import filterOption from '../../../../../utils/filterOption'

const { Option } = Select

interface AutomationRulesModalProps {
  visible: boolean
  onCancel?: () => void
  isEditRules: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  loadDataSource: (() => Promise<void>) | (() => void)
  editRecordId?: number | undefined
  setEditRecord: any
  tabsKey: string
}

const AutomationRulesModal: FC<AutomationRulesModalProps> = ({
  visible,
  onCancel,
  isEditRules,
  setModalVisible,
  loadDataSource,
  editRecordId,
  setEditRecord,
  tabsKey
}: AutomationRulesModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [fieldOption, setFieldOption] = useState()
  const [criteriaOptions, setCriteriaOptions] = useState([])
  const [formList, setFormList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showPrepaid] = useState(false)

  // ** Zustand Store
  const selectedCoaType = useGlobalStore((state) => state.selectedCoaType)
  const activeGlAccountOptions = useGlobalStore(
    (state) => state.activeGlAccountOptions
  )
  const setSelectedCoaType = useGlobalStore((state) => state.setSelectedCoaType)

  // ** ToDo move existing states/actions to Zustand store
  const prepaidAccountId = useAccrualRulesStore(
    (state) => state.prepaidAccountId
  )

  const setPrepaidAccountId = useAccrualRulesStore(
    (state) => state.setPrepaidAccountId
  )

  useEffect(() => {
    // ** Check if selectedActiveCoaType and activeGlAccountOptions are empty
    if (!selectedCoaType && !activeGlAccountOptions.length) {
      setSelectedCoaType('source_coas.glaccount')
    }
  }, [selectedCoaType, activeGlAccountOptions, setSelectedCoaType])

  const { getSourceCoas } = useFetchSourceCoasDropdownList()

  const { data: sourceCoaDropdownList, isLoading: isSourceCoaDataFetched } =
    getSourceCoas

  /* Case Insensitive Sorting */
  const sortAsc = (optionA: any, optionB: any) => {
    if (optionA.value && optionB.value) {
      return optionA.value
        .toLowerCase()
        .localeCompare(optionB.value.toLowerCase())
    } else {
      return 0
    }
  }

  const handleSaveRules = async (value: any) => {
    let payload = {
      rules: {
        criteria: {
          field: value.field,
          operator: value.operator,
          values: value.values
        },
        internal_accrual: value.internal_accrual,
        external_accrual: value.external_accrual,
        calculation: value.calculation
      },
      name: value.name,
      status: value.status ? 1 : 0,
      calculated_form_id: 15,
      external_form_id: value.external_form_id,
      internal_form_id: value.internal_form_id,
      default_type: tabsKey,
      active: value.active,
      prepaid_account_id: value.prepaid_account_id
    }

    try {
      let response: any
      if (editRecordId) {
        response = await updateAccrualRules(editRecordId!, payload)
      } else {
        response = await createAccrualRules(payload)
      }

      if (response) {
        if (editRecordId) {
          message.success('Successfully updated!')
          setEditRecord(undefined)
        } else {
          message.success('Successfully created!')
        }
        setModalVisible(false)
        setCriteriaOptions([])
        loadDataSource()
        form.resetFields()
      }
    } catch (error) {
      const { response } = error as AxiosError
      const errMsg: any = response?.data
      if (errMsg.errors) {
        if (errMsg.errors[Object.keys(errMsg.errors)[0]]) {
          message.error(errMsg.errors[Object.keys(errMsg.errors)[0]])
        } else {
          message.error(errMsg.message)
        }
      } else {
        message.error('Unable to create rule.')
      }
      console.log(response)
    }
  }

  const handleOnChangeField = (item: any) => {
    setFieldOption(item)
    setCriteriaOptions([])
    form.setFieldValue('values', [])
  }

  const handleClickOptions = async () => {
    if (criteriaOptions.length === 0) {
      setIsLoading(true)
      if (fieldOption) {
        getFieldOptions(fieldOption)
      }
    }
  }

  const getFieldOptions = async (fieldOption: string) => {
    try {
      let response: any
      if (fieldOption === 'Vendor') {
        response = await getSourceVendorOptions()
      } else {
        response = await getSourceCoaOptionsWithInactive(fieldOption)
      }

      if (response && response.data) {
        if (response.data[fieldOption]) {
          setCriteriaOptions(response.data[fieldOption])
        } else {
          setCriteriaOptions(response.data)
        }
        setIsLoading(false)
      } else {
        setCriteriaOptions([])
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // remove delayed in updating form
    if (editRecordId) {
      getAccrualRulesById(editRecordId)
        .then((response: any) => {
          form.setFieldsValue(response.data)
          form.setFieldsValue(response.data.rules)

          if (criteriaOptions.length === 0) {
            // for initial load of edit modal only
            form.setFieldsValue(response.data.rules.criteria)
            setFieldOption(response.data.rules.criteria['field'])
            getFieldOptions(response.data.rules.criteria['field'])
          }

          response.data.prepaid_account_id &&
            setPrepaidAccountId(response.data.prepaid_account_id)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      form.resetFields()
    }
    //eslint-disable-next-line
  }, [editRecordId, isEditRules])

  useEffect(() => {
    if (visible && formList.length === 0) {
      const getFormList = async () => {
        try {
          const response = await getFormsOptions()

          if (response && response.data) {
            setFormList(response.data.sort(sortAsc))
          }
        } catch (err) {
          console.log(err)
        }
      }
      getFormList()
    }
  }, [visible, formList])

  return (
    <Modal
      data-testid='accural-rules-create-modal'
      data-cy='accural-rules-create-modal'
      open={visible}
      onOk={form.submit}
      title={`${editRecordId ? 'Edit' : 'Create'} ${
        tabsKey === 'po' ? 'PO' : 'Vendor'
      } Rule`}
      width={800}
      okText={editRecordId ? 'Update' : 'Save'}
      onCancel={() => {
        setPrepaidAccountId(null)
        setCriteriaOptions([])
        form.resetFields()
        onCancel && onCancel()
      }}
      className='aut-rules-modal'
    >
      <Form
        data-testid='accural-rules-create-form'
        data-cy='accural-rules-create-form'
        layout='horizontal'
        form={form}
        onFinish={handleSaveRules}
      >
        <Form.Item
          data-testid='accural-rules-name-input'
          data-cy='accural-rules-name-input'
          label='Rule Name'
          name='name'
          rules={[
            {
              required: true,
              message: `This is a required field.`
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          data-testid='accural-rules-status-checkbox'
          data-cy='accural-rules-status-checkbox'
          label='Rule Active'
          name='status'
          valuePropName='checked'
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>
        <div className='criteria-label'>Rule Criteria</div>
        <div className='criteria-box'>
          <div className='criteria-label'>IF</div>
          <Form.Item
            data-testid='accural-rules-field-select'
            data-cy='accural-rules-field-select'
            name='field'
            style={{ width: '90%' }}
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              placeholder='Please select'
              options={[
                { key: 'Vendor', value: 'Vendor' },
                { key: 'Subsidiary', value: 'Subsidiary' },
                { key: 'Department', value: 'Department' },
                { key: 'GLAccount', value: 'GLAccount' }
              ]}
              onChange={handleOnChangeField}
            />
          </Form.Item>
          <Form.Item
            data-testid='accural-rules-operator-select'
            data-cy='accural-rules-operator-select'
            name='operator'
            style={{ width: '90%' }}
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              placeholder='Please select'
              options={[
                { key: 'IN', value: 'IN' },
                { key: 'NOT IN', value: 'NOT IN' }
              ]}
            />
          </Form.Item>
          <Form.Item
            data-testid='accural-rules-values-select'
            data-cy='accural-rules-values-select'
            name='values'
            style={{ width: '90%' }}
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              mode='multiple'
              placeholder='Please select'
              onClick={handleClickOptions}
              optionFilterProp='children'
            >
              {isLoading ? (
                <Option disabled>Loading options...</Option>
              ) : (
                criteriaOptions &&
                criteriaOptions.map((item: any) => {
                  return (
                    <Option
                      key={
                        item.g_source_system_id
                          ? item.g_source_system_id
                          : item.id
                      }
                      value={
                        item.g_source_system_id
                          ? item.g_source_system_id
                          : item.id
                      }
                    >
                      {item.value}
                    </Option>
                  )
                })
              )}
            </Select>
          </Form.Item>
        </div>
        <div className='criteria-label'>Set Values</div>
        <div className='criteria-box set-values-box'>
          <Form.Item
            data-testid='accural-rules-internal-select'
            data-cy='accural-rules-internal-select'
            label='Internal Accrual'
            name='internal_accrual'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              placeholder='Please select'
              options={getPredefinedOptions('internal_accrual')?.filter(
                (item) =>
                  tabsKey === 'vendor'
                    ? item.label !== 'New' && item.label !== '% Complete'
                    : item.label !== 'New'
              )}
            />
          </Form.Item>
          <Form.Item
            data-testid='accural-rules-external-select'
            data-cy='accural-rules-external-select'
            label='External Accrual'
            name='external_accrual'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              placeholder='Please select'
              options={getPredefinedOptions('external_accrual')?.filter(
                (item) =>
                  tabsKey === 'vendor'
                    ? item.label !== 'New' && item.label !== '% Complete'
                    : item.label !== 'New'
              )}
            />
          </Form.Item>
          <Form.Item
            label='Calculation'
            name='calculation'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select
              placeholder='Please select'
              options={getPredefinedOptions('calculation')?.filter((item) =>
                tabsKey === 'vendor'
                  ? item.label !== 'New' && item.label !== 'Straightline'
                  : item.label !== 'New' && item.label !== 'Historical Average'
              )}
            />
          </Form.Item>
          <Form.Item
            data-testid='accural-rules-internal-select'
            data-cy='accural-rules-internal-select'
            label='Internal Form'
            name='internal_form_id'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select key={'key'} placeholder='Please select'>
              {formList ? (
                <>
                  {formList.map((item: any) => {
                    let option
                    if (item.app.app_key === 'Internal') {
                      option = (
                        <Option key={item.id} value={item.id}>
                          {item.value}
                        </Option>
                      )
                    }
                    return option
                  })}
                </>
              ) : (
                <Option disabled>Loading options...</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            data-testid='accural-rules-external-select'
            data-cy='accural-rules-external-select'
            label='External Form'
            name='external_form_id'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
          >
            <Select placeholder='Please select'>
              {formList ? (
                <>
                  {formList.map((item: any) => {
                    let option
                    if (item.app.app_key === 'External') {
                      option = (
                        <Option key={item.id} value={item.id}>
                          {item.value}
                        </Option>
                      )
                    }
                    return option
                  })}
                </>
              ) : (
                <Option disabled>Loading options...</Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            data-testid='accural-rules-active-select'
            data-cy='accural-rules-active-select'
            label='Active'
            name='active'
            rules={[
              {
                required: true,
                message: `This is a required field.`
              }
            ]}
            initialValue={1}
          >
            <Select
              placeholder='Please select'
              options={getPredefinedOptions('active')}
            ></Select>
          </Form.Item>
          {showPrepaid && (
            <Form.Item
              data-testid='accural-rules-prepaid-select'
              data-cy='accural-rules-prepaid-select'
              label='Prepaid Account'
              name='prepaid_account_id'
              rules={[{ required: false }]}
            >
              <Select
                placeholder='Please select'
                filterOption={filterOption}
                value={prepaidAccountId}
                listHeight={120}
                showSearch
                allowClear
              >
                {isSourceCoaDataFetched || !sourceCoaDropdownList?.length ? (
                  <Option disabled>Loading options...</Option>
                ) : (
                  sourceCoaDropdownList.map((item: SourceCoa) => (
                    <Option
                      key={item.g_source_system_id}
                      value={item.g_source_system_id}
                    >
                      {item.value}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  )
}

export default AutomationRulesModal

AutomationRulesModal.defaultProps = {
  visible: false
}
