/** zustand imports */
import { create } from 'zustand'

/** types imports */
import IFormItem from '../types/IFormItem'

interface StoreProps {
  /** states */
  formItems: IFormItem[]
  selectedInternalAttributes: string[]

  /** actions */
  setFormItems: (formItems: IFormItem[]) => void
  setSelectedInternalAttributes: (selectedInternalAttributes: string[]) => void
  reset: () => void
}

const initialState: Pick<
  StoreProps,
  'formItems' | 'selectedInternalAttributes'
> = {
  formItems: [],
  selectedInternalAttributes: []
}

// =================================================================
const useDimentionFieldsStore = create<StoreProps>((set) => ({
  /** states */
  ...initialState,

  /** actions */
  setFormItems: (formItems: IFormItem[]) => set({ formItems }),
  setSelectedInternalAttributes: (selectedInternalAttributes: string[]) =>
    set({ selectedInternalAttributes }),
  reset: () => set(initialState)
}))

export default useDimentionFieldsStore
