// ** AntD Imports
import { ButtonProps } from 'antd'

// ** Types Imports
import { TFilter } from '../types/Filter'

export const DEFAULT_FILTER: TFilter = {
  id: 0,
  name: 'All lines'
}

export const DEFAULT_FILTER_IDS = [100000, 100001, 100002, 100003]

export const INPUT_MAX_LENGTH = 30

export const DEFAULT_BTN_PROPS: ButtonProps = {
  shape: 'round',
  type: 'primary'
}

export enum DEFAULT_FILTER_TABS {
  CRITERIA = 'Criteria',
  COLUMN = 'Column'
}

export const OPTIONS_OPERATOR = [
  {
    label: 'Equals',
    value: '='
  },
  {
    label: 'Not equals',
    value: '!='
  },
  {
    label: 'Is greater than',
    value: '>'
  },
  {
    label: 'Is greater than or equals',
    value: '>='
  },
  {
    label: 'Is less than',
    value: '<'
  },
  {
    label: 'Is less than or equals',
    value: '<='
  }
]

export const OPTIONS_CHECKBOX = [
  {
    label: 'Yes',
    value: 1
  },
  {
    label: 'No',
    value: 0
  }
]

export enum MODAL_MODE {
  CREATE = 'Create',
  UPDATE = 'Update',
  PREVIEW = 'Preview'
}
