/** antd imports */
import { Modal } from 'antd'

/** zustand store imports */
import useLogRecordModalStore from '../stores/useLogRecordModalStore'

// =================================================================
export default function LogRecordModal() {
  /** zustand store */
  const openModal = useLogRecordModalStore((state) => state.openModal)
  const record = useLogRecordModalStore((state) => state.record)
  // const record_message = useLogRecordModalStore((state) => state.record_message)
  const setOpenModal = useLogRecordModalStore((state) => state.setOpenModal)

  return (
    <Modal
      data-testid='log-record-modal'
      open={openModal}
      onCancel={() => setOpenModal(false)}
      title='Log Record'
      cancelButtonProps={{
        hidden: true
      }}
      okButtonProps={{
        hidden: true
      }}
      footer={null}
      destroyOnClose
      width={700}
    >
      <pre>{record && record}</pre>
      {/* <pre>{record_message && JSON.parse(JSON.stringify(record_message))}</pre> */}
    </Modal>
  )
}
