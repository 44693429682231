// ** Third Party Imports
import { Row, Col } from 'antd'
import { DownOutlined, ExportOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import GDropdownBtn from '../../../../components/gappify/GDropdownBtn'
import LoginAuditTrailTable from './components/Table'

// ** Hooks Imports
import useExport from './hooks/useExport'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { RootState } from '../../../../context/store'

// =================================================================

const LoginAuditTrail = () => {
  // ** Hooks **
  const { getExportMenu, handleExportClick } = useExport()

  // ** Redux **
  const { exportIsLoading } = useSelector(
    (state: RootState) => state.loginAuditTrail
  )

  return (
    <div className='table-content-wrapper'>
      <Row>
        <Col span={24} className='login-audit-trail-export-wrapper'>
          <GDropdownBtn
            dataTestId='global-filter-panel-export-dropdown-btn'
            dataCy='global-filter-panel-export-dropdown-btn'
            menuItems={getExportMenu()}
            btnText='Export to'
            btnIcon={<ExportOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            className='g-btn-default'
            onClick={handleExportClick}
            loading={exportIsLoading}
          />
        </Col>
      </Row>
      <LoginAuditTrailTable />
    </div>
  )
}

export default LoginAuditTrail
