// ** Custom Components **
import PostJEModal from './PostJeModal'
import PostJEModalQ4 from './PostJeModal_q4'

// ** Feature Flag **
import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

// =======================================================================
const PostJeModalWrapper = () => {
  const isFlagEnabled = useIsFlagEnabled()

  // Using Flag component...
  // <Flag
  //   authorizedFlags={[FEATURE_FLAGS.edinburgh_period_management]}
  //   renderOff={<PostJEModal />}
  //   renderOn={<PostJEModalQ4 />}
  // />

  return isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management) ? (
    <PostJEModalQ4 />
  ) : (
    <PostJEModal />
  )
}

export default PostJeModalWrapper
