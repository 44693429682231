/** antd imports */
import { Form, Input, Modal, Select } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'
import CreateApiTokenPayload from '../types/CreateApiTokenPayload'
import moment from 'moment'
import useCreateTokenApi from '../hooks/useCreateTokenApi'

// =================================================================
export default function GenerateApiKeyModal() {
  /** antd */
  const [form] = Form.useForm()

  /** zustand states */
  const { isGenerateApiKeyModalOpen, setIsGenerateApiKeyModalOpen } =
    useApiKeyStore()

  /** hooks */
  const { handleSubmit, isLoading } = useCreateTokenApi()

  /** component functions */
  const handleFormSubmit = (values: CreateApiTokenPayload) => {
    let payload: CreateApiTokenPayload

    const addMonths = (date: Date, months: number) => {
      date.setMonth(date.getMonth() + months)

      if (months < 24) {
        date.setDate(date.getDate() + 1)
      }

      return date
    }

    switch (values.future_date) {
      case '3 months':
        payload = {
          future_date: moment(addMonths(new Date(), 3).toString()).format(
            'YYYY-MM-DD hh:mm:ss'
          ),
          description: values.description
        }
        break
      case '1 year':
        payload = {
          future_date: moment(addMonths(new Date(), 12).toString()).format(
            'YYYY-MM-DD hh:mm:ss'
          ),
          description: values.description
        }
        break
      case '2 years':
        payload = {
          future_date: moment(addMonths(new Date(), 24).toString()).format(
            'YYYY-MM-DD hh:mm:ss'
          ),
          description: values.description
        }
        break
      // case 'never expire':
      //   payload = {
      //     never_expire: true,
      //     description: values.description
      //   }
      //   break
      default:
        payload = {
          future_date: moment(addMonths(new Date(), 1).toString()).format(
            'YYYY-MM-DD hh:mm:ss'
          ),
          description: values.description
        }
        break
    }

    handleSubmit(payload)
  }

  /** react sideeffects */
  useEffect(() => {
    if (isGenerateApiKeyModalOpen) {
      form.resetFields()
    }
  }, [form, isGenerateApiKeyModalOpen])

  return (
    <Modal
      data-testid='generate-api-key-modal'
      data-cy='generate-api-key-modal'
      title='Generate API Key'
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round',
        htmlType: 'submit',
        loading: isLoading,
        disabled: isLoading
      }}
      okText='Generate'
      onOk={form.submit}
      onCancel={() => setIsGenerateApiKeyModalOpen(false)}
      open={isGenerateApiKeyModalOpen}
      closable={false}
      destroyOnClose
    >
      <p>
        Generate a new API access token to securely use Gappify's API. Please
        provide the following information to create your token.
      </p>
      <Form form={form} layout='vertical' onFinish={handleFormSubmit}>
        <Form.Item
          label='Descriptive name'
          name='description'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input />
        </Form.Item>
        <p style={{ marginBottom: '20px', marginTop: '-20px' }}>
          <em>
            Providing a descriptive name will help you identify you API key
            later.
          </em>
        </p>
        <Form.Item label='Valid until' name='future_date'>
          <Select
            placeholder='Select an option'
            options={[
              {
                label: '1 month',
                value: '1 month'
              },
              {
                label: '3 months',
                value: '3 months'
              },
              {
                label: '1 year',
                value: '1 year'
              },
              {
                label: '2 years',
                value: '2 years'
              }
              // {
              //   label: 'Never expire',
              //   value: 'never expire'
              // }
            ]}
            allowClear
          />
        </Form.Item>
        <p style={{ marginBottom: '20px', marginTop: '-20px' }}>
          <em>Defaults to 1 month if no option is selected</em>
        </p>
      </Form>
    </Modal>
  )
}
