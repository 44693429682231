import { FC } from 'react'
import { Result, Modal } from 'antd'
import GButton from './GButton'

const GResultModal: FC<any> = ({
  onClickOk,
  status,
  title,
  subTitle,
  visible,
  height,
  width
}): JSX.Element => {
  return (
    <Modal
      width={width}
      bodyStyle={{ height: height }}
      footer={null}
      closable={false}
      open={visible}
    >
      <Result title={title} status={status} subTitle={subTitle} />
      <div className='result-modal-btn'>
        <GButton btnText='OK' type='primary' onClick={onClickOk} />
      </div>
    </Modal>
  )
}

GResultModal.defaultProps = {
  height: 350,
  width: 450
}

export default GResultModal
