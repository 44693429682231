import { create } from 'zustand'

interface ResultModalStoreProps {
  /** state */
  showModal: boolean

  /** actions */
  setShowModal: (showModal: boolean) => void
}

const useResultModalStore = create<ResultModalStoreProps>((set) => ({
  /** state */
  showModal: false,

  /** actions */
  setShowModal: (showModal: boolean) => set({ showModal })
}))

export default useResultModalStore
