import { MenuOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { SetStateAction } from 'react'
import TransactionManagerColumn from '../../../types/TransactionManagerColumn'
import handleOnChangeLabelFieldColumn from './handleOnChangeLabelFieldColumn'
import renderColumnValue from './renderColumnValue'

export default function getTransactionColumnManagerColumns(
  canEdit: boolean,
  searchValue: string,
  dataSource: TransactionManagerColumn[],
  setDataSource: (value: SetStateAction<TransactionManagerColumn[]>) => void
): ColumnsType<TransactionManagerColumn> {
  return [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      render: (value, record) => {
        if (canEdit && !searchValue) {
          return (
            <MenuOutlined
              onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
              data-testid={`transaction-column-manager-table-grab-handler-${record.id}`}
              data-cy={`transaction-column-manager-table-grab-handler-${record.id}`}
              style={{ cursor: 'grab', color: '#999' }}
            />
          )
        }
        return
      }
    },
    {
      title: 'Field API Name',
      dataIndex: 'api_name',
      render: (value, record) => (
        <span
          data-testid={`transaction-column-manager-table-field-api-name-${record.id}`}
          data-cy={`transaction-column-manager-table-field-api-name-${record.id}`}
        >
          {value}
        </span>
      )
    },
    {
      title: 'Field label',
      dataIndex: 'label',
      render: (value: string, record) => {
        if (!canEdit) {
          return value
        }
        return (
          <Input
            data-testid={`transaction-column-manager-table-input-field-${record.id}`}
            data-cy={`transaction-column-manager-table-input-field-${record.id}`}
            value={value}
            onChange={handleOnChangeLabelFieldColumn(
              record.id,
              dataSource,
              setDataSource
            )}
          />
        )
      }
    },
    {
      title: 'Show',
      align: 'center',
      render: (value: any, record) =>
        renderColumnValue(
          canEdit,
          record.properties.show,
          record.id,
          'show',
          dataSource,
          setDataSource
        )
    },
    {
      title: 'Read-only',
      align: 'center',
      render: (value: any, record) =>
        renderColumnValue(
          canEdit,
          record.properties.read_only,
          record.id,
          'read_only',
          dataSource,
          setDataSource
        )
    }
    // ** Commenting this column to hide **
    // {
    //   title: 'Default Column',
    //   align: 'center',
    //   render: (value: any, record) =>
    //     renderColumnValue(
    //       canEdit,
    //       record.properties.default_column,
    //       record.id,
    //       'default_column',
    //       dataSource,
    //       setDataSource
    //     )
    // }
  ]
}
