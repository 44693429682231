/** type imports */
import TransactionField from '../types/TransactionField'

// =================================================================
export default function useGetTRDSearchableFields() {
  const getSearchableFields = (columnHeaders: TransactionField[]): string => {
    const vendorName = columnHeaders.find((item) => {
      return item.api_name === 'g_name'
    })?.label
    const department = columnHeaders.find((item) => {
      return item.api_name === 'g_department_debit'
    })?.label
    const GLAccount = columnHeaders.find((item) => {
      return item.api_name === 'g_glaccount_debit'
    })?.label
    const PONumber = columnHeaders.find((item) => {
      return item.api_name === 'g_po_number'
    })?.label
    const subsidiary = columnHeaders.find((item) => {
      return item.api_name === 'g_subsidiary'
    })?.label
    const email = columnHeaders.find((item) => {
      return item.api_name === 'g_email'
    })?.label

    const searchableFields = `${vendorName}, ${department}, ${GLAccount}, ${PONumber}, ${subsidiary}, ${email}`

    return searchableFields
      .replaceAll('undefined,', '')
      .replaceAll('undefined', '')
  }

  return {
    getSearchableFields
  }
}
