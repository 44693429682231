import { create } from 'zustand'

// ** Types Imports
import PeriodManagementProps from '../types/PeriodManagementProps'

// =================================================================
export default create<PeriodManagementProps>((set) => ({
  period: '',

  setPeriod: (value: string | null) => set({ period: value })
}))
