import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from 'antd'
import { SetStateAction } from 'react'
import GIcon from '../../../components/gappify/GIcon'
import TransactionManagerColumn from '../../../types/TransactionManagerColumn'
import handleOnChangeCheckbox from './handleOnChangeCheckbox'

export default function renderColumnValue(
  canEdit: boolean,
  value: boolean | string,
  recordId: number,
  recordProperty: 'show' | 'read_only' | 'default_column',
  dataSource: TransactionManagerColumn[],
  setDataSource: (value: SetStateAction<TransactionManagerColumn[]>) => void
): JSX.Element {
  if (canEdit)
    return (
      <Checkbox
        data-testid={`transaction-column-manager-table-${recordProperty}-checkbox-${recordId}`}
        data-cy={`transaction-column-manager-table-${recordProperty}-checkbox-${recordId}`}
        checked={Boolean(value)}
        onChange={handleOnChangeCheckbox(
          recordId,
          recordProperty,
          dataSource,
          setDataSource
        )}
        disabled={value === 'permanent'}
      />
    )
  if (value)
    return (
      <GIcon
        dataTestId={`transaction-column-manager-table-${recordProperty}-checkmark-${recordId}`}
        dataCy={`transaction-column-manager-table-${recordProperty}-checkmark-${recordId}`}
        icon={faCheck}
        className='g-icon-primary'
      />
    )
  return <></>
}
