// ** React Imports
import React from 'react'

// ** Antd Imports
import { Form, Input } from 'antd'

// ** Custom Component Imports
import GButton from '../../../../components/gappify/GButton'
import PermNotesActions from '../../components/PermNotesActions'

// ** Hook Imports
import useAnalytics from '../../hooks/useAnalytics'
import useApiService from '../../hooks/useApiService'

// ** Third Party Imports
import moment from 'moment'
import { useQueryClient } from '@tanstack/react-query'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// ** Types
interface DataType {
  // Define data structure here
  key: string
  vendor_id: string
  id: string
}

// =================================================================
export default function usePermNotesData() {
  // ** AntD **
  const { TextArea } = Input

  // ** State **
  const [tempNotes, setTempNotes] = React.useState<string | null>(null)

  // ** Store **
  const {
    permNoteVendorId,
    permNotesEditingKey,
    setPermNotesEditingKey,
    responseType,
    permNotesErrorMsg,
    setPermNotesErrorMsg
  } = useAnalyticsStore()

  const { msUrl } = useApiService()
  const { modifyPermNote } = useAnalytics({ responseType: responseType })

  // ** TanStack Query **
  const queryClient = useQueryClient()
  const permNotes: Record<string, []> =
    queryClient.getQueryData([`perm-notes`, permNoteVendorId]) || {}

  const columns = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      width: '150px'
    },
    {
      title: 'Date/Time',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '150px'
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '150px'
    },
    {
      title: 'Report',
      dataIndex: 'report',
      key: 'report',
      width: '150px'
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
      width: '300px',
      render: (text: string, record: DataType, rowIndex: number) => {
        // console.log(`record`, record)
        const isEditing = permNotesEditingKey === record.key
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '300px'
            }}
          >
            {isEditing ? (
              <>
                <Form.Item style={{ margin: 0 }}>
                  <TextArea
                    defaultValue={text}
                    rows={4}
                    onChange={(e) => setTempNotes(e.target.value)}
                  />
                </Form.Item>
                <p style={{ color: 'red', margin: 0, padding: 0 }}>
                  {permNotesErrorMsg}
                </p>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    justifyContent: 'flex-end',
                    marginTop: 5
                  }}
                >
                  <GButton
                    style={{ width: 80 }}
                    onClick={() => {
                      setPermNotesEditingKey(null)
                      setPermNotesErrorMsg(null)
                    }}
                  >
                    Cancel
                  </GButton>
                  <GButton
                    style={{ width: 80 }}
                    onClick={() => {
                      console.log(`save`, {
                        note: tempNotes
                      })
                      modifyPermNote({
                        data: {
                          note: tempNotes as string
                        },
                        options: {
                          baseURL: msUrl.analytics,
                          endpoint: `api/update/perm-note/${record.id}`,
                          method: 'PATCH'
                        }
                      })
                    }}
                  >
                    Save
                  </GButton>
                </div>
              </>
            ) : (
              <span style={{ wordWrap: 'break-word' }}>{text}</span>
            )}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: '50px',
      render: (text: string, record: DataType) => (
        <PermNotesActions
          record={record as any}
          actionLabel='Perm Notes Actions'
        />
      )
    }
  ]

  const rowsFromBackend =
    permNotes?.data?.map((item: Record<string, string>, index) => ({
      key: (index + 1).toString(),
      id: item.id,
      name: item.name,
      created_at: moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a'),
      updated_at: moment(item.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
      report: item.report,
      note: item.note,
      vendor_id: item.vendor_id,
      user_id: item.user_id
    })) || []

  // ** Used for Mock Data Generation **
  const createRows = (numRows: number) => {
    const baseRow = {
      user: 'Gappify QACS',
      date: '2023-06-01 09:51AM',
      report: 'atlassian@gappify.com',
      notes: 'System Administrator'
    }

    const users = ['Atlassian', 'Reddit', 'Stripe', 'Glassdoor']

    const reports = [
      'atlassian@gappify.com',
      'stripe@gmail.com',
      'reddit@gmail.com',
      'glassdoor@gmail.com'
    ]

    const notes = [
      'System Administrator',
      'Developer',
      'Operator',
      'How many characters is two hundred and fifty-five characters. Will that be enough for the purpose of free text fields. To reach max How many characters is two hundred and fifty-five characters. Will that be enough for the purpose of free text fields. To reach max.',
      'Please note this vendor is in good standing and should not be reached out to.'
    ]

    return Array.from({ length: numRows }, (_, i) => {
      //   const randomDate = dates[Math.floor(Math.random() * dates.length)]
      const randomUsers = users[Math.floor(Math.random() * users.length)]
      const randomReports = reports[Math.floor(Math.random() * reports.length)]
      const randomNotes = notes[Math.floor(Math.random() * notes.length)]
      return {
        key: (i + 1).toString(),
        ...baseRow,
        // date: randomDate,
        date: (i + 1).toString(),
        user: randomUsers,
        report: randomReports,
        notes: randomNotes
      }
    })
  }
  const rows = createRows(200)

  return {
    rows,
    columns,
    rowsFromBackend
  }
}
