/** Andt imports */
import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Menu, MenuProps } from 'antd'
import type { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'

/** React imports */
import { FC, useEffect } from 'react'

/** React router imports */
import { Link, Path, useNavigate, useResolvedPath } from 'react-router-dom'

/** Custom components imports */
import GDropdown from '../components/gappify/GDropdown'

/** Helpers imports */
import { AUTH_TOKEN, ROLES_AND_PERMISSIONS } from '../helpers/constants/Auth'

/** Custom hooks imports */
import useCompanySettings from '../hooks/useCompanySettings'
import useLogout from '../hooks/useLogout'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'

/** zustand imports */
import useUploadFileModalStore from './UploadFileModal/manager/useUploadFileModalStore'
import useTxnGlobalSettingsStore from '../global/useTxnGlobalSettingsStore'

const DROPDOWN_KEYS = {
  UPLOAD_FILES: '0',
  LOGOUT: '1',
  SETTINGS: '2'
}

interface HeaderProps {
  onShowUploadModal?: () => void
}

// =================================================================
const Header: FC<HeaderProps> = ({
  onShowUploadModal
}: HeaderProps): JSX.Element => {
  const rp: any = localStorage.getItem(ROLES_AND_PERMISSIONS)
  const username = JSON.parse(rp) ? JSON.parse(rp).username : 'user'
  const resolvedPath: Path = useResolvedPath(window.location.pathname)
  const navigate = useNavigate()

  /** hooks */
  const { data, isSuccess } = useCompanySettings()
  const {
    refetch: logout,
    isFetching,
    isError: isLogoutError,
    isSuccess: isLogoutSuccess
  } = useLogout()
  const RP = useRolesAndPermissions()

  /** zustand store */
  const { setIsModalOpen } = useUploadFileModalStore()
  const { isConsolidatedTxnLines } = useTxnGlobalSettingsStore()

  useEffect(() => {
    if (isLogoutError || isLogoutSuccess) {
      localStorage.clear()
      sessionStorage.clear()
      // navigate('/login')
      window.location.href = '/login'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutError, isLogoutSuccess])

  const getHeaderTabs = () => {
    const headerMenuItems: MenuProps['items'] = []
    headerMenuItems.push({
      label: (
        <span
          style={
            !RP.USER_HAS_REVIEW_CENTER_TAB_PERMISSION ||
            !RP.USER_HAS_REVIEW_CENTER_GET_FILTER
              ? {
                  cursor: 'not-allowed'
                }
              : undefined
          }
        >
          <Link
            to='/review-center'
            style={
              !RP.USER_HAS_REVIEW_CENTER_TAB_PERMISSION ||
              !RP.USER_HAS_REVIEW_CENTER_GET_FILTER
                ? {
                    pointerEvents: 'none'
                  }
                : undefined
            }
          >
            Review Center
          </Link>
        </span>
      ),
      key: '/review-center',
      disabled:
        !RP.USER_HAS_REVIEW_CENTER_TAB_PERMISSION ||
        !RP.USER_HAS_REVIEW_CENTER_GET_FILTER
    })
    if (data.enable_new_transactions) {
      const CAN_VIEW_CONSOLIDATED_TXN = isConsolidatedTxnLines

      if (CAN_VIEW_CONSOLIDATED_TXN) {
        headerMenuItems.push({
          label: 'Transactions',
          className: resolvedPath.pathname.includes('rd')
            ? 'ant-menu-item-selected'
            : '',
          key: '/transactions-consolidated',
          disabled: !RP.USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION,
          children: [
            {
              label: (
                <Link
                  to='/transactions-consolidated'
                  style={
                    !RP.USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION
                      ? {
                          pointerEvents: 'none'
                        }
                      : undefined
                  }
                >
                  Header
                </Link>
              ),
              key: 'header'
            },
            {
              label: (
                <Link
                  to='/transactions-consolidated/lines'
                  style={
                    !RP.USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION
                      ? {
                          pointerEvents: 'none'
                        }
                      : undefined
                  }
                >
                  Lines
                </Link>
              ),
              key: 'lines'
            }
          ]
        })
      } else {
        headerMenuItems.push({
          label: 'Transactions',
          className: resolvedPath.pathname.includes('rd')
            ? 'ant-menu-item-selected'
            : '',
          key: '/transactions-rd',
          disabled: !RP.USER_HAS_TRANSACTION_TAB_PERMISSION,
          children: [
            {
              label: (
                <Link
                  to='/transactions-rd'
                  style={
                    !RP.USER_HAS_TRANSACTION_TAB_PERMISSION
                      ? {
                          pointerEvents: 'none'
                        }
                      : undefined
                  }
                >
                  Header
                </Link>
              ),
              key: 'header'
            },
            {
              label: (
                <Link
                  to='transactions-rd/lines'
                  style={
                    !RP.USER_HAS_TRANSACTION_TAB_PERMISSION
                      ? {
                          pointerEvents: 'none'
                        }
                      : undefined
                  }
                >
                  Lines
                </Link>
              ),
              key: 'lines'
            }
          ]
        })
      }
    } else {
      headerMenuItems.push({
        label: (
          <span
            style={
              !RP.USER_HAS_TRANSACTION_TAB_PERMISSION
                ? {
                    cursor: 'not-allowed'
                  }
                : undefined
            }
          >
            <Link
              to='/transactions'
              style={
                !RP.USER_HAS_TRANSACTION_TAB_PERMISSION
                  ? {
                      pointerEvents: 'none'
                    }
                  : undefined
              }
            >
              Transactions
            </Link>
          </span>
        ),
        className:
          resolvedPath.pathname === 'transactions'
            ? 'ant-menu-item-selected'
            : '',
        key: '/transactions',
        disabled: !RP.USER_HAS_TRANSACTION_TAB_PERMISSION
      })
    }
    headerMenuItems.push({
      label: (
        <span
          style={
            !RP.USER_HAS_QJE_TAB_PERMISSION
              ? {
                  cursor: 'not-allowed'
                }
              : undefined
          }
        >
          <Link
            to={'/qje2'}
            style={
              !RP.USER_HAS_QJE_TAB_PERMISSION
                ? {
                    pointerEvents: 'none'
                  }
                : undefined
            }
          >
            QJE's
          </Link>
        </span>
      ),
      className: resolvedPath.pathname.includes('qje')
        ? 'ant-menu-item-selected'
        : '',
      key: '/qje',
      disabled: !RP.USER_HAS_QJE_TAB_PERMISSION
    })

    if (data.display_task_tab) {
      headerMenuItems.push({
        label: (
          <span
            style={
              !RP.USER_HAS_TASK_MANAGER_TAB_PERMISSION
                ? {
                    cursor: 'not-allowed'
                  }
                : undefined
            }
            data-cy='accrual-manager-header'
          >
            <Link
              to='/task-manager'
              style={
                !RP.USER_HAS_TASK_MANAGER_TAB_PERMISSION
                  ? {
                      pointerEvents: 'none'
                    }
                  : undefined
              }
            >
              Accrual Manager
            </Link>
          </span>
        ),
        className: resolvedPath.pathname.includes('task-manager')
          ? 'ant-menu-item-selected'
          : '',
        key: '/task-manager',
        disabled: !RP.USER_HAS_TASK_MANAGER_TAB_PERMISSION
      })
    }

    if (data.display_advanced_analytics) {
      headerMenuItems.push({
        label: (
          <span
            style={
              !RP.USER_HAS_GLOBAL_AA_PERMISSION
                ? {
                    cursor: 'not-allowed'
                  }
                : undefined
            }
          >
            <Link
              to='/insights'
              style={
                !RP.USER_HAS_GLOBAL_AA_PERMISSION
                  ? {
                      pointerEvents: 'none'
                    }
                  : undefined
              }
            >
              Insights
            </Link>
          </span>
        ),
        key: '/insights',
        disabled: !RP.USER_HAS_GLOBAL_AA_PERMISSION
      })
    }

    return headerMenuItems
  }

  const getDefaultTab = () => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      if (
        !RP.USER_HAS_REVIEW_CENTER_TAB_PERMISSION ||
        !RP.USER_HAS_REVIEW_CENTER_GET_FILTER
      ) {
        if (!RP.USER_HAS_TASK_MANAGER_TAB_PERMISSION) {
          if (!RP.USER_HAS_TRANSACTION_TAB_PERMISSION) {
            if (!RP.USER_HAS_QJE_TAB_PERMISSION) {
              localStorage.removeItem(AUTH_TOKEN)
              window.location.href = `/error?message=Forbidden&status=403`
            }
            return ['/qje']
          }
          return ['/transactions']
        }
        return ['/task-manager']
      }
      return ['/review-center']
    } else {
      window.location.href = `/`
    }
  }

  const handleDropdownOnClick = (item: MenuItemType) => {
    if (item?.key === DROPDOWN_KEYS.UPLOAD_FILES) {
      // onShowUploadModal!()
      setIsModalOpen(true)
    } else if (item?.key === DROPDOWN_KEYS.LOGOUT) {
      logout()
    } else if (item?.key === DROPDOWN_KEYS.SETTINGS) {
      navigate('/settings')
    }
  }

  const dropdownMenu = (): JSX.Element => {
    const items: MenuItemType[] = [
      {
        label: (
          <span data-testid='upload-file-btn' data-cy='upload-file-btn'>
            Upload files
          </span>
        ),
        key: DROPDOWN_KEYS.UPLOAD_FILES,
        disabled:
          !RP.USER_HAS_UPLOAD_FILE_PERMISSION &&
          !RP.USER_HAS_UPLOAD_IMPORTED_ACCRUALS_PERMISSION
      },
      {
        label: (
          <span data-testid='settings-btn' data-cy='settings-btn'>
            Settings
          </span>
        ),
        key: DROPDOWN_KEYS.SETTINGS,
        // currenlty set to accrual rules page
        // subject to change
        disabled: !RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW
      },
      {
        type: 'divider'
      },
      {
        label: 'Logout',
        icon: isFetching && (
          <LoadingOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        ),
        key: DROPDOWN_KEYS.LOGOUT
      }
    ]
    return <Menu items={items} onClick={handleDropdownOnClick} />
  }

  return (
    <div className='navbar-container' data-testid='Header'>
      <Link to='/review-center'>
        <img
          src='/assets/images/gappify-logo-black.svg'
          alt='Gappify'
          height={24}
        />
      </Link>

      {isSuccess && (
        <Menu
          selectedKeys={[resolvedPath.pathname]}
          mode='horizontal'
          items={getHeaderTabs()}
          defaultSelectedKeys={getDefaultTab()}
        />
      )}

      <GDropdown overlay={dropdownMenu}>
        <Button
          shape='round'
          icon={
            <UserOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          size='middle'
        >
          {username}
        </Button>
      </GDropdown>
    </div>
  )
}

export default Header
