/** zustand imports */
import { create } from 'zustand'

interface StoreProps {
  /** state */
  isEditMode: boolean

  /** actions */
  setIsEditMode: (isEditMode: boolean) => void
}

// =================================================================
const useWorkDayStore = create<StoreProps>((set) => ({
  /** state */
  isEditMode: false,

  /** actions */
  setIsEditMode: (isEditMode: boolean) => set({ isEditMode })
}))

export default useWorkDayStore
