import { Select } from 'antd'

type TProps = {
  value: string
  onChange: (value: string) => void
}

const options = [
  { label: '2024-03-01', value: '2024-03-01' },
  { label: '2024-02-01', value: '2024-02-01' },
  { label: '2024-01-01', value: '2024-01-01' },
  { label: '2023-12-01', value: '2023-12-01' },
  { label: '2023-11-01', value: '2023-11-01' },
  { label: '2023-10-01', value: '2023-10-01' }
]

const PeriodDropdown = (props: TProps) => {
  const { value, onChange } = props

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      defaultValue='2024-03-01'
      placeholder='Select period'
    />
  )
}

export default PeriodDropdown
