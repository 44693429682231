/** react imports */
import { useEffect } from 'react'

/** constants imports */
import {
  EXPIRY_COUNTER,
  IDLE_TIMER,
  MILLISECOND
} from '../../helpers/constants/Auth'
import TO_SECONDS from '../constants/TO_SECONDS'

/** hooks imports */
import useApiService from '../../hooks/useApiService'
import useInfoModal from './useInfoModal'
import useReset from './useReset'

// =================================================================
export default function useCountDown() {
  /** hooks */
  const { showInfoModal } = useInfoModal()
  const { reset } = useReset()
  const { msUrl } = useApiService()

  useEffect(() => {
    const allowance = msUrl.token_exp_allowance * TO_SECONDS

    const timer = setInterval(() => {
      let counter: number = parseInt(localStorage.getItem(EXPIRY_COUNTER)!)
      let idleTimer: number = parseInt(localStorage.getItem(IDLE_TIMER)!)

      if (counter < allowance) {
        if (idleTimer < allowance) {
          localStorage.removeItem(EXPIRY_COUNTER)
          showInfoModal()
        } else {
          reset()
        }
      }

      if (counter-- > 0) {
        localStorage.setItem(EXPIRY_COUNTER, counter.toString())
      }
    }, MILLISECOND)

    return () => clearInterval(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
