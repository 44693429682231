// ** AntD Imports
import { Form, Table } from 'antd'

// ** Data Imports
import UpdateAccrualData from '../../../../mock/ActionModalsData/UpdateAccrualData'

// ** API Services Imports
import useAnalytics from '../../../../hooks/useAnalytics'

// ** Zustand Imports
import useAnalyticsStore from '../../../../manager/analytics-store'

// =================================================================
const UpdateAccrualManager = () => {
  // ** AntD **
  const [form] = Form.useForm()

  // ** Data **
  const { columns, rowsFromBackend } = UpdateAccrualData()

  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** API Services **
  const { getUpdateAccrualManager } = useAnalytics({
    responseType: responseType
  })
  const { isRefetching, isLoading } = getUpdateAccrualManager

  return (
    <Form form={form} component={false}>
      <Table
        dataSource={rowsFromBackend}
        columns={columns as []}
        pagination={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        loading={isRefetching || isLoading}
      />
    </Form>
  )
}

export default UpdateAccrualManager
