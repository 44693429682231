import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'

interface RequestPayload {
  id: number
  email: string
}

export default async function resetPassword(payload: RequestPayload) {
  try {
    const response = await axiosIdentityManagerInstance.post(
      '/api/user/reset-password',
      payload
    )

    return response.data
  } catch (error) {
    throw error
  }
}
