import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from 'antd'
import { SetStateAction } from 'react'
import GIcon from '../../../components/gappify/GIcon'
import AddLineScreenManagerColumn from '../types/AddLineScreenManagerColumn'
import handleOnChangeCheckbox from './handleOnChangeCheckbox'

export default function renderColumnValue(
  canEdit: boolean,
  value: boolean,
  recordId: number,
  formProperty: 'show' | 'read_only' | 'required',
  dataSource: AddLineScreenManagerColumn[],
  setDataSource: (value: SetStateAction<AddLineScreenManagerColumn[]>) => void,
  required?: boolean
): JSX.Element {
  if (canEdit) {
    const disabled = formProperty !== 'required' && required
    return (
      <Checkbox
        data-testid={`add-line-screen-manager-table-${formProperty}-checkbox-${recordId}`}
        data-cy={`add-line-screen-manager-table-${formProperty}-checkbox-${recordId}`}
        checked={value}
        onChange={handleOnChangeCheckbox(
          recordId,
          formProperty,
          dataSource,
          setDataSource
        )}
        disabled={disabled}
      />
    )
  }
  if (value)
    return (
      <GIcon
        dataTestId={`add-line-screen-manager-table-${formProperty}-checkbox-${recordId}`}
        dataCy={`add-line-screen-manager-table-${formProperty}-checkbox-${recordId}`}
        icon={faCheck}
        className='g-icon-primary'
      />
    )
  return <></>
}
