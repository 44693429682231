/** antd imports */
import { Form, Input } from 'antd'

/** hooks imports */
import useFetchWorkDaySettings from '../hooks/useFetchWorkDaySettings'

/** zustand store imports */
import useWorkDayStore from '../manager/useWorkdayStore'

// =================================================================
export default function RequestDetailsFormItems() {
  /** hooks */
  const { data } = useFetchWorkDaySettings()

  /** zustand store */
  const { isEditMode } = useWorkDayStore()

  return (
    <>
      <h2>Request Details</h2>
      <Form.Item
        label='Auth URL'
        name='auth_url'
        rules={[{ required: true, message: 'This field is required' }]}
        initialValue={data?.data.url_details.auth_url}
      >
        <Input
          placeholder='Enter auth url'
          value={'test'}
          disabled={!isEditMode}
        />
      </Form.Item>
      <Form.Item
        label='Base URL / Endpoint'
        name='get_url'
        rules={[{ required: true, message: 'This field is required' }]}
        initialValue={data?.data.url_details.get_url}
      >
        <Input placeholder='Enter base url / endpoint' disabled={!isEditMode} />
      </Form.Item>
    </>
  )
}
