import React from 'react'
import DepartmentGlVendor from './DepartmentGlVendor'
import SubsidiaryVendor from './SubsidiaryVendor'
import SubsidiaryDepartmentGlVendor from './SubsidiaryDepartmentGlVendor'
import SubsidiaryGlDepartmentVendor from './SubsidiaryGlDepartmentVendor'
import DepartmentVendor from './DepartmentVendor'

const TableRow: React.FC<any> = ({
  subsidiaries,
  fields,
  view,
  clickHandler,
  config
}) => {
  return (
    <>
      {(() => {
        switch (view) {
          case 'SUBSIDIARY_VENDORS':
            return (
              <SubsidiaryVendor
                subsidiaries={subsidiaries}
                fields={fields}
                view={view}
                clickHandler={clickHandler}
                config={config}
              ></SubsidiaryVendor>
            )
          case 'DEPARTMENT_GL_VENDOR':
            return (
              <DepartmentGlVendor
                subsidiaries={subsidiaries}
                fields={fields}
                view={view}
                clickHandler={clickHandler}
                config={config}
              ></DepartmentGlVendor>
            )
          case 'SUBSIDIARY_DEPARTMENT_GL_VENDOR':
            return (
              <SubsidiaryDepartmentGlVendor
                subsidiaries={subsidiaries}
                fields={fields}
                view={view}
                clickHandler={clickHandler}
                config={config}
              ></SubsidiaryDepartmentGlVendor>
            )
          case 'SUBSIDIARY_GL_DEPARTMENT_VENDOR':
            return (
              <SubsidiaryGlDepartmentVendor
                subsidiaries={subsidiaries}
                fields={fields}
                view={view}
                clickHandler={clickHandler}
                config={config}
              ></SubsidiaryGlDepartmentVendor>
            )
          case 'DEPARTMENT_VENDOR':
            return (
              <DepartmentVendor
                subsidiaries={subsidiaries}
                fields={fields}
                view={view}
                clickHandler={clickHandler}
                config={config}
              ></DepartmentVendor>
            )
          default:
            return null
        }
      })()}
    </>
  )
}

export default TableRow
