/** react imports */
import { useEffect } from 'react'

/** constants imports */
import {
  EXPIRATION,
  IDLE_TIMER,
  MILLISECOND
} from '../../helpers/constants/Auth'
import TO_SECONDS from '../constants/TO_SECONDS'

// =================================================================
export default function useIdleTimer() {
  useEffect(() => {
    const idleTimer = setInterval(() => {
      let idleCounter: number = parseInt(localStorage.getItem(IDLE_TIMER)!)
      const events = ['click', 'keypress', 'keydown', 'mousedown', 'load']

      for (let i in events) {
        // eslint-disable-next-line no-loop-func
        window.addEventListener(events[i], () => {
          idleCounter = parseInt(localStorage.getItem(EXPIRATION)!) * TO_SECONDS

          localStorage.setItem(IDLE_TIMER, idleCounter.toString())
        })
      }

      if (idleCounter-- > 0) {
        localStorage.setItem(IDLE_TIMER, idleCounter.toString())
      } else {
        localStorage.removeItem(IDLE_TIMER)
      }
    }, MILLISECOND)

    return () => clearInterval(idleTimer)
  }, [])
}
