/** antd imports */
import { Tag } from 'antd'
import { ColumnType } from 'antd/es/table'

/** 3rd party imports */
import moment from 'moment'

/** types imports */
import { MoreOutlined } from '@ant-design/icons'
import GDropdownBtn from 'src/components/gappify/GDropdownBtn'
import ApiKeysTableRecord from '../types/ApiKeysTableRecord'
import ClickParams from '../types/ClickParams'

/** enums imports */
import ApiTokenStatus from '../enums/ApiTokenStatus'

/** hooks imports */
import useToggleTokenStatus from './useToggleTokenStatus'

// =================================================================
export default function useApiKeysTableColumns(): ColumnType<ApiKeysTableRecord>[] {
  /** hooks */
  const { handleToggleTokenStatus } = useToggleTokenStatus()

  const columns: ColumnType<ApiKeysTableRecord>[] = [
    {
      title: '',
      width: 70,
      render: (value, record) => {
        const menuItems = []

        const currentDate = new Date().toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles'
        })
        const expirationDate = new Date(record.expiration_date)
        const isTokenExpired = expirationDate <= new Date(currentDate)

        if (isTokenExpired) {
          return <></>
        }

        if (record.status === ApiTokenStatus.ACTIVE) {
          menuItems.push({
            key: 'deactivate',
            label: 'Deactivate'
          })
        } else {
          menuItems.push({
            key: 'activate',
            label: 'Activate'
          })
        }

        return (
          <span className='data-table-action'>
            <GDropdownBtn
              btnText=''
              btnIcon={
                <MoreOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
              menuItems={menuItems}
              onClick={(e: ClickParams) =>
                handleToggleTokenStatus(e, record.id)
              }
            />
          </span>
        )
      }
    },
    {
      title: 'API Key Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        const currentDate = new Date().toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles'
        })
        const expirationDate = new Date(record.expiration_date)
        const isTokenExpired = expirationDate <= new Date(currentDate)

        if (isTokenExpired) {
          return <Tag color='error'>Expired</Tag>
        }

        if (value === ApiTokenStatus.ACTIVE) {
          return <Tag color='success'>Active</Tag>
        }

        return <Tag color='warning'>Inactive</Tag>
      }
    },
    {
      title: 'Created At (PST)',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => {
        const date = moment(value)
        return date.isValid() ? date.format('YYYY-MM-DD hh:mm:ss') : false
      },
      sorter: true,
      showSorterTooltip: false
    },
    /** Temporarily hide created by column */
    // {
    //   title: 'Created By',
    //   dataIndex: 'user',
    //   key: 'user',
    //   render: (value, record) => record.user.user_profile.email
    // },
    {
      title: 'Expiry (PST)',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: (value: string) => {
        const date = moment(value)
        return date.isValid()
          ? date.format('YYYY-MM-DD hh:mm:ss')
          : "Doesn't Expire"
      }
    }
  ]

  return columns
}
