// ** Third Party Imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** API Services Imports
import {
  AnalyticsData,
  useAnalyticsService
} from '../services/AnalyticsService'

// ** Hook Imports
import useApiService from './useApiService'

// ** Zustand Store Imports
import useAnalyticsStore from '../manager/analytics-store'
import useGFStore from '../components/Modals/GlobalFilterModal/store/GlobalFilterStore'

// ** Type/Enum Imports
import { EReportTypes } from '../constants/Globals'
import TableRecord from '../types/TableRecord'

// =================================================================
export default function useAnalytics({
  responseType
}: {
  responseType: string | undefined
}) {
  // ** Store **
  const { setErrorMsg, reportType } = useAnalyticsStore()

  // ** API Services **
  const { AnalyticsAPI } = useAnalyticsService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** Store **
  const {
    sortSpendDepartments,
    sortSpendEmployees,
    nonCompliantTableSort,
    period,
    permNoteVendorId,
    buttonClicked,
    setPermNoteMessage,
    modalType,
    page,
    setPermNotesEditingKey,
    permNotesEditingKey,
    setPermNotesErrorMsg,
    selectedRows,
    showEmailVendor,
    setAdditionalEmails,
    valuesPopupVendorId,
    valuesPopupDuration,
    costCuttingTabView,
    monthsToShow,
    nonCompliantTablePage,
    sortIncreasingSpend,
    pageIncreasingSpend,
    selectedItem,
    setEmailTemplate
  } = useAnalyticsStore()

  // ** Zustand - Global Filtering Store **
  const activeFilterId = useGFStore((state) => state.activeFilterId)
  const isPreviewModeDep = useGFStore((state) => state.isPreviewModeDep)

  // ** TanStack **
  const queryClient = useQueryClient()

  // ** Local Storage **
  const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  // =================================================================

  const getPermNotes = useQuery({
    queryKey: ['perm-notes', permNoteVendorId],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/get/perm-notes/${permNoteVendorId}`,
          method: `GET`
        }
      }),
    enabled: permNoteVendorId !== undefined && modalType === 'perm'
  })

  const { mutate: postPermNotes, isLoading: postPermNotesLoading } =
    useMutation(AnalyticsAPI, {
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ['perm-notes'] })
        setPermNoteMessage('')
        queryClient.invalidateQueries({ queryKey: ['accrual-analytics'] })
      },
      onError: (error: { data: { message: string } }) => {
        console.log(`ERROR POST`, error)
      }
    })

  const handleSubmit = ({ notes }: { notes: string }) => {
    postPermNotes({
      data: {
        user_id: user.user_id,
        vendor_id: permNoteVendorId,
        report: buttonClicked,
        note: notes
      },
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/save/perm-note`,
        method: 'POST',
        customHeaders: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  }

  const { mutate: modifyPermNote, isLoading: deletePermNoteLoading } =
    useMutation(AnalyticsAPI, {
      onSuccess: (response) => {
        console.log(`SUCCESS MODIFY`, response)
        queryClient.invalidateQueries({ queryKey: ['perm-notes'] })
        setPermNotesEditingKey(null)
      },
      onError: (
        error: Record<
          string,
          {
            error: {
              note: string
            }
          }
        >
      ) => {
        console.log(`ERROR MODIFY`, error.data.error.note)
        setPermNotesErrorMsg(error.data.error.note)
      }
    })

  const handleModifyPermNote = (
    key: string,
    record: TableRecord,
    button: string
  ) => {
    switch (key) {
      case '1':
        console.log(`Edit Perm`)
        permNotesEditingKey === record.key
          ? setPermNotesEditingKey(null)
          : setPermNotesEditingKey(record.key)
        break
      case '2':
        console.log(`Delete Perm`, record.key)
        break
      default:
        break
    }
  }

  const getUpdateAccrualManager = useQuery({
    queryKey: ['update-accrual-manager', permNoteVendorId],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `api/accrual-manager/vendor-task/${permNoteVendorId}`,
          method: `GET`
        }
      }),
    enabled: permNoteVendorId !== undefined && modalType === 'update'
  })

  const {
    mutate: patchUpdateAccrualManager,
    isLoading: patchUpdateAccrualManagerLoading
  } = useMutation(AnalyticsAPI, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['update-accrual-manager', permNoteVendorId]
      })
      setPermNoteMessage('')
    },
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR POST`, error)
    }
  })

  const handleSubmitUpdateAccrual = ({
    vendorTaskId,
    updateAccrualChanges
  }: {
    vendorTaskId: string
    updateAccrualChanges: Record<string, string | boolean>
  }) => {
    patchUpdateAccrualManager({
      data: updateAccrualChanges,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `/api/accrual-manager/vendor-task/${vendorTaskId}`,
        method: 'PATCH'
      }
    })
  }

  const getHistoryLog = useQuery({
    queryKey: ['history-log', permNoteVendorId],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `api/accrual-manager/history/vendor/${permNoteVendorId}`,
          method: `GET`
        }
      }),
    enabled: permNoteVendorId !== undefined && modalType === 'history'
  })

  const getEmailDetailsVendor = useQuery({
    queryKey: ['email-details-vendor', selectedRows.join(','), showEmailVendor],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/response-rates/email/${responseType}?vendor_ids=${selectedRows.join(
            ','
          )}`,
          method: `GET`
        }
      }),
    onSuccess: (data) => {
      console.log('success email details vendor', data)
      setAdditionalEmails(Array(data.length).fill(''))
    },
    enabled: !!selectedRows.length && showEmailVendor
  })

  const getEmailDetailsInternal = useQuery({
    queryKey: ['email-details-internal', selectedRows.join(',')],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/response-rates/email/internal?vendor_ids=${selectedRows.join(
            ','
          )}`,
          method: `GET`
        }
      }),
    // enabled: !!selectedRows.length && showEmailVendor
    enabled: false
  })

  const {
    mutate: postEmail,
    isLoading: postEmailLoading,
    isSuccess: postEmailSuccess,
    isError: postEmailError
  } = useMutation(AnalyticsAPI, {
    onSuccess: (response) => {},
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR POST EMAIL`, error)
    }
  })

  const handleSubmitEmail = ({ emailData }: { emailData: AnalyticsData }) => {
    postEmail({
      data: emailData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/response-rates/email/send`,
        method: 'POST'
      }
    })
  }

  const getDataValuesPopup = useQuery({
    queryKey: [
      'data-values-popup',
      responseType,
      valuesPopupVendorId,
      valuesPopupDuration
    ],
    queryFn: () =>
      AnalyticsAPI({
        params: {
          vendor_id: valuesPopupVendorId,
          period: valuesPopupDuration
        },
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/response-rates/${responseType}-lines/get`,
          method: `GET`
        }
      }),
    onSuccess: (data) => {
      console.log('success datavaluespopup', data)
    },
    /**
     * ! BUGFIX:
     * Added 'responseType' state to prevent refetching when value is undefined
     */
    enabled: !!valuesPopupVendorId && !!valuesPopupDuration && !!responseType
  })

  const getTableSpendCompliance = useQuery({
    queryKey: [
      'cost-cutting',
      costCuttingTabView,
      sortSpendDepartments,
      sortSpendEmployees,
      nonCompliantTableSort,
      period,
      monthsToShow,
      page,
      nonCompliantTablePage,
      activeFilterId,
      isPreviewModeDep
    ],
    queryFn: () => {
      return AnalyticsAPI({
        params: {
          order_by:
            costCuttingTabView === 'department'
              ? sortSpendDepartments
              : costCuttingTabView === 'employee'
              ? sortSpendEmployees
              : nonCompliantTableSort,
          period: period,
          months_to_show: monthsToShow,
          page_number:
            costCuttingTabView === 'action' ? nonCompliantTablePage : page,
          filter: activeFilterId,
          preview_mode: isPreviewModeDep
        },
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/analytics/cost-cutting/spend-compliance/${costCuttingTabView}`,
          method: `GET`
        }
      })
    },
    enabled: selectedItem === 'PO Compliance' && !!costCuttingTabView
  })

  const getTableIncreasingSpend = useQuery({
    queryKey: [
      'increasing-spend',
      sortIncreasingSpend,
      period,
      monthsToShow,
      pageIncreasingSpend,
      activeFilterId,
      isPreviewModeDep
    ],
    queryFn: () => {
      return AnalyticsAPI({
        params: {
          order_by: sortIncreasingSpend,
          period: period,
          months_to_show: monthsToShow,
          page_number: pageIncreasingSpend,
          filter: activeFilterId,
          preview_mode: isPreviewModeDep
        },
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/analytics/cost-cutting/increasing-spend`,
          method: `GET`
        }
      })
    },
    enabled: selectedItem === 'Rising Expenses'
  })

  const {
    mutate: postSuggestedAction,
    isLoading: postSuggestedActionLoading,
    isSuccess: postSuggestedActionSuccess,
    isError: postSuggestedActionError
  } = useMutation(AnalyticsAPI, {
    onSuccess: (response) => {
      // Conditional re-fetching of data/query
      if (selectedItem === 'Rising Expenses') {
        queryClient.invalidateQueries({
          queryKey: ['increasing-spend']
        })
      } else {
        queryClient.invalidateQueries({
          queryKey: ['cost-cutting', costCuttingTabView]
        })
      }
    },
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR POST SUGGESTED ACTION`, error)

      const errorMsg = error?.data?.message
      if (errorMsg) setErrorMsg(errorMsg)
    }
  })

  const {
    mutate: postExecuteAction,
    isLoading: postExecuteActionLoading,
    isSuccess: postExecuteActionSuccess,
    isError: postExecuteActionError
  } = useMutation(AnalyticsAPI, {
    onSuccess: (response) => {
      // Conditional re-fetching of data/query
      if (selectedItem === 'Rising Expenses') {
        queryClient.invalidateQueries({
          queryKey: ['increasing-spend']
        })
      } else {
        queryClient.invalidateQueries({
          queryKey: ['cost-cutting', costCuttingTabView]
        })
      }
    },
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR POST EXECUTE ACTION`, error)

      const errorMsg = error?.data?.message
      if (errorMsg) setErrorMsg(errorMsg)
    }
  })

  /**
   * TODO: Need to refactor AnalytisAPI types
   * This conflics with other handler functions
   * Temporarily set 'any' as the type here
   */
  const handlePostSuggestedAction = (data: any) => {
    let endpoint = `api/analytics/cost-cutting/spend-compliance/invoice-purchase-order-action`

    // Custom condition as each have different endpoint formats
    if (selectedItem === 'Rising Expenses') {
      endpoint = `api/analytics/cost-cutting/increasing-spend/action-update`
    }

    postSuggestedAction({
      data: data,
      options: {
        baseURL: msUrl.analytics,
        endpoint,
        method: 'POST'
      }
    })
  }

  const handlePostExecuteAction = (data: any, executeType: string) => {
    postExecuteAction({
      data: data,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/analytics/cost-cutting/${executeType}/execute`,
        method: 'POST'
      }
    })
  }

  const hasEmailTemplate = (thisReportType: EReportTypes) => {
    const validReportTypes: EReportTypes[] = [
      EReportTypes.ResponseRatesInternal,
      EReportTypes.ResponseRatesVendor,
      EReportTypes.POCNonCompliant,
      EReportTypes.RisingExpenses
    ]
    return validReportTypes.includes(thisReportType)
  }

  const getEmailTemplate = useQuery({
    queryKey: ['email-template', reportType],
    queryFn: () =>
      AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/email/${reportType}/template`,
          method: `GET`
        }
      }),
    onSuccess: (data) => {
      setEmailTemplate(data)
    },
    enabled: !!reportType && hasEmailTemplate(reportType)
  })

  return {
    getPermNotes,
    handleSubmit,
    postPermNotesLoading,
    getUpdateAccrualManager,
    patchUpdateAccrualManagerLoading,
    handleSubmitUpdateAccrual,
    getHistoryLog,
    handleModifyPermNote,
    deletePermNoteLoading,
    modifyPermNote,
    getEmailDetailsVendor,
    getEmailDetailsInternal,
    handleSubmitEmail,
    postEmailLoading,
    postEmailSuccess,
    postEmailError,
    getDataValuesPopup,
    getTableSpendCompliance,
    handlePostSuggestedAction,
    postSuggestedActionLoading,
    postSuggestedActionSuccess,
    postSuggestedActionError,
    handlePostExecuteAction,
    postExecuteActionLoading,
    postExecuteActionSuccess,
    postExecuteActionError,
    getTableIncreasingSpend,
    getEmailTemplate
  }
}
