import MsUrl from '../../../types/MsUrl'

const Local: MsUrl = {
  idms: process.env.REACT_APP_IDMS
    ? process.env.REACT_APP_IDMS
    : 'http://localhost:8081/',
  txn_manager: process.env.REACT_APP_TM
    ? process.env.REACT_APP_TM
    : 'http://localhost:8082',
  review_center: process.env.REACT_APP_RC
    ? process.env.REACT_APP_RC
    : 'http://localhost:8084',
  qje_manager: process.env.REACT_APP_QJE
    ? process.env.REACT_APP_QJE
    : 'http://localhost:8083',
  taskmanager: process.env.REACT_APP_TSM
    ? process.env.REACT_APP_TSM
    : 'https://vendor-accruals.test',
  history_logs: process.env.REACT_APP_HISTORY
    ? process.env.REACT_APP_HISTORY
    : 'https://history.dev.gappifyinc.com',
  analytics: process.env.REACT_APP_AA
    ? process.env.REACT_APP_AA
    : 'http://localhost:8085',
  token_duration: 60,
  token_exp_allowance: 1,
  schedule_manager: process.env.REACT_APP_SM
    ? process.env.REACT_APP_SM
    : 'http://localhost:8086',
  schedule_manager_env: 'qa',
  search:
    process.env.REACT_APP_SEACH ||
    'https://search.dev.gappifyinc.com/api/search',
  period_manager: 'https://period-manager.dev.gappifyinc.com',
  settings:
    process.env.REACT_APP_SETTINGS || 'https://settings.dev.gappifyinc.com',
  gappify_api:
    process.env.REACT_APP_GAPPIFY_API || 'https://api.dev.gappifyinc.com'
} as const

export default Local
