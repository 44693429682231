// ** Style Imports
import styles from '../styles/resetpassword.module.css'

// ** Zustand Imports
import useResetPasswordModalStore from '../manager/resetpassword-store'

// =================================================================
const ResponseMessage = () => {
  // ** Zustand Store **
  const { responseMessage } = useResetPasswordModalStore()

  return responseMessage ? (
    <div
      data-testid='error-message'
      className={styles.errorMessage}
      style={{
        color: responseMessage.toLowerCase().includes('emailed')
          ? '#237804'
          : '#cf1322',
        background: responseMessage.toLowerCase().includes('emailed')
          ? '#f6ffed'
          : '#fff1f0',
        border: `1px solid ${
          responseMessage.toLowerCase().includes('emailed')
            ? '#b7eb8f'
            : '#ffa39e'
        }`
      }}
    >
      {responseMessage}
    </div>
  ) : (
    <></>
  )
}

export default ResponseMessage
