/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** services imports */
import useApiService from '../../../../../hooks/useApiService'
import useApiKeyService from '../services/useApiKeyService'

/** types imports */
import useApiKeyStore from '../manager/useApiKeyStore'

/** zustand store imports */
import ApiKeyResponse from '../types/ApiKeyResponse'

// =================================================================
export default function useFetchApiKeys() {
  /** services */
  const { apiKeyManagerAPI } = useApiKeyService()
  const { msUrl } = useApiService()

  /** zustand stores */
  const { perPage, page, sortOrder } = useApiKeyStore()

  return useQuery<ApiKeyResponse>({
    queryKey: ['api-keys', page, perPage, sortOrder],
    queryFn: () =>
      apiKeyManagerAPI({
        options: {
          baseURL: msUrl.idms,
          endpoint: '/api/api-manager',
          method: 'GET'
        },
        params: {
          per_page: perPage,
          sort: sortOrder,
          page
        }
      }),
    refetchInterval: 5000 * 60, // sets the refetch interval to 5mins
    staleTime: 5000 * 60 // sets the stale time to 5mins
  })
}
