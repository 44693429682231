export default function filterOption(input: string, option: any): boolean {
  if (option.children) {
    let optionChildren = option.children
    if (typeof optionChildren === 'number') {
      optionChildren = optionChildren + ''
    }
    return optionChildren.toLowerCase().includes(input.toLowerCase())
  }
  return false
}
