// ** React imports **
import React, { useEffect, useState } from 'react'

// ** Antd imports **
import { Button, message, Modal, Table } from 'antd'

// ** Hooks **
import useFetchSyncDetails from '../hooks/useFetchSyncDetails'

// ===============================================
const SyncDetailsModal: React.FC = () => {
  // ** States **
  const [isModalOpen, setIsModalOpen] = useState(false)

  // ** Hooks **
  const {
    data: syncDetails,
    isLoading: isLoadingSyncDetails,
    isRefetching: isRefetchingSyncDetails,
    isError: isErrorSyncDetails,
    error: errorSyncDetails,
    refetch: refetchSyncDetails
  } = useFetchSyncDetails()

  // ** Functions **
  const showModal = () => {
    setIsModalOpen(true)
    refetchSyncDetails()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const syncDetailsColumns: Record<string, any>[] = [
    {
      title: 'Success',
      dataIndex: 'processed_rows',
      key: 'processed_rows',
      align: 'center',
      width: '15%',
      onCell: (record: Record<string, any>) => ({
        style: { fontSize: 20 },
        'data-cy': 'success-count-cell',
        'data-testid': 'success-count-cell'
      })
    },
    {
      title: 'Error',
      dataIndex: 'failed_rows',
      key: 'failed_rows',
      align: 'center',
      width: '15%',
      onCell: (record: Record<string, any>) => ({
        style: { fontSize: 20 },
        'data-cy': 'error-count-cell',
        'data-testid': 'error-count-cell'
      })
    },
    {
      title: 'Total Sync',
      dataIndex: 'total_rows',
      key: 'total_rows',
      align: 'center',
      width: '15%',
      onCell: (record: Record<string, any>) => ({
        style: { fontSize: 20 },
        'data-cy': 'total-rows-cell',
        'data-testid': 'total-rows-cell'
      })
    },
    {
      title: 'Error Message',
      dataIndex: 'remarks',
      key: 'remarks',
      onCell: (record: Record<string, any>) => ({
        'data-cy': 'error-cell',
        'data-testid': 'error-cell'
      })
    }
  ]

  useEffect(() => {
    if (isErrorSyncDetails) {
      message.error(
        (errorSyncDetails as Record<string, any>)?.error ||
          'An error has occurred. Please try again.'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorSyncDetails])

  return (
    <>
      <Button
        type='link'
        style={{ color: '#725bb4', padding: 0 }}
        data-testid='sync-details-button'
        data-cy='sync-details-button'
        onClick={showModal}
      >
        Sync Details
      </Button>
      <Modal
        title='Sync Details'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
        data-testid='sync-details-modal'
        data-cy='sync-details-modal'
      >
        <Table
          dataSource={[syncDetails?.data] || []}
          columns={syncDetailsColumns}
          pagination={false}
          data-testid='sync-details-table'
          data-cy='sync-details-table'
          loading={isLoadingSyncDetails || isRefetchingSyncDetails}
        />
      </Modal>
    </>
  )
}

export default SyncDetailsModal
