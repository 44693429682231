// ** React Imports
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** AntD Imports
import { Button, Form, Tag } from 'antd'

// ** Component Imports
import UserActivationHeader from './components/UserActivationHeader'
import PasswordRules from './components/PasswordRules'
import FormInput from './components/FormInput'

// ** Hooks Imports
import useAPI from './hooks/useAPI'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { RootState } from '../../context/store'

// =================================================================
const UserActivationForm = () => {
  // ** React Router **
  const navigate = useNavigate()
  const { token } = useParams()

  // ** Redux **
  const { isTokenValid, errorMessages, responseMessage, isSSO } = useSelector(
    (state: RootState) => state.userActivationForm
  )

  // ** Hooks **
  const { checkUserToken, postForm } = useAPI()

  const [form] = Form.useForm()

  // ** Submit Form Functions **
  const handleSubmit = async <T extends []>(values: T) => {
    const formData = new FormData()
    for (const key in values) {
      formData.append(key, values[key])
    }
    formData.append(`token`, token as string)
    postForm(formData)
  }

  // ** Check User Token **
  useEffect(() => {
    checkUserToken(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // if token is not valid, redirect user or show error message
  if (isTokenValid === false || isSSO) {
    navigate('/')
  }

  if (responseMessage && responseMessage.success) {
    setTimeout(() => {
      navigate('/')
    }, 3000)
  }

  return (
    <>
      {isTokenValid && (
        <div className='login-page'>
          <img
            className='bg-img img img-responsive'
            src='/assets/images/MULTICITY-Laptop.jpeg'
            alt='gpfy-bg'
          />

          <div className='login-header'>
            <img src='/assets/images/gappify-logo-black.svg' alt='gpfy-logo' />
          </div>

          <div className='user-activation-content-wrapper'>
            {responseMessage && responseMessage.success ? (
              <Tag
                color='orange'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {responseMessage && responseMessage.message}
              </Tag>
            ) : (
              <>
                <UserActivationHeader />

                <Form
                  form={form}
                  initialValues={{ remember: true }}
                  name='control-hooks'
                  onFinish={handleSubmit}
                  data-testid='user-activation-form'
                >
                  <div className='form'>
                    <div className='firstWrapper'>
                      <FormInput
                        wrapperCol={{ sm: { span: 18 } }}
                        labelCol={{ sm: { span: 5 } }}
                        label='Password'
                        name='password'
                        customRequired
                        required
                        message='Please input your password!'
                        className='form-item'
                        isPassword
                      />
                      <FormInput
                        wrapperCol={{ sm: { span: 18 } }}
                        labelCol={{ sm: { span: 5 } }}
                        label={
                          <span className='label-span'>
                            <span style={{ color: 'red', fontSize: 9 }}>*</span>
                            Confirm Password
                          </span>
                        }
                        name='password_confirmation'
                        dependencies={['password']}
                        required
                        message='Please confirm your password!'
                        className='form-item'
                        isPassword
                        isConfirmation
                        hasFeedback
                      />
                    </div>

                    <PasswordRules />
                  </div>
                  {errorMessages &&
                    errorMessages.map((message, index) => (
                      <p
                        key={index}
                        style={{
                          margin: 0,
                          padding: 0,
                          color: 'red',
                          fontSize: 12
                        }}
                      >
                        {message}
                      </p>
                    ))}
                  <Form.Item className='submit-button'>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='submitButton'
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default UserActivationForm
