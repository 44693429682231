import { FC } from 'react'
import { Modal, Progress } from 'antd'

interface ProcessingCreateQjeModalProps {
  visible: boolean
  proccessedQjeItems: number
  totalQjeItems: number
}

const ProcessingCreateQjeModal: FC<ProcessingCreateQjeModalProps> = ({
  visible,
  proccessedQjeItems,
  totalQjeItems
}: ProcessingCreateQjeModalProps): JSX.Element => {
  const center = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <Modal
      data-testid='process-create-qje-modal'
      data-cy='process-create-qje-modal'
      open={visible}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2>Processing...</h2>
        </div>
      }
      okButtonProps={{
        hidden: true
      }}
      footer={null}
      closable={false}
      centered
    >
      {proccessedQjeItems > 0 && (
        <div
          data-testid='process-create-qje-modal-progress'
          data-cy='process-create-qje-modal-progress'
        >
          <Progress
            percent={(proccessedQjeItems / totalQjeItems) * 100}
            status='active'
            showInfo={false}
          />
          <h2 style={center}>
            Proccessing {proccessedQjeItems} out of {totalQjeItems} items
          </h2>
        </div>
      )}
      <div style={center}>
        <p>Do not close the browser</p>
      </div>
    </Modal>
  )
}

export default ProcessingCreateQjeModal
