/** antd imports */
import { FormInstance } from 'antd'

/** custom components imports */
import AuthorizationParametersFormItems from './AuthorizationParametersFormItems'
import DimensionFieldsFormItems from './DimentionsFieldsFormItems'
import QueryParametersFormItems from './QueryParametersFormItems'
import RequestDetailsFormItems from './RequestDetailsFormItems'

interface WorkdaySetupFormProps {
  form: FormInstance
}

// =================================================================
export default function WorkdaySetupForm({ form }: WorkdaySetupFormProps) {
  return (
    <>
      <RequestDetailsFormItems />
      <AuthorizationParametersFormItems form={form} />
      <QueryParametersFormItems />
      <DimensionFieldsFormItems />
    </>
  )
}
