/** react imports */
import { useEffect } from 'react'

/** react router imports */
import { useNavigate } from 'react-router-dom'

/** hooks imports */
import useFlagStore from 'src/features/Flag/stores/flagStore'
import useCompanySettings from 'src/hooks/useCompanySettings'
import useRolesAndPermissions from 'src/hooks/useRolesAndPermissions'

// =================================================================
export default function useLogsFlag() {
  /** react router */
  const navigate = useNavigate()

  /** hooks */
  const RP = useRolesAndPermissions()
  const flags = useFlagStore((state) => state.flags)
  const isFlagsLoading = useFlagStore((state) => state.isFlagsLoading)
  const { isLoading: isCompanySettingsLoading, data: companySettings } =
    useCompanySettings()

  const logsFeatureFlag = flags.find(
    (flag) => flag.name === 'v1.3.0_integration'
  )

  useEffect(() => {
    if (isFlagsLoading) return
    if (!logsFeatureFlag || !RP.USER_HAS_INTEGRATION_LOGS_GET_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isCompanySettingsLoading) return
    if (!companySettings.api_enabled) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanySettingsLoading])
}
