// ** AntD Imports
import { DatePicker } from 'antd'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'

// ** API Services Imports
import useApiService from './useApiService'
import { useAnalyticsService } from '../services/AnalyticsService'

// ** Custom Component Imports
import useAdditionalOptions from './useAdditionalOptions'

// ** Hook Imports
import useAnalytics from './useAnalytics'
import useGlobalFilter from './useGlobalFilter'

// ** Third Party Imports
import moment from 'moment'
import download from 'downloadjs'

// ** Type Imports
import ClickParam from '../types/ClickParam'
import { EModalActions } from '../components/Modals/GlobalFilterModal/types/global-filter-types'

// ** Zustand Store Imports
import useAnalyticsStore from '../manager/analytics-store'
import capitalize from '../helper/Capitalize'
import { useQueryClient } from '@tanstack/react-query'
import useGFStore from '../components/Modals/GlobalFilterModal/store/GlobalFilterStore'

interface CsvParams {
  order_by: string
  period: string | undefined
  months_to_show: string | number
  with_count?: string | number
  non_responsive_only?: string | number
  filter: number
}

// =================================================================
export default function useDropdown() {
  // ** Store **
  const {
    header,
    title,
    responseType,
    setPeriod,
    setShowDatePicker,
    sort,
    period,
    costCuttingTabView,
    monthsToShow,
    sortInternal,
    sortVendor,
    sortSpendDepartments,
    sortSpendEmployees,
    nonCompliantTableSort,
    sortIncreasingSpend,
    page,
    internalPage,
    vendorPage,
    selectedItem,
    increasingSpendHeader,
    reportType
  } = useAnalyticsStore()

  // ** Zustand - Global Filtering Store **
  const setActiveFilter = useGFStore((state) => state.setActiveFilter)
  const setActiveFilterObj = useGFStore((state) => state.setActiveFilterObj)
  const setActiveFilterId = useGFStore((state) => state.setActiveFilterId)
  const setOpenFilterModal = useGFStore((state) => state.setOpenFilterModal)
  const setFilterModalAction = useGFStore((state) => state.setFilterModalAction)
  const setIsPreviewMode = useGFStore((state) => state.setIsPreviewMode)
  const activeFilterId = useGFStore((state) => state.activeFilterId)
  const isPreviewModeDep = useGFStore((state) => state.isPreviewModeDep)
  const sessionFilter = useGFStore((state) => state.sessionFilter)
  const setSessionFilter = useGFStore((state) => state.setSessionFilter)

  // ** API Services **
  const { AnalyticsAPI } = useAnalyticsService()

  // ** Hooks **
  const { getTableSpendCompliance, getTableIncreasingSpend } = useAnalytics({
    responseType: responseType
  })
  const { data: SpendComplianceData } = getTableSpendCompliance
  const { data: IncreasingSpendData } = getTableIncreasingSpend
  const { getCurrentFilters } = useGlobalFilter()
  const { data: CurrentFiltersData } = getCurrentFilters
  const { getMonthsToShow, getShowCount, getNonResponsive } =
    useAdditionalOptions()
  const { msUrl } = useApiService()

  // ** Constants **
  const currentMonthsToShow = getMonthsToShow()
  const currentShowCount = getShowCount()
  const currentNonResponsive = getNonResponsive()

  const queryClient = useQueryClient()

  const AnalyticsData: any = queryClient.getQueryData([
    'analytics',
    sortInternal,
    sortVendor,
    responseType,
    period,
    currentMonthsToShow,
    currentShowCount,
    currentNonResponsive,
    page,
    internalPage,
    vendorPage,

    /**
     * ? BUGFIX: https://gappify.atlassian.net/browse/AA-811
     * ? Added additional query keys used from the initial useQuery() from:
     * ? pages/AccrualAnalyticsNonES/views/ConfirmationResponseRates.tsx
     */
    activeFilterId,
    isPreviewModeDep
  ])

  // =================================================================
  const getPeriodMenu = () => {
    const menu: MenuItemType[] = []

    // TODO: Needs refactoring to use single global table component
    if (AnalyticsData) {
      Object.entries(AnalyticsData?.period_drop_down).forEach(
        ([key, value]) => {
          menu.push({
            label: value as string,
            key: key
          })
        }
      )
    } else if (SpendComplianceData) {
      Object.entries(SpendComplianceData?.period_drop_down).forEach(
        ([key, value]) => {
          menu.push({
            label: value as string,
            key: key
          })
        }
      )
    } else if (IncreasingSpendData) {
      Object.entries(IncreasingSpendData?.period_drop_down).forEach(
        ([key, value]) => {
          menu.push({
            label: value as string,
            key: key
          })
        }
      )
    }

    menu.push({
      label: (
        <DatePicker
          className='period-picker'
          picker='month'
          placeholder='Select Period'
          format='MMMM YYYY'
          onChange={(date) => {
            if (date) {
              const formattedDate = moment(date)
                .startOf('month')
                .format('YYYY-MM-DD')
              setPeriod(formattedDate)
              setShowDatePicker(false)
            }
          }}
        />
      ),
      key: 'date-picker'
    })

    return menu
  }

  // =================================================================

  const getFiltersArray = () => {
    // Generate object for current filters
    const currentFilters = CurrentFiltersData?.data || []
    const currentFiltersArr = currentFilters.map(
      (value: any, index: number) => {
        return {
          key: value.id,
          label: value.name,
          filter_fields: value.filter_fields,
          search_params: value.search_params,
          onClick: () => {
            setActiveFilter(value.name)
            setActiveFilterObj(getActiveFilterByKey(value.id))
            setActiveFilterId(value.id)
            setIsPreviewMode(0)

            if (reportType) {
              const sessionFilterObj = {
                [reportType]: {
                  id: value.id,
                  name: value.name,
                  filterObj: getActiveFilterByKey(value.id)
                }
              }
              const newSessionFilter = { ...sessionFilter, ...sessionFilterObj }
              setSessionFilter(newSessionFilter)
            }
          }
        }
      }
    )

    return currentFiltersArr
  }

  const getFilterMenu = () => {
    return [
      ...getFiltersArray(),
      {
        type: 'divider'
      },
      {
        key: 'all',
        label: 'All',
        onClick: () => {
          setActiveFilter('all')
          setActiveFilterObj(null)
          setActiveFilterId(0)
          setIsPreviewMode(0)

          if (reportType) {
            const newSessionFilter = { ...sessionFilter }
            delete newSessionFilter[reportType]
            setSessionFilter(newSessionFilter)
          }
        }
      },
      {
        type: 'divider'
      },
      {
        key: 'new',
        label: 'Create New Filter',
        onClick: () => {
          setOpenFilterModal(true)
          setFilterModalAction(EModalActions.CREATE)
        }
      }
    ]
  }

  const getActiveFilterByKey = (key: number) => {
    const filteredArray = getFiltersArray().filter(
      (value: any, index: number) => value.key === key
    )
    return filteredArray[0]
  }

  // =================================================================
  const handlePeriodClick = (param?: ClickParam) => {
    if (param?.key !== 'date-picker') {
      setPeriod(param?.key)
      setShowDatePicker(false)
    }
  }

  // ** Export Menu ==================================================
  const getExportMenu = () => {
    const menu: MenuItemType[] = [
      {
        label: 'CSV',
        key: 'csv'
      }
    ]

    return menu
  }

  // ** Export Function =============================================
  const exportCSV = async (ext: string, type: string) => {
    let endpoint = '',
      params: CsvParams = {
        order_by: sort,
        period: period,
        months_to_show: currentMonthsToShow,

        /**
         * ? BUGFIX: https://gappify.atlassian.net/browse/AA-812
         * ? Added filter ID parameter when calling export endpoint
         * ? This will also apply the active filter on the exported file
         */
        filter: activeFilterId
      }

    if (header === 'Confirmation Rates') {
      endpoint = `/api/response-rates/${responseType}/export`
      params = {
        ...params,
        order_by: responseType === 'internal' ? sortInternal : sortVendor,
        with_count: currentShowCount,
        non_responsive_only: currentNonResponsive
      }
    } else if (header === 'Cost Monitoring') {
      let costCuttingMenu = title.toLowerCase().replace(/\s/g, '-')

      // TODO: Refactor repetitive pattern
      // Condition for different sorting values
      if (costCuttingMenu === 'po-compliance') {
        // Custom URL condition after label changes
        costCuttingMenu = 'spend-compliance'

        endpoint = `api/analytics/cost-cutting/${costCuttingMenu}/${costCuttingTabView}/export`

        // For Non-Compliant tab views
        if (costCuttingTabView === 'department') {
          params = {
            ...params,
            order_by: sortSpendDepartments,
            months_to_show: monthsToShow
          }
        } else if (costCuttingTabView === 'employee') {
          params = {
            ...params,
            order_by: sortSpendEmployees,
            months_to_show: monthsToShow
          }
        } else {
          params = {
            ...params,
            order_by: nonCompliantTableSort
          }
        }
      } else {
        // Custom URL condition after label changes
        if (costCuttingMenu === 'rising-expenses') {
          costCuttingMenu = 'increasing-spend'
          endpoint = `api/analytics/cost-cutting/${costCuttingMenu}/export`

          params = {
            ...params,
            order_by: sortIncreasingSpend
          }
        }
      }
    }

    const getSubtitle = () => {
      let subtitle = ''
      if (header === 'Confirmation Rates') {
        subtitle = `${title}`
      } else {
        if (selectedItem === 'Rising Expenses') {
          subtitle = `${title} ${increasingSpendHeader}`
        } else {
          let tablTitle = costCuttingTabView
          if (costCuttingTabView === 'action') {
            tablTitle = 'non-compliant invoice'
          }
          subtitle = `${title} ${capitalize(tablTitle)}s`
        }
      }

      return subtitle
    }

    try {
      const response = await AnalyticsAPI({
        params,
        options: {
          baseURL: msUrl.analytics,
          endpoint,
          method: `GET`
        }
      })

      // ** Export CSV file name convention **
      const fullDateTime = moment().format('MM-DD-YYYY-hh-mm-ss A')
      const subtitle = getSubtitle()
      const filename = `${header} - ${subtitle} - ${fullDateTime}${ext}`
      download(response, filename, type)
    } catch (error) {}
  }

  // ** Export Function ============================================
  const handleExportClick = (param?: ClickParam) => {
    if (param?.key === 'csv') {
      exportCSV('.csv', 'text/csv')
    }
  }

  // =================================================================

  return {
    getPeriodMenu,
    getFilterMenu,
    getExportMenu,
    handlePeriodClick,
    handleExportClick
  }
}
