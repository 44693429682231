import { useEffect } from 'react'

// ** AntD Imports
import { Form, Select, Tag, message } from 'antd'
import { ColumnsType } from 'antd/es/table'

// ** Constant Imports
import { optionsList } from '../constants/NonCompliant'

// ** Style Imports
import styles from '../styles/non-compliant-data.module.css'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'
import useAnalytics from '../hooks/useAnalytics'

// ** Type/Enum Imports
import { SuggestedActions } from '../constants/NonCompliant'

// =================================================================
const success = () => {
  message.success(`Status updated successfully!`)
}

const error = (errorMsg: string) => {
  message.error(errorMsg || 'Something went wrong. Please try again later.')
}

export default function useNonCompliantData() {
  // ** Util function to render options **
  const renderOption = (option: {
    bgColor: string
    fontColor: string
    value: string
  }) => (
    <Select.Option
      value={option.value}
      key={option.value}
      className={styles.option}
    >
      <Tag
        color={option.bgColor}
        style={{
          color: option.fontColor
        }}
        className={styles.tag}
      >
        {option.value}
      </Tag>
    </Select.Option>
  )

  // ** Store **
  const {
    editedSuggestedActionKey,
    setEditedSuggestedActionKey,
    errorMsg,
    nonCompliantTableSort
  } = useAnalyticsStore()

  // ** API Services **
  const {
    handlePostSuggestedAction,
    postSuggestedActionLoading,
    postSuggestedActionSuccess,
    postSuggestedActionError
  } = useAnalytics({
    responseType: undefined
  })

  const isLoading = (key: number) => {
    return key === editedSuggestedActionKey && postSuggestedActionLoading
  }

  // ** Component updates after calling Post Suggested Actions API
  useEffect(() => {
    if (postSuggestedActionSuccess) {
      success()
      setEditedSuggestedActionKey(null)
    }

    if (postSuggestedActionError) error(errorMsg)
    // eslint-disable-next-line
  }, [postSuggestedActionSuccess, postSuggestedActionError])

  // ** Display a dash if cell value is empty
  const renderCell = (text: string) => text || '-'

  const getSortOrder: any = (column: number) => {
    const sortSplit = nonCompliantTableSort.split(':')
    const sortColumn = Number(sortSplit[0])
    const sortOrder = `${sortSplit[1]}end`
    return column === sortColumn ? sortOrder : null
  }

  const columns: ColumnsType<any> = [
    {
      width: 120,
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      key: 0,
      sorter: true,
      sortOrder: getSortOrder(0),
      render: renderCell
    },
    {
      width: 140,
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 1,
      sorter: true,
      sortOrder: getSortOrder(1),
      render: renderCell
    },
    {
      width: 200,
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 2,
      sorter: true,
      sortOrder: getSortOrder(2),
      render: renderCell
    },
    {
      width: 140,
      title: 'Invoice Amount',
      dataIndex: 'invoice_amount',
      key: 3,
      sorter: true,
      sortOrder: getSortOrder(3),
      render: renderCell
    },
    {
      width: 100,
      title: 'Currency',
      dataIndex: 'currency',
      key: 4,
      sorter: true,
      sortOrder: getSortOrder(4),
      render: renderCell
    },
    {
      width: 200,
      title: 'PO Number',
      dataIndex: 'po_number',
      key: 5,
      sorter: true,
      sortOrder: getSortOrder(5),
      render: renderCell
    },
    {
      width: 200,
      title: 'PO Owner',
      dataIndex: 'po_owner',
      key: 6,
      sorter: true,
      sortOrder: getSortOrder(6),
      render: renderCell
    },
    {
      width: 120,
      title: 'PO Date',
      dataIndex: 'po_date',
      key: 7,
      sorter: true,
      sortOrder: getSortOrder(7),
      render: renderCell
    },
    {
      width: 200,
      title: 'Department',
      dataIndex: 'department_name',
      key: 8,
      sorter: true,
      sortOrder: getSortOrder(8),
      render: renderCell
    },
    {
      width: 200,
      title: 'Subsidiary',
      dataIndex: 'subsidiary_name',
      key: 9,
      sorter: true,
      sortOrder: getSortOrder(9),
      render: renderCell
    },
    {
      width: 150,
      title: 'Violation Type',
      dataIndex: 'violation_type',
      key: 10,
      sorter: true,
      sortOrder: getSortOrder(10),
      render: renderCell
    },
    {
      width: 250,
      title: <div className={styles.center}>Suggested Action</div>,
      dataIndex: 'suggested_action',
      key: 11,
      sorter: true,
      sortOrder: getSortOrder(11),
      render: (text: string, record: any, rowIndex: number) => {
        const allActions = Object.values(SuggestedActions)

        /**
         * ? Render condition
         * IF value is included in SuggestedActions enum, render the dropdown
         * ELSE render only the status as plain text
         */
        if (allActions.includes(text as unknown as SuggestedActions)) {
          return (
            <div className={styles.actionWrap}>
              <Form.Item style={{ margin: 0 }} initialValue={text}>
                <Select
                  key={record.key}
                  className={styles.customDropdown}
                  style={{
                    width: 230,
                    padding: 0,
                    margin: 0
                  }}
                  onChange={(value) => {
                    const data = {
                      invoice_id: record.invoice_id,
                      purchase_order_id: record.purchase_order_id,
                      action: value
                    }

                    setEditedSuggestedActionKey(record.key)
                    handlePostSuggestedAction(data)
                  }}
                  value={text}
                  loading={isLoading(record.key)}
                  disabled={isLoading(record.key)}
                >
                  {optionsList.map(renderOption)}
                </Select>
              </Form.Item>
            </div>
          )
        }
        return (
          <div className={styles.suggestedActionCell}>
            {text}
            {record.action_updated_at && ` (${record.action_updated_at})`}
          </div>
        )
      }
    }
  ]

  return {
    columns
  }
}
