import _ from 'underscore'

class DaysTypeEnum {
  key: string
  dscp: string
  color: string
  bgColor: string

  constructor(key: string, dscp: string, color: string, bgColor: string) {
    this.key = key
    this.dscp = dscp
    this.color = color
    this.bgColor = bgColor
  }
}

const toReturn: Record<string, DaysTypeEnum> = {
  CALENDAR: new DaysTypeEnum('Calendar', 'Calendar', '#00a3ed', '#ddf4ff'),
  WEEKDAYS: new DaysTypeEnum('Weekdays', 'Weekdays', '#ff9600', '#ff96003b')
}

const methods = {
  getList: (): DaysTypeEnum[] => {
    let keyList: DaysTypeEnum[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o)
      }
    })
    return keyList
  },
  getKeyList: (): string[] => {
    let keyList: string[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o.key)
      }
    })
    return keyList
  },
  getDscpFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })
    return found ? found.dscp : ''
  },
  getColorFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.color : ''
  },
  getBgColorFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.bgColor : ''
  },
  getKeyValueList: (): { id: string; value: string }[] => {
    let keyList: { id: string; value: string }[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push({ id: o.key, value: o.dscp })
      }
    })
    return keyList
  }
}

const DaysTypeEnumObject = {
  ...toReturn,
  ...methods
}

export default Object.freeze(DaysTypeEnumObject)
