import { AxiosError } from 'axios'
import HttpStatus from '../helpers/constants/HttpStatus'

export default async function logout(callback: () => void): Promise<void> {
  try {
    await callback()
    localStorage.clear()
    window.location.href = '/login'
  } catch (error) {
    const err = error as AxiosError
    console.log(err.response)
    if (err.response?.status === HttpStatus.HTTP_UNAUTHORIZED) {
      localStorage.clear()
      window.location.href = '/login'
    }
  }
}
