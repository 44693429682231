import { Button, Divider } from 'antd'

import { DragDropContext } from 'react-beautiful-dnd'

import ListDroppable from './components/ListDroppable'

import { DEFAULT_BTN_PROPS } from '../../filteringNew/constants'

type TProps = {
  availableFields: any
  setAvailableFields: any
  selectedFields: any
  setSelectedFields: any
}

const FilteringColumn = ({
  availableFields,
  setAvailableFields,
  selectedFields,
  setSelectedFields
}: TProps) => {
  const handleOnDragEnd = (result: any) => {
    const { source, destination } = result

    if (!destination) return

    const sourceId = source.droppableId
    const destinationId = destination.droppableId

    if (sourceId === destinationId) {
      /**
       * User executes re-ordering of fields
       */
      const items =
        sourceId === 'available-fields'
          ? Array.from(availableFields)
          : Array.from(selectedFields)

      const [reorderedItems] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItems)

      if (sourceId === 'available-fields') {
        setAvailableFields(items)
      } else {
        setSelectedFields(items)
      }
    } else {
      /**
       * User executes dragging between available & selected fields container
       */
      const sourceItems =
        sourceId === 'available-fields'
          ? Array.from(availableFields)
          : Array.from(selectedFields)

      const destinationItems =
        destinationId === 'available-fields'
          ? Array.from(availableFields)
          : Array.from(selectedFields)

      const [movedItem] = sourceItems.splice(source.index, 1)
      destinationItems.splice(destination.index, 0, movedItem)

      if (sourceId === 'available-fields') {
        setAvailableFields(sourceItems)
        setSelectedFields(destinationItems)
      } else {
        setAvailableFields(destinationItems)
        setSelectedFields(sourceItems)
      }
    }
  }

  const handleOnClickX = (index: number) => {
    const sourceItems = Array.from(selectedFields)
    const destinationItems = Array.from(availableFields)

    const [movedItem] = sourceItems.splice(index, 1)
    destinationItems.push(movedItem)

    setAvailableFields(destinationItems)
    setSelectedFields(sourceItems)
  }

  const handleOnClickCaret = (index: number) => {
    const sourceItems = Array.from(availableFields)
    const destinationItems = Array.from(selectedFields)

    const [movedItem] = sourceItems.splice(index, 1)
    destinationItems.push(movedItem)

    setSelectedFields(destinationItems)
    setAvailableFields(sourceItems)
  }

  const handleRemoveAll = () => {
    setAvailableFields([...availableFields, ...selectedFields])
    setSelectedFields([])
  }

  const BtnPrimary = () => {
    return (
      <Button
        {...DEFAULT_BTN_PROPS}
        className='g-btn-default'
        onClick={handleRemoveAll}
      >
        Remove all
      </Button>
    )
  }

  return (
    <>
      {/* Remove all button for selected fields list */}
      <div style={{ textAlign: 'right' }}>
        <BtnPrimary />
      </div>

      {/* Field container labels */}
      <div style={{ display: 'flex', gap: '40px' }}>
        <div style={{ width: '50%' }}>
          <Divider orientation='left'>Available Fields</Divider>
        </div>
        <div style={{ width: '50%' }}>
          <Divider orientation='left'>Selected Fields</Divider>
        </div>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div style={{ display: 'flex', gap: '40px' }}>
          {/* Available fields container */}
          <ListDroppable
            droppableId='available-fields'
            dataSource={availableFields}
            actions={false}
            handleOnClickCaret={handleOnClickCaret}
          />

          {/* Selected fields container */}
          <ListDroppable
            droppableId='selected-fields'
            dataSource={selectedFields}
            actions={true}
            handleOnClickX={handleOnClickX}
          />
        </div>
      </DragDropContext>
    </>
  )
}

export default FilteringColumn
