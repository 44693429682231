import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'
import { Space } from 'antd'
import { FC, useEffect, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import GButton from '../gappify/GButton'
import GDropdownBtn from '../gappify/GDropdownBtn'
import GIcon from '../gappify/GIcon'
import { useSelector } from 'react-redux'

interface QjePeriodAndFilterDropdownProps {
  getFilterMenu?: any
  truncateFilterLabel?: any
  getFilterLabel?: any
  isPreviewMode?: any
  previewTriggeredFrom?: any
  setIsShowCreateNewFilterModal?: any
  setIsShowUpdateFilterModal?: any
  reloadData?: any

  // refs
  selectedFilterRef: any
  selectedPeriodRef: any
}

const QjePeriodAndFilterDropdown: FC<QjePeriodAndFilterDropdownProps> = ({
  getFilterMenu,
  truncateFilterLabel,
  getFilterLabel,
  isPreviewMode,
  previewTriggeredFrom,
  setIsShowCreateNewFilterModal,
  setIsShowUpdateFilterModal,
  reloadData,

  // refs
  selectedFilterRef
}: QjePeriodAndFilterDropdownProps): JSX.Element => {
  const selectedGlobalFilter = useSelector(
    (state: any) => state.allQjes.selectedFilter
  )

  const [selectedFilter, setSelectedFilter] = useState<any>(
    selectedFilterRef.current
  )

  const handleOnClickFilter = (item: any) => {
    // setIsPreviewMode(false)
    if (item.key === 'new') {
      setIsShowCreateNewFilterModal(true)
    } else {
      // update local state
      setSelectedFilter({
        id: item.key,
        label: getFilterLabel(item.key)
      })
      // update ref
      selectedFilterRef.current = {
        id: item.key,
        label: getFilterLabel(item.key)
      }
      reloadData()
    }
  }

  // update local state when global state is updated
  useEffect(() => {
    // update local state
    setSelectedFilter(selectedGlobalFilter)
    // update ref
    selectedFilterRef.current = selectedGlobalFilter
    reloadData()
    // eslint-disable-next-line
  }, [selectedGlobalFilter])

  return (
    <Space size={1}>
      <GDropdownBtn
        className='g-btn-default'
        dataTestId='qje-filter-dropdown'
        dataCy='qje-filter-dropdown'
        onClick={handleOnClickFilter}
        menuItems={getFilterMenu()}
        btnText={truncateFilterLabel(`${selectedFilter.label}`)}
        children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
        overlayStyle={{
          maxHeight: '300px',
          overflowY: 'scroll',
          boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.1)'
        }}
      />
      {isPreviewMode ? (
        <GButton
          type='text'
          dataTestId='qje-preview-btn'
          dataCy='qje-preview-btn'
          className='g-btn-icon'
          onClick={() => {
            if (previewTriggeredFrom === 'new')
              setIsShowCreateNewFilterModal(true)
            if (previewTriggeredFrom === 'update')
              setIsShowUpdateFilterModal(true)
          }}
          icon={<GIcon icon={faEye} />}
        />
      ) : !['all', 'unposted', 'posted', 'rejected'].includes(
          selectedFilter.id
        ) ? (
        <GButton
          type='text'
          dataTestId='qje-update-filter-btn'
          dataCy='qje-update-filter-btn'
          className='g-btn-icon'
          onClick={() => setIsShowUpdateFilterModal(true)}
          icon={<GIcon icon={faPencil} />}
        />
      ) : null}
    </Space>
  )
}

export default QjePeriodAndFilterDropdown
