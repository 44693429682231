import {
  axiosIdentityManagerInstance,
  axiosMfaInstance
} from '../helpers/axios'
import { AUTH_TOKEN } from '../helpers/constants/Auth'

const token = localStorage.getItem(AUTH_TOKEN)
const headers = {
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  ContentType: 'application/json'
}

export const loginUser = (data: object) => {
  return axiosIdentityManagerInstance.post('/api/login', data)
}

export const refreshToken = () => {
  return axiosIdentityManagerInstance.get('/api/refresh').catch((err) => {
    console.log(err)
  })
}

export const loginGoogle = () => {
  return axiosIdentityManagerInstance.get('/login/google')
}

export const loginOkta = (params: object) => {
  return axiosIdentityManagerInstance.get('/login/okta', { params })
}

export const loginAzure = (params: object) => {
  return axiosIdentityManagerInstance.get('/login/azure', { params })
}

export const loginSsoUser = (params: string) => {
  return axiosIdentityManagerInstance.get('/api/login/sso', { params })
}

export const logoutUser = () => {
  return axiosIdentityManagerInstance.get('/api/logout')
}

export const logoutUserOkta = () => {
  // TODO
  // not yet passing logout okta to idms
  // return axiosIdentityManagerInstance.get('/logout/okta')
}

export const getRolesAndPermission = () => {
  return axiosIdentityManagerInstance.get('/api/ui-actions')
}

export const loginMfa = (params: any, mfaToken: string) => {
  return axiosMfaInstance.post('/api/login/mfa', params, {
    headers: {
      Authorization: `Bearer ${mfaToken}`,
      Accept: 'application/json',
      ContentType: 'application/json'
    }
  })
}

export const generateNewToken = (mfaToken: string) => {
  return axiosMfaInstance.get('api/login/mfa-resend', {
    headers: {
      Authorization: `Bearer ${mfaToken}`,
      Accept: 'application/json',
      ContentType: 'application/json'
    }
  })
}

export const getRoles = () => {
  return axiosIdentityManagerInstance.get('/api/role', {
    headers
  })
}
