export type TFilterData = {
  name: string
  report: string
  filter_fields: TFilterFields[]

  // Additional optional payloads
  order_by?: string
  period?: string | undefined
  months_to_show?: string | number
  with_count?: string | number
  non_responsive_only?: string | number
  preview_mode?: number
}

export type TFilterFields = {
  column: string
  value: string[]
}

export type TFilterDropdownObj = {
  key: number
  label: string
  filter_fields: string
  search_params: string
  name?: string
  id?: number
}

export enum EModalActions {
  CREATE,
  UPDATE,
  DELETE
}
