import { SetStateAction } from 'react'
import {
  getFxRateAmount,
  getLookupTransactionCurrency
} from '../../services/TxnManagerAPI'
import { message } from 'antd'
import { updateTrdTransactions } from '../../services/TrdManagerAPI'
import type { AxiosError } from 'axios'
import _ from 'underscore'
import moment from 'moment'
import TransactionLine from 'src/types/TransactionLine'

export const checkThreshold = (
  row: any,
  isDocsAlwaysRequired: boolean,
  index: number,
  setErrorFields: SetStateAction<any>,
  errorFields: any,
  isThreshDocRequired: boolean
) => {
  if (
    row['g_threshold_support_document'] === null ||
    row['g_threshold_support_document'] === ''
  ) {
    if (isDocsAlwaysRequired || isThreshDocRequired) {
      setErrorFields({
        ...errorFields,
        [index]: {
          ...errorFields[index],
          g_threshold_support_document: [
            'Threshold Support Document field is required'
          ]
        }
      })
    } else {
      if (!_.isEmpty(errorFields)) {
        setErrorFields(deleteKey(errorFields, index))
        removeItemFromArray(
          row['fieldErrors']['keys'],
          'g_threshold_support_document'
        )
        delete row['fieldErrors']['msg']['g_threshold_support_document']
      }
    }
  } else {
    if (!_.isEmpty(errorFields)) {
      setErrorFields(deleteKey(errorFields, index))
      removeItemFromArray(
        row['fieldErrors']['keys'],
        'g_threshold_support_document'
      )
      delete row['fieldErrors']['msg']['g_threshold_support_document']
    }
  }
}
const removeItemFromArray = (array: any, item: any) => {
  const index = array.indexOf(item)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

const deleteKey = (object: any, key: number) => {
  if (key in object) {
    delete object[key]
  }
  return object
}

export const setFxRateAmount = async (row: any): Promise<void> => {
  const gAccrualAmount = Math.abs(row['g_accrual_amount'])
  const gTransactionCurrency = row['g_transaction_currency']
  const gSubsidiaryCurrency = row['g_subsidiary_currency']
  const gReportingCurrency = row['g_reporting_currency']

  if (gTransactionCurrency === gSubsidiaryCurrency) {
    row['g_subsidiary_amount'] = gAccrualAmount
  } else {
    const payload = {
      fromCurrency: gTransactionCurrency,
      toCurrency: gSubsidiaryCurrency,
      accrualAmount: gAccrualAmount
    }

    try {
      const { data } = await getFxRateAmount(payload)
      if (data) {
        row['g_subsidiary_amount'] = parseFloat(
          data
            .toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
            .split(',')
            .join('')
        )
      } else {
        row['g_subsidiary_amount'] = gAccrualAmount
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  if (gTransactionCurrency === gReportingCurrency) {
    row['g_reporting_amount'] = gAccrualAmount
  } else {
    const payload = {
      fromCurrency: gTransactionCurrency,
      toCurrency: gReportingCurrency,
      accrualAmount: gAccrualAmount
    }

    try {
      const { data } = await getFxRateAmount(payload)
      if (data) {
        row['g_reporting_amount'] = parseFloat(
          data
            .toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
            .split(',')
            .join('')
        )
      } else {
        row['g_reporting_amount'] = parseFloat(
          gAccrualAmount
            .toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
            .split(',')
            .join('')
        )
      }
      // setIsThresholDocsRequired(false)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }
}

export const getSubsidiary = async (data: any, row: any) => {
  const payload = {
    subsidiary: data['g_subsidiary']
  }

  try {
    const { data } = await getLookupTransactionCurrency(payload)
    if (data) {
      row['g_subsidiary_currency'] = data
    }
  } catch (error) {
    const { response } = error as AxiosError
    console.log(response)
  }
}

export const checkRequired = (
  row: any,
  key: string,
  label: string,
  index: number,
  setErrorFields: SetStateAction<any>,
  errorFields: any
) => {
  if (row[key] === null || row[key] === '') {
    setErrorFields({
      ...errorFields,
      [index]: {
        [key]: [`${label} field is required`]
      }
    })
  } else {
    if (!_.isEmpty(errorFields)) {
      setErrorFields(deleteKey(errorFields, index))
      delete row['fieldErrors']
    }
  }
}

export const submitEditLine = async (
  editedRows: any[],
  setErrorFields: SetStateAction<any>,
  errorFields: any,
  setEditedRows: SetStateAction<any>
) => {
  if (_.isEmpty(errorFields)) {
    if (editedRows.length > 0) {
      try {
        /**
         * ENG-6975 (https://gappify.atlassian.net/browse/ENG-6975)
         * ? Custom condition:
         * ? Checks if page is from Review Center to add additional payload
         * ? Requested by Paolo
         *
         * ? New payload:
         * * rc_inline_update: true
         */
        const currentURL = window.location.href
        if (currentURL.includes('review-center')) {
          editedRows = editedRows.map((item) => {
            return { ...item, rc_inline_update: true }
          })
        }

        /**
         * AC-1418 (https://gappify.atlassian.net/browse/AC-1418)
         * ? Remove g_status from payload
         * ? The updating of status is handled on the BE side
         */
        editedRows = editedRows.map((item) => {
          item.g_status && delete item.g_status
          return item
        })

        const { data } = await updateTrdTransactions(editedRows)

        if (data.success) {
          message.success('Changes successfully saved!')
        }

        setErrorFields([])
        setEditedRows([])
        return true
      } catch (error) {
        const { response } = error as AxiosError
        const errMsg: any = response?.data
        message.error(errMsg.message)
        setErrorFields(errMsg.error_message)

        let errObject = Object.entries(errorFields)
        let errMsgList: any[] = []
        errObject &&
          errObject.forEach(([key, value]) => {
            let item: any = value
            let valueObj = Object.entries(item)

            valueObj &&
              valueObj.forEach(([key, value]) => {
                let msg: any = value
                if (errMsgList.includes(msg[0]) || errMsgList.length === 0) {
                  errMsgList.push(msg)
                }
              })
          })
        errMsgList.forEach((item) => message.error(item))
      }
    }
  } else {
    message.error('Your changes could not be saved.')

    let errObject = Object.entries(errorFields)
    let errMsgList: any[] = []
    errObject &&
      errObject.forEach(([key, value]) => {
        let item: any = value
        let valueObj = Object.entries(item)

        valueObj &&
          valueObj.forEach(([key, value]) => {
            let msg: any = value
            if (!errMsgList.includes(msg[0]) || errMsgList.length === 0) {
              errMsgList.push(msg[0])
            }
          })
      })
    errMsgList.forEach((item) => message.error(item))
  }
  return false
}

export const checkOverrideReason = (
  editedRows: any,
  errorFields: any[],
  setErrorFields: SetStateAction<any>
) => {
  editedRows.forEach((item: any) => {
    if (!Object.keys(item).includes('override_reason')) {
      setErrorFields({
        ...errorFields,
        [item.index]: {
          ...errorFields[item.index],
          override_reason: [`Override Reason field is required`]
        }
      })
    }
  })
}

export const handleOnSaveEdit = (
  originalList: any[],
  editedRows: any[],
  displayThresholdSupport: boolean,
  thresholdAmount: number,
  errorFields: any[],
  setErrorFields: SetStateAction<any>,
  hasOverrideColumn: boolean,
  requireOverrideExp: boolean,
  transactionLines: any[],
  setEditedRows: SetStateAction<any>
) => {
  let errors: any[] = []
  editedRows.forEach((item) => {
    if (displayThresholdSupport) {
      const isDocsAlwaysRequired =
        parseFloat(
          originalList[item.index]['g_reporting_amount'] &&
            originalList[item.index]['g_reporting_amount']
              .toString()
              .replace(',', '')
        ) >= parseFloat(thresholdAmount.toString())
      let reportingAmount = item['g_reporting_amount']
        ? item['g_reporting_amount']
        : transactionLines[item.index]['g_reporting_amount']

      if (
        isDocsAlwaysRequired ||
        (reportingAmount &&
          parseFloat(reportingAmount.toString().split(',').join('')) >=
            parseFloat(thresholdAmount.toString()))
      ) {
        let thresholdDocs = item['g_threshold_support_document']
          ? item['g_threshold_support_document']
          : transactionLines[item.index]['g_threshold_support_document']
        if (
          thresholdDocs === null ||
          thresholdDocs === undefined ||
          thresholdDocs === ''
        )
          errors = {
            ...errors,
            [item.index]: {
              ...errors[item.index],
              g_threshold_support_document: [
                'Threshold Support Document field is required'
              ]
            }
          }
      } else {
        if (
          errorFields[item.index] &&
          errorFields[item.index]['g_threshold_support_document']
        ) {
          delete errorFields[item.index]['g_threshold_support_document']
        }
      }
    }

    if (hasOverrideColumn && requireOverrideExp) {
      if (!item['override_reason']) {
        errors = {
          ...errors,
          [item.index]: {
            ...errors[item.index],
            override_reason: ['Override Reason field is required']
          }
        }
      } else {
        if (
          errorFields[item.index] &&
          errorFields[item.index]['override_reason']
        ) {
          delete errorFields[item.index]['override_reason']
        }
      }
    }
  })

  setErrorFields(errors)

  return submitEditLine(editedRows, setErrorFields, errors, setEditedRows)
}

export const getTxnColumns = (data: any, periodFilter: string) => {
  const featureFlags = data['transaction.feature_flags']
  const consoStartDate =
    featureFlags['consolidated_transaction_lines_start_date']
  const consoTxnColumns = data['transaction_manager_columns']
  const oldTxnColumns = data['transaction_manager_columns_old']

  const isBeforeConsoStartDate =
    periodFilter < moment(consoStartDate, 'YYYY-MM').format('YYYY-MM') // get the month year only

  return isBeforeConsoStartDate
    ? oldTxnColumns
      ? oldTxnColumns
      : []
    : consoTxnColumns
}

export const getEmailToken = (type: string, row: TransactionLine) => {
  return type === 'vdac_g_status' ? row.vdac_token_id : row.open_token_id
}
