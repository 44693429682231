// ** React Imports
import { useCallback } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Services Imports
import { LoginAuditTrailAPI } from '../services/LoginAuditTrailAPI'

// ** Reducer Imports
import {
  setData,
  setIsLoading,
  setPage,
  setPageSize,
  setTotalItems,
  setExportIsLoading
} from '../redux/reducer'

// ** Store Imports
import { RootState } from '../../../../../context/store'

// ** Third Party Imports
import download from 'downloadjs'

// =======================================================================================
export default function useAPI() {
  // ** API **
  const { getLoginAuditTrailTable } = LoginAuditTrailAPI()

  // ** Redux **
  const dispatch = useDispatch()
  const { page, sort, exportType, pageSize } = useSelector(
    (state: RootState) => state.loginAuditTrail
  )

  // ** Date(used for Export CSV title) **
  const date = new Date().toISOString().slice(0, 10)

  // ** Fetch Table Data =================================================================
  const fetchData = useCallback(async () => {
    dispatch(setIsLoading(true))
    try {
      const response = await getLoginAuditTrailTable({
        params: {
          perPage: pageSize, // Set to lower for demo purposes (default 100 / pageSize)
          page: page,
          sort: sort
        },
        options: {
          endpoint: ''
        }
      })
      const { data } = response

      dispatch(setData(data?.data))
      dispatch(setPage(data?.current_page))
      dispatch(setPageSize(data?.per_page))
      dispatch(setTotalItems(data?.total))
      dispatch(setIsLoading(false))
    } catch (error) {}
  }, [getLoginAuditTrailTable, page, sort, dispatch, pageSize])

  // ** Export CSV ======================================================================
  const exportCSV = async (ext: string, type: string) => {
    dispatch(setExportIsLoading(true))
    try {
      const response = await getLoginAuditTrailTable({
        params: {
          perPage: 999999
        },
        options: {
          endpoint: '/export'
        }
      })
      if (response.status === 200) {
        dispatch(setExportIsLoading(false))
        download(response?.data, `LoginAudit${date}${ext}`, exportType)
      }
    } catch (error) {}
  }

  return {
    fetchData,
    exportCSV
  }
}
