/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** api services imports */
import useGlobalService from '../services/globalService'

/** hooks imports */
import useApiService from './useApiService'

// =================================================================
export default function useFeatureFlags() {
  /** api service */
  const { globalServiceApi } = useGlobalService()

  /** hooks */
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['feature-flags'],
    queryFn: () =>
      globalServiceApi({
        params: {},
        data: {},
        options: {
          baseURL: msUrl.idms,
          endpoint: '/api/next-flag',
          method: 'GET'
        }
      })
  })
}
