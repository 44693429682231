import moment from 'moment'
import { FC, SetStateAction, useEffect, useRef, useState } from 'react'
import GDynamicTable from '../components/gappify/GDynamicTable'
import QjeRowDropDownButtons from '../components/qje/QjeRowDropDownButtons'
import {
  mergeSimilarRows,
  settingsToGridColumns,
  fetchJEs,
  SortInfo,
  maxRecordsPerPage
} from '../utils/qje/qje-utils'
import { qjeExport, qjeSettings } from '../services/QjeManagerApi'
import download from 'downloadjs'
import { useDispatch, useSelector } from 'react-redux'
import { setReviewedPOData as setQjeData } from '../context/actions/qjeAction'
import { message } from 'antd'
import QjeReviewedBulkUpdateComponent from '../components/qje/QjeReviewedBulkUpdateComponent'
import { CheckOutlined } from '@ant-design/icons'
import QjePager from '../components/qje/QjePager'
import {
  getDateFormat,
  getDateTimeMomentFormat,
  getMomentTimeFormat
} from '../utils/Date'
import { getGlobalSettings } from '../services/TxnManagerAPI'
import type { AxiosError } from 'axios'

// ** Zustand Store Imports
// ** Custom Types Imports
import QjeTransactionProps from '../types/QjeTransactionsProps'

const QjeReviewedPoClosure: FC = (): JSX.Element => {
  // useDispatch - for updating the global state
  const dispatch = useDispatch()
  const reviewedPoClosureData = useSelector(
    (state: any) => state.allQjes.reviewedPOData
  )
  const selectedPeriod = useSelector(
    (state: any) => state.allQjes.selectedPeriod
  )

  // refs...
  const selectedPeriodRef = useRef(selectedPeriod)

  // const [data, setData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<SetStateAction<any>>(true)
  // const [activePeriod, setActivePeriod] = useState<
  //   { period: string } | undefined
  // >(getDefaultPeriod())
  const [settings, setSettings] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number | undefined>(1)
  const [totalDataSize, setTotalDataSize] = useState<number>(0)
  const [pageSizeSetting, setPageSizeSetting] = useState<number | undefined>(
    500
  )

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    field: 'created_at',
    order: 'ascend'
  })

  const [isTrdFlagEnabled, setIsTrdEnabled] = useState<boolean>(false)
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false)

  const handleOnClickPeriod = (item: any) => {
    const newPeriod = {
      period: moment(item.key, 'YYYY-MM-DD').format('YYYY-MM-DD')
    }

    selectedPeriodRef.current = newPeriod
    reloadData()
  }

  const handleOnClickExport = async (item: any) => {
    setIsExportLoading(true)
    try {
      const parameters = {
        filter: 'all',
        period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
        subtype: 'reviewed',
        type: 'PO',
        sortField: sortInfo.field,
        sortDir: sortInfo.order === 'ascend' ? 'asc' : 'desc',
        recordsPerPage: maxRecordsPerPage
      }
      const { data, headers } = await qjeExport(item.key, parameters)
      const content = headers['content-type']

      if (item.key === 'excel') {
        download(
          data,
          `QJE-${moment(selectedPeriodRef.current.period).format(
            'YYYY-MM-DD'
          )}-JE.xlsx`,
          content
        )
      } else {
        download(
          data,
          `QJE-${moment(selectedPeriodRef.current.period).format(
            'YYYY-MM-DD'
          )}-JE.${item.key}`,
          content
        )
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsExportLoading(false)
    }
  }

  const getTrdFlag = async () =>
    await getGlobalSettings().then((res) => {
      const { data } = res
      const txnTab = data['transaction.feature_flags']
      return txnTab.enable_new_transactions
    })

  useEffect(() => {
    getTrdFlag().then((res: boolean) => setIsTrdEnabled(res))
  }, [])

  const getQjeColumns = () => {
    let columns: any[] = []

    if (settings && Object.keys(settings).length > 0) {
      settingsToGridColumns(settings, isTrdFlagEnabled).forEach(
        (item: any, index: number) => {
          const apiName = item.api_name ?? 'no-api-name'
          item.is_sortable = true
          // for columns with custom rendering
          if (item.api_name === 'g_resubmitted') {
            columns.push({
              ...item,
              render: (value: any, row: any, index: number) => {
                const obj: any = {
                  children: (
                    <div
                      className='qje-column'
                      data-testid={`qje-review-je-${apiName}-${index}`}
                      data-cy={`qje-review-je-${apiName}-${index}`}
                    >
                      {row.g_resubmitted === 1 && (
                        <div
                          style={{
                            margin: 'auto',
                            display: 'block'
                          }}
                        >
                          <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>
                        </div>
                      )}
                    </div>
                  )
                }
                return obj
              }
            })
          } else if (item.api_name === 'g_reviewed') {
            columns.push({
              ...item,
              width: 100,
              render: (value: any, row: any, index: number) => {
                const obj: any = {
                  children: (
                    <QjeReviewedBulkUpdateComponent
                      index={index}
                      initialValue={row.g_reviewed}
                      reload={reloadData}
                      row={row}
                    />
                  ),
                  props: {
                    rowSpan: row.rowSpan
                  }
                }
                return obj
              }
            })
          } else if (item.api_name === 'g_is_prepaid') {
            columns.push({
              ...item,
              render: (
                value: number,
                row: QjeTransactionProps,
                index: number
              ) => {
                const obj: any = {
                  children: (
                    <div
                      className='qje-column'
                      data-testid={`qje-reviewed-po-closure-${apiName}-${index}`}
                      data-cy={`qje-reviewed-po-closure-${apiName}-${index}`}
                    >
                      {row.g_is_prepaid === 1 && (
                        <div
                          style={{
                            margin: 'auto',
                            display: 'block'
                          }}
                        >
                          <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
                        </div>
                      )}
                    </div>
                  )
                }
                return obj
              }
            })
          } else if (item.api_name === 'created_at') {
            columns.push({
              ...item,
              width: 150,
              render: (value: any, row: any, index: number) => {
                const obj: any = {
                  children: (
                    <div
                      className='qje-column'
                      data-testid={`qje-review-je-${apiName}-${index}`}
                      data-cy={`qje-review-je-${apiName}-${index}`}
                    >
                      {getDateTimeMomentFormat(
                        value,
                        getDateFormat('m/d/Y'),
                        getMomentTimeFormat('h:i a')
                      )}
                    </div>
                  )
                }
                return obj
              }
            })
          } else
            columns.push({
              ...item,
              render: (value: any, row: any, index: number) => {
                const obj: any = {
                  children: (
                    <div
                      className='qje-column'
                      data-testid={`qje-review-je-${apiName}-${index}`}
                      data-cy={`qje-review-je-${apiName}-${index}`}
                    >
                      {value}
                    </div>
                  )
                }
                return obj
              }
            })
        }
      )
    }

    // fixed position
    columns = columns.map((column: any, index: number) => {
      if (index < 2) return { ...column, fixed: 'left' }
      else return column
    })

    return columns
  }

  const reloadData = () => {
    dispatch(setQjeData([]))
    setIsLoading(true)
    const parameters = {
      filter: 'all',
      filter_fields: [],
      page: currentPage,
      period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
      previewMode: false,
      subtype: 'reviewed',
      type: 'PO',
      recordsPerPage: pageSizeSetting
    }

    fetchJEs(parameters)
      .then((res) => {
        dispatch(setQjeData(res.data))
        setTotalDataSize(parseInt(res.headers['x-total-count']))
        setIsLoading(false)
      })
      .catch((err) => {
        message.error('An error occurred.')
        console.log(err)
      })
  }

  //handles pagination
  const handleTablePagination = (page: number, pageSize: number) => {
    handleTableChange({ page: page, pageSize: pageSize }, {}, sortInfo)
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ): Promise<void> => {
    let _pageSize = pagination.pageSize ?? pageSizeSetting
    let _page = pagination.page ?? 1

    setIsLoading(true)
    setCurrentPage(_page)
    setSortInfo(sorter)
    setPageSizeSetting(_pageSize)

    const params = {
      page: _page,
      filter: 'all',
      period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
      previewMode: false,
      subtype: 'reviewed',
      type: 'PO',
      sortField: sorter.field,
      sortDir: sorter.order === 'ascend' ? 'asc' : 'desc',
      recordsPerPage: _pageSize
    }

    try {
      const { data } = await fetchJEs(params)
      dispatch(setQjeData(data))
      setCurrentPage(pagination.current)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      message.error('An error occured.')
      console.error(error)
    }
  }

  useEffect(() => {
    reloadData()
    // eslint-disable-next-line
  }, [selectedPeriodRef.current])

  useEffect(() => {
    const loadSettings = async () => {
      try {
        let { data } = await qjeSettings(
          'PO',
          `${moment(selectedPeriodRef.current.period).format('YYYY-MM-DD')}`,
          'reviewed'
        )
        setSettings(data)
      } catch (error) {
        console.log('Error: ', error)
      }
    }

    loadSettings()
    // eslint-disable-next-line
  }, [selectedPeriodRef.current])

  return (
    <div
      data-testid='qje-reviewed-po-closure'
      data-cy='qje-reviewed-po-closure'
    >
      <div className='qje-table'>
        <GDynamicTable
          data-testid='qje-reviewed-po-closure-supergrid'
          data-cy='qje-reviewed-po-closure-supergrid'
          loading={isLoading}
          columnHeader={getQjeColumns()}
          // columnHeader={qje_column}
          dataSource={mergeSimilarRows(reviewedPoClosureData)}
          showHeader
          hasGlobalFilter
          onClickPeriod={handleOnClickPeriod}
          rightChild={<QjeRowDropDownButtons tab='reviewed-po-closure' />}
          hasExportBtn
          hasExportPermission={true}
          onClickExport={handleOnClickExport}
          onChange={handleTableChange}
          pagination={false}
          isServerSideSort={true}
          isExportLoading={isExportLoading}
          showTxnFilter
        />
        <div className='qje-pagination'>
          <QjePager
            defaultPageSize={pageSizeSetting}
            current={currentPage}
            total={totalDataSize}
            onChange={handleTablePagination}
            pageSize={pageSizeSetting}
            isPo={true}
          />
        </div>
      </div>
    </div>
  )
}

export default QjeReviewedPoClosure
