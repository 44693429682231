/** zustand imports */
import { create } from 'zustand'
import Source from '../types/Source'

interface UploadFileModalStoreProps {
  /** state */
  isModalOpen: boolean
  isImportAccrualEnabled: boolean
  isSourceDataEnabled: boolean
  source: Source | null
  importedAccrualName: string | null
  sourceFile: File | null
  importedAccrualFile: File | null

  /** actions */
  setIsModalOpen: (isModalOpen: boolean) => void
  setIsImportAccrualEnabled: (isImportAccrualEnable: boolean) => void
  setIsSourceDataEnabled: (isSourceDataEnabled: boolean) => void
  setSource: (source: Source | null) => void
  setImportedAccrualName: (importedAccrualName: string | null) => void
  setSourceFile: (sourceFile: File | null) => void
  setImportedAccrualFile: (importedAccrualFile: File | null) => void
}

// =================================================================
const useUploadFileModalStore = create<UploadFileModalStoreProps>((set) => ({
  /** state */
  isModalOpen: false,
  isImportAccrualEnabled: false,
  isSourceDataEnabled: false,
  source: null,
  importedAccrualName: null,
  sourceFile: null,
  importedAccrualFile: null,

  /** actions */
  setIsModalOpen: (isModalOpen: boolean) => set({ isModalOpen }),
  setIsImportAccrualEnabled: (isImportAccrualEnabled: boolean) =>
    set({ isImportAccrualEnabled }),
  setIsSourceDataEnabled: (isSourceDataEnabled: boolean) =>
    set({ isSourceDataEnabled }),
  setSource: (source: Source | null) => set({ source }),
  setImportedAccrualName: (importedAccrualName: string | null) =>
    set({ importedAccrualName }),
  setSourceFile: (sourceFile: File | null) => set({ sourceFile }),
  setImportedAccrualFile: (importedAccrualFile: File | null) =>
    set({ importedAccrualFile })
}))

export default useUploadFileModalStore
