// ** AntD Imports
import { FormInstance, Table } from 'antd'

// ** Data Imports
import MonthlyData from '../../../mock/MonthlyData'

// ** Hook Imports
import useScheduleManager from '../../../hooks/useScheduleManager'

// ** Zustand Imports
import useScheduleStore from '../../../manager/schedule-store'

import styles from '../styles/monthly.module.css'

// =================================================================
const MonthlyTable = ({ form }: { form: FormInstance }) => {
  // ** Data **
  const { columns, rowsFromBackend, editableRowsFromBackend } = MonthlyData()

  // ** Store **
  const { editMonthly } = useScheduleStore()

  // ** Hooks **
  const { getScheduleManagerMonthly } = useScheduleManager()
  const { isLoading, isRefetching } = getScheduleManagerMonthly

  // ** Comment out if we wanna use mock data **
  // const { data } = getScheduleManager
  // console.log(`TABLE data,`, data)
  // useEffect(() => {
  //   setMonthlyData(rows)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // const { msUrl } = useApiService()
  // const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  return (
    <Table
      dataSource={editMonthly ? editableRowsFromBackend : rowsFromBackend}
      columns={columns}
      rowKey='key'
      pagination={false}
      style={{ paddingTop: 20 }}
      loading={isLoading || isRefetching}
      rowClassName={editMonthly ? styles['antd-table-row'] : ''}
    />
  )
}

export default MonthlyTable
