// ** AntD Imports
import { Form, Input, Popconfirm, message } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { DeleteOutlined } from '@ant-design/icons'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'

// ** Style Imports
import styles from '../styles/non-compliant-data.module.css'

// ** Type/Enum Imports
import { SuggestedActions } from '../constants/NonCompliant'
import { SuggestedActions as SuggestedActionsIncreasing } from '../constants/IncreasingSpend'
import { INonCompliantInvoices } from '../types/NonCompliantDataType'
import { TIncreasingSpend } from '../types/IncreasingSpendDataType'

// ==================================================================
// ** Display a dash if cell value is empty
const renderCell = (text: string) => text || '-'

export default function ExecuteActionsData() {
  // ** Zustand **
  const {
    nonCompliantSelectedRowKeys,
    nonCompliantFirstTabData,
    nonCompliantSecondTabData,
    nonCompliantThirdTabData,
    increasingSpendSelectedRowKeys,
    increasingSpendFirstTabData,
    increasingSpendSecondTabData,
    setNonCompliantFirstTabData,
    setNonCompliantSecondTabData,
    setNonCompliantThirdTabData,
    setIncreasingSpendFirstTabData,
    setIncreasingSpendSecondTabData
  } = useAnalyticsStore()

  const handleDelete = ({ record }: any) => {
    const action = record.suggested_action
    let finalTabData: [] | INonCompliantInvoices[] | TIncreasingSpend[],
      setFinalTabData

    // Initialize tab state & tab setter
    // ! Workaround due to type issues to support both Non Compliant & Increasing Spend
    // * Can be refactored
    if (nonCompliantSelectedRowKeys.length) {
      if (action === SuggestedActions.DismissPolicyExemption) {
        finalTabData = nonCompliantFirstTabData
        setFinalTabData = setNonCompliantFirstTabData
      } else if (action === SuggestedActions.DismissOther) {
        finalTabData = nonCompliantSecondTabData
        setFinalTabData = setNonCompliantSecondTabData
      } else {
        finalTabData = nonCompliantThirdTabData
        setFinalTabData = setNonCompliantThirdTabData
      }

      // Perform updating of state by removing/filtering the selected row
      setFinalTabData(
        finalTabData.filter((item: any) => item.key !== record.key)
      )
    } else if (increasingSpendSelectedRowKeys.length) {
      console.log('Increasing spend!!!!')
      if (action === SuggestedActionsIncreasing.Dismiss) {
        console.log('action =', SuggestedActionsIncreasing.Dismiss)
        finalTabData = increasingSpendFirstTabData
        setFinalTabData = setIncreasingSpendFirstTabData
      } else {
        console.log('action =', SuggestedActionsIncreasing.AlertStandard)
        finalTabData = increasingSpendSecondTabData
        setFinalTabData = setIncreasingSpendSecondTabData
      }

      // Perform updating of state by removing/filtering the selected row
      setFinalTabData(
        finalTabData.filter((item: any) => item.key !== record.key)
      )
    }

    // Show success notification
    message.success('Record deleted successfully!')
  }

  const PopupConfirmation = (record: any) => (
    <Popconfirm
      title='Are you sure you want to delete this record?'
      onConfirm={() => handleDelete(record)}
      okText='Yes'
      cancelText='No'
      placement='topRight'
      arrowPointAtCenter={true}
    >
      <DeleteOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}  style={{ cursor: 'pointer', margin: 0, padding: 0 }} />
    </Popconfirm>
  )

  const dismissPolicyExemptionColumns: ColumnType<any>[] = [
    {
      width: 150,
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      render: renderCell
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      render: renderCell
    },
    {
      width: 150,
      title: 'Invoice Amount',
      dataIndex: 'invoice_amount',
      render: renderCell
    },
    {
      width: 200,
      title: 'Violation Type',
      dataIndex: 'violation_type',
      render: renderCell
    },
    {
      width: 100,
      title: <div className={styles.center}>Action</div>,
      render: (text: string, record: any, rowIndex: number) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PopupConfirmation record={record} />
        </div>
      )
    }
  ]

  const violationStandardColumns: ColumnType<any>[] = [
    {
      width: 150,
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      render: renderCell
    },
    {
      width: 350,
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      render: renderCell
    },
    {
      width: 200,
      title: 'Violation Type',
      dataIndex: 'violation_type',
      render: renderCell
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 230,
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item
            name={[record.key, 'email']}
            style={{ margin: 0 }}
            initialValue={text}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email!'
              },
              { required: true, message: 'Email is required' }
            ]}
          >
            <Input style={{ width: '200px' }} />
          </Form.Item>
        )
      }
    },
    {
      title: 'CC Email',
      width: 230,
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item
            name={[record.key, 'cc_email']}
            style={{ margin: 0 }}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email!'
              }
            ]}
          >
            <Input style={{ width: '200px' }} />
          </Form.Item>
        )
      }
    },
    {
      width: 100,
      title: <div className={styles.center}>Action</div>,
      render: (text: string, record: any, rowIndex: number) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PopupConfirmation record={record} />
        </div>
      )
    }
  ]

  const violationStandardColumnsWithStyles = violationStandardColumns.map(
    (column) => ({
      ...column,
      onCell: () => ({
        style: {
          verticalAlign: 'top' // Align cell content to the top
        }
      })
    })
  )

  const dismissColumns: ColumnType<any>[] = [
    {
      width: 300,
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      render: renderCell
    },
    {
      width: 300,
      title: 'Subsidiary',
      dataIndex: 'subsidiary_name',
      render: renderCell
    },
    {
      width: 170,
      title: 'Subsidiary Currency',
      dataIndex: 'subsidiary_currency',
      render: renderCell
    },
    {
      width: 170,
      title: 'Amount Variance',
      dataIndex: 'amount_variance',
      render: renderCell
    },
    {
      width: 170,
      title: 'Percentage Variance',
      dataIndex: 'percentage_variance',
      render: renderCell
    },
    {
      width: 100,
      title: <div className={styles.center}>Action</div>,
      render: (text: string, record: any, rowIndex: number) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PopupConfirmation record={record} />
        </div>
      )
    }
  ]

  const alertStandardColumns: ColumnType<any>[] = [
    {
      width: 300,
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      render: renderCell
    },
    {
      width: 300,
      title: 'Subsidiary',
      dataIndex: 'subsidiary_name',
      render: renderCell
    },
    {
      width: 170,
      title: 'Subsidiary Currency',
      dataIndex: 'subsidiary_currency',
      render: renderCell
    },
    {
      width: 170,
      title: 'Amount Variance',
      dataIndex: 'amount_variance',
      render: renderCell
    },
    {
      width: 170,
      title: 'Percentage Variance',
      dataIndex: 'percentage_variance',
      render: renderCell
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 230,
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item
            name={[record.key, 'email']}
            style={{ margin: 0 }}
            initialValue={text}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email!'
              },
              { required: true, message: 'Email is required' }
            ]}
          >
            <Input style={{ width: '200px' }} />
          </Form.Item>
        )
      }
    },
    {
      title: 'CC Email',
      width: 230,
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item
            name={[record.key, 'cc_email']}
            style={{ margin: 0 }}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email!'
              }
            ]}
          >
            <Input style={{ width: '200px' }} />
          </Form.Item>
        )
      }
    },
    {
      width: 100,
      title: <div className={styles.center}>Action</div>,
      render: (text: string, record: any, rowIndex: number) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PopupConfirmation record={record} />
        </div>
      )
    }
  ]

  const alertStandardColumnsWithStyles = alertStandardColumns.map((column) => ({
    ...column,
    onCell: () => ({
      style: {
        verticalAlign: 'top' // Align cell content to the top
      }
    })
  }))

  return {
    dismissPolicyExemptionColumns,
    violationStandardColumnsWithStyles,
    dismissColumns,
    alertStandardColumnsWithStyles
  }
}
