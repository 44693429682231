import { create } from 'zustand'

interface StoreProps {
  openModal: boolean
  record: string
  record_message: string
  page: number
  per_page: number
  setOpenModal: (openModal: boolean) => void
  setRecord: (record: string) => void
  setRecordMessage: (record_message: string) => void
  setPage: (page: number) => void
  setPerPage: (per_page: number) => void
}

const useLogRecordModalStore = create<StoreProps>((set) => ({
  openModal: false,
  record: '',
  record_message: '',
  page: 1,
  per_page: 100,
  setOpenModal: (openModal) => set({ openModal }),
  setRecord: (record) => set({ record }),
  setRecordMessage: (record_message) => set({ record_message }),
  setPage: (page) => set({ page }),
  setPerPage: (per_page) => set({ per_page })
}))

export default useLogRecordModalStore
