import { FC } from 'react'
import { Pagination } from 'antd'
import QjePagerProps from '../../types/QjePagerProps'

const QjePager: FC<QjePagerProps> = ({
  current,
  defaultPageSize,
  disabled,
  pageSize,
  total,
  onChange,
  isPo = false
}: QjePagerProps): JSX.Element => {
  const renderPagerStats = (total: number, range: [number, number]) => {
    return `Showing ${range[0]}-${range[1]} of ${total}`
  }

  return (
    <Pagination
      data-testid='qje-pager'
      data-cy='qje-pager'
      current={current}
      showSizeChanger={true}
      defaultPageSize={defaultPageSize}
      disabled={disabled}
      pageSize={pageSize}
      pageSizeOptions={[100, 250, 500, 1000]}
      showTotal={renderPagerStats}
      total={isPo ? total ?? 0 : (total ?? 0) / 2}
      onChange={onChange}
    />
  )
}

export default QjePager
