import { FC, MouseEventHandler, SetStateAction } from 'react'
import { Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

interface ConfirmCreateQjeModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  onOk: MouseEventHandler<HTMLElement>
}

const ConfirmCreateQjeModal: FC<ConfirmCreateQjeModalProps> = ({
  visible,
  setModalVisible,
  onOk
}: ConfirmCreateQjeModalProps): JSX.Element => {
  return (
    <Modal
      data-testid='confirm-create-qje-modal'
      data-cy='confirm-create-qje-modal'
      open={visible}
      onOk={onOk}
      title={
        <h2>
          <InfoCircleOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} style={{ color: '#1890ff' }} /> Are you sure?
        </h2>
      }
      onCancel={() => setModalVisible(false)}
      closable={false}
      centered
    >
      You want to create these QJEs, you can't undo it once its created.
    </Modal>
  )
}

ConfirmCreateQjeModal.defaultProps = {
  visible: false
}

export default ConfirmCreateQjeModal
