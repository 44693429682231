import moment from 'moment'
import { SetStateAction } from 'react'
import { qjeFetch } from '../../services/QjeManagerApi'

export const mergeSimilarRows = (rowsData: any) => {
  return rowsData
  /* 
  //The values for data.key and data.rowSpan is already provided by the backed
  const pageSize = 10
  const uniqueUserIdsPerPage = new Set()
  let pageNo = 0
  
  const mergedData = rowsData.map((rowData: any, index: number) => {
    const updatedRowsData = { ...rowData }
    if (index !== 0 && index % pageSize === 0) {
      uniqueUserIdsPerPage.clear()
      pageNo += 1
    }
    if (uniqueUserIdsPerPage.has(rowData.line_group)) {
      updatedRowsData.rowSpan = 0
    } else {
      const occurCount = rowsData
        .slice(pageNo * pageSize, (pageNo + 1) * pageSize)
        .filter((data: any) => data.line_group === rowData.line_group).length
      updatedRowsData.rowSpan = Math.min(pageSize, occurCount)
      uniqueUserIdsPerPage.add(rowData.line_group)
    }
    return updatedRowsData
  })
  console.log(rowsData, mergedData)
  return mergedData
  */
}

const getTxnLineId = (txn_number: string) => {
  const toArray = txn_number?.split('-')
  const indexOfTxnLineId = 3
  return toArray && toArray[indexOfTxnLineId]
}

const isValidUrlWithoutHttps = (url: string) => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}$', // domain name
    'i' // case-insensitive
  )
  return regex.test(url)
}

const isValidURL = (text: string) => {
  try {
    new URL(text)
    return true
  } catch {
    return false
  }
}

// this will be replaced by settingsToGridColumns(), which uses qje settings from the BE
export const qje_column = [
  {
    id: 8,
    label: 'Subsidiary',
    api_name: 'g_subsidiary'
    // is_sortable: true
  },
  {
    id: 5,
    label: 'Gl',
    api_name: 'g_glaccount'
    // sorter: (a: any, b: any) => {
    //   if (a.g_debit !== null || b.g_debit !== null)
    //     a.g_glaccount.localeCompare(b.g_glaccount)
    // }
  },
  {
    id: 3,
    label: 'Currency',
    api_name: 'g_currency'
    // is_sortable: true
  },
  {
    id: 4,
    label: 'G Name',
    api_name: 'g_name'
  },
  {
    id: 6,
    label: 'Debit',
    api_name: 'g_debit',
    render: (input: any, row: any, index: number) => {
      if (row['g_debit'] === null) {
        row['g_debit'] = row['g_credit']
      }
      //to show debit values only
      if (index % 2 === 0) {
        return input
      }
    },
    is_sortable: true
  },
  {
    id: 7,
    label: 'Credit',
    api_name: 'g_credit'
  },
  {
    id: 8,
    label: 'Transaction Number',
    api_name: 'transaction_number',
    render: (value: string, row: any) => (
      <a
        href={`/transactions/${getTxnLineId(row.g_transaction_id)}/lines`}
        target='_blank'
        rel='noreferrer'
        style={{ color: '#725bb4' }}
      >
        {value}
      </a>
    )
  },
  {
    id: 9,
    label: 'Threshold Support Document',
    api_name: 'g_threshold_support_document',
    render: (value: string) => (
      <a
        href={value}
        target='_blank'
        rel='noreferrer'
        style={{ color: '#725bb4' }}
      >
        {value}
      </a>
    )
  }
]

export const settingsToGridColumns = (
  settings: any,
  isTrdFlagEnabled?: boolean
) => {
  return Object.keys(settings).map((key: any, i: number) => {
    let column: any = {
      id: i,
      label: (
        <div
          data-testid={`qje-supergrid-header-${key}`}
          data-cy={`qje-supergrid-header-${key}`}
        >
          {settings[key].label}
        </div>
      ),
      api_name: key,
      hidden: settings[key].hidden,
      data_type: settings[key].data_type
    }

    // if (['g_subsidiary', 'g_currency', 'g_debit'].includes(key)) {
    //   column.isSortable = true
    // }

    if (key === 'g_debit') {
      column.render = (input: any, row: any, index: number) => {
        if (row['g_debit'] === null) {
          row['g_debit'] = row['g_credit']
        }
        //to show debit values only
        if (index % 2 === 0) {
          return input
        }
      }
    }

    if (key === 'transaction_number') {
      column.render = (value: string, row: any) => (
        <a
          href={`/${
            isTrdFlagEnabled ? 'transactions-rd' : 'transactions'
          }/${getTxnLineId(row.g_transaction_id)}/lines`}
          target='_blank'
          rel='noreferrer'
          style={{ color: '#725bb4' }}
        >
          {value}
        </a>
      )
    }

    if (key === 'g_threshold_support_document') {
      column.render = (value: string) => (
        <div>
          {value && (isValidUrlWithoutHttps(value) || isValidURL(value)) ? (
            <a
              href={
                value.startsWith('http') || value.startsWith('https')
                  ? value
                  : 'http://' + value
              }
              target='_blank'
              rel='noreferrer'
              style={{ color: '#725bb4' }}
            >
              {value}
            </a>
          ) : (
            value
          )}
        </div>
      )
    }

    return column
  })
}

export const reloadJEs = async (
  setter: SetStateAction<any>,
  setLoading: SetStateAction<any>,
  parameters: any
) => {
  const payload = {
    filter: parameters.filter,
    filter_fields: [],
    page: parameters.page,
    period: parameters.period,
    previewMode: parameters.previewMode,
    subtype: parameters.subtype,
    type: parameters.type
  }
  setLoading(true)
  try {
    const { data } = await qjeFetch(payload)
    setter(data)
    setLoading(false)
  } catch (error) {
    setLoading(false)
    console.log('Error: ', error)
  }
}

export const fetchJEs = async (parameters: any) => {
  const payload = {
    filter: parameters.filter,
    filter_fields: [],
    page: parameters.page,
    period: parameters.period,
    previewMode: parameters.previewMode,
    subtype: parameters.subtype,
    type: parameters.type,
    recordsPerPage: parameters.recordsPerPage,
    sortField: parameters.sortField ?? 'created_at',
    sortDir: parameters.sortDir ?? 'asc'
  }
  return await qjeFetch(payload)
}

export const getDefaultPeriod = (): { period: string } | undefined => {
  if (moment().tz('America/Los_Angeles').date() <= 15) {
    return {
      period: moment()
        .tz('America/Los_Angeles')
        .subtract(1, 'month')
        .format('YYYY-MM-01')
    }
  } else if (moment().tz('America/Los_Angeles').date() > 15) {
    return {
      period: moment().tz('America/Los_Angeles').format('YYYY-MM-01')
    }
  }
}

export interface SortInfo {
  field: string
  order: 'ascend' | 'descend'
}

export const maxRecordsPerPage = Math.pow(2, 31) - 1

export const getFilterMenu = ({
  filtersObject,
  useHasCreateQjeFilterPermission
}: Record<string | number, any>) => {
  return [
    ...Object.keys(filtersObject)
      .filter(
        (key: any) =>
          !['new', 'all', 'unposted', 'posted', 'rejected'].includes(key)
      )
      .map((key: any) => {
        return { key: key, label: filtersObject[key] }
      })
      .sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
    {
      type: 'divider'
    },
    {
      key: 'all',
      label: 'All JEs'
    },
    {
      key: 'unposted',
      label: 'Unposted JEs'
    },
    {
      key: 'posted',
      label: 'Posted JEs'
    },
    {
      key: 'rejected',
      label: 'Rejected JEs'
    },
    {
      type: 'divider'
    },
    {
      key: 'new',
      label: 'Create New Filter',
      // disabled: !RP.USER_HAS_CREATE_QJE_FILTER_PERMISSION
      disabled: !useHasCreateQjeFilterPermission
    }
  ]
}
