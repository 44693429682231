import ColumnFields from '../types/ColumnFields'

const removePrefix = (str: string): string => {
  if (str.startsWith('g_')) {
    return str.substring(2)
  }
  return str
}

const modifyString = (str: string) => {
  // Remove "g_" prefix
  let modifiedStr = str.startsWith('g_') ? str.substring(2) : str

  // Replace underscores with spaces
  modifiedStr = modifiedStr.replace(/_/g, ' ')

  return modifiedStr
}

const getFieldsLabel = (
  apiNames: string[],
  columnFields: ColumnFields[] | null
) => {
  return apiNames?.map((apiName: string) => {
    const columnField = columnFields?.find(
      (item) => item.api_name === removePrefix(apiName)
    )
    return columnField ? columnField.label : modifyString(apiName) //if api_name is not found in the column settings
  })
}

export { getFieldsLabel }
