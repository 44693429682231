const ActionPermissions = {
  EXPORT_REVIEW_CENTER: 1,
  FILTERING_REVIEW_CENTER: 2,
  QUICK_ACTIONS_REVIEW_CENTER: 3,
  CONFIG_REVIEW_CENTER: 4,
  REVIEW_CENTER_DATA_REVIEW_CENTER: 5,
  REVIEW_CENTER_HISTORICAL_DATA: 6,
  REVIEW_CENTER_CALCULATED_ACCRUAL_DATA: 7,
  REVIEW_CENTER_IMPORTED_ACCRUAL_DATA: 8,
  EXPORT_TASK_MANAGER: 9,
  FILTERING_TASK_MANAGER: 10,
  COPY_TASK_MANAGER: 11,
  DELETE_TASK_MANAGER: 12,
  HISTORY_TASK_MANAGER: 13,
  VOID_HEADER_TRANSACTIONS: 14,
  STOP_WORKSTEP: 15,
  EDIT_AND_COMPLETE_TRANSACTIONS: 16,
  COMMENTS_TRANSACTIONS: 17,
  HISTORY_TRANSACTIONS: 18,
  ATTACHMENTS_TRANSACTIONS: 19,
  NUDGE_ALL_TRANSACTIONS: 20,
  OVERRIDE_TRANSACTIONS: 21,
  NUDGE_SELECTED_TRANSACTIONS: 22,
  ADD_MORE_TRANSACTIONS: 23,
  ADD_LINE_TRANSACTIONS: 24,
  // CREATE_QJE_TRANSACTIONS: 25, this permission is obsolete. Use CREATE_QJE (id 58) instead
  EXPORT_TRANSACTIONS: 26,
  EXPORT_LINES_TRANSACTIONS: 27,
  FILTERING_TRANSACTIONS: 28,
  VOID_LINE_TRANSACTIONS: 29,
  COPY_LINE_TRANSACTIONS: 30,
  DELETE_COPIED_LINE_TRANSACTIONS: 31,
  PASSBACK_TRANSACTIONS: 32,
  INLINE_EDITING_TRANSACTIONS: 33,
  LINE_HISTORY_TRANSACTIONS: 34,
  DELETE_LINE_TRANSACTIONS: 35,
  QUICK_SEARCH_TRANSACTIONS: 36,
  UPLOAD_SOURCE_DATA_TRANSACTIONS: 37,
  UPLOAD_IMPORTED_ACCRUALS_TRANSACTIONS: 38,
  VIEW_TRANSACTION_SETTINGS: 39,
  LINE_LOOKUP_TRANSACTIONS: 40,
  VIEW_TRANSACTION: 41,
  VIEW_TRANSACTION_LINES: 42,
  VIEW_TRANSACTIONS: 43,
  ADD_COMMENT: 44,
  VIEW_COMMENTS: 45,
  VIEW_COMMENT: 46,
  DELETE_COMMENT: 47,
  UPDATE_COMMENT: 48,
  LIST_SOURCE_COA: 49,
  ADD_ATTACHMENT: 50,
  VIEW_ATTACHMENTS: 51,
  VIEW_ATTACHMENT: 52,
  DELETE_ATTACHMENT: 53,
  UPDATE_ATTACHMENT: 54,
  DOWNLOAD_ATTACHMENT: 55,
  CREATE_TRANSACTIONS: 56,
  VIEW_TRANSACTION_LINE: 57,
  CREATE_QJE: 58,
  POST_QJE: 59,
  REJECT_QJE: 60,
  FILTERING_QJE: 61,
  CLOSE_PO_QJES: 62,
  EXPORT_QJES: 63,
  USER_MANAGEMENT_ADMIN: 64,
  DASHBOARD_ADMIN: 65,
  APPS_ADMIN: 66,
  SUBSCRIPTIONS_ADMIN: 67,
  REPORTS_ADMIN: 68,
  INTEGRATIONS_ADMIN: 69,
  EMAILS_ADMIN: 70,
  TASK_SCHEDULER_ADMIN: 71,
  REVIEW_CENTER_GET_ALL_FILTERS: 94,
  REVIEW_CENTER_GET_FILTER: 95,
  REVIEW_CENTER_SAVE_FILTER: 96,
  REVIEW_CENTER_UPDATE_FILTER: 97,
  REVIEW_CENTER_DELETE_FILTER: 98,
  LIST_SOURCE_DATA: 99,
  TRANSACTION_GLOBAL_SETTINGS: 100,
  NUDGE_LIST_TRANSACTIONS: 101,
  EMAIL_TRACKER_TRANSACTIONS: 102,
  DOWNLOAD_SOURCE_DATA_TRANSACTIONS: 103,
  QJE_VIEW_TRANSACTIONS: 104,
  LOOKUP_FXRATE_AMOUNT: 105,
  LOOKUP_TRANSACTION_CURRENCY: 106,
  LIST_FILTERS: 107,
  SHOW_FILTER: 108,
  ADD_FILTER: 109,
  UPDATE_FILTER: 110,
  DELETE_FILTER: 111,
  CREATE_QJE_FILTER: 112,
  EDIT_QJE_FILTER: 113,
  DELETE_QJE_FILTER: 114,
  VIEW_QJE_FILTERS: 115,
  GET_QJE_FILTER: 116,
  VENDOR_TASKS: 117,
  LINE_CALCULATIONS: 118,
  VIEW_QJE_LOG: 119,
  CREATE_QJE_LOG: 120,
  VIEW_QJE_SETTING: 121,
  QJE_REVIEW_JE: 122,
  QJE_REVIEW_PO: 123,
  QJE_REJECT_JE: 124,
  GET_QJE_FILTER_FIELDS: 128,
  REVIEW_CENTER_SETTINGS: 129,
  QJE_OUTBOUND: 200,
  QJE_COMPANY_SETTINGS: 201,
  QJE_REVIEWED_FLAG: 202,
  USER_DEACTIVATE: 300,
  USER_RESET_PASSWORD: 301,
  SELF_SERVICE_USER_MANAGEMENT: 302,
  LOGIN_AUDIT_TRAIL: 303,
  VENDOR_TASKS_CREATE_FROM_PO: 400,
  VENDOR_TASKS_CREATE_FROM_VENDOR: 401,
  VENDOR_TASKS_COPY_TASK: 402,
  VENDOR_TASKS_SETTINGS: 403,
  VENDOR_TASKS_MASS_UPDATE: 404,
  VENDOR_TASKS_DETAILS_MASS_UPDATE: 405,
  VENDOR_TASK_DETAILS_INDEX: 406,
  VENDOR_TASK_DETAILS_CREATE: 407,
  VENDOR_TASK_DETAILS_STORE: 408,
  VENDOR_TASK_DETAILS_SHOW: 409,
  VENDOR_TASK_DETAILS_EDIT: 410,
  VENDOR_TASK_DETAILS_UPDATE: 411,
  VENDOR_TASK_DETAILS_DESTROY: 412,
  VENDOR_TASKS_INDEX: 413,
  VENDOR_TASKS_CREATE: 414,
  VENDOR_TASKS_STORE: 415,
  VENDOR_TASKS_SHOW: 416,
  VENDOR_TASKS_EDIT: 417,
  VENDOR_TASKS_UPDATE: 418,
  VENDOR_TASKS_DESTROY: 419,
  SOURCE_CHART_OF_ACCOUNTS_INDEX: 420,
  SOURCE_CHART_OF_ACCOUNTS_CREATE: 421,
  SOURCE_CHART_OF_ACCOUNTS_STORE: 422,
  SOURCE_CHART_OF_ACCOUNTS_SHOW: 423,
  SOURCE_CHART_OF_ACCOUNTS_EDIT: 424,
  SOURCE_CHART_OF_ACCOUNTS_UPDATE: 425,
  SOURCE_CHART_OF_ACCOUNTS_DESTROY: 426,
  SOURCE_VENDORS_INDEX: 427,
  SOURCE_VENDORS_CREATE: 428,
  SOURCE_VENDORS_STORE: 429,
  SOURCE_VENDORS_SHOW: 430,
  SOURCE_VENDORS_EDIT: 431,
  SOURCE_VENDORS_UPDATE: 432,
  SOURCE_VENDORS_DESTROY: 433,
  SOURCE_PURCHASE_ORDERS_INDEX: 434,
  SOURCE_PURCHASE_ORDERS_CREATE: 435,
  SOURCE_PURCHASE_ORDERS_STORE: 436,
  SOURCE_PURCHASE_ORDERS_SHOW: 437,
  SOURCE_PURCHASE_ORDERS_EDIT: 438,
  SOURCE_PURCHASE_ORDERS_UPDATE: 439,
  SOURCE_PURCHASE_ORDERS_DESTROY: 440,
  FORMS_INDEX: 441,
  FORMS_STORE: 442,
  FORMS_CREATE: 443,
  FORMS_DESTROY: 444,
  FORMS_UPDATE: 445,
  FORMS_EDIT: 446,
  CREATE_TM_FILTER: 479,
  EDIT_TM_FILTER: 480,
  DELETE_TM_FILTER: 481,
  VIEW_TM_FILTERS: 482,
  GET_TM_FILTER: 483,
  GET_TM_FILTER_FIELDS: 484,
  DOWNLOAD_TASKS: 485,
  ACCRUAL_RULES_MANAGER_SHOW: 486,
  ACCRUAL_RULES_MANAGER_EDIT: 487,
  ACCRUAL_RULES_MANAGER_DELETE: 488,
  ACCRUAL_RULES_MANAGER_UPDATE: 489,
  ACCRUAL_RULES_MANAGER_CREATE: 490,
  IMPORT_ACCRUAL_MANAGER: 491,
  VIEW_TRANSACTION_LINES_CONSOLIDATED: 500,
  VIEW_TRANSACTION_COLUMN_MANAGER: 501,
  EDIT_TRANSACTION_COLUMN_MANAGER: 502,
  DELETE_TRANSACTION_COLUMN_MANAGER: 503,
  DELETE_TRANSACTION: 504,
  RESTORE_TRANSACTION: 505,
  NO_QJE_TRANSACTION_LINES: 506,
  PRIOR_PERIOD_LIST: 507,
  PRIOR_PERIOD_UPDATE: 508,
  GET_PRIOR_TRANSACTION_LINES: 509,
  TRANSACTION_COLUMN_MANAGER_CONSOLIDATED: 511,
  HISTORY_SHOW: 600,
  HISTORY_SAVE: 601,
  HISTORY_FIELDS_SHOW: 602,
  HISTORY_FIELDS_ARRAY_SHOW: 603,
  USER_MANAGEMENT_HISTORY_SHOW: 604,
  GLOBAL_AA: 700,
  VENDOR_PERM_NOTES_AA: 701,
  UPDATE_ACCRUAL_MANAGER_AA: 702,
  USER_MANAGEMENT_SETTINGS: 703,
  SCHEDULE_MANAGER_CREATE: 800,
  SCHEDULE_MANAGER_GET: 801,
  SCHEDULE_MANAGER_UPDATE: 802,
  SCHEDULE_MANAGER_DELETE: 803,
  API_MANAGER_CREATE: 804,
  API_MANAGER_GET: 805,
  API_MANAGER_UPDATE: 806,
  API_MANAGER_DELETE: 807,
  SOURCE_VENDOR_GET: 900,
  SOURCE_COA_GET: 901,
  SOURCE_BUDGET_GET: 902,
  SOURCE_FX_GET: 903,
  SOURCE_INVOICE_GET: 904,
  SOURCE_TRANSACTION_GET: 905,
  SOURCE_PURCHASE_ORDER_GET: 906,
  SOURCE_VENDOR_POST: 907,
  SOURCE_COA_POST: 908,
  SOURCE_BUDGET_POST: 909,
  SOURCE_FX_POST: 910,
  SOURCE_INVOICE_POST: 911,
  SOURCE_TRANSACTION_POST: 912,
  SOURCE_PURCHASE_ORDER_POST: 913,
  PERIOD_MANAGER_SETTINGS: 250,
  INTEGRATION_LOGS_GET: 916,
  INTEGRATION_LOGS_EXPORT: 917,
  COPY_ACCRUAL_FORM_LINK: 513
}

export default ActionPermissions
