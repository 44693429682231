// ** React Imports
import { useState } from 'react'

// ** AntD Imports
import { Input } from 'antd'

// ** API Services Imports
import useAnalytics from '../../../../hooks/useAnalytics'

// ** Custom Component Imports
import GButton from '../../../../../../components/gappify/GButton'

// ** Zustand Imports
import useAnalyticsStore from '../../../../manager/analytics-store'

// =================================================================
const AddNotesFooter = () => {
  // ** AntD **
  const { TextArea } = Input

  // ** Store **
  const { permNoteMessage, setPermNoteMessage, responseType } =
    useAnalyticsStore()

  // ** API Services **
  const { handleSubmit } = useAnalytics({ responseType: responseType })

  // ** State **
  const [isMaxedOut, setIsMaxedOut] = useState(false)

  // ** Function **
  const handleTextChange = (e: any) => {
    const value = e.target.value
    setPermNoteMessage(value)
    setIsMaxedOut(value.length >= 2500)
  }

  return (
    <div>
      <TextArea
        placeholder='Add a note'
        rows={4}
        value={permNoteMessage}
        onChange={handleTextChange}
        maxLength={2500}
        showCount
      />

      <div
        style={{
          color: 'red',
          textAlign: 'left',
          minHeight: '30px',
          visibility: isMaxedOut ? 'visible' : 'hidden'
        }}
      >
        {isMaxedOut ? 'Maximum character count reached.' : ''}
      </div>

      <GButton
        btnText='Add'
        shape='default'
        onClick={() => {
          handleSubmit({ notes: permNoteMessage })
        }}
        style={{ width: 100 }}
        disabled={permNoteMessage.length === 0}
      />
    </div>
  )
}

export default AddNotesFooter
