/** react imports */
import { useEffect } from 'react'

/** react router imports */
import { useNavigate } from 'react-router-dom'

/** hooks imports */
import useCompanySettings from '../../../../../hooks/useCompanySettings'
import useFeatureFlags from '../../../../../hooks/useFeatureFlags'
import useRolesAndPermissions from '../../../../../hooks/useRolesAndPermissions'

// =================================================================
export default function useApiKeysFlag() {
  /** react router */
  const navigate = useNavigate()

  /** hooks */
  const RP = useRolesAndPermissions()
  const { isLoading: isFeatureFlagsLoading, data: featureFlags } =
    useFeatureFlags()
  const { isLoading: isCompanySettingsLoading, data: companySettings } =
    useCompanySettings()

  useEffect(() => {
    if (isFeatureFlagsLoading) return
    if (!featureFlags.vgateway || !RP.USER_HAS_API_MANAGER_GET_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureFlagsLoading])

  useEffect(() => {
    if (isCompanySettingsLoading) return
    if (!companySettings.api_enabled) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanySettingsLoading])
}
