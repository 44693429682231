import React from 'react'
import SubTotal from './SubTotal'
import Columns from './Columns'
import ReviewCenterHelper from '../../helpers/review-center-helper'

const SubsidiaryDepartmentGlVendor: React.FC<any> = ({
  subsidiaries,
  fields,
  view,
  clickHandler,
  config
}) => {
  return (
    <React.Fragment
      data-cy='rc-subsidiary-department-gl-vendor'
      data-testid='rc-subsidiary-department-gl-vendor'
    >
      {subsidiaries &&
        Object.entries(subsidiaries).map(
          ([subsidiary_id, subsidiary]: any, subsidiary_index: any) => (
            <React.Fragment
              key={subsidiary_index}
              data-cy={`rc-subsidiary-row${subsidiary_index}`}
              data-testid={`rc-subsidiary-row${subsidiary_index}`}
            >
              {subsidiary &&
                subsidiary.department &&
                Object.entries(subsidiary.department).map(
                  (
                    [department_id, department]: any,
                    department_index: any,
                    department_total: any
                  ) => (
                    <React.Fragment
                      key={department_index}
                      data-cy={`rc-department-row${department_index}`}
                      data-testid={`rc-department-row${department_index}`}
                    >
                      {department &&
                        department.gl &&
                        Object.entries(department.gl).map(
                          ([gl_id, gl]: any, gl_index: any, gl_total: any) => (
                            <React.Fragment
                              key={gl_index}
                              data-cy={`rc-gl-row${gl_index}`}
                              data-testid={`rc-gl-row${gl_index}`}
                            >
                              {gl &&
                                gl.vendor &&
                                Object.entries(gl.vendor).map(
                                  (
                                    [vendor_id, vendor]: any,
                                    vendor_index: any,
                                    all_vendors
                                  ) => (
                                    <tr
                                      key={vendor_index}
                                      data-cy={`rc-vendor-row${vendor_index}`}
                                      data-testid={`rc-vendor-row${vendor_index}`}
                                    >
                                      {department_index === 0 &&
                                        gl_index === 0 &&
                                        vendor_index === 0 && (
                                          <td
                                            className='border-l header-column sticky-col sticky-col-1'
                                            rowSpan={ReviewCenterHelper.subsidiaryDepartmentRowSpan(
                                              subsidiary.department
                                            )}
                                            data-cy={`rc-subsidiary-name-row${subsidiary_index}`}
                                            data-testid={`rc-subsidiary-name-row${subsidiary_index}`}
                                            title={subsidiary.name}
                                          >
                                            {subsidiary.name || 'Blank'}
                                            <br />
                                            <span
                                              className='text-color-purple'
                                              data-cy={`rc-subsidiary-currency-row${subsidiary_index}`}
                                              data-testid={`rc-subsidiary-currency-row${subsidiary_index}`}
                                            >
                                              {subsidiary.currency}
                                            </span>
                                          </td>
                                        )}

                                      {gl_index === 0 && vendor_index === 0 && (
                                        <td
                                          className='header-column sticky-col sticky-col-2'
                                          rowSpan={ReviewCenterHelper.departmentGLRowSpan(
                                            department.gl
                                          )}
                                          data-cy={`rc-department-name-row${department_index}`}
                                          data-testid={`rc-department-name-row${department_index}`}
                                        >
                                          {department.name || 'Blank'}
                                        </td>
                                      )}

                                      {vendor_index === 0 && (
                                        <td
                                          className='header-column sticky-col sticky-col-3'
                                          rowSpan={all_vendors.length}
                                          data-cy={`rc-gl-name-row${gl_index}`}
                                          data-testid={`rc-gl-name-row${gl_index}`}
                                        >
                                          {gl.name || 'Blank'}
                                        </td>
                                      )}
                                      <td
                                        className='vendor-column sticky-col sticky-col-4'
                                        data-cy={`rc-vendor-name-row${vendor_index}`}
                                        data-testid={`rc-vendor-name-row${vendor_index}`}
                                      >
                                        {vendor.name || 'Blank'}
                                      </td>

                                      <Columns
                                        values={vendor}
                                        fields={fields}
                                        subsidiary_id={subsidiary_id}
                                        department_id={department_id}
                                        glaccount_id={gl_id}
                                        vendor_id={vendor_id}
                                        clickHandler={clickHandler}
                                      ></Columns>
                                    </tr>
                                  )
                                )}
                              <SubTotal
                                data-cy={`rc-gl-subtotal${gl_index}`}
                                data-testid={`rc-gl-subtotal${gl_index}`}
                                subtotalLabel={
                                  config.show_coa_names_on_subtotals && gl.name
                                }
                                values={gl.vendor}
                                fields={fields}
                                tier={1}
                                stickycol={3}
                                colspan={2}
                                border={`border-r border-t ${
                                  `${gl_index + 1}` === `${gl_total.length}`
                                    ? ' '
                                    : ' border-b '
                                }`}
                                methodName='vendorSubTotal'
                              ></SubTotal>
                            </React.Fragment>
                          )
                        )}
                      <SubTotal
                        data-cy={`rc-department-subtotal${department_index}`}
                        data-testid={`rc-department-subtotal${department_index}`}
                        subtotalLabel={
                          config.show_coa_names_on_subtotals && department.name
                        }
                        values={department.gl}
                        fields={fields}
                        tier={2}
                        stickycol={2}
                        colspan={3}
                        border={`border-r border-t ${
                          `${department_index + 1}` ===
                          `${department_total.length}`
                            ? ' '
                            : ' border-b '
                        }`}
                        methodName='departmentGlSubTotal'
                      ></SubTotal>
                    </React.Fragment>
                  )
                )}
              <SubTotal
                data-cy={`rc-subsidiary-subtotal${subsidiary_index}`}
                data-testid={`rc-subsidiary-subtotal${subsidiary_index}`}
                subtotalLabel={
                  config.show_coa_names_on_subtotals && subsidiary.name
                }
                values={subsidiary.department}
                fields={fields}
                tier={3}
                stickycol={1}
                colspan={4}
                border='border-lr border-t border-b'
                methodName='subsidiaryDepartmentSubTotal'
              ></SubTotal>
            </React.Fragment>
          )
        )}
    </React.Fragment>
  )
}

export default SubsidiaryDepartmentGlVendor
