// ** View Imports
import Monthly from '../views/Monthly'
// import Notifications from '../views/Notifications'
import Standard from '../views/Standard'

// =================================================================
const TabItems = [
  {
    label: `Monthly Schedule`,
    key: '1',
    children: <Monthly />
  },
  {
    label: `Standard Schedule`,
    key: '2',
    children: <Standard />
  }
  // {
  //   label: `Notifications`,
  //   key: '3',
  //   children: <Notifications />
  // }
]

export default TabItems
