import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'

export default async function activateUser(userId: number) {
  try {
    const response = await axiosIdentityManagerInstance.patch(
      `/api/user/${userId}/activate`
    )
    return response.data
  } catch (error) {
    throw error
  }
}
