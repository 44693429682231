// ** React Imports
import { useEffect, useState } from 'react'

// ** AntD Imports
import { Table, message } from 'antd'

// ** Data Imports
import TableData from '../data/TableData'

// ** Third Party Imports
import ReactDragListView from 'react-drag-listview'

// ** Hooks Imports
import useAnalyticsSettings from '../hooks/useAnalyticsSettings'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../manager/analytics-settings-store'

// ** Types Imports
import { IRules, ISingleReorderRule } from '../types/table-record'

// ** Custom Component Imports
import AnalyticsSettingsHeader from './Header'

// =================================================================
const success = () => {
  message.success('Rule reordered successfully!')
}

const error = (errorMsg: string) => {
  message.error(errorMsg)
}

const AnalyticsSettingsTable = () => {
  const [dataSource, setDataSource] = useState<IRules[] | []>([])

  // ** Data **
  const { columns } = TableData()

  // ** Store **
  const { setShowHeader, errorMsg } = useAnalyticsSettingsStore()

  // ** Hooks **
  const {
    getAnalyticsRules,
    deleteRuleLoading,
    patchRuleLoading,
    handleReorderRule,
    patchReorderRuleLoading,
    patchReorderRuleSuccess,
    patchReorderRuleError
  } = useAnalyticsSettings()
  const { isLoading, isRefetching, data } = getAnalyticsRules

  /**
   * The function `onDragEnd` reorders an array of items by moving an item from one index to another.
   * @param {number} fromIndex - The `fromIndex` parameter represents the index of the item being
   * dragged. It indicates the current position of the item in the array.
   * @param {number} toIndex - The `toIndex` parameter represents the index where the dragged item
   * should be moved to.
   */
  const onDragEnd = (fromIndex: number, toIndex: number) => {
    /**
     * ! BUG: There's a ghost/hidden row in the current table UI.
     * To work around this, decrease @param fromIndex & @param toIndex by 1
     */
    fromIndex--
    toIndex--

    const items = [...dataSource]
    const item = items.splice(fromIndex, 1)[0]
    items.splice(toIndex, 0, item)
    setDataSource(items)
  }

  const handleReorderSave = () => {
    const originalData = data?.data
    const newData = dataSource

    // Prevents calling Reorder API if no changes found
    if (newData === originalData) {
      setShowHeader(true)
      return
    }

    let reorderData: ISingleReorderRule[] = []

    newData.forEach((value, index) => {
      const currentOriginalData = originalData[index]
      /**
       * This prevents updating of all rows and only update those that are re-ordered.
       * First checks if current row is different from original row arrangement.
       * Then sets the weight value equal to the original data.
       */
      if (value !== currentOriginalData) {
        const ruleWeightData = {
          rule_id: value.id,
          weight: currentOriginalData.weight
        }
        reorderData.push(ruleWeightData)
      }
    })

    handleReorderRule({ data: reorderData })
  }

  const handleReorderCancel = () => setDataSource(data?.data)

  const getTableLoading = () => {
    return (
      isLoading ||
      isRefetching ||
      deleteRuleLoading ||
      patchRuleLoading ||
      patchReorderRuleLoading
    )
  }

  // ** Component updates after table data updates
  useEffect(() => {
    setDataSource(data?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // ** Component updates after calling Reorder Rule API
  useEffect(() => {
    if (patchReorderRuleSuccess) {
      success()
      setShowHeader(true)
    }

    if (patchReorderRuleError) error(errorMsg)
    // eslint-disable-next-line
  }, [patchReorderRuleSuccess, patchReorderRuleError])

  return (
    <>
      <AnalyticsSettingsHeader
        handleReorderSave={handleReorderSave}
        handleReorderCancel={handleReorderCancel}
        patchReorderRuleLoading={patchReorderRuleLoading}
      />
      <ReactDragListView handleSelector='.drag-handle' onDragEnd={onDragEnd}>
        <Table
          dataSource={dataSource}
          rowKey='id'
          columns={columns as []}
          loading={getTableLoading()}
          pagination={false}
          scroll={{ x: 'max-content', y: 680 }}
        />
      </ReactDragListView>
    </>
  )
}

export default AnalyticsSettingsTable
