/** Hooks imports */
import { ResponseType } from 'axios'
import useApiService from '../hooks/useApiService'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface TxnParams {}

interface TxnData {}

interface TxnOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
  responseType?: ResponseType
}

// =================================================================
export default function useTxnService() {
  const { apiRequest } = useApiService()

  const txnApi = (props: BaseProps<TxnParams, TxnData, TxnOptions>) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials,
      props.options.responseType
    )
  }

  return { txnApi }
}
