import { useEffect, useState } from 'react'

// ** AntD Imports
import { Tooltip, type MenuProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import GDropdownBtn from '../../../gappify/GDropdownBtn'

// ** Zustand Imports
import useFilterStore from '../../store/filterStore'

// ** Types/Constants Imports
import TProps, { TFilter } from '../../types/Filter'
import { DEFAULT_FILTER, DEFAULT_FILTER_IDS, MODAL_MODE } from '../../constants'
import getInstance from '../../constants/instance'

const FilterDropdown = ({
  microservice,
  activeFilterRef,
  loadDataCallback,
  getSavedFiltersUrl,
  getFieldsUrl,
  hasCustomColumns,
  updateColumnHeaders
}: TProps) => {
  const { instance } = getInstance(microservice)!

  const [defaultFilters, setDefaultFilters] = useState<TFilter[]>([])

  // ** Zustand Store **
  const selectedFilter = useFilterStore((state) => state.selectedFilter)
  const isReloadFilters = useFilterStore((state) => state.isReloadFilters)
  const allFilters = useFilterStore((state) => state.allFilters)
  const setSelectedFilter = useFilterStore((state) => state.setSelectedFilter)
  const setModalMode = useFilterStore((state) => state.setModalMode)
  const setIsModalOpen = useFilterStore((state) => state.setIsModalOpen)
  const setIsPreviewMode = useFilterStore((state) => state.setIsPreviewMode)
  const setIsReloadFilters = useFilterStore((state) => state.setIsReloadFilters)
  const setAllFields = useFilterStore((state) => state.setAllFields)
  const setAllFilters = useFilterStore((state) => state.setAllFilters)

  const reloadData = (filterId: number | string, thisPreviewMode?: boolean) => {
    activeFilterRef.current = {
      id: filterId
    }
    loadDataCallback(thisPreviewMode || false)
  }

  const getSavedFilters = async () => {
    await instance.get(getSavedFiltersUrl).then((res) => {
      const { data } = res

      const newAllFilters = data.filter(
        (item: TFilter) => !DEFAULT_FILTER_IDS.includes(item.id)
      )

      const newDefaultFilters = data.filter((item: TFilter) =>
        DEFAULT_FILTER_IDS.includes(item.id)
      )

      setAllFilters(newAllFilters)
      setDefaultFilters(newDefaultFilters)
    })
  }

  const getAllFields = async () => {
    await instance.get(getFieldsUrl).then((res) => {
      const { data } = res
      data.fields && setAllFields(data.fields)
    })
  }

  const generateDropDownLabel = (text: string) => {
    const textLength: number = text.length

    if (textLength > 9) {
      return (
        <Tooltip placement='topLeft' title={text}>
          <span>{text.replace(/^(.{9}).{2,}/, '$1…')}</span>
        </Tooltip>
      )
    }

    return text
  }

  const generateSavedFilterItems = () => {
    const savedFilters: MenuProps['items'] = allFilters.map((item: TFilter) => {
      return {
        key: item.id,
        label: item.name,
        onClick: () => {
          setSelectedFilter(item)
          setIsPreviewMode(false)
          reloadData(item.id)
          if (hasCustomColumns) {
            updateColumnHeaders(item.id)
          }
        }
      }
    })

    return savedFilters
  }

  const generateDefaultFilters = () => {
    const thisFilters: MenuProps['items'] = defaultFilters.map(
      (item: TFilter) => {
        return {
          key: item.id,
          label: item.name,
          onClick: () => {
            setSelectedFilter(item)
            setIsPreviewMode(false)
            reloadData(item.id)
          }
        }
      }
    )

    return thisFilters
  }

  const items: MenuProps['items'] = [
    ...generateSavedFilterItems(),
    {
      type: 'divider'
    },
    {
      key: DEFAULT_FILTER.id,
      label: DEFAULT_FILTER.name,
      onClick: () => {
        setSelectedFilter(DEFAULT_FILTER)
        setIsPreviewMode(false)
        reloadData(DEFAULT_FILTER.id)
        if (hasCustomColumns) updateColumnHeaders(DEFAULT_FILTER.id)
      }
    },
    ...generateDefaultFilters(),
    {
      type: 'divider'
    },
    {
      key: -1,
      label: 'Create new filter',
      onClick: () => {
        setIsModalOpen(true)
        setModalMode(MODAL_MODE.CREATE)
      }
    }
  ]

  useEffect(() => {
    getSavedFilters()
    getAllFields()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isReloadFilters) {
      getSavedFilters()
      setIsReloadFilters(false)
    }
    // eslint-disable-next-line
  }, [isReloadFilters])

  return (
    <GDropdownBtn
      className='g-btn-default'
      menuClassName='filter-dp'
      menuItems={items}
      btnText={generateDropDownLabel(selectedFilter.name)}
      children={
        <DownOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      }
    />
  )
}

export default FilterDropdown
