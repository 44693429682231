import { Modal, Form, Input, Select, message, Button, Popconfirm } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import GButton from '../gappify/GButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import GIcon from '../gappify/GIcon'
import {
  qjeFilterUpdate,
  qjeFilterDelete,
  qjeFilterDetails,
  getSuggestedValues
} from '../../services/QjeManagerApi'
import { setSelectedFilter as setGlobalSelectedFilter } from '../../context/actions/qjeAction'
import { useDispatch, useSelector } from 'react-redux'
import filterSort from '../../utils/filterSort'
import filterOption from '../../utils/filterOption'

interface UpdateFilterModalProps {
  showModal: boolean
  onCancel: any
  setIsShowModal: (value: SetStateAction<boolean>) => void
  setCustomFilters: (value: SetStateAction<any>) => any
  setIsLoading: (value: SetStateAction<any>) => any
  setIsPreviewMode: (value: SetStateAction<boolean>) => void
  data: any
  setPreviewTriggeredFrom: any
  settings: any
  loadingSettings: any
  setDataPreviewed: any
  reloadData: any
  getFilterLabel: any
  // refs
  selectedFilterRef: any
  selectedPeriodRef: any
  isPreviewMode?: boolean
}

const UpdateFilterModal: FC<UpdateFilterModalProps> = ({
  showModal,
  onCancel,
  setIsShowModal,
  setCustomFilters,
  setIsLoading,
  setIsPreviewMode,
  data,
  setPreviewTriggeredFrom,
  settings,
  loadingSettings,
  setDataPreviewed,
  reloadData,
  getFilterLabel,
  selectedFilterRef,
  isPreviewMode
}: UpdateFilterModalProps): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [filterName, setFilterName] = useState<any>('')
  const [newFilters, setNewFilters] = useState<any[]>([]) // this refers to the array of filter fields
  const [countNewFilters, setCountNewFilters] = useState(0) // this refers to the length of the `filter_fields`
  const [loadingFilterDetails, setLoadingFilterDetails] =
    useState<boolean>(true)
  const [suggestedValues, setSuggestedValues] = useState<
    { id: any; value: any }[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [focusedApiName, setFocusedApiName] = useState<string>('')

  const selectedGlobalFilter = useSelector(
    (state: any) => state.allQjes.selectedFilter
  )

  const renderAppropriateInputField = (type: string, filter: any) => {
    switch (type) {
      case 'short_text':
        return (
          <Input
            data-testid={`qje-filter-value-input-${filter.key}`}
            data-cy={`qje-filter-value-input-${filter.key}`}
            style={{ width: '90%' }}
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'picklist_multiple':
        return (
          <Select
            data-testid={`qje-filter-value-input-${filter.key}`}
            data-cy={`qje-filter-value-input-${filter.key}`}
            onClick={() => {
              getSuggestedValues(filter.field, '').then((res: any) =>
                setSuggestedValues(res.data)
              )
            }}
            mode='multiple'
            style={{ width: '90%' }}
            placeholder='Please select'
            defaultValue={[]}
            optionLabelProp='label'
            options={
              // for custom fields
              // filter.field === 'g_resubmitted'
              ['g_resubmitted', 'g_reviewed'].includes(filter.field)
                ? [
                    { key: 0, value: 1, label: 'Yes' },
                    { key: 1, value: 0, label: 'No' }
                  ]
                : suggestedValues.map((e: any) => {
                    return { ...e, key: e.value, label: e.value }
                  })
            }
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
            onKeyUp={(e: any) => {
              if (e.target.value.length >= 3) {
                setFocusedApiName(filter.field)
                setSearchTerm(e.target.value)
              }
            }}
            onInputKeyDown={async (e: any) => {
              if (
                [e.key, e.code].includes('Enter') &&
                !['', null, undefined].includes(e.target.value)
              ) {
                await form.setFieldValue(`${filter.key}-value`, [
                  ...form.getFieldValue(`${filter.key}-value`),
                  e.target.value
                ])
                await setNewFilters(
                  newFilters.map((f: any) => {
                    return filter.key === f.key
                      ? {
                          ...f,
                          value: form.getFieldValue(`${filter.key}-value`)
                        }
                      : f
                  })
                )
                e.target.value = ''
              }
            }}
          />
        )
    }
  }

  const renderFilterInputComponents = (filter: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {loadingSettings ? (
          <Form.Item
            data-testid={`qje-filter-field-form-item-${filter.key}`}
            data-cy={`qje-filter-field-form-item-${filter.key}`}
            initialValue={'Loading...'}
            name={'loading'}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Input style={{ width: '90%' }} disabled />
          </Form.Item>
        ) : (
          <Form.Item
            data-testid={`qje-filter-field-form-item-${filter.key}`}
            data-cy={`qje-filter-field-form-item-${filter.key}`}
            initialValue={filter.field}
            name={`${filter.key}-field`}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Select
              data-testid={`qje-filter-field-selector-${filter.key}`}
              data-cy={`qje-filter-field-selector-${filter.key}`}
              showSearch
              placeholder='Please select'
              onChange={(e) => handleChangeFilter(filter, e)}
              style={{ width: '90%' }}
              filterOption={filterOption}
              filterSort={filterSort}
            >
              {settings
                ? Object.keys(settings).map((e: string, i: number) => {
                    return (
                      <Select.Option
                        data-testid={`qje-filter-field-option-${filter.key}-${e}`}
                        data-cy={`qje-filter-field-option-${filter.key}-${e}`}
                        value={e}
                        key={i}
                        disabled={newFilters.map((f) => f.field).includes(e)}
                      >
                        {settings[e].label}
                      </Select.Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          data-testid={`qje-filter-value-option-${filter.key}`}
          data-cy={`qje-filter-value-option-${filter.key}`}
          initialValue={filter.value}
          name={`${filter.key}-value`}
          label='Value'
          rules={[{ required: true, message: 'Enter value.' }]}
          style={{ width: '90%' }}
        >
          {renderAppropriateInputField('picklist_multiple', filter)}
        </Form.Item>

        <GButton
          dataTestId={`qje-remove-filter-${filter.key}`}
          dataCy={`qje-remove-filter-${filter.key}`}
          type='text'
          className='g-btn-icon'
          onClick={() => {
            form.setFieldValue(`${filter.key}-value`, [])
            form.setFieldValue(`${filter.key}-field`, null)
            setNewFilters(newFilters.filter((f: any) => f.key !== filter.key))
          }}
          icon={<GIcon icon={faTrash} />}
        />
      </div>
    )
  }

  const onPreview = () => {
    if (newFilters.length === 0) {
      message.error('You need to add at least one filter.')
    } else {
      setPreviewTriggeredFrom('update')
      setIsPreviewMode(true)

      let filterDictionary: any = {}
      newFilters.forEach((filter: any) => {
        if (Object.keys(filterDictionary).includes(filter.field)) {
          filterDictionary[filter.field] = [
            ...filterDictionary[filter.field],
            ...filter.value
          ]
        } else {
          filterDictionary[filter.field] = [...filter.value]
        }
      })

      reloadData({ getAll: true, onlyRetrieveData: true })
        .then((data: any) => {
          let filtered = data.filter((entry: any) => {
            let numberOfFieldsSatisfied = 0
            Object.keys(entry)
              .filter((key: any) => Object.keys(filterDictionary).includes(key))
              .forEach((key: any) => {
                if (filterDictionary[key].includes(entry[key])) {
                  numberOfFieldsSatisfied += 1
                }
              })
            return (
              numberOfFieldsSatisfied === Object.keys(filterDictionary).length
            )
          })

          let filteredByPair = [...filtered]

          filtered.forEach((entry: any) => {
            let foundPair = data.find(
              (dataEntry: any) =>
                `${dataEntry.line_group}` === `${entry.line_group}` &&
                `${dataEntry.id}` !== `${entry.id}` &&
                !filtered.map((entry: any) => entry.id).includes(dataEntry.id)
            )

            if (foundPair) {
              filteredByPair = [...filteredByPair, foundPair]
            }
          })

          filteredByPair = filteredByPair.sort((a: any, b: any) => a.id - b.id)

          setDataPreviewed(filteredByPair)
          setIsShowModal(false)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const onFinish = async (e: any) => {
    setIsLoading(true)
    let payload: any = {}
    payload.filter_name = e.name
    payload.filter_fields = newFilters.map((filter) => {
      return { column: filter.field, value: filter.value }
    })
    if (newFilters.length === 0)
      message.error('You need to add at least one filter.')
    else {
      try {
        const { data } = await qjeFilterUpdate(
          payload,
          selectedFilterRef.current.id
        )
        message.success(data.message)
        setCustomFilters(data.all_filters)
        // setActiveFilter(data.default_filter)
        setIsLoading(false)
        setIsShowModal(false)
        setIsPreviewMode(false)
        reloadData()
        resetFields('all')
      } catch (error) {
        message.error('An error occurred.')
        setIsLoading(false)
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    console.log('Failed:', errorInfo)
    setIsLoading(false)
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      const { data } = await qjeFilterDelete(selectedFilterRef.current.id)
      message.success(data.message)
      setCustomFilters(data.all_filters)
      setIsLoading(false)
      setIsShowModal(false)

      // update refs and global filters
      dispatch(
        setGlobalSelectedFilter({
          id: data.default_filter,
          label: getFilterLabel(data.default_filter)
        })
      )
      selectedFilterRef.current = {
        id: data.default_filter,
        label: getFilterLabel(data.default_filter)
      }
    } catch (error) {
      message.error('An error occurred.')
      console.log('Error: ', error)
      setIsLoading(false)
    }
  }

  const handleChangeFilter = (filter: any, newField: any) => {
    setNewFilters(
      newFilters.map((newFilter) =>
        filter.key === newFilter.key
          ? { ...newFilter, field: newField, value: [] }
          : newFilter
      )
    )
  }

  const resetFields = (mode: string | number, clearTitle?: boolean) => {
    setCountNewFilters(0)
    setNewFilters([])
    if (mode === 'all') {
      Array.from(Array(countNewFilters), (_, index: number) => index).forEach(
        (index: number) => {
          form.setFieldValue(`${index}-field`, null)
          form.setFieldValue(`${index}-operator`, null)
          form.setFieldValue(`${index}-value`, null)
        }
      )
      setNewFilters([])
      setCountNewFilters(0)
    } else {
      form.setFieldValue(`${mode}-field`, null)
      form.setFieldValue(`${mode}-operator`, null)
      form.setFieldValue(`${mode}-value`, null)
    }

    if (clearTitle) {
      form.setFieldValue('name', null)
    }
  }

  useEffect(() => {
    if (showModal) {
      if (!isPreviewMode) {
        form.resetFields()
        Array.apply(null, Array(countNewFilters)).forEach((_, i: any) => {
          form.setFieldValue(`${i}-value`, [])
          form.setFieldValue(`${i}-field`, null)
        })
        setLoadingFilterDetails(true)
        if (
          !['all', 'posted', 'unposted', 'rejected'].includes(
            selectedFilterRef.current.id
          )
        ) {
          const fetchDetails = async () => {
            try {
              const { data } = await qjeFilterDetails(
                `${selectedFilterRef.current.id}`
              )
              setFilterName(data.name)
              setNewFilters(
                JSON.parse(data.filter_fields).map((e: any, i: any) => {
                  return {
                    key: i,
                    field: e.column,
                    value: e.value
                  }
                })
              )

              // for name
              form.setFieldValue('name', data.name)

              // for form fields
              let filter_fields = JSON.parse(data.filter_fields)
              filter_fields.forEach((e: any, i: any) => {
                form.setFieldValue(`${i}-field`, e.column)
                form.setFieldValue(`${i}-value`, e.value)
              })

              // for numbering of filter fields
              setCountNewFilters(filter_fields.length)
              setLoadingFilterDetails(false)
            } catch (error) {
              console.log(error)
              setLoadingFilterDetails(false)
            }
          }
          fetchDetails()
        }
      }
    }
    // eslint-disable-next-line
  }, [
    selectedGlobalFilter,
    selectedFilterRef,
    selectedFilterRef.current,
    showModal
  ])

  // for autocomplete backend calls...
  // after 1 sec, if user is not typing anything, fetch suggested values from BE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSuggestedValues(focusedApiName, searchTerm).then((res: any) => {
        setSuggestedValues(res.data)
      })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <Modal
      data-testid='qje-update-filter-modal'
      data-cy='qje-update-filter-modal'
      title='Update Filter'
      open={showModal}
      onCancel={onCancel}
      onOk={form.submit}
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round'
      }}
      footer={[
        <Button
          data-testid='qje-save-filter-btn'
          data-cy='qje-save-filter-btn'
          shape='round'
          key='save-view'
          type='primary'
          onClick={form.submit}
        >
          Update View
        </Button>,

        <Popconfirm
          data-testid='qje-popconfirm-delete-filter'
          data-cy='qje-popconfirm-delete-filter'
          title='Are you sure to delete this filter?'
          onConfirm={() => handleDelete()}
          okText='Yes'
          cancelText='No'
        >
          <Button
            data-testid='qje-delete-view-btn'
            data-cy='qje-delete-view-btn'
            shape='round'
            key='preview'
            type='default'
          >
            Delete View
          </Button>
        </Popconfirm>,
        <Button
          data-testid='qje-preview-filter-btn'
          data-cy='qje-preview-filter-btn'
          shape='round'
          key='preview'
          type='default'
          onClick={onPreview}
        >
          Preview
        </Button>,
        <Button
          data-testid='qje-cancel-filter-btn'
          data-cy='qje-cancel-filter-btn'
          shape='round'
          key='cancel'
          type='default'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
      width={900}
    >
      {loadingFilterDetails ? (
        <Form.Item
          label='Name'
          rules={[{ required: true, message: 'Enter name of the filter.' }]}
        >
          <Input disabled value='Loading filter details...' />
        </Form.Item>
      ) : (
        <Form
          data-testid='qje-update-filter-form'
          data-cy='qje-update-filter-form'
          form={form}
          layout='vertical'
          name='update-filter'
          initialValues={{ remember: true }}
          autoComplete='off'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            data-testid='qje-filter-name-form-item'
            data-cy='qje-filter-name-form-item'
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Enter name of the filter.' }]}
          >
            <Input
              data-testid='qje-filter-name-input'
              data-cy='qje-filter-name-input'
              value={filterName}
              maxLength={30}
            />
          </Form.Item>
          {newFilters.map((filter: any) => {
            return renderFilterInputComponents(filter)
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <GButton
              dataTestId='qje-add-filter-field-btn'
              dataCy='qje-add-filter-field-btn'
              btnText='Add Filter'
              onClick={() => {
                setNewFilters([
                  ...newFilters,
                  {
                    key: countNewFilters,
                    field: null,
                    value: []
                  }
                ])
                setCountNewFilters(countNewFilters + 1)
              }}
            />

            {newFilters.length > 0 ? (
              <GButton
                dataTestId='qje-remove-all-filter-fields-btn'
                dataCy='qje-remove-all-filter-fields-btn'
                btnText='Remove All'
                onClick={() => {
                  resetFields('all')
                }}
              />
            ) : null}
          </div>
        </Form>
      )}
    </Modal>
  )
}

export default UpdateFilterModal
