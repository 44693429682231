/** services imports */
import useApiService from '../../../../../hooks/useApiService'

/** types */
import WorkDayApiOptions from '../types/WorkDayApiOptions'
import WorkDayApiParams from '../types/WorkDayApiParams'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

// =================================================================
export default function useWorkdayApiService() {
  /** services */
  const { apiRequest } = useApiService()

  const workDayApi = <T>(
    props: BaseProps<WorkDayApiParams, T, WorkDayApiOptions>
  ) =>
    apiRequest(
      props.options.baseUrl,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return { workDayApi }
}
