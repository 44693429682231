// ** React Imports
import { useState } from 'react'

// ** AntD Imports
import { Form, Input, Select, Switch, Tag, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'

// ** Hook Imports
import useAnalytics from '../../hooks/useAnalytics'

// ** Third Party Imports
import { useQueryClient } from '@tanstack/react-query'

// ** Style Imports
import '../../styles/update-accrual.styles.css'

// ** Type Imports
import AccrualDataType from '../../types/AccrualDataType'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
export default function UpdateAccrualData() {
  // ** Store **
  const {
    permNoteVendorId,
    updateAccrualEditingKey,
    handleUpdateAccrualCellClick,
    responseType
  } = useAnalyticsStore()

  // ** State **
  const [tempEmail, setTempEmail] = useState<string | null>(null)

  // ** TanStack Query **
  const queryClient = useQueryClient()
  const updateAccrual: Record<string, []> =
    queryClient.getQueryData(['update-accrual-manager', permNoteVendorId]) || {}

  const accrualCode =
    (updateAccrual?.data &&
      updateAccrual?.data.length > 0 &&
      updateAccrual?.data.map(
        (item: { accrual_code: Record<string, string> }) => {
          return Object.entries(item.accrual_code).reduce(
            (acc, [key, value]) => {
              acc[key] = value
              return acc
            },
            {} as Record<string, string>
          )
        }
      )) ||
    []

  const calculatedCode =
    (updateAccrual?.data &&
      updateAccrual?.data.length > 0 &&
      updateAccrual?.data.map(
        (item: { calculation_types: Record<string, string> }) => {
          return Object.entries(item.calculation_types).reduce(
            (acc, [key, value]) => {
              acc[key] = value
              return acc
            },
            {} as Record<string, string>
          )
        }
      )) ||
    []

  // ** Hooks (API) **
  const { handleSubmitUpdateAccrual, patchUpdateAccrualManagerLoading } =
    useAnalytics({
      responseType: responseType
    })

  const columns: ColumnType<AccrualDataType>[] = [
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: '50px',
      render: (text: string, record: AccrualDataType) => (
        <Switch
          checked={record.active === 1}
          onChange={(value) => {
            handleSubmitUpdateAccrual({
              vendorTaskId: `${record.id}`,
              updateAccrualChanges: { [`active`]: value }
            })
          }}
          loading={patchUpdateAccrualManagerLoading}
        />
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.name && b.name) {
      //     return a.name.localeCompare(b.name)
      //   } else if (a.name) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'Task Type',
      dataIndex: 'task_type',
      key: 'task_type',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.task_type && b.task_type) {
      //     return a.task_type.localeCompare(b.task_type)
      //   } else if (a.task_type) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => {
        const taskTypeColors: Record<string, string> = {
          Vendor: 'processing',
          'PO Copy': 'warning',
          PO: 'error'
        }
        const color = taskTypeColors[text] || 'success'

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Tag
              color={color}
              style={{ width: 100, textAlign: 'center', borderRadius: '5px' }}
            >
              {text}
            </Tag>
          </div>
        )
      }
    },
    {
      title: 'PO Number Line',
      dataIndex: 'vendor_po_id',
      key: 'vendor_po_id',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.vendor_po_id && b.vendor_po_id) {
      //     return a.vendor_po_id.localeCompare(b.vendor_po_id)
      //   } else if (a.vendor_po_id) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'Vendor Accrual',
      dataIndex: 'external_accrual',
      key: 'external_accrual',
      width: '300px',
      // sorter: (a, b) => {
      //   const valueA = String(a.external_accrual)
      //   const valueB = String(b.external_accrual)

      //   return valueA.localeCompare(valueB)
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string, record: AccrualDataType, rowIndex: number) => {
        const currentRowAccrualCodes = accrualCode[rowIndex]
        const options = Object.entries(currentRowAccrualCodes).map(
          ([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          )
        )
        const isEditing =
          updateAccrualEditingKey === `${record.key}-external_accrual`
        const displayText =
          currentRowAccrualCodes[record.external_accrual] || text
        if (record) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <Form.Item style={{ margin: 0 }} initialValue={displayText}>
                {isEditing ? (
                  <Select
                    style={{
                      width: 200
                    }}
                    onChange={(value) => {
                      handleSubmitUpdateAccrual({
                        vendorTaskId: `${record.id}`,
                        updateAccrualChanges: { [`external_accrual`]: value }
                      })
                    }}
                    loading={patchUpdateAccrualManagerLoading}
                    onBlur={() =>
                      handleUpdateAccrualCellClick(
                        `${record.key}-external_accrual`
                      )
                    }
                    defaultValue={displayText}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        // If the dropdown is closed, remove the editing key.
                        handleUpdateAccrualCellClick(null)
                      }
                    }}
                    autoFocus={true}
                    open={isEditing}
                  >
                    {options}
                  </Select>
                ) : (
                  <span
                    onDoubleClick={() =>
                      handleUpdateAccrualCellClick(
                        `${record.key}-external_accrual`
                      )
                    }
                  >
                    {displayText}
                  </span>
                )}
              </Form.Item>
            </div>
          )
        }
        return text
      }
    },
    {
      title: 'Internal Accrual',
      dataIndex: 'internal_accrual',
      key: 'internal_accrual',
      width: '300px',
      // sorter: (a, b) => {
      //   const valueA = String(a.internal_accrual)
      //   const valueB = String(b.internal_accrual)

      //   return valueA.localeCompare(valueB)
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string, record: AccrualDataType, rowIndex: number) => {
        const currentRowAccrualCodes = accrualCode[rowIndex]
        const options = Object.entries(currentRowAccrualCodes).map(
          ([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          )
        )
        const isEditing =
          updateAccrualEditingKey === `${record.key}-internal_accrual`
        const displayText =
          currentRowAccrualCodes[record.internal_accrual] || text
        if (record) {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <Form.Item style={{ margin: 0 }} initialValue={displayText}>
                {isEditing ? (
                  <Select
                    style={{ width: 200 }}
                    onChange={(value) => {
                      handleSubmitUpdateAccrual({
                        vendorTaskId: `${record.id}`,
                        updateAccrualChanges: { [`internal_accrual`]: value }
                      })
                    }}
                    loading={patchUpdateAccrualManagerLoading}
                    onBlur={() =>
                      handleUpdateAccrualCellClick(
                        `${record.key}-internal_accrual`
                      )
                    }
                    defaultValue={displayText}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        // If the dropdown is closed, remove the editing key.
                        handleUpdateAccrualCellClick(null)
                      }
                    }}
                    autoFocus={true}
                    open={isEditing}
                  >
                    {options}
                  </Select>
                ) : (
                  <span
                    onDoubleClick={() =>
                      handleUpdateAccrualCellClick(
                        `${record.key}-internal_accrual`
                      )
                    }
                  >
                    {displayText}
                  </span>
                )}
              </Form.Item>
            </div>
          )
        }
        return text
      }
    },
    {
      title: 'Calculated',
      dataIndex: 'calculation',
      key: 'calculation',
      width: '300px',
      // sorter: (a, b) => {
      //   const valueA = String(a.calculation)
      //   const valueB = String(b.calculation)

      //   return valueA.localeCompare(valueB)
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string, record: AccrualDataType, rowIndex: number) => {
        const currentRowCalculatedCodes = calculatedCode[rowIndex]
        const options = Object.entries(currentRowCalculatedCodes).map(
          ([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          )
        )
        const isEditing =
          updateAccrualEditingKey === `${record.key}-calculation`
        const displayText =
          currentRowCalculatedCodes[record.calculation] || text
        if (record) {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <Form.Item style={{ margin: 0 }} initialValue={displayText}>
                {isEditing ? (
                  <Select
                    style={{ width: 200 }}
                    onChange={(value) => {
                      handleSubmitUpdateAccrual({
                        vendorTaskId: `${record.id}`,
                        updateAccrualChanges: { [`calculation`]: value }
                      })
                    }}
                    loading={patchUpdateAccrualManagerLoading}
                    onBlur={() =>
                      handleUpdateAccrualCellClick(`${record.key}-calculation`)
                    }
                    defaultValue={displayText}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        // If the dropdown is closed, remove the editing key.
                        handleUpdateAccrualCellClick(null)
                      }
                    }}
                    autoFocus={true}
                    open={isEditing}
                  >
                    {options}
                  </Select>
                ) : (
                  <span
                    onDoubleClick={() =>
                      handleUpdateAccrualCellClick(`${record.key}-calculation`)
                    }
                  >
                    {displayText}
                  </span>
                )}
              </Form.Item>
            </div>
          )
        }
        return text
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.email && b.email) {
      //     return a.email.localeCompare(b.email)
      //   } else if (a.email) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'Internal Email',
      dataIndex: 'internal_email',
      key: 'internal_email',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.internalEmail && b.internalEmail) {
      //     return a.internalEmail.localeCompare(b.internalEmail)
      //   } else if (a.internalEmail) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'CC Email',
      dataIndex: 'cc_email',
      key: 'cc_email',
      width: '300px',
      // sorter: (a, b) => {
      //   if (a.cc_email && b.cc_email) {
      //     return a.cc_email.localeCompare(b.cc_email)
      //   } else if (a.cc_email) {
      //     return 1
      //   } else {
      //     return -1
      //   }
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text: string, record: AccrualDataType) => {
        const isEditing = updateAccrualEditingKey === `${record.key}-cc_email`
        if (record) {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <Form.Item
                name={`${record.key}-cc_email`}
                style={{ margin: 0 }}
                initialValue={text}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email!'
                  }
                ]}
              >
                {isEditing ? (
                  <Input
                    defaultValue={text}
                    onBlur={() => {
                      if (tempEmail !== null) {
                        handleSubmitUpdateAccrual({
                          vendorTaskId: `${record.id}`,
                          updateAccrualChanges: { [`cc_email`]: tempEmail }
                        })
                      }
                      handleUpdateAccrualCellClick(`${record.key}-cc_email`)
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setTempEmail(e.target.value)
                    }}
                  />
                ) : (
                  <span
                    onDoubleClick={() =>
                      handleUpdateAccrualCellClick(`${record.key}-cc_email`)
                    }
                  >
                    {text ?? 'Click to add email'}
                  </span>
                )}
              </Form.Item>
            </div>
          )
        }
        return text
      }
    }
  ]

  const rowsFromBackend =
    (updateAccrual?.data &&
      updateAccrual?.data.length > 0 &&
      updateAccrual?.data.map((item: Record<string, string>, index) => ({
        key: (index + 1).toString(),
        id: item.id,
        active: item.active,
        name: item.name,
        task_type: item.task_type,
        vendor_po_id: item.vendor_po_id,
        external_accrual: item.external_accrual,
        internal_accrual: item.internal_accrual,
        calculation: item.calculation,
        email: item.email,
        internal_email: item.internal_email,
        cc_email: item.cc_email
      }))) ||
    []

  const createRows = (numRows: number) => {
    const baseRow = {
      name: 'DLA Piper',
      poNumberLine: 'PO-US-562-1',
      vendorAccrual: 'Unbilled',
      internalAccrual: 'System Administrator',
      calculated: 'Historical Accrual',
      email: 'todd@gappify.com',
      internalEmail: 'rahul@gappify.com',
      ccEmail: 'paul@gappify.com'
    }

    const names = ['Atlassian', 'Reddit', 'Stripe', 'Glassdoor']

    const poNumberLines = ['PO-US-562-1', 'PO-US-562-2', 'PO-US-562-3']

    const vendorAccruals = ['Unbilled', 'No']

    const internalAccruals = ['No', 'Unbilled']

    const calculateds = ['Historical Accrual', 'No']

    const emails = [
      'todd@gappify.com',
      'knoel@gappify.com',
      'paolo@gappify.com',
      'alyza@gappify.com'
    ]

    const internalEmails = [
      'todd@gappify.com',
      'knoel@gappify.com',
      'paolo@gappify.com',
      'alyza@gappify.com'
    ]

    return Array.from({ length: numRows }, (_, i) => {
      const randomNames = names[Math.floor(Math.random() * names.length)]
      const randomPoNumberLines =
        poNumberLines[Math.floor(Math.random() * poNumberLines.length)]
      const randomVendorAccruals =
        vendorAccruals[Math.floor(Math.random() * vendorAccruals.length)]
      const randomInternalAccruals =
        internalAccruals[Math.floor(Math.random() * internalAccruals.length)]
      const randomCalculateds =
        calculateds[Math.floor(Math.random() * calculateds.length)]
      const randomEmails = emails[Math.floor(Math.random() * emails.length)]
      const randomInternalEmails =
        internalEmails[Math.floor(Math.random() * internalEmails.length)]
      return {
        key: (i + 1).toString(),
        ...baseRow,
        name: randomNames,
        poNumberLine: randomPoNumberLines,
        vendorAccrual: randomVendorAccruals,
        internalAccrual: randomInternalAccruals,
        calculated: randomCalculateds,
        email: randomEmails,
        internalEmail: randomInternalEmails
      }
    })
  }
  const rows = createRows(20)

  return {
    rows,
    columns,
    rowsFromBackend
  }
}
