import { MoreOutlined } from '@ant-design/icons'
import { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import GDropdownBtn from '../../../../../components/gappify/GDropdownBtn'
import User from '../types/User'
import { SetStateAction } from 'react'
import DROPDOWN_KEYS from '../constants/DROPDOWN_KEYS'
import handleDropdownOnClick from './handleDropdownOnClick'

export default function getUserDashboardActionItems(
  record: User,
  setUserId: (value: SetStateAction<number | undefined>) => void,
  setUserEmail: (value: SetStateAction<string>) => void,
  setIsActivateModalVisible: (value: SetStateAction<boolean>) => void,
  setUserStatus: (
    value: SetStateAction<'Active' | 'Inactive' | 'Pending' | undefined>
  ) => void,
  setUserName: (value: SetStateAction<string>) => void,
  setIsUserModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResetModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResendActionLinkModalVisible: (value: SetStateAction<boolean>) => void,
  setIsHistoryModalVisible: (value: SetStateAction<boolean>) => void
): JSX.Element {
  const isAccessSsoEnabled = localStorage.getItem('access_sso')
  const userDashboardActionItems: MenuItemType[] = [
    {
      key: DROPDOWN_KEYS.EDIT,
      label: (
        <span data-testid='edit-action-btn' data-cy='edit-action-btn'>
          Edit
        </span>
      )
    }
  ]

  if (
    record.status !== 'Inactive' &&
    !record.is_new &&
    (isAccessSsoEnabled === 'false' || !isAccessSsoEnabled)
  ) {
    userDashboardActionItems.push({
      key: DROPDOWN_KEYS.RESET,
      label: (
        <span data-testid='reset-action-btn' data-cy='reset-action-btn'>
          Reset Password
        </span>
      )
    })
  }

  userDashboardActionItems.push({
    key: DROPDOWN_KEYS.HISTORY,
    label: (
      <span data-testid='history-action-btn' data-cy='history-action-btn'>
        History
      </span>
    )
  })

  if (record.status === 'Active' || record.status === 'Inactive') {
    const label: string = record.status === 'Active' ? 'Deactivate' : 'Activate'

    userDashboardActionItems.push(
      {
        type: 'divider'
      },
      {
        key: DROPDOWN_KEYS.ACTIVATE,
        label: (
          <span data-testid='activate-action-btn' data-cy='activate-action-btn'>
            {label} User
          </span>
        )
      }
    )
  }

  if (record.is_new && record.status === 'Pending') {
    userDashboardActionItems.push(
      {
        type: 'divider'
      },
      {
        key: DROPDOWN_KEYS.RESEND_ACTIVATION,
        label: (
          <span
            data-testid='resend-activation-action-btn'
            data-cy='resend-activation-action-btn'
          >
            Resend Activation Link
          </span>
        )
      }
    )
  }

  return (
    <span className='data-table-action'>
      <GDropdownBtn
        dataTestId={`actions-btn`}
        dataCy={`actions-btn`}
        menuItems={userDashboardActionItems}
        btnText=''
        btnIcon={
          <MoreOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        }
        onClick={(event: MenuItemType) =>
          handleDropdownOnClick(
            record.id,
            record.email,
            record.status,
            record.name,
            setUserId,
            setUserEmail,
            setIsActivateModalVisible,
            setUserStatus,
            setUserName,
            setIsUserModalVisible,
            setIsResetModalVisible,
            setIsResendActionLinkModalVisible,
            setIsHistoryModalVisible,
            event?.key
          )
        }
      />
    </span>
  )
}
