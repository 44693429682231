// ** Third Party Imports
import { useMutation } from '@tanstack/react-query'

// ** API Services Imports
import useResetPasswordModalService from '../services/ResetPasswordModalService'

// ** Zustand Store Imports
import useResetPasswordModalStore from '../manager/resetpassword-store'

// =================================================================
export default function useResetPasswordMutation() {
  // ** API Services **
  const { ResetPasswordModalAPI } = useResetPasswordModalService()

  // ** Zustand Store **
  const { setResponseMessage, setIsResetPasswordModalOpen } =
    useResetPasswordModalStore()

  const { mutate: ResetPasswordAPI, isLoading } = useMutation(
    ResetPasswordModalAPI,
    {
      onSuccess: (response) => {
        setResponseMessage(response.message)
        setTimeout(() => {
          setResponseMessage('')
          setIsResetPasswordModalOpen(false)
        }, 1000)
      },
      onError: (error: { data: { message: string } }) => {
        setResponseMessage(error.data.message)
      }
    }
  )

  const handleSubmit = (
    values: {},
    msUrl: { token_duration: number; idms: string }
  ) => {
    ResetPasswordAPI({
      options: {
        baseURL: msUrl.idms,
        endpoint: `api/login/${values}/reset-password`,
        method: 'POST'
      }
    })
  }

  return {
    handleSubmit,
    isLoading
  }
}
