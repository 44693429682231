import { TApiColumns } from '../types/api'

type TParentHeader = {
  title: string
  key: keyof TApiColumns
}

export enum PARENT_HEADERS {
  historicals = 'Historicals',
  month_to_date = 'Month-to-Date',
  gappify_accruals = 'Gappify Accruals',
  analysis = 'Analysis'
}

const tableParentHeader: TParentHeader[] = [
  {
    title: '',
    key: 'dimensions'
  },
  {
    title: PARENT_HEADERS.historicals,
    key: 'historicals'
  },
  {
    title: PARENT_HEADERS.month_to_date,
    key: 'month_to_date'
  },
  {
    title: PARENT_HEADERS.gappify_accruals,
    key: 'gappify_accruals'
  },
  {
    title: PARENT_HEADERS.analysis,
    key: 'analysis'
  }
]

export default tableParentHeader
