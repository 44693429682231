import { Checkbox, Popover } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface CheckboxRowProps {
  label: string
  defaultChecked?: boolean
  onChange?: (e: CheckboxChangeEvent) => void
  disabled?: boolean
  dataTestId?: string
  dataCy?: string
  popoverMessage?: string
  checked?: boolean
}

const CheckboxRow = ({
  label,
  defaultChecked,
  onChange,
  disabled,
  dataTestId,
  dataCy,
  popoverMessage,
  checked
}: CheckboxRowProps) => {
  return (
    <Checkbox
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={checked}
      disabled={disabled}
      data-testid={dataTestId}
      data-cy={dataCy}
    >
      <Popover content={popoverMessage}>{label}</Popover>
    </Checkbox>
  )
}

export default CheckboxRow
