// ** Ant Design Components **
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

// ** React Components and Hooks **
import { FC } from 'react'
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

// ** QJE Manager State **
import useQjeStore from '../manager/qje-store'

// ** Types **
import LineGroupCheckboxProps from '../types/LineGroupCheckboxProps'

// ===============================================================
const LineGroupCheckbox: FC<LineGroupCheckboxProps> = ({
  index,
  line_group
}: LineGroupCheckboxProps): JSX.Element => {
  // * Hooks **
  const RP = useRolesAndPermissions()
  const { selectedLineGroups, setSelectedLineGroups } = useQjeStore()

  const handleOnCheck = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked
    if (!isChecked) {
      const removeItem = selectedLineGroups.filter(
        (item: string | number) => item !== line_group
      )
      setSelectedLineGroups(removeItem)
    } else {
      setSelectedLineGroups([...selectedLineGroups, line_group!])
    }
  }

  return (
    <Checkbox
      data-testid={`qje-review-je-checkbox-linegroup-${line_group}`}
      data-cy={`qje-review-je-checkbox-linegroup-${line_group}`}
      defaultChecked={false}
      disabled={
        !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
        !RP.USER_HAS_REJECT_QJE_PERMISSION ||
        !RP.USER_HAS_REVIEWED_FLAG_QJE_PERMISSION
      }
      checked={selectedLineGroups.includes(line_group!)}
      onChange={handleOnCheck}
    />
  )
}

export default LineGroupCheckbox
