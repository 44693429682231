/** React Imports */
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

/** 3rd Party Imports */
import jwt_decode from 'jwt-decode'

/** Custom Hooks */
import useLogin from 'src/pages/Login/hooks/useLogin'

/** Types Imports */
import DecodedJWTProps from 'src/pages/Login/types/DecodedJWTProps'
import LoginResponseDataProps from 'src/pages/Login/types/LoginResponseDataProps'

/** Constants */
import { AUTH_TOKEN } from 'src/helpers/constants/Auth'

//==========================================================================
export default function useToolsGoogleSSO() {
  const [searchParameters] = useSearchParams()
  const parameters = ['access_token', 'expires_in', 'expires_on', 'token_type']
  const getParameters = (parameter: string): string =>
    searchParameters.get(parameter) || ''
  const [access_token, expires_in, expires_on, token_type] =
    parameters.map(getParameters)
  const { setLocalStorageData, redirectPage } = useLogin()
  const allToolsParamsPresent = parameters.every(getParameters)

  useEffect(() => {
    if (!allToolsParamsPresent) {
      return
    }

    const data: LoginResponseDataProps = {
      access_token,
      expires_in: Number(expires_in),
      expires_on,
      token_type
    }

    const jwtToken = window.atob(access_token)
    const decodedJwt: DecodedJWTProps = jwt_decode(jwtToken)

    localStorage.setItem(AUTH_TOKEN, access_token)
    setLocalStorageData(data, decodedJwt)
    redirectPage(decodedJwt)
  }, [
    redirectPage,
    setLocalStorageData,
    access_token,
    expires_in,
    expires_on,
    token_type,
    allToolsParamsPresent
  ])

  return { allToolsParamsPresent }
}
