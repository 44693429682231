// ** Ant Design Components **
import { DatePicker, Form, Input, message, Modal } from 'antd'

// ** React Hooks **
import { FC, useEffect, useState } from 'react'

// ** Date Utility **
import { formatDateSlash } from '../../../utils/Date'
import moment from 'moment'

// ** Custom Hooks **
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

// ** Types **
import type PostJeModalProps from '../types/PostJeModalProps'

// ** Custom Components **
import GButton from '../../../components/gappify/GButton'

// ** QJE Manager State **
import useQjeStore from '../manager/qje-store'
import useQje from '../hooks/useQje'

// =======================================================================
const PostJEModal: FC<PostJeModalProps> = (
  props: PostJeModalProps
): JSX.Element => {
  // ** States **
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState<boolean>(false)

  // ** Hooks **
  const { selectedLineGroups, period } = useQjeStore()
  const RP = useRolesAndPermissions()

  // ** React Query **
  const {
    isLoadingQjeAction,
    handleSubmit,
    isSuccessQjeAction,
    isErrorQjeAction
  } = useQje()

  // ** Functions **
  const handleShowModal = (): void => {
    setShowModal(true)
  }

  const handleCancelModal = (): void => {
    setShowModal(false)
    form.resetFields()
  }

  const submitPostJE = async (): Promise<void> => {
    // ** NEW IMPLEMENTATION (using react-query) **
    handleSubmit({
      endpoint: '/api/review/je',
      payload: {
        // Ensure that line_groups are unique values
        line_groups: Array.from(new Set(selectedLineGroups)),
        je_date: formatDateSlash(form.getFieldValue('je_date')),
        je_memo: form.getFieldValue('je_memo'),
        je_reversal_date: form.getFieldValue('je_reversal_date')
          ? formatDateSlash(form.getFieldValue('je_reversal_date'))
          : null,
        je_approval_type: null
      }
    })
  }

  const onFinishFailed = (errorInfo: any) =>
    message.error(errorInfo.errorFields[0].errors[0])

  useEffect(() => {
    if (showModal) {
      form.setFieldValue('je_date', moment(period.key).endOf('month'))
      form.setFieldValue(
        'je_reversal_date',
        moment(period.key).add(1, 'months').startOf('month')
      )
    }
  }, [period, form, showModal])

  // Handle success POST method...
  useEffect(() => {
    if (isSuccessQjeAction) {
      // Handle success, e.g., show a success message, update UI, etc.
      message.success('JE processed successfully!')
      setShowModal(false)
      form.resetFields()
    }
  }, [isSuccessQjeAction, form])

  // Catch error when POST method failed..
  useEffect(() => {
    if (isErrorQjeAction) {
      // Handle error, e.g., show an error message, log the error, etc.
      console.error('An error occurred during mutation!')
    }
  }, [isErrorQjeAction])

  return (
    <>
      <GButton
        dataTestId='qje-btn-post-je'
        dataCy='qje-btn-post-je'
        btnText='Post JE'
        disabled={
          !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
          selectedLineGroups?.length === 0
        }
        onClick={handleShowModal}
      />
      <Modal
        data-testid='qje-post-je-modal'
        data-cy='qje-post-je-modal'
        open={showModal}
        title="Please provide following information to post JE's"
        okText='Post'
        onCancel={handleCancelModal}
        onOk={form.submit}
        width={600}
        centered
        destroyOnClose
        confirmLoading={isLoadingQjeAction}
      >
        <Form
          data-testid='qje-post-je-form'
          data-cy='qje-post-je-form'
          form={form}
          layout='vertical'
          onFinish={submitPostJE}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='JE Date'
            name='je_date'
            rules={[{ required: true }]}
            data-testid='qje-post-je-date-form-item'
            data-cy='qje-post-je-date-form-item'
          >
            <DatePicker
              style={{ width: '100%' }}
              data-testid='qje-post-je-date-picker'
              data-cy='qje-post-je-date-picker'
            />
          </Form.Item>
          <Form.Item
            label='JE Memo'
            name='je_memo'
            rules={[{ required: true }]}
            data-testid='qje-post-je-memo-form-item'
            data-cy='qje-post-je-memo-form-item'
          >
            <Input
              data-testid='qje-post-je-memo-input'
              data-cy='qje-post-je-memo-input'
            />
          </Form.Item>
          <Form.Item
            label='JE Reversal Date'
            name='je_reversal_date'
            data-testid='qje-post-je-reversal-date-form-item'
            data-cy='qje-post-je-reversal-date-form-item'
          >
            <DatePicker
              style={{ width: '100%' }}
              data-testid='qje-post-je-reversal-date-picker'
              data-cy='qje-post-je-reversal-date-picker'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default PostJEModal
