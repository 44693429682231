// ** React Imports
import { useEffect, useState } from 'react'

// ** AntD Imports
import { Button, Form, Modal, Tabs, message } from 'antd'

// ** Custom Component Imports
import ExecuteActionsTable from '../../Table/ExecuteActionsTable'

// ** Type/Enum Imports
import AnalyticsModalProps from '../../../types/Modal'
import { SuggestedActions } from '../../../constants/NonCompliant'
import { SuggestedActions as SuggestedActionsIncreasing } from '../../../constants/IncreasingSpend'
import { INonCompliantInvoices } from '../../../types/NonCompliantDataType'
import { TIncreasingSpend } from '../../../types/IncreasingSpendDataType'

// ** Zustand Imports
import useAnalyticsStore from '../../../manager/analytics-store'

// ** Style Imports
import styles from '../../../styles/non-compliant-data.module.css'
import useAnalytics from '../../../hooks/useAnalytics'

const success = () => {
  message.success('Execute action success!')
}

const error = (errorMsg: string) => {
  message.error(errorMsg || 'Something went wrong. Please try again later.')
}

// =================================================================
const ExecuteActionModal = ({
  openModal,
  setOpenModal,
  modalType
}: AnalyticsModalProps) => {
  // ** State **
  const [selectedTab, setSelectedTab] = useState<string>('')

  // ** Zustand **
  const {
    nonCompliantSelectedRowKeys,
    nonCompliantSelectedRows,
    nonCompliantFirstTabData,
    nonCompliantSecondTabData,
    nonCompliantThirdTabData,
    setNonCompliantFirstTabData,
    setNonCompliantSecondTabData,
    setNonCompliantThirdTabData,
    increasingSpendSelectedRowKeys,
    increasingSpendSelectedRows,
    increasingSpendFirstTabData,
    increasingSpendSecondTabData,
    setIncreasingSpendFirstTabData,
    setIncreasingSpendSecondTabData,
    errorMsg
  } = useAnalyticsStore()

  // ** Hooks **
  const {
    handlePostExecuteAction,
    postExecuteActionLoading,
    postExecuteActionSuccess,
    postExecuteActionError
  } = useAnalytics({
    responseType: undefined
  })

  // ** AntD **
  const [form] = Form.useForm()

  const getChildren = (label: string) => {
    if (label === selectedTab) {
      return <ExecuteActionsTable selectedTab={selectedTab} />
    }

    return <>Not active tab</>
  }

  const TabItems = [
    {
      label: SuggestedActions.DismissPolicyExemption,
      key: '1',
      children: getChildren(SuggestedActions.DismissPolicyExemption)
    },
    {
      label: SuggestedActions.DismissOther,
      key: '2',
      children: getChildren(SuggestedActions.DismissOther)
    },
    {
      label: SuggestedActions.ViolationStandard,
      key: '3',
      children: getChildren(SuggestedActions.ViolationStandard)
    }
  ]

  const TabItemsIncreasing = [
    {
      label: SuggestedActionsIncreasing.Dismiss,
      key: '1',
      children: getChildren(SuggestedActionsIncreasing.Dismiss)
    },
    {
      label: SuggestedActionsIncreasing.AlertStandard,
      key: '2',
      children: getChildren(SuggestedActionsIncreasing.AlertStandard)
    }
  ]

  const renderTabItems = () => {
    let currentTabItems = []

    if (nonCompliantSelectedRowKeys.length) currentTabItems = TabItems
    else currentTabItems = TabItemsIncreasing

    return currentTabItems.map((tabItem) => {
      return {
        ...tabItem
        //   disabled:
      }
    })
  }

  const onTabClick = (key: string) => {
    if (nonCompliantSelectedRowKeys.length) {
      switch (key) {
        case '1': {
          setSelectedTab(SuggestedActions.DismissPolicyExemption)
          break
        }
        case '2': {
          setSelectedTab(SuggestedActions.DismissOther)
          break
        }
        case '3': {
          setSelectedTab(SuggestedActions.ViolationStandard)
          break
        }
        default:
          break
      }
    } else {
      switch (key) {
        case '1': {
          setSelectedTab(SuggestedActionsIncreasing.Dismiss)
          break
        }
        case '2': {
          setSelectedTab(SuggestedActionsIncreasing.AlertStandard)
          break
        }
        default:
          break
      }
    }
  }

  const formatTabDataNonCompliant = (data: any) => {
    // Initialize first tab
    setSelectedTab(SuggestedActions.DismissPolicyExemption)

    let tempFirstTabData: INonCompliantInvoices[] = []
    let tempSecondTabData: INonCompliantInvoices[] = []
    let tempThirdTabData: INonCompliantInvoices[] = []

    /**
     * Loop through all selected rows
     * Grouping them by Suggested Actions to display in separate tabs
     */
    data.forEach((value: INonCompliantInvoices, index: number) => {
      const currentAction = value.suggested_action
      if (currentAction === SuggestedActions.DismissPolicyExemption) {
        tempFirstTabData.push(value)
      } else if (currentAction === SuggestedActions.DismissOther) {
        tempSecondTabData.push(value)
      } else if (currentAction === SuggestedActions.ViolationStandard) {
        tempThirdTabData.push(value)
      }
    })

    // Set into each respective states
    setNonCompliantFirstTabData(tempFirstTabData)
    setNonCompliantSecondTabData(tempSecondTabData)
    setNonCompliantThirdTabData(tempThirdTabData)
  }

  const formatTabDataIncreasingSpend = (data: any) => {
    // Initialize first tab
    setSelectedTab(SuggestedActionsIncreasing.Dismiss)

    let tempFirstTabData: TIncreasingSpend[] = []
    let tempSecondTabData: TIncreasingSpend[] = []

    /**
     * Loop through all selected rows
     * Grouping them by Suggested Actions to display in separate tabs
     */
    data.forEach((value: TIncreasingSpend, index: number) => {
      const currentAction = value.suggested_action
      if (currentAction === SuggestedActionsIncreasing.Dismiss) {
        tempFirstTabData.push(value)
      } else if (currentAction === SuggestedActionsIncreasing.AlertStandard) {
        tempSecondTabData.push(value)
      }
    })

    // Set into each respective states
    setIncreasingSpendFirstTabData(tempFirstTabData)
    setIncreasingSpendSecondTabData(tempSecondTabData)
  }

  const handleSubmit = (formData: any) => {
    if (nonCompliantSelectedRowKeys.length) handleSubmitNonCompliant(formData)
    else handleSubmitIncreasingSpend(formData)
  }

  const handleSubmitNonCompliant = (formData: any) => {
    // ###########################################################
    // START: Restructure data

    // Restructure form & tab data as needed by the API
    let restructuredData: any[] = []
    nonCompliantFirstTabData.forEach((value) => {
      const currentData = {
        invoice_id: value.invoice_id,
        purchase_order_id: value.purchase_order_id,
        suggested_action: value.suggested_action,
        vendor_name: value.vendor_name,
        email: value.email
      }
      restructuredData.push(currentData)
    })

    nonCompliantSecondTabData.forEach((value) => {
      const currentData = {
        invoice_id: value.invoice_id,
        purchase_order_id: value.purchase_order_id,
        suggested_action: value.suggested_action,
        vendor_name: value.vendor_name,
        email: value.email
      }
      restructuredData.push(currentData)
    })

    nonCompliantThirdTabData.forEach((value) => {
      const currentData = {
        invoice_id: value.invoice_id,
        purchase_order_id: value.purchase_order_id,
        suggested_action: value.suggested_action,
        vendor_name: value.vendor_name,
        email: formData[value.key].email,
        cc_email: formData[value.key].cc_email || null
      }
      restructuredData.push(currentData)
    })

    // END: Restructure data
    // ###########################################################

    let finalData = {
      data: restructuredData,
      subject: formData.subject || '-',
      body: formData.body || '-'
    }

    handlePostExecuteAction(finalData, 'spend-compliance/action')
  }

  const handleSubmitIncreasingSpend = (formData: any) => {
    // ###########################################################
    // START: Restructure data

    // Restructure form & tab data as needed by the API
    let restructuredData: any[] = []
    increasingSpendFirstTabData.forEach((value) => {
      const currentData = {
        invoice_id: value.id,
        suggested_action: value.suggested_action,
        email: value.email
      }
      restructuredData.push(currentData)
    })

    increasingSpendSecondTabData.forEach((value) => {
      const currentData = {
        invoice_id: value.id,
        suggested_action: value.suggested_action,
        vendor_name: value.vendor_name,
        email: formData[value.key].email,
        cc_email: formData[value.key].cc_email || null
      }
      restructuredData.push(currentData)
    })

    // END: Restructure data
    // ###########################################################

    let finalData = {
      data: restructuredData,
      subject: formData.subject || '-',
      body: formData.body || '-'
    }

    handlePostExecuteAction(finalData, 'increasing-spend')
  }

  const handleCancel = () => {
    if (nonCompliantSelectedRowKeys.length) {
      setSelectedTab(SuggestedActions.DismissPolicyExemption)
      setNonCompliantThirdTabData([])
    } else {
      setSelectedTab(SuggestedActionsIncreasing.Dismiss)
      setIncreasingSpendSecondTabData([])
    }
    setOpenModal(false)
    form.resetFields()
  }

  const isEmptyData = () => {
    return (
      !!!nonCompliantFirstTabData.length &&
      !!!nonCompliantSecondTabData.length &&
      !!!nonCompliantThirdTabData.length
    )
  }

  const isEmptyDataIncreasing = () => {
    return (
      !!!increasingSpendFirstTabData.length &&
      !!!increasingSpendSecondTabData.length
    )
  }

  useEffect(() => {
    if (openModal && modalType === 'execute-action') {
      if (nonCompliantSelectedRowKeys.length)
        formatTabDataNonCompliant(nonCompliantSelectedRows)
      else if (increasingSpendSelectedRowKeys.length)
        formatTabDataIncreasingSpend(increasingSpendSelectedRows)
    }
    // eslint-disable-next-line
  }, [openModal, modalType])

  // ** Component updates after calling Post Execute Actions API
  useEffect(() => {
    if (postExecuteActionSuccess) {
      success()
      handleCancel()
    }

    if (postExecuteActionError) error(errorMsg)
    // eslint-disable-next-line
  }, [postExecuteActionSuccess, postExecuteActionError])

  if (!openModal || modalType !== 'execute-action') return null

  return (
    <Modal
      title={
        <div
          style={{
            textAlign: 'center'
          }}
        >
          Execute Actions
        </div>
      }
      centered
      open={openModal}
      onCancel={handleCancel}
      width={1200}
      bodyStyle={{ height: '50vh' }}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='small'
          items={renderTabItems()}
          onTabClick={onTabClick}
        />
        <Form.Item
          key='submit'
          className={styles.footerBtn}
          hidden={
            selectedTab !== SuggestedActions.ViolationStandard &&
            selectedTab !== SuggestedActionsIncreasing.AlertStandard
          }
        >
          <Button
            type='primary'
            htmlType='submit'
            disabled={
              postExecuteActionLoading ||
              (isEmptyData() && isEmptyDataIncreasing())
            }
          >
            Execute Actions
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ExecuteActionModal
