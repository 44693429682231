/** tanstack imports */
import { useMutation, useQueryClient } from '@tanstack/react-query'

/** antd imports */
import { notification } from 'antd'

/** axios imports */
import { AxiosResponse } from 'axios'

/** services imports */
import useApiService from '../../../../../hooks/useApiService'
import useWorkdayApiService from '../services/useWorkdayApiService'

/** zustand store imports */
import useWorkDayStore from '../manager/useWorkdayStore'

/** types imports */
import WorkDayStoreCredentialsFormData from '../types/WorkDayStoreCredentialsFormData'

// =================================================================
export default function useWorkdayForm() {
  /** services */
  const { msUrl } = useApiService()
  const { workDayApi } = useWorkdayApiService()

  /** hooks */
  const queryClient = useQueryClient()

  /** zustand store */
  const { setIsEditMode } = useWorkDayStore()

  const { mutate: workdayStoreCredentialsConfigsApi, isLoading } = useMutation(
    workDayApi<WorkDayStoreCredentialsFormData>,
    {
      onSuccess: (response) => {
        setIsEditMode(false)
        notification.success({
          message: 'Successfully saved',
          placement: 'bottomRight'
        })
        queryClient.invalidateQueries(['workday'])
      },
      onError: (error: AxiosResponse) => {
        notification.error({
          message: 'Error',
          description:
            'Saving Failed - Invalid Credentials or required fields are missing',
          placement: 'bottomRight'
        })
      }
    }
  )

  const handleFormSubmit = (values: any) => {
    const dimensionValues: Record<string, string> = {}

    for (const key in values) {
      if (key.startsWith('internal_attribute_field_')) {
        const index = key.split('_')[3] // Extract the index
        const newKey = values[key] // New key is the value of the current key
        dimensionValues[newKey] = values[`dimension_value_field_${index}`] // Set the value from the corresponding dimension key
      }
    }

    const data: WorkDayStoreCredentialsFormData = {
      auth_details: {
        password: values.password,
        refresh_token: values.refresh_token,
        username: values.username
      },
      params: {
        rulesets: {
          accountPostingRuleSet: values.account_posting_rule_set,
          accountPostingRuleType: values.account_posting_rule_type
        },
        dimension_values: dimensionValues
      },
      url_details: {
        auth_url: values.auth_url,
        get_url: values.get_url
      }
    }

    console.log({
      values,
      data
    })

    workdayStoreCredentialsConfigsApi({
      options: {
        baseUrl: msUrl.idms,
        endpoint: '/api/api-manager-workday',
        method: 'POST'
      },
      data
    })
  }

  return { handleFormSubmit, isLoading }
}
