// ** Zustand Imports
import useAnalyticsStore from "../manager/analytics-store";

// =====================================================================
export default function useAdditionalOptions() {

    // ** Store **
    const { selectedItem, internalMonthsToShow, vendorMonthsToShow, showInternalCount, showVendorCount, showInternalNonResponsive, showVendorNonResponsive } = useAnalyticsStore()

    // =================================================================
    const getMonthsToShow = () => {
        switch (selectedItem) {
            case 'Internal':
                return internalMonthsToShow;
            case 'Vendor':
                return vendorMonthsToShow;
            // ... additional cases if needed
            default:
                return 6;  // default value
        }
    }

    const getShowCount = () => {
        switch (selectedItem) {
            case 'Internal':
                return showInternalCount;
            case 'Vendor':
                return showVendorCount;
            // ... additional cases if needed
            default:
                return 6;  // default value
        }
    }

    const getNonResponsive = () => {
        switch (selectedItem) {
            case 'Internal':
                return showInternalNonResponsive;
            case 'Vendor':
                return showVendorNonResponsive;
            // ... additional cases if needed
            default:
                return 6;  // default value
        }
    }

    return {
        getMonthsToShow,
        getShowCount,
        getNonResponsive
    }

}
