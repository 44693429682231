const Role = {
  GAPPIFY_ADMINISTRATOR: 1,
  READ_ONLY: 2,
  SYSTEM_ADMINISTRATOR: 3,
  TRANSACTION_MANAGER: 4,
  TASK_MANAGER: 5,
  QJE_MANAGER: 6,
  FUll_USER: 7,
  PUBLIC_API_USER: 8,
  CLOSE_MANAGER: 9
} as const

export default Role
