export const formatNumberWithComma = (numberString: string) => {
  // Initially convert string number to float
  const numberFloat = parseFloat(numberString)

  // Specifying options for formatting
  const options = {
    style: 'decimal', // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  // Formatting via toLocaleString
  const formattedNumber = numberFloat.toLocaleString('en-US', options)

  return formattedNumber
}
