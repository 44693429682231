import { MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import constants from '../config/constants'

type Props = {
  isReorderMode: boolean
  isLoadingUpdateOrder: boolean
  handleReorderMode: () => void
  handleCreateView: () => void
  handleSaveReoder: () => void
  handleCancelReorder: () => void
}

const { ACTION_BUTTONS } = constants

const ActionButtons = (props: Props) => {
  const {
    isReorderMode,
    isLoadingUpdateOrder,
    handleReorderMode,
    handleCreateView,
    handleSaveReoder,
    handleCancelReorder
  } = props

  return (
    <Space style={{ float: 'right', marginBottom: 20 }}>
      {isReorderMode ? (
        <>
          <Button
            type='primary'
            onClick={handleSaveReoder}
            disabled={isLoadingUpdateOrder}
            style={{ width: 80 }}
            data-testid='btn-reorder-save'
          >
            {ACTION_BUTTONS.REORDER_SAVE}
          </Button>
          <Button
            onClick={handleCancelReorder}
            disabled={isLoadingUpdateOrder}
            style={{ width: 80 }}
            data-testid='btn-reorder-cancel'
          >
            {ACTION_BUTTONS.REORDER_CANCEL}
          </Button>
        </>
      ) : (
        <>
          <Button
            icon={
              <MenuOutlined
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            }
            onClick={handleReorderMode}
            data-testid='btn-reorder'
          >
            {ACTION_BUTTONS.REORDER}
          </Button>
          <Button
            icon={
              <PlusOutlined
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            }
            type='primary'
            onClick={handleCreateView}
            data-testid='btn-create-review-center-view'
          >
            {ACTION_BUTTONS.CREATE}
          </Button>
        </>
      )}
    </Space>
  )
}

export default ActionButtons
