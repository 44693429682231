// ** Redux Imports
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

// ** Reducer Imports
import reducers from './reducers/index'

// =================================================================
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // We are ignoring since we do not really need serializable states
      serializableCheck: {
        ignoredActions: [],
        ignoredPaths: ['allQjes.selectedPeriod.period']
      },
      // https://github.com/klis87/redux-requests/issues/388#issuecomment-714470124
      // to fix error: "Invariant failed" on failed request
      immutableCheck: false
    })
})

export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
