import moment from 'moment'

export const getBackendDataType = (formSettings: any, apiName: string) => {
  return formSettings.find((e: any) => e.api_name === apiName)?.data_type
}

export const substringRemover = (ogString: string, substring: string) => {
  return ogString.replace(substring, '')
}

export const clean = (obj: any) => {
  for (let propName in obj) {
    if ([undefined, 'Invalid date'].includes(obj[propName])) {
      delete obj[propName]
    }
  }
  return obj
}

export const parseDataIntoType = (value: any, targetDataType: string) => {
  if (value) {
    switch (targetDataType) {
      case 'varchar':
        return value
      case 'datetime':
        return moment(value).format('MM/DD/YYYY')
      case 'date':
        return moment(value).format('MM/DD/YYYY HH:mm:ss')
      case 'boolean':
        return ['Yes', 'yes', true].includes(value)
      case 'int':
        if ([null, undefined].includes(value)) return null
        else return parseInt(`${value}`, 10)
      case 'decimal':
        if ([null, undefined].includes(value)) return null
        else return parseFloat(`${value.toString().replace(/,/g, '')}`)
      default:
        return value
    }
  }
  return value
}
