import { FC } from 'react'
import getBrowserDetails from '../utils/getBrowserDetails'
import getDateAndTime from '../utils/getDateAndTime'

interface OktaErrorProps {
  error: string | null
  errorDescription: string | null
}

const OktaError: FC<OktaErrorProps> = ({
  error,
  errorDescription
}: OktaErrorProps): JSX.Element => {
  return (
    <div
      className='main-error-page'
      data-testid='okta-error-panel'
      data-cy='okta-error-panel'
    >
      <img
        src='./assets/images/gappify-logo-black.svg'
        alt='gpfy-logo'
        style={{
          marginBottom: '50px'
        }}
        data-testid='okta-error-gappify-logo'
        data-cy='okta-error-gappify-logo'
      />

      <h1
        className='error-title'
        data-testid='okta-error-title'
        data-cy='okta-error-title'
      >
        Whoops! <br />
        We encountered an error :(
      </h1>
      {errorDescription && (
        <h2 data-testid='okta-error-message' data-cy='okta-error-message'>
          {errorDescription}
        </h2>
      )}
      <section
        data-testid='okta-error-details-section'
        data-cy='okta-error-details-section'
      >
        <span
          data-testid='okta-error-status-code'
          data-cy='okta-error-status-code'
        >
          Status code: 403
        </span>
        <br />
        {error && (
          <>
            <span
              data-testid='okta-error-description'
              data-cy='okta-error-description'
            >
              Description: {error}
            </span>
            <br />
          </>
        )}
        <span data-testid='okta-error-date' data-cy='okta-error-date'>
          Date: {getDateAndTime()}
        </span>
        <br />
        <span
          data-testid='okta-error-user-agent'
          data-cy='okta-error-user-agent'
        >
          User agent: {getBrowserDetails()}
        </span>
      </section>
    </div>
  )
}

export default OktaError
