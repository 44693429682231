// ** AntD Imports
import { Skeleton, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'

// ** Constants/Types Imports
import { CELL_COLOR } from '../../constants/CellColor'
import { TableDataType } from '../../types/SpendComplianceType'

// ** Custom Component Imports
import TableTag from '../../../../components/TableTag'

// ** Hooks Imports
import useAnalytics from '../../hooks/useAnalytics'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
const formatDataSource = (
  data: TableDataType[],
  costCuttingTabView: string
) => {
  let preFinalData: any[] = []

  const formatPeriodData = (value: TableDataType, index: number | string) => {
    let periodData = {}
    let currentPeriodData: string[] | undefined
    let key0: string | undefined, key1: string | undefined

    if (costCuttingTabView === 'department') {
      key0 = value.subsidiary_name
      key1 = value.department_name
      currentPeriodData = value.department_data
    } else if (costCuttingTabView === 'employee') {
      key0 = value.department_name
      key1 = value.employee_name
      currentPeriodData = value.employee_data
    }

    currentPeriodData?.forEach((periodValue, periodIndex) => {
      periodData = { ...periodData, ...{ [periodIndex + 2]: periodValue } }
    })

    return {
      key: index,
      0: key0 || '-',
      1: key1 || '-',
      ...periodData
    }
  }

  /* 
    Format period data
    
    NOTE: 
      This condition is a workaround because API return format varies depending on the current page
      IF page = 1, data key is an Array
      ELSE, data key is an Object
  */
  if (data.length !== undefined) {
    preFinalData = data.map((value, index) => {
      return formatPeriodData(value, index)
    })
  } else {
    preFinalData = Object.entries(data).map(([index, value]) => {
      return formatPeriodData(value, index)
    })
  }

  // Format data with row span
  let currentSubsidiary = ''
  const finalData = preFinalData.map((value) => {
    if (currentSubsidiary !== value['0']) {
      currentSubsidiary = value['0']
      return {
        ...value,
        rowSpan: preFinalData.filter((obj) => obj['0'] === value['0']).length
      }
    }

    return {
      ...value,
      rowSpan: 0
    }
  })

  return finalData
}

const SpendComplianceTable = () => {
  // ** Zustand **
  const {
    setPage,
    setSortSpendDepartments,
    setSortSpendEmployees,
    costCuttingTabView,
    sortSpendDepartments,
    sortSpendEmployees
  } = useAnalyticsStore()

  // ** Hooks **
  const { getTableSpendCompliance } = useAnalytics({
    responseType: undefined
  })
  const { isLoading, isRefetching, data } = getTableSpendCompliance

  // ###########################################################
  // START: Table props ----------------------------------------

  const tablePagination = {
    pageSize: data?.data?.per_page,
    total: data?.data?.total,
    showSizeChanger: false,
    current: data?.data?.current_page,
    defaultCurrent: 1,
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]}-${range[1]} of ${total}`
  }

  const tableColumns: ColumnType<any>[] = data?.header?.map(
    (value: string, index: number) => {
      const sortSplit =
        costCuttingTabView === 'department'
          ? sortSpendDepartments.split(':')
          : sortSpendEmployees.split(':')
      const sortColumn = Number(sortSplit[0])
      const sortOrder = `${sortSplit[1]}end`

      const columnIndex = index
      let columnWidth: number
      if (
        value === 'Subsidiary' ||
        value === 'Department' ||
        value === 'Employee'
      )
        columnWidth = 200
      else columnWidth = 140

      return {
        title: value,
        key: index,
        dataIndex: String(index),
        sorter: true,
        sortOrder: index === sortColumn && sortOrder,
        width: columnWidth,
        onCell: (record: any) => {
          // Subsidiary row span prop
          if (index === 0) return { rowSpan: record.rowSpan }

          // Default empty prop
          return {}
        },
        render: (text: string, record: any, index: number) => {
          // Table color coding logic
          if (columnIndex >= 2) {
            let thisCellColorBg = ''
            let thisCellColorText = ''
            let percentage =
              record && Number(record[columnIndex].replace('%', ''))

            if (percentage >= 95) {
              thisCellColorBg = CELL_COLOR.GREEN.BACKGROUND
              thisCellColorText = CELL_COLOR.GREEN.TEXT
            } else if (percentage >= 90 && percentage <= 94) {
              thisCellColorBg = CELL_COLOR.LIGHT_GREEN.BACKGROUND
              thisCellColorText = CELL_COLOR.LIGHT_GREEN.TEXT
            } else if (percentage >= 85 && percentage <= 89) {
              thisCellColorBg = CELL_COLOR.YELLOW.BACKGROUND
              thisCellColorText = CELL_COLOR.YELLOW.TEXT
            } else {
              thisCellColorBg = CELL_COLOR.RED.BACKGROUND
              thisCellColorText = CELL_COLOR.RED.TEXT
            }

            return (
              <TableTag
                color={thisCellColorText}
                background={thisCellColorBg}
                name={text}
              />
            )
          }

          // Default cell if no color coding is needed
          return <p style={{ marginTop: 5, marginBottom: 5 }}>{text}</p>
        }
      }
    }
  )

  // END: Table props ------------------------------------------
  // ###########################################################

  // Temporary workaround to prevent re-rendering if 3rd tab is selected
  if (costCuttingTabView === 'action') return <></>

  return (
    <div className='data-table' style={{ marginTop: 0 }}>
      <Skeleton
        data-testid='skeleton-analytics'
        active
        loading={isLoading || isRefetching}
        title={{ width: '100%' }}
        paragraph={{ rows: 15 }}
        style={{ padding: 20 }}
      >
        <Table
          dataSource={formatDataSource(
            data?.data?.data || [],
            costCuttingTabView
          )}
          columns={tableColumns}
          pagination={tablePagination}
          onChange={(pagination, filters, sorter: any) => {
            // Pagination change handler
            setPage(pagination.current || 1)

            // ** Normal Sorting **
            if (sorter.field !== undefined) {
              const column = sorter.field.toString()
              const order = sorter.order === 'ascend' ? 'asc' : 'desc'

              if (costCuttingTabView === 'department')
                setSortSpendDepartments(`${column}:${order}`)
              else if (costCuttingTabView === 'employee')
                setSortSpendEmployees(`${column}:${order}`)
            }
          }}
          sortDirections={['ascend', 'descend', 'ascend']}
          loading={isLoading || isRefetching}
          className='table'
          scroll={{ x: 'max-content', y: 680 }}
        />
      </Skeleton>
    </div>
  )
}

export default SpendComplianceTable
