import { combineReducers } from 'redux'
import { loginReducer } from './loginReducer'
import { taskManagerReducer } from './taskManagerReducer'
import { qjeReducer } from './qjeReducer'
import errorReducer from './errorReducer'
import forbiddenErrorReducer from './forbiddenErrorReducer'
import LoginAuditTrailReducer from '../../pages/Settings/pages/LoginAuditTrail/redux/reducer'
import UserActivationFormReducer from '../../pages/UserActivationForm/redux/reducer'

const reducers = combineReducers({
  allLogin: loginReducer,
  allTasks: taskManagerReducer,
  allErrors: errorReducer,
  allForbiddenErrors: forbiddenErrorReducer,
  allQjes: qjeReducer,
  loginAuditTrail: LoginAuditTrailReducer,
  userActivationForm: UserActivationFormReducer
})

export default reducers