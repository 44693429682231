import { FC } from 'react'
import { Dropdown } from 'antd'
import GDropdownProps from '../../types/GDropdownProps'

const GDropdown: FC<GDropdownProps> = ({
  align,
  arrow,
  autoFocus,
  children,
  className,
  disabled,
  destroyPopupOnHide,
  forceRender,
  getPopupContainer,
  mouseEnterDelay,
  mouseLeaveDelay,
  onVisibleChange,
  openClassName,
  overlay,
  overlayClassName,
  overlayStyle,
  placement,
  prefixCls,
  transitionName,
  trigger,
  visible
}: GDropdownProps): JSX.Element => {
  return (
    <Dropdown
      align={align}
      arrow={arrow}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      destroyPopupOnHide={destroyPopupOnHide}
      forceRender={forceRender}
      getPopupContainer={getPopupContainer}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      onOpenChange={onVisibleChange}
      openClassName={openClassName}
      overlay={overlay}
      overlayClassName={overlayClassName}
      overlayStyle={overlayStyle}
      placement={placement}
      prefixCls={prefixCls}
      transitionName={transitionName}
      trigger={trigger}
      open={visible}
    >
      {children}
    </Dropdown>
  )
}

GDropdown.defaultProps = {
  arrow: false,
  autoFocus: false,
  destroyPopupOnHide: false,
  getPopupContainer: () => document.body,
  placement: 'bottomLeft',
  trigger: ['click']
}

export default GDropdown
