// ** Antd imports
import { Modal } from 'antd'

// ** React imports
import { SetStateAction } from 'react'
import useResendActivationLink from '../hooks/useResendActivationLink'
import ResponseData from '../types/ResponseData'

interface ResendActivationLinkModalProps {
  visible: boolean
  id?: number
  email: string
  name: string
  setIsModalVisible: (value: SetStateAction<boolean>) => void
  afterClose?: () => void
  onSubmitFinish: () => void
}

const ResendActivationLinkModal = ({
  visible,
  id,
  email,
  name,
  setIsModalVisible,
  afterClose,
  onSubmitFinish
}: ResendActivationLinkModalProps): JSX.Element => {
  const { resend } = useResendActivationLink()

  const handleModalOnOk = async () => {
    const response: ResponseData = await resend(id!)

    if (response.success) {
      onSubmitFinish()
    } else {
      setIsModalVisible(false)
    }
  }

  return (
    <Modal
      destroyOnClose
      width={700}
      title='Resend Activation'
      open={visible}
      okText='Resend'
      afterClose={afterClose}
      onCancel={() => setIsModalVisible(false)}
      onOk={handleModalOnOk}
      closable={false}
      data-testid='resend-activation-modal'
      data-cy='resend-activation-modal'
    >
      <p>
        You are about to resend activation link to {name} ({email}) from this
        account.
      </p>
      <p>Are you sure you want to resend activation link to this user?</p>
    </Modal>
  )
}

export default ResendActivationLinkModal
