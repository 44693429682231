import MsUrl from '../../../types/MsUrl'

const Dev: MsUrl = {
  idms: 'https://idms.dev.gappifyinc.com',
  txn_manager: 'https://tm.dev.gappifyinc.com',
  review_center: 'https://rc.dev.gappifyinc.com/',
  qje_manager: 'https://qje.dev.gappifyinc.com',
  taskmanager: 'https://tsm.dev.gappifyinc.com',
  history_logs: 'https://history.dev.gappifyinc.com',
  analytics: 'https://advanced-analytics.dev.gappifyinc.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.dev.gappifyinc.com',
  schedule_manager_env: 'dev',
  search: 'https://search.dev.gappifyinc.com',
  period_manager: 'https://period-manager.dev.gappifyinc.com',
  settings: 'https://settings.dev.gappifyinc.com',
  gappify_api: 'https://api.dev.gappifyinc.com'
} as const

export default Dev
