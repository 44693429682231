import { useQuery } from '@tanstack/react-query'
import useApiService from '../../../hooks/useApiService'
import useAuthService from '../../../services/useAuthService'
import LoginResponseDataProps from '../types/LoginResponseDataProps'

export default function useLoginSSO(uid: string | null) {
  const { authAPI } = useAuthService()
  const { msUrl } = useApiService()

  return useQuery<LoginResponseDataProps>({
    queryKey: ['login-sso'],
    queryFn: () =>
      authAPI({
        params: {
          uid
        },
        options: {
          baseURL: msUrl.idms,
          endpoint: '/api/login/sso',
          method: 'GET'
        }
      }),
    enabled: false
  })
}
