import { useEffect, useState } from 'react'

// ** AntD Imports
import { Col, Row, Skeleton, Tooltip, Typography } from 'antd'
import { DownOutlined, ExportOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import GButton from '../../../../components/gappify/GButton'
import GDropdownBtn from '../../../../components/gappify/GDropdownBtn'
import GIcon from '../../../../components/gappify/GIcon'
import GlobalFilterModal from '../Modals/GlobalFilterModal'

// ** Helper Imports
import capitalize from '../../helper/Capitalize'
import { formatDateForDisplay } from '../../helper/formatDate'

// ** Hook Imports
import useAnalytics from '../../hooks/useAnalytics'
import useDropdown from '../../hooks/useDropdown'

// ** Style Imports
import styles from '../../styles/dropdown.module.css'

// ** Third Party Imports
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'
import useGFStore from '../Modals/GlobalFilterModal/store/GlobalFilterStore'

// ** Type Imports
import { EModalActions } from '../Modals/GlobalFilterModal/types/global-filter-types'

// =================================================================
const AnalyticsDropdown = ({
  showTitle = true,
  data
}: {
  showTitle?: boolean
  data?: any
}) => {
  // ** States **
  const [periodData, setPeriodData] = useState<{} | null>()

  // ** Zustand **
  const {
    showDatePicker,
    setShowDatePicker,
    title,
    period,
    responseType,
    header
  } = useAnalyticsStore()

  // ** Zustand - Global Filtering Store **
  const activeFilter = useGFStore((state) => state.activeFilter)
  const isPreviewMode = useGFStore((state) => state.isPreviewMode)
  const setOpenFilterModal = useGFStore((state) => state.setOpenFilterModal)
  const setFilterModalAction = useGFStore((state) => state.setFilterModalAction)

  // ** Hooks **
  const {
    getPeriodMenu,
    getFilterMenu,
    getExportMenu,
    handlePeriodClick,
    handleExportClick
  } = useDropdown()

  // ** React Query **
  const { getTableSpendCompliance, getTableIncreasingSpend } = useAnalytics({
    responseType: responseType
  })
  const { data: dataSpendCompliance } = getTableSpendCompliance
  const { data: dataIncreasingSpend } = getTableIncreasingSpend

  const getFilterDropDownLabel = (text: string) => {
    const textLength: number = text.length

    if (textLength > 9) {
      return (
        <Tooltip placement='topLeft' title={text}>
          <span>{text.replace(/^(.{9}).{2,}/, '$1…')}</span>
        </Tooltip>
      )
    }

    return text
  }

  // TODO: Needs refactoring to use single global table component
  useEffect(() => {
    if (data?.period_drop_down) {
      setPeriodData(Object.values(data?.period_drop_down)[0])
    } else if (dataSpendCompliance?.period_drop_down) {
      setPeriodData(Object.values(dataSpendCompliance?.period_drop_down)[0])
    } else if (dataIncreasingSpend?.period_drop_down) {
      setPeriodData(Object.values(dataIncreasingSpend?.period_drop_down)[0])
    } else {
      setPeriodData(null)
    }
  }, [data, dataSpendCompliance, dataIncreasingSpend])

  return (
    <>
      <Row className={styles.container}>
        <Col span={8}>
          <div className={styles.wrap} data-testid='period-filter-dropdown'>
            {!periodData ? (
              <>
                <Skeleton.Button
                  active
                  size='large'
                  shape='round'
                  block={false}
                  style={{ width: 200, height: 32 }}
                />
                <Skeleton.Button
                  active
                  size='large'
                  shape='round'
                  block={false}
                  style={{ width: 50, height: 32 }}
                />
              </>
            ) : (
              <>
                <GDropdownBtn
                  className='g-btn-default'
                  onClick={handlePeriodClick}
                  menuItems={getPeriodMenu()}
                  btnText={
                    period
                      ? formatDateForDisplay(period)
                      : (periodData as string)
                  }
                  children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
                  visible={showDatePicker}
                  onVisibleChange={setShowDatePicker}
                  loading={!periodData}
                />
                <GDropdownBtn
                  className='g-btn-default'
                  menuItems={getFilterMenu()}
                  btnText={getFilterDropDownLabel(capitalize(activeFilter))}
                  children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
                />
                {isPreviewMode ? (
                  <GButton
                    type='text'
                    className='g-btn-icon'
                    icon={<GIcon icon={faEye} />}
                    onClick={() => {
                      setOpenFilterModal(true)
                    }}
                  />
                ) : (
                  <></>
                )}
                {!isPreviewMode && activeFilter !== 'all' ? (
                  <GButton
                    type='text'
                    className='g-btn-icon'
                    icon={<GIcon icon={faPencil} />}
                    onClick={() => {
                      setOpenFilterModal(true)
                      setFilterModalAction(EModalActions.UPDATE)
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Col>

        <Col span={8}>
          <div className={styles.containerCenter}>
            {showTitle && (
              <Typography.Title data-testid='title' className={styles.title}>
                {header}: {title}
              </Typography.Title>
            )}
          </div>
        </Col>

        <Col span={8}>
          <div className={styles.containerRight}>
            {!periodData ? (
              <Skeleton.Button
                active
                size='large'
                shape='round'
                block={false}
                style={{ width: 140, height: 32 }}
              />
            ) : (
              <GDropdownBtn
                className='g-btn-default'
                onClick={handleExportClick}
                menuItems={getExportMenu()}
                btnText={`Export to`}
                btnIcon={<ExportOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
                children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
              />
            )}
          </div>
        </Col>
      </Row>
      <GlobalFilterModal />
    </>
  )
}

export default AnalyticsDropdown
