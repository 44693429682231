import ActionTypes from '../constants/action-types'

export default function setForbiddenError(params: {
  visible: boolean
  message?: string
}) {
  return {
    type: ActionTypes.SET_FORBIDDEN_ERROR,
    payload: params
  }
}
