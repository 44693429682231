import { message, Table, Tabs } from 'antd'
import { FC, useEffect, useState } from 'react'
import AccrualRule from './types/AccrualRule'
import getAccrualRulesColumns from './utils/getAccrualRulesColumns'
import loadAccrualRules from './utils/loadAccrualRules'
import ReactDragListView from 'react-drag-listview'
import { reorderAccrualRules } from '../../../../services/TaskManagerApi'
import AccrualRulesHeader from './components/AccrualRulesHeader'
import { useNavigate } from 'react-router-dom'
import AutomationRulesModal from '../AccrualRules/components/AutomationRulesModal'
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'

const AccrualRules: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const [dataSource, setDataSource] = useState<AccrualRule[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isReorderMode, setIsReorderMode] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [isEditRules, setIsEditRules] = useState(true)
  const [editRecord, setEditRecord] = useState<AccrualRule | undefined>()
  const [tabsKey, setTabsKey] = useState<string>('vendor')

  const RP = useRolesAndPermissions()

  const toggleReorderMode = (value?: boolean) => {
    setIsReorderMode(value ? value : !isReorderMode)
  }

  const loadDataSource = async () => {
    await loadAccrualRules(setIsLoading, setDataSource, tabsKey)
  }

  const onDragEnd = (fromIndex: number, toIndex: number) => {
    const data = [...dataSource]
    const item = data.splice(fromIndex, 1)[0]
    data.splice(toIndex, 0, item)
    setDataSource(data)
  }

  const handleSaveReorderOnClick = async () => {
    try {
      let { data } = await reorderAccrualRules({
        row: dataSource.map((record: AccrualRule, index: number) => {
          return { id: record.id, weight: index }
        })
      })
      message.success(data.message)
      toggleReorderMode(false)
    } catch (err) {
      console.log('Error', err)
      message.error('An error occurred. Try again.')
    }
  }

  const handleCancelReorderOnClick = () => {
    loadDataSource()
    toggleReorderMode(false)
  }

  const handleOnclickTabs = (key: string) => {
    setTabsKey(key)
  }

  useEffect(() => {
    loadDataSource()
    // eslint-disable-next-line
  }, [tabsKey])

  useEffect(() => {
    if (!RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-testid='accrual-rules' className='accrual-rules-tbl'>
      <Tabs defaultActiveKey={'Vendor'} onChange={handleOnclickTabs}>
        <Tabs.TabPane tab='Vendor' key='vendor'>
          <AccrualRulesHeader
            isReorderMode={isReorderMode}
            handeSaveReorderOnClick={handleSaveReorderOnClick}
            handleCancelReorderOnClick={handleCancelReorderOnClick}
            toggleReorderMode={toggleReorderMode}
            setModalVisible={setModalVisible}
            setIsEditRules={setIsEditRules}
          />

          <ReactDragListView handleSelector='span' onDragEnd={onDragEnd}>
            <Table
              dataSource={dataSource}
              columns={getAccrualRulesColumns(
                loadDataSource,
                isReorderMode,
                setModalVisible,
                setEditRecord
              )}
              pagination={false}
              loading={isLoading}
            />
          </ReactDragListView>
        </Tabs.TabPane>
        <Tabs.TabPane tab='PO' key='po'>
          <AccrualRulesHeader
            isReorderMode={isReorderMode}
            handeSaveReorderOnClick={handleSaveReorderOnClick}
            handleCancelReorderOnClick={handleCancelReorderOnClick}
            toggleReorderMode={toggleReorderMode}
            setModalVisible={setModalVisible}
            setIsEditRules={setIsEditRules}
          />

          <ReactDragListView handleSelector='span' onDragEnd={onDragEnd}>
            <Table
              dataSource={dataSource}
              columns={getAccrualRulesColumns(
                loadDataSource,
                isReorderMode,
                setModalVisible,
                setEditRecord
              )}
              pagination={false}
              loading={isLoading}
            />
          </ReactDragListView>
        </Tabs.TabPane>
      </Tabs>
      <AutomationRulesModal
        visible={modalVisible}
        onCancel={() => {
          setEditRecord(undefined)
          setModalVisible(false)
          setIsEditRules(true)
        }}
        isEditRules={isEditRules}
        setModalVisible={setModalVisible}
        loadDataSource={loadDataSource}
        editRecordId={editRecord?.id}
        setEditRecord={setEditRecord}
        tabsKey={tabsKey}
      />
    </div>
  )
}

export default AccrualRules
