import { useState } from 'react'
import getUsers from '../services/getUsers'
import User from '../types/User'
import ReqParams from '../types/ReqParams'

export default function useGetUsers() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])

  const loadUsers = async (params?: ReqParams) => {
    setIsLoading(true)

    try {
      const response = await getUsers(params)
      const data: User[] = response.data

      const mappedUsers = data.map((item) => {
        item.key = item.id
        return item
      })
      setUsers(mappedUsers)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, users, loadUsers }
}
