import { FC } from 'react'
import { Form, Input } from 'antd'
import { Modal } from 'antd'

const SaveFilterModal: FC<any> = ({
  filterValues,
  visible,
  onClickCancel,
  columns,
  onSaveFilter
}): JSX.Element => {
  const [form] = Form.useForm()

  const handleSubmit = (values: any) => {
    const parameters: object[] = []
    if (filterValues) {
      const filterValuesToArray = Object.entries(filterValues)

      filterValuesToArray.forEach(([key, value]: any) => {
        if (value) {
          let label = getLabel(key)
          parameters.push({
            [label]: [value]
          })
        }
      })
    }

    onSaveFilter && onSaveFilter(values, parameters)
  }

  const getLabel = (api_name: string) => {
    let found = columns.find((item: any) => {
      if (item.api_name === api_name) {
        return true
      }

      return false
    })
    return found ? found.label : ''
  }

  const handleCancel = () => {
    form.resetFields()
    onClickCancel && onClickCancel()
  }

  return (
    <Modal
      title='Save Filter'
      open={visible}
      okButtonProps={{ htmlType: 'submit' }}
      onOk={form.submit}
      onCancel={handleCancel}
      data-testid='save-filter-modal'
      data-cy='save-filter-modal'
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout='vertical'
        data-testid='save-filter-modal-form'
        data-cy='save-filter-modal-form'
      >
        <Form.Item
          label={'Enter the name of your new filter'}
          name='name'
          rules={[{ required: true, message: 'Please fill out this field.' }]}
        >
          <Input
            data-testid='save-filter-modal-form-input-field'
            data-cy='save-filter-modal-form-input-field'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default SaveFilterModal
