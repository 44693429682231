// ** AntD Imports
import { Typography } from 'antd'

// =================================================================

const UserActivationHeader = () => {
  return (
    <div
      className='user-activation-header-container'
      data-testid='UserActivationHeader'
    >
      <Typography className='title'>Gappify Activation Form</Typography>
    </div>
  )
}

export default UserActivationHeader
