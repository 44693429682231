/** antd imports */
import { Col, Form, Input, Row, Select, Typography } from 'antd'

/** components imports */
import GButton from '../../../components/gappify/GButton'

/** hooks imports */
import useUploadFileModal from '../hooks/useUploadFileModal'

/** zustand store imports */
import useUploadFileModalStore from '../manager/useUploadFileModalStore'

// =================================================================
export default function ModalBody() {
  /** antd */
  const [form] = Form.useForm()

  /** hooks */
  const {
    getSourceFileList,
    handleSourceChange,
    handleSourceFileOnChange,
    getAccrualPeriod,
    handleImportedAccrualNameOnChange,
    handleImportedAccrualFileOnChange,
    downloadSampleCsv
  } = useUploadFileModal()

  /** zustand store */
  const { source } = useUploadFileModalStore()

  return (
    <>
      {source === 'imported_accruals' ? (
        <section>
          <p
            data-testid='imported-accruals-step-1'
            data-cy='imported-accruals-step-1'
          >
            <span>Step 1: Please use this</span>
            <GButton
              btnText='template'
              size='small'
              type='link'
              shape='default'
              onClick={downloadSampleCsv}
              dataTestId='download-template-btn'
              dataCy='download-template-btn'
            />
            <span>to setup and format data for the upload.</span>
          </p>
          <p
            data-testid='imported-accruals-step-2'
            data-cy='imported-accruals-step-2'
          >
            Step 2: Provide a name for this Imported Accruals:
            <br />
            <Input
              type='text'
              placeholder='Imported Accrual Name'
              style={{ width: '60%' }}
              maxLength={50}
              onChange={handleImportedAccrualNameOnChange}
              data-testid='imported-accrual-name-input-field'
              data-cy='imported-accrual-name-input-field'
              allowClear
              showCount
            />
            <Typography.Text
              italic
              style={{
                marginLeft: '5px'
              }}
            >
              (limit to 50 characters only)
            </Typography.Text>
          </p>
          <p
            data-testid='imported-accruals-step-3'
            data-cy='imported-accruals-step-3'
          >
            Step 3: Please note that this Imported Accruals will be for the
            period:{' '}
            <Typography.Text strong>{getAccrualPeriod()}</Typography.Text>
          </p>
          <p
            data-testid='imported-accruals-step-4'
            data-cy='imported-accruals-step-4'
          >
            Step 4: Attach the file in the option below to complete the upload.
            <br />
            <Form form={form}>
              <Form.Item name='accrualFile'>
                <Input
                  type='file'
                  accept='.csv'
                  placeholder='Upload file'
                  style={{ width: '60%' }}
                  onChange={handleImportedAccrualFileOnChange}
                  data-testid='imported-accrual-file-upload-field'
                  data-cy='imported-accrual-file-upload-field'
                />
              </Form.Item>
            </Form>
            <Typography.Text
              italic
              style={{
                marginLeft: '5px'
              }}
            >
              Only csv files are supported (max size: 50MB)
            </Typography.Text>
          </p>
        </section>
      ) : (
        <Row>
          <Col span={12} style={{ padding: 5 }}>
            <Select
              value={source}
              placeholder='Select source'
              style={{ width: '100%' }}
              size='large'
              onChange={handleSourceChange}
              data-testid='source-field'
              data-cy='source-field'
              allowClear
            >
              {getSourceFileList()}
            </Select>
          </Col>
          <Col span={12} style={{ padding: 5 }}>
            <Input
              type='file'
              accept='.csv'
              placeholder='Upload file'
              style={{ height: '40px' }}
              onChange={handleSourceFileOnChange}
              data-testid='source-file-upload-field'
              data-cy='source-file-upload-field'
            />
          </Col>
          <Typography.Text
            strong
            style={{
              paddingLeft: '5px'
            }}
          >
            Only csv files are supported (max size: 50MB)
          </Typography.Text>
        </Row>
      )}
    </>
  )
}
