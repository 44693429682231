import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'
import ReqParams from '../types/ReqParams'

export default async function getUsers(params?: ReqParams) {
  try {
    const response = await axiosIdentityManagerInstance.get('/api/users', {
      params
    })

    return response.data
  } catch (error) {
    throw error
  }
}
