import { axiosTxnManagerInstance } from '../helpers/axios'
import type TransactionManagerColumn from '../types/TransactionManagerColumn'
import type TrdActionTabParams from '../types/TrdActionTabParams'
import type TxnLinesReqParams from '../types/TxnLinesReqParams'

export const getTrdTransactions = (params?: TxnLinesReqParams) => {
  return axiosTxnManagerInstance.get(`/api/transactions/lines`, { params })
}

export const getTrdLineExport = (params?: TxnLinesReqParams) => {
  return axiosTxnManagerInstance.get(`api/transactions/consolidated-export`, {
    params
  })
}

export const deleteTransaction = (txnId: number) => {
  return axiosTxnManagerInstance.delete(`api/transactions/${txnId}`)
}

export const updateTrdTransactions = (data: any) => {
  return axiosTxnManagerInstance.post(
    `/api/transactions/lines/update-consolidated`,
    data
  )
}

export const getTxnManagerColumns = () => {
  return axiosTxnManagerInstance.get(
    `api/transactions/manager/columns/transactionColumnManager`
  )
}

export const getAddLineScreenManagerData = () => {
  return axiosTxnManagerInstance.get(
    `api/transactions/manager/columns/addLineManager`
  )
}

export const updateAddLineScreenManagerData = (
  payload: {
    id: number
    form_weight: number
    form_properties: {
      read_only: boolean
      required: boolean
      show: boolean
    }
  }[]
) => {
  return axiosTxnManagerInstance.post(
    `api/transactions/manager/columns/add-line`,
    payload
  )
}

export const updateTxnManagerColumns = (
  payload: TransactionManagerColumn[]
) => {
  return axiosTxnManagerInstance.post(
    `api/transactions/manager/columns`,
    payload
  )
}
export const getAllTrdFilters = () => {
  return axiosTxnManagerInstance.get(`api/transactions/lines/all-filters`)
}

export const getFilterFields = () => {
  return axiosTxnManagerInstance.get(`api/transactions/lines/all-filter-fields`)
}

export const getFilterDetails = (filterId: string) => {
  return axiosTxnManagerInstance.get(
    `api/transactions/lines/get-filter/${filterId}`
  )
}

export const createFilter = (params: any) => {
  return axiosTxnManagerInstance.post(
    `api/transactions/lines/create-filter`,
    params
  )
}

export const updateFilter = (filterId: string, params: any) => {
  return axiosTxnManagerInstance.put(
    `api/transactions/lines/update-filter/${filterId}`,
    params
  )
}

export const deleteFilter = (filterId: string) => {
  return axiosTxnManagerInstance.delete(
    `api/transactions/lines/delete-filter/${filterId}`
  )
}

export const getTrdConsolidatedComments = (params?: TrdActionTabParams) => {
  return axiosTxnManagerInstance.get(`api/transactions/comments-consolidated`, {
    params
  })
}

export const getTrdConsolidatedHistories = (params?: TrdActionTabParams) => {
  return axiosTxnManagerInstance.get(`api/transactions/history-consolidated`, {
    params
  })
}

export const getTrdConsolidatedAttachments = (params?: TrdActionTabParams) => {
  /**
   * ENG-8147 - Use single endpoint for fetching attachments
   * As per BE (Kiner)
   * Ref: https://gappify.atlassian.net/browse/ENG-8147
   */
  return axiosTxnManagerInstance.get(
    // Uncomment if need to rollback
    // `api/transactions/attachments-consolidated`,
    `api/attachments`,
    {
      params
    }
  )
}

/**
 * ? Txn FRD (Filter Redesign) Endpoints
 * ? Ref:
 * ? https://gappify.atlassian.net/browse/AC-211
 * ? https://gappify.atlassian.net/browse/AC-225
 */
export const frdGetAllTrdFilters = () => {
  return axiosTxnManagerInstance.get(`api/transaction-lines-filter`)
}

export const frdGetFilterDetails = (filterId: string) => {
  return axiosTxnManagerInstance.get(`api/transaction-lines-filter/${filterId}`)
}

export const frdGetFilterPreview = (filterId: string) => {
  return axiosTxnManagerInstance.get(`api/transaction-lines-filter/get-preview`)
}

export const frdCreateFilter = (params: any) => {
  return axiosTxnManagerInstance.post(`api/transaction-lines-filter`, params)
}

export const frdCreateFilterPreview = (params: any) => {
  return axiosTxnManagerInstance.post(
    `api/transaction-lines-filter/save-preview`,
    params
  )
}

export const frdUpdateFilter = (filterId: string, params: any) => {
  return axiosTxnManagerInstance.put(
    `api/transaction-lines-filter/${filterId}`,
    params
  )
}

export const frdDeleteFilter = (filterId: string) => {
  return axiosTxnManagerInstance.delete(
    `api/transaction-lines-filter/${filterId}`
  )
}

export const frdCreateFilterPreview2 = (params: any) => {
  return axiosTxnManagerInstance.post(
    `api/transactions/lines/preview-filter`,
    params
  )
}
