import { axiosIdentityManagerInstance } from '../../../../../helpers/axios';
import ExportUserCsvParams from '../types/ExportUserCsvParams';

export default async function exportUserToCsv(params?: ExportUserCsvParams) {
    try {
        const response = await axiosIdentityManagerInstance.get('/api/admin/users/download', {
            params
        })

        return response.data
    } catch (error) {
        throw error
    }
}