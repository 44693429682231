/** zustand imports */
import { create } from 'zustand'

export interface CompanySettingsStoreProps {
  /** state */
  api_enabled: boolean
  workday_enabled: boolean

  /** actions */
  setApiEnabled: (enabled: boolean) => void
  setWorkdayEnabled: (enabled: boolean) => void
}

const useCompanySettingsStore = create<CompanySettingsStoreProps>((set) => ({
  /** state */
  api_enabled: false,
  workday_enabled: false,

  /** actions */
  setApiEnabled: (enabled) => set({ api_enabled: enabled }),
  setWorkdayEnabled: (enabled) => set({ workday_enabled: enabled })
}))

export default useCompanySettingsStore
