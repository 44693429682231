// ** Type Imports
import { IRules } from '../types/table-record'
import AnalyticsSettingsProps from '../types/analytics-settings-store-types'

// ** Zustand Imports
import { create } from 'zustand'

// =================================================================
const useAnalyticsSettingsStore = create<AnalyticsSettingsProps>((set) => ({
  // Define initial state
  openModal: false,
  showHeader: true,
  originalData: [],
  stagedData: [],
  selectedRecord: null,
  errorMsg: '',
  selectedDropdown: '',

  // Define actions
  setOpenModal: (openModal: boolean) => set({ openModal }),
  setShowHeader: (showHeader: boolean) => set({ showHeader }),
  setOriginalData: (originalData: []) => set({ originalData }),
  setStagedData: (stagedData: []) => set({ stagedData }),
  setSelectedRecord: (selectedRecord: IRules | null) => set({ selectedRecord }),
  setErrorMsg: (errorMsg: string) => set({ errorMsg }),
  setSelectedDropdown: (selectedDropdown: string) => set({ selectedDropdown })
}))

export default useAnalyticsSettingsStore
