import _ from 'underscore'

interface MethodDefinitions {
  getList: () => ActiveEnum[]
  getKeyList: () => (string | boolean)[]
  getDscpFromKey: (key: boolean) => string
  getColorFromKey: (key: boolean) => string | undefined
  getBgColorFromKey: (key: boolean) => string | undefined
  getKeyValueList: () => { id: boolean; value: string }[]
}

class ActiveEnum {
  key: boolean
  dscp: string
  color?: string
  bgColor?: string

  constructor(key: boolean, dscp: string, color?: string, bgColor?: string) {
    this.key = key
    this.dscp = dscp
    this.color = color
    this.bgColor = bgColor
  }
}

const toReturn: Record<string, ActiveEnum> = {
  YES: new ActiveEnum(true, 'Yes'),
  NO: new ActiveEnum(false, 'No')
}

const methods: MethodDefinitions = {
  getList: (): ActiveEnum[] => {
    let keyList: ActiveEnum[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o)
      }
    })
    return keyList
  },

  getKeyList: (): (string | boolean)[] => {
    let keyList: (string | boolean)[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o.key)
      }
    })
    return keyList
  },
  getDscpFromKey: (key: boolean): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })
    return found ? found.dscp : ''
  },
  getColorFromKey: (key: boolean): string | undefined => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.color : ''
  },
  getBgColorFromKey: (key: boolean): string | undefined => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.bgColor : ''
  },
  getKeyValueList: (): { id: boolean; value: string }[] => {
    let keyList: { id: boolean; value: string }[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push({ id: o.key, value: o.dscp })
      }
    })
    return keyList
  }
}

const ActiveEnumObject = {
  ...toReturn,
  ...methods
}

export default Object.freeze(ActiveEnumObject)
