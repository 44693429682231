const constants = {
  TITLE: 'Review Center',
  REVIEW_CENTER_VIEWS_KEY: ['review-center-views'],
  HISTORY: {
    MODAL_TITLE: 'Audit Trail',
    BTN_LABEL: 'Audit Trail'
  },
  VIEW_SUBTAB: {
    key: 'view',
    label: 'View'
  },
  LAYOUT_SUBTAB: {
    key: 'layout',
    label: 'Layout',
    LAYOUT_KEY: ['review-center-layout']
  },
  DEFAULT_ACTIVE_TAB: 'view',
  CREATE_MODAL: {
    BTN_LABEL: 'Create New View',
    TITLE: 'Create View',
    KEY: 'create'
  },
  EDIT_MODAL: {
    TITLE: 'Edit View',
    KEY: 'edit'
  },
  DELETE_MODAL: {
    TITLE: 'Delete View',
    KEY: 'delete',
    MSG: 'Are you sure you want to delete this view? This action cannot be undone.',
    TEST_ID: 'delete-modal'
  },
  CURRENCY: {
    FUNCTIONAL: {
      key: 'Functional',
      label: 'Functional'
    },
    REPORTING: {
      key: 'Reporting',
      label: 'Reporting'
    }
  },
  ACTION_BUTTONS: {
    CREATE: 'Create New View',
    REORDER: 'Reorder',
    REORDER_SAVE: 'Save',
    REORDER_CANCEL: 'Cancel'
  }
}

export default constants
