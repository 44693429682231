// ** AntD Imports
import { FormInstance, Modal, message } from 'antd'
import useScheduleManager from '../../../hooks/useScheduleManager'

// ** Zustand Imports
import useScheduleStore from '../../../manager/schedule-store'

// =================================================================
const ScheduleManagerMonthlyModal = ({ form }: { form: FormInstance }) => {
  // ** Store **
  const {
    openModal,
    setOpenModal,
    setConfirmLoading,
    monthlyEditPayload,
    confirmLoading
    // lineIdToBeSaved
  } = useScheduleStore()

  // ** Hooks **
  const { handleSubmit } = useScheduleManager()

  const handleOk = () => {
    /**
     * ? Temporary empty payload handler
     */
    if (!monthlyEditPayload.length) {
      message.error('Please fill up all required fields.')
    } else {
      setConfirmLoading(true)
      handleSubmit(
        monthlyEditPayload,
        '/schedule-manager/schedule-monthly-update'
      )
    }

    // try {
    //   await handleSubmit(
    //     monthlyEditPayload.filter(
    //       (entry) => entry.taskScheduleId === lineIdToBeSaved
    //     ),
    //     '/schedule-manager/schedule-monthly-update'
    //   )
    //   message.success('All changes have been applied.')
    // } catch (e) {
    //   console.log('Error', e)
    //   message.error('An error occurred.')
    // } finally {
    //   setConfirmLoading(false)
    // }
  }

  // ** Commenting this out, might be useful for reference **
  // const handleSaveAll = () => {
  //   const formValues = form.getFieldsValue()
  //   // Create a copy of the data
  //   const newData = [...monthlyData]
  //   // Loop through all editing keys and update the respective rows
  //   editingKeys.forEach((key: string) => {
  //     const index = newData.findIndex((item) => item.key === key)
  //     if (index > -1) {
  //       // Convert the moment object for date to a string
  //       if (formValues[`${key}-launchDate`]) {
  //         formValues[`${key}-launchDate`] =
  //           formValues[`${key}-launchDate`].format('MMMM D, YYYY')
  //       }
  //       // Convert the moment object for time to a string
  //       if (formValues[`${key}-launchTime`]) {
  //         formValues[`${key}-launchTime`] =
  //           formValues[`${key}-launchTime`].format('h:mm A')
  //       }
  //       const rowFormValues = {
  //         launchDate: formValues[`${key}-launchDate`],
  //         launchTime: formValues[`${key}-launchTime`],
  //         active: formValues[`${key}-active`]
  //         // ... Add any other fields as necessary
  //       }
  //       const updatedRowData = {
  //         ...formValues,
  //         ...rowFormValues,
  //         key: key
  //       }
  //       newData[index] = { ...newData[index], ...updatedRowData }
  //     }
  //   })
  //   // Update the store and clear editing state
  //   setMonthlyData(newData)
  //   toggleAllEditingKeys([])
  // }

  return (
    <Modal
      destroyOnClose
      open={openModal}
      onOk={handleOk}
      onCancel={() => {
        setOpenModal(false)
      }}
      title='Are you sure you want to save the changes?'
      confirmLoading={confirmLoading}
    >
      <p>Saving the changes will update the Current Period Schedule</p>
    </Modal>
  )
}

export default ScheduleManagerMonthlyModal
