/** antd imports */
import { ButtonProps, Modal } from 'antd'

/** hooks imports */
import useToggleTokenStatus from '../hooks/useToggleTokenStatus'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'

// =================================================================
export default function ActivateTokenModal() {
  /** hooks */
  const { isLoading, handleToogle } = useToggleTokenStatus()

  /** zustand stores */
  const {
    isActivateTokenModalOpen,
    activateTokenModalMode,
    id,
    setIsActivateTokenModalOpen
  } = useApiKeyStore()

  const activateTokenModalTitle =
    activateTokenModalMode === 'activate'
      ? 'Activate Token'
      : 'Deactivate Token'

  const modalOkText =
    activateTokenModalMode === 'activate' ? 'Activate' : 'Deactivate'

  const getModalOkBtnPropsmodalBtnProps = (): ButtonProps => {
    if (activateTokenModalMode === 'activate') {
      return {
        type: 'primary',
        loading: isLoading,
        danger: false
      }
    }
    if (activateTokenModalMode === 'deactivate') {
      return {
        danger: true,
        loading: isLoading
      }
    }
    return {}
  }

  const handleModalOnOk = () => {
    handleToogle({ activate: activateTokenModalMode === 'activate' }, id!)
  }

  const getModalBody = (): JSX.Element => {
    if (activateTokenModalMode === 'deactivate') {
      return (
        <>
          <p>
            You are about to deactivate the <strong>token</strong>. This action
            will prevent from accessing the APIs.
          </p>
          <p>
            Are you sure you want to deactivate this <strong>token</strong>?
          </p>
        </>
      )
    }

    if (activateTokenModalMode === 'activate') {
      return <p>Are you sure you want to activate this token?</p>
    }

    return <></>
  }

  return (
    <Modal
      destroyOnClose
      width={700}
      title={activateTokenModalTitle}
      open={isActivateTokenModalOpen}
      okText={modalOkText}
      okButtonProps={getModalOkBtnPropsmodalBtnProps()}
      onCancel={() => setIsActivateTokenModalOpen(false)}
      closable={false}
      onOk={handleModalOnOk}
      data-testid='activate-token-modal'
      data-cy='activate-token-modal'
    >
      {getModalBody()}
    </Modal>
  )
}
