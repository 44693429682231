/** antd imports */
import { Col, Form, Input, Row } from 'antd'

/** hooks imports */
import useFetchWorkDaySettings from '../hooks/useFetchWorkDaySettings'

/** zustand store imports */
import useWorkDayStore from '../manager/useWorkdayStore'

/** styles imports */
import styles from './../styles/main.module.css'

// =================================================================
export default function QueryParametersFormItems() {
  /** hooks */
  const { data } = useFetchWorkDaySettings()

  /** zustand store */
  const { isEditMode } = useWorkDayStore()

  return (
    <>
      <h2>Query Parameters</h2>
      <Row>
        <Col span={12}>
          <Form.Item
            label='Account Posting Rule Set'
            name='account_posting_rule_set'
            rules={[{ required: true, message: 'This field is required' }]}
            className={styles.form_dimension_fields_item}
            style={{
              width: '90%'
            }}
            initialValue={data?.data.params.rulesets.accountPostingRuleSet}
          >
            <Input placeholder='Enter rule set' disabled={!isEditMode} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Account Posting Rule Type'
            name='account_posting_rule_type'
            rules={[{ required: true, message: 'This field is required' }]}
            className={styles.form_dimension_fields_item}
            style={{
              width: '90%'
            }}
            initialValue={
              data?.data.params.rulesets.accountPostingRuleType || 'SPEND'
            }
          >
            <Input placeholder='Enter rule type' disabled={!isEditMode} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
