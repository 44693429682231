// ** Hook Imports **
import useApiService from '../../../hooks/useApiService'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData | TData[]
  options: TOptions
}

// TODO: add properties
interface AccrualManagerParams {
  // TODO: add properties needed
  csv?: File
  'tenant-id'?: string
}

export interface AccrualManagerData {
  // TODO: add properties needed
}

interface AccrualManagerOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
  responseType?: string
}

// =================================================================
export default function useAccrualManagerService() {
  const { apiRequest } = useApiService()

  // TODO: replace any, if possible
  const AccrualManagerAPI = (
    props: BaseProps<AccrualManagerParams, any, AccrualManagerOptions>
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    AccrualManagerAPI
  }
}
