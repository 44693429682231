import { useState } from 'react'
import activateUser from '../services/activateUser'
import ResponseData from '../types/ResponseData'

export default function useActivateUser() {
  const [success, setSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const activate = async (userId: number) => {
    try {
      const data: ResponseData = await activateUser(userId)
      setMessage(data.message)
      setSuccess(data.success)
    } catch (error: any) {
      const data: ResponseData = error.response.data
      setMessage(data.message)
      setSuccess(data.success)
    }
  }

  return { success, message, activate }
}
