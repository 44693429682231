// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'
import Period from '../types/Period'

// ** Type Imports
interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData | TData[]
  options: TOptions
}

interface PeriodManagerParams {
  year?: string | null | undefined
}

interface PeriodManagerOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function usePeriodManagerService() {
  const { apiRequest } = useApiService()

  const PeriodManagerAPI = (
    props: BaseProps<PeriodManagerParams, Period, PeriodManagerOptions>
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    PeriodManagerAPI
  }
}
