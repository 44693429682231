import moment from 'moment'
import Period from '../types/Period'

// ** This will group the data by year **
const groupByYear = (data: Period[]) => {
  const grouped: Record<string, any> = {}
  data &&
    data.forEach((item) => {
      const year = item.period!.toString().split('-')[0]
      if (!grouped[year]) {
        grouped[year] = {
          id: item.id || Math.floor(Math.random() * 10000), // random id generation
          period: year,
          children: []
        }
      }
      grouped[year].children.push({
        ...item,
        period: moment(item.period).format('MMMM YYYY')
      })
    })

  return grouped
}

export { groupByYear }
