import { FC, SetStateAction, useEffect, useState, useRef } from 'react'
import { CheckOutlined, HistoryOutlined } from '@ant-design/icons'
import GDynamicTable from '../components/gappify/GDynamicTable'
import { Card, message } from 'antd'
import {
  mergeSimilarRows,
  settingsToGridColumns,
  fetchJEs,
  SortInfo,
  maxRecordsPerPage
} from '../utils/qje/qje-utils'
import moment from 'moment'
import CreateNewFilterModal from '../components/qje/CreateNewFilterModal'
import QjeRowDropDownButtons from '../components/qje/QjeRowDropDownButtons'
import UpdateFilterModal from '../components/qje/UpdateFilterModal'
import {
  qjeFiltersFetch,
  getQjeLogs,
  qjeExport,
  qjeSettings
} from '../services/QjeManagerApi'
import QjeHistoryModal from '../components/qje/QjeHistoryModal'
import PostJEModal from '../components/qje/PostJEModal'
import download from 'downloadjs'

import type { AxiosError } from 'axios'
import RejectJEModal from '../components/qje/RejectJEModal'
// import Pagination from 'antd/es/pagination'

import { useDispatch, useSelector } from 'react-redux'
import {
  // setSelectedPeriod,
  // setSelectedFilter,
  setQjeData,
  setSelectedForBulkUpdate,
  setIsOnEditReviewed,
  setReviewedEditKey,
  setSelectedLineGroups
} from '../context/actions/qjeAction'
import QjePeriodAndFilterDropdown from '../components/qje/QjePeriodAndFilterDropdown'
import HeaderLineGroupCheckbox from '../components/qje/HeaderLineGroupCheckbox'
import LineGroupCheckbox from '../components/qje/LineGroupCheckbox'
import QjeReviewedBulkUpdateComponent from '../components/qje/QjeReviewedBulkUpdateComponent'
import QjePager from '../components/qje/QjePager'
import {
  getDateFormat,
  getMomentTimeFormat,
  getDateTimeMomentFormat
} from '../utils/Date'

import { getGlobalSettings } from '../services/TxnManagerAPI'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'

// ** Custom Types Imports
import QjeTransactionProps from '../types/QjeTransactionsProps'

const QjeReviewJe: FC = (): JSX.Element => {
  // ** Zustand Store
  // useSelector - for accessing
  const reviewJeData = useSelector((state: any) => state.allQjes.qjeData)
  const selectedPeriod = useSelector(
    (state: any) => state.allQjes.selectedPeriod
  )
  const selectedFilter = useSelector(
    (state: any) => state.allQjes.selectedFilter
  )

  // useDispatch - for updating the global state
  const dispatch = useDispatch()

  // refs...
  const selectedPeriodRef = useRef(selectedPeriod)
  const selectedFilterRef = useRef(selectedFilter)
  const selectedLineGroupsRef = useRef([])

  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<SetStateAction<any>>(false)
  const [isShowCreateNewFilterModal, setIsShowCreateNewFilterModal] =
    useState(false)
  // const [activePeriod, setActivePeriod] = useState<
  //   { period: string } | undefined
  // >(getDefaultPeriod())
  // const [activeFilter, setActiveFilter] = useState<string>('all')
  const [customFilters, setCustomFilters] = useState<any>({})
  const [isShowUpdateFilterModal, setIsShowUpdateFilterModal] = useState(false)
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [historyData, setHistoryData] = useState({})
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  // eslint-disable-next-line
  const [checkboxCount, setCheckboxCount] = useState<number>(0)
  const [isPostJEModalVisible, setIsPostJEModalVisible] =
    useState<boolean>(false)
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)
  const [previewTriggeredFrom, setPreviewTriggeredFrom] = useState<any>('')
  const [settings, setSettings] = useState<any>()
  const [loadingSettings, setLoadingSettings] = useState<boolean>(true)
  const [dataPreviewed, setDataPreviewed] = useState<any>([])

  const [totalDataSize, setTotalDataSize] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [paginationPageSize, setPaginationPageSize] = useState<number>(500)
  //const [disablePagination, setDisablePagination] = useState<boolean>(false)
  const [sortInfo, setSortInfo] = useState<SortInfo>({
    field: 'created_at',
    order: 'ascend'
  })
  const [isRejectJEModalVisible, setIsRejectJEModalVisible] =
    useState<boolean>(false)

  const [isTrdFlagEnabled, setIsTrdEnabled] = useState<boolean>(false)
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false)

  const RP = useRolesAndPermissions()

  const handleOnClickPeriod = (item: any) => {
    const newPeriod = {
      period: moment(item.key, 'YYYY-MM-DD').format('YYYY-MM-DD')
    }

    // setActivePeriod(newPeriod)
    // setSelectedRows([])
    // dispatch(setSelectedPeriod(newPeriod))

    dispatch(setIsOnEditReviewed([]))
    dispatch(setSelectedForBulkUpdate([]))
    dispatch(setReviewedEditKey(''))
    dispatch(setSelectedLineGroups([]))

    selectedPeriodRef.current = newPeriod
    reloadData()
  }

  const getTrdFlag = async () =>
    await getGlobalSettings().then((res) => {
      const { data } = res
      const txnTab = data['transaction.feature_flags']
      return txnTab.enable_new_transactions
    })

  useEffect(() => {
    getTrdFlag().then((res: boolean) => setIsTrdEnabled(res))
  }, [])

  const getQjeColumns = (): any[] => {
    let columns: any = [
      {
        title: () => (
          <HeaderLineGroupCheckbox
            selectableLineGroups={reviewJeData
              .filter((data: any) => {
                return data.g_status === 'Unposted'
              })
              .map((data: any) => {
                return data.line_group
              })}
            selectedLineGroupsRef={selectedLineGroupsRef}
            selectableStatus={['Unposted']}
          />
        ),
        width: 50,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div className='qje-column'>
                {row.g_status === 'Unposted' ? (
                  <LineGroupCheckbox
                    index={index}
                    row={row}
                    selectedLineGroupsRef={selectedLineGroupsRef}
                  />
                ) : (
                  <span
                    data-testid={`qje-review-je-checkbox-linegroup-${index}`}
                    data-cy={`qje-review-je-checkbox-linegroup-${index}`}
                  />
                )}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }
          return obj
        }
      },
      {
        id: 9,
        api_name: 'has_history',
        width: 45,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                style={{ cursor: 'pointer', color: '#725bb4' }}
                onClick={() => handleOnClickHistory(row)}
                className='qje-column'
                data-testid={`qje-review-je-history-btn-${index}`}
                data-cy={`qje-review-je-history-btn-${index}`}
              >
                {row.has_history && <HistoryOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }
          return obj
        }
      }
    ]

    // qje_column.forEach((item: any) => {
    //   columns.push(item)
    // })

    if (settings && Object.keys(settings).length > 0) {
      settingsToGridColumns(settings, isTrdFlagEnabled).forEach((item: any) => {
        const apiName = item.api_name ?? 'no-api-name'
        let column = { ...item, is_sortable: true }

        if (item.data_type === 'decimal') {
          column.render = (input: any, row: any, index: number) => {
            // Check if input is null (NaN)
            if (input && !isNaN(input)) {
              return (
                <div
                  className='text-right'
                  data-testid={`qje-review-je-${apiName}-${index}`}
                  data-cy={`qje-review-je-${apiName}-${index}`}
                >
                  {Number(parseFloat(input).toFixed(2)).toLocaleString('en-US')}
                </div>
              )
            } else {
              return JSON.parse(input)
            }
          }
        } else if (item.api_name === 'g_reviewed') {
          column = {
            ...column,
            width: 100,
            render: (value: any, row: any, index: number) => {
              const obj: any = {
                children: (
                  <QjeReviewedBulkUpdateComponent
                    initialValue={row.g_reviewed}
                    reload={reloadData}
                    row={row}
                    index={index}
                  />
                ),
                props: {
                  rowSpan: row.rowSpan
                }
              }
              return obj
            }
          }
        } else if (item.api_name === 'g_resubmitted') {
          column = {
            ...column,
            width: 150,
            render: (value: any, row: any, index: number) => {
              const obj: any = {
                children: (
                  <div
                    className='qje-column'
                    data-testid={`qje-review-je-${apiName}-${index}`}
                    data-cy={`qje-review-je-${apiName}-${index}`}
                  >
                    {row.g_resubmitted === 1 && (
                      <div
                        style={{
                          margin: 'auto',
                          display: 'block'
                        }}
                      >
                        <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
                      </div>
                    )}
                  </div>
                ),
                props: {
                  rowSpan: row.rowSpan
                }
              }
              return obj
            }
          }
        } else if (item.api_name === 'g_is_prepaid') {
          column = {
            ...column,
            width: 150,
            render: (
              value: number,
              row: QjeTransactionProps,
              index: number
            ) => {
              const obj: any = {
                children: (
                  <div
                    className='qje-column'
                    data-testid={`qje-review-je-${apiName}-${index}`}
                    data-cy={`qje-review-je-${apiName}-${index}`}
                  >
                    {row.g_is_prepaid === 1 && (
                      <div
                        style={{
                          margin: 'auto',
                          display: 'block'
                        }}
                      >
                        <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
                      </div>
                    )}
                  </div>
                ),
                props: {
                  rowSpan: row.rowSpan
                }
              }
              return obj
            }
          }
        } else if (item.api_name === 'created_at') {
          column = {
            ...column,
            width: 150,
            render: (value: any, row: any, index: number) => {
              const obj: any = {
                children: (
                  <div
                    className='qje-column'
                    data-testid={`qje-review-je-${apiName}-${index}`}
                    data-cy={`qje-review-je-${apiName}-${index}`}
                  >
                    {getDateTimeMomentFormat(
                      value,
                      getDateFormat('m/d/Y'),
                      getMomentTimeFormat('h:i a')
                    )}
                  </div>
                )
              }
              return obj
            }
          }
        } else {
          column = {
            ...column,
            render: (value: any, row: any, index: number) => {
              const obj: any = {
                children: (
                  <div
                    className='qje-column'
                    data-testid={`qje-review-je-${apiName}-${index}`}
                    data-cy={`qje-review-je-${apiName}-${index}`}
                  >
                    {value}
                  </div>
                )
              }
              return obj
            }
          }
        }

        column.sortDirections = ['ascend', 'descend', 'ascend']
        columns.push(column)
      })
    }

    // fixed position
    columns = columns.map((column: any, index: number) => {
      if (index < 2) return { ...column, fixed: 'left' }
      else return column
    })

    return columns
  }

  const handleOnClickHistory = async (row: any) => {
    const { data } = await getQjeLogs(row.line_group)
    setHistoryData(data.log)
    setShowHistory(true)
  }

  const handleHistoryModalCancel = () => {
    setShowHistory(false)
  }

  const getFilterMenu = () => {
    return [
      ...Object.keys(customFilters)
        .filter(
          (key: any) =>
            !['new', 'all', 'unposted', 'posted', 'rejected'].includes(key)
        )
        .map((key: any) => {
          return { key: key, label: customFilters[key] }
        })
        .sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
      {
        type: 'divider'
      },
      {
        key: 'all',
        label: 'All JEs'
      },
      {
        key: 'unposted',
        label: 'Unposted JEs'
      },
      {
        key: 'posted',
        label: 'Posted JEs'
      },
      {
        key: 'rejected',
        label: 'Rejected JEs'
      },
      {
        type: 'divider'
      },
      {
        key: 'new',
        label: 'Create New Filter',
        disabled: !RP.USER_HAS_CREATE_QJE_FILTER_PERMISSION
      }
    ]
  }

  const getFilterLabel = (key: string) =>
    Object.keys(customFilters).length > 0 ? customFilters[key] : 'All JEs'

  // const handleFilterMenuOnClick = (item: MenuItemType) => {
  //   setIsOnEditMode([])
  //   setSelectedForBulkUpdate([])
  //   setEditKey('')
  //   setIsPreviewMode(false)
  //   if (item.key === 'new') {
  //     setIsShowCreateNewFilterModal(true)
  //   } else {
  //     // todo: update this
  //     // setActiveFilter(`${item.key}`)
  //   }
  // }

  const handleOnClickExport = async (item: any) => {
    setIsExportLoading(true)
    try {
      const parameters = {
        filter: selectedFilterRef.current.id,
        period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
        subtype: 'unposted',
        type: 'JE',
        sortField: sortInfo.field,
        sortDir: sortInfo.order === 'ascend' ? 'asc' : 'desc',
        recordsPerPage: maxRecordsPerPage
      }
      const { data, headers } = await qjeExport(item.key, parameters)
      const content = headers['content-type']

      if (item.key === 'excel') {
        download(data, `QJE-${selectedPeriod.period}-JE.xlsx`, content)
      } else {
        download(data, `QJE-${selectedPeriod.period}-JE.${item.key}`, content)
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsExportLoading(false)
    }
  }

  const onCancelModal = (modal: string) => {
    if (isPreviewMode) {
      setIsPreviewMode(false)
      reloadData()
    }

    if (modal === 'new') setIsShowCreateNewFilterModal(false)
    if (modal === 'update') setIsShowUpdateFilterModal(false)
  }

  const reloadData = async (override?: {
    getAll: boolean
    onlyRetrieveData: boolean
  }) => {
    let data: any[] = []
    if (!override?.onlyRetrieveData) {
      dispatch(setQjeData([]))
    }

    setIsLoading(true)
    const parameters = {
      filter: override?.getAll ? 'all' : selectedFilterRef.current.id,
      filter_fields: [],
      page: current,
      period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
      previewMode: false,
      subtype: 'unposted',
      type: 'JE',
      recordsPerPage: paginationPageSize
    }

    await fetchJEs(parameters)
      .then((res: any) => {
        if (override?.onlyRetrieveData) {
          data = res.data
        } else {
          dispatch(setQjeData(res.data))
          setTotalDataSize(parseInt(res.headers['x-total-count']))
          setIsLoading(false)
        }
      })
      .catch((err: any) => {
        message.error('An error occurred.')
        console.log(err)
      })

    return data
  }

  useEffect(() => {
    reloadData()

    // eslint-disable-next-line
  }, [selectedFilter, selectedPeriod])

  useEffect(() => {
    const loadFilters = async () => {
      try {
        const { data } = await qjeFiltersFetch()
        setCustomFilters(data)
      } catch (error) {
        console.log('Error: ', error)
      }
    }

    loadFilters()
  }, [])

  useEffect(() => {
    setLoadingSettings(true)
    const loadSettings = async () => {
      try {
        let { data } = await qjeSettings(
          'JE',
          `${selectedPeriod?.period}`,
          'unposted'
        )
        setSettings(data)
        setLoadingSettings(false)
      } catch (error) {
        console.log('Error: ', error)
        setLoadingSettings(false)
      }
    }

    loadSettings()
  }, [selectedPeriod])

  useEffect(() => {
    const unpostedCount = data.filter(
      (item) => item.g_status === 'Unposted'
    ).length
    setCheckboxCount(unpostedCount)
  }, [data])

  // Adjust pagination size display total whenever there is a change in
  // dataPreviewed and isPreviewMode
  useEffect(() => {
    setTotalDataSize(dataPreviewed.length)
  }, [dataPreviewed.length, isPreviewMode])

  const handleOnPostJEClick = () => {
    setIsPostJEModalVisible(true)
  }

  const handlePostJESuccess = () => {
    handlePostRejectJESuccess('JE Processed Successfully')
    selectedLineGroupsRef.current = []
    dispatch(setSelectedLineGroups([]))
    reloadData()
  }

  const handleRejectJESuccess = () => {
    handlePostRejectJESuccess('JE rejected successfully')
    selectedLineGroupsRef.current = []
    dispatch(setSelectedLineGroups([]))
    reloadData()
  }

  const handlePostRejectJESuccess = async (successMessage: string) => {
    try {
      setSelectedRows([])
      if (
        ['all', 'posted', 'unposted', 'rejected'].includes(selectedFilter.id)
      ) {
        // await reloadJEs(setData, setIsLoading, parameters)
        reloadData()
      }
      message.success(successMessage)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const truncateFilterLabel = (label: string) => {
    if (label.length > 15) return `${label.slice(0, 15).trim()}...`
    else return label
  }

  //handles pagination
  const handleTablePagination = (page: number, pageSize: number) => {
    setIsLoading(true)
    handleTableChange({ page: page, pageSize: pageSize }, {}, sortInfo)
  }

  // comment: used for pagination component
  const handleTableChange = async (
    page: any,
    filters: any,
    sorter: any
  ): Promise<void> => {
    let _pageSize = page.pageSize ?? paginationPageSize
    let _page = page.page ?? 1
    //setDisablePagination(true)
    setIsLoading(true)
    setCurrent(_page)
    setSortInfo(sorter)

    setPaginationPageSize(_pageSize)

    const params = {
      page: _page,
      period: moment(selectedPeriodRef.current.period).format('YYYY-MM-DD'),
      subtype: 'unposted',
      type: 'JE',
      filter_fields: [],
      filter: selectedFilterRef.current.id,
      previewMode: false,
      sortField: sorter.field,
      sortDir: sorter.order === 'ascend' ? 'asc' : 'desc',
      recordsPerPage: _pageSize
    }

    setSelectedRows([])

    try {
      const { data } = await fetchJEs(params)
      dispatch(setQjeData(data))
      setIsLoading(false)
      //setDisablePagination(false)
    } catch (error: any) {
      setIsLoading(false)
      //setDisablePagination(false)
      message.error('AN ERROR OCCURED')
      console.error(error)
    }
  }

  return (
    <>
      <div data-testid='qje-review-je' data-cy='qje-review-je'>
        <Card style={{ borderTopWidth: 0 }}>
          <div className='qje-table'>
            <GDynamicTable
              data-testid='qje-review-je-supergrid'
              data-cy='qje-review-je-supergrid'
              loading={isLoading}
              columnHeader={getQjeColumns()}
              dataSource={
                isPreviewMode
                  ? mergeSimilarRows(dataPreviewed)
                  : mergeSimilarRows(reviewJeData)
              }
              showHeader
              leftChild={
                <QjePeriodAndFilterDropdown
                  getFilterMenu={getFilterMenu}
                  truncateFilterLabel={truncateFilterLabel}
                  getFilterLabel={getFilterLabel}
                  isPreviewMode={isPreviewMode}
                  previewTriggeredFrom={previewTriggeredFrom}
                  setIsShowCreateNewFilterModal={setIsShowCreateNewFilterModal}
                  setIsShowUpdateFilterModal={setIsShowUpdateFilterModal}
                  reloadData={reloadData}
                  // refs...
                  selectedFilterRef={selectedFilterRef}
                  selectedPeriodRef={selectedPeriodRef}
                />
              }
              rightChild={
                <QjeRowDropDownButtons
                  tab='review-je'
                  hasSelectedJE={selectedRows.length > 0}
                  onPostJEClick={handleOnPostJEClick}
                  onRejectJEClick={() => setIsRejectJEModalVisible(true)}
                  selectedLineGroupsRef={selectedLineGroupsRef}
                />
              }
              hasGlobalFilter
              onClickPeriod={handleOnClickPeriod}
              hasExportBtn
              hasExportPermission={RP.USER_HAS_EXPORT_QJE_PERMISSION}
              onClickExport={handleOnClickExport}
              onChange={handleTableChange}
              pagination={false}
              isServerSideSort={true}
              isExportLoading={isExportLoading}
              showTxnFilter
            />
            <div className='qje-pagination'>
              <QjePager
                defaultPageSize={paginationPageSize}
                current={current}
                total={totalDataSize}
                onChange={handleTablePagination}
                pageSize={paginationPageSize}
                isPo={false}
              />
            </div>
          </div>
        </Card>
      </div>
      <CreateNewFilterModal
        showModal={isShowCreateNewFilterModal}
        onCancel={() => onCancelModal('new')}
        setIsShowModal={setIsShowCreateNewFilterModal}
        setCustomFilters={setCustomFilters}
        setIsLoading={setIsLoading}
        period={selectedPeriod}
        setIsPreviewMode={setIsPreviewMode}
        setPreviewTriggeredFrom={setPreviewTriggeredFrom}
        settings={settings}
        loadingSettings={loadingSettings}
        setDataPreviewed={setDataPreviewed}
        getFilterLabel={getFilterLabel}
        reloadData={reloadData}
        // refs...
        selectedFilterRef={selectedFilterRef}
        selectedPeriodRef={selectedPeriodRef}
      />
      <UpdateFilterModal
        showModal={isShowUpdateFilterModal}
        onCancel={() => onCancelModal('update')}
        setIsShowModal={setIsShowUpdateFilterModal}
        setCustomFilters={setCustomFilters}
        // setActiveFilter={setActiveFilter}
        // activeFilter={activeFilter}
        setIsPreviewMode={setIsPreviewMode}
        data={data}
        setPreviewTriggeredFrom={setPreviewTriggeredFrom}
        settings={settings}
        loadingSettings={loadingSettings}
        setDataPreviewed={setDataPreviewed}
        setIsLoading={setIsLoading}
        getFilterLabel={getFilterLabel}
        reloadData={reloadData}
        // refs...
        selectedFilterRef={selectedFilterRef}
        selectedPeriodRef={selectedPeriodRef}
        isPreviewMode={isPreviewMode}
      />
      <QjeHistoryModal
        showModal={showHistory}
        data={historyData!}
        onCancel={handleHistoryModalCancel}
        settings={settings}
      />
      <PostJEModal
        visible={isPostJEModalVisible}
        setModalVisible={setIsPostJEModalVisible}
        lineGroups={selectedRows}
        onSuccess={handlePostJESuccess}
        period={selectedPeriod.period}
        selectedLineGroupsRef={selectedLineGroupsRef}
      />
      <RejectJEModal
        visible={isRejectJEModalVisible}
        setModalVisible={setIsRejectJEModalVisible}
        lineGroups={selectedRows}
        onSuccess={handleRejectJESuccess}
        selectedLineGroupsRef={selectedLineGroupsRef}
      />
      <link
        href='/assets/stylesheets/pagination.css'
        rel='stylesheet'
        type='text/css'
      />
    </>
  )
}

export default QjeReviewJe
