// ** Antd Imports
import { FormInstance } from 'antd'

// ** Custom Component Imports
import SaveButton from '../../../components/SaveButton'
import CancelButton from '../../../components/CancelButton'

// ** Styles Imports
import styles from '../../../styles/footer.module.css'

const TableFooter = ({ form }: { form: FormInstance }) => {
  // =================================================================
  return (
    <div className={styles.container}>
      <CancelButton btnKey='standard-cancel' />
      <SaveButton btnKey='standard-save' />
    </div>
  )
}

export default TableFooter
