// Antd Imports
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { Button, Form, FormInstance, Select, Space, message } from 'antd'

// Mock Imports
import ModalData from '../mock/ModalData'

type Props = {
  form: FormInstance<any>
  handleOnFinish: (values: any) => void
  isLoadingState: boolean
  availableOptions: Record<string, any>[]
  selectedDimensions: string[]
  setSelectedDimensions: (value: string[]) => void
}

const { currencies } = ModalData()

const ViewModalForm = (props: Props) => {
  const {
    form,
    handleOnFinish,
    isLoadingState,
    availableOptions,
    selectedDimensions,
    setSelectedDimensions
  } = props

  return (
    <Form
      layout='vertical'
      form={form}
      initialValues={{ dimensions: [undefined] }}
      onFinish={handleOnFinish}
      data-testid='view-modal-form'
    >
      <Form.List
        name='dimensions'
        data-testid='view-modal-form-dimensions'
        rules={[
          {
            validator: async (_, dimensions) => {
              if (!dimensions || dimensions.length < 1) {
                return Promise.reject(new Error('At least 1 required.'))
              }
            }
          }
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item required={true} key={field.key}>
                <Space>
                  {fields.length > 1 && (
                    <Button
                      type='text'
                      onClick={() => {
                        setSelectedDimensions(
                          selectedDimensions.filter(
                            (_, _index) => index !== _index
                          )
                        )
                        remove(field.name)
                      }}
                      icon={
                        <MinusCircleFilled
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        />
                      }
                      loading={isLoadingState}
                      data-testid={`view-modal-form-dimensions-delete-${index}`}
                    />
                  )}
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'This is a required field.'
                      }
                    ]}
                    noStyle
                  >
                    <Select
                      style={{ width: '275px' }}
                      onChange={() =>
                        setSelectedDimensions(form.getFieldValue('dimensions'))
                      }
                      loading={isLoadingState}
                      disabled={isLoadingState}
                      data-testid={`view-modal-form-dimensions-dropdown-${index}`}
                    >
                      {availableOptions?.map((dimension) => (
                        <Select.Option
                          key={dimension?.api_name}
                          value={dimension?.api_name}
                          disabled={selectedDimensions.includes(
                            dimension?.api_name
                          )}
                        >
                          {dimension?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {fields.length === index + 1 && index < 3 && (
                    <Button
                      onClick={() => {
                        if (
                          form.getFieldValue('dimensions')[index] === undefined
                        ) {
                          message.error(
                            'Select a value first before adding another.'
                          )
                        } else add()
                      }}
                      icon={
                        <PlusCircleFilled
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        />
                      }
                      loading={isLoadingState}
                      data-testid='view-modal-form-dimensions-add'
                    >
                      Add
                    </Button>
                  )}
                </Space>
              </Form.Item>
            ))}
          </>
        )}
      </Form.List>

      <Form.Item
        name='currency'
        label='Currency'
        data-testid='view-modal-form-currency'
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'This is a required field.'
          }
        ]}
      >
        <Select
          style={{ width: '350px' }}
          loading={isLoadingState}
          disabled={isLoadingState}
        >
          {currencies.map((currency) => (
            <Select.Option key={currency.key} value={currency.key}>
              {currency.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default ViewModalForm
