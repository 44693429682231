// ** AntD Imports **
import { Button } from 'antd'

// ** Zustand Imports **
import useScheduleStore from '../manager/schedule-store'

// ** Styles **
// import styles from '../../../styles/footer.module.css'
import styles from '../styles/footer.module.css'

const CancelButton = ({ btnKey }: { btnKey: string }) => {
  const { handleOnClickCancel } = useScheduleStore()
  return (
    <Button
      htmlType='button'
      className={styles.button}
      shape='round'
      type='default'
      onClick={() => handleOnClickCancel(btnKey)}
    >
      CANCEL
    </Button>
  )
}

export default CancelButton
