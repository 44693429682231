// ** Third party imports **
import moment, { Moment } from 'moment'

// ** Type imports **
import MonthlyDataType from '../types/MonthlyDataType'
import StandardDataType from '../types/StandardDataType'
import DatetimeUnit from '../../../../../types/DatetimeUnit'

export const convertMinutesToDaysAndTime = (
  days: number,
  hours?: number,
  minutes?: number
) => {
  if (hours && minutes) {
    return `${days} day/s at ${moment(`${hours}:${minutes}`, ['HH.mm']).format(
      'hh:mm A'
    )}`
  } else return `${days} day/s`
}

//(24*60) - (converTime.hour(0 * 60 + converTime.minute(0)

export const convertDaysAndTimeToMinutes = (
  days: number,
  time: number | Moment
) => {
  let convertDaysMinutes = days * 24 * 60
  let convertTime = moment(time)
  let convertMinutes = 0

  if (convertDaysMinutes < 0) {
    convertMinutes =
      24 * 60 - ((23 - convertTime.hour()) * 60 + (60 - convertTime.minute()))
  } else {
    convertMinutes = convertTime.hour() * 60 + convertTime.minute()
  }

  let totalConvertedMins = 0

  if (convertDaysMinutes < 0) {
    totalConvertedMins = convertDaysMinutes - convertMinutes
  } else {
    totalConvertedMins = convertDaysMinutes + convertMinutes
  }

  return totalConvertedMins
}

export const onPressEnterField = (
  // TODO: replace any
  key: string,
  row: MonthlyDataType | StandardDataType,
  e: any
) => {
  let value: number = e
  let convertTime = moment(
    `${Math.abs(row.runOffsetRemainderHours!)}:${
      row.runOffsetRemainderMinutes
    }`,
    ['HH.mm']
  )
  let totalConvertedMins = convertDaysAndTimeToMinutes(value, convertTime)
  let data = {
    runOffsetMinutes: totalConvertedMins,
    runOffsetFullDays: value
  }
  return data
}

export const onChangeTime = (
  row: MonthlyDataType | StandardDataType,
  time: Moment | null
): StandardDataType => {
  let convertTime = moment(time)
  let data = {
    runOffsetMinutes: convertDaysAndTimeToMinutes(
      row.runOffsetFullDays!,
      moment(convertTime)
    ),
    envName: row.envName,
    runOffsetRemainderHours: convertTime.hour(),
    runOffsetRemainderMinutes: convertTime.minute()
  }
  return data
}

export const getLast6MonthsData = () => {
  const currentDate = moment()
  const monthsData = []

  for (let i = 0; i < 6; i++) {
    const targetDate = currentDate.clone().subtract(i, 'months')
    const key = targetDate.format('YYYY-MM-01')
    const label = targetDate.format('MMMM YYYY')

    monthsData.push({ key, label })
  }

  return monthsData
}

export const getDatetimeDifference = (
  firstDatetime: Moment,
  secondDatetime: Moment,
  unit: DatetimeUnit
): number => {
  const firstMoment = moment(firstDatetime)
  const secondMoment = moment(secondDatetime)
  return Math.abs(secondMoment.diff(firstMoment, unit))
}

export const convertNumberToTime = (number: number): string => {
  // Calculate hours and minutes
  const hours: number = Math.floor(number / 60)
  const minutes: number = number % 60

  // Determine AM or PM based on hours
  const period: string = hours >= 12 ? 'PM' : 'AM'

  // Convert hours to 12-hour format
  const formattedHours: number = hours % 12 === 0 ? 12 : hours % 12

  // Format the time as "hh:mm AM/PM"
  const timeString: string = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`

  return timeString
}

// ** Used for standard history
export const convertNumberToCustomTime = (number: number): string => {
  // Calculate days, hours, and minutes
  let days: number = Math.floor(Math.abs(number) / (60 * 24))
  let remainingMinutes: number = number % (60 * 24)
  let hours: number = Math.floor(Math.abs(remainingMinutes) / 60)
  let minutes: number = remainingMinutes % 60

  // Determine AM or PM based on hours
  const period: string = hours >= 12 ? 'PM' : 'AM'

  // Handle negative value of day / hours
  if (number < 0) {
    days = days * -1
    hours = hours * -1
  }

  // Convert hours to 12-hour format
  const formattedHours: number = hours % 12 === 0 ? 12 : hours % 12

  // Format the time as "dd day/s hh:mm AM/PM"
  const timeString: string = `${days} day${days > 1 ? 's' : ''}, ${Math.abs(
    formattedHours
  )}:${Math.abs(minutes).toString().padStart(2, '0')} ${period}`

  return timeString
}

// ** Used for monthly history
export const customFormatDateTime = (inputDateTime: string): string => {
  const formattedDateTime = moment(inputDateTime, 'YYYY-MM-DD HH:mm:ss').format(
    'MMMM DD, YYYY hh:mm A'
  )
  return formattedDateTime
}
