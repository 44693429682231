/** antd imports */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Select } from 'antd'

/** hooks imports */
import useFetchWorkDaySettings from '../../hooks/useFetchWorkDaySettings'

/** zustand store imports */
import useDimentionFieldsStore from '../../manager/useDimentionFieldsStore'
import useWorkDayStore from '../../manager/useWorkdayStore'

/** styles imports */
import styles from '../../styles/main.module.css'

interface FormItemProps {
  index: number
  selectedDimensionValue?: string
  selectedInternalAttribute?: string
}

interface DropdownOption {
  value: string
  label: string
  disabled?: boolean
}

// =================================================================
export default function FormItem({
  index,
  selectedDimensionValue,
  selectedInternalAttribute
}: FormItemProps) {
  /** hooks */
  const { data } = useFetchWorkDaySettings()

  /** zustand store */
  const { formItems, selectedInternalAttributes, setFormItems } =
    useDimentionFieldsStore()
  const { isEditMode } = useWorkDayStore()

  const getInternalAttributeDropdownOptions = (): DropdownOption[] => {
    const coaOptionKeys = data?.data.coa_option_keys!

    const internalAttributeFields: DropdownOption[] = Object.keys(coaOptionKeys)
      .sort()
      .map((value) => {
        return {
          label: value,
          disabled: selectedInternalAttributes.includes(value),
          value
        }
      })

    return internalAttributeFields
  }

  const getDimensionValueDropdownOptions = (): DropdownOption[] => {
    const coaOptionValues = data?.data.coa_option_values!

    const uniqueSet = coaOptionValues.filter(
      (value, index, array) => array.indexOf(value) === index
    )

    const dimesionValueFields: DropdownOption[] = uniqueSet
      .sort()
      .map((item) => {
        return {
          label: item,
          value: item
        }
      })

    return dimesionValueFields
  }

  return (
    <Row>
      <Col span={11}>
        <Form.Item
          name={`internal_attribute_field_${index}`}
          rules={[{ required: true, message: 'This field is required' }]}
          className={styles.form_dimension_fields_item}
          initialValue={selectedInternalAttribute}
        >
          <Select
            placeholder='Select field'
            options={getInternalAttributeDropdownOptions()}
            allowClear
            style={{
              width: '90%'
            }}
            disabled={!isEditMode}
          />
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          name={`dimension_value_field_${index}`}
          rules={[{ required: true, message: 'This field is required' }]}
          className={styles.form_dimension_fields_item}
          initialValue={selectedDimensionValue}
        >
          <Select
            placeholder='Select posting rule set'
            options={getDimensionValueDropdownOptions()}
            allowClear
            style={{
              width: '90%'
            }}
            disabled={!isEditMode}
          />
        </Form.Item>
      </Col>
      <Col>
        <Button
          style={{ color: '#725bb4' }}
          icon={<DeleteOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
          type='text'
          onClick={() => {
            const newFormItemsList = formItems.filter(
              (item) => item.key !== index
            )
            setFormItems(newFormItemsList)
          }}
          disabled={!isEditMode}
          data-testid={`workday-remove-field-btn-${index}`}
          data-cy={`workday-remove-field-btn-${index}`}
        />
      </Col>
    </Row>
  )
}
