/** zustand imports */
import { create } from 'zustand'

/** types imports */
import SortOrder from '../types/SortOrder'

interface StoreProps {
  /** states */
  isGenerateApiKeyModalOpen: boolean
  isTokenModalOpen: boolean
  apiKey: string
  page: number
  perPage: number
  sortOrder: SortOrder
  isActivateTokenModalOpen: boolean
  id: number | null
  activateTokenModalMode: 'activate' | 'deactivate'

  /** actions */
  setIsGenerateApiKeyModalOpen: (isGenerateApiKeyModalOpen: boolean) => void
  setApiKey: (apiKey: string) => void
  setIsTokenModalOpen: (isTokenModalOpen: boolean) => void
  setPage: (page: number) => void
  setPerPage: (perPage: number) => void
  setSortOrder: (sortOrder: SortOrder) => void
  setIsActivateTokenModalOpen: (isActivateTokenModalOpen: boolean) => void
  setId: (id: number | null) => void
  setActivateTokenModalMode: (
    activateTokenModalMode: 'activate' | 'deactivate'
  ) => void
}

// =================================================================
const useApiKeyStore = create<StoreProps>((set) => ({
  /** states */
  isGenerateApiKeyModalOpen: false,
  isTokenModalOpen: false,
  isActivateTokenModalOpen: false,
  apiKey: '',
  page: 1,
  perPage: 50,
  sortOrder: 'desc',
  id: null,
  activateTokenModalMode: 'activate',

  /** actions */
  setIsGenerateApiKeyModalOpen: (isGenerateApiKeyModalOpen: boolean) =>
    set({ isGenerateApiKeyModalOpen }),
  setApiKey: (apiKey: string) => set({ apiKey }),
  setIsTokenModalOpen: (isTokenModalOpen: boolean) => set({ isTokenModalOpen }),
  setIsActivateTokenModalOpen: (isActivateTokenModalOpen: boolean) =>
    set({ isActivateTokenModalOpen }),
  setPage: (page: number) => set({ page }),
  setPerPage: (perPage: number) => set({ perPage }),
  setSortOrder: (sortOrder: SortOrder) => set({ sortOrder }),
  setId: (id: number | null) => set({ id }),
  setActivateTokenModalMode: (
    activateTokenModalMode: 'activate' | 'deactivate'
  ) => set({ activateTokenModalMode })
}))

export default useApiKeyStore
