import { useEffect, useState } from 'react'

// Custom Component Imports
import ReviewCenterTable from './components/ReviewCenterTable'

// Hooks Imports
import useFetchData from './hooks/useFetchData'

// Utils Imports
import generateDataSource from './utils/generateDataSource'
import generateColumns from './utils/generateColumns'
import PeriodDropdown from './components/PeriodDropdown'
import ViewDropdown from './components/ViewDropdown'

type TProps = {}

const ReviewCenterQ3 = (props: TProps) => {
  /**
   * TODO: Implement the following:
   * 1. Fetch data from the API - DONE
   * 2. Implement the loading/error state - DONE
   * 3. Change mock data to fetched data - DONE
   * 4. Implement the logic for the URL
   * 5. Implement number formatter
   */

  /** Local states */
  const [view, setView] = useState('')
  const [period, setPeriod] = useState('')

  /** Tanstack Query states */
  const { data, isLoading, isError } = useFetchData(view, period)

  /** Dropdown onChange handlers */
  const handleChangeView = (value: string) => {
    setView(value)
  }

  const handleChangePeriod = (value: string) => {
    setPeriod(value)
  }

  useEffect(() => {
    /**
     * TODO: Change hard coded values
     * 1. Use the fetched data from the API
     * 2. Fetch the default/active dimension
     * 3. Fetch the default/active period
     */
    setView('SUBSIDIARY_DEPARTMENT_GL_VENDOR')
    setPeriod('2024-03-01')
  }, [])

  if (isLoading) return <>Loading ...</>
  if (isError) return <>Something went wrong.</>

  return (
    <>
      <div style={{ display: 'flex', gap: 16, marginBottom: 24 }}>
        <PeriodDropdown value={period} onChange={handleChangePeriod} />
        <ViewDropdown value={view} onChange={handleChangeView} />
      </div>
      <ReviewCenterTable
        columns={generateColumns(data!.headers)}
        data={generateDataSource(data!.data)}
      />
    </>
  )
}

export default ReviewCenterQ3
