export const mergeSimilarRows = (rows: any) => {
  const pageSize = 10
  const uniqueUserIdsPerPage = new Set()
  let pageNo = 0
  const mergedData = rows.map((rowData: any, index: number) => {
    const updatedRowsData = { ...rowData }
    if (index !== 0 && index % pageSize === 0) {
      uniqueUserIdsPerPage.clear()
      pageNo += 1
    }
    if (uniqueUserIdsPerPage.has(rowData.uniquekey)) {
      updatedRowsData.rowSpan = 0
    } else {
      const occurCount = rows
        .slice(pageNo * pageSize, (pageNo + 1) * pageSize)
        .filter((data: any) => data.uniquekey === rowData.uniquekey).length
      updatedRowsData.rowSpan = Math.min(pageSize, occurCount)
      uniqueUserIdsPerPage.add(rowData.uniquekey)
    }
    return updatedRowsData
  })
  return mergedData
}

// For dataSource of GlobalHistoryModal if QJE
export const destructureQjeHistoryData = (historyData: any) => {
  let destructuredData: object[] = []
  if (historyData && Array.isArray(historyData) && historyData.length > 0) {
    historyData?.forEach((entry: any) => {
      entry?.data?.forEach((entry2: any) => {
        destructuredData = [
          ...destructuredData,
          {
            ...entry,
            ...entry2,
            uniquekey: entry.created_at,
            created_at: entry.created_at
          }
        ]
      })
    })
  }
  return mergeSimilarRows(
    destructuredData.sort((a: any, b: any) => b.created_at - a.created_at)
  )
}
