import { Col, Row } from 'antd'
import { FC, SetStateAction } from 'react'
import GButton from '../../../../../components/gappify/GButton'

// ** Zustand Store Imports
import useAccrualRulesStore from '../manager/useAccrualRulesStore'

interface AccrualRulesHeaderProps {
  isReorderMode: boolean
  handeSaveReorderOnClick: () => Promise<void>
  handleCancelReorderOnClick: () => void
  toggleReorderMode: (value?: boolean) => void
  setModalVisible: (value: SetStateAction<boolean>) => void
  setIsEditRules: (value: SetStateAction<boolean>) => void
}

const AccrualRulesHeader: FC<AccrualRulesHeaderProps> = ({
  isReorderMode,
  handeSaveReorderOnClick,
  handleCancelReorderOnClick,
  toggleReorderMode,
  setModalVisible,
  setIsEditRules
}): JSX.Element => {
  // ** Zustand Store
  const setPrepaidAccountId = useAccrualRulesStore(
    (state) => state.setPrepaidAccountId
  )

  return (
    <Row style={{ marginBottom: '10px' }}>
      <Col sm={24} lg={6} className='txn-panel-center' />
      <Col sm={24} lg={12} className='txn-panel-center'>
        {isReorderMode && (
          <>
            <GButton
              btnText='Save'
              shape='default'
              type='primary'
              onClick={handeSaveReorderOnClick}
            />
            <GButton
              btnText='Cancel'
              shape='default'
              onClick={handleCancelReorderOnClick}
            />
          </>
        )}
      </Col>
      <Col sm={24} lg={6} className='txn-panel-right'>
        {!isReorderMode && (
          <>
            <GButton
              btnText='New Rule'
              onClick={() => {
                setModalVisible(true)
                setIsEditRules(false)
                setPrepaidAccountId(null)
              }}
            />
            <GButton
              btnText='Reorder'
              onClick={() => toggleReorderMode(true)}
            />
          </>
        )}
      </Col>
    </Row>
  )
}

export default AccrualRulesHeader
