import { axiosGenericInstance } from '../helpers/axios'

export const callPostApi = (url: string, payload: object) => {
  return axiosGenericInstance.post(url, payload)
}

export const callGetApi = (url: string, payload?: object) => {
  return axiosGenericInstance.get(url, payload)
}

export const callDeleteApi = (url: string, payload: object) => {
  return axiosGenericInstance.delete(url, payload)
}

export const callPutApi = (url: string, payload?: object) => {
  return axiosGenericInstance.put(url, payload).catch((err) => {
    console.log(err)
  })
}

export const getApiNoPayload = (url: string) => {
  return axiosGenericInstance.get(url).catch((err) => {
    console.log(err)
  })
}

export const calldownloadFile = (url: string) => {
  return axiosGenericInstance.get(url, { responseType: 'arraybuffer' })
}
