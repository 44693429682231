import { useMutation, useQueryClient } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import { message } from 'antd'
import { ApiViewData } from '../types/api'
import constants from '../config/constants'

type Response = {
  message: string
}

type Error = {
  data: { error: Record<keyof ApiViewData, string[]> }
}

type Params = {
  data: ReorderItem[]
}

type ReorderItem = {
  view_id: number
  weight: number
}

const useReorderView = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()
  const queryClient = useQueryClient()

  return useMutation<Response, Error, Params>({
    mutationFn: ({ data }) =>
      globalApi({
        options: {
          baseUrl: msUrl.review_center,
          endpoint: `/api/review-center/admin/view/reorder`,
          method: 'PATCH'
        },
        data: { data }
      }),
    onSuccess: (data) => {
      message.success(data.message)
      queryClient.invalidateQueries({
        queryKey: constants.REVIEW_CENTER_VIEWS_KEY
      })
    },
    onError: (err) => {
      const errorObj = err.data.error
      const firstKey = Object.keys(errorObj)[0] as keyof ApiViewData
      const errorMsg = errorObj[firstKey]
      message.error(errorMsg)
    }
  })
}

export default useReorderView
