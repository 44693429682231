// ** AntD Imports
import { Form } from 'antd'

// ** Content Imports
import StandardTable from './components/Table'
import TableHeader from './components/TableHeader'
import TableFooter from './components/TableFooter'
import ConfirmEditModal from './components/ConfirmEditModal'

// ** Style Imports
import styles from '../../styles/tabs-container-styles.module.css'

// ** Hook Imports
import useScheduleStore from '../../manager/schedule-store'
import useScheduleManager from '../../hooks/useScheduleManager'

// =================================================================
const Standard = () => {
  useScheduleManager()

  // ** AntD **
  const [form] = Form.useForm()

  // ** Global store **
  const { editStandard } = useScheduleStore()

  return (
    <div className={styles.container}>
      {!editStandard && <TableHeader />}
      <StandardTable form={form} />
      {editStandard && <TableFooter form={form} />}
      <ConfirmEditModal />
    </div>
  )
}

export default Standard
