import useApiService from 'src/hooks/useApiService'
import { LogsTableRecord } from '../types/LogsTableRecord'

export interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

export interface Params {
  per_page?: number
  page?: number
  [key: `sort[${string}]`]: 'asc' | 'desc'
  [key: `filter[${string}]`]: string | number
  preview_mode?: 0 | 1
  custom_filter: string | number | null
}

export interface Options {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

export interface LogsResponse {
  data: LogsTableRecord[]
  total: number
  page: number
  per_page: number
  last_page: number
}

export interface FilterOptionsResponse {
  request_id: string[]
  batch_id: string[]
  action: string[]
  object: string[]
  status: number[]
}

export interface SaveFilterPayload {
  id?: number
  name: string
  filters: {
    field: string
    values: string[] | number[]
  }[]
}

export interface SaveFilterResponse {
  id: number
  name: string
  filters: {
    field: string
    values: string[] | number[]
  }[]
  created_at: string
  updated_at: string
}

export type ExportToCsvParams = Omit<Params, 'per_page' | 'page'>

export interface ColumnHeadersResponse {
  fields: {
    key: number
    weight: number
    api_name: string
    label: string
    field_type: string
    data_type: string | null
    read_only: boolean
    required: boolean
    editable: boolean
    fixed: boolean
    hidden: boolean
    is_sortable: boolean
    options_source:
      | {
          url: string
          method: 'GET' | 'POST' | 'PUT' | 'DELETE'
        }
      | unknown[]
  }[]
}

export default function useIntegrationLogsService() {
  const { apiRequest } = useApiService()

  const fetchIntegrationLogsApi = (
    props: BaseProps<Params, void, Options>
  ): Promise<LogsResponse> =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  const fetchFilterOptionsApi = (
    props: BaseProps<void, void, Options>
  ): Promise<FilterOptionsResponse> =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  const exportLogsToCsvApi = (
    props: BaseProps<ExportToCsvParams, void, Options>
  ) =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  const fetchColumnHeadersApi = (
    props: BaseProps<void, void, Options>
  ): Promise<ColumnHeadersResponse> =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return {
    fetchIntegrationLogsApi,
    fetchFilterOptionsApi,
    exportLogsToCsvApi,
    fetchColumnHeadersApi
  }
}
