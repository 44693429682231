import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'

export default async function resendActivationLink(userId: number) {
  try {
    const response = await axiosIdentityManagerInstance.post(
      `/api/user/${userId}/activation-resend`
    )
    return response.data
  } catch (error) {
    throw error
  }
}
