/** antd imports */
import { Alert, Button, Col, Form, FormInstance, Input, Row } from 'antd'

/** hooks imports */
import useFetchWorkDaySettings from '../hooks/useFetchWorkDaySettings'

/** zustand store imports */
import useWorkdayCheckCredentials from '../hooks/useWorkdayCheckCredentials'
import useWorkDayStore from '../manager/useWorkdayStore'

/** styles imports */
import styles from './../styles/main.module.css'

/** types imports */
import WorkdayCheckCredentialsFormData from '../types/WorkdayCheckCredentialsFormData'

interface AuthorizationParametersFormItemsProps {
  form: FormInstance
}

// =================================================================
export default function AuthorizationParametersFormItems({
  form
}: AuthorizationParametersFormItemsProps) {
  /** hooks */
  const { handleWorkdayCheckCredentials, isLoading, isError, isSuccess } =
    useWorkdayCheckCredentials()
  const { data } = useFetchWorkDaySettings()

  /** zustand store */
  const { isEditMode } = useWorkDayStore()

  const handleCheckCredentials = () => {
    console.log(form.getFieldsValue())
    const values = form.getFieldsValue()

    const payload: WorkdayCheckCredentialsFormData = {
      auth_details: {
        password: values.password,
        refresh_token: values.refresh_token,
        username: values.username
      },
      url_details: {
        auth_url: values.auth_url,
        get_url: values.get_url
      }
    }

    handleWorkdayCheckCredentials(payload)
  }

  return (
    <>
      <h2>Authorization Parameters</h2>
      <Row>
        <Col span={10}>
          <div className='ant-col ant-form-item-label'>
            <label
              className='ant-form-item-required'
              title='Internal Attribute / Field'
            >
              Username
            </label>
          </div>
        </Col>
        <Col span={10}>
          <div className='ant-col ant-form-item-label'>
            <label className='ant-form-item-required' title='Dimension Value'>
              Password
            </label>
          </div>
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item
            name='username'
            rules={[{ required: true, message: 'This field is required' }]}
            className={styles.form_dimension_fields_item}
            style={{
              width: '90%'
            }}
            initialValue={data?.data.auth_details.username}
          >
            <Input placeholder='Enter username' disabled={!isEditMode} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'This field is required' }]}
            className={styles.form_dimension_fields_item}
            style={{
              width: '90%'
            }}
            initialValue={data?.data.auth_details.password}
          >
            <Input.Password
              placeholder='Enter password'
              disabled={!isEditMode}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button
            style={{
              color: '#725bb4'
            }}
            type='text'
            loading={isLoading}
            onClick={handleCheckCredentials}
            data-testid='workday-check-creds-btn'
            data-cy='workday-check-creds-btn'
            disabled={!isEditMode}
          >
            Check Credentials
          </Button>
        </Col>
      </Row>
      {isSuccess && (
        <Alert
          message='Credentials successfully matched'
          type='success'
          showIcon
          style={{
            marginBottom: '20px'
          }}
          data-testid='workday-alert-success'
          data-cy='workday-alert-success'
        />
      )}
      {isError && (
        <Alert
          message='Mismatched credentials. Please try again.'
          type='error'
          showIcon
          style={{
            marginBottom: '20px'
          }}
          data-testid='workday-alert-error'
          data-cy='workday-alert-error'
        />
      )}
      <Form.Item
        label='Refresh Token'
        name='refresh_token'
        rules={[{ required: true, message: 'This field is required' }]}
        initialValue={data?.data.auth_details.refresh_token}
      >
        <Input placeholder='Refresh Token' disabled={!isEditMode} />
      </Form.Item>
    </>
  )
}
