import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import type TrackerFields from '../../types/TrackerFields'
import { getMomentTimezoneFormat } from '../../utils/Date'

const getTracker = (item: TrackerFields, timezone: string): JSX.Element => {
  const title = item.email_state
    ? `Email Status: ${item.email_state}. ${moment(
        item.email_event_time
      ).format('MM/DD/YYYY hh:mm a')}`
    : `Email sent on ${
        timezone &&
        moment(item.created_at)
          .tz(getMomentTimezoneFormat(timezone))
          .format('MM/DD/YYYY hh:mm a')
      }`

  const trackerClassname = item.email_state ? 'tracker read' : 'tracker'

  return (
    <Tooltip placement='left' title={title}>
      <div className={trackerClassname}></div>
    </Tooltip>
  )
}

export default getTracker
