import useApiService from 'src/hooks/useApiService'
import useIntegrationLogsService from '../services/useIntegrationLogsService'
import { useQuery } from '@tanstack/react-query'
import useLogsTableStore from '../stores/useLogsTableStore'

// =================================================================
export default function useFetchIntegrationLogs() {
  /** services */
  const { fetchIntegrationLogsApi } = useIntegrationLogsService()
  const { msUrl } = useApiService()

  /** zustand states */
  const page = useLogsTableStore((state) => state.page)
  const per_page = useLogsTableStore((state) => state.per_page)
  const sort = useLogsTableStore((state) => state.sort)
  const preview_mode = useLogsTableStore((state) => state.preview_mode)
  const custom_filter = useLogsTableStore((state) => state.custom_filter)

  return useQuery({
    queryKey: [
      'integration-logs',
      page,
      per_page,
      sort,
      preview_mode,
      custom_filter
    ],
    queryFn: () =>
      fetchIntegrationLogsApi({
        options: {
          baseURL: msUrl.gappify_api,
          endpoint: '/api/logs',
          method: 'GET'
        },
        params: {
          ...sort,
          preview_mode: preview_mode ? 1 : 0,
          custom_filter,
          page,
          per_page
        }
      })
  })
}
