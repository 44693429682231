/** services imports */
import useApiService from 'src/hooks/useApiService'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface TData {
  activate: boolean
}

interface TOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function useToggleTokenStatusService() {
  const { apiRequest } = useApiService()

  const toggleTokenStatusAPI = (props: BaseProps<void, TData, TOptions>) =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return { toggleTokenStatusAPI }
}
