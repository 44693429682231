export const CELL_COLOR = {
  GREEN: {
    BACKGROUND: '#b0f791',
    TEXT: '#435231'
  },
  LIGHT_GREEN: {
    BACKGROUND: '#dcffd7',
    TEXT: '#435231'
  },
  YELLOW: {
    BACKGROUND: '#fbf6e1',
    TEXT: '#9b7a0d'
  },
  RED: {
    BACKGROUND: '#fff0f6',
    TEXT: '#c41d7f'
  }
}
