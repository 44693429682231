import { ColumnsType } from 'antd/lib/table'
import { ApiViewTableData } from '../types/api'

const mockColumns: ColumnsType<ApiViewTableData> = [
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 60,
    render: () => ''
  },
  {
    title: 'Review Center View',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 120
  },
  {
    title: 'Default',
    dataIndex: 'is_default',
    key: 'is_default',
    width: 100,
    render: (value: boolean) => (value ? 'Yes' : 'No')
  }
]

export default mockColumns
