export default function LoginOktaIcon() {
  return (
    <img
      src='./assets/images/okta-logo.svg'
      className='img img-responsive'
      width='25'
      height='auto'
      alt='okta-logo'
    />
  )
}
