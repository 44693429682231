export default function handleNestedValues(obj: any, errorList: string[] = []): string[] {
    if (obj && typeof obj === 'object') {
        for (const key in obj) {
            handleNestedValues(obj[key], errorList)
        }
    } else {
        //   console.log(`FUNC`, obj)
        errorList.push(obj)
    }
    return errorList
}