/** Tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** Services imports */
import useTxnService from '../services/useTxnService'
import useApiService from './useApiService'

// =================================================================
export default function useTxnGlobalSettings() {
  /** API service */
  const { txnApi } = useTxnService()
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['txn-global-settings'],
    queryFn: () =>
      txnApi({
        options: {
          baseURL: msUrl.txn_manager,
          endpoint: '/api/global/settings',
          method: 'GET'
        }
      })
  })
}
