// ** AntD Imports
import { Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'

// ** Type Imports
import { TableRecord } from '../types/Table'

// =================================================================
export const columns: ColumnType<TableRecord>[] = [
  {
    title: 'Date (PST)',
    dataIndex: 'created_at',
    sorter: true
  },
  {
    title: 'User',
    dataIndex: 'user_profile',
    render: (userProfile) => {
      return userProfile !== null ? (
        <Typography.Text style={{ fontSize: 12 }}>
          {userProfile.first_name + ' ' + userProfile.last_name}
        </Typography.Text>
      ) : (
        <Typography.Text style={{ fontSize: 12, color: 'red' }}>
          No User Profile
        </Typography.Text>
      )
    }
  },
  {
    title: 'User Email',
    dataIndex: ['user_profile', 'email'],
    render: (value) => {
      return <Typography.Text style={{ fontSize: 12 }}>{value}</Typography.Text>
    }
  },
  {
    title: 'Role',
    dataIndex: 'roles',
    render: (value: string[]) =>
      value.map((role, index, array) =>
        index + 1 === array.length ? role : `${role}, `
      )
  },
  {
    title: 'IP Address',
    dataIndex: 'ip_address'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => {
      return status.charAt(0).toUpperCase() + status.slice(1)
    }
  },
  {
    title: 'Method',
    dataIndex: 'method'
  }
]
