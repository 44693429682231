/** React imports */
import { useEffect, useState } from 'react'

/** antd imports */
import { Form, Input, Modal } from 'antd'

/** custom component imports */
import GButton from 'src/components/gappify/GButton'

/** hooks imports */
import useLoginSaml from '../hooks/useLoginSaml'

/** zustand imports */
import useLoginStore from '../manager/useLoginStore'

/** Utils */
import { isValidEmail } from 'src/utils/regexValidators'

/** redux imports */
import setError from '../../../context/actions/errorAction'
import store from './../../../context/store'

// =================================================================
export default function SamlModal() {
  /** antd */
  const [form] = Form.useForm()

  /** React states */
  const [isModalVisible, setIsModalVisible] = useState(false)

  /** zustand states */
  const { samlEmail, setSamlEmail } = useLoginStore()

  /** hooks */
  const {
    refetch: handleSubmit,
    isError,
    isSuccess,
    data,
    error,
    isFetching
  } = useLoginSaml({ email: samlEmail })

  /** functions */
  const emailValidator = (rule: any, value: any) => {
    return isValidEmail(value)
      ? Promise.resolve()
      : Promise.reject(
          new Error('Please provide a properly formatted email address.')
        )
  }
  const onClickModalBtn = () => {
    setIsModalVisible(true)
  }

  const onClickCancel = () => {
    form.setFields([
      {
        name: 'email',
        errors: [],
        value: null
      }
    ])
    setSamlEmail('')
    setIsModalVisible(false)
  }

  const onClickOk = () => {
    if (
      !form?.getFieldValue('email') ||
      form?.getFieldValue('email')?.length === 0
    ) {
      form.setFields([
        {
          name: 'email',
          errors: ['You need to write something!']
        }
      ])
    } else if (form.getFieldsError()[0].errors.length === 0) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setSamlEmail('')
      setIsModalVisible(false)

      window.location.replace(data.redirect_url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      form.setFields([
        {
          name: 'email',
          errors: [error.data.error]
        }
      ])

      store.dispatch(
        setError({
          visible: false,
          guid: '',
          status: 0,
          message: ''
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <>
      <div className='saml-btn'>
        <GButton
          dataTestId={'saml-modal-btn'}
          dataCy={'saml-modal-btn'}
          btnText={'SIGN IN WITH SAML'}
          type={'primary'}
          onClick={onClickModalBtn}
          icon={
            <img
              src='./assets/images/transparent-square.svg'
              className='img img-responsive'
              width='25'
              height='auto'
              alt='saml-logo'
            />
          }
        />
      </div>
      <Modal
        data-testid={`saml-modal`}
        data-cy={`saml-modal`}
        title={'Enter your email address'}
        open={isModalVisible}
        onOk={onClickOk}
        okButtonProps={{ htmlType: 'submit' }}
        onCancel={onClickCancel}
        confirmLoading={isFetching}
      >
        <Form form={form} onFinish={handleSubmit} layout='vertical'>
          <Form.Item
            data-testid='saml-email-input'
            data-cy='saml-email-input'
            name='email'
            rules={[
              { required: true, message: 'You need to write something!' },
              {
                validator: emailValidator
              }
            ]}
          >
            <Input
              data-testid='saml-email-input-field'
              data-cy='saml-email-input-field'
              placeholder='Enter a valid email address'
              onChange={(event) => {
                const email = event.target.value
                setSamlEmail(email)
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
