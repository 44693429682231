// ** Third Party Imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** API Services Imports
import useGlobalService from '../../../../../services/globalService'

// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../manager/analytics-settings-store'

// ** Type Imports
import { ISingleRule } from '../types/form-data'
import { IReorderRule } from '../types/table-record'

// =================================================================
export default function useAnalyticsSettings() {
  // ** Store **
  const { setErrorMsg, selectedDropdown } = useAnalyticsSettingsStore()

  // ** API Services **
  const { globalServiceApi } = useGlobalService()

  // ** TanStack **
  const queryClient = useQueryClient()

  // ** Hooks **
  const { msUrl } = useApiService()

  // =================================================================
  const getAnalyticsRules = useQuery({
    queryKey: ['analytics-rules'],
    queryFn: () =>
      globalServiceApi({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `api/analytics-rules`,
          method: `GET`
        }
      })
  })

  const {
    mutate: postRule,
    isLoading: postRuleLoading,
    isSuccess: postRuleSuccess,
    isError: postRuleError
  } = useMutation(globalServiceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['analytics-rules'] })
    },
    onError: (error: {
      data: { errors: { name: string }; message: string }
    }) => {
      console.log(`ERROR POST RULE`, error)

      const errorMsg = error?.data?.errors?.name
      if (errorMsg) setErrorMsg(errorMsg)
    }
  })

  const {
    mutate: patchRule,
    isLoading: patchRuleLoading,
    isSuccess: patchRuleSuccess,
    isError: patchRuleError
  } = useMutation(globalServiceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['analytics-rules'] })
    },
    onError: (error: {
      data: { errors: { name: string }; message: string }
    }) => {
      console.log(`ERROR PATCH RULE`, error)

      const errorMsg = error?.data?.errors?.name
      if (errorMsg) setErrorMsg(errorMsg)
    }
  })

  const {
    mutateAsync: deleteRuleAsync,
    isLoading: deleteRuleLoading,
    isSuccess: deleteRuleSuccess,
    isError: deleteRuleError
  } = useMutation({
    mutationFn: ({ id }: { id: number }) =>
      globalServiceApi({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `api/analytics-rules/${id}`,
          method: 'DELETE'
        }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['analytics-rules'] })
    },
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR DELETE RULE`, error)
    }
  })

  const {
    mutate: patchReorderRule,
    isLoading: patchReorderRuleLoading,
    isSuccess: patchReorderRuleSuccess,
    isError: patchReorderRuleError
  } = useMutation(globalServiceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['analytics-rules'] })
    },
    onError: (error: {
      data: { errors: { name: string }; message: string }
    }) => {
      console.log(`ERROR PATCH REORDER RULE`, error)

      const errorMsg = error?.data?.errors?.name
      if (errorMsg) setErrorMsg(errorMsg)
    }
  })

  const getDropdownList = useQuery({
    queryKey: ['analytics-rules-dropdown-list', selectedDropdown],
    queryFn: () =>
      globalServiceApi({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `api/analytics-rules/dropdown-list/${selectedDropdown}`,
          method: `GET`
        }
      }),
    enabled: !!selectedDropdown
  })

  // ###########################################################
  // START: Handlers
  // -----------------------------------------------------------

  const handleSubmitRule = ({ ruleData }: { ruleData: ISingleRule }) => {
    postRule({
      data: ruleData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/analytics-rules`,
        method: 'POST'
      }
    })
  }

  const handleUpdateRule = ({
    ruleData,
    id
  }: {
    ruleData: ISingleRule
    id: number
  }) => {
    patchRule({
      data: ruleData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/analytics-rules/${id}`,
        method: 'PATCH'
      }
    })
  }

  const handleReorderRule = (ruleData: IReorderRule) => {
    patchReorderRule({
      data: ruleData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/analytics-rules/reorder`,
        method: 'PATCH'
      }
    })
  }

  // -----------------------------------------------------------
  // END: Handlers
  // ###########################################################

  return {
    getAnalyticsRules,
    handleSubmitRule,
    postRuleLoading,
    postRuleSuccess,
    postRuleError,
    deleteRuleAsync,
    deleteRuleLoading,
    deleteRuleSuccess,
    deleteRuleError,
    handleUpdateRule,
    patchRuleLoading,
    patchRuleSuccess,
    patchRuleError,
    handleReorderRule,
    patchReorderRuleLoading,
    patchReorderRuleSuccess,
    patchReorderRuleError,
    getDropdownList
  }
}
