import { useEffect, useState } from 'react'

import { List } from 'antd'
import { CloseOutlined, RightOutlined } from '@ant-design/icons'

import { Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd'

type TListDroppableProps = {
  droppableId: string
  dataSource: any
  actions: boolean
  handleOnClickX?: any
  handleOnClickCaret?: any
}

/**
 * ? Workaround to enable dnd when in strict mode
 * ? Ref: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
 */
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

const ListDroppable = ({
  droppableId,
  dataSource,
  actions,
  handleOnClickX,
  handleOnClickCaret
}: TListDroppableProps) => {
  return (
    <StrictModeDroppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ width: '50%' }}
        >
          <List
            style={{ height: '100%' }}
            size='small'
            bordered
            dataSource={dataSource}
            renderItem={(item: any, index: number) => (
              <Draggable
                key={item.api_name}
                draggableId={item.api_name}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <List.Item
                      actions={
                        actions
                          ? [
                              <CloseOutlined
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                onClick={() => handleOnClickX(index)}
                                style={{ fontSize: '12px', color: 'red' }}
                              />
                            ]
                          : [
                              <RightOutlined
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                onClick={() => handleOnClickCaret(index)}
                                style={{ fontSize: '12px', color: 'gray' }}
                              />
                            ]
                      }
                      style={{ borderBottom: '1px solid #d9d9d9' }}
                    >
                      {item.label}
                    </List.Item>
                  </div>
                )}
              </Draggable>
            )}
          />
          {provided.placeholder}
        </div>
      )}
    </StrictModeDroppable>
  )
}

export default ListDroppable
