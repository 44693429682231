import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = {
  data: Option[]
  success: boolean
}

type Option = {
  label: string
  api_name: string
}

const useFetchReviewCenterDimensions = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useQuery<Response>({
    queryKey: ['review-center-dimensions'],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/review-center/dimensions`,
          method: 'GET'
        }
      })
  })
}

export default useFetchReviewCenterDimensions
