const TOOLTIP_MSG = {
  CONSO: {
    DEFAULT_OLD: '',
    DEFAULT:
      'This is a placeholder tooltip message for Consolidated Transaction View.',
    DISABLED:
      'You cannot enable this flag because transactions have been created already for the current period.'
  }
}

export { TOOLTIP_MSG }
