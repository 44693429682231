// ** Zustand Imports
import { create } from 'zustand'

// ** Types Imports
import AccrualRulesStoreProps from '../types/AccrualRulesStoreProps'

// =================================================================
export default create<AccrualRulesStoreProps>((set) => ({
  // ** ToDo Utilize Zustand for Accrual Rules
  prepaidAccountId: null,

  setPrepaidAccountId: (value: number | string | null) =>
    set({ prepaidAccountId: value })
}))
