// ** AntD Imports
import { Divider, List, Typography } from 'antd'

// ** Hook Imports
import useCollapsible from '../../hooks/useCollapsible'

// ** Style Imports
import styles from '../../styles/collapsible.module.css'

// ** Type Imports
import CollapsibleProps from '../../types/Collapsible'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
const CollapsibleList = ({ data, title, paramName }: CollapsibleProps) => {
  // ** Zustand **
  const { collapsed, selectedItem } = useAnalyticsStore()

  // ** Hooks **
  const { handleClick } = useCollapsible(paramName)

  return (
    <>
      <List
        className={`${styles.customList} ${
          collapsed ? styles.hidden : styles.visible
        }`}
        size='large'
        header={
          <Typography.Title level={1} className={styles.title}>
            {title}
          </Typography.Title>
        }
        dataSource={data}
        renderItem={(item, index) => (
          <div className={`${item === selectedItem ? styles.selected : ''}`}>
            <List.Item
              className={`${styles.item} ${
                item === selectedItem ? styles.selectedText : ''
              }`}
              onClick={() => handleClick(item, title)}
              data-testid='list-item-btn'
            >
              {item}
            </List.Item>
          </div>
        )}
      />
      <Divider
        className={styles.divider}
        style={{ display: collapsed ? 'none' : 'block' }}
      />
    </>
  )
}

export default CollapsibleList
