// ** React Imports
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// ** AntD Imports
import { Layout } from 'antd'

// ** Custom Component Imports
import Collapsible from './components/Collapsible'

// ** Hook Imports
import useFeatureFlags from '../../hooks/useFeatureFlags'
import useCompanySettings from '../../hooks/useCompanySettings'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

// ** Style Imports
import styles from './styles/main.module.css'

// ** View Imports
import IncreasingSpend from './views/IncreasingSpend'
import ConfirmationResponseRates from './views/ConfirmationResponseRates'
import SpendCompliance from './views/SpendCompliance'

// ** Zustand Imports
import useAnalyticsStore from './manager/analytics-store'

// ** Type/Enum Imports
import { EReportTypes } from './constants/Globals'

// TODO: Can be refactored as ENUMS and use across all AA pages
const ADVANCED_ANALYTICS_PATHS = {
  'confirmation-rates-vendor': {
    path: '/insights?confirmation-response-rates=Vendor',
    title: 'Confirmation Rates',
    subtitle: 'Vendor'
  },
  'confirmation-rates-internal': {
    path: '/insights?confirmation-response-rates=Internal',
    title: 'Confirmation Rates',
    subtitle: 'Internal'
  },
  'cost-monitoring-po-compliance': {
    path: '/insights?cost-cutting=PO+Compliance',
    title: 'Cost Monitoring',
    subtitle: 'PO Compliance'
  },
  'cost-monitoring-rising-expenses': {
    path: '/insights?cost-cutting=Rising+Expenses',
    title: 'Cost Monitoring',
    subtitle: 'Rising Expenses'
  }
}

// TODO: Need route refactoring to avoid this
const paramCostMonitoring = 'cost-cutting'
const paramConfirmationRates = 'confirmation-response-rates'
const validCostMonitoring = ['PO Compliance', 'Rising Expenses']
const validConfirmationRates = ['Internal', 'Vendor']

// =================================================================
const AccrualAnalytics = () => {
  // ** MUI **
  const { Content } = Layout

  // ** Router **
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  // ** Store **
  const {
    isAnalytics,
    selectedItem,
    setHeader,
    setTitle,
    setSelectedItem,
    setResponseType,
    setCostCuttingTabView,
    setReportType
  } = useAnalyticsStore()

  // ** Hooks **
  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const { data: companySettingsData, isLoading: isCompanySettingsLoading } =
    useCompanySettings()
  const RP = useRolesAndPermissions()

  useEffect(() => {
    if (isFeatureFlagsLoading || isCompanySettingsLoading) return
    if (!isAnalytics || !RP.USER_HAS_GLOBAL_AA_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companySettingsData) {
      // Default values to avoid blank white page
      let thisTitle = ''
      let thisSubtitle = ''

      // Check if there's a valid params in the URL
      const activeCostMonitoring = searchParams.get(paramCostMonitoring)
      const activeConfirmationRates = searchParams.get(paramConfirmationRates)

      // Check if there's a valid cost monitoring params
      if (
        activeCostMonitoring &&
        validCostMonitoring.includes(activeCostMonitoring)
      ) {
        thisTitle = 'Cost Monitoring'
        thisSubtitle = activeCostMonitoring
      }
      // Check if there's a valid confirmation rates params
      else if (
        activeConfirmationRates &&
        validConfirmationRates.includes(activeConfirmationRates)
      ) {
        thisTitle = 'Confirmation Rates'
        thisSubtitle = activeConfirmationRates
      }
      // Make sure to run this ONLY if default AA setting has a value
      else if (
        companySettingsData &&
        companySettingsData.advanced_analytics_type
      ) {
        const defaultReport: keyof typeof ADVANCED_ANALYTICS_PATHS =
          companySettingsData.advanced_analytics_type

        const { path, title, subtitle } =
          ADVANCED_ANALYTICS_PATHS[defaultReport]

        thisTitle = title
        thisSubtitle = subtitle

        if (path !== location.pathname) {
          navigate(path)
        }
      } else {
        thisTitle = 'Cost Monitoring'
        thisSubtitle = 'PO Compliance'
      }

      /**
       * This initialize required states for initial render of UI
       * Added slight delay via setTimeout to prevent UI glitch
       */
      setTimeout(() => {
        setHeader(thisTitle)
        setTitle(thisSubtitle)
        setSelectedItem(thisSubtitle)

        if (!validCostMonitoring.includes(thisSubtitle)) {
          setResponseType(thisSubtitle.toLowerCase())
          setCostCuttingTabView('')

          if (thisSubtitle.toLowerCase() === 'internal') {
            setReportType(EReportTypes.ResponseRatesInternal)
          } else if (thisSubtitle.toLowerCase() === 'vendor') {
            setReportType(EReportTypes.ResponseRatesVendor)
          }
        } else {
          setResponseType(undefined)
        }
      }, 100)
    }

    // eslint-disable-next-line
  }, [companySettingsData])

  // ** Enums **
  const views = {
    'PO Compliance': <SpendCompliance />,
    'Rising Expenses': <IncreasingSpend />,
    Internal: <ConfirmationResponseRates />,
    Vendor: <ConfirmationResponseRates />
  }

  const CurrentView = selectedItem
    ? views[selectedItem as keyof typeof views]
    : null

  return isAnalytics && RP.USER_HAS_GLOBAL_AA_PERMISSION ? (
    <Layout className={styles.container}>
      <Collapsible />
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <>{CurrentView}</>
        </Content>
      </Layout>
    </Layout>
  ) : (
    // <AnalyticsTable />
    <></>
  )
}

export default AccrualAnalytics
