import { FC } from 'react'
import { Modal, Row, Col } from 'antd'
import HistoryViewDetails from '../types/HistoryViewDetails'
import moment from 'moment-timezone'

interface ViewDetailsModalProps {
  showModal: boolean
  onCancel: () => void
  item: HistoryViewDetails
  dateTimeFormat?: string
  timezone?: string
}

const ViewDetailsModal: FC<ViewDetailsModalProps> = ({
  showModal,
  onCancel,
  item,
  dateTimeFormat,
  timezone
}): JSX.Element => {
  const getVendors = (stringVendor: string) => {
    return stringVendor.split(', ')
  }

  const getType = (type: string) => {
    if (type) {
      if (type.includes('nudge_selected')) {
        return 'Nudge Selected'
      } else if (type.includes('nudge_all')) {
        return 'Nudge All'
      }
    }
    return type
  }

  const formatTime = (time: string | number) => {
    if (typeof time === 'string') {
      return moment.tz(timezone!).format(dateTimeFormat)
    } else {
      return moment.unix(time).tz(timezone!).format(dateTimeFormat)
    }
  }

  return (
    <Modal
      data-testid='view-details-modal'
      data-cy='view-details-modal'
      title='View Details'
      open={showModal}
      onCancel={onCancel}
      width={1000}
      bodyStyle={{ height: 300 }}
      footer={null}
      className={'view-details-modal'}
    >
      <Row>
        <Col lg={10}>Nudge Type</Col>
        <Col>{getType(item.type!)}</Col>
      </Row>
      <Row>
        <Col lg={10}>Nudge Emails/Vendors</Col>
        <Col>
          {item.vendor &&
            getVendors(item.vendor)?.map((item) => {
              return <div>{item}</div>
            })}
        </Col>
      </Row>
      <Row>
        <Col lg={10}>Message</Col>
        <Col>{item.nudge_message}</Col>
      </Row>
      <Row>
        <Col lg={10}>Time</Col>
        <Col>{timezone && formatTime(item.params?.nudge_time!)}</Col>
      </Row>
    </Modal>
  )
}
export default ViewDetailsModal
