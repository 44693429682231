// ** AntD Imports
import { Button, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import usePermItems from './content/perm-items'

// ** Hook Imports
import useAnalytics from '../../hooks/useAnalytics'

// ** Style Imports
import styles from '../../styles/actions.module.css'

// ** Type Imports
import ActionProps from '../../types/Actions'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
const PermNotesActions = ({ record, actionLabel }: ActionProps) => {
  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** Hooks **
  const { handleModifyPermNote } = useAnalytics({ responseType: responseType })
  const { PermActionItems } = usePermItems(record)

  return (
    <Dropdown
      overlay={
        <Menu
          items={PermActionItems}
          onClick={({ key }) => handleModifyPermNote(key, record, actionLabel)}
        />
      }
      trigger={['click']}
    >
      <Button
        className={styles.primaryBtn}
        icon={<MoreOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} className ={styles.outlineBtn} />}
      />
    </Dropdown>
  )
}

export default PermNotesActions
