import jwt_decode from 'jwt-decode'
import { SetStateAction } from 'react'
import ActionPermissions from '../../../helpers/constants/ActionPermissions'
import {
  AUTH_TOKEN,
  EXPIRATION,
  EXPIRES_ON,
  EXPIRY_COUNTER,
  ROLES_AND_PERMISSIONS
} from '../../../helpers/constants/Auth'
import { loginUser } from '../../../services/AuthApi'
import MsUrl from '../../../types/MsUrl'

export default async function login(
  username: string | null,
  msUrl: MsUrl,
  values: { [name: string]: any },
  setIsLoading: (value: SetStateAction<boolean>) => void,
  setIsAuth: (value: SetStateAction<boolean>) => void,
  setErrorMessage: (value: SetStateAction<string>) => void,
  setUserHasViewTxnColumnManagerPermission: (
    value: SetStateAction<boolean>
  ) => void,
  TO_SECONDS: number
): Promise<void> {
  const payload: { username: string; [name: string]: any } = {
    username: username!,
    token_duration: msUrl.token_duration,
    ...values
  }

  setIsLoading(true)
  try {
    const { data } = await loginUser(payload)
    setIsAuth(true)
    setErrorMessage('')
    let jwtToken = window.atob(data.access_token)
    let decodedjwt: any = jwt_decode(jwtToken)
    const action_permissions: number[] = decodedjwt.action_permissions
    const hasViewTxnColumnManagerPermission = action_permissions.find(
      (item) => item === ActionPermissions.VIEW_TRANSACTION_COLUMN_MANAGER
    )
    setUserHasViewTxnColumnManagerPermission(
      Boolean(hasViewTxnColumnManagerPermission)
    )

    localStorage.setItem(AUTH_TOKEN, data.access_token)
    localStorage.setItem(EXPIRATION, data.expires_in)
    localStorage.setItem(
      EXPIRY_COUNTER,
      (data.expires_in * TO_SECONDS).toString()
    )
    localStorage.setItem(EXPIRES_ON, data.expires_on)
    localStorage.setItem(ROLES_AND_PERMISSIONS, JSON.stringify(decodedjwt))
    window.location.reload()
  } catch (error: any) {
    setIsAuth(false)
    const { response } = error
    if (response && response.data.error) {
      if (response.data.error.password) {
        let pwError = error.response.data.error.password
        setErrorMessage(pwError[0])
      } else {
        setErrorMessage(error.response.data.error)
      }
    }
  } finally {
    setIsLoading(false)
  }
}
