import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import DecodedJWTProps from '../types/DecodedJWTProps'
import LoginResponseDataProps from '../types/LoginResponseDataProps'
import useLogin from './useLogin'

export default function useHandleLoginSSO<TError>(
  isSuccess: boolean,
  isError: boolean,
  error: TError,
  data?: LoginResponseDataProps
) {
  const { handleError, setLocalStorageData, redirectPage } = useLogin()

  useEffect(() => {
    if (isSuccess && data) {
      const jwtToken = window.atob(data.access_token)
      const decodedJwt: DecodedJWTProps = jwt_decode(jwtToken)
      setLocalStorageData(data, decodedJwt)
      redirectPage(decodedJwt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess])

  useEffect(() => {
    if (isError) {
      handleError(error, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])
}
