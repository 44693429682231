import currency from 'currency.js'
class ReviewCenterHelper {

    static getValue(data: any, key: string) {
        return (typeof data[key] !== "undefined") ? this.formatCurrency(data[key]) : '';
    }

    static getDefaultValue(data: any, key: string) {
        return (data[key]) ? data[key] : '0.00';
    }

    static formatAmount(amount: any, precision: any = 2) {
        return amount.toFixed(precision);
    }

    static formatCurrency(amount: any, precision: any = 2) {
        return currency(amount, {
            pattern: `#`,
            negativePattern: `(#)`,
            decimal: '.',
            separator: ',',
            precision: precision
        }).format();
    }

    static departmentGLRowSpan(data: any) {
        let row_span = Object.keys(data).length;
        for (let id in data) {
            row_span += Object.keys(data[id].vendor).length;
        }
        return row_span;
    }

    static subsidiaryDepartmentRowSpan(data: any) {
        let row_span = Object.keys(data).length;
        for (let department_id in data) {
            row_span += this.departmentGLRowSpan(data[department_id].gl);
        }
        return row_span;
    }

    static subsidiaryGLRowSpan(data: any) {
        let row_span = Object.keys(data).length;
        for (let gl_id in data) {
            row_span += this.departmentGLRowSpan(data[gl_id].department);
        }
        return row_span;
    }
}

export default ReviewCenterHelper;