// ** Helper Imports
import {
  axiosIdentityManagerInstance,
  axiosIdentityManagerUploadInstance
} from '../../../helpers/axios'

// ** Type Imports
import {
  BaseProps,
  UserActivationOptions
} from '../../UserActivationForm/types/user-activation-api'

// =================================================================
export default function HardResetPasswordAPI() {
  const getUserActivateTokenValidation = (
    props: BaseProps<UserActivationOptions, unknown>
  ) => {
    return axiosIdentityManagerInstance.get(
      `api/user/${props.options.token}${props.options.endpoint}`,
      {}
    )
  }

  const postUserActivation = (
    props: BaseProps<UserActivationOptions, FormData>
  ) => {
    return axiosIdentityManagerUploadInstance.post(
      `api/user${props.options.endpoint}`,
      props.data
    )
  }

  return {
    getUserActivateTokenValidation,
    postUserActivation
  }
}
