// ** AntD Imports
import { Modal } from 'antd'

// ** Custom Component Imports
import ResponseMessage from './components/ResponseMessage'
import ResetPasswordModalForm from './components/InputForm'

// ** Style Imports
import styles from './styles/resetpassword.module.css'

// ** Zustand Store Imports
import useResetPasswordModalStore from './manager/resetpassword-store'

// =================================================================
const ResetPasswordModal = () => {
  // ** Zustand Store **
  const { isResetPasswordModalOpen, handleOk, handleCancel } =
    useResetPasswordModalStore()

  return (
    <Modal
      data-testid='resetpassword-modal'
      destroyOnClose
      closable={false}
      className={styles.modal}
      title={
        <div className={styles.header}>
          <img src='./assets/images/gappify-logo-black.svg' alt='gpfy-logo' />
        </div>
      }
      open={isResetPasswordModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <ResponseMessage />
      <ResetPasswordModalForm />
    </Modal>
  )
}

export default ResetPasswordModal
