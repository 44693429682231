import useAnalytics from '../../../../../hooks/useAnalytics'
import useAnalyticsStore from '../../../../../manager/analytics-store'
import { DataValuesRow } from '../../../../../types/DataValuesType'

const DataValuesTable = () => {
  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** API Services **
  const { getDataValuesPopup } = useAnalytics({
    responseType: responseType
  })

  const { data, isRefetching, isLoading } = getDataValuesPopup

  return (
    <div id='popup-table-wrapper' className='g-table-ui'>
      <table className='w-full text-left'>
        <thead>
          <tr className='title-bg-color border-bt sticky-row sticky-row-1'>
            <th className='border-l'>Date sent</th>
            <th>Date submitted</th>
            <th>Status</th>
            <th>Email</th>
            <th className='border-r'>CC Email</th>
          </tr>
        </thead>

        <tbody>
          {
            /* Loading state */
            isLoading || isRefetching ? (
              <tr>
                <td className='border-l border-r border-b' colSpan={8}>
                  Loading data ...
                </td>
              </tr>
            ) : /* Empty data state */
            !data?.data?.length ? (
              <tr>
                <td className='border-l border-r border-b' colSpan={8}>
                  No transactions available
                </td>
              </tr>
            ) : (
              data.data.map((row: DataValuesRow, index: number) => {
                return (
                  <tr key={index}>
                    <td className='border-lr border-b'>
                      {row.date_sent || '-'}
                    </td>
                    <td className='border-b'>{row.date_submitted || '-'}</td>
                    <td className='border-b'>{row.status || '-'}</td>
                    <td className='border-b'>{row.email || '-'}</td>
                    <td className='border-r border-b'>{row.cc_email || '-'}</td>
                  </tr>
                )
              })
            )
          }
        </tbody>
      </table>
    </div>
  )
}

export default DataValuesTable
