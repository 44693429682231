/** antd imports */
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'

/** custom components imports */
import LoginError from './LoginError'
import LoginResetPasswordBtn from './LoginResetPasswordBtn'
import LoginSSO from './LoginSSO'

/** hooks imports */
import useLogin from '../hooks/useLogin'
import useLoginCredentials from '../hooks/useLoginCredentials'

export default function LoginForm() {
  /** hooks */
  const { onFinishFailed } = useLogin()
  const { isLoading, handleSubmit } = useLoginCredentials()

  return (
    <div className='login-form-container'>
      <LoginError />
      <Form
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(values: any) => {
          handleSubmit(values)
        }}
        onFinishFailed={onFinishFailed}
        autoComplete='on'
      >
        {/* Username field */}
        <Form.Item
          name='username'
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input
            data-testid='login-username-input-field'
            data-cy='login-username-input-field'
            placeholder='Username'
            prefix={<UserOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} className='site-form-item-icon' />}
          />
        </Form.Item>

        {/* Password field */}
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            data-testid='login-password-input-field'
            data-cy='login-password-input-field'
            placeholder='Password'
            prefix={<LockOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>}
          />
        </Form.Item>

        <Button
          data-testid='login-submit-btn'
          data-cy='login-submit-btn'
          type='primary'
          htmlType='submit'
          className='login-btn'
          loading={isLoading}
        >
          LOG IN
        </Button>
        <LoginResetPasswordBtn />
      </Form>
      <LoginSSO />
    </div>
  )
}
