import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  MinusCircleTwoTone,
  SyncOutlined
} from '@ant-design/icons'

const SYNC_STATUSES: Record<string, any> = {
  SUCCESS: {
    label: 'Successfully synced',
    icon: (
      <CheckCircleTwoTone
        twoToneColor='#52c41a'
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        data-testid='succesfully-synced-icon'
        data-cy='succesfully-synced-icon'
      />
    )
  },
  PARTIAL: {
    label: 'Partially synced',
    icon: (
      <MinusCircleTwoTone
        twoToneColor='#52c41a'
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        data-testid='partially-synced-icon'
        data-cy='partially-synced-icon'
      />
    )
  },
  IN_PROGRESS: {
    label: 'Syncing in progress',
    icon: (
      <SyncOutlined
        spin
        style={{ color: '#fe9422' }}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        data-testid='in-progress-icon'
        data-cy='in-progress-icon'
      />
    )
  },
  ERROR: {
    label: 'Sync error',
    icon: (
      <ExclamationCircleTwoTone
        twoToneColor='red'
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        data-testid='sync-error-icon'
        data-cy='sync-error-icon'
      />
    )
  }
}

export { SYNC_STATUSES }
