import React from 'react'
import { ReviewCenterViewsHeaderColumns } from '../../utils/enums/ReviewCenterViewsEnum'

const TableHeader: React.FC<any> = ({ data, fields, view }) => {
  const header_columns =
    fields &&
    Object.entries(fields).map(([key, columns]: any, index: any) =>
      columns.map((v: any, i: any) => (
        <td
          className={i === columns.length - 1 ? 'border-r' : ''}
          key={i}
          data-cy={`rc-header${i}`}
          data-testid={`rc-header${i}`}
        >
          <strong>{v.label}</strong>
        </td>
      ))
    )

  const view_header_columns_row_2 = ReviewCenterViewsHeaderColumns[view].map(
    (v: any, i: any) => (
      <td
        className={v.classes}
        key={i}
        data-cy={`rc-view-header${i}`}
        data-testid={`rc-view-header${i}`}
      >
        <strong>{v.label}</strong>
      </td>
    )
  )

  const view_header_columns_row_1 = ReviewCenterViewsHeaderColumns[view].map(
    (v: any, i: any, views: any) => (
      <th
        className={`border-none bg-color-white sticky-col sticky-col-${i + 1} ${
          i + 1 === views.length ? 'border-r' : ''
        }`}
        key={i}
      ></th>
    )
  )

  return (
    <thead>
      <tr className='sticky-row sticky-row-1'>
        {view_header_columns_row_1}
        {fields['HISTORICALS'].length > 0 && (
          <th
            className='border-r border-b-none title-bg-color'
            colSpan={fields['HISTORICALS'].length}
            data-cy='rc-header-historicals'
            data-testid='rc-header-historicals'
          >
            <strong>Historicals</strong>
          </th>
        )}
        {fields['MONTH_TO_DATE'].length > 0 && (
          <th
            className='border-r border-b-none title-bg-color'
            colSpan={fields['MONTH_TO_DATE'].length}
            data-cy='rc-header-mtd'
            data-testid='rc-header-mtd'
          >
            <strong>Month-To-Date</strong>
          </th>
        )}
        {fields['ACCRUALS'].length > 0 && (
          <th
            className='border-r border-b-none title-bg-color'
            colSpan={fields['ACCRUALS'].length}
            data-cy='rc-header-gpfy-accruals'
            data-testid='rc-header-gpfy-accruals'
          >
            <strong>Gappify Accruals</strong>
          </th>
        )}
        {fields['ANALYSIS'].length > 0 && (
          <th
            className='border-r border-b-none title-bg-color'
            colSpan={fields['ANALYSIS'].length}
            data-cy='rc-header-gpfy-analysis'
            data-testid='rc-header-gpfy-analysis'
          >
            <strong>Analysis</strong>
          </th>
        )}
      </tr>
      <tr className='title-bg-color sticky-row sticky-row-2'>
        {view_header_columns_row_2}
        {header_columns}
      </tr>
    </thead>
  )
}

export default TableHeader
