import { Spin } from 'antd'
import useFlagStore from './stores/flagStore'

type TProps = {
  authorizedFlags: string[]
  renderOn: JSX.Element
  renderOff: JSX.Element
}

const Flag = (props: TProps) => {
  const { authorizedFlags, renderOn, renderOff } = props

  // ** Zustand Store **
  const flags = useFlagStore((state) => state.flags)
  const isFlagsLoading = useFlagStore((state) => state.isFlagsLoading)

  const isAuthorized = () => {
    const matchedFlags = flags.filter((item) => {
      const { name, isActive } = item
      const isMatched = isActive && authorizedFlags.includes(name)
      return isMatched
    })
    return Boolean(matchedFlags.length)
  }

  const RenderComponent = () => {
    return isAuthorized() ? renderOn : renderOff
  }

  if (isFlagsLoading) return <Spin size='small' style={{ margin: 5 }} />
  return <RenderComponent />
}

export default Flag
