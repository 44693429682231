import { Key, SetStateAction } from 'react'
import DROPDOWN_KEYS from '../constants/DROPDOWN_KEYS'

export default function handleDropdownOnClick(
  userId: number,
  email: string,
  status: 'Active' | 'Inactive' | 'Pending',
  name: string,
  setUserId: (value: SetStateAction<number | undefined>) => void,
  setUserEmail: (value: SetStateAction<string>) => void,
  setIsActivateModalVisible: (value: SetStateAction<boolean>) => void,
  setUserStatus: (
    value: SetStateAction<'Active' | 'Inactive' | 'Pending' | undefined>
  ) => void,
  setUserName: (value: SetStateAction<string>) => void,
  setIsUserModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResetModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResendActionLinkModalVisible: (value: SetStateAction<boolean>) => void,
  setIsHistoryModalVisible: (value: SetStateAction<boolean>) => void,
  key?: Key
) {
  if (key === DROPDOWN_KEYS.ACTIVATE) {
    setUserId(userId)
    setUserEmail(email)
    setUserStatus(status)
    setUserName(name)
    setIsActivateModalVisible(true)
  }

  if (key === DROPDOWN_KEYS.EDIT) {
    setIsUserModalVisible(true)
    setUserId(userId)
  }

  if (key === DROPDOWN_KEYS.RESET) {
    setUserId(userId)
    setUserEmail(email)
    setIsResetModalVisible(true)
  }

  if (key === DROPDOWN_KEYS.RESEND_ACTIVATION) {
    setUserId(userId)
    setUserEmail(email)
    setUserName(name)
    setIsResendActionLinkModalVisible(true)
  }

  if (key === DROPDOWN_KEYS.HISTORY) {
    setIsHistoryModalVisible(true)
    setUserId(userId)
  }
}
