import { useEffect } from 'react'

// Antd Imports
import { message } from 'antd'

// Component Imports
import LayoutForm from '../components/LayoutForm'

// Hook Imports
import useFetchLayout from '../hooks/useFetchLayout'
import useUpdateLayout from '../hooks/useUpdateLayout'

type Props = {}

const Layout = (props: Props) => {
  /** Tanstack Query states */
  const {
    data: fetchedLayout,
    isLoading: isLoadingLayout,
    isRefetching: isRefetchingLayout,
    isError: isErrorLayout
  } = useFetchLayout()
  const { mutate: updateLayout, isLoading: isLoadingUpdateLayout } =
    useUpdateLayout()

  /** Helpers */
  const getIsLoadingState = () => {
    return isLoadingLayout || isRefetchingLayout || isLoadingUpdateLayout
  }

  /** Handlers */
  const handleOnFinish = (values: any) => {
    updateLayout({ payload: values })
  }

  /** Side effects */
  useEffect(() => {
    if (isErrorLayout) {
      message.error('Something went wrong. See logs for more details.')
    }
    // eslint-disable-next-line
  }, [isErrorLayout])

  return (
    <div style={{ margin: '0 5em 48px' }}>
      <LayoutForm
        data={fetchedLayout?.data}
        isLoading={getIsLoadingState()}
        handleOnFinish={handleOnFinish}
      />
    </div>
  )
}

export default Layout
