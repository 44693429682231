import { CSSProperties, FC, ReactNode } from 'react'
import { Space } from 'antd'

interface GFormItemProps {
  label: string
  style?: CSSProperties
  direction?: 'horizontal' | 'vertical'
  children: ReactNode
}

const GFormItem: FC<GFormItemProps> = ({
  label,
  style,
  direction,
  children
}: GFormItemProps): JSX.Element => {
  return (
    <Space direction={direction} style={style} data-testid='GFormItem'>
      <label>{label}</label>
      {children}
    </Space>
  )
}

GFormItem.defaultProps = {
  direction: 'vertical'
}

export default GFormItem
