import { Button, Form, Input, Modal, Popconfirm } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import resetPassword from '../services/resetPassword'

interface ResetPasswordModalProps {
  visible: boolean
  id?: number
  email: string
  setIsModalVisible: (value: SetStateAction<boolean>) => void
  afterClose?: () => void
  onSubmitFinish: () => void
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  visible,
  id,
  email,
  setIsModalVisible,
  afterClose,
  onSubmitFinish
}: ResetPasswordModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    if (visible) {
      form.setFieldValue('email', email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleModalOnCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const handleOnSubmit = async (values: any) => {
    const payload = {
      id: id!,
      email: values.email
    }

    setIsConfirmLoading(true)
    try {
      await resetPassword(payload)

      // reset state after success api call
      form.resetFields()
      setIsConfirmLoading(false)
      setIsModalVisible(false)
      onSubmitFinish()
    } catch (error) {
      console.log(error)

      form.resetFields()
      setIsConfirmLoading(false)
      setIsModalVisible(false)
    }
  }

  const modalFooter: JSX.Element = (
    <>
      <Button
        key='back'
        onClick={handleModalOnCancel}
        data-testid='modal-cancel-btn'
        data-cy='modal-cancel-btn'
      >
        Cancel
      </Button>

      <Popconfirm
        title='Are you sure you want to reset the password?'
        onConfirm={form.submit}
        okText='Yes'
        cancelText='No'
      >
        <Button
          key='submit'
          type='primary'
          loading={isConfirmLoading}
          disabled={isConfirmLoading}
          style={{ marginLeft: '10px' }}
          data-testid='modal-reset-btn'
          data-cy='modal-reset-btn'
        >
          Reset
        </Button>
      </Popconfirm>
    </>
  )

  return (
    <Modal
      destroyOnClose
      title='Reset Password'
      open={visible}
      okText='Reset'
      onCancel={handleModalOnCancel}
      afterClose={afterClose}
      onOk={form.submit}
      closable={false}
      footer={modalFooter}
      data-testid='reset-password-modal'
      data-cy='reset-password-modal'
    >
      <Form form={form} layout='horizontal' onFinish={handleOnSubmit}>
        <Form.Item
          label='Email Address'
          name='email'
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input
            data-testid='email-input-field'
            data-cy='email-input-field'
            disabled
          />
        </Form.Item>
      </Form>
      <p>
        By choosing “Reset”, the user&apos;s current password will no longer be
        valid. The user will receive email instructions on how to reset their
        password.
      </p>
    </Modal>
  )
}

export default ResetPasswordModal
