import { useEffect, useState } from 'react'

// Antd Imports
import { Button, Checkbox, Form, Modal, message } from 'antd'

// Hook Imports
import useCreateView from '../hooks/useCreateView'

// Zustand Imports
import useStore from '../manager/rc-settings-store'

// Constants Imports
import constants from '../config/constants'
import { ApiViewTableData } from '../types/api'
import useUpdateView from '../hooks/useUpdateView'
import ViewModalForm from './ViewModalForm'

type Props = {
  availableOptions: Record<string, any>[]
  isLoadingSingleView: boolean
  fetchedSingleView: ApiViewTableData | undefined
  setEditId: (id: number) => void
}

const { CREATE_MODAL, EDIT_MODAL, CURRENCY } = constants

const ViewModal = (props: Props) => {
  const {
    availableOptions,
    isLoadingSingleView,
    fetchedSingleView,
    setEditId
  } = props
  const [form] = Form.useForm()

  /** Tanstack Query states */
  const {
    mutate: createView,
    isLoading: isLoadingCreateView,
    isSuccess: isSuccessCreateView
  } = useCreateView()
  const {
    mutate: updateView,
    isLoading: isLoadingUpdateView,
    isSuccess: isSuccessUpdateView
  } = useUpdateView()

  /** Local states */
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([])
  const [isDefault, setIsDefault] = useState(false)

  /** Global states */
  const { showViewModal, viewModalType, setShowViewModal } = useStore()

  /** Helpers */
  const getIsLoadingState = () => {
    if (viewModalType === 'create') return isLoadingCreateView
    return isLoadingUpdateView || isLoadingSingleView
  }
  const initializeForm = (view: ApiViewTableData) => {
    const { fields, currency, is_default } = view
    let dimensions = fields.split(',')

    // Remove the dimension from the UI if not found in the availableOptions
    dimensions = dimensions.filter((item) => {
      return availableOptions?.find((option) => option.api_name === item)
    })

    form.setFieldsValue({
      dimensions,
      currency
    })

    // Set default value for is_default checkbox
    setIsDefault(!!is_default)

    // Set initial value for selectedDimensions
    setSelectedDimensions(dimensions)
  }

  /** Event handlers */
  const handleOnFinish = (values: any) => {
    const { currency, dimensions } = values
    const payload = {
      name: getLabels(dimensions),
      fields: dimensions.join(','),
      currency,
      is_default: isDefault ? 1 : 0
    }
    if (viewModalType === 'create') {
      createView(payload)
    } else {
      updateView({
        id: fetchedSingleView!.id,
        payload
      })
    }
  }
  const handleOk = () => {
    // ** Check requirement for Functional currency **
    if (
      form.getFieldValue('currency') === CURRENCY.FUNCTIONAL.key &&
      form.getFieldValue('dimensions')[0] !== 'g_subsidiary_id'
    ) {
      message.error(
        'To use functional currency, the view has to start with Subsidiary.'
      )
    } else {
      form.submit()
    }
  }
  const handleCloseModal = () => {
    form.resetFields()
    setSelectedDimensions([])
    setIsDefault(false)
    setShowViewModal(false)
    setEditId(0)
  }

  // ** For generating the Name of the View **
  const getLabels = (apiNames: string[]) => {
    const labels = apiNames
      .map((apiName) => {
        const item = availableOptions?.find((item) => item.api_name === apiName)
        return item ? item.label : null
      })
      .filter((label) => label !== null)
    const result = labels.join(' > ')
    return result
  }

  /** Side effects */
  useEffect(() => {
    if (fetchedSingleView) {
      initializeForm(fetchedSingleView)
    }
    // eslint-disable-next-line
  }, [fetchedSingleView])

  useEffect(() => {
    if (!isLoadingCreateView && isSuccessCreateView) {
      handleCloseModal()
    }
    // eslint-disable-next-line
  }, [isLoadingCreateView, isSuccessCreateView])

  useEffect(() => {
    if (!isLoadingUpdateView && isSuccessUpdateView) {
      handleCloseModal()
    }
    // eslint-disable-next-line
  }, [isLoadingUpdateView, isSuccessUpdateView])

  return (
    <Modal
      title={viewModalType === 'create' ? CREATE_MODAL.TITLE : EDIT_MODAL.TITLE}
      open={showViewModal}
      onCancel={handleCloseModal}
      data-testid='view-modal'
      footer={[
        <Checkbox
          key='checkbox'
          style={{ float: 'left' }}
          checked={isDefault}
          onChange={(e) => setIsDefault(e.target.checked)}
          data-testid='default-checkbox'
          disabled={getIsLoadingState()}
        >
          Set as default
        </Checkbox>,
        <Button
          key='back'
          onClick={handleCloseModal}
          data-testid='cancel-button'
          loading={getIsLoadingState()}
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={handleOk}
          data-testid='save-button'
          loading={getIsLoadingState()}
        >
          Save
        </Button>
      ]}
    >
      <ViewModalForm
        form={form}
        handleOnFinish={handleOnFinish}
        isLoadingState={getIsLoadingState()}
        availableOptions={availableOptions}
        selectedDimensions={selectedDimensions}
        setSelectedDimensions={setSelectedDimensions}
      />
    </Modal>
  )
}

export default ViewModal
