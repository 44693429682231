import ActionTypes from '../constants/action-types'
import StoreAction from '../../types/StoreAction'

export const setTasks = (tasks: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_TASKS,
    payload: tasks
  }
}

export const setTaskParams = (params: object): StoreAction => {
  return {
    type: ActionTypes.SET_TASK_PARAMS,
    payload: params
  }
}

export const setTotalTasks = (total: number): StoreAction => {
  return {
    type: ActionTypes.SET_TOTAL_TASKS,
    payload: total
  }
}

export const setIsLoading = (loading: boolean): StoreAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    payload: loading
  }
}

export const setCurrPage = (currPage: number): StoreAction => {
  return {
    type: ActionTypes.SET_CURR_PAGE,
    payload: currPage
  }
}

export const setTsmPreviewMode = (previewMode: boolean): StoreAction => {
  return {
    type: ActionTypes.SET_TSM_PREVIEW_MODE,
    payload: previewMode
  }
}

export const setTsmFilterFields = (filterFields: any[]): StoreAction => {
  return {
    type: ActionTypes.SET_TSM_FILTER_FIELDS,
    payload: filterFields
  }
}
