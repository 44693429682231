import { FC, useState } from 'react'
import { Button, Checkbox, message, Radio } from 'antd'
import { batchReviewQje } from '../../services/QjeManagerApi'
import {
  setIsOnEditReviewed,
  setReviewedEditKey
} from '../../context/actions/qjeAction'
import { useSelector, useDispatch } from 'react-redux'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckOutlined } from '@ant-design/icons'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'
interface QjeReviewedBulkUpdateComponentProps {
  initialValue: number
  reload: () => any
  row: any
  index: number
}

const QjeReviewedBulkUpdateComponent: FC<
  QjeReviewedBulkUpdateComponentProps
> = ({ initialValue, reload, row, index }): JSX.Element => {
  // useDispatch - for updating the global state
  const dispatch = useDispatch()

  // const selectedForBulkUpdate = useSelector(
  //   (state: any) => state.allQjes.selectedForBulkUpdate
  // )
  const selectedLineGroups = useSelector(
    (state: any) => state.allQjes.selectedLineGroups
  )
  const isOnEditReviewed = useSelector(
    (state: any) => state.allQjes.isOnEditReviewed
  )
  const reviewedEditKey = useSelector(
    (state: any) => state.allQjes.reviewedEditKey
  )

  const [value, setValue] = useState<number>(initialValue)
  const [willUpdateAllSelected, setWillUpdateAllComponents] =
    useState<boolean>(false)

  const RP = useRolesAndPermissions()

  function generatePayload(): { line_group: string; reviewed: number }[] {
    let payload: { line_group: string; reviewed: number }[] = []
    if (willUpdateAllSelected) {
      selectedLineGroups.forEach((e: any) => {
        payload = [
          ...payload,
          {
            line_group: e,
            reviewed: value
          }
        ]
      })
    } else {
      payload = [{ line_group: reviewedEditKey, reviewed: value }]
    }
    return payload
  }

  async function onClickApply() {
    dispatch(setReviewedEditKey(''))
    dispatch(setIsOnEditReviewed([]))

    try {
      let data: any = await batchReviewQje('je', generatePayload())
      message.success(data.data.message)
      await reload()
    } catch (err) {
      console.log('error', err)
      message.error('An error occurred. Try again')
    }
  }

  function onClickCancel() {
    dispatch(setReviewedEditKey(''))
    dispatch(setIsOnEditReviewed([]))
  }

  async function updateReviewedStatus(
    lineGroup: string,
    reviewedStatus: number
  ) {
    try {
      let data: any = await batchReviewQje('je', [
        { line_group: lineGroup, reviewed: reviewedStatus }
      ])
      message.success(data.data.message)
      await reload()
      dispatch(setReviewedEditKey(''))
      dispatch(setIsOnEditReviewed([]))
    } catch (err) {
      console.log('error', err)
      message.error('An error occurred. Try again.')
    }
  }

  return selectedLineGroups.length > 1 &&
    selectedLineGroups.includes(row.line_group) &&
    reviewedEditKey === row.line_group ? (
    <div className='bulk-column'>
      <Radio.Group
        data-testid={`qje-review-jeg_reviewed-bulk-column-radio-group-${index}`}
        data-cy={`qje-review-jeg_reviewed-bulk-column-radio-group-${index}`}
        onChange={() => setValue(value === 1 ? 0 : 1)}
        defaultValue={value}
      >
        <Radio.Button
          value={1}
          data-testid={`qje-review-jeg_reviewed-bulk-column-yes-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-column-yes-${index}`}
        >
          Yes
        </Radio.Button>
        <Radio.Button
          value={0}
          data-testid={`qje-review-jeg_reviewed-bulk-column-no-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-column-no-${index}`}
        >
          No
        </Radio.Button>
      </Radio.Group>
      <div className='checkbox-lbl'>
        <Checkbox
          data-testid={`qje-review-jeg_reviewed-bulk-update-apply-all-checkbox-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-update-apply-all-checkbox-${index}`}
          defaultChecked={false}
          onChange={() => setWillUpdateAllComponents(!willUpdateAllSelected)}
        />
        <label
          data-testid={`qje-review-jeg_reviewed-bulk-update-label-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-update-label-${index}`}
        >
          Update {selectedLineGroups.length} selected items
        </label>
      </div>
      <div className='bulk-buttons'>
        <Button
          size='small'
          onClick={onClickCancel}
          ghost
          data-testid={`qje-review-jeg_reviewed-bulk-update-cancel-btn-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-update-cancel-btn-${index}`}
        >
          Cancel
        </Button>
        <Button
          size='small'
          onClick={onClickApply}
          data-testid={`qje-review-jeg_reviewed-bulk-update-apply-btn-${index}`}
          data-cy={`qje-review-jeg_reviewed-bulk-update-apply-btn-${index}`}
        >
          Apply
        </Button>
      </div>
    </div>
  ) : isOnEditReviewed.includes(row.line_group) ? (
    <div className='qje-column'>
      {row.g_status !== 'Posted' ? (
        <div
          style={{
            margin: 'auto',
            display: 'block'
          }}
        >
          <Checkbox
            data-testid={`qje-review-je-g_reviewed-inline-checkbox-${index}`}
            data-cy={`qje-review-je-g_reviewed-inline-checkbox-${index}`}
            defaultChecked={row.g_reviewed === 1}
            disabled={!RP.USER_HAS_REVIEWED_FLAG_QJE_PERMISSION}
            onChange={(e: CheckboxChangeEvent): void => {
              const isChecked = e.target.checked
              updateReviewedStatus(row.line_group, isChecked ? 1 : 0)
            }}
          />
        </div>
      ) : (
        row.g_reviewed === 1 && (
          <div
            data-testid={`qje-review-je-g_reviewed-checkoutlined-${index}`}
            data-cy={`qje-review-je-g_reviewed-checkoutlined-${index}`}
            style={{
              margin: 'auto',
              display: 'block'
            }}
          >
            <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
          </div>
        )
      )}
    </div>
  ) : (
    <div
      data-testid={`qje-review-je-g_reviewed-${index}`}
      data-cy={`qje-review-je-g_reviewed-${index}`}
      style={{ width: '100%' }}
      className='qje-column'
      onClick={(event: any) => {
        switch (event.detail) {
          case 1: {
            dispatch(setIsOnEditReviewed([]))
            break
          }
          case 2: {
            // The ‘Reviewed’ value should no longer be allowed to be changed once the QJE has been posted.
            if (row.g_status === 'Unposted') {
              dispatch(setIsOnEditReviewed([row.line_group]))
              dispatch(setReviewedEditKey(row.line_group))
            }
            break
          }
          default:
            break
        }
      }}
    >
      {row.g_reviewed === 1 ? 'Yes' : 'No'}
    </div>
  )
}

export default QjeReviewedBulkUpdateComponent
