import { FC, useEffect } from 'react'
import { Tabs } from 'antd'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'
import {
  setIsOnEditReviewed,
  setReviewedEditKey,
  setSelectedLineGroups
} from '../context/actions/qjeAction'
import { useDispatch } from 'react-redux'

import useCompanySettings from '../hooks/useCompanySettings'

const Qje: FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { data } = useCompanySettings()

  const menuItems =
    data && !data.display_review_po_closure
      ? [
          {
            label: 'Review JE',
            key: 'review-je'
          }
        ]
      : [
          {
            label: 'Review JE',
            key: 'review-je'
          },
          {
            label: 'Review PO Closure',
            key: 'review-po-closure'
          }
        ]

  useEffect(() => {
    if (['/qje', '/qje/'].includes(location.pathname))
      navigate('/qje/review-je')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div data-testid='qje-page' data-cy='qje-page'>
      <Tabs
        data-testid='qje-subtabs'
        data-cy='qje-subtabs'
        tabBarStyle={{ margin: 0 }}
        type='card'
        items={menuItems}
        onChange={(e) => {
          navigate(`/qje/${e}`)
          dispatch(setIsOnEditReviewed([]))
          dispatch(setReviewedEditKey(''))
          dispatch(setSelectedLineGroups([]))
        }}
        activeKey={
          location.pathname.includes('review-je')
            ? 'review-je'
            : 'review-po-closure'
        }
      />
      <Outlet />
    </div>
  )
}

export default Qje
