import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import { TApiData, TApiColumns } from '../types/api'

type TResponse = {
  data: TApiData[]
  headers: TApiColumns
}

const useFetchData = (view: string, period: string) => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useQuery<TResponse>({
    queryKey: ['review-center', view, period],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.review_center,
          endpoint: `/api/review-center/poc/${view}/${period}`,
          method: 'GET'
        }
      }),
    enabled: !!view && !!period
  })
}

export default useFetchData
