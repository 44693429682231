import { useEffect, useState } from 'react'
import { FC } from 'react'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import {
  getTransactions,
  getGlobalSettings,
  exportTxn
} from '../services/TxnManagerAPI'
import { formatDate, getCurrentPeriod } from '../utils/Date'
import TxnStatusEnum from '../utils/enums/TxnStatusEnum'
import GDynamicTable from '../components/gappify/GDynamicTable'
import GDropdownBtn from '../components/gappify/GDropdownBtn'
import GResultModal from '../components/gappify/GResultModal'
import Transaction from '../types/Transaction'
import download from 'downloadjs'
import type { AxiosError } from 'axios'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'

const TransactionPage: FC = (): JSX.Element => {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [pageSize, setPageSize] = useState<number | undefined>(50)
  const [totalItems, setTotalItems] = useState<number | undefined>()
  const [txnFilterLabel, setTxnFilterLabel] = useState('All')
  const [periodFilter, setPeriodFilter] = useState({
    period: getCurrentPeriod().startOf('month').format('YYYY-MM')
  })
  const [isLoading, setIsLoading] = useState(true)
  const [showHeader, setShowHeader] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [columns, setColumns] = useState([])
  const [typeFilter, setTypeFilter] = useState({})
  const [columnFilter, setColumnFilter] = useState({})
  const [optionsList, setOptionsList] = useState({})

  const RP = useRolesAndPermissions()

  const getTransactionList = async (params: any) => {
    const response = await getTransactions(params)

    if (response && response.data) {
      setTransactions(response.data)
      setTotalItems(parseInt(response.headers['x-total-count']))
      setShowHeader(true)
    }

    setIsLoading(false)
  }

  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    const order = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    const params = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort:
        sorter && sorter.field && `${sorter.field && sorter.field}:${order}`,
      ...periodFilter,
      ...typeFilter,
      ...columnFilter
    }

    setIsLoading(true)
    setPageSize(pagination.pageSize)
    getTransactionList(params)
  }

  const handleFilterChange = async (values: any, allValues: any) => {
    let newValues = allValues
    Object.keys(allValues).forEach((key) => {
      if (key === 'created_at' || key === 'updated_at') {
        if (allValues[key]) {
          newValues[key] = [
            formatDate(allValues[key][0]),
            formatDate(allValues[key][1])
          ]
        }
      }
    })

    const params = {
      ...newValues,
      ...typeFilter,
      ...periodFilter
    }
    setColumnFilter(newValues)
    setIsLoading(true)
    getTransactionList(params)
  }

  const handleOnClickPeriod = (item: any) => {
    const period = {
      period: moment(item.key, 'YYYY-MM').format('YYYY-MM')
    }

    const params = {
      ...period,
      ...typeFilter,
      ...columnFilter
    }

    setPeriodFilter(period)
    setIsLoading(true)
    getTransactionList(params)
  }

  const handleOnClickTxnFilter = (item: any) => {
    const type = item.key === 'CALC' ? ['CALC', 'MAN', 'IMP'] : item.key
    const filter = { app_type: type }

    const params = {
      ...filter,
      ...periodFilter,
      ...columnFilter
    }

    if (item.key === 'CALC') {
      setTxnFilterLabel('Calculated/Manual/Imported Accruals')
    } else if (item.key === 'VDAC') {
      setTxnFilterLabel('Vendor Accruals')
    } else if (item.key === 'OPEN') {
      setTxnFilterLabel('Internal Accruals')
    } else {
      setTxnFilterLabel('All Lines')
    }

    setTypeFilter(filter)
    setIsLoading(true)
    getTransactionList(params)
  }

  const handleClickExport = async (item: any) => {
    let fileType = item.key
    try {
      const params = {
        ...periodFilter,
        ...typeFilter,
        flag: 'consolidated'
      }

      const response = await exportTxn(fileType, params)
      console.log('response', response)
      const content = response?.headers['content-type']
      download(
        response?.data,
        `Transactions-${periodFilter.period}.${fileType}`,
        content
      )
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const handleOnClickReset = () => {
    const params = {
      ...typeFilter,
      ...periodFilter
    }

    getTransactionList(params)
  }

  const handleOnClickModalBtn = () => {
    setIsModalVisible(false)
  }

  const getSettings = async () => {
    const response = await getGlobalSettings()
    const list: any = []

    if (response && response.data) {
      const columnHeaders = response.data.transaction_columns
      const statusOptions = response.data['transaction.status']
      const formOptions = response.data['transaction.form_name']
      const usersOptions = response.data['transaction.users']

      setOptionsList({
        status: statusOptions,
        form_name: formOptions,
        user_modified_by: usersOptions
      })

      columnHeaders.forEach((item: any) => {
        if (item.api_name === 'transaction_number') {
          list.push({
            ...item,
            width: 150,
            render: (value: string, row: any) => (
              <span
                style={
                  !RP.USER_HAS_VIEW_TRANSACTION_PERMISSION
                    ? {
                        cursor: 'not-allowed'
                      }
                    : undefined
                }
              >
                <a
                  data-testid={`txn-page-transaction-lines-btn-${row.id}`}
                  data-cy={`txn-page-transaction-lines-btn-${row.id}`}
                  href={`/transactions/${row.id}/lines`}
                  target='_blank'
                  rel='noreferrer'
                  style={
                    !RP.USER_HAS_VIEW_TRANSACTION_PERMISSION
                      ? {
                          pointerEvents: 'none'
                        }
                      : undefined
                  }
                >
                  {value}
                </a>
              </span>
            )
          })
        } else if (item.api_name === 'status') {
          list.push({
            ...item,
            width: 100,
            render: (value: number) => (
              <p className='status-text'>{TxnStatusEnum.getLabelById(value)}</p>
            )
          })
        } else {
          list.push({ ...item })
        }
      })
    }
    setColumns(list)
  }

  // const truncateString = (str: string, n: number = 60) => {
  //   return str.length > n ? str.slice(0, n - 1) + '...' : str
  // }

  useEffect(() => {
    getSettings()
    getTransactionList(periodFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className='txn-manager-container parent-txn-ctr'
      data-testid='txn-page'
      data-cy='txn-page'
    >
      <GDynamicTable
        isServerSideSort={true}
        columnHeader={columns}
        dataSource={transactions}
        hasColumnFilters={false}
        hasGlobalFilter
        isDraggableSort={false}
        loading={isLoading}
        pageSize={pageSize}
        totalItems={totalItems}
        onChange={handleTableChange}
        onFilterChange={handleFilterChange}
        showHeader={showHeader}
        onClickPeriod={handleOnClickPeriod}
        onClickExport={handleClickExport}
        hasExportBtn
        hasExportPermission={RP.USER_HAS_TXN_EXPORT_PERMISSION}
        hasPDFExport={false}
        hasExcelExport={false}
        columnFilterInitValues={{
          created_at: undefined,
          updated_at: undefined
        }}
        optionsList={optionsList}
        leftChild={
          <GDropdownBtn
            dataTestId='txn-page-filter-dropdown'
            dataCy='txn-page-filter-dropdown'
            menuItems={[
              {
                label: 'All',
                key: ''
              },
              {
                label: 'Vendor Accruals',
                key: 'VDAC'
              },
              {
                label: 'Internal Accruals',
                key: 'OPEN'
              },
              {
                label: 'Calculated/Manual/Imported Accruals',
                key: 'CALC'
              }
            ]}
            btnText={txnFilterLabel}
            children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            onClick={handleOnClickTxnFilter}
            defaultSelectedKeys={['']}
          />
        }
        onClickReset={handleOnClickReset}
        showTxnFilter
      />
      <GResultModal
        title={'Generating Report'}
        status={'success'}
        subTitle={'Your report will be emailed to you shortly.'}
        onClickOk={handleOnClickModalBtn}
        visible={isModalVisible}
      />
    </div>
  )
}

export default TransactionPage
