/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** hooks imports */
import useApiService from '../../../hooks/useApiService'

/** services imports */
import useTxnService from '../../../services/useTxnService'

/** zustand store imports */
import useUploadFileModalStore from '../manager/useUploadFileModalStore'

// =================================================================
export default function useDownloadCSV() {
  /** hooks */
  const { msUrl } = useApiService()

  /** services */
  const { txnApi } = useTxnService()

  /** zustand store */
  const { source } = useUploadFileModalStore()

  return useQuery({
    queryKey: ['download-csv', source],
    queryFn: () =>
      txnApi({
        options: {
          baseURL: msUrl.txn_manager,
          endpoint: `/api/source-csv/${source}`,
          method: 'GET',
          responseType: 'arraybuffer'
        }
      }),
    enabled: false
  })
}
