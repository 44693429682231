// ** AntD Imports
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'

// ** Types Imports
import ClickParam from '../types/ClickParam'

// ** Hooks Imports
import useAPI from './useAPI'

// =================================================================
export default function useExport() {
  // ** Hooks **
  const { exportCSV } = useAPI()

  // ** Export Menu ================================================
  const getExportMenu = () => {
    const menu: MenuItemType[] = [
      {
        label: 'CSV',
        key: 'csv'
      }
    ]

    return menu
  }

  // ** Export Function ============================================
  const handleExportClick = (param?: ClickParam) => {
    if (param?.key === 'csv') {
      exportCSV('.csv', 'text/csv')
    }
  }

  return {
    getExportMenu,
    handleExportClick
  }
}
