import { FC, FormEvent, SetStateAction, useState } from 'react'
import { Modal, Input } from 'antd'
import { callPostApi } from '../services/GenericAPI'
import type { AxiosError } from 'axios'

interface TxnLineNudgeAllModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  transactionId: string | undefined
  url: string
  onSuccess: () => void
  onError: () => void
}

const TxnLineNudgeAllModal: FC<TxnLineNudgeAllModalProps> = ({
  visible,
  setModalVisible,
  transactionId,
  url,
  onSuccess,
  onError
}: TxnLineNudgeAllModalProps): JSX.Element => {
  const [additionalMessage, setAdditionalMessage] = useState<string>('')

  const submitNudgeAll = async (): Promise<void> => {
    const params = {
      nudge_message: additionalMessage,
      nudge_selected: false,
      transaction_id: parseInt(transactionId!)
    }

    try {
      await callPostApi(url, params)
      setAdditionalMessage('')
      onSuccess()
    } catch (error: any) {
      const { response } = error as AxiosError
      console.log(response)
      setAdditionalMessage('')
      onError()
    }
  }

  const handleAdditionalMessageChange = (
    e: FormEvent<HTMLTextAreaElement>
  ): void => {
    const value = e.currentTarget.value
    setAdditionalMessage(value)
  }

  const handleModalOnCancel = (): void => {
    setAdditionalMessage('')
    setModalVisible(false)
  }

  return (
    <Modal
      data-testid='txn-line-nudge-all-modal'
      data-cy='txn-line-nudge-all-modal'
      title='Nudge All'
      open={visible}
      onCancel={handleModalOnCancel}
      onOk={submitNudgeAll}
      okButtonProps={{
        shape: 'round'
      }}
      okText='Submit'
      cancelButtonProps={{
        shape: 'round'
      }}
      destroyOnClose
    >
      <div>
        <label>Additional message</label>
        <Input.TextArea
          data-testid='txn-line-nudge-all-modal-additional-msg-input-field'
          data-cy='txn-line-nudge-all-modal-additional-msg-input-field'
          size='middle'
          placeholder='A standard reminder will be sent. Add any additional custom message here.'
          onChange={handleAdditionalMessageChange}
        />
      </div>
    </Modal>
  )
}

TxnLineNudgeAllModal.defaultProps = {
  visible: false
}

export default TxnLineNudgeAllModal
