// ** Third Party Imports
import axios from 'axios'

// ** API Services Imports
import UserActivationAPI from '../services/UserActivationAPI'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import {
  setErrorMessages,
  setInvalidTokenMessage,
  setIsSSO,
  setIsTokenValid,
  setResponseMessage
} from '../redux/reducer'

// ** Helper Imports
import handleNestedValues from '../helper/handleNestedValues'

// =================================================================
export default function useAPI() {
  const dispatch = useDispatch()
  const { getUserActivateTokenValidation, postUserActivation } =
    UserActivationAPI()

  const checkUserToken = async (token: string | undefined) => {
    try {
      const response = await getUserActivateTokenValidation({
        options: {
          token: token,
          endpoint: '/validate-activation'
        }
      })
      if (response.status === 200) {
        dispatch(setIsTokenValid(true))
        dispatch(setIsSSO(response?.data.data.is_sso))
        dispatch(setInvalidTokenMessage(response?.data.message))
      }
    } catch (error: unknown) {
      dispatch(setIsTokenValid(false))
      if (axios.isAxiosError(error)) {
        const errorMessage = (error.response?.data as any)?.message
        dispatch(setInvalidTokenMessage(errorMessage))
      } else if (error instanceof Error) {
        dispatch(setInvalidTokenMessage(error.message))
      }
    }
  }

  const postForm = async (formData: FormData) => {
    try {
      const response = await postUserActivation({
        options: {
          endpoint: '/activate-update'
        },
        data: formData
      })
      if (response.status === 200) {
        dispatch(setResponseMessage(response.data))
      }
    } catch (error: any) {
      const errorList = handleNestedValues(error?.response.data)
      dispatch(setErrorMessages(errorList))
    }
  }

  return {
    checkUserToken,
    postForm
  }
}
