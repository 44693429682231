// ** React Imports
import React, { useEffect, useState } from 'react'

// ** AntD Imports
import { Checkbox, Skeleton, Table } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'

// ** Data Imports
import useIncreasingSpend from '../../mock/IncreasingSpendData'

// ** Hooks Imports
import useAnalytics from '../../hooks/useAnalytics'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// ** Type/Enum Imports
import { TIncreasingSpend } from '../../types/IncreasingSpendDataType'
import { SuggestedActions } from '../../constants/IncreasingSpend'
import { EReportTypes } from '../../constants/Globals'

// ** Style Imports
import styles from '../../styles/non-compliant-data.module.css'

// =================================================================
const formatDataSource = (data: TIncreasingSpend[]) => {
  let finalData = data

  /* 
    ! NOTE: 
    * This condition is a workaround because API return format varies depending on the current page
    * IF page = 1, data key is an Array
    * ELSE, data key is an Object
    
    ? NOTE:
    * Added work around logic to have a unique key per row
    * This will be used in handling selected/checked rows per page
  */
  if (!data.length) {
    finalData = Object.entries(data).map(([index, value]) => {
      return { ...value, key: Number(index) }
    })
  } else {
    finalData = finalData.map((value, index) => {
      return { ...value, key: index }
    })
  }

  return finalData
}

/**
 * ? Returns TRUE if value is included in SuggestedActions enum
 */
const isRowCheckEnabled = (action: string) => {
  const allActions = Object.values(SuggestedActions)
  return allActions.includes(action as unknown as SuggestedActions)
}

const IncreasingSpendTable = () => {
  // ** Data **
  const { columns } = useIncreasingSpend()

  // ** Hooks **
  const { getTableIncreasingSpend } = useAnalytics({
    responseType: undefined
  })
  const { isLoading, isRefetching, data } = getTableIncreasingSpend

  // ** State **
  const [dataSource, setDataSource] = useState<TIncreasingSpend[] | []>([])

  const {
    setPageIncreasingSpend,
    setSortIncreasingSpend,
    increasingSpendSelectedRowKeys,
    setIncreasingSpendSelectedRowKeys,
    setIncreasingSpendSelectedRows,
    setIncreasingSpendHeader,
    setReportType
  } = useAnalyticsStore()

  // ** Table row selection **
  const rowSelection: TableRowSelection<[]> = {
    selectedRowKeys: increasingSpendSelectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows) => {
      setIncreasingSpendSelectedRowKeys(newSelectedRowKeys)
      setIncreasingSpendSelectedRows(selectedRows)
    },
    renderCell: (checked, record: any, index, originNode) => {
      if (isRowCheckEnabled(record.suggested_action)) {
        return originNode
      }
      return <Checkbox checked={false} disabled />
    }
  }

  const tablePagination = {
    pageSize: data?.data?.per_page,
    total: data?.data?.total,
    showSizeChanger: false,
    current: data?.data?.current_page,
    defaultCurrent: 1,
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]}-${range[1]} of ${total}`
  }

  useEffect(() => {
    setReportType(EReportTypes.RisingExpenses)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data) {
      setDataSource(formatDataSource(data?.data?.data))

      // Set period header label
      setIncreasingSpendHeader(data?.increasing_spend_header)

      // Clear selected rows to refresh data
      setIncreasingSpendSelectedRowKeys([])
      setIncreasingSpendSelectedRows([])
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <div className='data-table' style={{ marginTop: 0 }}>
      <Skeleton
        data-testid='skeleton-analytics'
        active
        loading={isLoading || isRefetching}
        title={{ width: '100%' }}
        paragraph={{ rows: 15 }}
        style={{ padding: 20 }}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey='key'
          pagination={tablePagination}
          onChange={(pagination, filters, sorter: any) => {
            // Pagination change handler
            setPageIncreasingSpend(pagination.current || 1)

            // ** Normal Sorting **
            if (sorter.field !== undefined) {
              const column = sorter.columnKey.toString()
              const order = sorter.order === 'ascend' ? 'asc' : 'desc'
              setSortIncreasingSpend(`${column}:${order}`)
            }

            // ** Logic for reseting selection state upon pagination **
            if (increasingSpendSelectedRowKeys.length > 0) {
              setIncreasingSpendSelectedRowKeys([])
            }
          }}
          sortDirections={['ascend', 'descend', 'ascend']}
          loading={isLoading || isRefetching}
          className='table'
          scroll={{ x: 'max-content', y: 680 }}
          rowSelection={rowSelection}
          rowClassName={(record) => {
            return isRowCheckEnabled(record.suggested_action)
              ? ''
              : styles.excludedRow
          }}
          bordered
        />
      </Skeleton>
    </div>
  )
}

export default IncreasingSpendTable
