// ** Ant Design Icons **
import {
  CopyOutlined,
  DeleteOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  PlusOutlined
} from '@ant-design/icons'

// ** Font Awesome Icons **
import { faCheck, faFileLines } from '@fortawesome/free-solid-svg-icons'

// ** Ant Design Components **
import {
  Checkbox,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  TableColumnGroupType,
  TableColumnType,
  Tooltip
} from 'antd'

// ** React and React Router **
import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** Custom Components **
import AddMoreModal from '../components/AddMoreModal'
import ConfirmCreateQjeModal from '../components/ConfirmCreateQjeModal'
import ConfirmNoQjeModal from '../components/ConfirmNoQjeModal'
import ConfirmSubmitEditModal from '../components/ConfirmSubmitEditModal'
import GButton from '../components/gappify/GButton'
import GDynamicTable from '../components/gappify/GDynamicTable'
import GIcon from '../components/gappify/GIcon'
import OverrideModal from '../components/OverrideModal'
import ProcessingCreateQjeModal from '../components/ProcessingCreateQjeModal'
import SuccessCreateQjeModal from '../components/SuccessCreateQjeModal'
import AddNewLineOptionModal from '../components/txn-manager/AddNewLineOptionModal'
import TxnLineCalculationModal from '../components/TxnLineCalculationModal'
import TxnLineLookupModal from '../components/TxnLineLookupModal'
import TxnLineNudgeAllModal from '../components/TxnLineNudgeAllModal'
import TxnLineNudgeSelectedModal from '../components/TxnLineNudgeSelectedModal'
import TxnLinePassbackModal from '../components/TxnLinePassbackModal'
import TxnLinesActionTabsPanel from '../components/TxnLinesActionTabsPanel'
import TxnLinesHistoryModal from '../components/TxnLinesHistoryModal'
import TxnLinesNewHistoryModal from '../components/TxnLinesNewHistoryModal'
import AttachmentsModal from '../components/AttachmentsModal'
import GTransactionLinesActions from '../components/gappify/GTransactionLinesActions'
import TrdLinesFilter from '../components/txn-manager/TrdLinesFilter'
// import TrdLinesFilterFrd from '../components/txn-manager/TrdLinesFilterFrd'
import Filter from '../components/filteringNew'
import CopyAccrualFormLinkModal from 'src/components/CopyAccrualFormLinkModal'

// ** Zustand Imports **
import { useGlobalStore } from '../global/global-store'
import useGQuickSearchBarStore from '../components/gappify/GQuickSearchBar/manager/useGQuickSearchBarStore'

// ** React Query **
import TxnEnableFeaturesData from './Settings/pages/TxnEnableFeatures/data/TxnEnableFeaturesData'

// ** API Services **
import { qjeStore } from '../services/QjeManagerApi'
import {
  callDeleteApi,
  callGetApi,
  callPostApi,
  callPutApi
} from '../services/GenericAPI'
import { getTrdLineExport, getTrdTransactions } from '../services/TrdManagerAPI'
import {
  deleteSavedFiltersById,
  getGlobalSettings,
  getSavedFilters,
  getTransaction,
  getTransactionSettings,
  getTrdEmailTracker,
  getTrdTxnForm,
  saveFilter,
  getChildren
} from '../services/TxnManagerAPI'

// ** Other Dependencies **
import download from 'downloadjs'
import moment from 'moment'

// ** Enums and Utilities **
import {
  getSubsidiary,
  handleOnSaveEdit,
  setFxRateAmount,
  getTxnColumns,
  getEmailToken
} from '../utils/txn/trd-utils'
import useGetTRDSearchableFields from '../hooks/useGetTRDSearchableFields'
import TxnLineLevelActions from '../helpers/constants/TxnLineLevelActions'
import {
  formatDate,
  getCurrentPeriod,
  getDateTimeMomentFormat
} from '../utils/Date'
import TxnLineCalculationEnum from '../utils/enums/TxnLineCalculationEnum'
import TxnLineSourceEnum from '../utils/enums/TxnLineSourceEnum'
import TxnLineStatusEnum from '../utils/enums/TxnLineStatusEnum'
import getTracker from '../utils/txn/getTracker'
import { getTransactionHistory } from '../services/HistoryAPI'

// ** Types **
import type { MenuProps } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import type { TablePaginationConfig } from 'antd/lib/table'
import type { AxiosError } from 'axios'
import type AccrualType from '../types/AccrualType'
import type AppType from '../types/AppType'
import type DateTimeFormat from '../types/DateTimeFormat'
import type InvoiceDetail from '../types/InvoiceDetail'
import type TrackerFields from '../types/TrackerFields'
import type Transaction from '../types/Transaction'
import type TransactionField from '../types/TransactionField'
import type TransactionLine from '../types/TransactionLine'
import type { LineProperties } from '../types/TransactionLine'
import type TransactionProperties from '../types/TransactionProperties'
import type TxnLinesReqParams from '../types/TxnLinesReqParams'
import type TRDTransactionLines from '../types/TRDTransactionLines'

// ** Zustand Imports
import useTxnEnableFeatureStore from './Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'
import useTxnEnableFeatures from './Settings/pages/TxnEnableFeatures/hooks/useTxnEnableFeatures'
import NetExpenseModal from '../components/review-center/modals/NetExpenseModal'
import useTxnGlobalSettings from '../hooks/useTxnGlobalSettings'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

// ** Constants **
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

const TransactionLines: FC<TRDTransactionLines> = ({
  hasTransactionPanel,
  isExpandable,
  hasSelectAll,
  hasActionColumns,
  hasNetExpenseAmount,
  actionGetPopupContainer
}): JSX.Element => {
  const navigate = useNavigate()
  const { txnId, periodHeader } = useParams()
  const { txnChildren, setTxnChildren } = useGlobalStore()

  const {
    showImportedPopup,
    showTransactionsPopup,
    showCalculatedPopup,
    setHistoryData,
    setModalDelay,
    setHistoryModalVisible,
    showDelay,
    showHistory,
    historyData,
    showInternalPopup,
    showManualPopup,
    showVendorPopup,
    subsidiaryIdParams,
    departmentIdParams,
    glaccountIdParams,
    vendorIdParams,
    vendorNameParams,
    periodParams,
    typeParams,
    keyParams,
    viewParams,
    setIsCreateQjeCalled,
    setIsEditRowCalled,
    errorFields,
    setErrorFields,
    showQuickActions,
    setNewHistoryModalVisible,
    showNewHistoryModal
  } = useGlobalStore()
  const isFlagEnabled = useIsFlagEnabled()

  const [id] = useState('1')
  const [transaction] = useState<Transaction | null>(null)
  const [transactionProperties, setTransactionProperties] =
    useState<TransactionProperties | null>(null)

  const [transactionLines, setTransactionLines] = useState<TransactionLine[]>(
    []
  )
  const [columns, setColumns] = useState<TransactionField[]>([])
  const [columnHeaders, setColumnHeaders] = useState<TransactionField[]>([])
  const [columnHeadersAll, setColumnHeadersAll] = useState<TransactionField[]>(
    []
  )
  const [isLoading, setIsLoading] = useState(true)
  const [showHeader, setShowHeader] = useState(false)
  const [pageSize, setPageSize] = useState<number | undefined>(50)
  const [totalItems, setTotalItems] = useState<number | undefined>()

  const [isPassbackModalVisible, setIsPassbackModalVisible] =
    useState<boolean>(false)
  const [passbackUrl, setPassbackUrl] = useState<string>('')
  const [passbackVendorName, setPassbackVendorName] = useState<string>('')
  const [passbackEmail, setPassbackEmail] = useState<string>('')

  const [isNudgeAllModalVisible, setIsNudgeAllModalVisible] =
    useState<boolean>(false)
  const [isAddMoreModalVisible, setIsAddMoreModalVisible] =
    useState<boolean>(false)

  const [txnLineLookUpData, setTxnLineLookUpData] = useState<InvoiceDetail[]>(
    []
  )
  const [isTxnLineLookupModalLoading, setIsTxnLineLookupModalLoading] =
    useState<boolean>(false)
  const [isTxnLineLookupModalVisible, setIsTxnLineLookupModalVisible] =
    useState<boolean>(false)

  const [isNudgeSelectedModalVisible, setIsNudgeSelectedModalVisible] =
    useState<boolean>(false)

  const [txnLineId, setTxnLineId] = useState<number | null>(null)
  const [trackers, setTrackers] = useState<TrackerFields[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [checkboxCount, setCheckboxCount] = useState<number>(0)

  const [attachments, setAttachments] = useState<Array<any>>([])
  const [currentPage, setCurrentPage] = useState<number | undefined>(1)
  const [dateTimeFormat, setDateTimeFormat] = useState<DateTimeFormat>()
  const [showOverride, setOverrideModalVisible] = useState(false)
  const [overrideData, setOverrideData] = useState<any>({})

  const [columnFilter, setColumnFilter] = useState<any>()
  const [optionsList, setOptionsList] = useState<any>(null)

  const [isConfirmCreateQjeModalVisible, setIsConfirmCreateQjeModalVisible] =
    useState<boolean>(false)
  const [isConfirmNoQjeModalVisible, setIsConfirmNoQjeModalVisible] =
    useState<boolean>(false)
  const [
    isProcessingCreateQjeModalVisible,
    setIsProcessingCreateQjeModalVisible
  ] = useState<boolean>(false)
  const [isConfirmSubmitEditModalVisible, setIsConfirmSubmitEditModalVisible] =
    useState<boolean>(false)
  const [saveFilterOptions, setSaveFilterOptions] = useState<Array<any>>([])
  const [showSaveFilterModal, setShowSaveFilterModal] = useState<boolean>(false)
  const [formFields, setFormFields] = useState<any>()
  const [thresholdAmount, setThresholdAmount] = useState<number>(0)
  const [displayThresholdSupport, setDisplayThresholdSupport] =
    useState<boolean>(false)
  const [invoiceDetailsRange, setInvoiceDetailsRange] = useState<number>(90)

  const [isCalculationModalVisible, setIsCalculationModalVisible] =
    useState<boolean>(false)
  const [calculationUrl, setCalculationUrl] = useState<string>('')
  const [successQjeCount, setSuccessQjeCount] = useState<number>(0)
  const [processedQjeCount, setProcessedQjeCount] = useState<number>(0)
  const [failedQjeCount, setFailedQjeCount] = useState<number>(0)
  const [isSuccessCreateQjeModalVisible, setIsSuccessCreateQjeModalVisible] =
    useState<boolean>(false)
  const [reportingCurrency, setReportingCurrency] = useState<string | null>('')
  const [filterkey, setFilterKey] = useState<number>(0)
  const [lookUpApi, setLookUpApi] = useState('')
  const [createQjeErrors, setCreateQjeErrors] = useState<string[]>([])

  const [showAttachModal, setShowAttachModal] = useState(false)
  const [lineAttachments, setLineAttachments] = useState([])
  const [vendorName, setVendorName] = useState('')
  const [periodFilter, setPeriodFilter] = useState<any>({
    g_period:
      periodHeader || getCurrentPeriod().startOf('month').format('YYYY-MM')
  })
  const [typeFilter] = useState<{ [name: string]: any }>({})
  const [editedRows, setEditedRows] = useState<any[]>([])
  const [isAddNewLineOptionModalVisible, setIsAddNewLineOptionModalVisible] =
    useState<boolean>(false)
  const [isAddNewLineOptionModalLoading, setIsAddNewLineOptionModalLoading] =
    useState<boolean>(false)
  const [trdTxnId, setTrdTxnId] = useState<number | null>(null)
  const [enabledLineTypes, setEnabledLineTypes] = useState<string[]>([])
  const [enabledLineTypesOptions, setEnabledLineTypesOptions] = useState<
    string[]
  >([])
  const [isAddNewLineBtnLoading, setIsAddNewLineBtnLoading] =
    useState<boolean>(false)
  const [isTableEditable, setIsEditable] = useState<boolean>(false)
  const [
    isTxnLinesActionTabsPanelLoading,
    setIsTxnLinesActionTabsPanelLoading
  ] = useState<boolean>(true)
  const [appKey, setAppKey] = useState<AppType | undefined>()
  const [accrualType, setAccrualType] = useState<AccrualType | undefined>()
  const [
    isVisibleCopyAccrualFormLinkModal,
    setIsVisibleCopyAccrualFormLinkModal
  ] = useState<boolean>(false)
  const canCopyLines = useRef(false)
  const canDeleteLines = useRef(false)
  const activeFilterRef = useRef({
    id: 'all',
    label: 'All Lines',
    tempPreviewId: 0
  })
  const columnSort = useRef()
  const periodFilterLabel = useRef<string | undefined>()
  const requireOverrideExp = useRef(true)
  const hasOverrideColumn = useRef(true)
  const [originalList, setOriginalList] = useState<any>([])
  const [lineType, setLineType] = useState<string>()
  const [isLoadingColumnHeaders, setIsLoadingColumnHeaders] =
    useState<boolean>(true)
  const [clickedTransactionLine, setClickedTransactionLine] =
    useState<null | TransactionLine>(null)

  const { inputText, resetInputText } = useGQuickSearchBarStore()
  const { getSearchableFields } = useGetTRDSearchableFields()

  const { data } = TxnEnableFeaturesData()
  const { getTxnEnableFeatures } = useTxnEnableFeatures()
  const {
    isRefetching,
    isSuccess: isTxnEnableFeaturesSuccess,
    data: getTxnEnableFeaturesData
  } = getTxnEnableFeatures

  const {
    priorPeriodTransaction,
    setPriorPeriodTransaction,
    considerBothDebitAndCreditValues
  } = useTxnEnableFeatureStore()
  const [isConsolidated, setIsConsolidated] = useState<boolean>(false)

  const setIsPrepaidEntryFeatureFlagEnabled = useTxnEnableFeatureStore(
    (state) => state.setNegativeAccrualAmountToPrepaidEntry
  )

  const isPrepaidEntryFeatureFlagEnabled = useTxnEnableFeatureStore(
    (state) => state.negativeAccrualAmountToPrepaidEntry
  )

  const RP = useRolesAndPermissions()

  const {
    data: txnGlobalSettings,
    isLoading: isLoadingTxnGlobalSettings,
    isRefetching: isRefetchingTxnGlobalSettings
  } = useTxnGlobalSettings()

  useEffect(() => {
    if (isTxnEnableFeaturesSuccess) {
      setIsPrepaidEntryFeatureFlagEnabled(
        getTxnEnableFeaturesData?.negative_accrual_amount_to_prepaid_entry!
      )
    }
  }, [
    isTxnEnableFeaturesSuccess,
    getTxnEnableFeaturesData,
    setIsPrepaidEntryFeatureFlagEnabled
  ])

  useEffect(() => {
    setPriorPeriodTransaction(
      data?.prior_period_information_on_transaction_lines!
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isRefetching])

  useEffect(() => {
    resetInputText()

    if (periodHeader) {
      /**
       * ENG-7752
       * Introduced new url
       * Reads if periodHeader is included in the URL and sets as the initial value
       */
      const g_period = moment(periodHeader).format('YYYY-MM')
      const thisPeriodLabel = moment(g_period).format('MMMM YYYY')
      periodFilterLabel.current = thisPeriodLabel
      setPeriodFilter({ g_period })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //get global settings time format
  useEffect(() => {
    const getDateTimeFormat = async (): Promise<void> => {
      setIsTxnLinesActionTabsPanelLoading(true)
      try {
        const { data } = await getGlobalSettings()
        const userDateTimeFormat = data['transactions.user.profile.settings']
        const featureFlags = data['transaction.feature_flags']
        const companyValues = data['transaction.company_values']
        canCopyLines.current = featureFlags['enable_line_copy']
        canDeleteLines.current = featureFlags['enable_line_delete']
        requireOverrideExp.current =
          featureFlags['require_override_explanation']

        setThresholdAmount(
          featureFlags.threshold_support_value
            ? featureFlags.threshold_support_value
            : 0
        )
        setDisplayThresholdSupport(featureFlags.display_threshold_support)
        setInvoiceDetailsRange(Number(featureFlags.invoice_details_range))
        setDateTimeFormat(userDateTimeFormat)
        setReportingCurrency(companyValues.g_reporting_currency)
        featureFlags.enable_line_add &&
          setEnabledLineTypes(featureFlags.enable_line_add)
        setIsConsolidated(featureFlags['consolidated_transaction_lines'])
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      } finally {
        setIsTxnLinesActionTabsPanelLoading(false)
      }
    }
    getDateTimeFormat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculatedParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams
  }
  const importedParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams
  }
  const internalParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams
  }

  const manualParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams
  }

  const vendorParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams
  }

  const totalParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: 'TOTALS',
    key: keyParams,
    period: periodParams,
    view: viewParams
  }

  const encodedTotalParams = btoa(JSON.stringify(totalParams))
  const encodedCalculatedParams = btoa(JSON.stringify(calculatedParams))
  const encodedImportedParams = btoa(JSON.stringify(importedParams))
  const encodedInternalParams = btoa(JSON.stringify(internalParams))
  const encodedManualParams = btoa(JSON.stringify(manualParams))
  const encodedVendorParams = btoa(JSON.stringify(vendorParams))

  const TRDQuickSearchParams = {
    search: inputText ? inputText : undefined
  }

  // Get txn lines list
  useEffect(() => {
    !txnId &&
      periodFilter === null &&
      setPeriodFilter({
        g_period: getCurrentPeriod().startOf('month').format('YYYY-MM')
      })

    if (showCalculatedPopup) {
      getTransactionLinesList({
        rc_params: encodedCalculatedParams
      })
    } else if (showImportedPopup) {
      getTransactionLinesList({
        rc_params: encodedImportedParams
      })
    } else if (showInternalPopup) {
      getTransactionLinesList({
        rc_params: encodedInternalParams
      })
    } else if (showManualPopup) {
      getTransactionLinesList({
        rc_params: encodedManualParams
      })
    } else if (showVendorPopup) {
      getTransactionLinesList({
        rc_params: encodedVendorParams
      })
    } else if (showQuickActions) {
      getTransactionLinesList({
        rc_totals_params: encodedTotalParams
      })
    } else {
      getTransactionLinesList({
        ...periodFilter,
        ...typeFilter,
        page: 1,
        paginate_count: pageSize,
        custom_filter: activeFilterRef.current.id,
        ...TRDQuickSearchParams
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnId, periodFilter, inputText])

  // Get txn data
  useEffect(() => {
    if (txnId) {
      const getTransactionData = async (): Promise<void> => {
        try {
          const { data } = await getTransaction(txnId!)
          const txnPeriod: string = data.period

          if (txnId === 'consolidated') {
            const g_period = moment(periodHeader).format('YYYY-MM')
            const periodFilterLabelFormatted =
              moment(g_period).format('MMMM YYYY')
            periodFilterLabel.current = periodFilterLabelFormatted
            setPeriodFilter({ g_period })
          }
          if (txnPeriod) {
            const g_period = moment(txnPeriod).format('YYYY-MM')
            const periodFilterLabelFormatted =
              moment(g_period).format('MMMM YYYY')
            periodFilterLabel.current = periodFilterLabelFormatted
            setPeriodFilter({ g_period })
          }
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        }
      }
      getTransactionData()
    }

    // eslint-disable-next-line
  }, [txnId])

  const fetchSavedFilters = async (form_id: number) => {
    const filterResponse = await getSavedFilters({
      form_id: form_id
    })
    let options: any[] = []

    filterResponse.data?.forEach((item: any) => {
      options.push({
        label: item.name,
        key: item.id,
        parameters: item.parameters
      })
    })
    setSaveFilterOptions(options)
  }

  // Get email tracking
  useEffect(() => {
    const getEmailTracking = async (): Promise<void> => {
      try {
        const { data } = await getTrdEmailTracker(periodFilter.g_period, txnId)

        if (Array.isArray(data)) {
          setTrackers(data)
        }
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getEmailTracking()
  }, [periodFilter.g_period, txnId])

  // Get column headers
  useEffect(() => {
    const getColumns = async (): Promise<void> => {
      try {
        const { data } = await getGlobalSettings() // TODO
        const list: TransactionField[] = getTxnColumns(
          data,
          periodFilter.g_period
        )

        const enums = data.transaction_properties?.enums
        setColumnHeaders(list)
        setColumnHeadersAll(list)
        setIsLoadingColumnHeaders(false)

        setFormFields(data.form_fields)

        setOptionsList({
          g_line_source:
            enums &&
            Object.entries(enums['transactions.lines.g_line_source']).map(
              ([key, value]) => ({
                id: value,
                value: key.replace('_', ' ')
              })
            ),
          g_status:
            enums &&
            Object.entries(enums['transactions.lines.g_status']).map(
              ([key, value]) => ({
                id: value,
                value: key.replace('_', ' ')
              })
            ),
          g_calculation:
            enums &&
            Object.entries(enums['transactions.lines.g_calculation']).map(
              ([key, value]) => ({
                id: value,
                value: key
              })
            ),
          g_override_flag: [
            {
              id: 1,
              value: 'Yes'
            },
            {
              id: 0,
              value: 'No'
            }
          ],
          g_name: data?.transaction_vendors?.g_name.map(
            (item: string, index: number) => ({ id: item, value: item })
          )
        })
      } catch (error) {
        const { response } = error as AxiosError
        console.log('error', error, response)
        setIsLoadingColumnHeaders(false)
      }
    }
    getColumns() // eslint-disable-next-line
  }, [periodFilter])
  // }, [id, transactionLines])

  /**
   * ? Hotfix for ENG-7723
   * ? Listed out all possible values for clickable accrual types - c/o Carmi
   * ? Returns true if passed type is included in the list
   */
  const isTypeClickable = (type: string) => {
    const clickableTypes = ['Percent', 'Straightline', 'Historical Average']
    return clickableTypes.includes(type)
  }

  const checkIfPercentage = (api_name: string, row: TransactionLine) => {
    /**
     * ? Ref: https://gappify.atlassian.net/browse/ENG-7723
     */
    if (api_name === 'vdac_g_accrual_amount') {
      return row.vdac_g_accrual_type === 'Percent'
    } else if (api_name === 'open_g_accrual_amount') {
      return row.open_g_accrual_type === 'Percent'
    } else if (api_name === 'man_g_accrual_amount') {
      /**
       * ? Hotfix for ENG-8014
       * ? Calculated amount column will always be clickable
       * ? Ref: https://gappify.atlassian.net/browse/ENG-8014
       */
      // return isTypeClickable(row.man_g_accrual_type!)
      return true
    }

    /**
     * ? Fallback return if g_accrual_amount / Unbilled Amount is used
     * ? Ref: https://gappify.atlassian.net/browse/ENG-7792
     */
    return isTypeClickable(row.g_accrual_type!)
  }

  useEffect(() => {
    const list: TransactionField[] = []
    console.log('Column headers:', columnHeaders.length)
    columnHeaders.forEach((item, index) => {
      const apiName = item.api_name ?? 'no-api-name'
      if (index === 0 || index === 1) {
        //fix first 2 columns
        item.fixed = 'left'
      }
      if (item.api_name === 'g_created_at') {
        list.push({
          ...item,
          width: 230,
          render: (value: string, row: TransactionLine) => {
            const emailTracker = trackers.find(
              (item) => row.token_id === item.token_id
            )

            return (
              <Row className='created-column'>
                <Col lg={18}>
                  {dateTimeFormat
                    ? value &&
                      getDateTimeMomentFormat(
                        value,
                        dateTimeFormat.date_format,
                        dateTimeFormat.time_format
                      )
                    : value && moment(value).format('MM/DD/YYYY hh:mm a')}
                </Col>
                {!isConsolidated && isLineOriginal(row.g_line_source!) && (
                  <>
                    {emailTracker && dateTimeFormat && (
                      <Col lg={1}>
                        {getTracker(
                          emailTracker!,
                          dateTimeFormat!.timezone_format
                        )}
                      </Col>
                    )}
                  </>
                )}
              </Row>
            )
          }
        })
      } else if (item.api_name === 'g_date_submitted') {
        list.push({
          ...item,
          render: (value: string, row: TransactionLine) => {
            return (
              <Row className='created-column'>
                {dateTimeFormat
                  ? value &&
                    getDateTimeMomentFormat(
                      value,
                      dateTimeFormat.date_format,
                      dateTimeFormat.time_format
                    )
                  : value && moment(value).format('MM/DD/YYYY hh:mm a')}
              </Row>
            )
          }
        })
      } else if (
        item.api_name === 'g_status' ||
        item.api_name === 'man_g_status' ||
        item.api_name === 'open_g_status' ||
        item.api_name === 'vdac_g_status'
      ) {
        list.push({
          ...item,
          render: (value: number, row: TransactionLine) => {
            const tokenId = getEmailToken(item.api_name, row)
            const emailTracker = trackers.find(
              (item) => item.token_id === tokenId
            )
            if (value) {
              const label = TxnLineStatusEnum.getLabelById(Number(value))
              if (label === 'QJE Error') {
                return (
                  <Tooltip title={row.qje_messages} color='#0b0a0a'>
                    <Row className='created-column'>
                      <Col lg={18}>{label} </Col>
                      {isConsolidated &&
                        (item.api_name === 'open_g_status' ||
                          item.api_name === 'vdac_g_status') &&
                        isLineOriginal(row.g_line_source!) &&
                        emailTracker &&
                        dateTimeFormat && (
                          <Col lg={1}>
                            {getTracker(
                              emailTracker!,
                              dateTimeFormat!.timezone_format
                            )}
                          </Col>
                        )}
                    </Row>
                  </Tooltip>
                )
              }
              return (
                <Row className='created-column'>
                  <Col lg={18}>{label} </Col>
                  {isConsolidated &&
                    (item.api_name === 'open_g_status' ||
                      item.api_name === 'vdac_g_status') &&
                    isLineOriginal(row.g_line_source!) &&
                    emailTracker &&
                    dateTimeFormat && (
                      <Col lg={1}>
                        {getTracker(
                          emailTracker!,
                          dateTimeFormat!.timezone_format
                        )}
                      </Col>
                    )}
                </Row>
              )
            }
            return <>{value}</>
          }
        })
      } else if (item.api_name === 'g_line_source') {
        list.push({
          ...item,
          render: (value: number) => {
            return <>{TxnLineSourceEnum.getLabelById(value)}</>
          }
        })
      } else if (item.api_name === 'g_override_flag') {
        list.push({
          ...item,
          render: (value: string) => {
            if (value === '1') {
              return <GIcon icon={faCheck} className='g-icon-primary' />
            }
            return <></>
          }
        })
      } else if (item.api_name === 'g_is_prepaid') {
        list.push({
          ...item,
          render: (value: number) => {
            if (value === 1) {
              return <GIcon icon={faCheck} className='g-icon-primary' />
            }
            return <></>
          }
        })
      } else if (item.api_name === 'g_nocharges') {
        list.push({
          ...item,
          render: (value: number) => {
            if (value === 1) {
              return <GIcon icon={faCheck} className='g-icon-primary' />
            }
            return <></>
          }
        })
      } else if (item.field_type === 'view_details_lookup') {
        list.push({
          ...item,
          render: (value: any, row: TransactionLine) => {
            return (
              <GButton
                btnText='View'
                size='small'
                type='primary'
                shape='round'
                disabled={
                  !row.line_properties?.actions.lookup.enabled ||
                  !RP.USER_HAS_TXN_LINE_LOOKUP_PERMISSION
                }
                onClick={() =>
                  handleOnLookUpClick(
                    row.line_properties?.actions.lookup.url! +
                      `?api_name=${apiName}`,
                    item.api_name
                  )
                }
              />
            )
          }
        })
      } else if (item.api_name === 'g_calculation') {
        list.push({
          ...item,
          render: (value: number) => (
            <>{TxnLineCalculationEnum.getLabelFromId(Number(value))}</>
          )
        })
      } else if (item.api_name === 'g_accrual_amount') {
        list.push({
          ...item,
          render: (value: string | null, row: TransactionLine) => (
            <>
              {RP.USER_HAS_LINE_CALCULATIONS_PERMISSION &&
              value &&
              row.line_properties?.actions.calculation.enabled &&
              !isTableEditable &&
              checkIfPercentage(item.api_name, row) ? (
                <>
                  <GButton
                    size='small'
                    type='link'
                    className={'btn-amount'}
                    children={
                      <div
                        style={{
                          color:
                            !row.line_properties?.actions.calculation.status ||
                            value === 'Error'
                              ? 'red'
                              : '#725bb4'
                        }}
                      >
                        {value === 'Error'
                          ? value
                          : value &&
                            parseFloat(value!)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    }
                    onClick={() => {
                      setIsCalculationModalVisible(true)
                      setCalculationUrl(
                        row.line_properties?.actions.calculation.url!
                      )
                    }}
                    disabled={!RP.USER_HAS_LINE_CALCULATIONS_PERMISSION}
                  />
                </>
              ) : (
                <div
                  style={{
                    textAlign: 'right',
                    minHeight: '20px'
                  }}
                >
                  {value &&
                    value !== 'Error' &&
                    parseFloat(value!)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
              )}
            </>
          )
        })
      } else if (
        item.api_name === 'vdac_g_accrual_amount' || // Vendor
        item.api_name === 'open_g_accrual_amount' || // Internal
        item.api_name === 'man_g_accrual_amount' // Calculated
      ) {
        // For NON Consolidated...
        if (!isConsolidated) {
          list.push({
            ...item,
            render: (value: string | null, row: TransactionLine) => (
              <>
                {RP.USER_HAS_LINE_CALCULATIONS_PERMISSION &&
                value &&
                row.line_properties?.actions.calculation.enabled &&
                !isTableEditable &&
                checkIfPercentage(item.api_name, row) ? (
                  <>
                    <GButton
                      size='small'
                      type='link'
                      className={'btn-amount'}
                      children={
                        <div
                          style={{
                            color:
                              !row.line_properties?.actions.calculation
                                .status || value === 'Error'
                                ? 'red'
                                : '#725bb4'
                          }}
                        >
                          {value === 'Error'
                            ? value
                            : value &&
                              parseFloat(value!)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      }
                      onClick={() => {
                        setIsCalculationModalVisible(true)
                        setCalculationUrl(
                          row.line_properties?.actions.calculation.url!
                        )
                      }}
                      disabled={!RP.USER_HAS_LINE_CALCULATIONS_PERMISSION}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      textAlign: 'right',
                      minHeight: '20px'
                    }}
                  >
                    {value &&
                      value !== 'Error' &&
                      parseFloat(value!)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                )}
              </>
            )
          })
        } else {
          // for CONSOLIDATED...
          let api = item.api_name
          let actionProperty =
            api === 'vdac_g_accrual_amount'
              ? 'vdac_calculation'
              : api === 'open_g_accrual_amount'
              ? 'open_calculation'
              : 'man_calculation'

          list.push({
            ...item,
            render: (value: string | null, row: TransactionLine) => {
              let action =
                (row.line_properties?.actions as any)?.[actionProperty] || {}
              return (
                <>
                  {RP.USER_HAS_LINE_CALCULATIONS_PERMISSION &&
                  value &&
                  action?.enabled &&
                  !isTableEditable &&
                  checkIfPercentage(api, row) ? (
                    <>
                      <GButton
                        size='small'
                        type='link'
                        className={'btn-amount'}
                        children={
                          <div
                            style={{
                              color:
                                !action?.status || value === 'Error'
                                  ? 'red'
                                  : '#725bb4'
                            }}
                          >
                            {value === 'Error'
                              ? value
                              : value &&
                                parseFloat(value!)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        }
                        onClick={() => {
                          setIsCalculationModalVisible(true)
                          setCalculationUrl(action?.url!)
                        }}
                        disabled={!RP.USER_HAS_LINE_CALCULATIONS_PERMISSION}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        textAlign: 'right',
                        minHeight: '20px'
                      }}
                    >
                      {value &&
                        value !== 'Error' &&
                        parseFloat(value!)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  )}
                </>
              )
            }
          })
        }
      } else if (item.api_name === 'g_accrual_amount_original') {
        list.push({
          ...item,
          render: (value) => (
            <div style={{ textAlign: 'right' }}>
              {value &&
                parseFloat(value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          )
        })
      } else if (item.api_name === 'g_reporting_amount_original') {
        list.push({
          ...item,
          render: (value) => (
            <div style={{ textAlign: 'right' }}>
              {value &&
                parseFloat(value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          )
        })
      } else if (
        item.api_name === 'g_percentage_of_completion' ||
        item.api_name === 'vdac_g_percentage_of_completion' ||
        item.api_name === 'open_g_percentage_of_completion'
      ) {
        list.push({
          ...item,
          render: (value: number) => (
            <div
              style={{
                textAlign: 'right'
              }}
            >
              {value && `${value} %`}
            </div>
          )
        })
      } else if (
        item.api_name === 'g_close_po' ||
        item.data_type === 'boolean'
      ) {
        list.push({
          ...item,
          render: (value) => {
            if (value === '1' || value === 1) {
              return 'Yes'
            }
            if (value === '0' || value === 0) {
              return 'No'
            }
            return value
          }
        })
      } else if (item.api_name === 'g_threshold_support_document') {
        list.push({
          ...item,
          render: (value: string) => (
            <div>
              {value && isValidURL(value) && !isTableEditable ? (
                <a
                  href={value}
                  target='_blank'
                  rel='noreferrer'
                  style={{ color: '#725bb4' }}
                >
                  {value}
                </a>
              ) : (
                value
              )}
            </div>
          )
        })
      } else {
        if (item.api_name === 'override_reason') {
          hasOverrideColumn.current = !item.hidden
        }
        list.push(item)
      }
    })

    list.push({
      key: 'attachments',
      api_name: 'attachments',
      label: 'Attachments',
      render: (attachments: any, row: any) => (
        <div>
          {attachments && attachments.length > 0 && (
            <div
              style={{ color: '#725bb4', cursor: 'pointer' }}
              onClick={() => {
                setLineAttachments(attachments)
                setShowAttachModal(true)
                setVendorName(row.g_name)
              }}
            >
              <GIcon icon={faFileLines} />
            </div>
          )}
        </div>
      )
    })

    if (considerBothDebitAndCreditValues && hasNetExpenseAmount) {
      setColumns([
        {
          fixed: 'left',
          key: 'netExpenseAmount',
          api_name: 'netExpenseAmount',
          label: 'Net Expense Amount',
          render: (_, row: any) => <NetExpenseModal row={row} />,
          align: 'right'
        },
        ...list
      ])
    } else setColumns(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnHeaders])
  // }, [trackers, dateTimeFormat, isTableEditable, errorFields, columnHeaders])

  useEffect(() => {
    if (!RP.USER_HAS_VIEW_TRANSACTION_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchTxnSettings = async () => {
      if (txnId) {
        try {
          const { data } = await getTransactionSettings(txnId)
          setTransactionProperties(data.transaction_properties)
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        }
      }
    }
    fetchTxnSettings()
  }, [txnId])

  const isValidURL = (text: string) => {
    try {
      new URL(text)
      return true
    } catch {
      return false
    }
  }

  const getTransactionLinesList = async (params?: TxnLinesReqParams) => {
    setIsLoading(true)
    setTransactionLines([])
    try {
      // Override custom_filter, change to 'all' if its value is empty, undefined, null or O
      if (params && 'custom_filter' in params) {
        if (!params.custom_filter) {
          params.custom_filter = 'all'
        }
      }

      const response = await getTrdTransactions({
        ...params,
        transaction_id: txnId
      })
      const boxCount: number = response.data.reduce(
        (total: number, item: any) => {
          if (
            Number(item.g_status) === TxnLineStatusEnum.SUBMITTED.id ||
            Number(item.g_status) === TxnLineStatusEnum.QJE_ERROR.id ||
            Number(item.g_status) === TxnLineStatusEnum.QJE_REJECTED.id ||
            Number(item.g_status) === TxnLineStatusEnum.NO_QJE.id
          ) {
            if (
              item.line_properties?.actions.qje.enabled ||
              item.line_properties?.actions.no_qje.enabled
            ) {
              return total + 1
            }
          }
          return total
        },
        0
      )

      setCheckboxCount(boxCount)
      const list = response?.data
      setTransactionLines(
        list?.map((item: any) => {
          return {
            ...item,
            parent: true,
            key: item.unique_line_identifier,
            children: txnChildren[item.unique_line_identifier] || []
            // overridden_by_user: item.overridden_by_user?.profile?.email || '' // Used for custom return for overridden column in table ENG-6088
            // overridden_by_user: item?.g_overridden_by
          }
        })
      )

      setOriginalList(list)
      setTotalItems(parseInt(response.headers['x-total-count']))
      setShowHeader(true)
      setErrorFields([])
      return response
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsLoading(false)
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any
  ): void => {
    const order = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    const defaultParams = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort:
        sorter && sorter.field && `${sorter.field && sorter.field}:${order}`,
      ...columnFilter,
      ...periodFilter,
      ...typeFilter,
      custom_filter: activeFilterRef.current.id,
      ...TRDQuickSearchParams
    }
    const actionParams = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort:
        sorter && sorter.field && `${sorter.field && sorter.field}:${order}`,
      ...columnFilter,
      periodParams,
      ...typeFilter,
      custom_filter: activeFilterRef.current.id,
      ...TRDQuickSearchParams
    }

    setSelectedRows([])
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    if (showCalculatedPopup) {
      getTransactionLinesList({
        ...actionParams,
        rc_params: encodedCalculatedParams
      })
    } else if (showImportedPopup) {
      getTransactionLinesList({
        ...actionParams,
        rc_params: encodedImportedParams
      })
    } else if (showInternalPopup) {
      getTransactionLinesList({
        ...actionParams,
        rc_params: encodedInternalParams
      })
    } else if (showManualPopup) {
      getTransactionLinesList({
        ...actionParams,
        rc_params: encodedManualParams
      })
    } else if (showVendorPopup) {
      getTransactionLinesList({
        ...actionParams,
        rc_params: encodedVendorParams
      })
    } else if (showQuickActions) {
      getTransactionLinesList({
        ...actionParams,
        rc_totals_params: encodedTotalParams
      })
    } else {
      getTransactionLinesList(defaultParams)
    }
    columnSort.current =
      sorter && sorter.field && `${sorter.field && sorter.field}:${order}`
    setEditedRows([])
    setErrorFields([])
  }

  const handleFilterChange = async (
    values: any,
    allValues: any
  ): Promise<void> => {
    let newValues = allValues
    Object.keys(allValues).forEach((key) => {
      if (
        key === 'g_created_at' ||
        key === 'g_date_submitted' ||
        key === 'g_due_date' ||
        key === 'g_period'
      ) {
        if (allValues[key]) {
          newValues[key] = [
            formatDate(allValues[key][0]),
            formatDate(allValues[key][1])
          ]
        }
      }
    })
    const params = {
      ...newValues,
      ...typeFilter,
      page: 1,
      paginate_count: pageSize
    }
    setCurrentPage(1)
    setColumnFilter(newValues)
    getTransactionLinesList(params)
    setSelectedRows([])
    setCheckboxCount(0)
  }

  const showPassbackModal = (
    passbackUrl: string,
    vendorName: string,
    email: string,
    txnLineId: number
  ): void => {
    setIsPassbackModalVisible(true)
    setPassbackUrl(passbackUrl)
    setPassbackVendorName(vendorName)
    setPassbackEmail(email)
    setTxnLineId(txnLineId)
  }

  const handlePassbackOnSuccess = async (): Promise<void> => {
    await getTransactionLinesList({
      page: currentPage,
      paginate_count: pageSize,
      ...typeFilter,
      ...periodFilter,
      ...TRDQuickSearchParams
    })
    message.success('Passback notification has been sent.')
  }

  const handlePassbackOnError = (): void => {
    setIsPassbackModalVisible(false)
    message.error('Something went wrong.')
  }

  const handleOnNudgeAllClick = (): void => {
    setIsNudgeAllModalVisible(true)
  }

  const handleNudgeAllOnSuccess = (): void => {
    setIsNudgeAllModalVisible(false)
    message.success('Reminder emails have been sent')
  }

  const handleNudgeAllOnError = (): void => {
    setIsNudgeAllModalVisible(false)
    message.error('Something went wrong.')
  }

  const handleExportClick: MenuProps['onClick'] = ({ key }) => {
    downloadFile(key)
  }

  const showAddMoreModal = () => setIsAddMoreModalVisible(true)
  const handleCancelAddMore = () => {
    setIsAddMoreModalVisible(false)
    setAppKey(undefined)
  }

  const downloadFile = async (fileType: string) => {
    try {
      const params = {
        ...periodFilter,
        ...typeFilter,
        transaction_id: txnId,
        custom_filter: activeFilterRef.current.id
      }

      if (fileType === 'csv_with_id') {
        params.with_id = true
      }

      const { data, headers } = await getTrdLineExport(params)
      const content = headers['content-type']
      download(
        data,
        `Transactions-${periodFilter.g_period}.${
          fileType === 'csv_with_id' ? 'csv' : fileType
        }`,
        content
      )
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const handleOnLookUpClick = async (url: string, api_name: string) => {
    try {
      setIsTxnLineLookupModalVisible(true)
      setIsTxnLineLookupModalLoading(true)
      const response = await callGetApi(url)
      setLookUpApi(api_name)
      setTxnLineLookUpData(response?.data.reverse())
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsTxnLineLookupModalLoading(false)
    }
  }

  const isAllCheckboxSelected = (): boolean => {
    if (checkboxCount === 0) return false
    return selectedRows.length === checkboxCount
  }

  const handleGetSelectAllCheckbox = () => (
    <Checkbox
      data-testid='tbl-select-all'
      checked={isAllCheckboxSelected()}
      onChange={onChangeCheckboxAll(transactionLines)}
    />
  )

  const onChangeCheckboxAll =
    (rows: TransactionLine[]) =>
    (e: CheckboxChangeEvent): void => {
      const isChecked = e.target.checked

      if (!isChecked) {
        setSelectedRows([])
        return
      }

      const allRowsToSelect: any[] = []
      const allRowsTxnIdToSelect: any[] = []

      rows.forEach((item) => {
        if (
          Number(item.g_status) === TxnLineStatusEnum.SUBMITTED.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_ERROR.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_REJECTED.id ||
          Number(item.g_status) === TxnLineStatusEnum.NO_QJE.id
        ) {
          if (
            item.line_properties?.actions.qje.enabled ||
            item.line_properties?.actions.no_qje.enabled
          ) {
            allRowsToSelect.push({
              id: item.id,
              transaction_id: item.transaction_id,
              line_properties: item.line_properties,
              token_id: item.token_id!
            })
            allRowsTxnIdToSelect.push(item.transaction_id!)
          }
        }
      })

      if (allRowsToSelect.length === 0) {
        message.info('No rows to select')
      }

      setSelectedRows(allRowsToSelect)
    }

  const handleGetLineCheckbox = (
    row: TransactionLine
  ): string | JSX.Element => {
    if (!isTableEditable) {
      if (
        row.line_properties?.actions.qje.enabled ||
        row.line_properties?.actions.no_qje.enabled
      ) {
        return (
          <Checkbox
            checked={isRowSelected(row)}
            onChange={handleOnChangeCheckbox(row)}
          />
        )
      }

      if (
        Number(row.g_status) === TxnLineStatusEnum.QJE_POSTED.id ||
        Number(row.g_status) === TxnLineStatusEnum.QJE_CREATED.id
      ) {
        return <GIcon icon={faCheck} className='g-icon-primary' />
      }
    }

    return ''
  }

  const handleOnChangeCheckbox =
    (row: TransactionLine) =>
    (e: CheckboxChangeEvent): void => {
      const isChecked = e.target.checked

      if (!isChecked) {
        setSelectedRows(
          selectedRows.filter(
            (item: any) =>
              item.token_id !== row.token_id! || item.id !== row.id!
          )
        )
        return
      }

      setSelectedRows([
        ...selectedRows,
        {
          id: row.id!,
          transaction_id: row.transaction_id!,
          line_properties: row.line_properties!,
          token_id: row.token_id!
        }
      ])
    }

  const isRowSelected = (row: TransactionLine): boolean =>
    selectedRows.some(
      (item) => item.token_id === row.token_id! && item.id === row.id!
    )

  const handleOnNudgeSelectedClick = (): void => {
    setIsNudgeSelectedModalVisible(true)
  }

  const handleOnNudgeSelectedSuccess = (): void => {
    Modal.success({
      content: 'Reminder emails have been sent'
    })
  }

  const handleOnNudgeSelectedError = (): void => {
    message.error('Something went wrong.')
  }

  const newHistoryColumns: (
    | TableColumnType<any>
    | TableColumnGroupType<any>
  )[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performedBy',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'oldValue',
      width: 200
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'newValue',
      width: 200
    }
  ]

  const handleGetActions = (record: TransactionLine) => {
    const data = record.line_properties
    return (
      <div className='action-menu'>
        {editedRows.length > 0 ? (
          <Popconfirm
            title='Please save/cancel your edits before proceeding with copying a row.'
            okText='Save'
            cancelText='Cancel'
            onCancel={() => {
              handleActionOnclick(record, TxnLineLevelActions.COPY_LINE)

              setEditedRows([])
              setErrorFields([])
            }}
            onConfirm={onSaveEditedRow}
            placement='left'
            disabled={
              !(data?.actions.copy.enabled && canCopyLines.current) ||
              !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION
            }
          >
            <GButton
              type='text'
              className='g-btn-icon action-btn'
              icon={
                <CopyOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
              btnText={'Copy Line'}
              disabled={
                !(data?.actions.copy.enabled && canCopyLines.current) ||
                !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION
              }
            />
          </Popconfirm>
        ) : (
          <GButton
            type='text'
            className='g-btn-icon action-btn'
            onClick={() =>
              handleActionOnclick(record, TxnLineLevelActions.COPY_LINE)
            }
            icon={
              <CopyOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            btnText={'Copy Line'}
            disabled={
              !(data?.actions.copy.enabled && canCopyLines.current) ||
              !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION
            }
          />
        )}

        <Popconfirm
          title='Are you sure you want to delete this item?'
          okText='Yes'
          cancelText='No'
          onConfirm={() =>
            handleActionOnclick(record, TxnLineLevelActions.DELETE_LINE)
          }
          placement='left'
          disabled={
            !(data?.actions.delete.enabled && canDeleteLines.current) ||
            !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION
          }
        >
          <GButton
            type='text'
            className='g-btn-icon action-btn'
            icon={
              <DeleteOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            btnText={'Delete Line'}
            disabled={
              !(data?.actions.delete.enabled && canDeleteLines.current) ||
              !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION
            }
          />
        </Popconfirm>

        {/* Button for triggering history component */}
        <GButton
          type='text'
          className='g-btn-icon action-btn'
          onClick={() => {
            // ** Check if earlier than January 01, 2024 **
            if (
              !isLoadingTxnGlobalSettings &&
              !isRefetchingTxnGlobalSettings &&
              moment(record?.g_period).isBefore(
                moment(
                  txnGlobalSettings['transaction.history.cutoff_date'],
                  'YYYY-MM-DD'
                )
              )
            ) {
              // ** Earlier than Jan 2024 - use old history **
              handleActionOnclick(record, TxnLineLevelActions.HISTORY_LOG)
            } else {
              // ** Jan 2024 and later - use new history **
              handleActionOnclick(record, TxnLineLevelActions.NEW_HISTORY_LOG)
            }
          }}
          icon={
            <HistoryOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          btnText={'History Log'}
          disabled={
            !data?.actions.history.enabled ||
            !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION
          }
        />

        {/* Button for triggering copy accrual link */}
        {isFlagEnabled(FEATURE_FLAGS.edinburgh_accrual_form_link) &&
          isConsolidated && (
            <GButton
              type='text'
              className='g-btn-icon action-btn'
              onClick={() => {
                setIsVisibleCopyAccrualFormLinkModal(true)
                setClickedTransactionLine(record)
              }}
              icon={
                <LinkOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
              btnText={'Copy Accrual Form Link'}
              disabled={!RP.USER_HAS_COPY_ACCRUAL_FORM_LINK_PERMISSION}
            />
          )}
      </div>
    )
  }

  const handleGetActionItems = (record: TransactionLine) => {
    const data = record.line_properties
    const actions: MenuItemType[] = []
    if (data && data.actions) {
      actions.push(
        {
          label: 'Copy Line',
          key: TxnLineLevelActions.COPY_LINE,
          disabled:
            !(data?.actions.copy.enabled && canCopyLines.current) ||
            !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION,
          icon: (
            <CopyOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        },
        {
          label: 'Delete Line',
          key: TxnLineLevelActions.DELETE_LINE,
          disabled:
            !(data?.actions.delete.enabled && canDeleteLines.current) ||
            !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION,
          icon: (
            <DeleteOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        },
        {
          label: 'History Log',
          key: TxnLineLevelActions.HISTORY_LOG,
          disabled:
            !data.actions.history.enabled ||
            !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION,
          icon: (
            <HistoryOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        }
      )

      if (isFlagEnabled(FEATURE_FLAGS.edinburgh_accrual_form_link)) {
        actions.push({
          label: 'Copy Accrual Form Link',
          key: TxnLineLevelActions.COPY_ACCRUAL_FORM_LINK,
          disabled: !RP.USER_HAS_COPY_ACCRUAL_FORM_LINK_PERMISSION,
          icon: (
            <LinkOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        })
      }
    }

    return actions
  }

  const handleActionOnclick = async (record: TransactionLine, item: string) => {
    const action = record.line_properties && record.line_properties.actions
    if (item === TxnLineLevelActions.PASSBACK) {
      showPassbackModal(
        action?.passback.url!,
        record.g_name!,
        record.g_email!,
        record.id!
      )
    } else if (item === TxnLineLevelActions.COPY_LINE) {
      try {
        setIsLoading(true)
        const payload = {
          app_key: action?.copy.payload.app_key,
          line_id: action?.copy.payload.line_id,
          transaction_id: action?.copy.payload.transaction_id
        }

        await callPostApi(action?.copy.url!, payload)
        await getTransactionLinesList({
          page: currentPage,
          paginate_count: pageSize,
          sort: columnSort.current,
          ...columnFilter,
          ...periodFilter,
          custom_filter: activeFilterRef.current.id,
          ...TRDQuickSearchParams
        })
        message.success('Record was successfully copied')
      } catch (error) {
        setIsLoading(false)
        const { response } = error as AxiosError
        console.log(response)
        message.error('Something went wrong')
      }
    } else if (item === TxnLineLevelActions.DELETE_LINE) {
      try {
        setIsLoading(true)
        const dataList = [...transactionLines]
        const indexOfDataToBeRemove = transactionLines.indexOf(record)

        const response = await callDeleteApi(
          action?.delete.url!,
          action?.delete.payload!
        )

        if (indexOfDataToBeRemove > -1 && response.data) {
          dataList.splice(indexOfDataToBeRemove, 1)
          setTransactionLines(
            dataList.map((item) => ({
              ...item,
              parent: true,
              key: item.unique_line_identifier,
              children: txnChildren[item.unique_line_identifier!] || []
            }))
          )
          message.success('Record was successfully removed')
          setIsLoading(false)
        } else {
          setIsLoading(false)
          message.error('Something went wrong')
        }
      } catch (error) {
        setIsLoading(false)
        const { response } = error as AxiosError
        console.log(response)
        message.error('Something went wrong')
      }
    } else if (item === TxnLineLevelActions.HISTORY_LOG) {
      callGetApi(action?.history.url!, action?.history.payload!).then((res) => {
        setHistoryModalVisible(true)
        setModalDelay(true)
        setHistoryData(res && res.data)
      })
    } else if (item === TxnLineLevelActions.NEW_HISTORY_LOG) {
      // ** For calling the new history log component
      getTransactionHistory(record.g_accrual_source!, record.id!).then(
        (res) => {
          setNewHistoryModalVisible(true)
          setModalDelay(true)
          setHistoryData(res && res.data)
        }
      )
    } else if (item === TxnLineLevelActions.OVERRIDE) {
      setOverrideData(record)
      setOverrideModalVisible(true)
      setModalDelay(true)
    }
  }

  const handleHistoryModalCancel = () => {
    setHistoryModalVisible(false)
    setNewHistoryModalVisible(false)
    setTimeout(() => {
      setModalDelay(false)
    }, 1000)
  }

  const isLineOriginal = (status: number): boolean => {
    return [
      TxnLineSourceEnum.ORIGINAL.id,
      TxnLineSourceEnum.SYSTEM_ADDED.id,
      TxnLineSourceEnum.USER_ADDED.id
    ].includes(status)
  }

  const handleOverrideModalCancel = () => {
    setOverrideModalVisible(false)
    setTimeout(() => {
      setModalDelay(false)
    }, 1000)
  }

  const getLinePropertyActionDetails = (
    id: any,
    action:
      | 'copy'
      | 'delete'
      | 'history'
      | 'lookup'
      | 'override'
      | 'passback'
      | 'qje'
  ) => {
    return transactionLines.find((txnLine: any) => txnLine.id === id)
      ?.line_properties?.actions[action]
  }

  const handleCreateQje = () => {
    if (selectedRows.length > 0) {
      setIsConfirmCreateQjeModalVisible(true)
    } else {
      Modal.info({
        title: 'Please select transaction first',
        centered: true
      })
    }
  }

  const createQje = async (): Promise<void> => {
    setIsConfirmCreateQjeModalVisible(false)
    setIsProcessingCreateQjeModalVisible(true)
    const errors: string[] = []

    for (const item of selectedRows) {
      setProcessedQjeCount((previousCount) => previousCount + 1)
      const { app_id: id, app_key: key } =
        item.line_properties.actions.qje.payload

      const payload = {
        app: {
          id,
          key
        },
        transaction_id: item.transaction_id,
        line_ids: [item.id]
      }

      try {
        await qjeStore(payload)
        setSuccessQjeCount((previousCount) => previousCount + 1)
      } catch (error: any) {
        const qjeErrors: any = error.response.data
        for (const qjeError in qjeErrors) {
          if (!errors.includes(qjeErrors[qjeError])) {
            errors.push(qjeErrors[qjeError])
          }
        }
        setFailedQjeCount((previousCount) => previousCount + 1)
      }
    }

    if (txnId) {
      const { data } = await getTransactionSettings(txnId)
      setTransactionProperties(data.transaction_properties)
    }

    setIsProcessingCreateQjeModalVisible(false)
    setIsSuccessCreateQjeModalVisible(true)
    setCreateQjeErrors(errors)
  }

  const handleOnSaveFilter = async (values: any, parameters: any) => {
    try {
      if (parameters.length > 0) {
        const { data } = await saveFilter({
          ...values,
          form_id: transaction?.form_id,
          parameters
        })
        if (data) {
          message.success('New filter successfully added')
          setShowSaveFilterModal(false)
          fetchSavedFilters(transaction?.form_id!)
        }
      } else {
        message.error('The parameters field is required.')
        setShowSaveFilterModal(false)
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const handleDeleteFilterOption = async (item: any, form: any) => {
    deleteSavedFiltersById(item.key).then((res) => {
      fetchSavedFilters(transaction?.form_id!)
      message.success('Filter has been deleted')
      if (filterkey === item.key) {
        form.resetFields()
        getTransactionLinesList({
          page: 1,
          paginate_count: pageSize,
          ...TRDQuickSearchParams
        })
      }
    })
  }

  const handleClickOption = async (item: any, form: any) => {
    if (item.key === 'save') {
      setShowSaveFilterModal(true)
    } else if (
      item.key === 100000 ||
      item.key === 100001 ||
      item.key === 100002 ||
      item.key === 100003
    ) {
      let reqParams = {
        ...item.parameters[0],
        ...TRDQuickSearchParams
      }
      form.resetFields()
      setColumnFilter({})
      getTransactionLinesList(reqParams)
    } else {
      form.resetFields()
      setFilterKey(item.key)
      let parameters = item.parameters
      let defaultValues: any = {}

      if (!Array.isArray(parameters)) {
        parameters = []
        Object.entries(item.parameters).forEach((data) => {
          parameters.push({
            [data[0]]: [data[1]]
          })
        })
      }

      parameters.forEach((item: any) => {
        const key: string = Object.keys(item)[0]
        const value = item[key]
        let apiKey = getApiName(key)
        defaultValues[apiKey] = value[0]
      })

      let newValues = defaultValues
      let reqParams = defaultValues
      Object.keys(defaultValues).forEach((key) => {
        if (
          key === 'g_created_at' ||
          key === 'g_date_submitted' ||
          key === 'g_due_date' ||
          key === 'g_period'
        ) {
          if (defaultValues[key] !== null) {
            //for column filter values
            newValues = {
              ...defaultValues,
              [key]: [
                moment(defaultValues[key][0]),
                moment(defaultValues[key][1])
              ]
            }
            //for request param values
            reqParams = {
              ...defaultValues,
              [key]: [
                moment(defaultValues[key][0], 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                ),
                moment(defaultValues[key][1], 'YYYY-MM-DD').format('YYYY-MM-DD')
              ]
            }
          }
        }
      })
      form.setFieldsValue(newValues)
      setColumnFilter(reqParams)
      getTransactionLinesList(reqParams)
    }
  }

  const getApiName = (label: string) => {
    let found = columns.find((item: any) => {
      if (item.label === label) {
        return true
      }

      return false
    })
    return found ? found.api_name : ''
  }

  const handleCancelFilterModal = () => {
    setShowSaveFilterModal(false)
  }

  const handleSuccessCreateQjeModalOnOk = () => {
    setIsSuccessCreateQjeModalVisible(false)
    setCreateQjeErrors([])
    setSelectedRows([])
    setSuccessQjeCount(0)
    setProcessedQjeCount(0)
    setFailedQjeCount(0)
    if (showCalculatedPopup) {
      getTransactionLinesList({
        rc_params: encodedCalculatedParams
      })
    } else if (showImportedPopup) {
      getTransactionLinesList({
        rc_params: encodedImportedParams
      })
    } else if (showInternalPopup) {
      getTransactionLinesList({
        rc_params: encodedInternalParams
      })
    } else if (showManualPopup) {
      getTransactionLinesList({
        rc_params: encodedManualParams
      })
    } else if (showVendorPopup) {
      getTransactionLinesList({
        rc_params: encodedVendorParams
      })
    } else if (showQuickActions) {
      getTransactionLinesList({
        rc_totals_params: encodedTotalParams
      })
    } else {
      getTransactionLinesList({
        page: currentPage,
        paginate_count: pageSize,
        ...periodFilter,
        ...columnFilter,
        ...typeFilter,
        sort: columnSort.current,
        custom_filter: activeFilterRef.current.id,
        ...TRDQuickSearchParams
      })
    }
    // setCallReviewCenterReload(!callReviewCenterReload) // comment this out to invoke reload of RC table
    setIsCreateQjeCalled(true)
  }

  const handleOnClickPeriod = (item: any) => {
    const period = {
      g_period: moment(item.key, 'YYYY-MM').format('YYYY-MM')
    }

    const params = {
      ...period,
      ...typeFilter,
      ...columnFilter,
      paginate_count: pageSize,
      page: 1,
      custom_filter: activeFilterRef.current.id,
      sort: columnSort.current,
      // ...typeFilter
      ...TRDQuickSearchParams
    }

    setPeriodFilter(period)
    setCurrentPage(1)
    setIsLoading(true)
    setSelectedRows([])
    getTransactionLinesList(params)
  }

  const handleOnNoQjeClick = (): void => {
    if (selectedRows.length > 0) {
      setIsConfirmNoQjeModalVisible(true)
    } else {
      Modal.info({
        title: 'Please select transaction first',
        centered: true
      })
    }
  }

  const processNoQjeLines = async (): Promise<void> => {
    setIsConfirmNoQjeModalVisible(false)
    setIsProcessingCreateQjeModalVisible(true)
    const rows: {
      id: number
      transaction_id: string | number | null
      line_properties: LineProperties
    }[] = selectedRows

    for (const item of rows) {
      setProcessedQjeCount((previousCount) => previousCount + 1)
      const { payload, url } = item.line_properties.actions.no_qje
      try {
        await callPostApi(url, payload)
        setSuccessQjeCount((previousCount) => previousCount + 1)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
        setFailedQjeCount((previousCount) => previousCount + 1)
      }
    }
    setIsProcessingCreateQjeModalVisible(false)
    setIsSuccessCreateQjeModalVisible(true)
  }

  const handleEditField = async (
    data: any,
    row: any,
    key: any,
    index: number,
    options: any
  ) => {
    let newlist = [...editedRows]
    let isExisting = false

    try {
      if (key === 'g_accrual_amount') {
        let parsedAccrualAmount = parseFloat(data['g_accrual_amount'] || '0')

        const isPrepaidEntryDisabled =
          !isPrepaidEntryFeatureFlagEnabled && parsedAccrualAmount < 0

        if (isPrepaidEntryDisabled) {
          parsedAccrualAmount = 0
        }

        const formattedAccrualAmount = parsedAccrualAmount.toFixed(2)

        data['g_accrual_amount'] = formattedAccrualAmount
        row['g_accrual_amount'] = formattedAccrualAmount

        await setFxRateAmount(row)

        data = {
          ...data,
          g_reporting_amount: row['g_reporting_amount'],
          g_subsidiary_amount: row['g_subsidiary_amount']
        }
      } else if (key === 'g_threshold_support_document') {
      } else if (key === 'g_subsidiary') {
        await getSubsidiary(data, row)

        await setFxRateAmount(row)

        data = {
          ...data,
          g_subsidiary_currency: row['g_subsidiary_currency'],
          g_reporting_amount: row['g_reporting_amount'],
          g_subsidiary_amount: row['g_subsidiary_amount']
        }
      } else if (key === 'g_transaction_currency') {
        await setFxRateAmount(row)

        data = {
          ...data,
          g_transaction_currency: row['g_transaction_currency'],
          g_reporting_amount: row['g_reporting_amount'],
          g_subsidiary_amount: row['g_subsidiary_amount']
        }
      }

      if (options && options.hidden_value) {
        row[`${key}_id`] = options.hidden_value
        data = {
          ...data,
          [`${key}_id`]: options.hidden_value
        }
      }
    } catch (error) {
      console.log('err:', error)
    }

    if (editedRows.length === 0) {
      if (!('override_reason' in data)) {
        row['override_reason'] = null
      }
      newlist.push({
        index: index,
        id: row.id,
        transaction_id: row.transaction_id,
        g_status: row.g_status,
        ...(isConsolidated && { source: row.g_accrual_source }),
        ...data
      })
    } else {
      newlist = newlist.map((item: any) => {
        if (item && item.id === row.id) {
          isExisting = true
          return {
            ...item,
            ...data
          }
        }
        return item
      })

      if (!isExisting) {
        if (!('override_reason' in data)) {
          row['override_reason'] = null
        }
        newlist.push({
          index: index,
          id: row.id,
          transaction_id: row.transaction_id,
          g_status: row.g_status,
          ...(isConsolidated && { source: row.g_accrual_source }),
          ...data
        })
      }
    }

    newlist.forEach((item: Record<string, unknown>) => {
      if (item.index === index) {
        if (data[`${key}`] === undefined || data[`${key}`] === null) {
          item[`${key}`] = null
          item[`${key}_id`] = null
        }
      }
    })

    setEditedRows(newlist)
  }

  const showAddNewLineOptionModal = async () => {
    setIsAddNewLineBtnLoading(true)
    const lineTypeOptions: string[] = []

    for (const item of enabledLineTypes) {
      const { data } = await getTrdTxnForm(item)
      if (Array.isArray(data) && data.length === 0) continue
      lineTypeOptions.push(item)
    }

    setEnabledLineTypesOptions(lineTypeOptions)
    setIsAddNewLineOptionModalVisible(true)
    setIsAddNewLineBtnLoading(false)
  }

  const getAddLineForm = async (
    appType: string,
    formId?: number,
    formTypeLabel?: string
  ) => {
    setIsAddNewLineOptionModalLoading(true)
    if (appType === 'MAN') {
      try {
        const { data } = await getTrdTxnForm(appType)
        setFormFields(data.form_fields)
        setTrdTxnId(data.transaction_id)
        setAppKey(data.g_accrual_source)
        setAccrualType(data.g_accrual_type)
        setIsAddMoreModalVisible(true)
      } catch (error) {
        console.log(error)
      } finally {
        setIsAddNewLineOptionModalLoading(false)
        setIsAddNewLineOptionModalVisible(false)
      }
    }
    if (appType === 'OPEN' || appType === 'VDAC') {
      try {
        const { data } = await getTrdTxnForm(appType, formId!)
        const form = data

        if (Array.isArray(form) && form.length === 0) {
          Modal.info({
            title: `There are no current period transactions for the form: ${formTypeLabel}`,
            centered: true
          })
        } else {
          setFormFields(form.form_fields)
          setTrdTxnId(form.transaction_id)
          setAppKey(data.g_accrual_source)
          setAccrualType(data.g_accrual_type)
          setIsAddMoreModalVisible(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsAddNewLineOptionModalLoading(false)
        setIsAddNewLineOptionModalVisible(false)
      }
    }
  }

  const handleCancelTable = () => {
    if (showCalculatedPopup) {
      getTransactionLinesList({
        rc_params: encodedCalculatedParams
      })
    } else if (showImportedPopup) {
      getTransactionLinesList({
        rc_params: encodedImportedParams
      })
    } else if (showInternalPopup) {
      getTransactionLinesList({
        rc_params: encodedInternalParams
      })
    } else if (showManualPopup) {
      getTransactionLinesList({
        rc_params: encodedManualParams
      })
    } else if (showVendorPopup) {
      getTransactionLinesList({
        rc_params: encodedVendorParams
      })
    } else if (showQuickActions) {
      getTransactionLinesList({
        rc_totals_params: encodedTotalParams
      })
    } else {
      getTransactionLinesList({
        page: currentPage,
        paginate_count: pageSize,
        ...periodFilter,
        ...columnFilter,
        custom_filter: activeFilterRef.current.id,
        sort: columnSort.current,
        ...TRDQuickSearchParams
      })
    }

    setEditedRows([])
  }

  const isAddNewLineButtonDisabled = (): boolean => {
    if (txnId) {
      return (
        !RP.USER_HAS_ADD_LINE_PERMISSION ||
        !transactionProperties?.actions.add_line.enabled
      )
    }
    return !RP.USER_HAS_ADD_LINE_PERMISSION || enabledLineTypes.length === 0
  }

  const handleOnClickVoid = () => {
    Modal.confirm({
      centered: true,
      title: 'Void',
      icon: (
        <InfoCircleOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          style={{ color: '#1890ff' }}
        />
      ),
      content:
        'This action cannot be undone. Are you sure you want to void this transaction?',
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        try {
          await callPutApi(
            transactionProperties?.actions.void_transaction?.url!
          )

          const { data } = await getTransactionSettings(txnId!)
          setTransactionProperties(data.transaction_properties)
          getTransactionLinesList()
          message.success('Transaction has been voided')
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        }
      }
    })
  }

  const onSaveEditedRow = async () => {
    let reload: any = await handleOnSaveEdit(
      originalList,
      editedRows,
      displayThresholdSupport,
      thresholdAmount,
      errorFields,
      setErrorFields,
      hasOverrideColumn.current,
      requireOverrideExp.current,
      transactionLines,
      setEditedRows
    )
    if (reload && showCalculatedPopup) {
      getTransactionLinesList({
        rc_params: encodedCalculatedParams
      })
    } else if (reload && showImportedPopup) {
      getTransactionLinesList({
        rc_params: encodedImportedParams
      })
    } else if (reload && showInternalPopup) {
      getTransactionLinesList({
        rc_params: encodedInternalParams
      })
    } else if (reload && showManualPopup) {
      getTransactionLinesList({
        rc_params: encodedManualParams
      })
    } else if (reload && showVendorPopup) {
      getTransactionLinesList({
        rc_params: encodedVendorParams
      })
    } else if (reload && showQuickActions) {
      getTransactionLinesList({
        rc_totals_params: encodedTotalParams
      })
    } else if (reload) {
      getTransactionLinesList({
        ...periodFilter,
        custom_filter: activeFilterRef.current.id,
        page: currentPage,
        sort: columnSort.current,
        ...TRDQuickSearchParams,
        paginate_count: pageSize
      })
    }
    // setCallReviewCenterReload(!callReviewCenterReload) // comment this out to invoke reload of RC table
    setIsEditRowCalled(true)
  }

  const callGetChildrenApi = async (record: TransactionLine) => {
    let fetchedData: any[] = []
    // ** For testing, use mock **
    // let mock = [
    //   {
    //     parent: false,
    //     key: 11,
    //     date: '2023-06-01 09:51AM',
    //     user: 'Gappify QACS',
    //     g_accrual_type: `test - ${Date.now()}`
    //     // ... other properties
    //   },
    //   {
    //     parent: false,
    //     key: 12,
    //     date: '2023-06-01 09:51AM',
    //     user: 'Gappify QACS',
    //     g_accrual_type: `test - ${Date.now()}`
    //     // ... other properties
    //   }
    //   // ... add more children as needed
    // ]
    try {
      let { data } = await getChildren(
        {
          id: `${record.transaction_id}`,
          from_g_period: moment(periodFilter.g_period)
            .subtract(3, 'month')
            .format('YYYY-MM'),
          to_g_period: moment(periodFilter.g_period)
            .subtract(1, 'month')
            .format('YYYY-MM'),
          task_id: `${record.task_id}`
        },
        isConsolidated
      )
      // ** For testing: use mock instead of data **
      // fetchedData = mock

      // ** For testing: use an empty array to test the handling of empty data
      fetchedData = Object.values(data)
    } catch (e) {
      console.log('Error', e)
      // ** For testing: use mock instead of [] **
      fetchedData = []
    }
    let newTxnChildren: Record<string, any> = JSON.parse(
      JSON.stringify(txnChildren)
    )
    newTxnChildren[`${record.unique_line_identifier}`] =
      fetchedData.length > 0
        ? fetchedData.map((childRow, index) => {
            return {
              ...childRow,
              key: `${record.unique_line_identifier}_${childRow.unique_line_identifier}`,
              overrideActions: {
                component: moment(childRow.g_period).format('MMM YYYY')
                // props: { colSpan: 2 }
              },
              overrideSelectBox: { component: <></>, props: { colSpan: 1 } },
              isPriorPeriodLine: true
            }
          })
        : [
            {
              overrideActions: {
                component: 'No Data'
              }
            }
          ]
    setTxnChildren(newTxnChildren)

    setTransactionLines(
      transactionLines.map((item: TransactionLine, index: number) => {
        if (item.unique_line_identifier === record.unique_line_identifier) {
          return {
            ...item,
            parent: true,
            key: item.unique_line_identifier,
            children:
              fetchedData.length > 0
                ? fetchedData.map((childRow: any, index: number) => {
                    return {
                      ...childRow,
                      key: `${record.unique_line_identifier}_${childRow.unique_line_identifier}`,
                      overrideActions: {
                        component: moment(childRow.g_period).format('MMM YYYY')
                        // props: { colSpan: 2 }
                      },
                      overrideSelectBox: {
                        component: <></>,
                        props: { colSpan: 1 }
                      },
                      isPriorPeriodLine: true
                    }
                  })
                : [
                    {
                      overrideActions: {
                        component: 'No Data'
                        // props: { colSpan: 2 }
                      },
                      overrideSelectBox: {
                        component: <></>,
                        props: { colSpan: 1 }
                      }
                    }
                  ]
          }
        } else return item
      })
    )
  }

  // TODO: replace any
  const onClickCollapse = async (expandedLine: TransactionLine) => {
    await setTransactionLines(
      // TODO: replace any
      await transactionLines.map((txnLine: TransactionLine) => {
        if (
          txnLine.unique_line_identifier === expandedLine.unique_line_identifier
        ) {
          let { children, ...rest } = txnLine
          return rest
        } else return txnLine
      })
    )
  }

  // const callGetChildrenApi = async ({
  //   id,
  //   from_g_period,
  //   to_g_period
  // }: {
  //   id: string
  //   from_g_period: string
  //   to_g_period: string
  // }) => {
  //   let res = await getChildren({ id, from_g_period, to_g_period })
  //   console.log('res', res)
  //   // let mock = [
  //   //   {
  //   //     parent: false,
  //   //     key: 11,
  //   //     date: '2023-06-01 09:51AM',
  //   //     user: 'Gappify QACS',
  //   //     g_accrual_type: `test - ${Date.now()}`
  //   //     // ... other properties
  //   //   },
  //   //   {
  //   //     parent: false,
  //   //     key: 12,
  //   //     date: '2023-06-01 09:51AM',
  //   //     user: 'Gappify QACS',
  //   //     g_accrual_type: `test - ${Date.now()}`
  //   //     // ... other properties
  //   //   }
  //   //   // ... add more children as needed
  //   // ]
  //   let newTxnChildren = { ...txnChildren }
  //   newTxnChildren[id] = res.data
  //   setTxnChildren(newTxnChildren)
  // }

  // console.log(`TRANSACTIONLINES`, transactionLines)

  return (
    <div
      className='txn-lines-container trd-lines-table'
      data-testid='transaction-lines'
      data-cy='transaction-lines'
    >
      {false && (
        <GTransactionLinesActions
          formName={transaction?.form_name!}
          transactionId={id}
          txnNumber={transaction?.transaction_number!}
          onAddMoreClick={showAddMoreModal}
          onNudgeAllClick={handleOnNudgeAllClick}
          onExportClick={handleExportClick}
          onNudgeSelectedClick={handleOnNudgeSelectedClick}
          onCreatQjeClick={handleCreateQje}
          setAttachmentsList={setAttachments}
          hasAttachments={attachments.length > 0}
          isNudgeAllEnable={transactionProperties?.actions.nudge_all.enabled}
          isNudgeSelectedEnable={
            transactionProperties?.actions.nudge_selected.enabled
          }
          isAddMoreEnable={transactionProperties?.actions.add_more.enabled}
          isAddLineEnable={transactionProperties?.actions.add_line.enabled}
          isCreateQjeEnable={transactionProperties?.actions.create_qje.enabled}
          appType={transaction?.app!}
        />
      )}
      <GDynamicTable
        actionGetPopupContainer={actionGetPopupContainer}
        rowKey={(row) => row.key}
        isExpandable={isExpandable && priorPeriodTransaction}
        onClickExpand={callGetChildrenApi}
        onClickCollapse={onClickCollapse}
        columnHeader={columns}
        dataSource={
          considerBothDebitAndCreditValues && hasNetExpenseAmount
            ? [
                ...transactionLines,
                // Additional line for the total row
                {
                  net_expense_amount: transactionLines
                    .map((line) => line.net_expense_amount)
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator! + currentValue!,
                      0
                    ),
                  isTotalNetExpenseAmount: true
                }
              ]
            : transactionLines
        }
        loading={isLoading || isLoadingColumnHeaders}
        pageSize={pageSize}
        totalItems={totalItems}
        showHeader={showHeader}
        onChange={handleTableChange}
        getSelectAllCheckbox={handleGetSelectAllCheckbox}
        getLineCheckboxes={handleGetLineCheckbox}
        selectedRowsCount={selectedRows.length}
        getActions={handleGetActions}
        getActionItems={handleGetActionItems}
        onFilterChange={handleFilterChange}
        optionsList={optionsList}
        onClickReset={() => {
          getTransactionLinesList({
            page: 1,
            paginate_count: pageSize
          })
        }}
        hasActionColumns={hasActionColumns}
        hasPDFExport={false}
        hasColumnFilters={false}
        hasSelectAll={hasSelectAll}
        hasSaveFilter
        hasGlobalFilter={
          showImportedPopup ||
          showTransactionsPopup ||
          showCalculatedPopup ||
          showInternalPopup ||
          showManualPopup ||
          showVendorPopup ||
          showQuickActions
            ? false
            : true
        }
        hasTransactionPanel={hasTransactionPanel}
        saveFilterOptions={saveFilterOptions}
        onSaveFilter={handleOnSaveFilter}
        showSaveModal={showSaveFilterModal}
        onClickSaveOptions={handleClickOption}
        onCancelFilterModal={handleCancelFilterModal}
        onClickDeleteOptions={handleDeleteFilterOption}
        dateTimeFormat={dateTimeFormat}
        current={currentPage}
        isServerSideSort={true}
        onClickCreateQje={handleCreateQje}
        leftChild={
          !isTxnLinesActionTabsPanelLoading ? (
            isConsolidated ? (
              <>
                {/* Initial approach for FRD. Commented out for future reference. */}
                {/* <TrdLinesFilterFrd
                  activeFilterRef={activeFilterRef}
                  reloadData={(isAll: boolean, preview_mode: boolean) => {
                    setCurrentPage(1)
                    const { id, tempPreviewId } = activeFilterRef.current
                    return getTransactionLinesList({
                      ...periodFilter,
                      custom_filter: tempPreviewId || id,
                      sort: columnSort.current,
                      page: 1,
                      ...(preview_mode && { preview_mode: 1 }),
                      ...TRDQuickSearchParams,
                      paginate_count: pageSize
                    })
                  }}
                  setTransactionLines={setTransactionLines}
                  optionsList={optionsList}
                />  */}

                {/* <TrdLinesFilterFrd
                  activeFilterRef={activeFilterRef}
                  reloadData={(isAll: boolean, preview_mode: boolean) => {
                    setCurrentPage(1)
                    const { id, tempPreviewId } = activeFilterRef.current
                    return getTransactionLinesList({
                      ...periodFilter,
                      custom_filter: tempPreviewId || id,
                      ...(tempPreviewId && { id }),
                      sort: columnSort.current,
                      page: 1,
                      ...(preview_mode && { preview_mode }),
                      ...TRDQuickSearchParams,
                      paginate_count: pageSize
                    })
                  }}
                  setTransactionLines={setTransactionLines}
                  optionsList={optionsList}
                  columnHeadersAll={columnHeadersAll}
                  setColumnHeaders={setColumnHeaders}
                /> */}

                <Filter
                  microservice='transaction-manager'
                  activeFilterRef={activeFilterRef}
                  loadDataCallback={(preview_mode: boolean) => {
                    setCurrentPage(1)
                    const { id } = activeFilterRef.current
                    const custom_filter = !preview_mode ? id || 'all' : null

                    getTransactionLinesList({
                      ...periodFilter,
                      ...(custom_filter && { custom_filter }),
                      sort: columnSort.current,
                      page: 1,
                      ...(preview_mode && { preview_mode }),
                      ...TRDQuickSearchParams,
                      paginate_count: pageSize
                    })
                  }}
                  getSavedFiltersUrl='api/transactions/lines/all-filters'
                  getFieldsUrl='api/transactions/lines/all-filter-fields'
                  getFilterByIdUrl='api/transactions/lines/get-filter'
                  createFilterUrl='api/transactions/lines/create-filter'
                  updateFilterUrl='api/transactions/lines/update-filter'
                  deleteFilterUrl='api/transactions/lines/delete-filter'
                  previewFilterUrl='api/transactions/lines/preview-filter'
                  allColumnHeaders={columnHeadersAll}
                  setColumnHeaders={setColumnHeaders}
                  hasCustomColumns={true}
                />
              </>
            ) : (
              <TrdLinesFilter
                activeFilterRef={activeFilterRef}
                reloadData={(isAll: boolean, preview_mode: boolean) => {
                  setCurrentPage(1)
                  return getTransactionLinesList({
                    ...periodFilter,
                    custom_filter: activeFilterRef.current.id,
                    sort: columnSort.current,
                    page: 1,
                    preview_mode: preview_mode,
                    ...TRDQuickSearchParams,
                    paginate_count: pageSize
                  })
                }}
                setTransactionLines={setTransactionLines}
                optionsList={optionsList}
              />
            )
          ) : (
            <></>
          )
        }
        rightChild={
          <GButton
            dataTestId='trd-transaction-lines-add-new-line-btn'
            dataCy='trd-transaction-lines-add-new-line-btn'
            className='g-btn-default'
            btnText='Add New line'
            icon={
              <PlusOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            disabled={isAddNewLineButtonDisabled()}
            onClick={showAddNewLineOptionModal}
            loading={isAddNewLineBtnLoading}
          />
        }
        hasCSVWithIdExport={true}
        hasExportBtn
        hasExportPermission={RP.USER_HAS_TXN_EXPORT_PERMISSION}
        hasExcelExport={false}
        onClickExport={handleExportClick}
        onClickPeriod={handleOnClickPeriod}
        showTxnFilter
        onNoQjeClick={handleOnNoQjeClick}
        onClickSubmit={onSaveEditedRow}
        onEditItem={handleEditField}
        errorFields={errorFields}
        isTableEditable={true}
        editedRowsCount={editedRows.length}
        editedRows={editedRows}
        onClickCancelTbl={handleCancelTable}
        editToggleCb={(isEditable) => {
          if (!isEditable && editedRows.length > 0) {
            getTransactionLinesList({
              ...periodFilter,
              custom_filter: activeFilterRef.current.id,
              sort: columnSort.current,
              page: currentPage,
              ...TRDQuickSearchParams,
              paginate_count: pageSize
            })
            setEditedRows([])
          }

          setIsEditable(isEditable)
        }}
        periodFilterLabel={periodFilterLabel.current}
        showVoidActionBtn={txnId && !isConsolidated ? true : false}
        isVoidActionBtnDisabled={
          !RP.USER_HAS_VOID_HEADER_TXN_PERMISSION ||
          !transactionProperties?.actions.void_transaction?.enabled
        }
        onClickVoid={handleOnClickVoid}
        hasQuickSearchBar
        quickSearchPlaceHolder={`Search ${getSearchableFields(columnHeaders)}`}
        onQuickSearchBarKeyDown={({ key }) => {
          setCurrentPage(1)
          /**
           * ! BUGFIX
           * Commented the search trigger when pressing 'Enter'
           * This causes the double API call on GET api/transactions/lines
           * Also increased debounce delay from 300ms to 600ms
           * ? See GQuickSearchBar component file for the debounce setup
           * ? Reference: https://gappify.atlassian.net/browse/ENG-7682
           */
          // if (key === 'Enter') {
          //   getTransactionLinesList({
          //     ...periodFilter,
          //     custom_filter: activeFilterRef.current.id,
          //     sort: columnSort.current,
          //     page: 1,
          //     ...TRDQuickSearchParams,
          //     paginate_count: pageSize
          //   })
          // }
        }}
      />
      {!isTxnLinesActionTabsPanelLoading &&
        !showCalculatedPopup &&
        !showTransactionsPopup &&
        !showImportedPopup &&
        !showInternalPopup &&
        !showManualPopup &&
        !showVendorPopup &&
        !showQuickActions && (
          <TxnLinesActionTabsPanel
            transactionId={txnId}
            historyApi={transactionProperties?.actions?.get_history}
            getCommentApi={transactionProperties?.actions?.get_comments}
            commentActionsApi={transactionProperties?.actions?.comments}
            trackers={trackers}
            setAttachmentsList={setAttachments}
            dateTimeFormat={dateTimeFormat}
            period={periodFilter.g_period}
            isConsolidatedTrdLines={txnId ? false : true}
            isConsolidated={isConsolidated}
            isTrd
            isFromHeader={txnId ? true : false}
          />
        )}
      <AttachmentsModal
        visible={showAttachModal}
        list={lineAttachments}
        title={vendorName}
        handleClose={() => {
          setShowAttachModal(false)
        }}
      />
      <TxnLinePassbackModal
        visible={isPassbackModalVisible}
        setModalVisible={setIsPassbackModalVisible}
        vendorName={passbackVendorName}
        email={passbackEmail}
        url={passbackUrl}
        lineId={txnLineId}
        transactionId={id}
        onSuccess={handlePassbackOnSuccess}
        onError={handlePassbackOnError}
      />
      <TxnLineNudgeAllModal
        setModalVisible={setIsNudgeAllModalVisible}
        transactionId={id}
        visible={isNudgeAllModalVisible}
        url={transactionProperties?.actions.nudge_all?.url!}
        onSuccess={handleNudgeAllOnSuccess}
        onError={handleNudgeAllOnError}
      />
      <AddMoreModal
        lineType={lineType}
        transactionId={trdTxnId!}
        showModal={isAddMoreModalVisible}
        onCancel={handleCancelAddMore}
        setIsAddMoreModalVisible={setIsAddMoreModalVisible}
        getTransactionLinesList={(params) => {
          getTransactionLinesList({
            ...params,
            ...periodFilter,
            custom_filter: activeFilterRef.current.id,
            sort: columnSort.current,
            ...TRDQuickSearchParams,
            paginate_count: pageSize
          })
        }}
        pageSize={pageSize!}
        formFields={formFields}
        isVisible={isAddMoreModalVisible}
        thresholdAmount={thresholdAmount}
        displayThresholdSupport={displayThresholdSupport}
        reportingCurrency={reportingCurrency}
        period={periodFilter.g_period}
        appType={typeFilter.app_type}
        appKey={appKey}
        accrualType={accrualType}
        isTrd
      />
      <TxnLineLookupModal
        data={txnLineLookUpData}
        visible={isTxnLineLookupModalVisible}
        setModalVisible={setIsTxnLineLookupModalVisible}
        setData={setTxnLineLookUpData}
        loading={isTxnLineLookupModalLoading}
        invoiceDetailsRange={invoiceDetailsRange}
        lookUpApi={lookUpApi}
      />
      <TxnLineNudgeSelectedModal
        visible={isNudgeSelectedModalVisible}
        setModalVisible={setIsNudgeSelectedModalVisible}
        onSuccess={handleOnNudgeSelectedSuccess}
        onError={handleOnNudgeSelectedError}
        nudgeSelected={transactionProperties?.actions.nudge_selected!}
      />
      {showDelay && (
        <TxnLinesHistoryModal
          showModal={showHistory}
          historyData={historyData}
          onCancel={handleHistoryModalCancel}
          labelList={columns}
          dateTimeFormat={dateTimeFormat}
        />
      )}
      {/* For new history modal */}
      {showDelay && (
        <TxnLinesNewHistoryModal
          showModal={showNewHistoryModal}
          historyData={historyData}
          onCancel={handleHistoryModalCancel}
          labelList={columns}
          dateTimeFormat={dateTimeFormat}
          columns={newHistoryColumns}
        />
      )}
      {showDelay && (
        <OverrideModal
          transactionId={id!}
          showModal={showOverride}
          onCancel={handleOverrideModalCancel}
          transaction={overrideData}
          getLinePropertyActionDetails={getLinePropertyActionDetails}
          setShowOverride={setOverrideModalVisible}
          getTransactionLinesList={getTransactionLinesList}
          pageSize={pageSize!}
          formFields={formFields?.override_form_fields}
          isVisible={showOverride}
          thresholdAmount={thresholdAmount}
          displayThresholdSupport={displayThresholdSupport}
          reportingCurrency={reportingCurrency}
          filters={columnFilter}
          currentPage={currentPage}
        />
      )}
      <ConfirmCreateQjeModal
        visible={isConfirmCreateQjeModalVisible}
        setModalVisible={setIsConfirmCreateQjeModalVisible}
        onOk={createQje}
      />
      <ConfirmNoQjeModal
        visible={isConfirmNoQjeModalVisible}
        setModalVisible={setIsConfirmNoQjeModalVisible}
        onOk={processNoQjeLines}
      />
      <ProcessingCreateQjeModal
        visible={isProcessingCreateQjeModalVisible}
        proccessedQjeItems={processedQjeCount}
        totalQjeItems={selectedRows.length}
      />
      <TxnLineCalculationModal
        visible={isCalculationModalVisible}
        calculationUrl={calculationUrl}
        setModalVisible={setIsCalculationModalVisible}
      />
      <SuccessCreateQjeModal
        visible={isSuccessCreateQjeModalVisible}
        successQjeCount={successQjeCount}
        failedQjeCount={failedQjeCount}
        onOk={handleSuccessCreateQjeModalOnOk}
        createQjeErrors={createQjeErrors}
      />
      <ConfirmSubmitEditModal
        visible={isConfirmSubmitEditModalVisible}
        setModalVisible={setIsConfirmSubmitEditModalVisible}
        setEditedRows={setEditedRows}
        onOk={(reload?: boolean) => {
          if (reload) {
            getTransactionLinesList({
              ...periodFilter,
              custom_filter: activeFilterRef.current.id,
              page: currentPage,
              sort: columnSort.current,
              ...TRDQuickSearchParams,
              paginate_count: pageSize
            })
          }

          setIsConfirmSubmitEditModalVisible(false)
        }}
        editedRows={editedRows}
        setErrorFields={setErrorFields}
        errorFields={errorFields}
        requireOverrideExp={requireOverrideExp.current}
      />
      <AddNewLineOptionModal
        visible={isAddNewLineOptionModalVisible}
        loading={isAddNewLineOptionModalLoading}
        enabledLineTypes={enabledLineTypes}
        enabledLineTypesOptions={enabledLineTypesOptions}
        setModalVisible={setIsAddNewLineOptionModalVisible}
        setLoading={setIsAddNewLineOptionModalLoading}
        onOk={getAddLineForm}
        setLineType={setLineType}
      />
      <CopyAccrualFormLinkModal
        isModalOpen={isVisibleCopyAccrualFormLinkModal}
        handleCancel={() => setIsVisibleCopyAccrualFormLinkModal(false)}
        transactionLine={clickedTransactionLine}
      />
    </div>
  )
}

TransactionLines.defaultProps = {
  hasTransactionPanel: true,
  isExpandable: true,
  hasSelectAll: true,
  hasActionColumns: true,
  hasNetExpenseAmount: false
}

export default TransactionLines
