// ** AntD Imports **
import { Popconfirm } from 'antd'

// ** Other Import
import GButton from '../../../components/gappify/GButton'

interface PopconfirmButtonType {
  title: string
  onConfirm?: () => void
  onCancel?: () => void
  icon?: JSX.Element
  btnText: string
  disabled?: boolean
}

const PopconfirmButton = ({
  title,
  onConfirm,
  onCancel,
  icon,
  btnText,
  disabled
}: PopconfirmButtonType) => {
  return (
    <Popconfirm
      placement='bottom'
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText='Yes'
      cancelText='No'
    >
      <GButton
        type='text'
        className='g-btn-icon action-btn'
        icon={icon}
        btnText={btnText}
        disabled={disabled}
      />
    </Popconfirm>
  )
}

export default PopconfirmButton
