import useApiService from './useApiService'

type BaseProps<Params, Data, Options> = {
  params?: Params
  data?: Data
  options: Options
}

type TOptions = {
  baseUrl: string
  endpoint: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

const useApiRequest = () => {
  const { apiRequest } = useApiService()

  const globalApi = <T>(props: BaseProps<T, T, TOptions>) =>
    apiRequest(
      props.options.baseUrl,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return { globalApi }
}

export default useApiRequest
