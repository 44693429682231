import ActionTypes from '../constants/action-types'
import StoreAction from '../../types/StoreAction'

export const setQjeData = (data: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_QJE_DATA,
    payload: data
  }
}

export const setUnreviewedPOData = (data: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_UNREVIEWED_PO_DATA,
    payload: data
  }
}

export const setReviewedPOData = (data: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_REVIEWED_PO_DATA,
    payload: data
  }
}

export const setQjePreviewData = (data: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_QJE_PREVIEW_DATA,
    payload: data
  }
}

export const setSelectedLineGroups = (tasks: object[]): StoreAction => {
  return {
    type: ActionTypes.SET_SELECTED_LINE_GROUPS,
    payload: tasks
  }
}

export const setSelectedPeriod = (period: { period: any }): StoreAction => {
  return {
    type: ActionTypes.SET_SELECTED_PERIOD,
    payload: period
  }
}

export const setSelectedFilter = (filter: {
  id: any
  label: string
}): StoreAction => {
  return {
    type: ActionTypes.SET_SELECTED_FILTER,
    payload: filter
  }
}

export const setSelectedForBulkUpdate = (lineGroups: any[]): StoreAction => {
  return {
    type: ActionTypes.SET_SELECTED_FOR_BULK_UPDATE,
    payload: lineGroups
  }
}

export const setIsOnEditReviewed = (lineGroups: any[]): StoreAction => {
  return {
    type: ActionTypes.SET_IS_ON_EDIT_REVIEWED,
    payload: lineGroups
  }
}

export const setReviewedEditKey = (lineGroup: string): StoreAction => {
  return {
    type: ActionTypes.SET_REVIEWED_EDIT_KEY,
    payload: lineGroup
  }
}
