import { SearchOutlined } from '@ant-design/icons'
import { Col, Input, message, Popconfirm, Row, Switch, Table } from 'antd'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { FC, FormEvent, KeyboardEvent, useEffect, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import GButton from '../../components/gappify/GButton'
import { updateTxnManagerColumns } from '../../services/TrdManagerAPI'
import TransactionManagerColumn from '../../types/TransactionManagerColumn'
import getTransactionColumnManagerColumns from './utils/getTransactionColumnManagerColumns'
import loadTxnManagerData from './utils/loadTxnManagerData'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

const TransactionColumnManager: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [dataSource, setDataSource] = useState<TransactionManagerColumn[]>([])
  const [dataSourceCopy, setDataSourceCopy] = useState<
    TransactionManagerColumn[]
  >([])

  const RP = useRolesAndPermissions()

  const handleOnChangeSwitch: SwitchChangeEventHandler = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setCanEdit(checked)
    if (!checked) {
      loadTxnManagerData(setIsLoading, setDataSource, setDataSourceCopy)
    }
  }

  const handleCancelOnClick = (): void => {
    setCanEdit(false)
    loadTxnManagerData(setIsLoading, setDataSource, setDataSourceCopy)
  }

  const handleSaveOnClick = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const payload = dataSource.map((item) => {
        item.label.trim()
        if (!item.label) {
          item.label = item.api_name
        }
        return item
      })
      await updateTxnManagerColumns(payload)
      message.success('Success')
    } catch (error) {
      console.log(error)
      message.error('Something went wrong')
    } finally {
      loadTxnManagerData(setIsLoading, setDataSource, setDataSourceCopy)
      setCanEdit(false)
      setIsLoading(false)
      setSearchValue('')
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.toLowerCase()
    const filteredRows = dataSourceCopy.filter((item) => {
      const apiName = item.api_name.toLowerCase()
      const label = item.label.toLowerCase()
      return apiName.match(value) || label.match(value)
    })

    setDataSource(filteredRows)
  }

  const handleSearchInputFieldChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSearchValue(value)
  }

  const handleOnDragEnd = (fromIndex: number, toIndex: number) => {
    const list = [...dataSourceCopy]
    const item = list.splice(fromIndex - 1, 1)[0]
    list.splice(toIndex - 1, 0, item)

    const data = list.map((item, index) => {
      item.weight = index
      return item
    })
    setDataSource(data)
    setDataSourceCopy(data)
  }

  useEffect(() => {
    loadTxnManagerData(setIsLoading, setDataSource, setDataSourceCopy)
  }, [])

  useEffect(() => {
    if (!searchValue) {
      setDataSource(dataSourceCopy)
    }
  }, [dataSourceCopy, searchValue])

  return (
    <div data-testid='transaction-column-manager'>
      <div
        className='txn-panel-container'
        data-testid='transaction-column-manager-panel'
        data-cy='transaction-column-manager-panel'
      >
        <Row>
          <Col sm={24} lg={6} className='txn-panel-left'>
            <Input
              data-testid='transaction-column-manager-panel-search-input-field'
              data-cy='transaction-column-manager-panel-search-input-field'
              value={searchValue}
              placeholder='Search'
              prefix={<SearchOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>}
              style={{ borderRadius: '32px' }}
              onChange={handleSearchInputFieldChange}
              onPressEnter={handleSearch}
              allowClear
            />
          </Col>
          <Col
            sm={24}
            lg={12}
            className='txn-panel-center'
            data-testid='transaction-column-manager-panel-btn-section'
            data-cy='transaction-column-manager-panel-btn-section'
          >
            {canEdit && (
              <>
                <Popconfirm
                  title='Are you sure you want to save?'
                  okText='Yes'
                  cancelText='No'
                  onConfirm={handleSaveOnClick}
                  placement='left'
                >
                  <GButton
                    dataTestId='transaction-column-manager-panel-save-btn'
                    dataCy='transaction-column-manager-panel-save-btn'
                    btnText='Save'
                    shape='default'
                    type='primary'
                    // onClick={handleSaveOnClick}
                    disabled={
                      !RP.USER_HAS_TRANSACTION_COLUMN_MANAGER_CONSOLIDATED
                    }
                  />
                </Popconfirm>
                <GButton
                  dataTestId='transaction-column-manager-panel-cancel-btn'
                  dataCy='transaction-column-manager-panel-cancel-btn'
                  btnText='Cancel'
                  shape='default'
                  onClick={handleCancelOnClick}
                />
              </>
            )}
          </Col>
          <Col
            sm={24}
            lg={6}
            className='txn-panel-right'
            data-testid='transaction-column-manager-panel-edit-switch-section'
            data-cy='transaction-column-manager-panel-edit-switch-section'
          >
            <span>Edit</span>
            <Switch
              data-testid='transaction-column-manager-panel-edit-switch-btn'
              data-cy='transaction-column-manager-panel-edit-switch-btn'
              checked={canEdit}
              onChange={handleOnChangeSwitch}
            />
          </Col>
        </Row>
      </div>
      <ReactDragListView handleSelector='span' onDragEnd={handleOnDragEnd}>
        <Table
          data-testid='transaction-column-manager-table'
          data-cy='transaction-column-manager-table'
          dataSource={dataSource}
          columns={getTransactionColumnManagerColumns(
            canEdit,
            searchValue,
            dataSource,
            setDataSource
          )}
          loading={isLoading}
          pagination={false}
          style={{ marginTop: '10px' }}
          scroll={{ y: 'calc(70vh - 50px)' }}
        />
      </ReactDragListView>
    </div>
  )
}

export default TransactionColumnManager
