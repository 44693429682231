// ** React Imports
import { useEffect, useRef, useState } from 'react'

// ** AntD Imports
import { Modal, message } from 'antd'
import { Button, Form, FormInstance, Input, Select } from 'vincent-antd'

// ** Custom Component Imports
import Footer from './components/Footer'
import Options from './components/Options'

// ** Hook Imports
import useGlobalFilters from '../../../hooks/useGlobalFilter'

// ** Third Party Imports
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// ** Style Imports
import styles from './styles/global-filter.module.css'

// ** Zustand Imports
import useAStore from '../../../manager/analytics-store'
import useGFStore from './store/GlobalFilterStore'

// ** Type Imports
import { EModalActions, TFilterData } from './types/global-filter-types'
import useAdditionalOptions from '../../../hooks/useAdditionalOptions'

const success = (text: string) => {
  message.success(`${text} filter successfully!`)
}

const error = (text: string) => {
  message.error(text)
}

// =================================================================
const GlobalFilterModal = () => {
  // ** Refs **
  const formRef = useRef<FormInstance>(null)
  const [form] = Form.useForm()

  // ** State **
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [filterObject, setFilterObject] = useState<any>({})
  const [formListKey, setFormListKey] = useState(0)

  // ** Hooks **
  const {
    getPrebuild,
    handleCreateFilter,
    handleUpdateFilter,
    handleDeleteFilter,
    mutateFilterData,
    mutateFilterLoading,
    mutateFilterSuccess,
    mutateFilterError,
    mutatePreviewData,
    mutatePreviewLoading,
    mutatePreviewSuccess,
    mutatePreviewError
  } = useGlobalFilters()
  const { data: PrebuildData } = getPrebuild
  const { getMonthsToShow, getShowCount, getNonResponsive } =
    useAdditionalOptions()

  // ** Constants **
  const currentMonthsToShow = getMonthsToShow()
  const currentShowCount = getShowCount()
  const currentNonResponsive = getNonResponsive()

  // ** Zustand - Analytics Store **
  const reportType = useAStore((state) => state.reportType)
  const setShowPreview = useAStore((state) => state.setShowPreview)
  const responseType = useAStore((state) => state.responseType)
  const sortInternal = useAStore((state) => state.sortInternal)
  const sortVendor = useAStore((state) => state.sortVendor)
  const period = useAStore((state) => state.period)

  // ** Zustand - Global Filtering Store **
  const activeFilterObj = useGFStore((state) => state.activeFilterObj)
  const openFilterModal = useGFStore((state) => state.openFilterModal)
  const filterModalAction = useGFStore((state) => state.filterModalAction)
  const isPreviewMode = useGFStore((state) => state.isPreviewMode)
  const setActiveFilter = useGFStore((state) => state.setActiveFilter)
  const setActiveFilterObj = useGFStore((state) => state.setActiveFilterObj)
  const setActiveFilterId = useGFStore((state) => state.setActiveFilterId)
  const setPreviewFilterObj = useGFStore((state) => state.setPreviewFilterObj)
  const setOpenFilterModal = useGFStore((state) => state.setOpenFilterModal)
  const setIsPreviewMode = useGFStore((state) => state.setIsPreviewMode)
  const setIsPreviewModeDep = useGFStore((state) => state.setIsPreviewModeDep)
  const sessionFilter = useGFStore((state) => state.sessionFilter)
  const setSessionFilter = useGFStore((state) => state.setSessionFilter)

  // ** Modal initializations **
  const getModalTitle = () => {
    return filterModalAction === EModalActions.CREATE
      ? 'Create New Filter'
      : 'Update Filter'
  }

  // ** Form actions **
  const onFinish = (values: any) => {
    const { name, filters } = values

    // Make sures that at least one filter is required
    if (!filters || !filters.length) {
      setIsPreviewMode(0)
      error('You need to add at least one filter.')
      return
    }

    const filter_fields = filters.map((filter: any, index: number) => ({
      column: filter.field,
      value: filter.value
    }))

    const finalData: TFilterData = {
      name,
      report: reportType as string,
      filter_fields: filter_fields
    }

    const additionalOptions = {
      // Original active params
      order_by: responseType === 'internal' ? sortInternal : sortVendor,
      period: period,
      months_to_show: currentMonthsToShow,
      with_count: currentShowCount,
      non_responsive_only: currentNonResponsive,

      // New preview_mode payload
      preview_mode: 1
    }

    const finalDataForPreview = {
      ...finalData,
      ...additionalOptions
    }

    const getPayloadData = () => {
      if (isPreviewMode) {
        return finalDataForPreview
      }

      return finalData
    }

    if (filterModalAction === EModalActions.CREATE) {
      handleCreateFilter(getPayloadData())
    } else if (filterModalAction === EModalActions.UPDATE && activeFilterObj) {
      const filterId = activeFilterObj.key || (activeFilterObj.id as number)
      handleUpdateFilter(getPayloadData(), filterId)
    }
  }

  const onCancel = () => {
    setOpenFilterModal(false)
    setShowPreview(false)
    setPreviewFilterObj(null)
    updateFilterStates()

    if (isPreviewMode === 1) {
      /**
       * ? NOTE
       * ? If preview mode is from CREATE action, resets active filter to All
       */
      if (filterModalAction === EModalActions.CREATE) {
        setActiveFilter('all')
        setActiveFilterId(0)
      }

      /**
       * ? NOTE
       * ? If preview mode is from UPDATE action, just resets the isPreviewMode state
       * ? Also applied when from CREATE action
       */
      setIsPreviewMode(0)
      setIsPreviewModeDep(0)
    }
  }

  const getFiltersOptions = () => {
    if (!PrebuildData && !PrebuildData?.data) return []

    return Object.entries(PrebuildData.data || {}).map(
      ([key, value]: [string, any]) => {
        return {
          label: value.label,
          value: key,
          allOptions: value.options,
          disabled: activeFilters.includes(key)
        }
      }
    )
  }

  const getValuesOptionsByKey = (key: string) => {
    const thisFilterObject = getFiltersOptions().find(
      (element) => element.value === key
    )
    return thisFilterObject?.allOptions
  }

  /**
   * @params model: string
   * values: ADD_UPDATE, DELETE, defaults to resetting all
   *
   * @params formItemData: object
   * formItemData: {
   *    formItemKey: number
   *    selectValue: string
   *    selectOptions: options[]
   * }
   *
   * @params deleteKey: number
   * Used only if mode = DELETE
   */
  const updateFilterStates = (
    mode?: string,
    formItemData?: any,
    deleteKey?: number
  ) => {
    switch (mode) {
      case 'ADD_UPDATE': {
        const {
          formItemKey: key,
          formItemName: name,
          selectValue: value,
          selectOptions: options
        } = formItemData

        /**
         * This updates filterObject state.
         * This auto replaces value if key already exists.
         */
        let newFilter = {
          [key]: { value, options }
        }
        setFilterObject({ ...filterObject, ...newFilter })

        /**
         * This replaces old value of select option
         * if key already exists in filterObject,
         * else just push the new value
         */
        let newActiveFilters: string[] = []
        if (filterObject.hasOwnProperty(key)) {
          newActiveFilters = activeFilters.filter(
            (current) => current !== filterObject[key].value
          )
          newActiveFilters.push(value)
        } else {
          newActiveFilters = [...activeFilters, value]
        }
        setActiveFilters(newActiveFilters)

        /**
         * This resets the option dropdown selected values
         */
        let currentValues = form.getFieldsValue()
        currentValues.filters[name].value = []
        form.setFieldsValue(currentValues)

        break
      }

      case 'DELETE': {
        if (deleteKey !== undefined) {
          /**
           * Delete value from activeFilters state
           */
          let newActiveFilters = activeFilters.filter(
            (current) => current !== filterObject[deleteKey]?.value
          )
          setActiveFilters(newActiveFilters)

          /**
           * Delete value from filterObject state
           */
          const newFilterObject = filterObject
          delete newFilterObject[deleteKey]
          setFilterObject(newFilterObject)
        }
        break
      }

      default: {
        setActiveFilters([])
        setFilterObject({})
        form.resetFields()
        setFormListKey(formListKey + 1)
        break
      }
    }
  }

  const onMutateFilterSuccess = (data: any) => {
    const filterName = data?.name || ''
    setActiveFilter(filterName)

    /**
     * ? NOTE:
     * ? Prevent re-assigning activeFilterObj state to not change modal form values when re-opening preview modal
     */
    if (!isPreviewMode) {
      setActiveFilterObj(data)
      setPreviewFilterObj(null)

      if (reportType) {
        const sessionFilterObj = {
          [reportType]: {
            id: data.id,
            name: data.name,
            filterObj: data
          }
        }
        const newSessionFilter = { ...sessionFilter, ...sessionFilterObj }
        setSessionFilter(newSessionFilter)
      }
    }

    /**
     * ? NOTE:
     * ? Added OR operator in case data from API will be null
     * ? API should ALWAYS create a preview data, and will ALWAYS have a returned response ID
     * ? This is for debugging purposes & catching possible errors in the future
     */
    const previewFilterId = data?.id || 0
    setActiveFilterId(previewFilterId)
    setIsPreviewModeDep(isPreviewMode)
  }

  const isLoading = () => mutateFilterLoading || mutatePreviewLoading

  // ###########################################################
  // START: useEffects to watch state changes

  useEffect(() => {
    // Initializes form values & filter states when action is Update
    if (
      openFilterModal &&
      filterModalAction === EModalActions.UPDATE &&
      activeFilterObj &&
      /**
       * ? NOTE:
       * ? Prevent re-assigning activeFilterObj state to not change modal form values when re-opening preview modal
       */
      !isPreviewMode
    ) {
      let thisActiveFilters: string[] = []
      let thisFilterObject: any = {}
      let formValuesFilters: any[] = []

      const { filter_fields } = activeFilterObj
      JSON.parse(filter_fields).forEach((value: any, index: number) => {
        thisActiveFilters.push(value.column)

        formValuesFilters.push({
          field: value.column,
          value: value.value
        })
      })

      const thisFormValues = {
        name: activeFilterObj.label || activeFilterObj.name,
        filters: formValuesFilters
      }

      thisActiveFilters.forEach((value: string, index: number) => {
        thisFilterObject[index] = {
          value,
          options: getValuesOptionsByKey(value)
        }
      })

      form.setFieldsValue(thisFormValues)
      setActiveFilters(thisActiveFilters)
      setFilterObject(thisFilterObject)
    }
    // eslint-disable-next-line
  }, [openFilterModal])

  useEffect(() => {
    if (mutateFilterSuccess) {
      let action = ''
      switch (filterModalAction) {
        case EModalActions.CREATE: {
          action = 'Create'
          onMutateFilterSuccess(mutateFilterData.data)
          break
        }

        case EModalActions.UPDATE: {
          action = 'Update'
          onMutateFilterSuccess(mutateFilterData.data)
          break
        }

        case EModalActions.DELETE: {
          action = 'Delete'
          setActiveFilter('all')
          setActiveFilterObj(null)
          setActiveFilterId(0)
          setIsPreviewMode(0)
          setIsPreviewModeDep(0)

          if (reportType) {
            const newSessionFilter = { ...sessionFilter }
            delete newSessionFilter[reportType]
            setSessionFilter(newSessionFilter)
          }
          break
        }

        default:
          break
      }

      if (!isPreviewMode) {
        success(action)
        onCancel()
      } else {
        setOpenFilterModal(false)
      }
    }

    if (mutateFilterError)
      error('Something went wrong. Please try again later.')
    // eslint-disable-next-line
  }, [mutateFilterSuccess, mutateFilterError])

  useEffect(() => {
    if (mutatePreviewSuccess) {
      const previewQuery = mutatePreviewData?.data?.query || {}
      setPreviewFilterObj(previewQuery)
      setShowPreview(true)
      setOpenFilterModal(false)
    }

    if (mutatePreviewError)
      error('Something went wrong. Please try again later.')
    // eslint-disable-next-line
  }, [mutatePreviewSuccess, mutatePreviewError])

  /**
   * ? BUGFIX: https://gappify.atlassian.net/browse/AA-807
   * ? reportType state change will trigger reset state call/onCancel()
   */
  useEffect(() => {
    if (reportType) {
      /**
       * Added condition to just trigger reset state if no store-in-session was found for the current report
       */
      if (!sessionFilter || !sessionFilter[reportType]) {
        onCancel()
        setActiveFilter('all')
        setActiveFilterId(0)
        setActiveFilterObj(null)
      } else {
        /**
         * Initializes filter dropdown/modal states if store-in-session filter was found for the current report
         */
        setActiveFilter(sessionFilter[reportType].name)
        setActiveFilterId(sessionFilter[reportType].id)
        setActiveFilterObj(sessionFilter[reportType].filterObj)
      }

      setIsPreviewMode(0)
      setIsPreviewModeDep(0)
      setPreviewFilterObj(null)
    }
    // eslint-disable-next-line
  }, [reportType])

  // END: useEffects to watch state changes
  // ###########################################################

  return (
    <Modal
      title={getModalTitle()}
      open={openFilterModal}
      onCancel={onCancel}
      width={1000}
      footer={
        <Footer
          formRef={formRef}
          onCancel={onCancel}
          handleDeleteFilter={handleDeleteFilter}
        />
      }
      className={isLoading() ? styles.loading : ''}
    >
      <Form
        ref={formRef}
        form={form}
        name='dynamic_form_nest_item'
        onFinish={onFinish}
        style={{ width: '100%' }}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label='Name'
          rules={[{ required: !!!isPreviewMode, message: 'Required Name' }]}
          name='name'
        >
          <Input />
        </Form.Item>

        <Form.List name='filters' key={formListKey}>
          {(fields, { add, remove }) => {
            const removeAll = () => {
              // Create an array of indices to remove
              const indices = fields.map((field, index) => index)
              // Pass all indices to the remove function
              remove(indices)
            }

            return (
              <>
                {fields?.map(({ key, name, ...restField }) => (
                  <div key={key} className={styles.formListWrapper}>
                    {/* Field dropdown */}
                    <Form.Item
                      {...restField}
                      label='Field'
                      name={[name, 'field']}
                      rules={[{ required: true, message: 'Required Field' }]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        placeholder='Select an item'
                        options={getFiltersOptions()}
                        onSelect={(value, option) => {
                          const filterData = {
                            formItemKey: key,
                            formItemName: name,
                            selectValue: value,
                            selectOptions: option.allOptions
                          }

                          updateFilterStates('ADD_UPDATE', filterData)
                        }}
                      />
                    </Form.Item>
                    {/* Values dropdown */}
                    <Form.Item
                      {...restField}
                      label='Value'
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Required Value' }]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        mode='multiple'
                        placeholder='Select one or more items'
                        options={filterObject[key]?.options?.map(
                          (value: any, index: number) => ({
                            label: value.value,
                            value: value.gsid
                          })
                        )}
                        optionFilterProp='label'
                      />
                    </Form.Item>
                    <Button
                      type='text'
                      className='g-btn-icon'
                      onClick={() => {
                        remove(name)
                        updateFilterStates('DELETE', {}, key)
                      }}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                    />
                  </div>
                ))}
                <Options
                  addFilter={() => add()}
                  removeAll={() => {
                    removeAll()
                    updateFilterStates()
                  }}
                />
              </>
            )
          }}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default GlobalFilterModal
