import { FC, useState } from 'react'
import { Input, Form, InputNumber, Select, DatePicker, Popconfirm } from 'antd'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { DownOutlined, DeleteOutlined } from '@ant-design/icons'
import TransactionFilterPanelProps from '../types/TransactionFilterPanelProps'
import GButton from './gappify/GButton'
import GIcon from './gappify/GIcon'
import GDropdownBtn from './gappify/GDropdownBtn'
import SaveFilterModal from './ SaveFilterModal'
import { callGetApi } from '../services/GenericAPI'
import filterSort from '../utils/filterSort'
import filterOption from '../utils/filterOption'
const { RangePicker } = DatePicker

const { Option } = Select

const ColumnFilterPanel: FC<TransactionFilterPanelProps> = ({
  columns,
  optionsList,
  onFilterChange,
  initialValues,
  hasClearButton,
  hasSaveFilter,
  onClickReset,
  saveFilterOptions,
  onSaveFilter,
  onClickSaveOptions,
  showSaveModal,
  onCancel,
  columnFilterDefault,
  onClickDeleteOptions
}: TransactionFilterPanelProps): JSX.Element => {
  const [form] = Form.useForm()
  const [filterValues, setFilterValues] = useState()
  const [groupedChoices, setGroupedChoices] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [label, setLabel] = useState('Save Filters')

  const onform = async (values: any, allValues: any) => {
    // change empty string to undefined
    Object.keys(allValues).forEach((key) => {
      if (allValues[key] === '' || allValues[key] === null) {
        allValues[key] = undefined
      }
    })

    setFilterValues(allValues)
    onFilterChange && onFilterChange(values, allValues)
  }

  const handleOnClickReset = () => {
    form.resetFields()
    setLabel('Save Filters')
    onClickReset && onClickReset()
  }

  const getOptions = (column: any) => {
    return optionsList[column.api_name]
  }

  const getSavedItems = () => {
    let formattedOptions: any = []
    const menu = [
      {
        label: (
          <div onClick={() => handleOnClickOption({ key: 'save' })}>
            Save current filter as new
          </div>
        ),
        key: 'save'
      },
      { type: 'divider' }
    ]

    saveFilterOptions?.forEach((item) => {
      formattedOptions.push({
        label: (
          <div className='filter-items'>
            <div onClick={() => handleOnClickOption(item)}>{item.label}</div>
            <Popconfirm
              overlayClassName={'filter-pop'}
              title={`Are you sure you want to delete ${item.label}?`}
              okText='Yes'
              cancelText='No'
              onConfirm={() => handleOnDelete(item)}
              placement='top'
              arrowPointAtCenter={false}
            >
              {!(
                item.key === 100000 ||
                item.key === 100001 ||
                item.key === 100002 ||
                item.key === 100003
              ) && 
              <DeleteOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />

              }
            </Popconfirm>
          </div>
        ),
        key: item.key
      })
    })

    return menu.concat(formattedOptions!)
  }

  const handleCancel = () => {
    form.resetFields()
    onCancel && onCancel()
  }

  const handleOnClickOption = (item: any) => {
    if (item.key !== 'save') {
      setLabel(item.label)
    }

    onClickSaveOptions && onClickSaveOptions(item, form)
  }

  const handleOnDelete = (item: any) => {
    if (item.label === label) {
      setLabel('Save Filters')
    }
    onClickDeleteOptions && onClickDeleteOptions(item, form)
  }

  return (
    <div
      className='table-column-filter'
      data-testid='column-filter-panel'
      data-cy='column-filter-panel'
    >
      <Form
        form={form}
        onValuesChange={onform}
        className={'filter-wrapper'}
        data-testid='column-filter-panel-form'
        data-cy='column-filter-panel-form'
      >
        {columns &&
          columns.map((item, i) => {
            const apiName = item.api_name ?? 'no-api-name'
            if (item.field_type === 'short_text') {
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <Input
                    placeholder={`${item.label ? item.label : item.title}`}
                    data-testid={`column-filter-panel-${apiName}-input-field`}
                    data-cy={`column-filter-panel-${apiName}-input-field`}
                  />
                </Form.Item>
              )
            } else if (item.field_type === 'numeric_accounting') {
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <InputNumber
                    placeholder={`${item.label ? item.label : item.title}`}
                    precision={2}
                    data-testid={`column-filter-panel-${apiName}-input-field`}
                    data-cy={`column-filter-panel-${apiName}-input-field`}
                  />
                </Form.Item>
              )
            } else if (item.field_type === 'numeric_standard') {
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <InputNumber
                    placeholder={`${item.label ? item.label : item.title}`}
                    data-testid={`column-filter-panel-${apiName}-input-field`}
                    data-cy={`column-filter-panel-${apiName}-input-field`}
                  />
                </Form.Item>
              )
            } else if (item.field_type === 'picklist_single') {
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <Select
                    data-testid={`column-filter-panel-${apiName}-picklist-field`}
                    data-cy={`column-filter-panel-${apiName}-picklist-field`}
                    mode='multiple' // for filter only: All picklist should be multiselect
                    onClick={async () => {
                      if (
                        item.options_source?.url &&
                        !Object.keys(groupedChoices).includes(item.api_name!) &&
                        item.api_name !== 'g_name'
                      ) {
                        setIsLoading(true)
                        const { data } = await callGetApi(
                          item.options_source.url + '&filter=true'
                        )
                        const copyGroupedChoices = { ...groupedChoices }
                        copyGroupedChoices[item.api_name!] = data
                        setGroupedChoices(copyGroupedChoices)
                        setIsLoading(false)
                      }
                    }}
                    placeholder={`${item.label ? item.label : item.title}`}
                    filterOption={filterOption}
                    filterSort={filterSort}
                    allowClear
                    showSearch
                  >
                    {Object.keys(groupedChoices).includes(item.api_name!) ? (
                      groupedChoices[item.api_name!].map((option: any) => (
                        <Option key={option.value} value={option.value}>
                          {option.value}
                        </Option>
                      ))
                    ) : isLoading ? (
                      <Option disabled>Loading options...</Option>
                    ) : (
                      getOptions(item) &&
                      getOptions(item).map(
                        (option: any) =>
                          option && (
                            <Option
                              key={
                                option.g_source_system_id
                                  ? option.g_source_system_id
                                  : option.id
                              }
                              value={
                                option.g_source_system_id
                                  ? option.g_source_system_id
                                  : option.id
                              }
                            >
                              {option.value}
                            </Option>
                          )
                      )
                    )}
                  </Select>
                </Form.Item>
              )
            } else if (item.field_type === 'picklist_multiple') {
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <Select
                    data-testid={`column-filter-panel-${apiName}-picklist-field`}
                    data-cy={`column-filter-panel-${apiName}-picklist-field`}
                    mode='multiple'
                    placeholder={`${item.label ? item.label : item.title}`}
                    filterOption={filterOption}
                    allowClear
                  >
                    {(optionsList || item.field_options) &&
                      getOptions(item) &&
                      getOptions(item).map(
                        (option: any) =>
                          option && (
                            <Option
                              key={
                                option.g_source_system_id
                                  ? option.g_source_system_id
                                  : option.id
                              }
                              value={
                                option.g_source_system_id
                                  ? option.g_source_system_id
                                  : option.id
                              }
                            >
                              {option.value}
                            </Option>
                          )
                      )}
                  </Select>
                </Form.Item>
              )
            } else if (item.field_type === 'date') {
              const placeholder = item.label ? item.label : item.title
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <RangePicker
                    data-testid={`column-filter-panel-${apiName}-date-field`}
                    data-cy={`column-filter-panel-${apiName}-date-field`}
                    defaultValue={undefined}
                    defaultPickerValue={undefined}
                    placeholder={[`From ${placeholder} `, `To ${placeholder}`]}
                  />
                </Form.Item>
              )
            } else if (item.field_type === 'date_range') {
              const placeholder = item.label ? item.label : item.title
              return (
                <Form.Item
                  key={i}
                  name={item.api_name ? item.api_name : item.dataIndex}
                >
                  <RangePicker
                    data-testid={`column-filter-panel-${apiName}-date-field`}
                    data-cy={`column-filter-panel-${apiName}-date-field`}
                    defaultValue={undefined}
                    defaultPickerValue={undefined}
                    placeholder={[`From ${placeholder} `, `To ${placeholder}`]}
                  />
                </Form.Item>
              )
            }
            return (
              <Form.Item
                key={i}
                name={item.api_name ? item.api_name : item.dataIndex}
              >
                <Input
                  placeholder={`${item.label ? item.label : item.title}`}
                  data-testid={`column-filter-panel-${apiName}-input-field`}
                  data-cy={`column-filter-panel-${apiName}-input-field`}
                />
              </Form.Item>
            )
          })}
      </Form>
      <div className='filter-btn-wrapper'>
        {hasSaveFilter && (
          <GDropdownBtn
            dataTestId='column-filter-panel-save-btn'
            dataCy='column-filter-panel-save-btn'
            overlayClassName='options-menu'
            menuItems={getSavedItems()}
            btnText={label}
            children={<DownOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
          }
            // onClick={handleOnClickOption}
          />
        )}
        {hasClearButton && (
          <GButton
            dataTestId='column-filter-panel-clear-btn'
            dataCy='column-filter-panel-clear-btn'
            type='primary'
            shape='default'
            btnText='Clear'
            icon={<GIcon icon={faRefresh} style={{ marginRight: '5px' }} />}
            onClick={handleOnClickReset}
            style={{ marginLeft: '10px' }}
          />
        )}
      </div>
      <SaveFilterModal
        filterValues={filterValues}
        visible={showSaveModal}
        onClickCancel={handleCancel}
        columns={columns}
        onSaveFilter={onSaveFilter}
      />
    </div>
  )
}

export default ColumnFilterPanel
