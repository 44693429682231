/** antd imports */
import { Button, Col, Row } from 'antd'

/** hooks imports */
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'
import useApiKeysFlag from './hooks/useApiKeysFlag'

/** components imports */
import ApiKeysTable from './components/ApiKeysTable'
import GenerateApiKeyModal from './components/GenerateApiKeyModal'
import TokenModal from './components/TokenModal'
import ActivateTokenModal from './components/ActivateTokenModal'

/** zustand store imports */
import useApiKeyStore from './manager/useApiKeyStore'

/** styles imports */
import styles from './styles/main.module.css'

// =================================================================
export default function ApiKeys() {
  /** zustand states */
  const { setIsGenerateApiKeyModalOpen } = useApiKeyStore()

  /** hooks */
  const RP = useRolesAndPermissions()
  useApiKeysFlag()

  return (
    <>
      <div
        className='table-content-wrapper'
        data-testid='integrations-api-keys-page'
        data-cy='integrations-api-keys-page'
      >
        <Row>
          <Col span={24} className={styles['generate-api-key-wrapper']}>
            <Button
              className='g-btn-default'
              shape='round'
              onClick={() => setIsGenerateApiKeyModalOpen(true)}
              disabled={!RP.USER_HAS_API_MANAGER_CREATE_PERMISSION}
              data-testid='generate-api-key-btn'
              data-cy='generate-api-key-btn'
            >
              Generate
            </Button>
          </Col>
        </Row>
        <ApiKeysTable />
      </div>
      <GenerateApiKeyModal />
      <TokenModal />
      <ActivateTokenModal />
    </>
  )
}
