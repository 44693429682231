export const AUTH_TOKEN: string = 'authToken'

export const EXPIRATION: string = 'expiration'

export const ROLES_AND_PERMISSIONS: string = 'rolesAndPermissions'

export const EXPIRY_COUNTER: string = 'expiryCounter'

export const SHOW_LOGOUT_MODAL: string = 'showLogoutModal'

export const MILLISECOND: number = 1000

export const MFA_TOKEN: string = 'mfaToken'

export const EXPIRES_ON = 'expirationOn'

export const TXN_TAB = 'txnTab'

export const IDLE_TIMER: string = 'idleTimer'

export const PENDO_METADATA: any = 'pendoMetadata'
