import { Modal, Table } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import type InvoiceDetail from '../types/InvoiceDetail'
import { getGlobalSettings } from '../services/TxnManagerAPI'
interface TxnLineLookupModalProps {
  data: Array<any>
  invoiceDetailsRange: number
  visible?: boolean
  loading?: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  setData: (value: SetStateAction<InvoiceDetail[]>) => void
  lookUpApi?: string
}

const TxnLineLookupModal: FC<TxnLineLookupModalProps> = ({
  visible,
  data,
  invoiceDetailsRange,
  loading,
  setModalVisible,
  setData,
  lookUpApi
}: TxnLineLookupModalProps): JSX.Element => {
  const [headers, setHeaders] = useState<any>([])

  const displayTextWidth = (text: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '13px Open Sans'
      return context!.measureText(text).width
    }
    return 150
  }

  const handleModalOnCancel = (): void => {
    setModalVisible(false)
    setData([])
  }

  const getInvoiceDetailsRange = (): number => {
    return invoiceDetailsRange === 90 ? 3 : invoiceDetailsRange === 180 ? 6 : 12
  }

  const removeVdac_toApi = (origApi: string | undefined) => {
    const vdacPrefix = 'vdac_'
    const openPrefix = 'open_'

    if (origApi && origApi.startsWith(vdacPrefix)) {
      origApi = origApi.substring(vdacPrefix.length)
    } else if (origApi && origApi.startsWith(openPrefix)) {
      origApi = origApi.substring(openPrefix.length)
    }

    return origApi
  }

  useEffect(() => {
    const getColumns = async () => {
      const { data } = await getGlobalSettings()
      const lookUp = data['transaction.lookup.headers']

      const newLookUpApi = removeVdac_toApi(lookUpApi)
      const columns = lookUp[`${newLookUpApi}`]

      if (columns) {
        setHeaders(
          columns.map((item: any) => {
            if (item.api_name === 'g_invoice_amount') {
              return {
                ...item,
                dataIndex: item.field_label,
                title: item.field_label,
                render: (item: string) => (
                  <div style={{ textAlign: 'right' }}>
                    {parseFloat(item)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                ),
                width: displayTextWidth(item.field_label)
              }
            } else {
              return {
                ...item,
                dataIndex: item.field_label,
                title: item.field_label,
                width: displayTextWidth(item.field_label)
              }
            }
          })
        )
      }
    }

    getColumns()
  }, [lookUpApi])

  return (
    <Modal
      data-testid='txn-line-lookup-modal'
      data-cy='txn-line-lookup-modal'
      title='Invoice Details'
      open={visible}
      okText='Close'
      width={1500}
      onCancel={handleModalOnCancel}
      onOk={handleModalOnCancel}
      okButtonProps={{
        loading: loading
      }}
      cancelButtonProps={{
        hidden: true
      }}
      destroyOnClose
    >
      Invoices Received in the last {getInvoiceDetailsRange()} Months
      <Table
        data-testid='txn-line-lookup-modal-table'
        data-cy='txn-line-lookup-modal-table'
        dataSource={data}
        columns={headers}
        pagination={false}
        loading={loading}
        size='small'
      />
    </Modal>
  )
}

TxnLineLookupModal.defaultProps = {
  visible: false,
  loading: false
}

export default TxnLineLookupModal
