const isOptionNotObject = (optionA: any, optionB: any): boolean => {
  return (
    optionA.children &&
    typeof optionA.children !== 'object' &&
    optionB.children &&
    typeof optionB.children !== 'object'
  )
}

export default function filterSort(optionA: any, optionB: any) {
  if (isOptionNotObject(optionA, optionB)) {
    let a = optionA.children
    let b = optionB.children
    if (typeof a === 'number') {
      a = a + ''
    }
    if (typeof optionB.children === 'number') {
      b = b + ''
    }
    return a.toLowerCase().localeCompare(b.toLowerCase())
  }
  return 0
}
