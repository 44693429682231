import { useQuery } from '@tanstack/react-query'
import useApiService from 'src/hooks/useApiService'
import useIntegrationLogsService from '../services/useIntegrationLogsService'

export default function useFetchColumnHeaders() {
  const { fetchColumnHeadersApi } = useIntegrationLogsService()
  const { msUrl } = useApiService()

  return useQuery({
    initialData: {
      fields: []
    },
    queryKey: ['logs-column-headers'],
    queryFn: () =>
      fetchColumnHeadersApi({
        options: {
          baseURL: msUrl.gappify_api,
          endpoint: '/api/log/filters/all-filter-fields',
          method: 'GET'
        }
      })
  })
}
