import { useEffect } from 'react'

// ** AntD Imports
import { Card, Tabs } from 'antd'

// ** Custom Component Imports
import AdditionalOptions from '../components/AdditionalOptions'
import AnalyticsDropdown from '../components/Dropdown'
import SpendComplianceTable from '../components/Table/SpendComplianceTable'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'
import NonCompliantTable from '../components/Table/NonCompliantTable'

// ** Constant imports
import { EReportTypes } from '../constants/Globals'

const TabItem = () => {
  return (
    <Card style={{ borderTopWidth: 0 }} bodyStyle={{ marginLeft: -16 }}>
      <AdditionalOptions
        showEmailButton={false}
        showActionButton={false}
        hasShowCount={false}
        hasShowNonResponsiveVendors={false}
      />
      <SpendComplianceTable />
    </Card>
  )
}

const TabItemNonCompliant = () => {
  return (
    <Card style={{ borderTopWidth: 0 }} bodyStyle={{ marginLeft: -16 }}>
      <AdditionalOptions
        showAdditionalOptions={false}
        showEmailButton={false}
        hasShowCount={false}
        hasShowNonResponsiveVendors={false}
      />
      <NonCompliantTable />
    </Card>
  )
}

const SpendCompliance = () => {
  // ** Zustand **
  const {
    setCostCuttingTabView,
    setPage,
    setNonCompliantTablePage,
    setReportType
  } = useAnalyticsStore()

  const tabItems = [
    {
      label: `Departments`,
      key: '1',
      children: <TabItem />
    },
    {
      label: `Employees`,
      key: '2',
      children: <TabItem />
    },
    {
      label: `Non-Compliant Invoices`,
      key: '3',
      children: <TabItemNonCompliant />
    }
  ]

  const resetPages = () => {
    setPage(0)
    setNonCompliantTablePage(0)
  }

  const onTabClick = (key: string) => {
    resetPages()
    switch (key) {
      case '1': {
        setCostCuttingTabView('department')
        setReportType(EReportTypes.POCDepartment)
        break
      }
      case '2': {
        setCostCuttingTabView('employee')
        setReportType(EReportTypes.POCEmployee)
        break
      }
      case '3': {
        setCostCuttingTabView('action')
        setReportType(EReportTypes.POCNonCompliant)
        break
      }
      default:
        break
    }
  }

  useEffect(() => {
    // Default tab view
    setCostCuttingTabView('department')
    setReportType(EReportTypes.POCDepartment)
    resetPages()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AnalyticsDropdown />
      <Tabs
        defaultActiveKey='1'
        type='card'
        size='small'
        tabBarStyle={{ margin: 0 }}
        items={tabItems.map((tabItem) => {
          return { ...tabItem, disabled: false }
        })}
        onTabClick={onTabClick}
        style={{ marginLeft: 18 }}
      />
    </>
  )
}

export default SpendCompliance
