import { FC, SetStateAction } from 'react'
import { Modal, Form, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { updateTrdTransactions } from '../services/TrdManagerAPI'
import type { AxiosError } from 'axios'
import _ from 'underscore'

interface ConfirmSubmitEditModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  onOk?: (() => void) | ((reload?: boolean) => void)
  editedRows: any[]
  setErrorFields: (value: SetStateAction<any>) => void
  errorFields?: any
  requireOverrideExp?: boolean
  setEditedRows: (value: SetStateAction<any>) => void | undefined
}

const ConfirmSubmitEditModal: FC<ConfirmSubmitEditModalProps> = ({
  visible,
  setModalVisible,
  onOk,
  editedRows,
  setErrorFields,
  errorFields,
  requireOverrideExp,
  setEditedRows
}: ConfirmSubmitEditModalProps): JSX.Element => {
  const [form] = Form.useForm()

  const handleSubmit = async (values: any) => {
    if (_.isEmpty(errorFields)) {
      if (editedRows.length > 0) {
        let payload = editedRows.map((item: any) => {
          return {
            ...item,
            ...values
          }
        })

        form.resetFields()
        try {
          const { data } = await updateTrdTransactions(payload)

          if (data.success) {
            message.success('Changes successfully saved!')
          }

          setErrorFields([])
          setEditedRows([])
          onOk && onOk(true)
        } catch (error) {
          const { response } = error as AxiosError
          const errMsg: any = response?.data
          message.error(errMsg.message)
          setErrorFields(errMsg.error_message)

          let errObject = Object.entries(errorFields)
          let errMsgList: any[] = []
          errObject &&
            errObject.forEach(([key, value]) => {
              let item: any = value
              let valueObj = Object.entries(item)

              valueObj &&
                valueObj.forEach(([key, value]) => {
                  let msg: any = value
                  if (errMsgList.includes(msg[0]) || errMsgList.length === 0) {
                    errMsgList.push(msg)
                  }
                })
            })
          errMsgList.forEach((item) => message.error(item))
        }
      }
    } else {
      message.error('Your changes could not be saved.')

      let errObject = Object.entries(errorFields)
      let errMsgList: any[] = []
      errObject &&
        errObject.forEach(([key, value]) => {
          let item: any = value
          let valueObj = Object.entries(item)

          valueObj &&
            valueObj.forEach(([key, value]) => {
              let msg: any = value
              if (errMsgList.includes(msg[0]) || errMsgList.length === 0) {
                errMsgList.push(msg)
              }
            })
        })
      errMsgList.forEach((item) => message.error(item))
      onOk && onOk()
    }
  }

  return (
    <Modal
      data-testid='confirm-submit-edit-modal'
      data-cy='confirm-submit-edit-modal'
      open={visible}
      onOk={form.submit}
      title={'Override explanation'}
      onCancel={() => setModalVisible(false)}
      closable={false}
      centered
    >
      <Form layout='vertical' form={form} onFinish={handleSubmit}>
        <Form.Item
          label='Please provide an override explanation before selecting confirm below'
          name='override_reason'
          rules={[
            {
              required: requireOverrideExp,
              message: `This is a required field.`
            }
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

ConfirmSubmitEditModal.defaultProps = {
  visible: false
}

export default ConfirmSubmitEditModal
