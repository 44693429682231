// ** AntD Imports
import { Layout } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

// ** Constants Imports
import listInfo from '../../constants/ListInfo'

// ** Custom Component Imports
import CollapsibleList from './List'
import CollapsibleTitle from './CollapsibleTitle'

// ** Hooks Imports
import useCollapsible from '../../hooks/useCollapsible'

// ** Style Imports
import styles from '../../styles/collapsible.module.css'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
const Collapsible = () => {
  // ** MUI **
  const { Sider } = Layout

  // ** Zustand **
  const { collapsed, hovered } = useAnalyticsStore()

  // ** Hooks **
  const { handleToggle, handleMouseEnter, handleMouseLeave } = useCollapsible()

  return (
    <Sider
      className={styles.sider}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={20}
      width={230}
      data-testid='main-collapsible'
    >
      <div className={styles.wrapper} data-testid='collapsible-btn'>
        <RightCircleOutlined
          onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
          onClick={handleToggle}
          className={`${hovered ? styles.hovered : styles.btn}`}
          style={{
            right: collapsed ? '-10px' : '300px',
            display: collapsed ? 'block' : 'none'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <LeftCircleOutlined
          onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
          onClick={handleToggle}
          className={`${hovered ? styles.hovered : styles.btn}`}
          style={{
            right: collapsed ? '300px' : '-10px',
            display: collapsed ? 'none' : 'block'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      <CollapsibleTitle />
      <CollapsibleList
        title={listInfo[0].title}
        data={listInfo[0].data}
        paramName='cost-cutting'
      />
      <CollapsibleList
        title={listInfo[1].title}
        data={listInfo[1].data}
        paramName='confirmation-response-rates'
      />
    </Sider>
  )
}

export default Collapsible
