import { MoreOutlined } from '@ant-design/icons'
import { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import GDropdownBtn from '../../../../../components/gappify/GDropdownBtn'
import TransactionManagerColumn from '../../../../../types/TransactionManagerColumn'
import AccrualRule from '../types/AccrualRule'
import handleDropdownOnClick from './handleDropdownOnClick'
import { SetStateAction } from 'react'

export default function getActionItems(
  record: AccrualRule,
  loadDataSource: () => Promise<void>,
  setModalVisible: (value: SetStateAction<boolean>) => void,
  setEditRecord: (value: SetStateAction<AccrualRule | undefined>) => void
): JSX.Element {
  const accrualRuleActionItems: MenuItemType[] = []

  const isAccrualRule = (
    record: AccrualRule | TransactionManagerColumn
  ): record is AccrualRule => {
    return 'default' in (record as AccrualRule)
  }

  if (isAccrualRule(record)) {
    if (record.default) {
      accrualRuleActionItems.push({
        key: 'activate',
        label: `${record.status ? 'Deactivate' : 'Activate'} Rule`
      })
    } else {
      accrualRuleActionItems.push(
        {
          key: 'edit',
          label: 'Edit'
        },
        {
          key: 'activate',
          label: `${record.status ? 'Deactivate' : 'Activate'} Rule`
        },
        {
          key: 'delete',
          label: 'Delete Rule'
        }
      )
    }
  }

  return (
    <span className='data-table-action'>
      <GDropdownBtn
        menuItems={accrualRuleActionItems}
        btnText=''
        btnIcon={<MoreOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}  />}
        onClick={(event: MenuItemType) =>
          handleDropdownOnClick(
            record.id,
            loadDataSource,
            setModalVisible,
            setEditRecord,
            record,
            event?.key
          )
        }
      />
    </span>
  )
}
