// ** React Query imports **
import { useMutation } from '@tanstack/react-query'

// ** Custom Hooks **
import useApiService from '../../../hooks/useApiService'
import useAccrualManagerstore from '../manager/tasks-store'

// ** API Services Imports **
import useAccrualManagerService from '../services/AccrualManagerService'

// ** AntD Imports **
import { message } from 'antd'

// =================================================================
export default function useImportAction() {
  // ** API Services **
  const { AccrualManagerAPI } = useAccrualManagerService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** Zustand Store **
  const { setshowImportModal, setImportFile, setShowConfirmModal } =
    useAccrualManagerstore()

  // ** POST METHODS **
  const {
    mutate: importAction,
    isLoading: isLoadingImport,
    isSuccess: isSuccessImport,
    isError: isErrorImport
  } = useMutation(AccrualManagerAPI, {
    onSuccess: (response) => {
      // ** After succesfull API call, do something... **
      setShowConfirmModal(false)
      setshowImportModal(false)
      setImportFile(null)
      message.success('Upload successful.')
    },
    onError: ({ data }) => {
      let { error } = data
      // Check if backend response contains `error` property
      if (error) {
        message.error(error)
      } else message.error('An error occurred. Try again.')
    }
  })

  const handleImportAction = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    importAction({
      data: payload,
      options: {
        baseURL: msUrl.taskmanager,
        endpoint: endpoint,
        method: 'POST',
        customHeaders: {
          'Content-Type': 'multipart/form-data'
        }
      }

      // ** Usage of customHeaders **
      // customHeaders: {
      //   'User-Token': `${localStorage?.getItem('authToken')}`
      // }
    })
  }

  return {
    isLoadingImport,
    isSuccessImport,
    isErrorImport,
    handleImportAction
  }
}
