/** antd imports */
import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** hooks imports */
import useFetchWorkDaySettings from '../../hooks/useFetchWorkDaySettings'

/** zustand store imports */
import useDimentionFieldsStore from '../../manager/useDimentionFieldsStore'
import useWorkDayStore from '../../manager/useWorkdayStore'

/** types imports */
import IFormItem from '../../types/IFormItem'

/** custom components imports */
import FormItem from './FormItem'

// =================================================================
export default function DimensionFieldsFormItems() {
  /** hooks */
  const { data, isSuccess } = useFetchWorkDaySettings()

  /** zustand store */
  const formItems = useDimentionFieldsStore((state) => state.formItems)
  const setFormItems = useDimentionFieldsStore((state) => state.setFormItems)
  const setSelectedInternalAttributes = useDimentionFieldsStore(
    (state) => state.setSelectedInternalAttributes
  )
  const isEditMode = useWorkDayStore((state) => state.isEditMode)

  useEffect(() => {
    if (isSuccess && data && data.data.params.dimension_values) {
      const coaOptionKeys = data.data.params.dimension_values
      const coas: IFormItem[] = Object.keys(coaOptionKeys).map(
        (value, index) => {
          const key = index
          return {
            key,
            field: (
              <FormItem
                key={key}
                index={key}
                selectedInternalAttribute={value}
                selectedDimensionValue={coaOptionKeys[value]}
              />
            )
          }
        }
      )
      setFormItems(coas)
      setSelectedInternalAttributes(Object.keys(coaOptionKeys))
    }
  }, [data, isSuccess, setFormItems, setSelectedInternalAttributes])

  const addField = () => {
    const formItemsLength = formItems.length
    const key = formItemsLength === 0 ? formItemsLength : getGreatestKey() + 1
    const newItem = {
      key,
      field: <FormItem key={key} index={key} />
    }
    setFormItems([...formItems, newItem])
  }

  const getGreatestKey = () => {
    let greatestKey = 0
    formItems.forEach((item) => {
      if (item.key > greatestKey) {
        greatestKey = item.key
      }
    })
    return greatestKey
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch'
        }}
      >
        <h2>Dimension Field(s)</h2>
        <Button
          style={{
            color: '#725bb4'
          }}
          icon={
            <PlusOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          type='text'
          onClick={addField}
          disabled={!isEditMode}
          data-testid='workday-add-list-btn'
          data-cy='workday-add-list-btn'
        >
          Add list
        </Button>
      </div>
      {formItems.length > 0 && (
        <Row>
          <Col span={11}>
            <div className='ant-col ant-form-item-label'>
              <label
                className='ant-form-item-required'
                title='Internal Attribute / Field'
              >
                Internal Attribute / Field
              </label>
            </div>
          </Col>
          <Col span={11}>
            <div className='ant-col ant-form-item-label'>
              <label className='ant-form-item-required' title='Dimension Value'>
                Dimension Value
              </label>
            </div>
          </Col>
          <Col span={2}></Col>
        </Row>
      )}
      {formItems.map((item) => item.field)}
    </>
  )
}
