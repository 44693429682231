import setForbiddenError from '../../../../../context/actions/forbiddenErrorAction'
import store from '../../../../../context/store'
import HttpStatus from '../../../../../helpers/constants/HttpStatus'
import resendActivationLink from '../services/resendActivationLink'
import ResponseData from '../types/ResponseData'

export default function useResendActivationLink() {
  const resend = async (userId: number) => {
    try {
      const data: ResponseData = await resendActivationLink(userId)
      return data
    } catch (error: any) {
      const data: ResponseData = error.response.data

      if (error.response.status === HttpStatus.HTTP_FORBIDDEN) {
        store.dispatch(
          setForbiddenError({
            visible: true,
            message: error.response.data.message
          })
        )
      }

      return data
    }
  }

  return { resend }
}
