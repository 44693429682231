// ** AntD Imports
import {
  TablePaginationConfig,
  FilterValue,
  TableRowSelection
} from 'vincent-antd/node_modules/antd/es/table/interface'

// ** Constant Imports
import listInfo from '../constants/ListInfo'

// ** Type Imports
import TableRecord from '../types/TableRecord'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'

// =================================================================
export default function useTable(
  // page: number,
  data?: unknown[]
) {
  // ** Zustand **
  const {
    selectedItem,
    setInternalPage,
    setVendorPage,
    selectedRowKeys,
    setSelectedRowKeys,
    setSortInternal,
    setSortVendor,
    responseType,
    setSelectedRows
  } = useAnalyticsStore()

  const handleTableChange = <T extends Record<string, any>>(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: T
  ) => {
    switch (selectedItem) {
      case listInfo[1].data[0]:
        setInternalPage(pagination.current || 1)
        break
      case listInfo[1].data[1]:
        setVendorPage(pagination.current || 1)
        break
      default:
        setInternalPage(pagination.current || 1)
        break
    }

    // ** Normal Sorting **
    if (sorter.field !== undefined) {
      const column = sorter.field.toString()
      const order = sorter.order === 'ascend' ? 'asc' : 'desc'

      if (responseType === 'internal') setSortInternal(`${column}:${order}`)
      else setSortVendor(`${column}:${order}`)
    }

    // ** Comment out for Chain Sorting **

    // if (sorter.field !== undefined) {
    //   const column = sorter.field.toString();
    //   const order = sorter.order;
    //   const index = sortState.findIndex((item) => item.column === column);

    //   let newSortState = [...sortState];
    //   if (index === -1) {
    //     // New column, add to sort state
    //     newSortState.push({ column, order });
    //   } else {
    //     // Existing column, update order
    //     newSortState[index].order = order;
    //   }

    //   setSortState(newSortState);

    //   // Join all entries in the format 'column:order' and separate by commas
    //   const sortString = newSortState
    //     .filter(item => item.order !== null)
    //     .map((item) => `${item.column}:${item.order === 'ascend' ? 'asc' : 'desc'}`)
    //     .join(',');

    //   setSort(sortString);
    // }

    // ** Logic for reseting selection state upon pagination **
    const currentSelectedRowKeys = selectedRowKeys
    if (selectedItem) {
      currentSelectedRowKeys[selectedItem] = []
    } else {
      currentSelectedRowKeys.default = []
    }
    setSelectedRowKeys({ ...currentSelectedRowKeys })
    setSelectedRows([])
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (selectedItem) {
      setSelectedRowKeys({
        ...selectedRowKeys,
        [selectedItem]: newSelectedRowKeys
      })
    } else {
      setSelectedRowKeys({ ...selectedRowKeys, default: newSelectedRowKeys })
    }
  }

  const rowSelection: TableRowSelection<TableRecord> = {
    selectedRowKeys: selectedItem
      ? selectedRowKeys[selectedItem] || []
      : selectedRowKeys.default || [],
    onChange: onSelectChange,

    // Comment out for disable purposes
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.status === "Failed",
    // }),

    onSelect: (record, selected, selectedRows) => {
      getCurrentSelectedKeys(selectedRows)
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      getCurrentSelectedKeys(selectedRows)
    }
  }

  const getCurrentSelectedKeys = (selectedRows: TableRecord[]) => {
    const selectedKeys: string[] = []
    selectedRows.forEach((row) => {
      selectedKeys.push(row.key)
    })
    setSelectedRows(selectedKeys)
    console.log('setSselectedRows', selectedKeys)
  }

  return {
    handleTableChange,
    rowSelection
  }
}
