//  ** React Imports **
import { ChangeEvent } from 'react'

// ** AntD Imports
import { ImportOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Input, Collapse, message } from 'antd'

// ** Custom Components **
import GButton from '../../../components/gappify/GButton'

// ** Hooks **
import useImportAction from '../hooks/useImportAction'

// ** Zustand imports **
import useAccrualManagerstore from '../manager/tasks-store'

// ** Utils **
import { getFieldsLabel } from '../utils/import-utils'
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

const { Panel } = Collapse

interface AccrualImportModalType {
  visible?: boolean
}

// =================================================================
const AccrualImportModal = ({ visible }: AccrualImportModalType) => {
  const maxFileSizeMB = 50

  // ** Zustand store **
  const {
    showImportModal,
    columnFields,
    immutableFieldsApiNameVendor,
    immutableFieldsApiNamePO,
    importFile,
    showConfirmModal,
    hasError,
    errorMsg,

    //setter
    setshowImportModal,
    setImportFile,
    setShowConfirmModal,
    setHasError

    //function
  } = useAccrualManagerstore()

  // ** Hooks **
  const { handleImportAction } = useImportAction()
  const RP = useRolesAndPermissions()

  // ** Functions **
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const fileLimit = maxFileSizeMB * 1024 * 1024 // Convert MB to bytes
    const file = event.target.files?.[0]

    if (file) {
      if (file.size < fileLimit) {
        setImportFile(file)
        setHasError(false)
      } else {
        setImportFile(null)
        setHasError(true)
        message.error(errorMsg)
      }
    } else {
      setImportFile(null)
    }
  }

  const onConfirmImport = () => {
    const companyId = JSON.parse(
      localStorage.getItem('rolesAndPermissions') as string
    )?.company_id
    // ** Implementation using react-query **
    handleImportAction({
      endpoint: '/api/accrual-manager/upload',
      payload: {
        csv: importFile,
        'tenant-id': companyId
      }
    })
  }

  return (
    <>
      <GButton
        type='ghost'
        btnText='Import'
        icon={
          <ImportOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        }
        onClick={() => setshowImportModal(true)}
        disabled={!RP.USER_HAS_IMPORT_ACCRUAL_MANAGER_PERMISSION}
      />
      <Modal
        zIndex={1}
        data-testid='upload-file-modal'
        data-cy='upload-file-modal'
        open={visible || showImportModal}
        title={'Import Accrual Manager'}
        okText='Import'
        onCancel={() => {
          setshowImportModal(false)
          setImportFile(null)
          setHasError(false)
        }}
        // onOk={handleUpload}
        okButtonProps={{
          shape: 'round',
          disabled: importFile ? false : true,
          onClick: () => setShowConfirmModal(true)
        }}
        cancelButtonProps={{ hidden: true }}
        width={800}
        centered
        destroyOnClose
      >
        <div>
          Step 1: Download the Accrual Manager to CSV and make the desired
          changes you wish to import back into Gappify.
        </div>
        <div style={{ paddingTop: '15px' }}>
          Step 2: To reduce errors during the import process, ensure formatting
          for all columns is consistent throughout the file.
        </div>
        <div style={{ paddingTop: '15px' }}>
          Step 3: Save the updated Accrual Manager file as a CSV to your local
          computer.
        </div>
        <div style={{ paddingTop: '15px' }}>
          Step 4: Attach the CSV file below by selecting “Choose File”.
        </div>

        <div style={{ paddingTop: '15px' }}>
          Step 5: Select the Import button below.
        </div>
        <div
          style={{
            paddingTop: '15px',
            fontStyle: 'italic'
          }}
        >
          <Input
            type='file'
            accept='.csv'
            status={hasError ? 'error' : ''}
            placeholder='Upload file'
            style={{ width: '60%' }}
            onChange={handleFileUpload}
            data-testid='imported-accrual-file-upload-field'
            data-cy='imported-accrual-file-upload-field'
          />
          <p
            style={{
              paddingTop: '5px',
              paddingLeft: '5px'
            }}
          >
            Max Size for CSV Files Imported is: 50 MB
          </p>
        </div>
        <Collapse className='accrual-import-collapse'>
          <Panel header='Fields that will not be updated upon import' key='1'>
            <p>
              Vendor Tasks:{' '}
              {getFieldsLabel(immutableFieldsApiNameVendor, columnFields).join(
                ', '
              )}
            </p>
            <p>
              PO Tasks:{' '}
              {getFieldsLabel(immutableFieldsApiNamePO, columnFields).join(
                ', '
              )}
            </p>
          </Panel>
        </Collapse>
      </Modal>
      <Modal
        centered
        zIndex={2}
        closable={false}
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onOk={() => {
          onConfirmImport()
        }}
        title={
          <>
            <ExclamationCircleOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              style={{ color: '#1677ff' }}
            />{' '}
            Are you sure?
          </>
        }
      >
        <p>
          Importing a new file will update the Accrual Manager and any/all
          fields where the import differs from the source data.
        </p>
      </Modal>
    </>
  )
}

export default AccrualImportModal
