// ** Hook Imports
import useApiService from "../hooks/useApiService";

// ** Type Imports
interface BaseProps<TParams, TData, TOptions> {
    params?: TParams;
    data?: TData;
    options: TOptions;
}

interface Params {
    email?: string;
    uid?: string | null;
}

interface Data {
    email?: string | "";
    token_duration?: number;
}

interface Options {
    baseURL: string | "";
    endpoint: string | "";
    method: "GET" | "POST" | "PUT" | "DELETE";
    withCredentials?: boolean;
    customHeaders?: Record<string, string>;
}

// =================================================================
export default function useResetPasswordModalService() {
    const { apiRequest } = useApiService();

    const ResetPasswordModalAPI = (props: BaseProps<Params, Data, Options>) => {
        return apiRequest(
            props.options.baseURL,
            props.options.endpoint,
            props.options.method,
            props.options.customHeaders,
            props.params,
            props.data,
            props.options.withCredentials
        );
    };

    return {
        ResetPasswordModalAPI,
    };
};
