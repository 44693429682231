// ** Zustand Imports
import { create } from 'zustand'

// ** Types
import UnapprovedInvoicesProps from '../types/UnapprovedInvoicesProps'

const useUnapprovedInvoicesStore = create<UnapprovedInvoicesProps>(
  (set, get) => ({
    // Define initial state
    isDirty: false,
    isEditMode: false,
    isSavingCompanySettings: false,
    invoiceStatusesValues: [],
    newStatus: '',

    // Define actions
    setIsDirty: (isDirty: boolean) => set({ isDirty }),
    setIsEditMode: (isEditMode: boolean) => set({ isEditMode }),
    setIsSavingCompanySettings: (isSavingCompanySettings: boolean) =>
      set({ isSavingCompanySettings }),
    setInvoiceStatusesValues: (invoiceStatusesValues: string[]) =>
      set({ invoiceStatusesValues }),
    setNewStatus: (newStatus: string) => set({ newStatus })
  })
)

export default useUnapprovedInvoicesStore
