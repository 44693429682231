import { Form, Input, Modal } from 'antd'
import { FC, SetStateAction } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { postRejectJE } from '../../services/QjeManagerApi'
import type { AxiosError } from 'axios'

interface RejectJEModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  onSuccess?: () => void
  onError?: () => void
  lineGroups: string[]
  selectedLineGroupsRef: any
}

const RejectJEModal: FC<RejectJEModalProps> = ({
  visible,
  setModalVisible,
  onError,
  onSuccess,
  lineGroups,
  selectedLineGroupsRef
}: RejectJEModalProps): JSX.Element => {
  const [form] = Form.useForm()

  const handleCancelModal = (): void => {
    setModalVisible(false)
    form.resetFields()
  }

  const submitRejectJE = async (): Promise<void> => {
    try {
      const payload = {
        line_groups: selectedLineGroupsRef.current.filter(
          (value: any, index: any, self: any) => {
            // only get unique values
            return self.indexOf(value) === index
          }
        ),
        je_reject_reason: form.getFieldValue('reject_je_reason')
      }

      await postRejectJE(payload)
      handleCancelModal()
      onSuccess && onSuccess()
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  return (
    <Modal
      data-testid='qje-reject-je-modal'
      data-cy='qje-reject-je-modal'
      open={visible}
      title={
        <>
          <ExclamationCircleOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} style={{ color: '#faad14' }} /> Reject JE
        </>
      }
      width={900}
      okText='Confirm'
      onCancel={handleCancelModal}
      onOk={form.submit}
      closable={false}
      centered
      destroyOnClose
    >
      <Form
        data-testid='qje-reject-je-form'
        data-cy='qje-reject-je-form'
        form={form}
        layout='vertical'
        onFinish={submitRejectJE}
      >
        <Form.Item
          data-testid='qje-reject-je-reason-form-item'
          data-cy='qje-reject-je-reason-form-item'
          label='Please provide a brief explanation as to why this JE is being rejected before selecting confirm below.'
          name='reject_je_reason'
          rules={[
            { required: true, message: 'Reject reason field is required' }
          ]}
        >
          <Input
            data-testid='qje-reject-je-reason-input'
            data-cy='qje-reject-je-reason-input'
            placeholder='Reject reason'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RejectJEModal
