export const getPredefinedOptions: (
  fieldName: string
) => { key: number; value: number | string; label: string }[] | undefined = (
  fieldName: string
) => {
  switch (fieldName) {
    case 'task_type':
      return [
        { key: 0, value: 'PO', label: 'PO' },
        { key: 1, value: 'Vendor', label: 'Vendor' },
        { key: 2, value: 'Vendor Copy', label: 'Vendor Copy' },
        { key: 3, value: 'PO Copy', label: 'PO Copy' }
      ]
    case 'external_accrual':
    case 'internal_accrual':
      return [
        { key: 0, value: 1, label: 'No' },
        { key: 1, value: 2, label: 'Unbilled' },
        { key: 2, value: 3, label: '% Complete' },
        { key: 3, value: 4, label: 'New' }
      ]
    case 'external_accrual_form':
      return [
        { key: 0, value: 'Vendor Accruals', label: 'Vendor Accruals' }, // 6
        { key: 1, value: 'Test Form', label: 'Test Form' } // 17
      ]
    case 'internal_accrual_form':
      return [{ key: 0, value: 'PO Accruals', label: 'PO Accruals' }]
    case 'calculated_accrual_form':
      return [
        { key: 0, value: 'Manual Accruals', label: 'Manual Accruals' }, // 11
        {
          key: 1,
          value: 'Calculated Accruals',
          label: 'Calculated Accruals'
        }, // 15
        { key: 2, value: 'Imported Accruals', label: 'Imported Accruals' } // 16
      ]
    case 'calculation':
      return [
        { key: 0, value: 1, label: 'None' },
        { key: 1, value: 2, label: 'Straightline' },
        { key: 2, value: 4, label: 'Historical Average' },
        { key: 3, value: 5, label: 'New' }
      ]
    case 'form_name':
      return [
        { key: 0, value: 6, label: 'Vendor Accruals' },
        { key: 1, value: 10, label: 'PO Accruals' },
        { key: 2, value: 11, label: 'Manual Accruals' },
        { key: 3, value: 15, label: 'Calculated Accruals' },
        { key: 4, value: 16, label: 'Imported Accruals' },
        { key: 5, value: 17, label: 'Test Form' }
      ]

    case 'is_active':
    case 'task_inactive':
    case 'reviewed':
    case 'active':
    case 'generate_accruals':
    case 'is_out_of_sync':
      return [
        { key: 1, value: 1, label: 'Yes' },
        { key: 0, value: 0, label: 'No' }
      ]
  }
}
