// ** AntD Imports
import { FormInstance } from 'antd'

// ** Custom Component Imports
import ScheduleManagerMonthlyModal from './Modal'
import CancelButton from '../../../components/CancelButton'
import SaveButton from '../../../components/SaveButton'

// ** Style Imports
import styles from '../../../styles/footer.module.css'

// =================================================================
const MonthlyFooter = ({ form }: { form: FormInstance }) => {
  // ** Store **

  return (
    <div className={styles.container}>
      {/* <Button
        className={styles.button}
        shape='round'
        key='preview'
        type='default'
        onClick={() => toggleAllEditingKeys([])} // Clear all editing rows
      >
        CANCEL
      </Button>
      <GButton
        btnText='SAVE'
        onClick={() => {
          setOpenModal(true)
        }}
        style={{ width: 150, height: 40 }}
      />
      <ScheduleManagerMonthlyModal form={form} /> */}
      <CancelButton btnKey='monthly-cancel' />
      <SaveButton btnKey='monthly-save' />
      <ScheduleManagerMonthlyModal form={form} />
    </div>
  )
}

export default MonthlyFooter
