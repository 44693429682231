/** constants imports */
import { EXPIRY_COUNTER } from '../../helpers/constants/Auth'
import TO_SECONDS from '../constants/TO_SECONDS'

/** hooks imports */
import useApiService from '../../hooks/useApiService'
import useRefreshToken from './useRefreshToken'

// =================================================================
export default function useReset() {
  /** hooks */
  const { refetch: refreshToken } = useRefreshToken()
  const { msUrl } = useApiService()

  const reset = async () => {
    const allowance = msUrl.token_exp_allowance * TO_SECONDS
    const expiryCounter = parseInt(localStorage.getItem(EXPIRY_COUNTER)!)

    if (expiryCounter < allowance) {
      refreshToken()
    }
  }

  return { reset }
}
