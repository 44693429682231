import FileSource from '../types/FileSource'

const fileSources: FileSource[] = [
  { key: 1, value: 'imported_accruals' },
  { key: 2, value: 'source_budgets' },
  { key: 3, value: 'source_channels' },
  { key: 4, value: 'source_classes' },
  { key: 5, value: 'source_coas' },
  { key: 6, value: 'source_companies' },
  { key: 7, value: 'source_currencies' },
  { key: 8, value: 'source_departments' },
  { key: 9, value: 'source_fx_rates' },
  { key: 10, value: 'source_glaccounts' },
  { key: 11, value: 'source_intercompanies' },
  { key: 12, value: 'source_invoices' },
  { key: 13, value: 'source_locations' },
  { key: 14, value: 'source_projects' },
  { key: 15, value: 'source_purchase_orders' },
  { key: 16, value: 'source_regions' },
  { key: 17, value: 'source_subsidiaries' },
  { key: 18, value: 'source_transactions' },
  { key: 19, value: 'source_vendors' }
]

export default fileSources
