// ** API Services Imports
import { useAnalyticsService } from '../services/AnalyticsService'

// ** Custom Component Imports
import ActionModals from '../components/Modals/ActionModals'
import AdditionalOptions from '../components/AdditionalOptions'
import AnalyticsDropdown from '../components/Dropdown'
import AnalyticsTable from '../components/Table'
import DataValuesModal from '../components/Modals/DataValuesModal'
import QuickInsights from '../components/QuickInsights'

// ** Hook Imports
import useApiService from '../hooks/useApiService'
import useAdditionalOptions from '../hooks/useAdditionalOptions'

// ** Third Party Imports
import { useQuery } from '@tanstack/react-query'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'
import useGFStore from '../components/Modals/GlobalFilterModal/store/GlobalFilterStore'

// =================================================================
const ConfirmationResponseRates = () => {
  // ** API **
  const { msUrl } = useApiService()
  const { AnalyticsAPI } = useAnalyticsService()

  // ** Hooks **
  const { getMonthsToShow, getShowCount, getNonResponsive } =
    useAdditionalOptions()

  // ** Constants **
  const currentMonthsToShow = getMonthsToShow()
  const currentShowCount = getShowCount()
  const currentNonResponsive = getNonResponsive()

  // ** Store **
  const {
    openModal,
    setOpenModal,
    modalType,
    sortInternal,
    sortVendor,
    responseType,
    period,
    page,
    internalPage,
    vendorPage
  } = useAnalyticsStore()

  // ** Zustand - Global Filtering Store **
  const activeFilterId = useGFStore((state) => state.activeFilterId)
  const isPreviewModeDep = useGFStore((state) => state.isPreviewModeDep)

  // ** TanStack Query **
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [
      'analytics',
      sortInternal,
      sortVendor,
      responseType,
      period,
      currentMonthsToShow,
      currentShowCount,
      currentNonResponsive,
      page,
      internalPage,
      vendorPage,
      activeFilterId,
      isPreviewModeDep
    ],
    queryFn: () =>
      AnalyticsAPI({
        data: {},
        params: {
          order_by: responseType === 'internal' ? sortInternal : sortVendor,
          period: period,
          months_to_show: currentMonthsToShow,
          with_count: currentShowCount,
          non_responsive_only: currentNonResponsive,
          page_number: responseType === 'internal' ? internalPage : vendorPage,
          filter: activeFilterId,
          preview_mode: isPreviewModeDep
        },
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/response-rates/${responseType?.replace(
            /\s+/g,
            '-'
          )}/get`,
          method: 'GET'
        }
      }),
    enabled: responseType !== undefined
  })

  return (
    <>
      <AnalyticsDropdown data={data} />
      <QuickInsights
        data={data}
        isLoading={isLoading}
        isRefetching={isRefetching}
      />
      <AdditionalOptions showActionButton={false} />
      <AnalyticsTable
        data={data}
        isLoading={isLoading}
        isRefetching={isRefetching}
      />
      <ActionModals
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalType={modalType}
      />
      <DataValuesModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalType={modalType}
      />
    </>
  )
}

export default ConfirmationResponseRates
