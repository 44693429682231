// ** Third Party Imports
import { faPencil } from '@fortawesome/free-solid-svg-icons'

// ** Custom Component Imports
import GButton from '../../../../../components/gappify/GButton'
import GIcon from '../../../../../components/gappify/GIcon'

// ** Data Imports
import MonthlyData from '../mock/MonthlyData'

// ** Zustand Imports
import useScheduleStore from '../manager/schedule-store'
// import useScheduleManager from '../hooks/useScheduleManager'

// ** Hooks imports
import useRolesAndPermissions from '../../../../../hooks/useRolesAndPermissions'

// =================================================================
const EditButton = ({
  btnKey,
  enabled
}: {
  btnKey: string
  enabled?: boolean
}) => {
  // ** Store **
  const { handleOnClickEdit } = useScheduleStore()

  // ** Data **
  const { rowsFromBackend } = MonthlyData()

  const RP = useRolesAndPermissions()

  // const { getScheduleManagerMonthly } = useScheduleManager()
  // const { isLoading, isRefetching } = getScheduleManagerMonthly

  // ** Constants **
  const monthlyDataKeys = rowsFromBackend.map((row) => row.key)

  // =================================================================
  return (
    <>
      <GButton
        onClick={() => {
          handleOnClickEdit(btnKey, monthlyDataKeys)
        }}
        className='g-btn-icon'
        btnText={'Edit'}
        icon={<GIcon icon={faPencil} style={{ marginRight: '5px' }} />}
        disabled={!enabled || !RP.USER_HAS_SCHEDULE_MANAGER_UPDATE_PERMISSION}
      />
    </>
  )
}

export default EditButton
