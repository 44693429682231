import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import { ApiLayoutData } from '../types/api'
import constants from '../config/constants'

type Response = {
  data: ApiLayoutData
  success: boolean
}

const useFetchLayout = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useQuery<Response>({
    queryKey: constants.LAYOUT_SUBTAB.LAYOUT_KEY,
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/review-center/layouts`,
          method: 'GET'
        }
      })
  })
}

export default useFetchLayout
