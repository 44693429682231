// ** React Imports
import { useState } from 'react'

// ** AntD Imports
import { Form, Input, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'

// ** Hook Imports

// ** Third Party Imports
import { useQueryClient } from '@tanstack/react-query'

// ** Style Imports
import '../styles/update-accrual.styles.css'

// ** Type Imports
import AccrualDataType from '../types/AccrualDataType'
import EmailDetailsType from '../types/EmailDetailsType'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'

// =================================================================
export default function EmailVendorData() {
  // ** State **
  const [tempEmail, setTempEmail] = useState<string | null>(null)

  // ** Store **
  const {
    handleUpdateAccrualCellClick,
    selectedRows,
    showEmailVendor,
    additionalEmails,
    setAdditionalEmails
  } = useAnalyticsStore()

  // ** TanStack Query **
  const queryClient = useQueryClient()
  const emailVendorData: EmailDetailsType[] =
    queryClient.getQueryData([
      'email-details-vendor',
      selectedRows.join(','),
      showEmailVendor
    ]) || []

  const columns: ColumnType<AccrualDataType>[] = [
    {
      title: 'Vendor Name',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '300px',
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'CC Email Address',
      dataIndex: 'cc_email',
      key: 'cc_email',
      width: '300px',
      render: (text: string) => (
        <Typography style={{ color: '#8D8D8D' }}>{text}</Typography>
      )
    },
    {
      title: 'Additional Email Address',
      dataIndex: 'additional_email',
      key: 'additional_email',
      width: '300px',
      render: (text: string, record: AccrualDataType, index: number) => {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <Form.Item
              name={`${record.key}-cc_email`}
              style={{ margin: 0 }}
              initialValue=''
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email!'
                }
              ]}
            >
              <Input
                defaultValue={text}
                onBlur={() => {
                  if (tempEmail !== null) {
                    const newAdditionalEmails = [...additionalEmails]
                    newAdditionalEmails[index] = tempEmail
                    setAdditionalEmails(newAdditionalEmails)
                  }
                  handleUpdateAccrualCellClick(`${record.key}-cc_email`)
                }}
                onChange={(e) => {
                  setTempEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>
        )
      }
    }
  ]

  const rowsFromBackend =
    (emailVendorData &&
      emailVendorData.length > 0 &&
      emailVendorData.map((item: EmailDetailsType, index) => ({
        key: (index + 1).toString(),
        id: item.id,
        name: item.name,
        task_type: item.task_type,
        email: item.email,
        cc_email: item.cc_email,
        additional_email: additionalEmails[index] || ''
      }))) ||
    []

  return {
    columns,
    rowsFromBackend
  }
}
