import { SetStateAction } from 'react'
import { getAddLineScreenManagerData } from '../../../services/TrdManagerAPI'
import AddLineScreenManagerColumn from '../types/AddLineScreenManagerColumn'

export default async function loadAddLineScreenManagerData(
  setIsLoading: (value: SetStateAction<boolean>) => void,
  setDataSource: (value: SetStateAction<AddLineScreenManagerColumn[]>) => void,
  setDataSourceCopy: (
    value: SetStateAction<AddLineScreenManagerColumn[]>
  ) => void
): Promise<void> {
  setIsLoading(true)
  try {
    const { data } = await getAddLineScreenManagerData()
    const dataMap: AddLineScreenManagerColumn[] = data.map(
      (item: AddLineScreenManagerColumn) => {
        item.key = item.id
        return item
      }
    )

    const sortedData = dataMap.sort((a, b) => {
      if (a.form_weight < b.form_weight) {
        return -1
      }
      if (a.form_weight > b.form_weight) {
        return 1
      }
      return 0
    })

    setDataSource(sortedData)
    setDataSourceCopy(sortedData)
  } catch (error) {
    console.log(error)
  } finally {
    setIsLoading(false)
  }
}
