import _ from 'underscore'

// TODO: convert to typescript
class TxnStatusEnum {
  id: number
  label: string

  constructor(id: number, label: string) {
    this.id = id
    this.label = label
  }
}

const toReturn = {
  COMPLETED: new TxnStatusEnum(1, 'Completed'),
  PENDING: new TxnStatusEnum(0, 'Pending'),
  VOIDED: new TxnStatusEnum(-1, 'Voided')
}

const methods = {
  getLabelById: (id: number) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.id === id) {
        return true
      }

      return false
    })
    return found ? found.label : ''
  }
}

export default Object.freeze({ ...toReturn, ...methods })
