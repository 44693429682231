// ** Zustand Imports
import { create } from 'zustand'
import ColumnFields from '../types/ColumnFields'

interface AccrualProps {
  showImportModal: boolean
  columnFields: ColumnFields[] | null
  immutableFields: string[] | null
  immutableFieldsApiNameVendor: string[]
  immutableFieldsApiNamePO: string[]
  importFile: File | null
  showConfirmModal: boolean
  hasError: boolean
  errorMsg: string
  taskId: number | string | null
  isModalOpen: boolean

  // Define actions
  setshowImportModal: (showImportModal: boolean) => void
  setColumnFields: (columnFields: ColumnFields[] | null) => void
  setImmutableFieldsVendor: (immutableFieldsApiNameVendor: any) => void
  setImmutableFieldsPO: (immutableFieldsApiNamePO: any) => void
  setImportFile: (importFile: File | null) => void
  setShowConfirmModal: (showConfirmModal: boolean) => void
  setHasError: (hasError: boolean) => void
  setErrorMsg: (errorMsg: string) => void
  setTaskId: (taskId: number | string | null) => void
  setIsModalOpen: (hasError: boolean) => void
}

const useAccrualManagerstore = create<AccrualProps>((set, get) => ({
  showImportModal: false,
  immutableFields: null,
  columnFields: null,
  immutableFieldsApiNameVendor: ['g_name', 'g_subsidiary_currency'],
  immutableFieldsApiNamePO: [
    'g_name',
    'g_po_balance',
    'g_po_balance_usd',
    'g_po_date',
    'g_po_header_amount',
    'g_po_id',
    'g_po_line_amount',
    'g_po_line_amount_cancelled',
    'g_po_line_amount_usd',
    'g_po_line_id',
    'g_po_line_status',
    'service_start_date',
    'service_end_date',
    'g_po_number',
    'g_invoiced_amount',
    'g_subsidiary_currency',
    'g_transaction_currency'
  ],
  importFile: null,
  showConfirmModal: false,
  hasError: false,
  errorMsg: 'Max Size for CSV Files Imported is: 50 MB',
  taskId: null,
  isModalOpen: false,

  // Define actions
  setshowImportModal: (showImportModal) => set({ showImportModal }),
  setColumnFields: (columnFields) => set({ columnFields }),
  setImmutableFieldsVendor: (immutableFieldsApiNameVendor) =>
    set({ immutableFieldsApiNameVendor }),
  setImmutableFieldsPO: (immutableFieldsApiNamePO) =>
    set({ immutableFieldsApiNamePO }),
  setImportFile: (importFile) => set({ importFile }),
  setShowConfirmModal: (showConfirmModal) => set({ showConfirmModal }),
  setHasError: (hasError) => set({ hasError }),
  setErrorMsg: (errorMsg) => set({ errorMsg }),
  setTaskId: (taskId) => set({ taskId }),
  setIsModalOpen: (isModalOpen) => set({ isModalOpen })
}))

export default useAccrualManagerstore
