/** antd imports */
import { Modal, Table } from 'antd'

/** react imports */
import { FC } from 'react'

const TxnLinesNewHistoryModal: FC<any> = ({
  showModal,
  onCancel,
  labelList,
  dateTimeFormat,
  historyData,
  columns
}): JSX.Element => {
  return (
    // ** Modal component is based from GlobalHistoryModal.tsx **
    // ** TxnLinesHistoryModal.tsx can also be used as a reference for the other codes **
    <Modal
      data-testid='global-history-modal'
      data-cy='global-history-modal'
      title={
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingInlineEnd: '25px'
          }}
        >
          <div>History</div>
        </div>
      }
      open={showModal}
      onCancel={onCancel}
      width={2000}
      bodyStyle={{ height: 600 }}
      footer={null}
    >
      <Table
        data-testid='global-history-table'
        data-cy='global-history-table'
        dataSource={historyData?.data || []}
        columns={columns || []}
        pagination={false}
        scroll={{ x: 'max-content', y: 500 }}
      />
    </Modal>
  )
}
export default TxnLinesNewHistoryModal
