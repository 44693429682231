/** react imports */
import React from 'react'

/** react router imports */
import { Link } from 'react-router-dom'

/** custom components imports */
import SettingsMenuLabel from '../components/SettingsMenuLabel'

/** custom hooks imports */
import useNavigateSettings from './useNavigateSettings'

interface Config {
  LABEL: React.ReactNode
  KEY: string
  WEIGHT?: number
}

/**
 * Hook for generating setting sidebar menu item
 */
export default function useGenerateMenuItem() {
  const { checkNavigation } = useNavigateSettings()

  /**
   * Generates settings siderbar menu item
   * @param pathName string
   * @param route string
   * @param config Config
   * @returns ReactNode
   */
  const generateMenuItem = (
    pathName: string,
    route: string,
    config: Config
  ) => {
    const { KEY, LABEL, WEIGHT } = config

    const isActive = pathName.includes(KEY)
      ? 'ant-menu-item-selected ant-menu-item-active'
      : ''

    const completeLink = `${route}/${KEY}`

    return {
      label: (
        <span data-testid={`${KEY}-link`} data-cy={`${KEY}-link`}>
          <Link to={completeLink}>{LABEL}</Link>
        </span>
      ),
      key: KEY,
      className: isActive,
      weight: WEIGHT,
      onClick: () => checkNavigation(completeLink)
    }
  }

  const generateMenuLabel = (label: string, options?: { beta: boolean }) => {
    if (options?.beta) {
      return <SettingsMenuLabel label={label} />
    }

    return label
  }

  return { generateMenuItem, generateMenuLabel }
}
