import _ from 'underscore'

class TxnLineCalculationEnum {
  id: string | number
  label: string

  constructor(id: string | number, label: string) {
    this.id = id
    this.label = label
  }
}

const toReturn = {
  NONE: new TxnLineCalculationEnum(1, 'None'),
  STRAIGHTLINE: new TxnLineCalculationEnum(2, 'Straightline'),
  TRAILING_MONTH_AVERAGE: new TxnLineCalculationEnum(4, 'Historical Average')
}

const methods = {
  getLabelById: (id: number | string) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.id === id) {
        return true
      }
      return false
    })
    return found ? found.label : ''
  },
  getLabelFromId: (id: string | number) => {
    const found = _.find(Object.values(toReturn), (item) => {
      if (item.id === id) return true
      return false
    })
    return found ? found.label : ''
  },
  getIdValueList: () => {
    let keyList: any = []
    Object.values(toReturn).forEach((o) => {
      keyList.push({ id: o.id, value: o.label })
    })
    return keyList
  }
}

export default Object.freeze({ ...toReturn, ...methods })
