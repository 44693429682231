// ** Ant Design Components **
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

// ** React Components and Hooks **
import { FC } from 'react'
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

// ** QJE Manager State **
import useQjeStore from '../manager/qje-store'

// ** Types **
import TransactionLine from '../../../types/TransactionLine'
import HeaderLineGroupCheckboxProps from '../types/HeaderLineGroupCheckboxProps'

// ======================================================================
const HeaderLineGroupCheckbox: FC<HeaderLineGroupCheckboxProps> = ({
  selectableStatus
}: HeaderLineGroupCheckboxProps): JSX.Element => {
  // ** Hooks **
  const RP = useRolesAndPermissions()
  const {
    selectedLineGroups,
    setSelectedLineGroups,
    reviewJeData,
    tab,
    unreviewedPoClosureData
  } = useQjeStore()

  const handleOnCheck = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked
    if (!isChecked) setSelectedLineGroups([])
    else setSelectedLineGroups(getAllSelectableLineGroups())
  }

  // Check which tab is active to access the appropriate data
  const displayedData = () => {
    if (tab === 'review-je') return reviewJeData
    else if (tab === 'unreviewed-po-closure') return unreviewedPoClosureData
    else return []
  }

  const getAllSelectableLineGroups = (): (string | number)[] => {
    // Check if selectableStatus is not null...
    if (selectableStatus)
      return Array.from(
        new Set(
          displayedData()
            .filter((record: TransactionLine) =>
              selectableStatus.includes(record.g_status!)
            )
            .map((record: TransactionLine) => record.line_group!)
        )
      )
    // If no selectableStatus is provided, just return all UNIQUE lineGroups...
    else
      return Array.from(
        new Set(
          displayedData().map((record: TransactionLine) => record.line_group!)
        )
      )
  }

  // Check if all selectable line_groups are selected...
  const isAllCheckboxSelected: () => boolean = () =>
    selectedLineGroups?.length > 0 &&
    selectedLineGroups?.length === getAllSelectableLineGroups()?.length

  return (
    <Checkbox
      className='qje-column'
      data-testid='qje-review-je-select-all'
      data-cy='qje-review-je-select-all'
      disabled={
        !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
        !RP.USER_HAS_REJECT_QJE_PERMISSION ||
        !RP.USER_HAS_REVIEWED_FLAG_QJE_PERMISSION
      }
      checked={isAllCheckboxSelected()}
      onChange={handleOnCheck}
    />
  )
}

export default HeaderLineGroupCheckbox
