// ** AntD Imports
import { MenuProps } from 'antd'

// =================================================================
export default function useAnalyticsSettingsActionItems() {
  const ActionItems: MenuProps['items'] = [
    {
      label: 'Edit',
      key: '1'
    },
    {
      label: 'Delete',
      key: '2'
    }
  ]

  return {
    ActionItems
  }
}
