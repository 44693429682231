/** tanstack imports */
import { useQueryClient } from '@tanstack/react-query'

/** antd imports */
import { Button, Form, Skeleton } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** custom components imports */
import WorkdaySetupForm from './components/WorkdaySetupForm'

/** hooks */
import useFetchWorkDaySettings from './hooks/useFetchWorkDaySettings'
import useWorkdayFlag from './hooks/useWorkdayFlag'
import useWorkdayForm from './hooks/useWorkdayForm'

/** zustand store imports */
import useDimentionFieldsStore from './manager/useDimentionFieldsStore'
import useWorkDayStore from './manager/useWorkdayStore'

/** styles imports */
import styles from './styles/main.module.css'

// =================================================================
export default function WorkdaySetup() {
  /** antd */
  const [form] = Form.useForm()

  /** hooks */
  useWorkdayFlag()
  const queryClient = useQueryClient()
  const { isLoading, isRefetching } = useFetchWorkDaySettings()
  const { handleFormSubmit, isLoading: isSaving } = useWorkdayForm()

  /** zustand store */
  const isEditMode = useWorkDayStore((state) => state.isEditMode)
  const setIsEditMode = useWorkDayStore((state) => state.setIsEditMode)
  const resetDimentionsFields = useDimentionFieldsStore((state) => state.reset)

  /** effects */
  useEffect(() => {
    return () => {
      form.resetFields()
      setIsEditMode(false)
      resetDimentionsFields()
    }
  }, [form, resetDimentionsFields, setIsEditMode])

  if (isLoading || isRefetching) {
    return (
      <div data-testid='workday-page' data-cy='workday-page'>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    )
  }

  return (
    <div data-testid='workday-page' data-cy='workday-page'>
      <div className={styles.header}>
        <div></div>
        <div>
          {isEditMode ? (
            <>
              <Button
                shape='round'
                className={styles.btn}
                onClick={() => {
                  queryClient.invalidateQueries(['workday'])
                  setIsEditMode(false)
                }}
                data-testid='workday-cancel-btn'
                data-cy='workday-cancel-btn'
              >
                Cancel
              </Button>
              <Button
                shape='round'
                type='primary'
                className={styles.btn}
                onClick={form.submit}
                disabled={isLoading || isRefetching || isSaving}
                loading={isLoading || isRefetching || isSaving}
                data-testid='workday-save-btn'
                data-cy='workday-save-btn'
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              shape='round'
              type='primary'
              className={styles.btn}
              onClick={() => setIsEditMode(true)}
              disabled={isLoading || isRefetching}
              loading={isLoading || isRefetching}
              data-testid='workday-edit-btn'
              data-cy='workday-edit-btn'
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <Form
        form={form}
        className={styles.form_container}
        layout='vertical'
        onFinish={handleFormSubmit}
        onFinishFailed={() => {}}
      >
        <WorkdaySetupForm form={form} />
      </Form>
    </div>
  )
}
