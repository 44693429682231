// ** Third Party Imports
import axios from 'axios'

// ** Library Imports
import request from '../lib/request'

// ** Helper Imports
import { AUTH_TOKEN } from '../../../helpers/constants/Auth'

// ** Utils Imports
import getMsUrl from '../../../utils/getMsUrl'

// =================================================================
export default function useApiService() {
  const hostname = window.location.hostname
  const msUrl = getMsUrl(hostname)

  const apiRequest = <Params = unknown, Data = unknown>(
    baseURL: string,
    url: string,
    method: string,
    customHeaders: Record<string, string> = {},
    params?: Params | undefined,
    data?: Data,
    withCredentials?: boolean
  ) => {
    // ** Token **
    const token = localStorage.getItem(AUTH_TOKEN)

    // ** Header Hook **
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const headers = { ...defaultHeaders, ...customHeaders }

    const client = axios.create({
      baseURL: baseURL,
      withCredentials: withCredentials
    })

    return request(client, {
      headers,
      url,
      method,
      params,
      data
    })
  }

  return {
    apiRequest,
    msUrl
  }
}
