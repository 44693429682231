import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SetStateAction } from 'react'
import TransactionManagerColumn from '../../../types/TransactionManagerColumn'

export default function handleOnChangeCheckbox(
  recordId: number,
  recordProperty: 'show' | 'read_only' | 'default_column',
  dataSource: TransactionManagerColumn[],
  setDataSource: (value: SetStateAction<TransactionManagerColumn[]>) => void
) {
  return (e: CheckboxChangeEvent): void => {
    const rows = dataSource.map((item) => {
      if (item.id === recordId) {
        item.properties[recordProperty] = !item.properties[recordProperty]
        return item
      }
      return item
    })
    setDataSource(rows)
  }
}
