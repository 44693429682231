import Action from '../../types/Action'

const ActionTypes: Action = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_TASKS: 'SET_TASKS',
  SET_TOTAL_TASKS: 'SET_TOTAL_TASKS',
  SET_TASK_PARAMS: 'SET_TASK_PARAMS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_CURR_PAGE: 'SET_CURR_PAGE',
  SET_ERROR: 'SET_ERROR',
  SET_FORBIDDEN_ERROR: 'SET_FORBIDDEN_ERROR',
  SET_TSM_PREVIEW_MODE: 'SET_TSM_PREVIEW_MODE',
  SET_TSM_FILTER_FIELDS: 'SET_TSM_FILTER_FIELDS',

  // for qje...
  SET_QJE_DATA: 'SET_QJE_DATA',
  SET_QJE_PREVIEW_DATA: 'SET_QJE_PREVIEW_DATA',
  SET_SELECTED_LINE_GROUPS: 'SET_SELECTED_LINE_GROUPS',
  SET_SELECTED_PERIOD: 'SET_SELECTED_PERIOD',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  SET_SELECTED_FOR_BULK_UPDATE: 'SET_SELECTED_FOR_BULK_UPDATE',
  SET_IS_ON_EDIT_REVIEWED: 'SET_IS_ON_EDIT_REVIEWED',
  SET_REVIEWED_EDIT_KEY: 'SET_REVIEWED_EDIT_KEY',
  SET_UNREVIEWED_PO_DATA: 'SET_UNREVIEWED_PO_DATA',
  SET_REVIEWED_PO_DATA: 'SET_REVIEWED_PO_DATA'
}

export default ActionTypes
