// ** AntD Imports
import { Modal } from 'antd'

// ** Content Imports
import PermNotes from './contents/PermNotes'
import UpdateAccrualManager from './contents/UpdateAccrualManager'
import HistoryLog from './contents/HistoryLog'

// ** Custom Component Imports
import AddNotesFooter from './components/AddNotesFooter'

// ** Type Imports
import AnalyticsModalProps from '../../../types/Modal'

// ** Zustand Imports
import useAnalyticsStore from '../../../manager/analytics-store'

// =================================================================
const ActionModals = ({
  openModal,
  setOpenModal,
  modalType
}: AnalyticsModalProps) => {
  // ** Store **
  const { permNotesVendorName } = useAnalyticsStore()

  let title
  let contents
  switch (modalType) {
    case 'update':
      title = 'Update Accrual Manager'
      contents = <UpdateAccrualManager />
      break
    case 'perm':
      title = `${permNotesVendorName} Perm Notes`
      contents = <PermNotes />
      break
    case 'history':
      title = `${permNotesVendorName} - View History`
      contents = <HistoryLog />
      break
    default:
      break
  }

  return (
    <Modal
      title={
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {title}
        </div>
      }
      centered
      open={openModal}
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      width={2000}
      bodyStyle={{ maxHeight: '50vh' }}
      footer={modalType === 'perm' && <AddNotesFooter />}
    >
      {contents}
    </Modal>
  )
}

export default ActionModals
