import { axiosHistoryInstance } from '../../../../../helpers/axios'

export default async function getUserHistoryById(id: number, params?: any) {
  try {
    const response = await axiosHistoryInstance.get(
      `api/identity-server/user/${id}`,
      { params }
    )
    return response.data
  } catch (error) {
    throw error
  }
}
