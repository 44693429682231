/** antd imports */
import { Table, TablePaginationConfig } from 'antd'

/** hooks imports */
import useApiKeysTableColumns from '../hooks/useApiKeysTableColumns'
import useFetchApiKeys from '../hooks/useFetchApiKeys'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'

// =================================================================
export default function ApiKeysTable() {
  /** hooks */
  const columns = useApiKeysTableColumns()
  const { data: response, isLoading, isRefetching } = useFetchApiKeys()

  /** zustand store */
  const { page, perPage, setPage, setPerPage, setSortOrder } = useApiKeyStore()

  const onTableChange = (
    config: TablePaginationConfig,
    filter: any,
    sorter: any
  ) => {
    const sortOrder = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    setPerPage(config.pageSize!)
    setPage(config.current!)
    setSortOrder(sortOrder)
  }

  return (
    <Table
      data-testid='api-keys-table'
      data-cy='api-keys-table'
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={response?.data.data}
      pagination={{
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total}`,
        pageSize: perPage,
        defaultCurrent: 1,
        current: page,
        pageSizeOptions: [10, 20, 50],
        showSizeChanger: true,
        total: response?.data.total
      }}
      loading={isLoading || isRefetching}
      onChange={onTableChange}
      scroll={{ y: '65dvh' }}
    />
  )
}
