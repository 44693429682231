/** zustand imports */
import { create } from 'zustand'

type FeatureFlagType = boolean | null

interface FeatureFlagsStoreProps {
  /** state */
  gateway: FeatureFlagType // API Integrations feature flag
  workday: FeatureFlagType // Workday Settings feature flag

  /** actions */
  setGateway: (gateway: FeatureFlagType) => void
  setWorkday: (workday: FeatureFlagType) => void
}

// =================================================================
const useFeatureFlagsStore = create<FeatureFlagsStoreProps>((set) => ({
  /** state */
  gateway: null, // API Integrations feature flag,
  workday: null, // Workday Settings feature flag,

  /** actions */
  setGateway: (gateway: FeatureFlagType) => set({ gateway }),
  setWorkday: (workday: FeatureFlagType) => set({ workday })
}))

export default useFeatureFlagsStore
