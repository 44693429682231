import { MenuOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { SetStateAction } from 'react'
import AddLineScreenManagerColumn from '../types/AddLineScreenManagerColumn'
import renderColumnValue from './renderColumnValue'

export default function getAddLineScreenManagerColumns(
  canEdit: boolean,
  searchValue: string,
  dataSource: AddLineScreenManagerColumn[],
  setDataSource: (value: SetStateAction<AddLineScreenManagerColumn[]>) => void
): ColumnsType<AddLineScreenManagerColumn> {
  return [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      render: (value, record) => {
        if (canEdit && !searchValue) {
          return (
            <MenuOutlined
              onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} 
              data-testid={`add-line-screen-manager-table-grab-handler-${record?.id}`}
              data-cy={`add-line-screen-manager-table-grab-handler-${record?.id}`}
              style={{ cursor: 'grab', color: '#999' }}
            />
          )
        }
        return
      }
    },
    {
      title: 'Field API Name',
      dataIndex: 'api_name',
      render: (value, record) => (
        <span
          data-testid={`add-line-screen-manager-table-field-api-name-${record?.id}`}
          data-cy={`add-line-screen-manager-table-field-api-name-${record?.id}`}
        >
          {value}
        </span>
      )
    },
    {
      title: 'Field label',
      dataIndex: 'label',
      render: (value: string, record) => {
        if (!canEdit) {
          return value
        }
        return (
          <Input
            data-testid={`add-line-screen-manager-table-input-field-${record?.id}`}
            data-cy={`add-line-screen-manager-table-input-field-${record?.id}`}
            value={value}
            disabled
          />
        )
      }
    },
    {
      title: 'Show (Manual)',
      align: 'center',
      render: (value: any, record) =>
        renderColumnValue(
          canEdit,
          record?.form_properties?.show,
          record?.id,
          'show',
          dataSource,
          setDataSource,
          record?.form_properties?.required
        )
    },
    {
      title: 'Read-only',
      align: 'center',
      render: (value: any, record) =>
        renderColumnValue(
          canEdit,
          record?.form_properties?.read_only,
          record?.id,
          'read_only',
          dataSource,
          setDataSource,
          record?.form_properties?.required
        )
    },
    {
      title: 'Required',
      align: 'center',
      render: (value: any, record) =>
        renderColumnValue(
          canEdit,
          record?.form_properties?.required,
          record?.id,
          'required',
          dataSource,
          setDataSource
        )
    }
  ]
}
