declare global {
  interface Window {
    pendo: {
      initialize: <T>(metadata: T) => void
      validateInstall: () => void
    }
  }
}

export const pendoInitialize = <U>(metadata: U) =>
  window.pendo.initialize(metadata)
export const pendoValidateInstall = () => window.pendo.validateInstall()
