import { FC, useState } from 'react'
import { Button, Input, Form } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { loginMfa, generateNewToken } from '../services/AuthApi'
import jwt_decode from 'jwt-decode'
import {
  AUTH_TOKEN,
  EXPIRATION,
  ROLES_AND_PERMISSIONS,
  MFA_TOKEN,
  EXPIRY_COUNTER,
  EXPIRES_ON
} from '../helpers/constants/Auth'
import ActionPermissions from '../helpers/constants/ActionPermissions'
const TO_SECONDS = 60

const MultiFactorAuth: FC = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [sentMessage, setSentMessage] = useState<string>('')
  const [mfaToken, setMfaToken] = useState<string | null>(
    localStorage.getItem(MFA_TOKEN)
  )

  const onFinish = (values: any) => {
    loginMfa(values, mfaToken!)
      .then((res) => {
        if (res && res.status === 200) {
          if (res.data) {
            let jwtToken = window.atob(res.data.access_token)
            let decodedjwt = jwt_decode(jwtToken)

            localStorage.removeItem(MFA_TOKEN)
            localStorage.setItem(AUTH_TOKEN, res.data.access_token)
            localStorage.setItem(EXPIRATION, res.data.expires_in)
            localStorage.setItem(
              EXPIRY_COUNTER,
              (res.data.expires_in * TO_SECONDS).toString()
            )
            localStorage.setItem(EXPIRES_ON, res.data.expires_on)

            localStorage.setItem(
              ROLES_AND_PERMISSIONS,
              JSON.stringify(decodedjwt)
            )

            redirectPage(decodedjwt)
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.error) {
          if (err.response.data.error.password) {
            let pwError = err.response.data.error.password
            setSentMessage('')
            setErrorMessage(pwError[0])
          } else {
            setSentMessage('')
            setErrorMessage(err.response.data.error)
          }
        }
      })
  }
  const checkPermission = (decodedjwt: any, permissionId: number): boolean => {
    const permissions = decodedjwt && decodedjwt.action_permissions

    return permissions && permissions.includes(permissionId)
  }

  const redirectPage = (decodedjwt: any) => {
    if (
      checkPermission(
        decodedjwt,
        ActionPermissions.REVIEW_CENTER_DATA_REVIEW_CENTER
      ) &&
      checkPermission(decodedjwt, ActionPermissions.REVIEW_CENTER_GET_FILTER)
    ) {
      window.location.href = '/review-center'
    } else {
      if (checkPermission(decodedjwt, ActionPermissions.VENDOR_TASKS)) {
        window.location.href = '/task-manager'
      } else {
        if (checkPermission(decodedjwt, ActionPermissions.VIEW_TRANSACTION)) {
          window.location.href = '/transactions'
        } else {
          if (
            checkPermission(decodedjwt, ActionPermissions.QJE_VIEW_TRANSACTIONS)
          ) {
            window.location.href = '/qje'
          } else {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = '/error?message=Forbidden&status=403'
          }
        }
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleOnClickGenerate = () => {
    generateNewToken(mfaToken!).then((res) => {
      localStorage.removeItem(MFA_TOKEN)
      if (res.data) {
        localStorage.setItem(MFA_TOKEN, res.data.mfa_token)
        if (res.data.mfa_token) {
          setMfaToken(localStorage.getItem(MFA_TOKEN))
          setSentMessage('Sent New Authentication Code')
          setErrorMessage('')
        }
      }
    })
  }

  if (!localStorage.getItem(MFA_TOKEN)) {
    window.location.href = '/login'
  }

  return (
    <div className='login-page'>
      <img
        className='bg-img img img-responsive'
        src='/assets/images/MULTICITY-Laptop.jpeg'
        alt='gpfy-bg'
      />

      <div className='login-header'>
        <img src='/assets/images/gappify-logo-black.svg' alt='gpfy-logo' />
      </div>
      <div className='login-form-container'>
        {errorMessage && !sentMessage && (
          <div className='login-error-msg'>{errorMessage}</div>
        )}
        {sentMessage && !errorMessage && (
          <div className='mfa-sent-msg'>{sentMessage}</div>
        )}
        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='on'
        >
          <Form.Item
            name='code'
            rules={[
              {
                required: true,
                message: 'Please enter verification code sent to your email.'
              }
            ]}
          >
            <Input placeholder='Authenticator' prefix={<LockOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />} />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='login-btn'>
              Validate
            </Button>
            <div
              className='login-reset'
              onClick={handleOnClickGenerate}
              style={{ cursor: 'pointer' }}
            >
              Generate New Token
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default MultiFactorAuth
