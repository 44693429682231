import { Table } from 'antd'

import 'antd/dist/antd.min.css'
const DynamicTable = ({
  columnHeader,
  data,
  tableProps,
  pagination,
  scroll,
  loading,
  onChange,
  expandedRowRender,
  rowSelection,
  onRow,
  showHeader,
  showSorterTooltip
}) => {
  return (
    <div className='data-table'>
      <Table
        {...tableProps}
        dataSource={data}
        columns={columnHeader}
        pageSizeOptions={[25, 35, 50, 100]}
        pagination={pagination}
        scroll={scroll}
        loading={loading}
        onChange={onChange}
        expandedRowRender={expandedRowRender}
        rowSelection={rowSelection}
        onRow={onRow}
        showHeader={showHeader}
        showSorterTooltip={showSorterTooltip}
      />
    </div>
  )
}
export default DynamicTable
