import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

// ** AntD Imports
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  FormInstance,
  message,
  InputNumber,
  DatePicker,
  Spin,
  Popconfirm,
  Tabs,
  TabsProps
} from 'antd'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// ** Custom Component Imports
import GButton from '../../../gappify/GButton'
import GIcon from '../../../gappify/GIcon'
import FilteringColumn from '../../../filtering/column'

// ** Custom Hooks Imports
import { callGetApi } from '../../../../services/GenericAPI'

// ** Zustand Imports
import useFilterStore from '../../store/filterStore'

// ** Types/Constants Imports
import {
  DEFAULT_BTN_PROPS,
  INPUT_MAX_LENGTH,
  MODAL_MODE,
  OPTIONS_OPERATOR,
  OPTIONS_CHECKBOX,
  DEFAULT_FILTER,
  DEFAULT_FILTER_TABS
} from '../../constants'
import getInstance from '../../constants/instance'
import { TApiField, TApiSource } from '../../types/Api'
import {
  TCacheSource,
  TFieldOption,
  TFilterItem
} from '../../types/FilterModal'
import TProps, { TFilterFields } from '../../types/Filter'

// ** Helper Imports
import catchError from '../../helper/catchError'

const { RangePicker } = DatePicker

const success = (text: string) => {
  message.success(`Success: ${text}`)
}

const error = (text: string) => {
  message.error(`Error: ${text}`)
}

// =================================================================
const FilterModal = ({
  microservice,
  activeFilterRef,
  loadDataCallback,
  getFilterByIdUrl,
  createFilterUrl,
  updateFilterUrl,
  deleteFilterUrl,
  previewFilterUrl,
  hasCustomColumns,
  allColumnHeaders,
  updateColumnHeaders
}: TProps) => {
  const { instance } = getInstance(microservice)!

  // ** Refs **
  const formRef = useRef<FormInstance>(null)
  const [form] = Form.useForm()

  // ** Local states **
  const [fieldOptions, setFieldOptions] = useState<TFieldOption[]>([])
  const [fieldMapping, setFieldMapping] = useState<TFieldOption[]>([])
  const [cacheSources, setCacheSources] = useState<TCacheSource[]>([])
  const [selectLoaders, setSelectLoaders] = useState<string[]>([])
  const [filterDictionary, setFilterDictionary] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeTabKey, setActiveTabKey] = useState<string>(
    DEFAULT_FILTER_TABS.CRITERIA
  )
  const [availableFields, setAvailableFields] = useState<any[]>([])
  const [selectedFields, setSelectedFields] = useState<any[]>([])

  // ** Zustand Store **
  const selectedFilter = useFilterStore((state) => state.selectedFilter)
  const modalMode = useFilterStore((state) => state.modalMode)
  const isModalOpen = useFilterStore((state) => state.isModalOpen)
  const isPreviewMode = useFilterStore((state) => state.isPreviewMode)
  const allFields = useFilterStore((state) => state.allFields)
  const setModalMode = useFilterStore((state) => state.setModalMode)
  const setIsModalOpen = useFilterStore((state) => state.setIsModalOpen)
  const setIsPreviewMode = useFilterStore((state) => state.setIsPreviewMode)
  const setSelectedFilter = useFilterStore((state) => state.setSelectedFilter)
  const setIsReloadFilters = useFilterStore((state) => state.setIsReloadFilters)

  const reloadData = (filterId: number | string, thisPreviewMode?: boolean) => {
    activeFilterRef.current = {
      id: filterId
    }
    loadDataCallback(thisPreviewMode || false)
  }

  const resetStates = () => {
    setIsModalOpen(false)
    setModalMode(null)
    setFieldMapping([])
    form.resetFields()
  }

  const createFilter = async (payload: any) => {
    setIsLoading(true)
    await instance
      .post(createFilterUrl, payload)
      .then((res) => {
        setIsLoading(false)
        success('Created filter!')

        const { data } = res
        setSelectedFilter(data)
        setIsReloadFilters(true)
        setModalMode(null)

        // Reload data
        reloadData(data.id)

        // Cleanup form & modal states
        setIsPreviewMode(false)
        resetStates()

        // Update column headers via new filterable fields value
        if (hasCustomColumns)
          updateColumnHeaders(data.id, data.filterable_fields)
      })
      .catch(catchError)
  }

  const previewFilter = async (payload: any) => {
    setIsLoading(true)
    await instance
      .post(previewFilterUrl, payload)
      .then((res) => {
        setIsLoading(false)
        success('Preview filter!')

        const { data } = res

        // Reload data with preview mode as true
        reloadData(data.id, true)

        // Alternate resetState for preview mode
        setIsModalOpen(false)

        // Update column headers via new filterable fields value
        if (hasCustomColumns)
          updateColumnHeaders(data.id, data.filterable_fields)
      })
      .catch(catchError)
  }

  const updateFilter = async (payload: any) => {
    setIsLoading(true)
    const requestUrl = `${updateFilterUrl}/${selectedFilter.id}`
    await instance
      .put(requestUrl, payload)
      .then((res) => {
        setIsLoading(false)
        success('Updated filter!')

        const { data } = res
        setSelectedFilter(data)
        setIsReloadFilters(true)
        setModalMode(null)

        // Reload data
        reloadData(data.id)

        // Cleanup form & modal states
        setIsPreviewMode(false)
        resetStates()

        // Update column headers via new filterable fields value
        if (hasCustomColumns)
          updateColumnHeaders(data.id, data.filterable_fields)
      })
      .catch(catchError)
  }

  const deleteFilter = async () => {
    setIsLoading(true)
    const requestUrl = `${deleteFilterUrl}/${selectedFilter.id}`
    await instance
      .delete(requestUrl)
      .then(() => {
        setIsLoading(false)
        success('Deleted filter!')

        setSelectedFilter(DEFAULT_FILTER)
        setIsReloadFilters(true)
        setModalMode(null)

        // Reload data
        reloadData(DEFAULT_FILTER.id)

        // Cleanup form & modal states
        resetStates()

        // Update column headers to the default value if custom columns is enabled
        if (hasCustomColumns) updateColumnHeaders('')
      })
      .catch(catchError)
  }

  const initializeForm = () => {
    const { id, filter_fields, filterable_fields } = selectedFilter
    const allVisibleColumns = getAllVisibleColumns(allColumnHeaders)

    // Return only those that are visible as selected fields
    const visibleFilterFields = getVisibleFilterFields(
      filter_fields,
      allVisibleColumns
    )

    // Initialize fieldMapping state
    const newFieldMapping: TFieldOption[] = visibleFilterFields!.map(
      (item: TFilterFields) => getMappingFromFieldOptions(item.column)!
    )
    setFieldMapping(newFieldMapping)

    // Get selected filter data
    getFilterById(id)

    // Initialize states for column tab if custom columns is enabled
    if (hasCustomColumns) {
      // Return only those that are visible as selected fields
      const newFilterableFields = filterable_fields!.filter((item: any) => {
        const thisFilterableField = allVisibleColumns.find((ele: any) => {
          return ele.api_name === item.api_name
        })
        return thisFilterableField ? !thisFilterableField.hidden : false
      })

      if (newFilterableFields && newFilterableFields.length) {
        setSelectedFields(newFilterableFields)
        generateAvailableFields(newFilterableFields)
      } else {
        setSelectedFields(allVisibleColumns)
        setAvailableFields([])
      }
    }
  }

  const getFilterById = async (id: string | number) => {
    setIsLoading(true)

    const url = `${getFilterByIdUrl}/${id}`
    await instance.get(url).then((res) => {
      setIsLoading(false)
      const { data } = res
      const { filter_fields: dataFilterFields } = data
      const { name, filter_fields } = selectedFilter
      const newFilterDictionary: any[] = []

      const allVisibleColumns = getAllVisibleColumns(allColumnHeaders)

      // Return only those that are visible as filter_fields
      const visibleFilterFields = getVisibleFilterFields(
        filter_fields,
        allVisibleColumns
      )

      // ? Re-structure returned data
      // Convert date fields to moment instance
      // Convert IDs to its string value via dictionary if available
      const newFilterFields = visibleFilterFields?.map(
        (item: TFilterFields, index: number) => {
          // Check the field type
          const thisField = getFieldFromAllFields(item.column)
          const thisFieldType = thisField?.field_type

          if (thisFieldType === 'date') {
            if (Array.isArray(item.value)) {
              const newValue = item.value.map((itemV: string) => {
                return moment(itemV, 'MM/DD/YYYY')
              })
              return { ...item, value: newValue }
            } else {
              // ! Possible edge case
              // Ready else statement for possible bug
              error('Value is not an array, cannot convert to moment.')
            }
          } else if (thisFieldType === 'picklist_single') {
            const thisDictionary = dataFilterFields[index].dictionary

            if (thisDictionary) {
              newFilterDictionary.push({
                column: item.column,
                dictionary: thisDictionary
              })

              // Return the new string values
              const newValue = thisDictionary.map(
                (itemV: any) => itemV.g_name || itemV.value
              )
              return { ...item, value: newValue }
            }
          } else if (thisFieldType === 'datepicker') {
            const newValue = moment(item.value, 'MM/DD/YYYY')
            return { column: item.column, value: newValue }
          }

          // Return as is by default
          return item
        }
      )

      // Initialize filterDictionary state
      setFilterDictionary(newFilterDictionary)

      // Initialize form values
      const thisFormValues = {
        name,
        // filters: filter_fields
        filters: newFilterFields
      }

      form.setFieldsValue(thisFormValues)
    })
  }

  const generateModalTitle = () => {
    if (modalMode === MODAL_MODE.CREATE) {
      return 'Create new filter'
    }
    return 'Update filter'
  }

  const generateBtnTitle = () => {
    if (modalMode === MODAL_MODE.CREATE) {
      return 'Save view'
    }
    return 'Update view'
  }

  const generateFieldOptions = () => {
    if (!allFields?.length) return []

    const options: TFieldOption[] = allFields
      .filter((item: TApiField) => !item.hidden)
      .map((item: TApiField) => {
        return {
          label: item.label,
          value: item.api_name,
          field_type: item.field_type,
          options_source: item.options_source,
          disabled: getFieldFromMapping(item.api_name) !== undefined
        }
      })

    return options
  }

  const generateAvailableFields = (thisSelectedFields: any) => {
    const tempAvailableFields = getAllVisibleColumns(allColumnHeaders).filter(
      (item: any) => {
        return thisSelectedFields.every((ele: any) => {
          return ele.api_name !== item.api_name
        })
      }
    )
    setAvailableFields(tempAvailableFields)
  }

  const getMappingFromFieldOptions = (column: string) => {
    return fieldOptions.find((item: TFieldOption) => item.value === column)
  }

  const getFieldFromMapping = (value: string) => {
    return fieldMapping.find((item: TFieldOption) => item.value === value)
  }

  const getSourceFromCache = (column: string) => {
    return cacheSources.find((item: any) => item.column === column)
  }

  const getFieldFromAllFields = (column: string) => {
    return allFields.find((item: TApiField) => item.api_name === column)
  }

  const getIndexFromSelectLoaders = (column: string) => {
    return selectLoaders.indexOf(column)
  }

  const getAllVisibleColumns = (columns: any[] | undefined) => {
    let visibleColumns = []
    if (columns && columns.length) {
      visibleColumns = columns.filter((item: any) => {
        return !item.hidden
      })
    }
    return visibleColumns
  }

  const getVisibleFilterFields = (
    filterFields: TFilterFields[] | undefined,
    allVisibleColumns: any[]
  ) => {
    if (!filterFields?.length) return []

    return filterFields!.filter((item: TFilterFields) => {
      const thisFilterField = allVisibleColumns.find((ele: any) => {
        return ele.api_name === item.column
      })
      return thisFilterField ? !thisFilterField.hidden : false
    })
  }

  const handleOnFinish = (values: any) => {
    const { name, filters } = values

    if (!filters?.length) {
      error('You need to add at least one filter.')
      return
    }
    if (hasCustomColumns && !selectedFields?.length) {
      error('You need to add at least one selected field.')
      return
    }

    const filter_fields: TFilterItem[] = filters.map((item: TFilterItem) => {
      const { column, op, value } = item
      const opObject = op ? { op } : undefined
      return { column, value, ...opObject }
    })

    // ? Re-structure filter_fields payload
    // Convert option values to its corresponding IDs if available
    //    - Check static options source if array
    //    - Check cache source if available
    //    - Check field dictionary if available
    // Convert moment instances to date string
    const newFilterFields = filter_fields.map((item: TFilterItem) => {
      const { column, value } = item

      const thisField = getFieldFromAllFields(column!)
      if (thisField) {
        const { field_type, options_source } = thisField
        const isOptionSourceArray = Array.isArray(options_source)

        if (isOptionSourceArray) {
          // Get the IDs from the all fields option source
          // This is the case when the field has a static option already
          const newValue = value.map((itemV: any) => {
            const thisFind = options_source.find(
              (itemC: any) => itemC.value === itemV
            )
            return thisFind?.id?.toString() || 'ID not found'
          })

          // Final return, override the value key
          return { ...item, value: newValue }
        } else if (field_type === 'picklist_single') {
          const thisCache = getSourceFromCache(column!)
          if (thisCache) {
            // Get the IDs from the source cache
            // This is the case when the user loads from the source url
            const thisCacheOptions = thisCache.options
            const newValue = value.map((itemV: any) => {
              const thisFind = thisCacheOptions.find(
                (itemC: any) => itemC.value === itemV
              )
              return thisFind?.key || 'Key not found'
            })

            // Final return, override the value key
            return { ...item, value: newValue }
          } else {
            // Get the IDs from the dictionary
            // This is the case when the user does not click the values dropdown
            const thisFilterDictionary = filterDictionary.find(
              (item: any) => item.column === column
            )
            if (thisFilterDictionary) {
              const thisDictionary = thisFilterDictionary.dictionary
              const newValue = value.map((itemV: any) => {
                const thisFind = thisDictionary.find(
                  (itemD: any) => itemD.g_name === itemV
                )
                return thisFind?.g_source_system_id || 'G Source ID not found'
              })

              // Final return, override the value key
              return { ...item, value: newValue }
            } else {
              // ! Possible edge case
              // Ready else statement for possible bug
              // error('No dictionary found, cannot convert to IDs.')
              console.log('No dictionary found, cannot convert to IDs.')
              console.log('This is an edge case happening for email fields.')
            }
          }
        } else if (field_type === 'date') {
          const newValue = value.map((itemV: any) => {
            return moment(itemV).format('MM/DD/YYYY')
          })

          // Final return, override the value key
          return { ...item, value: newValue }
        } else if (field_type === 'datepicker') {
          const newValue = moment(value).format('MM/DD/YYYY')

          // Final return, override the value key
          return { ...item, value: newValue }
        }
      }

      return item
    })

    let payload: any = {
      name,
      filter_fields: newFilterFields
    }

    // Add filterable_fields payload if custom columns is enabled
    if (hasCustomColumns) {
      payload.filterable_fields = selectedFields.map(
        (item: any, index: number) => ({
          api_name: item.api_name,
          label: item.label,
          weight: index
        })
      )
    }

    if (isPreviewMode) previewFilter(payload)
    else if (modalMode === MODAL_MODE.CREATE) createFilter(payload)
    else if (modalMode === MODAL_MODE.UPDATE) updateFilter(payload)
  }

  const handleOnFinishFailed = (errorInfo: any) => {
    console.log('errorInfo', errorInfo)
  }

  const handleOnPreview = () => {
    setIsPreviewMode(true)
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  const handleOnCancel = () => {
    // Cleanup form & modal states
    setIsPreviewMode(false)
    resetStates()

    // Reload default data
    setSelectedFilter(DEFAULT_FILTER)
    reloadData(DEFAULT_FILTER.id)
  }

  const handleDeleteFilterItem = (
    index: number,
    remove: (index: number | number[]) => void
  ) => {
    /**
     * ? Update field mapping state
     * Removes the index to be deleted from the fieldMapping state
     * Then removes the selected item from the form list
     */
    const newFieldMapping = Array.from(fieldMapping)
    newFieldMapping.splice(index, 1)
    setFieldMapping(newFieldMapping)
    remove(index)
  }

  const handleRemoveAllFilters = (removeAll: () => void) => {
    setFieldMapping([])
    removeAll()
  }

  const handleFieldOnChange = (
    index: number,
    option: TFieldOption | TFieldOption[]
  ) => {
    if (!Array.isArray(option)) {
      /**
       * ? Update field mapping state
       * IF   : Updates the fieldMapping state via index, then reset the form value via name
       * ELSE : Appends a new item on the fieldMapping state
       */
      let newFieldMapping = Array.from(fieldMapping)

      if (index < newFieldMapping.length) {
        newFieldMapping[index] = option
        if (newFieldMapping[index].field_type === 'datepicker') {
          form.setFieldValue(['filters', index, 'value'], null)
        } else {
          form.setFieldValue(['filters', index, 'value'], [])
        }
      } else {
        newFieldMapping.push(option)
      }

      setFieldMapping(newFieldMapping)
    } else {
      error('Wrong field option type. Field option must not be an array.')
    }
  }

  const renderField = (index: number) => {
    return (
      <Form.Item
        label='Field'
        name={[index, 'column']}
        rules={[{ required: true, message: 'Field is required.' }]}
        style={{
          flex: '1 1 0px'
        }}
      >
        <Select
          showSearch
          placeholder='Please select'
          optionFilterProp='label'
          options={generateFieldOptions()}
          onChange={(value, option) => handleFieldOnChange(index, option)}
        />
      </Form.Item>
    )
  }

  const renderOperator = (index: number) => {
    const typesWithOperator = ['numeric_accounting']
    const thisField = fieldMapping[index]

    if (!thisField || !typesWithOperator.includes(thisField.field_type))
      return <></>

    return (
      <Form.Item
        name={[index, 'op']}
        rules={[{ required: true, message: 'Operator is required.' }]}
        style={{
          flex: '1 1 0px'
        }}
      >
        <Select
          showSearch
          placeholder='Select operator'
          options={OPTIONS_OPERATOR}
        />
      </Form.Item>
    )
  }

  const renderValue = (index: number) => {
    const thisField = fieldMapping[index]
    if (!thisField) return <Input disabled />

    const { value, field_type, options_source } = thisField
    switch (field_type) {
      case 'picklist_single':
        let thisOptions: any[] = []
        let thisIsLoading = getIndexFromSelectLoaders(value) > -1

        if (Array.isArray(options_source)) {
          thisOptions = options_source.map((item: any) => ({
            // value: item.id.toString(),
            key: item.id.toString(),
            value: item.value,
            label: item.value
          }))
        } else {
          // If available, use options from the source cache
          const cachedSource = getSourceFromCache(value)
          thisOptions = cachedSource?.options || []
        }

        return (
          <Select
            mode='multiple'
            placeholder='Select value'
            optionFilterProp='label'
            options={thisOptions}
            onClick={() => {
              // Fetch from source url if options is empty
              if (!thisIsLoading && !thisOptions.length) {
                getOptionsBySource(value, options_source.url)
              }
            }}
            loading={thisIsLoading}
          />
        )

      case 'checkbox':
        return (
          <Select
            mode='multiple'
            placeholder='Select value'
            optionFilterProp='label'
            options={OPTIONS_CHECKBOX}
          />
        )

      case 'numeric_accounting':
        return (
          <InputNumber
            style={{ width: '100%' }}
            step='0.01'
            placeholder='Enter amount'
          />
        )

      case 'date':
        return <RangePicker style={{ width: '100%' }} format={'MM/DD/YYYY'} />

      case 'datepicker':
        return <DatePicker style={{ width: '100%' }} format={'MM/DD/YYYY'} />

      default:
        return <Input placeholder='Enter value' />
    }
  }

  const renderSingleFilterItem = (
    index: number,
    remove: (index: number | number[]) => void
  ) => {
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'end',
          gap: '24px'
        }}
      >
        {/* Field dropdown */}
        {renderField(index)}

        {/* Operator dropdown */}
        {renderOperator(index)}

        {/* Values */}
        <Form.Item
          label='Value'
          name={[index, 'value']}
          rules={[{ required: true, message: 'Value is required.' }]}
          style={{
            flex: '1 1 0px'
          }}
          valuePropName='value'
        >
          {renderValue(index)}
        </Form.Item>

        {/* Delete icon */}
        <div style={{ alignSelf: 'center' }}>
          <GButton
            type='text'
            className='g-btn-icon'
            onClick={() => handleDeleteFilterItem(index, remove)}
            icon={<GIcon icon={faTrash} />}
          />
        </div>
      </div>
    )
  }

  const renderFormList = () => {
    return (
      <Form.List name='filters'>
        {(fields, { add, remove }) => {
          const removeAll = () => {
            const indices = fields.map((field, index) => index)
            remove(indices)
          }
          return (
            <>
              {fields?.map(({ name }) => renderSingleFilterItem(name, remove))}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  {...DEFAULT_BTN_PROPS}
                  className='g-btn-default'
                  onClick={add}
                >
                  Add filter
                </Button>

                <Button
                  {...DEFAULT_BTN_PROPS}
                  className='g-btn-default'
                  onClick={() => handleRemoveAllFilters(removeAll)}
                >
                  Remove all
                </Button>
              </div>
            </>
          )
        }}
      </Form.List>
    )
  }

  const BtnPrimary = () => {
    return (
      <Button
        {...DEFAULT_BTN_PROPS}
        className='g-btn-default'
        onClick={() => {
          if (formRef.current) {
            setIsPreviewMode(false)
            formRef.current.submit()
          }
        }}
        disabled={isLoading}
      >
        {generateBtnTitle()}
      </Button>
    )
  }

  const BtnDelete = () => {
    if (modalMode === MODAL_MODE.CREATE) return <></>
    return (
      <Popconfirm
        title='Are you sure to delete this filter?'
        onConfirm={deleteFilter}
        okText='Yes'
        cancelText='No'
      >
        <Button {...DEFAULT_BTN_PROPS} type='default' disabled={isLoading}>
          Delete
        </Button>
      </Popconfirm>
    )
  }

  const BtnPreview = () => {
    return (
      <Button
        {...DEFAULT_BTN_PROPS}
        type='default'
        onClick={handleOnPreview}
        disabled={isLoading}
      >
        Preview
      </Button>
    )
  }

  const BtnCancel = () => {
    return (
      <Button
        {...DEFAULT_BTN_PROPS}
        type='default'
        onClick={handleOnCancel}
        disabled={isLoading}
      >
        Cancel
      </Button>
    )
  }

  const tabItems: TabsProps['items'] = [
    {
      key: DEFAULT_FILTER_TABS.CRITERIA,
      label: DEFAULT_FILTER_TABS.CRITERIA,
      children: <div key={DEFAULT_FILTER_TABS.CRITERIA}>{renderFormList()}</div>
    },
    {
      key: DEFAULT_FILTER_TABS.COLUMN,
      label: DEFAULT_FILTER_TABS.COLUMN,
      children: (
        <FilteringColumn
          key={DEFAULT_FILTER_TABS.COLUMN}
          availableFields={availableFields}
          setAvailableFields={setAvailableFields}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
        />
      )
    }
  ]

  const getOptionsBySource = async (column: string, url: string) => {
    // Add column to the selectLoaders state
    setSelectLoaders([...selectLoaders, column])

    // Fetch the options from the source url
    await callGetApi(url).then((res) => {
      // Format & add the options to the cacheSources state
      const options = res.data.map((item: TApiSource) => ({
        label: item.value,
        // value: item.g_source_system_id
        value: item.value,
        key: item.g_source_system_id
      }))
      const newSource = {
        column,
        options
      }
      setCacheSources([...cacheSources, newSource])

      // Remove column from the selectLoaders state
      const thisIndex = getIndexFromSelectLoaders(column)
      const newSelectLoaders = Array.from(selectLoaders)
      newSelectLoaders.splice(thisIndex, 1)
      setSelectLoaders(newSelectLoaders)
    })
  }

  useEffect(() => {
    // Runs only when not in preview mode
    if (isModalOpen && !isPreviewMode) {
      // Runs when Create filter is clicked
      if (modalMode === MODAL_MODE.CREATE) {
        setFieldMapping([])
        form.resetFields()

        // Runs when custom columns is enabled
        if (hasCustomColumns) {
          setSelectedFields(getAllVisibleColumns(allColumnHeaders))
          setAvailableFields([])
        }
      }

      // Runs when Edit icon is clicked
      else if (modalMode === MODAL_MODE.UPDATE) {
        initializeForm()
      }
    }

    // Criteria tab is always selected by default
    if (isModalOpen) setActiveTabKey(DEFAULT_FILTER_TABS.CRITERIA)
    // eslint-disable-next-line
  }, [isModalOpen])

  useEffect(() => {
    if (allFields.length) {
      setFieldOptions(generateFieldOptions())
    }
    // eslint-disable-next-line
  }, [allFields])

  return (
    <Modal
      title={generateModalTitle()}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <BtnPrimary key='btn-primary' />,
        <BtnDelete key='btn-delete' />,
        <BtnPreview key='btn-preview' />,
        <BtnCancel key='btn-cancel' />
      ]}
      width={900}
    >
      {isLoading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}

      <Form
        ref={formRef}
        form={form}
        layout='vertical'
        autoComplete='off'
        onFinish={handleOnFinish}
        onFinishFailed={handleOnFinishFailed}
        disabled={isLoading}
      >
        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Enter name of the filter.' }]}
        >
          <Input maxLength={INPUT_MAX_LENGTH} />
        </Form.Item>

        {hasCustomColumns ? (
          <Tabs
            activeKey={activeTabKey}
            onChange={(activeKey: string) => setActiveTabKey(activeKey)}
            items={tabItems}
            style={{ marginTop: -16 }}
          />
        ) : (
          renderFormList()
        )}
      </Form>
    </Modal>
  )
}

export default FilterModal
