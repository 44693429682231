/** react router imports */
import { useLocation } from 'react-router-dom'

// =================================================================
export default function AppVersion() {
  /** react router */
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const isVersionDisplayed = searchParams.get('version')

  const version = localStorage.getItem('version')

  return (
    <div
      className='version'
      style={{
        display: isVersionDisplayed !== 'true' ? 'none' : ''
      }}
    >
      {version}
    </div>
  )
}
