import { Form, Modal, Select } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { getAppTypeForm } from '../../services/TxnManagerAPI'

interface AddNewLineOptionModalProps {
  visible: boolean
  loading: boolean
  enabledLineTypes: string[]
  enabledLineTypesOptions: string[]
  setModalVisible: (value: SetStateAction<boolean>) => void
  setLoading: (value: SetStateAction<boolean>) => void
  onOk: (appType: string, formId?: number, formLabel?: string) => void
  setLineType: (value: SetStateAction<string | undefined>) => void
}

interface FormType {
  id: number
  value: string
}

const AddNewLineOptionModal: FC<AddNewLineOptionModalProps> = ({
  visible,
  loading,
  enabledLineTypes,
  enabledLineTypesOptions,
  setModalVisible,
  setLoading,
  onOk,
  setLineType
}: AddNewLineOptionModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [selectedLineType, setSelectedLineType] = useState<string>('')
  const [selectedFormType, setSelectedFormType] = useState<number | null>(null)
  const [isMan, setIsMan] = useState<boolean>(false)
  const [formTypes, setFormTypes] = useState<FormType[]>([])

  const handleOnOk = async () => {
    const appKey = form.getFieldValue('appKey')
    if (appKey === 'MAN') {
      onOk(form.getFieldValue('appKey'))
      return
    }

    if (selectedFormType && selectedLineType) {
      const selectedForm = formTypes.find(
        (item) => item.id === selectedFormType
      )

      onOk(selectedLineType, selectedFormType, selectedForm?.value)
      return
    }

    setIsMan(false)
    setLoading(true)
    try {
      const { data } = await getAppTypeForm(appKey)
      setFormTypes(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleOnCancel = () => {
    form.resetFields()
    setModalVisible(false)
  }

  const getFormOptions = () => {
    const formOptions: { value: number; label: string }[] = []

    formTypes.forEach((item) =>
      formOptions.push({ value: item.id, label: item.value })
    )
    return formOptions
  }

  const isOkBtnDisabled = (): boolean => {
    if (!isMan) {
      if (!selectedFormType) return true
      return false
    } else {
      if (selectedLineType) return false
      return true
    }
  }

  useEffect(() => {
    if (visible) {
      form.resetFields()
      setSelectedLineType('')
      setSelectedFormType(null)
      setIsMan(true)
    }
  }, [form, visible])

  return (
    <Modal
      data-testid='add-new-line-option-modal'
      data-cy='add-new-line-option-modal'
      open={visible}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      okText='Next'
      okButtonProps={{
        disabled: isOkBtnDisabled()
      }}
      closable={false}
      title={isMan ? 'Select line type' : 'Select form type'}
      confirmLoading={loading}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        layout='vertical'
        data-testid='add-new-line-option-modal-form'
        data-cy='add-new-line-option-modal-form'
      >
        {isMan ? (
          <Form.Item
            name='appKey'
            label='Please select the type of line to add'
            rules={[{ required: true, message: 'Please fill out this field.' }]}
          >
            <Select
              data-testid='add-new-line-option-modal-line-type-field'
              data-cy='add-new-line-option-modal-line-type-field'
              placeholder='Line type'
              options={[
                {
                  value: 'MAN',
                  label: 'Manual',
                  disabled:
                    !enabledLineTypes.find((item) => item === 'MAN') ||
                    !enabledLineTypesOptions.find((item) => item === 'MAN')
                },
                {
                  value: 'OPEN',
                  label: 'Internal',
                  disabled:
                    !enabledLineTypes.find((item) => item === 'OPEN') ||
                    !enabledLineTypesOptions.find((item) => item === 'OPEN')
                },
                {
                  value: 'VDAC',
                  label: 'Vendor',
                  disabled:
                    !enabledLineTypes.find((item) => item === 'VDAC') ||
                    !enabledLineTypesOptions.find((item) => item === 'VDAC')
                }
              ]}
              onChange={(value: string) => {
                setSelectedLineType(value)
                setLineType(value)
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name='formType'
            label='Please select the type of form'
            rules={[{ required: true, message: 'Please fill out this field.' }]}
          >
            <Select
              data-testid='add-new-line-option-modal-form-type-field'
              data-cy='add-new-line-option-modal-form-type-field'
              placeholder='Form type'
              options={getFormOptions()}
              onChange={(value: number) => {
                setSelectedFormType(value)
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default AddNewLineOptionModal
