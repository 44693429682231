// ** React Imports **
import { FC, useEffect, useState } from 'react'

// ** Ant Design Components **
import { Form, Input, Modal, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

// ** Custom Hooks **
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

// ** Types **
import type RejectJEModalProps from '../types/RejectJeModalProps'

// ** Custom Components **
import GButton from '../../../components/gappify/GButton'

// ** QJE Manager State **
import useQjeStore from '../manager/qje-store'
import useQje from '../hooks/useQje'

// =======================================================================
const RejectJEModal: FC<RejectJEModalProps> = (
  props: RejectJEModalProps
): JSX.Element => {
  // ** States **
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState<boolean>(false)

  // ** Hooks **
  const { selectedLineGroups } = useQjeStore()
  const RP = useRolesAndPermissions()

  // ** React Query **
  const {
    isLoadingQjeAction,
    handleSubmit,
    isSuccessQjeAction,
    isErrorQjeAction
  } = useQje()

  const handleShowModal = (): void => {
    setShowModal(true)
  }

  const handleCancelModal = (): void => {
    setShowModal(false)
    form.resetFields()
  }

  const submitRejectJE = async (): Promise<void> => {
    // ** NEW IMPLEMENTATION (using react-query) **
    handleSubmit({
      endpoint: '/api/reject/je',
      payload: {
        // Ensure that line_groups are unique values
        line_groups: Array.from(new Set(selectedLineGroups)),
        je_reject_reason: form.getFieldValue('reject_je_reason')
      }
    })
  }

  // Handle success POST method...
  useEffect(() => {
    if (isSuccessQjeAction) {
      // Handle success, e.g., show a success message, update UI, etc.
      message.success('JE rejected successfully!')
      setShowModal(false)
      form.resetFields()
    }
  }, [isSuccessQjeAction, form])

  // Catch error when POST method failed..
  useEffect(() => {
    if (isErrorQjeAction) {
      // Handle error, e.g., show an error message, log the error, etc.
      console.error('An error occurred during mutation!')
    }
  }, [isErrorQjeAction])

  return (
    <>
      <GButton
        dataTestId='qje-btn-reject-je'
        dataCy='qje-btn-reject-je'
        btnText='Reject JE'
        disabled={
          !RP.USER_HAS_REJECT_QJE_PERMISSION || selectedLineGroups?.length === 0
        }
        onClick={handleShowModal}
      />
      <Modal
        data-testid='qje-reject-je-modal'
        data-cy='qje-reject-je-modal'
        open={showModal}
        title={
          <>
            <ExclamationCircleOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              style={{ color: '#faad14' }}
            />{' '}
            Reject JE
          </>
        }
        width={900}
        okText='Confirm'
        onCancel={handleCancelModal}
        onOk={form.submit}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoadingQjeAction}
      >
        <Form
          data-testid='qje-reject-je-form'
          data-cy='qje-reject-je-form'
          form={form}
          layout='vertical'
          onFinish={submitRejectJE}
        >
          <Form.Item
            data-testid='qje-reject-je-reason-form-item'
            data-cy='qje-reject-je-reason-form-item'
            label='Please provide a brief explanation as to why this JE is being rejected before selecting confirm below.'
            name='reject_je_reason'
            rules={[
              { required: true, message: 'Reject reason field is required' }
            ]}
          >
            <Input
              data-testid='qje-reject-je-reason-input'
              data-cy='qje-reject-je-reason-input'
              placeholder='Reject reason'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default RejectJEModal
