import Monthly from '../types/MonthlyDataType'
import Standard from '../types/StandardDataType'

export const numberToOrdinal = (number: number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return 'Invalid input'
  }
  if (number === 0) {
    return 'Zeroth'
  }
  if (number < 0) {
    return 'Negative numbers not supported'
  }
  if (number <= 9) {
    const ordinals = [
      'Zeroth',
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth'
    ]
    return ordinals[number]
  }
  if (number % 10 === 0) {
    return number + 'th'
  }
  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + 'th'
  }
  const lastDigit = number % 10
  const suffixes = ['th', 'st', 'nd', 'rd']
  if (lastDigit >= 1 && lastDigit <= 3) {
    return number + suffixes[lastDigit]
  }
  return number + 'th'
}

export const getObjectPlacementByProperty = (
  arrayOfObjects: Record<string, any>[],
  propertyToBeChecked: string,
  valueToBeChecked: any
) => {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    if (arrayOfObjects[i][propertyToBeChecked] === valueToBeChecked) {
      return numberToOrdinal(i + 1)
    }
  }
  return 'Not found'
}

export const rearrangeArray: (
  arr: (Monthly | Standard)[]
) => (Monthly | Standard)[] = (arr: (Monthly | Standard)[]) => {
  let result = arr?.sort((a: Monthly | Standard, b: Monthly | Standard) => {
    const order: Record<string, number> = {
      external: 1,
      internal: 2,
      calculated: 3
    }
    return order[a.taskType!] - order[b.taskType!]
  })
  return result
}
