export const optionsList = [
  {
    bgColor: '#fdc9c3',
    fontColor: '#af2123',
    value: 'Violation: Standard'
  },
  {
    bgColor: '#cfeab6',
    fontColor: '#2c7750',
    value: 'Dismiss: Policy Exemption'
  },
  {
    bgColor: '#cfeab6',
    fontColor: '#2c7750',
    value: 'Dismiss: Other'
  }
]
export enum SuggestedActions {
  ViolationStandard = 'Violation: Standard',
  DismissPolicyExemption = 'Dismiss: Policy Exemption',
  DismissOther = 'Dismiss: Other'
}
