import { FC, useState } from 'react'
import GModalProps from '../../types/GModalProps'
import { Modal } from 'antd'
import GButton from './GButton'

const GModal: FC<GModalProps> = ({
  title,
  btnText,
  btnType,
  onClickBtn,
  btnIcon,
  visible,
  onOk,
  onCancel,
  content,
  okButtonProps,
  hasButton,
  width,
  className,
  btnDisabled,
  btnClassName,
  okText,
  cancelButtonProps,
  dataTestId,
  dataCy
}: GModalProps): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(visible)

  const onClickModalBtn = () => {
    setIsModalVisible(true)
    onClickBtn && onClickBtn()
  }

  const onClickCancel = () => {
    setIsModalVisible(false)
    onCancel && onCancel()
  }

  const onClickOk = () => {
    setIsModalVisible(false)
    onOk && onOk()
  }

  return (
    <>
      {hasButton && (
        <GButton
          dataTestId={`${dataTestId}-btn`}
          dataCy={`${dataCy}-btn`}
          btnText={btnText}
          type={btnType}
          onClick={onClickModalBtn}
          icon={btnIcon}
          disabled={btnDisabled}
          className={btnClassName}
        />
      )}
      <Modal
        data-testid={`${dataTestId}-modal`}
        data-cy={`${dataCy}-modal`}
        title={title}
        open={isModalVisible}
        onOk={onClickOk}
        okButtonProps={okButtonProps}
        onCancel={onClickCancel}
        width={width}
        className={className}
        okText={okText}
        cancelButtonProps={cancelButtonProps}
      >
        {content}
      </Modal>
    </>
  )
}

GModal.defaultProps = {
  btnType: 'primary',
  hasButton: true
}

export default GModal
