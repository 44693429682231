import {
  EXPIRATION,
  IDLE_TIMER,
  MILLISECOND
} from '../../../helpers/constants/Auth'

export default function idleCountdown(TO_SECONDS: number): void {
  const idletimer = setInterval(() => {
    let idleCounter: number = parseInt(localStorage.getItem(IDLE_TIMER)!)
    const events = ['click', 'keypress', 'keydown', 'mousedown', 'load']
    for (let i in events) {
      // eslint-disable-next-line
      window.addEventListener(events[i], () => {
        idleCounter = parseInt(localStorage.getItem(EXPIRATION)!) * TO_SECONDS

        localStorage.setItem(IDLE_TIMER, idleCounter.toString())
      })
    }

    if (idleCounter-- > 0) {
      localStorage.setItem(IDLE_TIMER, idleCounter.toString())
    } else {
      localStorage.removeItem(IDLE_TIMER)
      clearInterval(idletimer)
    }
  }, MILLISECOND)
}
