// ** Zustand Imports
import useSettingsStore from '../manager/settings-store'

// =================================================================
export default function useCollapsible(paramName?: string) {

  // ** Zustand **
  const {
    collapsed,
    setCollapsed,
    setHovered,
  } = useSettingsStore()

  // ** Handle Collapsible **
  const handleToggle = () => {
    setCollapsed(!collapsed)
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  return {
    handleToggle,
    handleMouseEnter,
    handleMouseLeave
  }
}
