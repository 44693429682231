/** antd imports */
import { Modal } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** custom components imports */
import ModalBody from './components/ModalBody'

/** hooks imports */
import useUpload from './hooks/useUpload'
import useUploadFileModal from './hooks/useUploadFileModal'

/** zustand store imports */
import useUploadFileModalStore from './manager/useUploadFileModalStore'

// =================================================================
export default function UploadFileModal() {
  /** hooks */
  const { handleUpload, isUploadLoading } = useUpload()
  const { getModalTitle, downloadSampleCsv, isUploadBtnDisabled } =
    useUploadFileModal()

  /** zustand store */
  const {
    isModalOpen,
    source,
    setIsModalOpen,
    setSource,
    setImportedAccrualName,
    setSourceFile,
    setImportedAccrualFile
  } = useUploadFileModalStore()

  useEffect(() => {
    if (isModalOpen) {
      setSource(null)
      setImportedAccrualName(null)
      setSourceFile(null)
      setImportedAccrualFile(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  return (
    <Modal
      data-testid='upload-file-modal'
      data-cy='upload-file-modal'
      open={isModalOpen}
      title={getModalTitle()}
      cancelText='Download Sample CSV'
      okText='Upload'
      onCancel={() => setIsModalOpen(false)}
      onOk={handleUpload}
      cancelButtonProps={{
        onClick: downloadSampleCsv,
        shape: 'round',
        className: 'g-btn-default',
        hidden: source === 'imported_accruals',
        disabled: !source || isUploadLoading
      }}
      okButtonProps={{
        shape: 'round',
        disabled: isUploadBtnDisabled() || isUploadLoading,
        loading: isUploadLoading
      }}
      width={900}
      centered
      destroyOnClose
    >
      <ModalBody />
    </Modal>
  )
}
