import { FC } from 'react'
import { Table, Modal } from 'antd'
import moment from 'moment'
import { CheckOutlined } from '@ant-design/icons'

interface QjeHistoryModalProps {
  showModal: boolean
  data: object
  onCancel: () => void
  settings: any
}

const QjeHistoryModal: FC<QjeHistoryModalProps> = ({
  showModal,
  data,
  onCancel,
  settings
}): JSX.Element => {
  const createColumns = () => {
    let columns: any = [
      {
        id: 10,
        title: 'Date',
        api_name: 'uniquekey',
        width: 200,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-date-${index}`}
                data-cy={`qje-review-je-history-date-${index}`}
              >
                {moment(row.created_at * 1000).format('MM/DD/YYYY hh:mm a')}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      },
      {
        key: 1,
        title: 'Status',
        dataIndex: 'status',
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-status-${index}`}
                data-cy={`qje-review-je-history-status-${index}`}
              >
                {row.status || row.g_status}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      },
      {
        key: 2,
        title: 'Action by',
        dataIndex: 'user_name',
        width: 200,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-user_name-${index}`}
                data-cy={`qje-review-je-history-user_name-${index}`}
              >
                {row.user_name}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      },
      {
        key: 3,
        title: 'Email',
        dataIndex: 'user_email',
        width: 200,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-user_email-${index}`}
                data-cy={`qje-review-je-history-user_email-${index}`}
              >
                {row.user_email}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      },
      {
        key: 4,
        title: 'Resubmitted',
        dataIndex: 'g_resubmitted',
        width: 150,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-g_resubmitted-${index}`}
                data-cy={`qje-review-je-history-g_resubmitted-${index}`}
              >
                {row.g_resubmitted === 1 && <CheckOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      },
      {
        key: 5,
        title: 'Resubmitted Reason',
        dataIndex: 'resubmitted_reason',
        width: 200,
        render: (value: any, row: any, index: number) => {
          const obj: any = {
            children: (
              <div
                className='qje-column'
                data-testid={`qje-review-je-history-resubmitted_reason-${index}`}
                data-cy={`qje-review-je-history-resubmitted_reason-${index}`}
              >
                {row.resubmitted_reason}
              </div>
            ),
            props: {
              rowSpan: row.rowSpan
            }
          }

          return obj
        }
      }
    ]

    if (settings && Object.keys(settings).length > 0) {
      Object.keys(settings).forEach((item: any) => {
        if (
          !['g_resubmitted', 'g_status'].includes(item) &&
          !settings[item].hidden
        )
          columns.push({
            api_name: item,
            dataIndex: item,
            label: settings[item].label,
            title: settings[item].label,
            width: 150
          })
      })
    }

    columns = columns.map((e: any) => {
      if ('g_debit' === e.api_name) {
        return {
          ...e,
          render: (input: any, row: any, index: number) => {
            if (row['g_debit'] === null) {
              row['g_debit'] = row['g_credit']
            }
            //to show debit values only
            if (index % 2 === 0) {
              return (
                <div
                  className='text-right'
                  data-testid={`qje-review-je-history-${e.api_name}-${index}`}
                  data-cy={`qje-review-je-history-${e.api_name}-${index}`}
                >
                  {parseFloat(input)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
              )
            }
          }
        }
      } else if ('g_credit' === e.api_name) {
        return {
          ...e,
          render: (input: any, row: any, index: number) => {
            if (row['g_credit'] === null) {
              return
            }

            return (
              <div
                className='text-right'
                data-testid={`qje-review-je-history-${e.api_name}-${index}`}
                data-cy={`qje-review-je-history-${e.api_name}-${index}`}
              >
                {parseFloat(input)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            )
          }
        }
      } else return e
    })

    return columns
  }

  const mergeSimilarRows = (rowsData: any) => {
    const pageSize = 10
    const uniqueUserIdsPerPage = new Set()
    let pageNo = 0
    const mergedData = rowsData.map((rowData: any, index: number) => {
      const updatedRowsData = { ...rowData }
      if (index !== 0 && index % pageSize === 0) {
        uniqueUserIdsPerPage.clear()
        pageNo += 1
      }
      if (uniqueUserIdsPerPage.has(rowData.uniquekey)) {
        updatedRowsData.rowSpan = 0
      } else {
        const occurCount = rowsData
          .slice(pageNo * pageSize, (pageNo + 1) * pageSize)
          .filter((data: any) => data.uniquekey === rowData.uniquekey).length
        updatedRowsData.rowSpan = Math.min(pageSize, occurCount)
        uniqueUserIdsPerPage.add(rowData.uniquekey)
      }
      return updatedRowsData
    })

    return mergedData
  }

  const destructureData = () => {
    let destructuredData: object[] = []
    if (data && Array.isArray(data) && data.length > 0) {
      data?.forEach((entry: any) => {
        entry?.data?.forEach((entry2: any) => {
          destructuredData = [
            ...destructuredData,
            {
              ...entry,
              ...entry2,
              uniquekey: entry.created_at,
              created_at: entry.created_at
            }
          ]
        })
      })
    }
    return mergeSimilarRows(
      destructuredData.sort((a: any, b: any) => b.created_at - a.created_at)
    )
  }

  return (
    <Modal
      data-testid='qje-history-modal'
      data-cy='qje-history-modal'
      title='View History'
      open={showModal}
      onCancel={onCancel}
      width={2000}
      bodyStyle={{ height: 600 }}
      footer={null}
    >
      <div className='qje-table'>
        <Table
          data-testid='qje-history-table'
          data-cy='qje-history-table'
          dataSource={destructureData()}
          columns={createColumns()}
          pagination={false}
          scroll={{ x: 'max-content', y: 500 }}
        />
      </div>
    </Modal>
  )
}
export default QjeHistoryModal
