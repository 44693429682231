import { DatePicker, Form, Input, message, Modal } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { postJE } from '../../services/QjeManagerApi'
import { formatDateSlash } from '../../utils/Date'
import moment from 'moment'

import type { AxiosError } from 'axios'

interface PostJEModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  onSuccess?: () => void
  onError?: () => void
  lineGroups: string[]
  period: string | undefined
  selectedLineGroupsRef: any
}

const PostJEModal: FC<PostJEModalProps> = ({
  visible,
  setModalVisible,
  onError,
  onSuccess,
  lineGroups,
  period,
  selectedLineGroupsRef
}: PostJEModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const jeApprovalType = null

  const handleCancelModal = (): void => {
    setModalVisible(false)
    form.resetFields()
  }

  const submitPostJE = async () => {
    setConfirmLoading(true)
    try {
      const payload = {
        line_groups: selectedLineGroupsRef.current.filter(
          (value: any, index: any, self: any) => {
            // only get unique values
            return self.indexOf(value) === index
          }
        ),
        je_date: formatDateSlash(form.getFieldValue('je_date')),
        je_memo: form.getFieldValue('je_memo'),
        je_reversal_date: form.getFieldValue('je_reversal_date')
          ? formatDateSlash(form.getFieldValue('je_reversal_date'))
          : null,
        je_approval_type: jeApprovalType
      }

      await postJE(payload)
      handleCancelModal()
      onSuccess && onSuccess()
      setConfirmLoading(false)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
      setConfirmLoading(false)
    } finally {
      setConfirmLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    setConfirmLoading(false)
  }

  useEffect(() => {
    if (visible) {
      form.setFieldValue('je_date', moment(period).endOf('month'))
      form.setFieldValue(
        'je_reversal_date',
        moment(period).add(1, 'months').startOf('month')
      )
    }
  }, [period, form, visible])

  return (
    <Modal
      data-testid='qje-post-je-modal'
      data-cy='qje-post-je-modal'
      open={visible}
      title="Please provide following information to post JE's"
      okText='Post'
      onCancel={handleCancelModal}
      onOk={form.submit}
      width={600}
      centered
      destroyOnClose
      confirmLoading={confirmLoading}
    >
      <Form
        data-testid='qje-post-je-form'
        data-cy='qje-post-je-form'
        form={form}
        layout='vertical'
        onFinish={submitPostJE}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label='JE Date'
          name='je_date'
          rules={[{ required: true }]}
          data-testid='qje-post-je-date-form-item'
          data-cy='qje-post-je-date-form-item'
        >
          <DatePicker
            style={{ width: '100%' }}
            data-testid='qje-post-je-date-picker'
            data-cy='qje-post-je-date-picker'
          />
        </Form.Item>
        <Form.Item
          label='JE Memo'
          name='je_memo'
          rules={[{ required: true }]}
          data-testid='qje-post-je-memo-form-item'
          data-cy='qje-post-je-memo-form-item'
        >
          <Input
            data-testid='qje-post-je-memo-input'
            data-cy='qje-post-je-memo-input'
          />
        </Form.Item>
        <Form.Item
          label='JE Reversal Date'
          name='je_reversal_date'
          data-testid='qje-post-je-reversal-date-form-item'
          data-cy='qje-post-je-reversal-date-form-item'
        >
          <DatePicker
            style={{ width: '100%' }}
            data-testid='qje-post-je-reversal-date-picker'
            data-cy='qje-post-je-reversal-date-picker'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

PostJEModal.defaultProps = {
  visible: false
}

export default PostJEModal
