// ** AntD Imports
import { Form, Table } from 'antd'

// ** Data Imports
import EmailVendorData from '../../../../mock/EmailVendorData'

// ** API Services Imports
import useAnalytics from '../../../../hooks/useAnalytics'

// ** Zustand Imports
import useAnalyticsStore from '../../../../manager/analytics-store'
// import useTable from '../../../../hooks/useTable'

// ** Type Imports
import EmailDetailsType from '../../../../types/EmailDetailsType'

// =================================================================
const Vendors = () => {
  // ** AntD **
  const [form] = Form.useForm()

  // ** Data **
  const { columns, rowsFromBackend } = EmailVendorData()

  // ** Hooks **
  // const { rowSelection } = useTable(rowsFromBackend)

  // ** Store **
  const {
    responseType,
    setSelectedRowsToEmail,
    selectedRowsToEmailKeys,
    setSelectedRowsToEmailKeys
  } = useAnalyticsStore()

  // ** API Services **
  // const { getUpdateAccrualManager, getEmailDetailsVendor } = useAnalytics({
  //   responseType: responseType
  // })
  const { getEmailDetailsVendor, postEmailLoading } = useAnalytics({
    responseType: responseType
  })
  const { isRefetching, isLoading } = getEmailDetailsVendor

  const customRowSelection = {
    selectedRowKeys: selectedRowsToEmailKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: EmailDetailsType[]
    ) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )

      setSelectedRowsToEmail(selectedRows)
      setSelectedRowsToEmailKeys(selectedRowKeys)
    }
  }

  return (
    <Form form={form} component={false}>
      <Table
        dataSource={rowsFromBackend as []}
        columns={columns as []}
        pagination={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        rowSelection={{ ...customRowSelection }}
        loading={isRefetching || isLoading || postEmailLoading}
      />
    </Form>
  )
}

export default Vendors
