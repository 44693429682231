// ** AntD Imports
import { Form } from 'antd'

// ** Content Imports
import MonthlyPeriodDropdown from './contents/PeriodDropdown'
import MonthlyTable from './contents/Table'

// ** Style Imports
import styles from '../../styles/tabs-container-styles.module.css'

// ** Zustand Imports
import useScheduleManager from '../../hooks/useScheduleManager'
import ScheduleManagerMonthlyModal from './contents/Modal'
import useScheduleStore from '../../manager/schedule-store'

// ** Custom components
import MonthlyFooter from './contents/Footer'

// =================================================================
const Monthly = () => {
  useScheduleManager()

  let { editMonthly, handleOnClickSave } = useScheduleStore()

  // ** AntD **
  const [form] = Form.useForm()

  return (
    <Form form={form} onFinish={() => handleOnClickSave('monthly-save')}>
      <div className={styles.container}>
        <MonthlyPeriodDropdown />
        <MonthlyTable form={form} />
        {editMonthly && (
          <>
            <MonthlyFooter form={form} />
            <ScheduleManagerMonthlyModal form={form} />
          </>
        )}
      </div>
    </Form>
  )
}

export default Monthly
