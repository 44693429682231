const formatIfDecimal = (decimalStr: string): string => {
  let formattedStr: string = ''
  try {
    const decimalNum: number = parseFloat(decimalStr)
    if (!isNaN(decimalNum)) {
      formattedStr = decimalNum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return formattedStr
    }
  } catch (error) {
    formattedStr = decimalStr
  }
  return formattedStr
}

export default formatIfDecimal
