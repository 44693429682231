/** zustand state imports */
import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import useFlagStore from 'src/features/Flag/stores/flagStore'

type FeatureFlag = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS]

// =================================================================
export default function useIsFlagEnabled() {
  /** zustand state */
  const flags = useFlagStore((state) => state.flags)

  const isFlagEnabled = (featureFlag: FeatureFlag) => {
    const matchedFlags = flags.filter((item) => {
      const { name, isActive } = item
      const isMatched = isActive && name === featureFlag
      return isMatched
    })
    return Boolean(matchedFlags.length)
  }

  return isFlagEnabled
}
