// ** Custom Component Imports
import AnalyticsDropdown from '../components/Dropdown'
import AdditionalOptions from '../components/AdditionalOptions'
import IncreasingSpendTable from '../components/Table/IncreasingSpendTable'

// =================================================================
const IncreasingSpend = () => {
  return (
    <>
      <AnalyticsDropdown />
      <AdditionalOptions
        showAdditionalOptions={false}
        showEmailButton={false}
        hasShowCount={false}
        hasShowNonResponsiveVendors={false}
      />
      <IncreasingSpendTable />
    </>
  )
}

export default IncreasingSpend
