// ** Hook Imports
import useApiService from '../hooks/useApiService'

// ** Type Imports
interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface AnalyticsParams {
  months_to_show?: string | number
  with_count?: string | number
  period?: string
  order_by?: string
  non_responsive_only?: string | number
  limit?: number
  page_number?: string | number
  vendor_id?: string
  duration?: string
  filter?: number
  preview_mode?: number
}

export interface AnalyticsData {
  email?: string | ''
  token_duration?: number
  vendor_id?: string
  report?: string
  note?: string | undefined
  user_id?: string
  subject?: string
  body?: string
  data?: EmailListData[]
}

interface EmailListData {
  id: number | string
  name: string
  task_type: string
  email: string
  cc_email: string
  additional_email?: string | undefined
}

interface AnalyticsOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export const useAnalyticsService = () => {
  const { apiRequest } = useApiService()

  const AnalyticsAPI = (
    props: BaseProps<AnalyticsParams, AnalyticsData, AnalyticsOptions>
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    AnalyticsAPI
  }
}
