import React from 'react'
import DROPDOWN_KEYS from '../constants/DROPDOWN_KEYS'
import {
  deleteAccrualRules,
  toggleAccrualRuleStatus
} from '../../../../../services/TaskManagerApi'
import AccrualRule from '../types/AccrualRule'
import { message } from 'antd'
import { SetStateAction } from 'react'
import { Modal } from 'antd'

const { confirm } = Modal

const showDeleteConfirm = (id: number, loadDataSource: () => Promise<void>) => {
  confirm({
    title: 'Are you sure to delete this Accrual Rule?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    async onOk() {
      try {
        await deleteAccrualRules(id)
        await loadDataSource()
        message.success('Deleted rule.')
      } catch (err) {
        console.log('error', err)
        message.error('An error occurred. Try again.')
      }
    }
  })
}

export default async function handleDropdownOnClick(
  id: number,
  loadDataSource: () => Promise<void>,
  setModalVisible: (value: SetStateAction<boolean>) => void,
  setEditRecord: (value: SetStateAction<AccrualRule | undefined>) => void,
  record: AccrualRule,
  key?: React.Key
): Promise<any> {
  if (key === DROPDOWN_KEYS.ACTIVATE) {
    try {
      await toggleAccrualRuleStatus(id)
      await loadDataSource()
      message.success('Updated status.')
    } catch (err) {
      console.log('error', err)
      message.error('An error occurred. Try again.')
    }
  } else if (key === DROPDOWN_KEYS.DELETE) {
    showDeleteConfirm(id, loadDataSource)
  } else if (key === DROPDOWN_KEYS.EDIT) {
    // TODO pending edit api
    setModalVisible(true)
    setEditRecord(record)
  }
}
