// ** AntD Imports
import { MenuProps, Switch } from 'antd'

// ** Style Imports
import styles from '../../styles/additional-options.module.css'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// ===============================================================

export default function useAdditionalOptionItems({
  hasShowCount,
  hasShowNonResponsiveVendors,
  hasShow12Months
}: {
  hasShowCount: boolean
  hasShowNonResponsiveVendors: boolean
  hasShow12Months: boolean
}) {
  // ** Store **
  const {
    selectedItem,
    setMonthsToShow,
    setInternalMonthsToShow,
    setVendorMonthsToShow,
    monthsToShow,
    internalMonthsToShow,
    vendorMonthsToShow,
    setShowInternalCount,
    setShowVendorCount,
    showInternalCount,
    showVendorCount,
    setShowInternalNonResponsive,
    setShowVendorNonResponsive,
    showInternalNonResponsive,
    showVendorNonResponsive
  } = useAnalyticsStore()

  const isShowMonthsChecked =
    (selectedItem === 'Internal' && internalMonthsToShow === 12) ||
    (selectedItem === 'Vendor' && vendorMonthsToShow === 12) ||
    (selectedItem === 'PO Compliance' && monthsToShow === 12)

  const isShowCountChecked =
    (selectedItem === 'Internal' && showInternalCount === 1) ||
    (selectedItem === 'Vendor' && showVendorCount === 1)

  const isShowNonResponsiveChecked =
    (selectedItem === 'Internal' && showInternalNonResponsive === 1) ||
    (selectedItem === 'Vendor' && showVendorNonResponsive === 1)

  // ===============================================================
  const AdditionalOptionsItems: MenuProps['items'] = []

  if (hasShowCount) {
    AdditionalOptionsItems.push({
      label: 'Show Count',
      key: '1',
      className: styles.item,
      icon: (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isShowCountChecked}
            onChange={(e) => {
              if (e) {
                switch (selectedItem) {
                  case 'Internal':
                    setShowInternalCount(1)
                    break
                  case 'Vendor':
                    setShowVendorCount(1)
                    break
                  default:
                    break
                }
              } else {
                switch (selectedItem) {
                  case 'Internal':
                    setShowInternalCount(0)
                    break
                  case 'Vendor':
                    setShowVendorCount(0)
                    break
                  default:
                    break
                }
              }
            }}
          />
        </div>
      )
    })
  }

  if (hasShowNonResponsiveVendors) {
    AdditionalOptionsItems.push({
      label: 'Show Non-Responsive Vendors Only',
      key: '2',
      className: styles.item,
      icon: (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isShowNonResponsiveChecked}
            onChange={(e) => {
              if (e) {
                switch (selectedItem) {
                  case 'Internal':
                    setShowInternalNonResponsive(1)
                    break
                  case 'Vendor':
                    setShowVendorNonResponsive(1)
                    break
                  default:
                    break
                }
              } else {
                switch (selectedItem) {
                  case 'Internal':
                    setShowInternalNonResponsive(0)
                    break
                  case 'Vendor':
                    setShowVendorNonResponsive(0)
                    break
                  default:
                    break
                }
              }
            }}
          />
        </div>
      )
    })
  }

  if (hasShow12Months) {
    AdditionalOptionsItems.push({
      label: 'Show 12 Months',
      key: '3',
      className: styles.item,
      icon: (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isShowMonthsChecked}
            onChange={(e) => {
              if (e) {
                switch (selectedItem) {
                  case 'Internal':
                    setInternalMonthsToShow(12)
                    break
                  case 'Vendor':
                    setVendorMonthsToShow(12)
                    break
                  case 'PO Compliance':
                    setMonthsToShow(12)
                    break
                  default:
                    break
                }
              } else {
                switch (selectedItem) {
                  case 'Internal':
                    setInternalMonthsToShow(6)
                    break
                  case 'Vendor':
                    setVendorMonthsToShow(6)
                    break
                  case 'PO Compliance':
                    setMonthsToShow(6)
                    break
                  default:
                    break
                }
              }
            }}
          />
        </div>
      )
    })
  }

  return {
    AdditionalOptionsItems
  }
}
