/** React Imports */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/** AntD Imports */
import { Spin } from 'antd'

/** Custom Hooks */
import useToolsGoogleSSO from 'src/pages/Login/hooks/useToolsGoogleSSO'

//==========================================================================
export default function LoginToolsSSO() {
  const navigate = useNavigate()
  const { allToolsParamsPresent } = useToolsGoogleSSO()

  useEffect(() => {
    if (!allToolsParamsPresent) {
      navigate('/login')
    }
  }, [allToolsParamsPresent, navigate])

  return (
    <div className='login-page'>
      <Spin
        size='small'
        style={{ margin: 5 }}
        data-testid='login-tools-sso-spinner'
      />
    </div>
  )
}
