import { create } from 'zustand'
import RCSettingsProps from '../types/RCSettingsStore'

// Define your store
const useStore = create<RCSettingsProps>((set, get) => ({
  viewModalType: undefined,
  showViewModal: false,
  availableOptions: [],
  showDeleteModal: false,
  selectedViewId: undefined,

  setViewModalType: (viewModalType) => set({ viewModalType }),
  setShowViewModal: (showViewModal) => set({ showViewModal }),
  setAvailableOptions: (availableOptions) => set({ availableOptions }),
  setShowDeleteModal: (showDeleteModal) => set({ showDeleteModal }),
  setSelectedViewId: (selectedViewId) => set({ selectedViewId })
}))

export default useStore
