import HttpStatus from './constants/HttpStatus'
import * as Auth from './constants/Auth'
import store from '../context/store'
import setError from '../context/actions/errorAction'
import setForbiddenError from '../context/actions/forbiddenErrorAction'
import generateGuid from './generateGuid'

export default function handleErrorResponse(error: any) {
  const { status } = error?.request || {}
  const { config } = error?.response || {}

  let message: string = ''
  if (!error?.response?.data?.message) {
    message = 'Something went wrong'
  } else {
    message = error?.response?.data?.message
  }

  if (status === HttpStatus.HTTP_UNAUTHORIZED) {
    if (config.url === '/api/login') {
      return Promise.reject(error)
    }

    localStorage.clear()
    if (!(localStorage.getItem(Auth.SHOW_LOGOUT_MODAL) === 'true'))
      window.location.href = '/login'
  } else if (status === HttpStatus.HTTP_UNPROCESSABLE_ENTITY) {
    return Promise.reject(error)
  } else if (status === HttpStatus.HTTP_FORBIDDEN) {
    store.dispatch(setForbiddenError({ visible: true }))
    return Promise.reject(error)
  } else {
    store.dispatch(
      setError({
        visible: true,
        guid: generateGuid(),
        status,
        message
      })
    )
    return Promise.reject(error)
  }
}
