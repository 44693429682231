/** tanstack imports */
import { useMutation } from '@tanstack/react-query'

/** axios imports */
import { AxiosResponse } from 'axios'

/** services imports */
import useApiService from '../../../../../hooks/useApiService'
import useWorkdayApiService from '../services/useWorkdayApiService'

/** types imports */
import WorkdayCheckCredentialsFormData from '../types/WorkdayCheckCredentialsFormData'

// =================================================================
export default function useWorkdayCheckCredentials() {
  /** services */
  const { msUrl } = useApiService()
  const { workDayApi } = useWorkdayApiService()

  const {
    mutate: workdayCheckCredentialsApi,
    isLoading,
    isSuccess,
    isError
  } = useMutation(workDayApi<WorkdayCheckCredentialsFormData>, {
    onSuccess: (response) => console.log(response),
    onError: (error: AxiosResponse) => console.log(error)
  })

  const handleWorkdayCheckCredentials = (
    data: WorkdayCheckCredentialsFormData
  ) => {
    workdayCheckCredentialsApi({
      options: {
        baseUrl: msUrl.idms,
        endpoint: '/api/api-manager-workday/check-credentials',
        method: 'POST'
      },
      data
    })
  }

  return { handleWorkdayCheckCredentials, isLoading, isSuccess, isError }
}
