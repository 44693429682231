// ** React Query **
import { useMutation, useQuery } from '@tanstack/react-query'

// ** Custom Hooks **
import useApiService from '../../../hooks/useApiService'

// ** API Services Imports **
import useQjeService from '../services/QjeService'

// ** Zustand Imports **
import useQjeStore from '../manager/qje-store'

// ** AntD Imports **
import { message } from 'antd'
import { useState } from 'react'

// =================================================================
export default function useQjeFiltering() {
  // ** States **
  const [deleteResponse, setDeleteResponse] = useState<any>()

  // ** API Services **
  const { QjeAPI } = useQjeService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** Local Storage **
  // const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  // ** Store **
  const { filter, tab, setFilter, setFilterFields, setPreviewMode } =
    useQjeStore()

  const getJeFilters = useQuery({
    queryKey: ['je-filters', tab],
    queryFn: () =>
      QjeAPI({
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/qje/filters',
          method: 'GET'
        }
      }),
    enabled: tab === 'review-je'
  })

  const getCustomFilterDetails = useQuery({
    queryKey: ['custom-filter-details', filter],
    queryFn: () =>
      QjeAPI({
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: `/api/qje/filters/${filter}`,
          method: 'GET'
        }
      }),
    enabled: ![
      'all',
      'unposted',
      'posted',
      'rejected',
      'extracted',
      'failed'
    ].includes(filter as string)
  })

  // ** POST METHOD(S) **
  const {
    mutate: qjeFilterCreation,
    isLoading: isLoadingFilterCreation,
    isSuccess: isSuccessFilterCreation,
    isError: isErrorFilterCreation
  } = useMutation(QjeAPI, {
    onSuccess: (response) => {
      // ** After succesfull API call, do something... **
      // message.success('All changes have been applied.')
      // console.log('POST method success', response)
      // TODO: set filter to response' default filter
      getJeFilters.refetch()
      setFilter(response?.responseData?.default_filter)

      // Reset states of Preview filter
      setFilterFields('')
      setPreviewMode(false)
    },
    onError: ({ data }) => {
      console.log('POST method failed', data)
      message.error('An error occurred.')
    }
  })

  // ** PUT METHOD(S) **
  const {
    mutate: qjeFilterUpdate,
    isLoading: isLoadingFilterUpdate,
    isSuccess: isSuccessFilterUpdate,
    isError: isErrorFilterUpdate
  } = useMutation(QjeAPI, {
    onSuccess: (response) => {
      // ** After succesfull API call, do something... **
      getJeFilters.refetch()
      getCustomFilterDetails.refetch()
      setFilter(response?.responseData?.default_filter)

      // Reset states of Preview filter
      setFilterFields('')
      setPreviewMode(false)
    },
    onError: ({ data }) => {
      console.log('PUT method failed', data)
      message.error('An error occurred.')
    }
  })

  const handleCreate = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    qjeFilterCreation({
      data: payload || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'POST'

        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  const handlePreview = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    qjeFilterCreation({
      data: { ...payload, previewMode: true } || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'POST'

        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  const handleEdit = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    qjeFilterUpdate({
      data: payload || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'PUT'
        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  // ** DELETE METHOD(S) **
  const {
    mutate: qjeFilterDeletion,
    isLoading: isLoadingFilterDeletion,
    isSuccess: isSuccessFilterDeletion,
    isError: isErrorFilterDeletion
  } = useMutation(QjeAPI, {
    onSuccess: (response) => {
      // ** After succesfull API call, do something... **
      // message.success('All changes have been applied.')
      // console.log('POST method success', response)
      getJeFilters.refetch()
      setDeleteResponse(response)
    },
    onError: ({ data }) => {
      console.log('DELETE method failed', data)
      message.error('An error occurred.')
    }
  })

  const handleDelete = ({
    payload,
    endpoint
  }: {
    payload?: Record<string, any>
    endpoint: string
  }) => {
    qjeFilterDeletion({
      data: payload || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'DELETE'

        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  return {
    getJeFilters,
    getCustomFilterDetails,
    qjeFilterCreation,
    isLoadingFilterCreation,
    isSuccessFilterCreation,
    isErrorFilterCreation,
    isLoadingFilterUpdate,
    isSuccessFilterUpdate,
    isErrorFilterUpdate,
    handleCreate,
    handleEdit,
    qjeFilterDeletion,
    isLoadingFilterDeletion,
    isSuccessFilterDeletion,
    isErrorFilterDeletion,
    handleDelete,
    deleteResponse,
    handlePreview
  }
}
