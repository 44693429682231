// ** React Imports **
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// ** Ant Design Components **
import { Card, Table, TableProps } from 'antd'
import { VList } from 'virtuallist-antd'

// ** Hooks **
import useTxnGlobalSettings from '../../../../../hooks/useTxnGlobalSettings'
import useQje from '../../../hooks/useQje'
import useQjeStore from '../../../manager/qje-store'

// ** Custom Components **
import ActionButtonsRow from '../../../components/ActionButtonsRow'
import QjeCustomPager from '../../../components/QjeCustomPager'
import HeaderLineGroupCheckbox from '../../../components/HeaderLineGroupCheckbox'
import LineGroupCheckbox from '../../../components/LineGroupCheckbox'
import VirtualSpinner from '../../../components/VirtualSpinner'

// ** Utility Functions **
import applyCustomRendering from '../../../utils/column/applyCustomRendering'
import destructureSettings from '../../../utils/column/destructureSettings'
import {
  getDateFormat,
  getDateTimeMomentFormat,
  getMomentTimeFormat
} from '../../../../../utils/Date'

// ** Types **
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import TransactionLine from '../../../../../types/TransactionLine'

// ====================================================================
const UnreviewedPoClosure = () => {
  // ** States and Refs **
  const [height, setHeight] = useState(window.innerHeight)
  const [unreviewedPoClosureData, setUnreviewedPoClosureData] = useState<
    TransactionLine[]
  >([])

  // ** Used for async virtual table **
  const lastListRenderInfo = useRef<{ start: number; renderLen: number }>({
    start: -1,
    renderLen: -1
  })

  // ** Zustand hooks **
  const {
    setUnreviewedPoClosureData: setGlobalUnreviewedPoClosureData,
    unreviewedPoClosureData: globalUnreviewedPoClosureData,
    page,
    recordsPerPage,
    setTotalDataSize,
    setTab,
    filter,
    period,
    sortField,
    sortDir,
    handleTableSort
  } = useQjeStore()

  // ** React Query hooks **
  const { getQjeSettings } = useQje()
  const {
    isLoading: isLoadingTxnGlobalSettings,
    isRefetching: isRefetchingTxnGlobalSettings
  } = useTxnGlobalSettings()
  const {
    data: qjeSettings,
    isLoading: isLoadingQjeSettings,
    isRefetching: isRefetchingQjeSettings
  }: UseQueryResult<any, any> = getQjeSettings
  const {
    data: unreviewedPoClosure,
    isLoading: isLoadingUnreviewedPoClosure,
    isRefetching: isRefetchingUnreviewedPoClosure
  }: UseQueryResult<any, any> = useQuery([
    'unreviewed-po-closure',
    filter,
    page,
    period,
    recordsPerPage,
    sortField,
    sortDir
  ])

  // ** Fixed for Unreviewed PO Closure **
  let fixedColumns: TableProps<TransactionLine>['columns'] = [
    {
      title: () => <HeaderLineGroupCheckbox />,
      dataIndex: 'fixed_checkbox',
      width: 53,
      fixed: 'left',
      // ** Use this to enable async rendering... **
      render: (value, record, index) =>
        [undefined].includes(value) ? (
          <VirtualSpinner />
        ) : (
          value && (
            <LineGroupCheckbox index={index} line_group={record.line_group} />
          )
        ),
      onCell: (record) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-unreviewed-checkbox-linegroup-${record.id}`,
        'data-cy': `qje-unreviwed-checkbox-linegroup-${record.id}`
      })
    }
  ]

  const isLoadedAllData: () => boolean = () => {
    return (
      !isLoadingTxnGlobalSettings &&
      !isLoadingQjeSettings &&
      !isLoadingUnreviewedPoClosure &&
      !isRefetchingUnreviewedPoClosure &&
      !isRefetchingTxnGlobalSettings &&
      !isRefetchingQjeSettings
    )
  }

  // This is only for SORTING
  // Pagination and filters are unused; they are handled by custom components
  const handleTableChange: TableProps<TransactionLine>['onChange'] = (
    pagination,
    filters,
    { field, order }: any
  ) => {
    const sortField = field.replace('custom_', '') // `custom_` is appended through onListRender()
    const sortDir = order === 'ascend' ? 'asc' : 'desc'
    handleTableSort(sortField, sortDir)
  }

  // ** Used for async virtual table **
  const onListRender = useCallback(
    (listInfo: { start: number; renderLen: number }) => {
      const { start, renderLen } = listInfo
      const lastInfo = lastListRenderInfo.current
      if (start !== lastInfo.start || renderLen !== lastInfo.renderLen) {
        lastListRenderInfo.current = { start, renderLen }
        setUnreviewedPoClosureData((prev) => {
          const currentData = prev.slice(start, start + renderLen)
          currentData.forEach((item: any, index: any) => {
            // Handle fixed columns here...
            item.fixed_checkbox = true

            // Handle custom columns here...
            // Columns that render plain text only...

            // Virtual table's index seems to be dynamic while scrolling, causing the checking of even/odd index to be inconsistent
            // To override this behavior, use the `isEven` property for more consistent checking

            item.custom_g_debit = item.isEven
              ? parseFloat(item.g_debit || item.g_credit)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : null
            item.custom_g_credit = !item.isEven
              ? parseFloat(item.g_credit || item.g_debit)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : null
            item.custom_created_at =
              getDateTimeMomentFormat(
                item.created_at,
                getDateFormat('m/d/Y'),
                getMomentTimeFormat('h:i a')
              ) || null

            // Columns that render JSX elements...
            item.custom_transaction_number = item.transaction_number || null
            item.custom_g_threshold_support_document =
              item.g_threshold_support_document || null
            item.custom_g_reviewed = item.g_reviewed || null
            item.custom_g_resubmitted = item.g_resubmitted === 1 || null
            item.custom_g_is_prepaid = item.g_is_prepaid === 1 || null
          })
          const newData = JSON.parse(JSON.stringify(prev))
          return newData
        })
      }
    },
    []
  )

  // ** Used for virtualizing table **
  // Link to guide: https://codesandbox.io/s/shu-xing-biao-ge-forked-4lt6u?file=/src/index.tsx
  const vComponent = useMemo(
    () =>
      VList({
        height: 'calc(100vh - 355px)', //actual table size
        resetTopWhenDataChange: false,
        onListRender
      }),
    // eslint-disable-next-line
    [onListRender, height, globalUnreviewedPoClosureData]
  )

  // to adjust virtual list height that fits the table
  // if window is zoom in and out
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, [])

  useEffect(() => setTab('unreviewed-po-closure'), [setTab])

  useEffect(() => {
    if (!isLoadingUnreviewedPoClosure && !isRefetchingUnreviewedPoClosure) {
      setGlobalUnreviewedPoClosureData(unreviewedPoClosure?.responseData)
      setUnreviewedPoClosureData(
        (unreviewedPoClosure?.responseData as TransactionLine[]).map(
          (record, index) => {
            // Virtual table's index seems to be dynamic while scrolling, causing the checking of even/odd index to be inconsistent
            // To override this behavior, add an `isEven` property for more consistent checking
            let modifiedRecord = { ...record, isEven: index % 2 === 0 }
            return modifiedRecord
          }
        )
      )
      setTotalDataSize(
        Number(unreviewedPoClosure?.responseHeaders['x-total-count'])
      )
    }
  }, [
    isLoadingUnreviewedPoClosure,
    isRefetchingUnreviewedPoClosure,
    setGlobalUnreviewedPoClosureData,
    setTotalDataSize,
    unreviewedPoClosure?.responseData,
    unreviewedPoClosure?.responseHeaders
  ])

  return (
    <div
      data-testid='qje-unreviewed-po-closure'
      data-cy='qje-unreviewed-po-closure'
    >
      <Card style={{ borderTopWidth: 0 }}>
        <ActionButtonsRow />
        <div className='data-table'>
          <Table
            columns={
              isLoadedAllData()
                ? [
                    ...fixedColumns,
                    ...applyCustomRendering({
                      qjeColumns: destructureSettings({
                        settings: qjeSettings?.responseData,
                        isSortable: true
                      })
                    })!
                  ]
                : []
            }
            components={vComponent}
            data-cy='qje-unreviewed-po-closure-table'
            data-testid='qje-unreviewed-po-closure-table'
            dataSource={isLoadedAllData() ? unreviewedPoClosureData : []}
            loading={!isLoadedAllData()}
            onChange={handleTableChange}
            pagination={false}
            rowKey='id'
            scroll={{ x: '100%', y: 'calc(100vh - 355px)' }}
            showSorterTooltip={false}
          />
          <div className='qje-pagination'>
            <QjeCustomPager />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default UnreviewedPoClosure
