import { Select } from 'antd'

type TProps = {
  value: string
  onChange: (value: string) => void
}

const values = [
  'SUBSIDIARY_VENDOR',
  'SUBSIDIARY_DEPARTMENT',
  'SUBSIDIARY_GL',
  'VENDOR_SUBSIDIARY',
  'VENDOR_DEPARTMENT',
  'VENDOR_GL',
  'DEPARTMENT_SUBSIDIARY',
  'DEPARTMENT_VENDOR',
  'DEPARTMENT_GL',
  'GL_SUBSIDIARY',
  'GL_VENDOR',
  'GL_DEPARTMENT',
  'SUBSIDIARY_VENDOR_DEPARTMENT',
  'SUBSIDIARY_VENDOR_GL',
  'SUBSIDIARY_DEPARTMENT_VENDOR',
  'SUBSIDIARY_DEPARTMENT_GL',
  'SUBSIDIARY_GL_VENDOR',
  'SUBSIDIARY_GL_DEPARTMENT',
  'VENDOR_SUBSIDIARY_DEPARTMENT',
  'VENDOR_SUBSIDIARY_GL',
  'VENDOR_DEPARTMENT_SUBSIDIARY',
  'VENDOR_DEPARTMENT_GL',
  'VENDOR_GL_SUBSIDIARY',
  'VENDOR_GL_DEPARTMENT',
  'DEPARTMENT_SUBSIDIARY_VENDOR',
  'DEPARTMENT_SUBSIDIARY_GL',
  'DEPARTMENT_VENDOR_SUBSIDIARY',
  'DEPARTMENT_VENDOR_GL',
  'DEPARTMENT_GL_SUBSIDIARY',
  'DEPARTMENT_GL_VENDOR',
  'GL_SUBSIDIARY_VENDOR',
  'GL_SUBSIDIARY_DEPARTMENT',
  'GL_VENDOR_SUBSIDIARY',
  'GL_VENDOR_DEPARTMENT',
  'GL_DEPARTMENT_SUBSIDIARY',
  'GL_DEPARTMENT_VENDOR',
  'SUBSIDIARY_VENDOR_DEPARTMENT_GL',
  'SUBSIDIARY_VENDOR_GL_DEPARTMENT',
  'SUBSIDIARY_DEPARTMENT_VENDOR_GL',
  'SUBSIDIARY_DEPARTMENT_GL_VENDOR',
  'SUBSIDIARY_GL_VENDOR_DEPARTMENT',
  'SUBSIDIARY_GL_DEPARTMENT_VENDOR',
  'VENDOR_SUBSIDIARY_DEPARTMENT_GL',
  'VENDOR_SUBSIDIARY_GL_DEPARTMENT',
  'VENDOR_DEPARTMENT_SUBSIDIARY_GL',
  'VENDOR_DEPARTMENT_GL_SUBSIDIARY',
  'VENDOR_GL_SUBSIDIARY_DEPARTMENT',
  'VENDOR_GL_DEPARTMENT_SUBSIDIARY',
  'DEPARTMENT_SUBSIDIARY_VENDOR_GL',
  'DEPARTMENT_SUBSIDIARY_GL_VENDOR',
  'DEPARTMENT_VENDOR_SUBSIDIARY_GL',
  'DEPARTMENT_VENDOR_GL_SUBSIDIARY',
  'DEPARTMENT_GL_SUBSIDIARY_VENDOR',
  'DEPARTMENT_GL_VENDOR_SUBSIDIARY',
  'GL_SUBSIDIARY_VENDOR_DEPARTMENT',
  'GL_SUBSIDIARY_DEPARTMENT_VENDOR',
  'GL_VENDOR_SUBSIDIARY_DEPARTMENT',
  'GL_VENDOR_DEPARTMENT_SUBSIDIARY',
  'GL_DEPARTMENT_SUBSIDIARY_VENDOR',
  'GL_DEPARTMENT_VENDOR_SUBSIDIARY'
]

const options = values.map((value) => ({ label: value, value }))

const ViewDropdown = (props: TProps) => {
  const { value, onChange } = props

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      defaultValue='SUBSIDIARY_DEPARTMENT_GL_VENDOR'
      placeholder='Select view'
      style={{ width: 325 }}
    />
  )
}

export default ViewDropdown
