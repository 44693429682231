/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** 3rd party imports */
import jwt_decode from 'jwt-decode'
import moment from 'moment'

/** constants imports */
import {
  AUTH_TOKEN,
  EXPIRATION,
  EXPIRES_ON,
  EXPIRY_COUNTER,
  IDLE_TIMER,
  ROLES_AND_PERMISSIONS
} from '../../helpers/constants/Auth'
import TO_SECONDS from '../constants/TO_SECONDS'

/** hooks imports */
import useApiService from '../../hooks/useApiService'

/** api services imports */
import useAuthService from '../../services/useAuthService'

// =================================================================
export default function useRefreshToken() {
  /** api service */
  const { authAPI } = useAuthService()

  /** hooks */
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['refresh-token'],
    queryFn: () =>
      authAPI({
        options: {
          baseURL: msUrl.idms,
          endpoint: '/api/refresh',
          method: 'GET'
        }
      }),
    onSuccess: (response) => {
      const jwtToken = window.atob(response.access_token)
      const decodedjwt = jwt_decode(jwtToken)
      const new_expires_on = moment(response.expires_on).add(
        response.expires_in,
        'minutes'
      )

      localStorage.setItem(AUTH_TOKEN, response.access_token)
      localStorage.setItem(EXPIRATION, response.expires_in)

      localStorage.setItem(EXPIRES_ON, new_expires_on.toString())
      localStorage.setItem(
        EXPIRY_COUNTER,
        (response.expires_in * TO_SECONDS).toString()
      )

      localStorage.setItem(
        IDLE_TIMER,
        (response.expires_in * TO_SECONDS).toString()
      )

      localStorage.setItem(ROLES_AND_PERMISSIONS, JSON.stringify(decodedjwt))
    },
    enabled: false
  })
}
