/** constants imports */
import { SHOW_LOGOUT_MODAL } from '../../helpers/constants/Auth'

/** hooks imports */
import useLogout from '../../hooks/useLogout'

/** zustand imports */
import useResultModalStore from '../manager/useResultModalStore'

export default function useInfoModal() {
  /** zustand state */
  const { setShowModal } = useResultModalStore()

  /** hooks */
  const { refetch: logout } = useLogout()

  const showInfoModal = () => {
    setShowModal(true)
    localStorage.setItem(SHOW_LOGOUT_MODAL, 'true')
    window.onbeforeunload = function () {
      logout() //user will still be logged out even the page is refreshed
    }
  }

  return { showInfoModal }
}
