// ** React Query **
import { useMutation, useQueryClient } from '@tanstack/react-query'

// ** Custom Hooks **
import useApiService from '../../../hooks/useApiService'

// ** API Services Imports **
import useQjeService from '../services/QjeService'

// ** Zustand Imports **
import useQjeStore from '../manager/qje-store'

// ** AntD Imports **
import { message } from 'antd'

// =================================================================
export default function useBulkUpdate() {
  // ** API Services **
  const { QjeAPI } = useQjeService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** TanStack Query **
  const queryClient = useQueryClient()

  // ** Local Storage **
  // const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  // ** Store **
  const {
    filter,
    page,
    period,
    recordsPerPage,
    sortField,
    sortDir,
    tab,
    setSelectedLineGroups
  } = useQjeStore()

  // ** POST METHODS **
  const {
    mutate: qjeAction,
    isLoading: isLoadingQjeAction,
    isSuccess: isSuccessQjeAction,
    isError: isErrorQjeAction
  } = useMutation(QjeAPI, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([
        tab,
        filter,
        page,
        period,
        recordsPerPage,
        sortField,
        sortDir
      ])

      // ** After succesfull API call, do something... **
      // message.success('All changes have been applied.')
      // console.log('POST method success', response)
      setSelectedLineGroups([])
      message.success('Changes saved.')
    },
    onError: ({ data }) => {
      console.log('POST method failed', data)
      message.error('An error occurred.')
    }
  })

  const handleSubmit = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    qjeAction({
      data: payload || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'POST'

        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  return {
    isLoadingQjeAction,
    handleSubmit,
    isSuccessQjeAction,
    isErrorQjeAction
  }
}
