// ** Hook Imports **
import useApiService from '../hooks/useApiService'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData | TData[]
  options: TOptions
}

// TODO: add properties
interface QjeParams {
  filter?: string | number
  filter_fields?: string
  page?: number
  period?: string
  previewMode?: boolean
  subtype?: string
  type?: string
  recordsPerPage?: number
  sortField?: string
  sortDir?: string
  qjetype?: string
  term?: string
}

export interface QjeData {
  // TODO: add properties needed
  line_group?: string | number | null
}

interface QjeOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
  responseType?: string
}

// =================================================================
export default function useQjeService() {
  const { apiRequest } = useApiService()

  // TODO: replace any, if possible
  const QjeAPI = (props: BaseProps<QjeParams, any, QjeOptions>) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    QjeAPI
  }
}
