import {
  EXPIRY_COUNTER,
  IDLE_TIMER,
  MILLISECOND
} from '../../../helpers/constants/Auth'
import MsUrl from '../../../types/MsUrl'
import reset from './reset'

export default function countDown(
  callback: () => void,
  msUrl: MsUrl,
  TO_SECONDS: number
): void {
  const allowance = msUrl.token_exp_allowance * TO_SECONDS
  const timer = setInterval(() => {
    let counter: number = parseInt(localStorage.getItem(EXPIRY_COUNTER)!)
    if (counter < allowance) {
      if (parseInt(localStorage.getItem(IDLE_TIMER)!) < allowance) {
        localStorage.removeItem(EXPIRY_COUNTER)
        clearInterval(timer)
        callback()
      } else {
        reset(msUrl, TO_SECONDS)
      }
    }
    if (counter-- > 0) {
      localStorage.setItem(EXPIRY_COUNTER, counter.toString())
    }
  }, MILLISECOND)
}
