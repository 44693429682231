import { create } from 'zustand'

// ** Type Imports
import { TFilterDropdownObj, EModalActions } from '../types/global-filter-types'

type TGlobalFilterStore = {
  // State values
  activeFilter: string
  activeFilterObj: TFilterDropdownObj | null
  activeFilterId: number
  previewFilterObj: any
  openFilterModal: boolean
  filterModalAction: EModalActions
  isPreviewMode: number
  isPreviewModeDep: number
  sessionFilter: any

  // State setters
  setActiveFilter: (activeFilter: string) => void
  setActiveFilterObj: (activeFilterObj: TFilterDropdownObj | null) => void
  setActiveFilterId: (activeFilter: number) => void
  setPreviewFilterObj: (previewFilterObj: any) => void
  setOpenFilterModal: (openFilterModal: boolean) => void
  setFilterModalAction: (filterModalAction: EModalActions) => void
  setIsPreviewMode: (isPreviewMode: number) => void
  setIsPreviewModeDep: (isPreviewModeDep: number) => void
  setSessionFilter: (sessionFilter: any) => void
}

const useGlobalFilterStore = create<TGlobalFilterStore>()((set) => ({
  // State values
  activeFilter: 'all',
  activeFilterObj: null,
  activeFilterId: 0,
  previewFilterObj: null,
  openFilterModal: false,
  filterModalAction: EModalActions.CREATE,
  isPreviewMode: 0,
  isPreviewModeDep: 0,

  /**
   * ? Sample format for sessionFilter state
   * {
   *  "response-rates-vendor": {
   *    "id": 1,
   *    "name": "Filter name",
   *    "filterObj": { ..... }
   *  },
   *  .....
   * }
   */
  sessionFilter: null,

  // State setters
  setActiveFilter: (activeFilter: string) => set({ activeFilter }),
  setActiveFilterObj: (activeFilterObj: TFilterDropdownObj | null) =>
    set({ activeFilterObj }),
  setActiveFilterId: (activeFilterId: number) => set({ activeFilterId }),
  setPreviewFilterObj: (previewFilterObj) => set({ previewFilterObj }),
  setOpenFilterModal: (openFilterModal: boolean) => set({ openFilterModal }),
  setFilterModalAction: (filterModalAction: EModalActions) =>
    set({ filterModalAction }),
  setIsPreviewMode: (isPreviewMode: number) => set({ isPreviewMode }),
  setIsPreviewModeDep: (isPreviewModeDep: number) => set({ isPreviewModeDep }),
  setSessionFilter: (sessionFilter) => set({ sessionFilter })
}))

export default useGlobalFilterStore
