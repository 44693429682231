import { Modal, Table } from 'antd'
import { FC, useEffect, useState, useRef } from 'react'
import getTableColumns from './helpers/getTableColumns'
import UserDashBoardHeader from './components/UserDashBoardHeader'
import useGetUsers from './hooks/useGetUsers'
import UserModal from '../Users/components/UserModal'
import { useNavigate } from 'react-router-dom'
import { TablePaginationConfig } from 'antd/es/table'
import ActivateUserModal from './components/ActivateUserModal'
import ResetPasswordModal from './components/ResetPasswordModal'
import exportUserToCsv from './services/exportUserToCsv'
import download from 'downloadjs'
import ResendActivationLinkModal from './components/ResendActivationLinkModal'
import UserHistoryModal from './components/UserHistoryModal'
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'

const Users: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const searchTermRef = useRef('')
  const [sort, setSort] = useState<'asc' | 'desc'>('asc')
  const [page, setPage] = useState<number>(1)
  const { isLoading, users, loadUsers } = useGetUsers()
  const [userId, setUserId] = useState<number>()
  const [userEmail, setUserEmail] = useState<string>('')
  const [isActivateModalVisible, setIsActivateModalVisible] =
    useState<boolean>(false)
  const [userStatus, setUserStatus] = useState<
    'Active' | 'Inactive' | 'Pending'
  >()
  const [userName, setUserName] = useState<string>('')
  const [isUserModalVisible, setIsUserModalVisible] = useState(false)
  const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false)
  const [rolesFilter, setRolesFilter] = useState<number[]>()
  const [statusFilter, setStatusFilter] = useState<
    'Active' | 'Inactive' | 'Pending'
  >()
  const [searchTerm, setSearchTerm] = useState(searchTermRef.current)
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)
  const [isResendActionLinkModalVisible, setIsResendActionLinkModalVisible] =
    useState<boolean>(false)
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false)

  const RP = useRolesAndPermissions()
  const userHasSelfServiceUserManagementPermission: boolean =
    RP.USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION

  useEffect(() => {
    loadUsers({ sort, page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page])

  useEffect(() => {
    if (!userHasSelfServiceUserManagementPermission) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (searchTerm && `${searchTerm}`.length >= 3) ||
      !searchTerm ||
      `${searchTerm}`.length === 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        loadUsers({
          role: rolesFilter,
          status: statusFilter,
          ...(searchTerm && { term: searchTerm })
        })
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [searchTerm])

  const reset = () => {
    setUserEmail('')
    setUserId(undefined)
    setUserName('')
    setUserStatus(undefined)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any
  ): void => {
    setPage(pagination.current!)
    const sortOrder = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    setSort(sortOrder)
  }

  const handleOnClickExport = async (event: {
    key: string
    keyPath: string[]
    item: React.ReactInstance
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  }) => {
    setIsLoadingExport(true)

    if (event.key === 'csv') {
      exportUserToCsv({ sort, role: rolesFilter, status: statusFilter }).then(
        () => {
          setIsLoadingExport(false)
        }
      )

      try {
        const date = new Date().toISOString().slice(0, 10)

        const response = await exportUserToCsv({
          sort,
          role: rolesFilter,
          status: statusFilter
        })

        download(response, `User${date}.csv`, 'csv')
      } catch (error) {
        throw error
      } finally {
        setIsLoadingExport(false)
      }
    }
  }

  const onChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchTermRef.current = e.target.value
    setSearchTerm(e.target.value)
  }

  const onKeyDownSearch = ({ key }: any) => {
    if (key === 'Enter') {
      loadUsers({
        role: rolesFilter,
        status: statusFilter,
        ...(searchTerm && { term: searchTerm })
      })
    }
  }

  const onSelectStatus = (value: any) => {
    let stat = value === 'all' ? null : value
    setStatusFilter(stat)
    loadUsers({
      role: rolesFilter,
      ...(stat && { status: stat }),
      ...(searchTerm && { term: searchTerm })
    })
  }

  const onSelectRoles = (value: any) => {
    setRolesFilter(value)

    loadUsers({
      role: value,
      status: statusFilter,
      ...(searchTerm && { term: searchTerm })
    })
  }

  return (
    <div data-testid='users-page' data-cy='users-page' role='contentinfo'>
      <UserDashBoardHeader
        onClickAddUser={() => setIsUserModalVisible(true)}
        onChangeSearch={onChangeTerm}
        onKeyDownSearch={onKeyDownSearch}
        onSelectStatus={onSelectStatus}
        onSelectRoles={onSelectRoles}
        onClickExport={handleOnClickExport}
        loadingExport={isLoadingExport}
      />

      <Table
        data-testid='users-table'
        data-cy='users-table'
        dataSource={users}
        columns={getTableColumns(
          setUserId,
          setUserEmail,
          setIsActivateModalVisible,
          setUserStatus,
          setUserName,
          setIsUserModalVisible,
          setIsResetModalVisible,
          setIsResendActionLinkModalVisible,
          setIsHistoryModalVisible
        )}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`,
          pageSize: 100,
          defaultCurrent: 1,
          current: page,
          pageSizeOptions: [100],
          showSizeChanger: true
        }}
        scroll={{ x: 'max-content', y: '55dvh' }}
      />
      <ActivateUserModal
        visible={isActivateModalVisible}
        id={userId}
        email={userEmail}
        name={userName}
        status={userStatus}
        setIsModalVisible={setIsActivateModalVisible}
        onSubmitFinish={() => {
          setIsActivateModalVisible(false)

          const label =
            userStatus === 'Active'
              ? 'User Deactivated'
              : userStatus === 'Inactive'
              ? 'User Activated'
              : ''

          Modal.success({
            content: <div data-testid='success-message'>{label}</div>,
            onOk: () => {
              /**
               * ENG-8251 - Fix logic when updating user account status to retain the current filters set
               * Ref: https://gappify.atlassian.net/browse/ENG-8251
               */
              loadUsers({
                sort,
                page,
                role: rolesFilter,
                ...(statusFilter && { status: statusFilter }),
                ...(searchTerm && { term: searchTerm })
              })
            }
          })
        }}
      />
      <ResetPasswordModal
        visible={isResetModalVisible}
        id={userId}
        email={userEmail}
        setIsModalVisible={setIsResetModalVisible}
        onSubmitFinish={() => {
          Modal.success({
            content: 'Reset password notification successfully sent',
            onOk: () => {
              reset()
              loadUsers()
            }
          })
        }}
        afterClose={reset}
      />

      <UserModal
        visible={isUserModalVisible}
        onCancelModal={() => {
          setIsUserModalVisible(false)
          setUserId(undefined)
        }}
        setModalVisible={setIsUserModalVisible}
        editRecordId={userId}
        loadDataSource={() => {
          loadUsers({
            role: rolesFilter,
            status: statusFilter,
            ...(searchTerm && { term: searchTerm })
          })
          setUserId(undefined)
        }}
      />
      <ResendActivationLinkModal
        visible={isResendActionLinkModalVisible}
        id={userId}
        email={userEmail}
        name={userName}
        setIsModalVisible={setIsResendActionLinkModalVisible}
        onSubmitFinish={() => {
          setIsResendActionLinkModalVisible(false)
          Modal.success({
            content: (
              <div data-testid='success-message'>
                Activation Link Sent to {userEmail}
              </div>
            ),
            onOk: () => {
              reset()
              loadUsers({ sort, page })
            }
          })
        }}
      />
      <UserHistoryModal
        visible={isHistoryModalVisible}
        onCancelModal={() => {
          setIsHistoryModalVisible(false)
          setUserId(undefined)
        }}
        userId={userId}
      />
    </div>
  )
}

export default Users
