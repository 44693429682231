import { ApiPeriodDropdown } from 'src/types/api/ApiPeriodDropdown'
import { create } from 'zustand'

type PeriodDropdownStoreProps = {
  periodDropdownData: ApiPeriodDropdown[] | null
  setPeriodDropdownData: (data: ApiPeriodDropdown[] | null) => void
}

const usePeriodDropdownStore = create<PeriodDropdownStoreProps>((set) => ({
  periodDropdownData: null,
  setPeriodDropdownData: (data: ApiPeriodDropdown[] | null) =>
    set({ periodDropdownData: data })
}))

export default usePeriodDropdownStore
