// ** React Imports **
import { useEffect, useState } from 'react'

// ** AntD Imports **
import { Button, Modal, Table, message } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

// ** Custom Components **
import GButton from '../../../components/gappify/GButton'
import useSyncAction from '../hooks/useSyncAction'
import useAccrualManagerstore from '../manager/tasks-store'

import { useDispatch, useSelector } from 'react-redux'
import { setTasks } from '../../../context/actions/taskManagerAction'

// ** Types **
interface SyncModalProps {
  onClick?: (props?: Record<string, any>) => void
  columns?: Record<string, any>
  onClose?: (props?: Record<string, any>) => void
  disabled?: boolean
}

// ============================================================
const SyncModal = ({ onClick, columns, onClose, disabled }: SyncModalProps) => {
  // ** States **
  const [tableData, setTableData] = useState<Record<string, any>[]>([])

  // ** Store **
  const { isModalOpen, setIsModalOpen, taskId } = useAccrualManagerstore()

  // ** Hooks **
  const {
    getSyncTableData,
    handleOnSync,
    isLoadingPut,
    isSuccessPut,
    putResponseData
  } = useSyncAction()
  const { data: syncData, isRefetching, isLoading } = getSyncTableData

  // ** Redux **
  const dispatch = useDispatch()
  const tasks = useSelector((state: any) => state.allTasks.tasks)

  // ** Functions **
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    handleOnSync([taskId])
    // setIsModalOpen(false)
    // onClose && onClose()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    // onClose && onClose()
  }

  const filteredColumns = () => {
    const removeFromList = [
      'external_accrual',
      'calculation',
      'external_accrual_form',
      'internal_accrual_form',
      'calculated_accrual_form',
      'internal_accrual',
      'active',
      'task_inactive'
    ]

    const indicatorColumn = [
      {
        title: '',
        dataIndex: 'column_indicator',
        key: 'column_indicator',
        width: 150
      }
    ]

    const filteredColumnList = columns!.filter(
      (column: any) => !removeFromList.includes(column.key)
    )

    if (tableData[0]?.task_type === 'Vendor') {
      const vendorTaskColumns = ['vendor_name', 'email', 'cc_email']
      const vendorTaskColumnList = columns!.filter((column: any) =>
        vendorTaskColumns.includes(column.key)
      )
      return indicatorColumn.concat(vendorTaskColumnList)
    }

    return indicatorColumn.concat(filteredColumnList)
  }

  // ** useEffect **
  // Handle populating table data...
  useEffect(() => {
    if (!isRefetching && !isLoading && syncData) {
      let { copy, source } = syncData
      setTableData([
        { column_indicator: 'Accrual Manager', ...copy },
        { column_indicator: 'Source Data', ...source }
      ])
    }
  }, [isLoading, isRefetching, syncData])

  // Handle succesful sync...
  useEffect(() => {
    if (isSuccessPut) {
      message.success('Sync successful.')
      dispatch(
        setTasks(
          tasks.map((task: Record<string, any>) => {
            if (putResponseData?.id === task?.id)
              return {
                ...task,
                ...putResponseData,
                vendor_task_details: {
                  ...task?.vendor_task_details,
                  ...putResponseData?.vendor_task_details,
                  data_source: 'api'
                }
              }
            else return task
          })
        )
      )
      setIsModalOpen(false)
      onClose && onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPut])

  return (
    <>
      <GButton
        disabled={disabled}
        data-testid='sync-button'
        data-cy='sync-button'
        type='text'
        className='g-btn-icon action-btn'
        icon={
          <SyncOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        }
        btnText={'Sync Line'}
        onClick={() => {
          showModal()
          onClick && onClick()
        }}
      />
      <Modal
        data-testid='sync-modal'
        data-cy='sync-modal'
        title='Sync Accrual Manager Line'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={2000}
        bodyStyle={{ height: 600 }}
        footer={[
          <Button
            data-testid='sync-modal-cancel-btn'
            data-cy='sync-modal-cancel-btn'
            type='default'
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            data-testid='sync-modal-sync-btn'
            data-cy='sync-modal-sync-btn'
            type='primary'
            onClick={handleOk}
            loading={isLoadingPut}
          >
            Sync
          </Button>
        ]}
      >
        <Table
          scroll={{ x: 'max-content', y: 600 }}
          dataSource={tableData || []}
          columns={filteredColumns() as any}
          pagination={false}
          loading={isRefetching || isLoading}
        />
      </Modal>
    </>
  )
}

export default SyncModal
