// ** Third Party Imports
import { useQuery } from '@tanstack/react-query'

// ** API Services Imports
import useGlobalService from '../services/globalService'

// ** Hook Imports
import useApiService from './useApiService'

// ** Zustand Store
import { useGlobalStore } from '../global/global-store'
// =================================================================
export default function useFetchSourceCoasDropdownList() {
  // ** API Services **
  const { globalServiceApi } = useGlobalService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** Zustand Store
  const { selectedCoaType } = useGlobalStore()

  // ** ToDo : if we will pass g_inactive = 0 or false
  // const { selectedCoaType, isSourceCoaInactive } = useGlobalStore()

  // ** BE currently returns active Source COAs by default
  // ** GET method **
  const getSourceCoas = useQuery({
    queryKey: ['data'],
    queryFn: () =>
      globalServiceApi({
        params: {
          source_type: selectedCoaType
          // ** ToDo : if we will pass g_inactive = 0 or false
          // includesInactive : isSourceCoaInactive
        },
        options: {
          baseURL: msUrl.txn_manager,
          endpoint: `api/source/data`,
          method: `GET`
        }
      }),
    // enabled: !!selectedCoaType && typeof selectedCoaType !== 'undefined'
    enabled: false //temporarily hide ENG-6295 and to pass unit test
  })

  return {
    getSourceCoas
  }
}
