export const DEV: string = 'login.dev.gappifyinc.com'

export const QA: string = 'qa.gappify.com'

export const QA_MSB: string = 'login.qa.gappifyinc.com'

export const STAGING: string = 'staging.gappify.com'

export const STAGING_MSB: string = 'login.staging.gappifyinc.com'

export const UAT: string = 'uat.gappify.com'

export const UAT_MSB: string = 'login.uat.gappifyinc.com'

export const PRE_PROD: string = 'login.prod.gappifyinc.com'

export const PROD_NEXT: string = 'login-next.gappify.com'

export const PROD: string = 'login.gappify.com'

export const LOCAL: string = 'login.local.gappifyinc.com'

export const DOCKER_BE: string = 'fe.local.gappifyinc.com'
