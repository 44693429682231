import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'
import UserPayload from '../types/UserPayload'
import { AUTH_TOKEN } from '../../../../../helpers/constants/Auth'

export default function createUser(payload: UserPayload) {
  const token = localStorage.getItem(AUTH_TOKEN)
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    ContentType: 'application/json'
  }

  try {
    return axiosIdentityManagerInstance.post('/api/admin/user', payload, {
      headers
    })
  } catch (error) {
    throw error
  }
}
