// ** Third Party Imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** API Services Imports
import { useAnalyticsService } from '../services/AnalyticsService'

// ** Hook Imports
import useApiService from './useApiService'

// ** Zustand Store Imports
import useAnalyticsStore from '../manager/analytics-store'
import useGFStore from '../components/Modals/GlobalFilterModal/store/GlobalFilterStore'

// ** Type Imports
import {
  EModalActions,
  TFilterData
} from '../components/Modals/GlobalFilterModal/types/global-filter-types'
import { EReportTypes } from '../constants/Globals'

// =================================================================
export default function useGlobalFilters() {
  // ** Store **
  const { reportType } = useAnalyticsStore()

  // ** Zustand - Global Filtering Store **
  const isPreviewMode = useGFStore((state) => state.isPreviewMode)
  const filterModalAction = useGFStore((state) => state.filterModalAction)

  // ** API Services **
  const { AnalyticsAPI } = useAnalyticsService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** TanStack **
  const queryClient = useQueryClient()

  // ###########################################################
  // START: Query calls for filter component

  const getPrebuild = useQuery({
    queryKey: ['prebuild', reportType],
    queryFn: () => {
      return AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/${reportType}/filters/prebuild`,
          method: `GET`
        }
      })
    },
    enabled: !!reportType
  })

  const getCurrentFilters = useQuery({
    queryKey: ['current-filters', reportType],
    queryFn: () => {
      return AnalyticsAPI({
        options: {
          baseURL: msUrl.analytics,
          endpoint: `/api/filters/${reportType}/get`,
          method: `GET`
        }
      })
    },
    enabled: !!reportType
  })

  const {
    mutate: mutateFilter,
    data: mutateFilterData,
    isLoading: mutateFilterLoading,
    isSuccess: mutateFilterSuccess,
    isError: mutateFilterError
  } = useMutation(AnalyticsAPI, {
    onSuccess: (response) => {
      if (!isPreviewMode) {
        queryClient.invalidateQueries({
          queryKey: ['current-filters', reportType]
        })

        /**
         * ? Triggers re-fetching of current report data when action is UPDATE
         * ? Switch case between all report type values to re-fetch only active report query
         */
        if (filterModalAction === EModalActions.UPDATE) {
          switch (reportType) {
            // * Confirmation Rates (Vendor & Internal) report
            case EReportTypes.ResponseRatesInternal:
            case EReportTypes.ResponseRatesVendor:
              queryClient.invalidateQueries({
                queryKey: ['analytics']
              })
              break

            // * Cost Monitoring - PO Compliance report
            case EReportTypes.POCDepartment:
            case EReportTypes.POCEmployee:
            case EReportTypes.POCNonCompliant:
              queryClient.invalidateQueries({
                queryKey: ['cost-cutting']
              })
              break

            // * Cost Monitoring - Rising Expenses report
            case EReportTypes.RisingExpenses:
              queryClient.invalidateQueries({
                queryKey: ['increasing-spend']
              })
              break

            default:
              break
          }
        }
      }
    },
    onError: (error: { data: { message: string } }) => {
      console.log(`ERROR POST FILTER`, error)
    }
  })

  const handleCreateFilter = (filterData: TFilterData) => {
    mutateFilter({
      data: filterData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/filters`,
        method: 'POST'
      }
    })
  }

  const handleUpdateFilter = (filterData: TFilterData, id: number) => {
    mutateFilter({
      data: filterData,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/filters/${id}`,
        method: 'PATCH'
      }
    })
  }

  const handleDeleteFilter = (id: number) => {
    mutateFilter({
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/filters/${id}`,
        method: 'DELETE'
      }
    })
  }

  const {
    mutate: mutatePreview,
    data: mutatePreviewData,
    isLoading: mutatePreviewLoading,
    isSuccess: mutatePreviewSuccess,
    isError: mutatePreviewError
  } = useMutation(AnalyticsAPI)

  const handlePreviewFilter = (searchParams: any) => {
    mutatePreview({
      data: searchParams,
      options: {
        baseURL: msUrl.analytics,
        endpoint: `api/filters/preview`,
        method: 'POST'
      }
    })
  }

  // END: Query calls for filter component
  // ###########################################################

  // ###########################################################
  // START: Query calls for loading report results

  const {
    data: mutateSearchData,
    mutate: mutateSearch,
    isLoading: mutateSearchLoading,
    isSuccess: mutateSearchSuccess,
    isError: mutateSearchError
  } = useMutation(AnalyticsAPI)

  const handleSearchReport = (searchParams: any) => {
    mutateSearch({
      data: searchParams,
      options: {
        baseURL: msUrl.search!,
        endpoint: `api/search`,
        method: 'POST'
      }
    })
  }

  // END: Query calls for loading report results
  // ###########################################################

  return {
    getPrebuild,
    getCurrentFilters,
    mutateFilterData,
    mutateFilterLoading,
    mutateFilterSuccess,
    mutateFilterError,
    handleCreateFilter,
    handleUpdateFilter,
    handleDeleteFilter,
    mutateSearchData,
    mutateSearchLoading,
    mutateSearchSuccess,
    mutateSearchError,
    handleSearchReport,
    mutatePreviewData,
    mutatePreviewLoading,
    mutatePreviewSuccess,
    mutatePreviewError,
    handlePreviewFilter
  }
}
