import { ColumnsType } from 'antd/es/table'

export default function getTaskHistoryColumns(): ColumnsType<any> {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performed_by',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'old',
      width: 200
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'new',
      width: 200
    }
  ]
}
