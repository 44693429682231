// ** AntD Imports
import { Typography } from 'antd'

// =================================================================

const ResetPasswordHeader = () => {
  return (
    <div
      className='user-activation-header-container'
      data-testid='ResetPasswordHeader'
    >
      <Typography className='title'>Gappify Password Reset</Typography>

      <Typography className='subtitle'>
        Password must be reset after{' '}
        <Typography.Text strong>90 days</Typography.Text>!
      </Typography>
    </div>
  )
}

export default ResetPasswordHeader
