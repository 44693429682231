// ** AntD Imports
import { MenuProps, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

// ** Hook Imports
import useAnalytics from '../../../hooks/useAnalytics'
import useApiService from '../../../hooks/useApiService'

// ** Type Imports
import TableRecord from '../../../types/TableRecord'

// ** Zustand Imports
import useAnalyticsStore from '../../../manager/analytics-store'

// =================================================================
export default function usePermItems(record: TableRecord) {
  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** Hooks **
  const { msUrl } = useApiService()
  const { modifyPermNote } = useAnalytics({
    responseType: responseType
  })

  const user = JSON.parse(localStorage.getItem('pendoMetadata') || '{}')
  const isCurrentUserOwner = record.user_id === user.visitor.id

  const PermActionItems: MenuProps['items'] = [
    {
      label: 'Edit',
      key: '1',
      icon: <EditOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>,
      disabled: !isCurrentUserOwner
    },
    {
      label: (
        <Popconfirm
          title='Are you sure you want to remove this note?'
          placement='bottomLeft'
          onConfirm={() => {
            modifyPermNote({
              options: {
                baseURL: msUrl.analytics,
                endpoint: `api/delete/perm-note/${record.id}`,
                method: 'DELETE'
              }
            })
          }}
        >
          <a
            href=' '
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            Delete
          </a>
        </Popconfirm>
      ),
      key: '2',
      icon: <DeleteOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />,
      disabled: !isCurrentUserOwner
    }
  ]

  return { PermActionItems }
}
