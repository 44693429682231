import { getAccrualRules } from './../../../../../services/TaskManagerApi'
import { SetStateAction } from 'react'
import AccrualRule from '../types/AccrualRule'

export default async function loadAccrualRules(
  setIsLoading: (value: SetStateAction<boolean>) => void,
  setDataSource: (value: SetStateAction<AccrualRule[]>) => void,
  tabsKey: string
): Promise<void> {
  setIsLoading(true)
  try {
    const { data } = await getAccrualRules()
    const accrualRules = data
      .map((item: AccrualRule) => {
        item.key = item.id
        return item
      })
      .filter((item: AccrualRule) => item.default_type === tabsKey)
    setDataSource(
      accrualRules.sort((a: AccrualRule, b: AccrualRule) => a.weight - b.weight)
    )
  } catch (error) {
    console.log(error)
  } finally {
    setIsLoading(false)
  }
}
