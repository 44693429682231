import { axiosTaskManagerInstance } from '../helpers/axios'

export const getVendorTasks = (params) => {
  return axiosTaskManagerInstance
    .get('api/vendor-tasks', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const updateVendorTasks = (cid, token, row_id, data) => {
  return axiosTaskManagerInstance
    .put(`api/vendor-tasks/${row_id}`, data)
    .catch((err) => {
      console.log(err)
    })
}

export const updateVendorDetailsTasks = (cid, token, row_id, data) => {
  return axiosTaskManagerInstance
    .put(`api/vendor-tasks/details/${row_id}`, data)
    .catch((err) => {
      console.log(err)
    })
}

export const deleteVendorTasks = (cid, token, row_id) => {
  return axiosTaskManagerInstance
    .delete(`api/vendor-tasks/${row_id}`)
    .catch((err) => {
      console.log(err)
    })
}

export const createVendorTasks = (cid, token, data) => {
  return axiosTaskManagerInstance
    .post('api/vendor-tasks', data)
    .catch((err) => {
      console.log(err)
    })
}

export const copyVendorTasks = (row_id) => {
  let params = {
    id: row_id
  }

  return axiosTaskManagerInstance
    .post('api/vendor-tasks/copy-from-vendor-task', params)
    .catch((err) => {
      console.log(err)
    })
}

export const createVendorDetailsTasks = (cid, token, data) => {
  return axiosTaskManagerInstance
    .post('api/vendor-tasks/details', data)
    .catch((err) => {
      console.log(err)
    })
}

export const getSourceCoaOptions = (type) => {
  const params = {
    type: type
  }
  return axiosTaskManagerInstance
    .get('api/source/chart-of-accounts', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getSourceVendorOptions = () => {
  return axiosTaskManagerInstance.get('api/source/vendors').catch((err) => {
    console.log(err)
  })
}

export const getFormsOptions = () => {
  return axiosTaskManagerInstance.get('/api/source/forms').catch((err) => {
    console.log(err)
  })
}

export const getAuthVendorTask = (companyId, token) => {
  let params = {
    customerId: companyId,
    token: token,
    withCredentials: true
  }

  return axiosTaskManagerInstance.get('api/me', { params }).catch((err) => {
    console.log(err)
  })
}

export const massUpdateVendorTasks = (data) => {
  return axiosTaskManagerInstance
    .put(`api/vendor-tasks/mass-update`, data)
    .catch((err) => {
      console.log(err)
    })
}

export const massUpdateVendorTasksDetails = (data) => {
  return axiosTaskManagerInstance.put(
    `api/vendor-tasks/details/mass-update`,
    data
  )
}

export const getTaskManagerDynamicColumn = () => {
  return axiosTaskManagerInstance
    .get('api/task-manager/settings')
    .catch((err) => {
      console.log(err)
    })
}

// G E N E R A T E  T R A N S A C T I O N
export const createSourceVendor = (data) => {
  return axiosTaskManagerInstance
    .post('api/source/vendors', data)
    .catch((err) => {
      console.log(err)
    })
}

export const createFromSourceVendor = (data) => {
  return axiosTaskManagerInstance
    .post('api/vendor-tasks/create-from-vendor', data)
    .catch((err) => {
      console.log(err)
    })
}

export const updateVendorTaskExternalInternalCalc = (id, data) => {
  return axiosTaskManagerInstance
    .put(`api/vendor-tasks/${id}`, data)
    .catch((err) => {
      console.log(err)
    })
}

export const updateVendorTaskDetailsCalc = (id, data) => {
  return axiosTaskManagerInstance
    .put(`api/vendor-tasks/details/${id}`, data)
    .catch((err) => {
      console.log(err)
    })
}

export const createTransaction = (data) => {
  return axiosTaskManagerInstance
    .post('api/transactions', data)
    .catch((err) => {
      console.log(err)
    })
}

export const getVendorTasksById = (id) => {
  return axiosTaskManagerInstance.get(`api/vendor-tasks/${id}`).catch((err) => {
    console.log(err)
  })
}
