import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GIconProps from '../../types/GIconProps'

const GIcon: FC<GIconProps> = ({
  icon,
  mask,
  maskId,
  className,
  color,
  spin,
  spinPulse,
  spinReverse,
  pulse,
  beat,
  fade,
  beatFade,
  bounce,
  shake,
  border,
  fixedWidth,
  inverse,
  listItem,
  flip,
  size,
  pull,
  rotation,
  transform,
  symbol,
  style,
  tabIndex,
  title,
  titleId,
  swapOpacity,
  dataTestId,
  dataCy
}: GIconProps): JSX.Element => {
  return (
    <FontAwesomeIcon
      icon={icon}
      mask={mask}
      maskId={maskId}
      className={className}
      color={color}
      spin={spin}
      spinPulse={spinPulse}
      spinReverse={spinReverse}
      pulse={pulse}
      beat={beat}
      fade={fade}
      beatFade={beatFade}
      bounce={bounce}
      shake={shake}
      border={border}
      fixedWidth={fixedWidth}
      inverse={inverse}
      listItem={listItem}
      flip={flip}
      size={size}
      pull={pull}
      rotation={rotation}
      transform={transform}
      symbol={symbol}
      style={style}
      tabIndex={tabIndex}
      title={title}
      titleId={titleId}
      swapOpacity={swapOpacity}
      data-testid={dataTestId}
      data-cy={dataCy}
    />
  )
}

export default GIcon
