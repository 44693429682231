import { useEffect } from 'react'
import { Typography, Checkbox, Form, Button } from 'antd'
import { ApiLayoutData } from '../types/api'
import styles from './layoutForm.module.css'

type Props = {
  data: ApiLayoutData | undefined
  isLoading: boolean
  handleOnFinish: (values: any) => void
}

const { Title } = Typography

const LayoutForm = (props: Props) => {
  const { data, isLoading, handleOnFinish } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(data)
    // eslint-disable-next-line
  }, [data])

  return (
    <Form form={form} disabled={isLoading} onFinish={handleOnFinish}>
      <Title level={4} className={styles.section}>
        Gappify Accruals
      </Title>
      <div className={styles.content}>
        <div className={styles.group}>
          {/* Show Calculated column checkbox */}
          <Form.Item
            className={styles.formItem}
            name='display_ca_on_review_center'
            valuePropName='checked'
          >
            <Checkbox
              data-cy='show-calculated-column-checkbox'
              data-testid='show-calculated-column-checkbox'
            >
              Show Calculated column
            </Checkbox>
          </Form.Item>

          {/* Show Imported column checkbox */}
          <Form.Item
            className={styles.formItem}
            name='display_imported_on_review_center'
            valuePropName='checked'
          >
            <Checkbox
              data-cy='show-imported-column-checkbox'
              data-testid='show-imported-column-checkbox'
            >
              Show Imported column
            </Checkbox>
          </Form.Item>

          {/* Show Analysis section checkbox */}
          <Form.Item
            className={styles.formItem}
            name='display_variance_column'
            valuePropName='checked'
          >
            <Checkbox
              data-cy='show-analysis-section-checkbox'
              data-testid='show-analysis-section-checkbox'
            >
              Show Analysis section
            </Checkbox>
          </Form.Item>
        </div>

        {/* Submit button */}
        <Form.Item noStyle>
          <Button
            type='primary'
            htmlType='submit'
            data-cy='save-button'
            data-testid='save-button'
            loading={isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default LayoutForm
