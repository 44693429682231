/** hooks imports */
import useApiService from '../hooks/useApiService'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface AuthParams {
  email?: string
  uid?: string | null
}

interface AuthData {
  email?: string | ''
  token_duration?: number
}

interface AuthOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

/// =================================================================
export default function useAuthService() {
  const { apiRequest } = useApiService()

  const authAPI = (props: BaseProps<AuthParams, AuthData, AuthOptions>) =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return { authAPI }
}
