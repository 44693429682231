import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import { ApiPeriodDropdown } from 'src/types/api/ApiPeriodDropdown'
import useIsFlagEnabled from './useIsFlagEnabled'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

type Response = {
  data: ApiPeriodDropdown[]
}

const useFetchPeriodDropdown = (limit?: number) => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()
  const isFlagEnabled = useIsFlagEnabled()

  return useQuery<Response>({
    queryKey: ['settings-period-dropdown', limit || ''],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/period-dropdown`,
          method: 'GET'
        },
        params: {
          ...(limit && { limit })
        }
      }),
    enabled: isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management)
  })
}

export default useFetchPeriodDropdown
