import { TableColumnGroupType, TableColumnType } from 'antd'
import moment from 'moment'
import {
  convertNumberToCustomTime,
  customFormatDateTime
} from '../utils/dateUtil'

export default function UpdatesData() {
  let columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performedBy',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'oldValue',
      width: 200,
      render: (text: string, record) => {
        if (record.field === 'Run Offset Time') {
          return convertNumberToCustomTime(Number(text))
        } else if (record.field === 'Launch Date Time') {
          return customFormatDateTime(text)
        } else if (record.field === 'Active') {
          return text === 'true' ? 'Yes' : 'No'
        } else {
          const date = moment(text)
          return date.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : text
        }
      }
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'newValue',
      width: 200,
      render: (text: string, record) => {
        if (record.field === 'Run Offset Time') {
          return convertNumberToCustomTime(Number(text))
        } else if (record.field === 'Launch Date Time') {
          return customFormatDateTime(text)
        } else if (record.field === 'Active') {
          return text === 'true' ? 'Yes' : 'No'
        } else {
          const date = moment(text)
          return date.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : text
        }
      }
    }
  ]

  // ** Mock Data **
  const createRows = (numRows: number) => {
    const baseRow = {
      date: 'June 29th, 2023',
      performedBy: 'Test User',
      action: 'Update',
      field: 'Test Field',
      oldValue: '1',
      newValue: '2'
    }

    const dates = ['June 29th, 2023']
    const performedBy = ['User A', 'User B', 'User C']
    const actions = ['Update']
    const fields = ['Field A', 'Field B', 'Field C']
    const oldValues = ['Old value 1', 'Old value 2', 'Old value 3']
    const newValues = ['New value 1', 'New value 2', 'New value 3']

    return Array.from({ length: numRows }, (_, i) => {
      const randDates = dates[Math.floor(Math.random() * dates.length)]
      const randPerformedBy =
        performedBy[Math.floor(Math.random() * performedBy.length)]
      const randActions = actions[Math.floor(Math.random() * actions.length)]
      const randFields = fields[Math.floor(Math.random() * fields.length)]
      const randOldValues =
        oldValues[Math.floor(Math.random() * oldValues.length)]
      const randNewValues =
        newValues[Math.floor(Math.random() * newValues.length)]
      return {
        key: (i + 1).toString(),
        ...baseRow,
        dates: randDates,
        performedBy: randPerformedBy,
        actions: randActions,
        fields: randFields,
        oldValues: randOldValues,
        newValues: randNewValues
      }
    })
  }
  const rows = createRows(3)

  return { columns, rows }
}
