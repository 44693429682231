export const firstOptions = [
  { key: 'vendor', value: 'Vendor' },
  { key: 'subsidiary', value: 'Subsidiary' },
  { key: 'department', value: 'Department' },
  { key: 'gl_account', value: 'GLAccount' }
]

export const secondOptions = [
  { key: 'in', value: 'IN' },
  { key: 'not_in', value: 'NOT IN' }
]

export const thirdOptions = [
  { key: 'google', value: 'Google' },
  { key: 'facebook', value: 'Facebook' },
  { key: 'canary_market', value: 'Canary Market' },
  { key: 'linkedin', value: 'Linkedin' },
  { key: 'marin_software', value: 'Marin Software, Inc.' },
  { key: 'oracle', value: 'Oracle' },
  { key: 'sap', value: 'SAP Inc.' },
  { key: 'zoom', value: 'Zoom Info' }
]
