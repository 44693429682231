// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'
import MonthlyDataType from '../types/MonthlyDataType'
import StandardDataType from '../types/StandardDataType'

// ** Type Imports
interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData | TData[]
  options: TOptions
}

interface ScheduleManagerParams {
  customerId: string
  envName: string
  period?: string
}

export interface ScheduleManagerData {
  taskScheduleId?: number
  customerId?: string
  envName?: string
  runOffsetMinutes?: number
  active?: boolean
}

interface ScheduleManagerOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function useScheduleManagerService() {
  const { apiRequest } = useApiService()

  const ScheduleManagerAPI = (
    props: BaseProps<
      ScheduleManagerParams,
      MonthlyDataType | StandardDataType,
      ScheduleManagerOptions
    >
  ) => {
    return apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )
  }

  return {
    ScheduleManagerAPI
  }
}
