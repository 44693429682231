const MENU_CONFIG = {
  USER_MANAGEMENT: {
    LABEL: 'User Management',
    KEY: 'user-management',
    WEIGHT: 2,
    USERS: {
      LABEL: 'Users',
      KEY: 'users'
    },
    LOGIN_AUDIT_TRAIL: {
      LABEL: 'Login Audit Trail',
      KEY: 'login-audit-trail'
    },
    SSO: {
      LABEL: 'SSO',
      DESCRIPTION:
        'The setup below will help establish SSO leveraging SAML with the IDP provider of your choice.',
      KEY: 'sso'
    }
  },
  SCHEDULE_MANAGER: {
    LABEL: 'Schedule Manager',
    KEY: 'schedule-manager',
    WEIGHT: 3
  },
  TASK_MANAGER: {
    LABEL: 'Accrual Manager',
    KEY: 'task-manager',
    WEIGHT: 7,
    ACCRUAL_RULES: {
      LABEL: 'Accrual Rules',
      KEY: 'accrual-rules'
    }
  },
  TXN_ENABLE_FEATURES: {
    LABEL: 'Enable Features',
    LABEL_NEW: 'Company Settings',
    KEY: 'txn-enable-features',
    WEIGHT: 1
  },
  COMPANY_SETTINGS: {
    LABEL: 'Company Settings',
    DESCRIPTION:
      'Tailor your company preference and control account-wide settings',
    KEY: 'company-settings',
    WEIGHT: 1
  },
  ADD_ON_MODULE: {
    LABEL: 'Add-on Module',
    KEY: 'add-on-module',
    WEIGHT: 8,
    ANALYTICS_SETTINGS: {
      LABEL: 'Insights',
      KEY: 'accrual-analytic'
    }
  },
  ANALYTICS_SETTINGS: {
    LABEL: 'Insights',
    KEY: 'accrual-analytic'
  },
  TXN_SETTINGS: {
    LABEL: 'Transactions',
    KEY: 'transactions',
    WEIGHT: 6,
    TXN_COL_MANAGER: {
      LABEL: 'Transaction Column Manager',
      KEY: 'transaction-column-manager'
    }
  },
  INTEGRATION: {
    LABEL: 'Integrations',
    KEY: 'integrations',
    WEIGHT: 9,
    API_KEYS: {
      LABEL: 'API Keys',
      KEY: 'api-keys'
    },
    WORKDAY: {
      LABEL: 'Workday Setup',
      KEY: 'workday-setup'
    },
    LOGS: {
      LABEL: 'Logs',
      KEY: 'logs'
    }
  },
  DEFAULT_OPEN: [
    'task-manager',
    'user-management',
    'transactions',
    'integrations',
    'add-on-module'
  ],
  REVIEW_CENTER: {
    LABEL: 'Review Center',
    KEY: 'review-center',
    WEIGHT: 5
  },
  PERIOD_MANAGEMENT: {
    LABEL: 'Period Management',
    KEY: 'period-management',
    WEIGHT: 4
  }
} as const

export default MENU_CONFIG
