/** antd imports */
import { CopyOutlined } from '@ant-design/icons'
import { Input, Modal, message } from 'antd'

/** hooks imports */
import useCopyTextToClipboard from '../../../../../hooks/useCopyTextToClipboard'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'

// =================================================================
export default function TokenModal() {
  /** zustand states */
  const { apiKey, isTokenModalOpen, setIsTokenModalOpen, setApiKey } =
    useApiKeyStore()

  /** hooks */
  const { handleCopyToClipboard } = useCopyTextToClipboard(apiKey)

  /** component functions */
  const handleOnOkModal = async () => {
    handleCopyToClipboard()
    message.success('Copied')
  }

  const handleOnCancelModal = () => {
    setIsTokenModalOpen(false)
    setApiKey('')
  }

  return (
    <Modal
      data-testid='token-modal'
      data-cy='token-modal'
      title='API key successfully created'
      closable={false}
      cancelText='Close'
      okText='Copy'
      okButtonProps={{ shape: 'round', icon: <CopyOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} /> }}
      cancelButtonProps={{ shape: 'round' }}
      open={isTokenModalOpen}
      onCancel={handleOnCancelModal}
      onOk={handleOnOkModal}
      destroyOnClose
      width={900}
    >
      <Input.TextArea style={{ height: 400 }} value={apiKey} />
      <p style={{ marginTop: '20px' }}>
        <em>
          This is your unique key and it is <strong>non-recoverable</strong>. If
          you lose this API key, you will have to regenerate another key.
        </em>
      </p>
    </Modal>
  )
}
