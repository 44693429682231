// ** AntD Imports **
import { Button, Table, Tag } from 'antd'

// ** Utils **
import { groupByYear } from './utils/utils'
import { getPeriodHistory } from 'src/services/HistoryAPI'

// ** Types **
import PeriodManagementProps from './types/PeriodManagementProps'
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'
import { HistoryOutlined } from '@ant-design/icons'
import usePeriodManager from './hooks/usePeriodManager'
import { useEffect } from 'react'
import moment from 'moment'

// ============================================================================
const PeriodManagement: React.FC<PeriodManagementProps> = () => {
  const { getPeriodManager, handleClickAction, isSuccessPut } =
    usePeriodManager()

  const { data, refetch } = getPeriodManager

  // ** History columns **
  const historyColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performed_by',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'old',
      width: 200
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'new',
      width: 200
    }
  ]

  // ** Columns Array *
  const columns = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (text: number, period: Record<string, any>) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10
          }}
        >
          {period.status !== undefined && (
            <GlobalHistoryModal
              reloadData={() => {
                return getPeriodHistory(period.id!)
              }}
              modalTitle={'History'}
              overrideMainButton={
                <Button
                  icon={
                    <HistoryOutlined
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    />
                  }
                  size={'small'}
                />
              }
              columns={historyColumns}
            />
          )}{' '}
          {text}
        </div>
      ),
      onCell: (record: Record<string, any>) => ({
        'data-cy': `period-${record.period}`,
        'data-testid': `period-${record.period}`
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: number) =>
        text !== undefined && (
          <Tag
            color={text === 1 ? 'success' : 'error'}
            style={{
              width: 100,
              textAlign: 'center',
              padding: 5,
              borderRadius: '15px'
            }}
          >
            {text === 1 ? 'OPEN' : 'CLOSED'}
          </Tag>
        ),
      onCell: (record: Record<string, any>) => ({
        'data-cy': `status-${record.period}`,
        'data-testid': `status-${record.period}`
      })
    },
    {
      title: 'Actions',
      dataIndex: 'status',
      key: 'status',
      render: (text: number, record: Record<string, any>) =>
        text !== undefined && (
          <Button
            data-cy={`action-${record.period}`}
            data-testid={`action-${record.period}`}
            style={{ width: 100, textAlign: 'center' }}
            onClick={() => onClickActions(record)}
          >
            {text === 1 ? 'Close' : 'Re-Open'}
          </Button>
        )
    }
  ]

  // ** Functions **
  const onClickExpand = (expanded: any, record: Record<string, any>) => {
    //   TODO: call fetch api here...
    console.log('record', record)
  }

  const onClickActions = (record: Record<string, any>) => {
    //   On success of api, reload table
    handleClickAction({ id: record.id, status: record.status === 1 ? 0 : 1 })
  }

  useEffect(() => {
    if (isSuccessPut) refetch()
    // eslint-disable-next-line
  }, [isSuccessPut])

  return (
    <>
      <Table
        columns={columns as Record<string, any>[]}
        rowKey={(record) => `${record.period}`}
        dataSource={Object.values(groupByYear(data!))!.reverse()}
        pagination={false}
        expandable={{
          onExpand: onClickExpand,
          defaultExpandedRowKeys: [moment().year().toString()]
        }}
        scroll={{ y: '70dvh' }}
      />
    </>
  )
}

export default PeriodManagement
