import { create } from 'zustand'

// ** Types/Constants Imports

type TFlagStore = {
  flags: TFlag[]
  isFlagsLoading: boolean
  setFlags: (flags: TFlag[]) => void
  setIsFlagsLoading: (isFlagsLoading: boolean) => void
}

export type TFlag = {
  name: string
  isActive: boolean
}

const useFlagStore = create<TFlagStore>((set) => ({
  flags: [],
  isFlagsLoading: true,
  setFlags: (flags) => set({ flags }),
  setIsFlagsLoading: (isFlagsLoading) => set({ isFlagsLoading })
}))

export default useFlagStore
