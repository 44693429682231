import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

// ** Antd imports
import { Button, Divider, message, Space, Typography } from 'antd'

// ** Global store
import useSettingsStore from 'src/pages/Settings/manager/settings-store'
import useUnapprovedInvoicesStore from './UnapprovedInvoices/manager/unapproved-invoices-store'

// ** Hooks
import useSubmitUnapprovedInvoices from './UnapprovedInvoices/hooks/useSubmitUnapprovedInvoices'

// ** Custom components
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'

// ** Constants
import MENU_CONFIG from 'src/pages/Settings/constants/MENU_CONFIG'
import { historyColumns } from 'src/pages/Settings/constants/SETTINGS_CONFIG'

type Props = {
  isHistoryEnabled: boolean
}

const CompanySettingsTitle = ({ isHistoryEnabled }: Props) => {
  const { Title, Text } = Typography
  const queryClient = useQueryClient()

  /** Global store */
  const historyApi = useSettingsStore((state) => state.historyApi)
  const isEditMode = useUnapprovedInvoicesStore((state) => state.isEditMode)
  const isSavingCompanySettings = useUnapprovedInvoicesStore(
    (state) => state.isSavingCompanySettings
  )
  const invoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.invoiceStatusesValues
  )
  const newStatus = useUnapprovedInvoicesStore((state) => state.newStatus)
  const setIsDirty = useUnapprovedInvoicesStore((state) => state.setIsDirty)
  const setIsEditMode = useUnapprovedInvoicesStore(
    (state) => state.setIsEditMode
  )
  const setIsSavingCompanySettings = useUnapprovedInvoicesStore(
    (state) => state.setIsSavingCompanySettings
  )
  const setNewStatus = useUnapprovedInvoicesStore((state) => state.setNewStatus)

  /** Local states */

  /** React queries */
  const {
    mutate: submitUnapprovedInvoices,
    data: dataSubmitUnapprovedInvoices,
    error: errorSubmitUnapprovedInvoices,
    isLoading: isLoadingSubmitUnapprovedInvoices,
    isSuccess: isSuccessSubmitUnapprovedInvoices,
    isError: isErrorSubmitUnapprovedInvoices
  } = useSubmitUnapprovedInvoices()

  /** Helpers */
  const handleSave = () => {
    /**
     * ! EDGE CASE CONDITION
     * If newStatus is not yet added to the Invoice Statuses list, meaning it has duplicate entry
     */
    if (newStatus) {
      if (newStatus.trim() === '') {
        // ? This prevents empty status to be added
        message.error('Please provide a valid status.')
      } else {
        // ? This prevents duplicate status to be added
        message.error('Please provide a unique status.')
      }
      setNewStatus('')
      return
    }

    // Insert save logic here
    const payload = {
      invoice_statuses: invoiceStatusesValues,

      /**
       * ? Dev notes:
       * ? Below payloads are just placeholders which are required by the current API endpoint
       * ? Will be updated on AC-2334
       */
      display_unrecorded_invoices_on_accrual_form: false,
      unrecorded_invoices_impact_on_straightline_calculation: {
        label: 'Sample only',
        payload: 'sample_only'
      }
    }

    submitUnapprovedInvoices(payload)
  }

  const handleEdit = () => {
    setIsEditMode(true)
  }

  /** Use effects */
  useEffect(() => {
    // Global state when saving company settings
    setIsSavingCompanySettings(isLoadingSubmitUnapprovedInvoices)

    // Success handling
    if (isSuccessSubmitUnapprovedInvoices && dataSubmitUnapprovedInvoices) {
      message.success('Company settings saved successfully.')

      // Re-fetch company settings data
      queryClient.invalidateQueries({ queryKey: ['get-unapproved-invoices'] })

      // Switch to view only mode after saving
      setIsEditMode(false)

      // Reset dirty state
      setIsDirty(false)

      // Reset new status
      setNewStatus('')
    }

    // Error handling
    if (isErrorSubmitUnapprovedInvoices) {
      let errorMsg = ''

      const errors = errorSubmitUnapprovedInvoices?.data?.errors
      if (errors) {
        // Get the exact error message if available
        // Can only display the first error
        errorMsg = Object.values(errors)[0][0]
      } else {
        // Else, display a generic error message
        errorMsg = 'Failed to update company settings. Please try again.'
      }

      message.error(errorMsg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubmitUnapprovedInvoices])

  return (
    <>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-testid='selected-settings-title'
      >
        <Title level={2} style={{ marginBottom: 0 }}>
          {MENU_CONFIG.COMPANY_SETTINGS.LABEL}
        </Title>
        <div style={{ display: 'flex', gap: '8px' }}>
          {isHistoryEnabled && (
            <GlobalHistoryModal
              modalTitle='Audit Trail'
              overrideMainButton={<Button>Audit Trail</Button>}
              columns={historyColumns}
              reloadData={historyApi}
            />
          )}

          <>
            {isEditMode ? (
              <Button
                type='primary'
                onClick={handleSave}
                disabled={isSavingCompanySettings}
                data-testid='unrecorded-invoices-btn-save'
                data-cy='unrecorded-btn-save'
              >
                Save
              </Button>
            ) : (
              <Button
                type='primary'
                onClick={handleEdit}
                data-testid='unrecorded-invoices-btn-edit'
                data-cy='unrecorded-btn-edit'
              >
                Edit
              </Button>
            )}
          </>
        </div>
      </Space>
      <Text>{MENU_CONFIG.COMPANY_SETTINGS.DESCRIPTION}</Text>
      <Divider />
    </>
  )
}

export default CompanySettingsTitle
