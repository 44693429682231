/** react router imports */
import { useNavigate } from 'react-router-dom'

/** 3rd party imports */
import moment from 'moment'

/** Zustand imports */
import useLoginStore from '../manager/useLoginStore'
import { useGlobalStore } from '../../../global/global-store'

/** Constants imports */
import ActionPermissions from '../../../helpers/constants/ActionPermissions'
import {
  AUTH_TOKEN,
  EXPIRATION,
  EXPIRES_ON,
  EXPIRY_COUNTER,
  IDLE_TIMER,
  PENDO_METADATA,
  ROLES_AND_PERMISSIONS
} from '../../../helpers/constants/Auth'

/** helpers imports */
import { pendoInitialize } from '../helpers/pendo-utils'

/** Types imports */
import type DecodedJWTProps from '../types/DecodedJWTProps'
import type LoginResponseDataProps from '../types/LoginResponseDataProps'

/** Constants */
const TO_SECONDS = 60

// =================================================================
export default function useLogin() {
  /** react router */
  const navigate = useNavigate()

  /** zustand store */
  const { setLoginErrorMessage } = useLoginStore()
  const { setIsPasswordExpired, setPasswordResetToken } = useGlobalStore()

  const checkPermission = (
    decodedJwt: DecodedJWTProps,
    permissionId: number
  ): boolean => {
    const permissions = decodedJwt && decodedJwt.action_permissions

    return permissions && permissions.includes(permissionId)
  }

  const setLocalStorageData = (
    data: LoginResponseDataProps,
    decodedJwt: DecodedJWTProps
  ) => {
    generatePendoMetadata(decodedJwt)
    const expiryTime = (Number(data.expires_in) * TO_SECONDS).toString()

    localStorage.setItem(AUTH_TOKEN, data.access_token)
    localStorage.setItem(EXPIRATION, data.expires_in.toString())
    localStorage.setItem(EXPIRY_COUNTER, expiryTime)
    localStorage.setItem(EXPIRES_ON, data.expires_on)
    localStorage.setItem(IDLE_TIMER, expiryTime)
    localStorage.setItem(ROLES_AND_PERMISSIONS, JSON.stringify(decodedJwt))
  }

  const generatePendoMetadata = (decodedJwt: DecodedJWTProps) => {
    const pendoMetadata = {
      visitor: {
        id: decodedJwt.user_id,
        email: decodedJwt.profile.email,

        // decodeJwt.profile
        first_name: decodedJwt?.profile?.first_name || '',
        last_name: decodedJwt?.profile?.last_name || '',
        middle_name: decodedJwt?.profile?.middle_name || '',

        // decodeJwt.roles
        roles: decodedJwt?.roles || []
      },
      account: {
        id: decodedJwt.company_id,

        // decodeJwt.profile
        first_name: decodedJwt?.profile?.first_name || '',
        last_name: decodedJwt?.profile?.last_name || '',
        middle_name: decodedJwt?.profile?.middle_name || '',

        // decodeJwt.roles
        roles: decodedJwt?.roles || []
      }
    }

    pendoInitialize(pendoMetadata)

    localStorage.setItem(PENDO_METADATA, JSON.stringify(pendoMetadata))
  }

  const isAuthTokenValid = (): boolean => {
    const expiresOn = localStorage.getItem(EXPIRES_ON)
    const authToken = localStorage.getItem(AUTH_TOKEN)

    return (
      authToken !== null && expiresOn !== null && moment(expiresOn) > moment()
    )
  }

  const handleError = (err: any, isLoginCredential: boolean = true) => {
    if (err && err.data.error) {
      if (err.data.error.password) {
        let pwError = err.data.error.password
        setLoginErrorMessage(pwError[0])
      } else {
        setLoginErrorMessage(err.data.error)
        isLoginCredential && setIsPasswordExpired(err.data.redirect)
        isLoginCredential && setPasswordResetToken(err.data.token)
      }
    }
  }

  const redirectPage = async (decodedJwt: DecodedJWTProps) => {
    switch (true) {
      // check if user has review center permission
      case checkPermission(
        decodedJwt,
        ActionPermissions.REVIEW_CENTER_DATA_REVIEW_CENTER
      ) &&
        checkPermission(decodedJwt, ActionPermissions.REVIEW_CENTER_GET_FILTER):
        navigate('/review-center')
        break
      // check if user has accrual manager permission
      case checkPermission(decodedJwt, ActionPermissions.VENDOR_TASKS):
        navigate('/task-manager')
        break
      // check if user has transaction permission
      case checkPermission(decodedJwt, ActionPermissions.VIEW_TRANSACTION):
        navigate('/transactions')
        break
      // check if user has qje permission
      case checkPermission(decodedJwt, ActionPermissions.QJE_VIEW_TRANSACTIONS):
        navigate('/qje')
        break
      // check if user has analytics permission
      case checkPermission(decodedJwt, ActionPermissions.GLOBAL_AA):
        navigate('/insights')
        break
      default:
        localStorage.clear()
        navigate('/error?message=Forbidden&status=403')
        break
    }
  }

  const onFinishFailed = (errorInfo: string | object) => {
    console.log('Failed:', errorInfo)
  }

  return {
    generatePendoMetadata,
    setLocalStorageData,
    redirectPage,
    handleError,
    isAuthTokenValid,
    onFinishFailed
  }
}
