export const column = [
  // {
  //   title: 'Exam Details',
  //   children: [
  //     {
  //       id: 737,
  //       title: 'Override File',
  //       dataIndex: 'override',
  //       data_type: 'varchar',
  //       width: 200
  //     },
  //     {
  //       id: 'cc_email',
  //       title: 'CC Email',
  //       dataIndex: 'cc_email',
  //       data_type: 'varchar',
  //       is_sortable: true
  //     },
  //     {
  //       id: 'cc_email',
  //       title: 'Status',
  //       dataIndex: 'cc_email',
  //       data_type: 'varchar'
  //     }
  //   ]
  // },

  {
    id: 1,
    label: 'Vendor',
    api_name: 'name',
    data_type: 'varchar',
    field_type: 'input',
    is_editable: true,
    is_sortable: true,
    has_tooltip: true
  },
  {
    id: 2,
    label: 'Unbilled Amount',
    api_name: 'unAmount',
    data_type: 'number',
    has_header_tooltip: true
  },
  {
    id: 3,
    label: 'Description',
    api_name: 'desc',
    data_type: 'varchar',
    has_tooltip: true
  },
  {
    id: 4,
    label: 'Currency',
    api_name: 'curr',
    data_type: 'varchar'
  },
  {
    id: 5,
    label: 'Accrual Type',
    api_name: 'accrual_type',
    data_type: 'varchar',
    sorter: (a, b) => a.accrual_type.localeCompare(b.accrual_type)
  },
  {
    title: 'Accrual Source',
    dataIndex: 'acrual_source',
    key: 6,
    width: 150,
    render: (item) => <div> {item}</div>,
    is_sortable: true
  },
  {
    id: 7,
    label: 'Date Sent',
    api_name: 'dateSent',
    data_type: 'varchar'
  }
]

export const data = [
  {
    id: 1,
    name: ' IT Assoc Inc.',
    unAmount: 2300,
    desc: 'Audit Fee',
    curr: 'USD',
    accrual_type: 'Vendor',
    acrual_source: 'Unbilled',
    dateSent: '2020-07-07'
  },
  {
    id: 2,
    name: ' Avarra Inc.',
    unAmount: 23300,
    desc: 'Legal Fee',
    curr: 'EUR',
    accrual_type: 'Straightline',
    acrual_source: 'Calculated',
    dateSent: '2020-07-07'
  },
  {
    id: 3,
    name: 'KD Corp.',
    unAmount: 6500,
    desc: 'Services expense',
    curr: 'USD',
    accrual_type: 'Internal',
    acrual_source: 'Unbilled',
    dateSent: '2020-07-07'
  }
]

export const transColumns = [
  {
    id: 1,
    label: 'Form Name',
    api_name: 'form_name',
    data_type: 'varchar',
    field_type: 'short_text',
    has_tooltip: false,
    help_text: '',
    is_editable: true,
    is_sortable: true
  },
  {
    id: 2,
    label: 'CC Email',
    api_name: 'g_cc_email',
    data_type: 'varchar',
    field_type: 'dropdown',
    field_option_source: 'GLAccount',
    has_tooltip: false,
    help_text: '',
    is_editable: true,
    is_sortable: true,
    has_header_tooltip: true
  },
  {
    id: 7,
    label: 'Close PO',
    api_name: 'g_close_po',
    data_type: 'varchar',
    field_type: 'checkbox',
    help_text: '',
    is_editable: true,
    is_sortable: true,
    has_tooltip: true
  },
  {
    id: 3,
    label: 'Invoiced Amount',
    api_name: 'g_invoiced_amount',
    data_type: 'number',
    field_type: 'number',
    help_text: '',
    is_editable: true,
    is_sortable: true,
    has_tooltip: true
  },
  {
    id: 8,
    label: 'Due Date',
    api_name: 'g_due_date',
    data_type: 'varchar',
    field_type: 'datepicker',
    help_text: '',
    is_editable: true,
    is_sortable: true,
    has_tooltip: true
  },
  {
    id: 4,
    label: 'PO Number',
    api_name: 'g_po_number',
    data_type: 'varchar',
    is_editable: true,
    field_type: 'dropdown',
    field_options: [
      { id: 1, value: 'Sample 1' },
      { id: 2, value: 'Sample 2' }
    ],
    help_text: '',
    is_sortable: true,
    has_tooltip: true
  },
  {
    id: 5,
    label: 'PO Line Description',
    api_name: 'g_po_line_description',
    data_type: 'varchar',
    field_type: 'short_text',
    is_editable: true,
    is_sortable: true,
    has_tooltip: true,
    help_text: ''
  },
  {
    id: 6,
    label: 'Acrual Type',
    api_name: 'g_accrual_type',
    data_type: 'varchar',
    field_type: 'short_text',
    has_tooltip: false,
    help_text: '',
    is_editable: false,
    is_sortable: true
  }
]

export const transData = [
  {
    id: 5583,
    g_accrual_type: 'Unbilled',
    g_source_system_id: '14-POC DryRun #2',
    g_vendor_id: '54321',
    g_created_at: '2022-07-07 22:14:55',
    g_created_by: '803',
    g_updated_at: '2022-07-10 18:21:41',
    g_last_modified_by: '803',
    g_name: 'PoC Dryrun #2 - 07082022',
    g_email: 'irish@gappify.com',
    g_subsidiary: '110 - Gappify Inc.',
    g_subsidiary_id: '2',
    g_product_debit: '110 - Gappify Inc.',
    g_product_debit_id: '2',
    g_product_credit: null,
    g_product_credit_id: null,
    g_glaccount_debit:
      '660025 Rent & Facilities Operating Exp : Facilities - Repairs and Maintenance',
    g_glaccount_debit_id: '1579',
    g_glaccount_credit: null,
    g_glaccount_credit_id: null,
    g_department_debit: '5465 - FAC San Mateo',
    g_department_debit_id: '134',
    g_department_credit: null,
    g_department_credit_id: null,
    g_location_debit: 'San Mateo',
    g_location_debit_id: '20',
    g_location_credit: null,
    g_location_credit_id: null,
    g_class_debit: '60 - Shared',
    g_class_debit_id: '14',
    g_class_credit: null,
    g_class_credit_id: null,
    g_period: '2022-07-01',
    g_type: null,
    g_transaction_group: 'Internal Accruals',
    g_subsidiary_currency: 'USD',
    g_subsidiary_currency_id: '1',
    g_transaction_currency: 'USD',
    task_id: 14,
    transaction_id: 113,
    taskinactive: null,
    workflow_id: 26,
    token_id: 8104,
    message_id: '4ded8eeb87192e3deed0a9fceb6ddf65@swift.generated',
    g_date_submitted: '2022-07-07 22:34:30',
    g_due_date: '2022-07-09',
    g_line_source: 1,
    weight: null,
    group_name: 'b6589fc6ab0dc82cf12099d1c2d40ab994e8410c',
    form_name: 'PO Accruals',
    internal_id: null,
    g_nocharges: null,
    passback_flag: 0,
    override_reason: null,
    g_cc_email: 'irish@gappify.com',
    g_custom_notification: null,
    g_custom_message: null,
    g_line_type: 'line',
    g_status: '3',
    g_qje_credit_id: null,
    g_qje_debit_id: null,
    g_accrual_amount: '3000.00',
    g_accrual_description: 'test4',
    g_unique_key: 'Line-14-2022-07-01-62c7bd4fe7d8f',
    g_subsidiary_amount: '3000.00',
    g_exchange_rate: null,
    hash: '62c7bd4fe7d82',
    parent_hash: null,
    g_transaction_currency_id: '1',
    g_po_id: null,
    g_po_number: '1004923-1',
    g_po_header_description: null,
    g_po_line_id: null,
    g_po_line_description:
      'Landscaping services for the San Mateo rooftop patio',
    g_po_line_amount: '600000.00',
    g_po_header_amount: null,
    g_po_line_status: null,
    g_po_owner: null,
    g_service_end_date: '2022-08-30',
    g_service_start_date: '2022-04-01',
    g_invoiced_amount: '0.00',
    g_po_balance: '5000.00',
    unique_line_identifier:
      '14-POC DryRun #2-line-113-2022-07-01-14--1657258471.0523-158940240',
    g_vendor_task_id: 14,
    g_external_accrual: '1',
    g_internal_accrual: '2',
    g_calculated_accruals: '0',
    g_internal_cc_email: 'irish@gappify.com',
    g_reviewed: 1,
    g_close_po: 'Yes',
    g_po_closed: null,
    g_calculation: '2',
    qje_messages: null,
    g_review_comments: null,
    g_region_debit: '110 - Gappify Inc.',
    g_region_debit_id: '2',
    g_region_credit: null,
    g_region_credit_id: null,
    g_project_debit: '110 - Gappify Inc.',
    g_project_debit_id: '2',
    g_project_credit: null,
    g_project_credit_id: null,
    g_override_flag: null,
    g_po_line_amount_cancelled: '5000.00',
    g_company_debit: '610100 Travel Expense : Airfare',
    g_company_debit_id: '204',
    g_company_credit: null,
    g_company_credit_id: null,
    g_intercompany_debit: '501 - Stars Company',
    g_intercompany_debit_id: '500',
    g_intercompany_credit: null,
    g_intercompany_credit_id: null,
    g_channel_debit: '110 - Gappify Inc.',
    g_channel_debit_id: '2',
    g_channel_credit: null,
    g_channel_credit_id: null,
    g_threshold_support_document: null,
    g_reporting_currency: 'USD',
    g_reporting_amount: '3000.00',
    g_reporting_currency_id: null,
    g_under_threshold: 'false',
    auto_nudge_count: null,
    error_message: null,
    g_override_value: null,
    deleted_at: null
  },
  {
    id: 5582,
    g_accrual_type: 'Percent',
    g_source_system_id: '13-POC DryRun #2',
    g_vendor_id: null,
    g_created_at: '2022-07-07 22:14:55',
    g_created_by: '803',
    g_updated_at: '2022-07-10 18:21:41',
    g_last_modified_by: '803',
    g_name: 'ELI-SRC_VNDR16077',
    g_email: 'irish@gappify.com',
    g_subsidiary: null,
    g_subsidiary_id: '2',
    g_product_debit: '110 - Gappify Inc.',
    g_product_debit_id: '2',
    g_product_credit: null,
    g_product_credit_id: null,
    g_glaccount_debit:
      '660025 Rent & Facilities Operating Exp : Facilities - Repairs and Maintenance',
    g_glaccount_debit_id: '1579',
    g_glaccount_credit: null,
    g_glaccount_credit_id: null,
    g_department_debit: '5465 - FAC San Mateo',
    g_department_debit_id: '134',
    g_department_credit: null,
    g_department_credit_id: null,
    g_location_debit: 'San Mateo',
    g_location_debit_id: '20',
    g_location_credit: null,
    g_location_credit_id: null,
    g_class_debit: '60 - Shared',
    g_class_debit_id: '14',
    g_class_credit: null,
    g_class_credit_id: null,
    g_period: '2022-07-01',
    g_type: null,
    g_transaction_group: 'Internal Accruals',
    g_subsidiary_currency: 'USD',
    g_subsidiary_currency_id: '1',
    g_transaction_currency: 'USD',
    task_id: 13,
    transaction_id: 113,
    taskinactive: null,
    workflow_id: 26,
    token_id: 8103,
    message_id: '81a916269c04ec2748421794f6bad2e4@swift.generated',
    g_date_submitted: '2022-07-07 22:34:30',
    g_due_date: '2022-07-09',
    g_line_source: 1,
    weight: null,
    group_name: 'b6589fc6ab0dc82cf12099d1c2d40ab994e8410c',
    form_name: 'PO Accruals',
    internal_id: null,
    g_nocharges: null,
    passback_flag: null,
    override_reason: null,
    g_cc_email: 'irish@gappify.com',
    g_custom_notification: null,
    g_custom_message: null,
    g_line_type: 'line',
    g_status: '9',
    g_qje_credit_id: 249,
    g_qje_debit_id: 248,
    g_accrual_amount: '54321.00',
    g_accrual_description: null,
    g_unique_key: 'Line-13-2022-07-01-62c7bd4fddec1',
    g_subsidiary_amount: '54321.00',
    g_exchange_rate: null,
    hash: '62c7bd4fddeb5',
    parent_hash: null,
    g_transaction_currency_id: '1',
    g_po_id: null,
    g_po_number: '1004923-1',
    g_po_header_description: null,
    g_po_line_id: null,
    g_po_line_description:
      'Landscaping services for the San Mateo rooftop patio',
    g_po_line_amount: '800000.00',
    g_po_header_amount: null,
    g_po_line_status: null,
    g_po_owner: null,
    g_service_end_date: '2022-08-30',
    g_service_start_date: '2022-04-01',
    g_invoiced_amount: '0.00',
    g_po_balance: '5000.00',
    unique_line_identifier:
      '13-POC DryRun #2-line-113-2022-07-01-13--1657258471.0521-28169327',
    g_vendor_task_id: 13,
    g_external_accrual: '1',
    g_internal_accrual: '3',
    g_calculated_accruals: '0',
    g_internal_cc_email: 'irish@gappify.com',
    g_reviewed: 1,
    g_close_po: 'Yes',
    g_po_closed: null,
    g_calculation: '2',
    qje_messages: null,
    g_review_comments: null,
    g_region_debit: '110 - Gappify Inc.',
    g_region_debit_id: '2',
    g_region_credit: null,
    g_region_credit_id: null,
    g_project_debit: '110 - Gappify Inc.',
    g_project_debit_id: '2',
    g_project_credit: null,
    g_project_credit_id: null,
    g_override_flag: '1',
    g_po_line_amount_cancelled: null,
    g_company_debit: '610100 Travel Expense : Airfare',
    g_company_debit_id: '204',
    g_company_credit: null,
    g_company_credit_id: null,
    g_intercompany_debit: '501 - Stars Company',
    g_intercompany_debit_id: '500',
    g_intercompany_credit: null,
    g_intercompany_credit_id: null,
    g_channel_debit: '110 - Gappify Inc.',
    g_channel_debit_id: '2',
    g_channel_credit: null,
    g_channel_credit_id: null,
    g_threshold_support_document: null,
    g_reporting_currency: 'USD',
    g_reporting_amount: '54321.00',
    g_reporting_currency_id: null,
    g_under_threshold: 'false',
    auto_nudge_count: null,
    error_message: '["PO Line Amount Cancelled field is required"]',
    g_override_value: '54321.00',
    deleted_at: null
  },
  {
    id: 5581,
    g_accrual_type: 'Unbilled',
    g_source_system_id: '12-POC DryRun #2',
    g_vendor_id: '54321',
    g_created_at: '2022-07-07 22:14:55',
    g_created_by: '803',
    g_updated_at: '2022-07-10 18:21:41',
    g_last_modified_by: '803',
    g_name: 'PoC Dryrun #2 - 07082022',
    g_email: 'irish@gappify.com',
    g_subsidiary: '110 - Gappify Inc.',
    g_subsidiary_id: '2',
    g_product_debit: '110 - Gappify Inc.',
    g_product_debit_id: '2',
    g_product_credit: null,
    g_product_credit_id: null,
    g_glaccount_debit:
      '660025 Rent & Facilities Operating Exp : Facilities - Repairs and Maintenance',
    g_glaccount_debit_id: '1579',
    g_glaccount_credit: null,
    g_glaccount_credit_id: null,
    g_department_debit: '5465 - FAC San Mateo',
    g_department_debit_id: '134',
    g_department_credit: null,
    g_department_credit_id: null,
    g_location_debit: 'San Mateo',
    g_location_debit_id: '20',
    g_location_credit: null,
    g_location_credit_id: null,
    g_class_debit: '60 - Shared',
    g_class_debit_id: '14',
    g_class_credit: null,
    g_class_credit_id: null,
    g_period: '2022-07-01',
    g_type: null,
    g_transaction_group: 'Internal Accruals',
    g_subsidiary_currency: 'USD',
    g_subsidiary_currency_id: '1',
    g_transaction_currency: 'USD',
    task_id: 12,
    transaction_id: 113,
    taskinactive: null,
    workflow_id: 26,
    token_id: 8102,
    message_id: null,
    g_date_submitted: '2022-07-07 22:34:30',
    g_due_date: '2022-07-09',
    g_line_source: 1,
    weight: null,
    group_name: 'b6589fc6ab0dc82cf12099d1c2d40ab994e8410c',
    form_name: 'PO Accruals',
    internal_id: null,
    g_nocharges: null,
    passback_flag: 0,
    override_reason: null,
    g_cc_email: 'irish@gappify.com',
    g_custom_notification: null,
    g_custom_message: null,
    g_line_type: 'line',
    g_status: '3',
    g_qje_credit_id: null,
    g_qje_debit_id: null,
    g_accrual_amount: '2000.00',
    g_accrual_description: 'test2',
    g_unique_key: 'Line-12-2022-07-01-62c7bd4fd51a3',
    g_subsidiary_amount: '2000.00',
    g_exchange_rate: null,
    hash: '62c7bd4fd5191',
    parent_hash: null,
    g_transaction_currency_id: '1',
    g_po_id: null,
    g_po_number: '1004923-1',
    g_po_header_description: null,
    g_po_line_id: null,
    g_po_line_description:
      'Landscaping services for the San Mateo rooftop patio',
    g_po_line_amount: '600000.00',
    g_po_header_amount: null,
    g_po_line_status: null,
    g_po_owner: null,
    g_service_end_date: '2022-08-30',
    g_service_start_date: '2022-04-01',
    g_invoiced_amount: '0.00',
    g_po_balance: '5000.00',
    unique_line_identifier:
      '12-POC DryRun #2-line-113-2022-07-01-12--1657258471.0522-761063874',
    g_vendor_task_id: 12,
    g_external_accrual: '1',
    g_internal_accrual: '2',
    g_calculated_accruals: '0',
    g_internal_cc_email: 'irish@gappify.com',
    g_reviewed: 1,
    g_close_po: 'Yes',
    g_po_closed: null,
    g_calculation: '2',
    qje_messages: null,
    g_review_comments: null,
    g_region_debit: '110 - Gappify Inc.',
    g_region_debit_id: '2',
    g_region_credit: null,
    g_region_credit_id: null,
    g_project_debit: '110 - Gappify Inc.',
    g_project_debit_id: '2',
    g_project_credit: null,
    g_project_credit_id: null,
    g_override_flag: null,
    g_po_line_amount_cancelled: '5000.00',
    g_company_debit: '610100 Travel Expense : Airfare',
    g_company_debit_id: '204',
    g_company_credit: null,
    g_company_credit_id: null,
    g_intercompany_debit: '501 - Stars Company',
    g_intercompany_debit_id: '500',
    g_intercompany_credit: null,
    g_intercompany_credit_id: null,
    g_channel_debit: '110 - Gappify Inc.',
    g_channel_debit_id: '2',
    g_channel_credit: null,
    g_channel_credit_id: null,
    g_threshold_support_document: null,
    g_reporting_currency: 'USD',
    g_reporting_amount: '2000.00',
    g_reporting_currency_id: null,
    g_under_threshold: 'false',
    auto_nudge_count: null,
    error_message: null,
    g_override_value: null,
    deleted_at: null
  },
  {
    id: 5580,
    g_accrual_type: 'Percent',
    g_source_system_id: '11-POC DryRun #2',
    g_vendor_id: null,
    g_created_at: '2022-07-07 22:14:55',
    g_created_by: '803',
    g_updated_at: '2022-07-10 18:21:41',
    g_last_modified_by: '803',
    g_name: 'ELI-SRC_VNDR16077',
    g_email: 'irish@gappify.com',
    g_subsidiary: null,
    g_subsidiary_id: '2',
    g_product_debit: '110 - Gappify Inc.',
    g_product_debit_id: '2',
    g_product_credit: null,
    g_product_credit_id: null,
    g_glaccount_debit:
      '660025 Rent & Facilities Operating Exp : Facilities - Repairs and Maintenance',
    g_glaccount_debit_id: '1579',
    g_glaccount_credit: null,
    g_glaccount_credit_id: null,
    g_department_debit: '5465 - FAC San Mateo',
    g_department_debit_id: '134',
    g_department_credit: null,
    g_department_credit_id: null,
    g_location_debit: 'San Mateo',
    g_location_debit_id: '20',
    g_location_credit: null,
    g_location_credit_id: null,
    g_class_debit: '60 - Shared',
    g_class_debit_id: '14',
    g_class_credit: null,
    g_class_credit_id: null,
    g_period: '2022-07-01',
    g_type: null,
    g_transaction_group: 'Internal Accruals',
    g_subsidiary_currency: 'USD',
    g_subsidiary_currency_id: '1',
    g_transaction_currency: 'USD',
    task_id: 11,
    transaction_id: 113,
    taskinactive: null,
    workflow_id: 26,
    token_id: 8101,
    message_id: null,
    g_date_submitted: '2022-07-07 22:34:30',
    g_due_date: '2022-07-09',
    g_line_source: 1,
    weight: null,
    group_name: 'b6589fc6ab0dc82cf12099d1c2d40ab994e8410c',
    form_name: 'PO Accruals',
    internal_id: null,
    g_nocharges: null,
    passback_flag: null,
    override_reason: null,
    g_cc_email: 'irish@gappify.com',
    g_custom_notification: null,
    g_custom_message: null,
    g_line_type: 'line',
    g_status: '3',
    g_qje_credit_id: null,
    g_qje_debit_id: null,
    g_accrual_amount: '297760.00',
    g_accrual_description: null,
    g_unique_key: 'Line-11-2022-07-01-62c7bd4fc5b26',
    g_subsidiary_amount: '297760.00',
    g_exchange_rate: null,
    hash: '62c7bd4fc5b16',
    parent_hash: null,
    g_transaction_currency_id: '1',
    g_po_id: null,
    g_po_number: '1004923-1',
    g_po_header_description: null,
    g_po_line_id: null,
    g_po_line_description:
      'Landscaping services for the San Mateo rooftop patio',
    g_po_line_amount: '600000.00',
    g_po_header_amount: null,
    g_po_line_status: null,
    g_po_owner: null,
    g_service_end_date: '2022-08-30',
    g_service_start_date: '2022-04-01',
    g_invoiced_amount: null,
    g_po_balance: '5000.00',
    unique_line_identifier:
      '11-POC DryRun #2-line-113-2022-07-01-11--1657258471.0522-379264537',
    g_vendor_task_id: 11,
    g_external_accrual: '3',
    g_internal_accrual: '3',
    g_calculated_accruals: '0',
    g_internal_cc_email: 'irish@gappify.com',
    g_reviewed: 1,
    g_close_po: 'Yes',
    g_po_closed: null,
    g_calculation: '2',
    qje_messages: null,
    g_review_comments: null,
    g_region_debit: '110 - Gappify Inc.',
    g_region_debit_id: '2',
    g_region_credit: null,
    g_region_credit_id: null,
    g_project_debit: '110 - Gappify Inc.',
    g_project_debit_id: '2',
    g_project_credit: null,
    g_project_credit_id: null,
    g_override_flag: '1',
    g_po_line_amount_cancelled: '5000.00',
    g_company_debit: '610100 Travel Expense : Airfare',
    g_company_debit_id: '204',
    g_company_credit: null,
    g_company_credit_id: null,
    g_intercompany_debit: '501 - Stars Company',
    g_intercompany_debit_id: '500',
    g_intercompany_credit: null,
    g_intercompany_credit_id: null,
    g_channel_debit: '110 - Gappify Inc.',
    g_channel_debit_id: '2',
    g_channel_credit: null,
    g_channel_credit_id: null,
    g_threshold_support_document: null,
    g_reporting_currency: 'USD',
    g_reporting_amount: '297760.00',
    g_reporting_currency_id: null,
    g_under_threshold: 'false',
    auto_nudge_count: null,
    error_message: null,
    g_override_value: '297760.00',
    deleted_at: null
  },
  {
    id: 5536,
    g_accrual_type: null,
    g_source_system_id: '2525',
    g_vendor_id: '300000024359028',
    g_created_at: '2022-06-15 00:15:22',
    g_created_by: '803',
    g_updated_at: '2022-06-15 00:15:22',
    g_last_modified_by: '803',
    g_name: 'Coordinated Resources Inc of San Francisco',
    g_email: 'rahul+020@gappify.com',
    g_subsidiary: 'US Primary Ledger',
    g_subsidiary_id: '300000001209009',
    g_product_debit: '0',
    g_product_debit_id: '50065',
    g_product_credit: '0',
    g_product_credit_id: '50065',
    g_glaccount_debit: '121310 - Asset Clearing - Capitalized',
    g_glaccount_debit_id: '50205',
    g_glaccount_credit: '212240 - Accrued expense',
    g_glaccount_credit_id: '50603',
    g_department_debit: '6620 - Workplace Services',
    g_department_debit_id: '50916',
    g_department_credit: '0 - Balance Sheet',
    g_department_credit_id: '50063',
    g_location_debit: null,
    g_location_debit_id: null,
    g_location_credit: null,
    g_location_credit_id: null,
    g_class_debit: null,
    g_class_debit_id: null,
    g_class_credit: null,
    g_class_credit_id: null,
    g_period: '2022-06-01',
    g_type: null,
    g_transaction_group: 'Accruals',
    g_subsidiary_currency: 'USD',
    g_subsidiary_currency_id: '39394000122',
    g_transaction_currency: 'USD',
    task_id: 1026,
    transaction_id: 101,
    taskinactive: 0,
    workflow_id: 26,
    token_id: 8032,
    message_id: null,
    g_date_submitted: null,
    g_due_date: '2022-06-17',
    g_line_source: 1,
    weight: null,
    group_name: 'a2c59ee30625d620230ed63c3618606d97eb147d',
    form_name: 'PO Accruals',
    internal_id: null,
    g_nocharges: null,
    passback_flag: null,
    override_reason: null,
    g_cc_email: null,
    g_custom_notification: null,
    g_custom_message: null,
    g_line_type: 'line',
    g_status: '1',
    g_qje_credit_id: null,
    g_qje_debit_id: null,
    g_accrual_amount: null,
    g_accrual_description: null,
    g_unique_key: 'Line-1026-2022-02-01-1655277319',
    g_subsidiary_amount: null,
    g_exchange_rate: null,
    hash: '62a9870ad45b9',
    parent_hash: null,
    g_transaction_currency_id: null,
    g_po_id: '300000023876162',
    g_po_number: 'USPO943',
    g_po_header_description: null,
    g_po_line_id: '300000498405377',
    g_po_line_description:
      'Oyster Point furniture - B1F2 ancillary (service fee)',
    g_po_line_amount: '8034.24',
    g_po_header_amount: null,
    g_po_line_status: 'CLOSED FOR RECEIVING',
    g_po_owner: 'Rahul',
    g_service_end_date: '2022-09-09',
    g_service_start_date: '2021-07-21',
    g_invoiced_amount: '4017.12',
    g_po_balance: '4017.12',
    unique_line_identifier:
      '2525-line-101-2022-06-01-1026-300000498405377-1655277322.8698',
    g_vendor_task_id: null,
    g_external_accrual: '0',
    g_internal_accrual: '0',
    g_calculated_accruals: '0',
    g_internal_cc_email: '0',
    g_reviewed: 0,
    g_close_po: null,
    g_po_closed: null,
    g_calculation: '0',
    qje_messages: null,
    g_review_comments: null,
    g_region_debit: null,
    g_region_debit_id: null,
    g_region_credit: null,
    g_region_credit_id: null,
    g_project_debit: null,
    g_project_debit_id: null,
    g_project_credit: null,
    g_project_credit_id: null,
    g_override_flag: null,
    g_po_line_amount_cancelled: null,
    g_company_debit: null,
    g_company_debit_id: null,
    g_company_credit: null,
    g_company_credit_id: null,
    g_intercompany_debit: null,
    g_intercompany_debit_id: null,
    g_intercompany_credit: null,
    g_intercompany_credit_id: null,
    g_channel_debit: null,
    g_channel_debit_id: null,
    g_channel_credit: null,
    g_channel_credit_id: null,
    g_threshold_support_document: null,
    g_reporting_currency: 'USD',
    g_reporting_amount: null,
    g_reporting_currency_id: null,
    g_under_threshold: 'false',
    auto_nudge_count: 0,
    error_message: null,
    g_override_value: null,
    deleted_at: null
  }
]

export const parent_columns = [
  {
    id: 1,
    label: 'Form Name',
    api_name: 'form_name',
    data_type: 'varchar',
    is_sortable: true
  },
  {
    id: 2,
    label: 'Transaction Id',
    api_name: 'transaction_number',
    data_type: 'varchar',
    is_sortable: true
  },
  {
    id: 5,
    label: 'Transaction Name',
    api_name: 'name',
    data_type: 'varchar',
    is_sortable: true
  },
  {
    id: 3,
    label: 'Created Date',
    api_name: 'created_at',
    data_type: 'dateTime',
    is_sortable: true,
    field_type: 'date_range'
  },
  {
    id: 4,
    label: 'Last Updated Date',
    api_name: 'updated_at',
    data_type: 'dateTime',
    is_sortable: true,
    field_type: 'date_range'
  }
]

export const qje_data = [
  {
    g_external_id: null,
    g_subsidiary: '220 - Gappify Netherlands',
    g_currency: 'EUR',
    g_name: 'United Therapeutics Corp.',
    g_debit: '56,756.00',
    g_credit: null,
    g_glaccount: '660030 Rent and Facilities Operating Exp : Sublease Income',
    g_line_description: 'test',
    g_department: '4625 - Prod Design & Research',
    g_class: '60 - Shared',
    g_po_number: '1006771-1',
    transaction_number: 'OPEN-1000260uat',
    g_status: 'Unposted',
    g_class_id: '14',
    g_resubmitted: 'No',
    g_transaction_app: 'OPEN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/9/1679">OPEN-1000260uat</a>',
    id: 577,
    g_qje_type: 'JE',
    line_group: '12d5ca9b3b63662de0e5e447dd056b55',
    token_id: 13507,
    g_app_id: 9,
    has_history: false
  },
  {
    g_external_id: null,
    g_subsidiary: '220 - Gappify Netherlands',
    g_currency: 'EUR',
    g_name: 'United Therapeutics Corp.',
    g_debit: null,
    g_credit: '56,756.00',
    g_glaccount: '211010 Total Accrued Liabilities : Accrued Liabilities',
    g_line_description: 'test',
    g_department: '8888 - Balance Sheet',
    g_class: '60 - Shared',
    g_po_number: '1006771-1',
    transaction_number: 'OPEN-1000260uat',
    g_status: 'Unposted',
    g_class_id: '14',
    g_resubmitted: 'No',
    g_transaction_app: 'OPEN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/9/1679">OPEN-1000260uat</a>',
    id: 578,
    g_qje_type: 'JE',
    line_group: '12d5ca9b3b63662de0e5e447dd056b55',
    token_id: 13507,
    g_app_id: 9,
    has_history: false
  },
  {
    g_external_id: '10000090-60-3-2022-07-13 22:12:46',
    g_subsidiary: '240 - Gappify UK Ltd.',
    g_currency: 'CAD',
    g_name: 'Alkermes PLC (ALKS)',
    g_debit: '5,467.00',
    g_credit: null,
    g_glaccount: '660030 Rent and Facilities Operating Exp : Sublease Income',
    g_line_description: null,
    g_department: '3130 - Sales Operations',
    g_class: '60 - Shared',
    g_po_number: '1002435-1',
    transaction_number: 'OPEN-1000260uat',
    g_status: 'Unposted',
    g_class_id: '14',
    g_resubmitted: 'Yes',
    g_transaction_app: 'OPEN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/9/1679">OPEN-1000260uat</a>',
    id: 610,
    g_qje_type: 'JE',
    line_group: '145cd6234cd6018fc39b6eb1300db7e3',
    token_id: 13508,
    g_app_id: 9,
    has_history: true
  },
  {
    g_external_id: '10000090-60-3-2022-07-13 22:12:46',
    g_subsidiary: '240 - Gappify UK Ltd.',
    g_currency: 'CAD',
    g_name: 'Alkermes PLC (ALKS)',
    g_debit: null,
    g_credit: '5,467.00',
    g_glaccount: '500010 Cost of Revenue : Domain Services 002',
    g_line_description: null,
    g_department: '8888 - Balance Sheet',
    g_class: '60 - Shared',
    g_po_number: '1002435-1',
    transaction_number: 'OPEN-1000260uat',
    g_status: 'Unposted',
    g_class_id: '14',
    g_resubmitted: 'Yes',
    g_transaction_app: 'OPEN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/9/1679">OPEN-1000260uat</a>',
    id: 611,
    g_qje_type: 'JE',
    line_group: '145cd6234cd6018fc39b6eb1300db7e3',
    token_id: 13508,
    g_app_id: 9,
    has_history: true
  },
  {
    g_external_id: '10000073---2022-06-30 19:39:57',
    g_subsidiary: null,
    g_currency: 'USD',
    g_name: 'Test Vendor 7',
    g_debit: '100,006.00',
    g_credit: null,
    g_glaccount: 'GLAccount Debit From CSV',
    g_line_description: 'Test Description 7',
    g_department: 'Department Debit From 7',
    g_class: 'Class Debit From 7',
    g_po_number: '9519',
    transaction_number: 'IMP-1000018uat',
    g_status: 'Unposted',
    g_class_id: '17',
    g_resubmitted: 'No',
    g_transaction_app: 'MAN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/30/1338">IMP-1000018uat</a>',
    id: 527,
    g_qje_type: 'JE',
    line_group: '1481fc7fab884650806838dd3c8c3c29',
    token_id: 11522,
    g_app_id: 30,
    has_history: false
  },
  {
    g_external_id: '10000073---2022-06-30 19:39:57',
    g_subsidiary: null,
    g_currency: 'USD',
    g_name: 'Test Vendor 7',
    g_debit: null,
    g_credit: '100,006.00',
    g_glaccount: '500010 Cost of Revenue : Domain Services 002',
    g_line_description: 'Test Description 7',
    g_department: '3130 - Sales Operations',
    g_class: 'Class Credit From 7',
    g_po_number: '9519',
    transaction_number: 'IMP-1000018uat',
    g_status: 'Unposted',
    g_class_id: '10037',
    g_resubmitted: 'No',
    g_transaction_app: 'MAN',
    g_transaction_id:
      '<a href="https://qa.gappify.com/review-center/transactions/30/1338">IMP-1000018uat</a>',
    id: 528,
    g_qje_type: 'JE',
    line_group: '1481fc7fab884650806838dd3c8c3c29',
    token_id: 11522,
    g_app_id: 30,
    has_history: false
  }
]
