import { Tooltip } from 'antd'
import '../styles/override.css'

/**
 * Generate the children columns for the table
 * @param cells - string[]
 * @param parent - string
 * @returns - any[]
 */
const generateChildren = (cells: string[], parent: string) => {
  return cells.map((cell, index) => {
    const thisIndex = `${parent}_${index}`
    const thisWidth = parent === 'dimensions' ? 125 : 100
    const classLastIndex = cells.length === index + 1 ? 'cell-last' : ''

    return {
      title: cell,
      dataIndex: thisIndex,
      key: thisIndex,
      width: thisWidth,
      onCell: (row: any) => {
        let colSpan = 1
        let rowSpan = 1
        let classTotals = row.is_totals ? 'cell-totals' : ''
        const classTitle =
          parent === 'dimensions' && row.is_totals ? 'cell-bold' : ''

        if (parent === 'dimensions') {
          if (row.is_totals) {
            // ** Handle colSpan for Subtotals on Dimensions grouping
            if (index === row.colSpanIndex) {
              colSpan = row.colSpan // ** Set colSpan to the remaining cells
            } else if (index > row.colSpanIndex) {
              colSpan = 0 // ** Set the rest of colSpan to 0
            }
          }

          // ** Handle rowSpan on Dimensions grouping
          rowSpan = row[`${thisIndex}_rowSpan`]
        }

        return {
          colSpan,
          rowSpan,
          className: `${classTotals} ${classTitle}`
        }
      },
      className: `cell-header-${parent} cell-index-${index} ${classLastIndex}`,
      fixed: parent === 'dimensions' ? 'left' : undefined,
      render: (text: string, row: any) => {
        if (parent === 'dimensions' && row.is_totals)
          return (
            <Tooltip placement='topLeft' title={text}>
              {text}
            </Tooltip>
          )
        return text
      }
    }
  })
}

export default generateChildren
