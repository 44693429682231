// ** Helper Imports
import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'

// ** Types Imports
import {
  BaseProps,
  LoginAuditTrailOptions,
  LoginAuditTrailParams
} from '../types/LoginAuditTrailServices'

// =================================================================
export const LoginAuditTrailAPI = () => {
  const getLoginAuditTrailTable = (
    props: BaseProps<LoginAuditTrailParams, LoginAuditTrailOptions>
  ) => {
    return axiosIdentityManagerInstance.get(
      `api/login-audits${props.options.endpoint}`,
      {
        params: props.params
      }
    )
  }

  return {
    getLoginAuditTrailTable
  }
}
