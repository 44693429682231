import _ from 'underscore'
import Role from '../constants/Role'

class RoleEnum {
  id: number
  label: string

  constructor(id: number, label: string) {
    this.id = id
    this.label = label
  }
}

const toReturn = {
  GAPPIFY_ADMINISTRATOR: new RoleEnum(
    Role.GAPPIFY_ADMINISTRATOR,
    'Gappify-Administrator'
  ),
  READ_ONLY: new RoleEnum(Role.READ_ONLY, 'Read-Only'),
  SYSTEM_ADMINISTRATOR: new RoleEnum(
    Role.SYSTEM_ADMINISTRATOR,
    'System-Administrator'
  ),
  TRANSACTION_MANAGER: new RoleEnum(
    Role.TRANSACTION_MANAGER,
    'Transaction-Manager'
  ),
  TASK_MANAGER: new RoleEnum(Role.TASK_MANAGER, 'Task-Manager'),
  QJE_MANAGER: new RoleEnum(Role.QJE_MANAGER, 'Qje-Manager'),
  FUll_USER: new RoleEnum(Role.FUll_USER, 'Full-User'),
  CLOSE_MANAGER: new RoleEnum(Role.CLOSE_MANAGER, 'Close-Manager')
} as const

const methods = {
  getLabelById: (id: number) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.id === id) {
        return true
      }

      return false
    })
    return found ? found.label : ''
  }
}

export default Object.freeze({ ...methods })
