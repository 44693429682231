const TxnLineLevelActions = {
  PASSBACK: '0',
  OVERRIDE: '1',
  COPY_LINE: '2',
  DELETE_LINE: '3',
  HISTORY_LOG: '4',
  NEW_HISTORY_LOG: '5',
  COPY_ACCRUAL_FORM_LINK: '6'
}

export default TxnLineLevelActions
