// ** Third Party Imports
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

// ** Data Imports 
import useHistoryLogData from '../mock/ActionModalsData/HistoryLogData'

// =================================================================
export default function useHandleDownloadPDF() {

    // ** Data **
    const { columns, rowsFromBackend } = useHistoryLogData()

    const handleDownloadPdf = async () => {
        const pdf = new jsPDF({
            orientation: 'landscape',
            format: 'a4',
            unit: 'px'
        })

        const headers = columns.map((col) => col.title)

        const bodyData = rowsFromBackend.map((row: Record<string, string>) => [
            row.Name,
            row['Date/Time'],
            row.Action,
            row.Field,
            row.old,
            row.new,
            row.Email,
            row.cc_email
        ])

        autoTable(pdf, {
            head: [headers],
            body: bodyData,
            columnStyles: {
                0: { minCellWidth: 20 },
                1: { minCellWidth: 20 },
                2: { minCellWidth: 20 },
                3: { minCellWidth: 20 },
                4: { minCellWidth: 20 },
                5: { minCellWidth: 20 },
                6: { minCellWidth: 20 },
                7: { minCellWidth: 20 },
                8: { minCellWidth: 20 },
            }
        })
        pdf.save('history-log.pdf')
    }

    return {
        handleDownloadPdf
    }

}
