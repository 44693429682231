const destructureSettings: (
  params: Record<string, any>
) => Record<string, any>[] = ({
  settings,
  isSortable
}: Record<string, any>) => {
  const columns = Object?.keys({ ...settings })?.map(
    (key: string | number, index: number) => {
      return {
        // ** Common column properties **
        title: (
          <div
            style={{ whiteSpace: 'nowrap' }}
            data-testid={`qje-supergrid-header-${key}`}
            data-cy={`qje-supergrid-header-${key}`}
          >
            {settings[key]?.label}
          </div>
        ),
        dataIndex: key,
        width: 200,

        // ** For sorting **
        sortDirections: ['ascend', 'descend', 'ascend'],

        // Use Table/s own sorter proprty
        sorter: isSortable,

        // Use custom sorter for column
        // sorter: (value: any, row: any, dir: string) =>
        //   console.log('sort by', key, dir),

        // ** Custom properties to be used for conditional renderings **
        api_name: key,
        data_type: settings[key]?.data_type,
        hidden: settings[key]?.hidden
      }
    }
  )
  return columns
}

export default destructureSettings
