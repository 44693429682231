// ** AntD Imports
import { Collapse, Skeleton } from 'antd'
import { ColumnType } from 'vincent-antd/node_modules/antd/lib/table'
import { Table as VTable } from 'vincent-antd'

// ** Hook Imports
import useTable from '../../hooks/useTable'

// ** Style Imports
import styles from '../../styles/quickinsights.module.css'

// =================================================================
const QuickInsights = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
}) => {
  // ** AntD **
  const { Panel } = Collapse

  const onChange = (key: string | string[]) => {}

  // ** Hooks **
  const apiQuickInsightsColumns: ColumnType<any>[] =
    data &&
    data?.quick_insights_headers &&
    data?.quick_insights_headers.map((header: any, columnIdx: any) => ({
      key: columnIdx,
      width: '250px',
      title: header,
      dataIndex: String(columnIdx),
      sorter: false
    }))

  if (apiQuickInsightsColumns) {
    apiQuickInsightsColumns.unshift({
      title: ' ',
      dataIndex: 'tableLabel',
      width: '250px',
      render: (item: any) => <p className={styles.item}>{item}</p>
    })
  }

  const tableLabels = [
    'Confirmation Sent',
    'Confirmation Received',
    '% of Confirmations Received'
  ]

  const apiQuickInsightsRows =
    data?.quick_insights &&
    data?.quick_insights.map((item: any, index: any) => {
      const formattedItem = Object.fromEntries(
        Object.entries(item).map(([key, value]) => {
          // Convert value to a localized string
          const formattedValue = parseInt(value as string).toLocaleString(
            'en-US'
          )

          // If the label is '% of Confirmations Received', append a '%' to the value
          if (
            tableLabels[index % tableLabels.length] ===
            '% of Confirmations Received'
          ) {
            return [key, `${formattedValue}%`]
          }

          return [key, formattedValue]
        })
      )
      return {
        key: index,
        tableLabel: tableLabels[index % tableLabels.length],
        ...formattedItem
      }
    })

  // ** Hooks **
  const { handleTableChange } = useTable(data)

  return (
    <div style={{ padding: '0px 0px 15px 18px' }}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        onChange={onChange}
        className={styles.collapse}
      >
        <Panel header='Quick Insights' key='1' className={styles.panel}>
          <div className='data-table' style={{ marginTop: 0 }}>
            <Skeleton
              loading={isLoading || isRefetching}
              paragraph={{ rows: 5 }}
              active
            >
              <VTable
                dataSource={apiQuickInsightsRows as []}
                columns={apiQuickInsightsColumns as []}
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                loading={isLoading || isRefetching}
              />
            </Skeleton>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default QuickInsights
