// ** React Imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// ** Antd and External Library Imports
import { Form, Space, message, Input, Tooltip } from 'antd'
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'
import {
  ExportOutlined,
  DownOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import download from 'downloadjs'

import { setCurrPage, setIsLoading } from '../context/actions/taskManagerAction'
import { exportTasks } from '../services/TaskManagerApi'
import { getAllFilters } from '../services/TaskManagerApi'

// ** Base Components
import GDropdownBtn from './gappify/GDropdownBtn'
import GButton from './gappify/GButton'
import GIcon from './gappify/GIcon'

// ** Custom Imports
import CreateNewFilterModal from './task-manager/CreateNewFilterModal'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'
import AccrualImportModal from '../pages/AccrualManager/components/AccrualImportModal'

/*eslint eqeqeq: "off"*/
const TaskManagerFilter = ({
  columns,
  externalOptions,
  internalOptions,
  activeFilterRef,
  reloadData,
  columnToBeSortedRef,
  totalTasks,
  setApplyToSelectedRows,
  setSelectedRows,
  setEditKey,
  searchTermRef,
  onClickImport
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [activeFilter, setActiveFilter] = useState(activeFilterRef.current)
  const [allFilters, setAllFilters] = useState({})
  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [previewTriggeredFrom, setPreviewTriggeredFrom] = useState()
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState(searchTermRef.current)

  const isLoading = useSelector((state) => state.allTasks.isLoading)
  const page = useSelector((state) => state.allTasks.currPage)

  const RP = useRolesAndPermissions()

  /*
   * ENG-1249 - Update Naming Convention for Accrual Cloud
   * @params columns - Object containing all column headers
   * @params api_name - api_name of the column header to match to columns object
   * @returns String of the label of the column header from columns Object that matches api_name
   */

  const getExportMenu = () => {
    return [
      {
        label: 'CSV',
        key: 'csv',
        disabled: isLoading,
        children: [
          {
            label: 'Export with IDs',
            key: 'with_id'
          },
          { label: 'Export without IDs', key: 'no_id' }
        ]
      }
    ]
  }

  const exportWithoutId = async () => {
    try {
      let filters = form.getFieldsValue()
      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) {
          delete filters[key]
        }
      })
      const { data, headers } = await exportTasks('csv', {
        filter: activeFilterRef.current.id.trim(),
        sort:
          columnToBeSortedRef.current.direction !== ''
            ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
            : null
      })
      const content = headers['content-type']

      download(data, `AccrualManager.csv`, content)

      message.success('CSV generated.')
      dispatch(setIsLoading(false))
    } catch (err) {
      console.log('error', err)
      message.error('An error occurred.')
      dispatch(setIsLoading(false))
    }
  }

  const exportWithId = async () => {
    try {
      let filters = form.getFieldsValue()
      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) {
          delete filters[key]
        }
      })
      const { data, headers } = await exportTasks('csv', {
        with_id: true, // BE can also handle `withId` but `with_id` should be standard
        filter: activeFilterRef.current.id.trim(),
        sort:
          columnToBeSortedRef.current.direction !== ''
            ? `${columnToBeSortedRef.current.columnName}:${columnToBeSortedRef.current.direction}`
            : null
      })
      const content = headers['content-type']

      download(data, `AccrualManager.csv`, content)

      message.success('CSV generated.')
      dispatch(setIsLoading(false))
    } catch (err) {
      console.log('error', err)
      message.error('An error occurred.')
      dispatch(setIsLoading(false))
    }
  }

  const onClickExport = async (item) => {
    if (totalTasks > 20000)
      message.error('Cannot export more than 20,000 records.')
    else {
      dispatch(setIsLoading(true))
      switch (item.key) {
        case 'csv':
          exportWithoutId()
          break
        case 'no_id':
          exportWithoutId()
          break
        case 'with_id':
          exportWithId()
          break
        default:
          dispatch(setIsLoading(false))
          return
      }
    }
  }

  const callGetAllFilters = async () => {
    let fetchedData = {}
    await getAllFilters().then((res) => {
      setAllFilters(res.data)
      fetchedData = res.data
    })
    return fetchedData
  }

  useEffect(() => {
    callGetAllFilters()
  }, [])

  const getFilterMenu = () => {
    return [
      ...[
        ...(allFilters &&
          Object.keys(allFilters)
            .filter(
              (filterKey) =>
                !['all', 'active', 'unreviewed'].includes(filterKey)
            )
            .map((filterKey) => {
              return {
                key: filterKey,
                label: allFilters[filterKey],
                disabled: !RP.USER_HAS_GET_TM_FILTER_PERMISSION
              }
            }))
      ],
      {
        type: 'divider'
      },
      {
        key: 'all',
        label: 'All Lines',
        disabled: !RP.USER_HAS_GET_TM_FILTER_PERMISSION
      },
      {
        key: 'active',
        label: 'Active Lines',
        disabled: !RP.USER_HAS_GET_TM_FILTER_PERMISSION
      },
      {
        key: 'unreviewed',
        label: 'Inactive Lines',
        disabled: !RP.USER_HAS_GET_TM_FILTER_PERMISSION
      },
      {
        type: 'divider'
      },
      {
        key: 'new',
        label: (
          <div onClick={() => setIsVisibleCreateModal(true)}>
            Create New Filter
          </div>
        ),
        disabled: !RP.USER_HAS_CREATE_TM_FILTER_PERMISSION
      }
    ]
  }

  const getFilterLabel = (filterKey) => {
    return allFilters[filterKey]
  }

  const handleSelectFilter = (filter) => {
    if (filter.key !== 'new') {
      setSelectedRows([])
      dispatch(setIsLoading(true))
      dispatch(setCurrPage(1))
      activeFilterRef.current = {
        id: filter.key,
        label: getFilterLabel(filter.key)
      }
      setActiveFilter({
        id: filter.key,
        label: getFilterLabel(filter.key)
      })
      reloadDataWithSearchTerm(1)
      setIsPreviewMode(false)
    }
  }

  const reloadDataWithSearchTerm = (_page = page) => {
    dispatch(setIsLoading(true))
    dispatch(setCurrPage(_page))
    reloadData({
      term: searchTerm,
      page: _page,
      filter: activeFilterRef.current.id
    })
  }

  const getSearchPlaceholder = () => {
    // Search name, department, glaccount, po number, email
    let labels = []

    let searchableApiNames = [
      'name',
      'department_debit',
      'glaccount_debit',
      'po_number',
      'email',
      'internal_email'
    ]

    searchableApiNames.forEach((apiName) => {
      let label = columns?.find((col) => col.api_name === apiName)?.label
      labels = [...labels, label]
    })

    return `Search ${labels
      .filter((label) => label?.trim().length > 0)
      .join(', ')}`
  }

  useEffect(() => {
    if (
      (searchTerm && `${searchTerm}`.length >= 3) ||
      !searchTerm ||
      `${searchTerm}`.length === 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        reloadDataWithSearchTerm(1)
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 15
        }}
      >
        <Space>
          <GDropdownBtn
            menuItems={getFilterMenu()}
            btnText={activeFilter.label}
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={handleSelectFilter}
            className='g-btn-default'
            setIsLoading={setIsLoading}
            callGetAllFilters={callGetAllFilters}
            disabled={!RP.USER_HAS_VIEW_TM_FILTERS_PERMISSION}
            menuClassName='filter-dp'
          />
          {isPreviewMode && previewTriggeredFrom === 'new' ? (
            <GButton
              type='text'
              className='g-btn-icon'
              icon={<GIcon icon={faEye} />}
              onClick={() => setIsVisibleCreateModal(true)}
            />
          ) : (
            !['all', 'active', 'unreviewed'].includes(activeFilter.id) && (
              <CreateNewFilterModal // update filter modal
                filterModalType='update'
                overrideLabel={
                  <GButton
                    type='text'
                    className='g-btn-icon'
                    icon={
                      <GIcon
                        icon={
                          isPreviewMode && previewTriggeredFrom === 'update'
                            ? faEye
                            : faPencil
                        }
                      />
                    }
                    disabled={!RP.USER_HAS_EDIT_TM_FILTER_PERMISSION}
                  />
                }
                overrideHeader={'Update Filter'}
                overrideFinishLabel={'Update View'}
                filterId={activeFilter.id}
                callGetAllFilters={callGetAllFilters}
                activeFilterRef={activeFilterRef}
                getFilterLabel={getFilterLabel}
                setActiveFilter={setActiveFilter}
                reloadData={reloadData}
                setIsPreviewMode={setIsPreviewMode}
                setPreviewTriggeredFrom={setPreviewTriggeredFrom}
                setApplyToSelectedRows={setApplyToSelectedRows}
                setSelectedRows={setSelectedRows}
                setEditKey={setEditKey}
              />
            )
          )}

          <div
            style={{
              width: 500
            }}
          >
            <Input
              placeholder={getSearchPlaceholder()}
              onChange={(e) => {
                searchTermRef.current = e.target.value
                setSearchTerm(e.target.value)
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  reloadDataWithSearchTerm(1)
                }
              }}
            />
          </div>
          <Tooltip
            overlayInnerStyle={{
              fontSize: 11,
              width: '100%'
            }}
            title={getSearchPlaceholder() || 'Search'}
          >
            <InfoCircleOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          </Tooltip>
        </Space>
        <Space>
          <AccrualImportModal />

          <GDropdownBtn
            menuItems={getExportMenu()}
            btnText='Export to'
            btnIcon={
              <ExportOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={onClickExport}
            className='g-btn-default'
            disabled={!RP.USER_HAS_DOWNLOAD_TASKS_PERMISSION || isLoading}
            menuClassName='filter-dp'
          />
        </Space>
      </div>
      <CreateNewFilterModal
        filterModalType='create'
        filterId={activeFilter.id}
        overrideLabel={<></>} // new filter modal
        callGetAllFilters={callGetAllFilters}
        activeFilterRef={activeFilterRef}
        getFilterLabel={getFilterLabel}
        setActiveFilter={setActiveFilter}
        reloadData={reloadData}
        setIsPreviewMode={setIsPreviewMode}
        setPreviewTriggeredFrom={setPreviewTriggeredFrom}
        overrideIsVisibleModal={isVisibleCreateModal}
        overrideSetIsVisibleModal={setIsVisibleCreateModal}
        willResetFormOnSuccesfulFinish
        setApplyToSelectedRows={setApplyToSelectedRows}
        setSelectedRows={setSelectedRows}
        setEditKey={setEditKey}
      />
    </>
  )
}

export default TaskManagerFilter
