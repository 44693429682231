// ** AntD Imports
import { Modal, Tabs } from 'antd'

// ** Custom Component Imports
import DataValuesTable from './components/Table'

// ** Type Imports
import DataValuesModalProps from '../../../types/DataValuesType'

// ** Zustand Imports
import useAnalyticsStore from '../../../manager/analytics-store'

// =================================================================
const DataValuesModal = ({
  openModal,
  setOpenModal,
  modalType
}: DataValuesModalProps) => {
  // ** Store **
  const { permNotesVendorName } = useAnalyticsStore()

  // ** Constants for mock data & testing **
  const checkPermissions: string = 'false'
  const activeKey = '1'
  const items = [
    {
      key: 'Data Values',
      label: `Data Values`
    }
  ]
  const handleTabChange = () => {}

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>{permNotesVendorName}</div>}
      centered
      open={modalType === 'data-values' && openModal}
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      width={2000}
      bodyStyle={{ maxHeight: '50vh' }}
      footer={<></>}
    >
      {checkPermissions === 'true' ? (
        <Tabs activeKey={activeKey} items={items} onChange={handleTabChange} />
      ) : (
        checkPermissions === 'false' && <DataValuesTable />
      )}
    </Modal>
  )
}

export default DataValuesModal
