import { axiosHistoryInstance } from '../helpers/axios'
import { axiosTaskManagerInstance } from '../helpers/axios'

export const getHistory = (id: string) => {
  return axiosHistoryInstance
    .get(`api/task-manager/vendor-task/${id}`)
    .catch((err) => {
      console.log(err)
    })
}

export const getTaskScheduleHistory = (id: string | number) => {
  return axiosHistoryInstance
    .get(`api/schedule-manager-history/task-schedule/${id}`)
    .catch((err) => {
      console.log(err)
    })
}

export const getStandardScheduleHistory = (id: string | number) => {
  return axiosHistoryInstance
    .get(`/api/schedule-manager-history/standard-schedule/${id}`)
    .catch((err) => console.log(err))
}

// ** For new fetching of history lines **
export const getTransactionHistory = (app: string, id: string | number) => {
  return axiosHistoryInstance
    .get(`/api/transaction-manager-history/${app}/${id}`)
    .catch((err) => console.log(err))
}

// ** Period Management **
export const getPeriodHistory = (id: string | number) => {
  return axiosHistoryInstance
    .get(`/api/period-management-history/${id}`)
    .catch((err) => console.log(err))
}

// ** SSO Management **
export const getSSOHistory = (id: string | number) => {
  return axiosHistoryInstance
    .get(`/api/sso-saml-history/${id}`)
    .catch((err) => console.log(err))
}

// ** Company Settings - Audit Trail **
export const getAuditTrail = () => {
  return axiosTaskManagerInstance
    .get(`/api/sync-all/logs`)
    .catch((err) => console.log(err))
}
