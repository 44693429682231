/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** services imports */
import useApiService from '../../../../../hooks/useApiService'
import useWorkdayApiService from '../services/useWorkdayApiService'

/** types imports */
import GetWorkDaySettingsApiResponse from '../types/GetWorkDaySettingsApiResponse'

// =================================================================
export default function useFetchWorkDaySettings() {
  /** services */
  const { workDayApi } = useWorkdayApiService()
  const { msUrl } = useApiService()

  return useQuery<GetWorkDaySettingsApiResponse>({
    queryKey: ['workday'],
    queryFn: () =>
      workDayApi({
        options: {
          baseUrl: msUrl.idms,
          endpoint: '/api/api-manager-workday',
          method: 'GET'
        }
      }),
    refetchInterval: 5000 * 60, // sets the refetch interval to 5mins
    staleTime: 5000 * 60 // sets the stale time to 5mins
  })
}
