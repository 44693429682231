// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Type Imports
import LoginAuditTrailState from '../types/Reducer'

// =================================================================
// Define the initial state
const initialState: LoginAuditTrailState = {
  data: [],
  isLoading: false,
  pageSize: undefined,
  totalItems: undefined,
  page: 1,
  sort: 'desc',
  exportType: undefined,
  exportIsLoading: false
}

// =================================================================
const LoginAuditTrailSlice = createSlice({
  name: 'loginAuditTrail',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<[]>) => {
      state.data = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPageSize: (state, action: PayloadAction<number | undefined>) => {
      state.pageSize = action.payload
    },
    setTotalItems: (state, action: PayloadAction<number | undefined>) => {
      state.totalItems = action.payload
    },
    setPage: (state, action: PayloadAction<number | undefined>) => {
      state.page = action.payload
    },
    setSort: (state, action: PayloadAction<'asc' | 'desc' | undefined>) => {
      state.sort = action.payload
    },
    setExportType: (state, action: PayloadAction<string | undefined>) => {
      state.exportType = action.payload
    },
    setExportIsLoading: (state, action: PayloadAction<boolean>) => {
      state.exportIsLoading = action.payload
    }
  }
})

export const {
  setData,
  setIsLoading,
  setPageSize,
  setTotalItems,
  setPage,
  setSort,
  setExportType,
  setExportIsLoading
} = LoginAuditTrailSlice.actions

export default LoginAuditTrailSlice.reducer
