import { AxiosInstance } from 'axios'
import {
  axiosGappifyApiInstance,
  axiosTxnManagerInstance
} from '../../../helpers/axios'

type TMsInstances = {
  microservice: string
  instance: AxiosInstance
}

const MS_INSTANCES: TMsInstances[] = [
  {
    microservice: 'transaction-manager',
    instance: axiosTxnManagerInstance
  },
  {
    microservice: 'gappify-api',
    instance: axiosGappifyApiInstance
  }
]

const getInstance = (microservice: string) => {
  return MS_INSTANCES.find(
    (item: TMsInstances) => item.microservice === microservice
  )
}

export default getInstance
