import { Space } from 'antd'
import GButton from '../gappify/GButton'
import { FC, MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

interface QjeRowDropDownButtonsProps {
  tab: string
  hasSelectedJE?: boolean
  hasSelectedUnreviewedPO?: boolean
  onPostJEClick?: MouseEventHandler<HTMLElement>
  onRejectJEClick?: MouseEventHandler<HTMLElement>
  onClosePOClick?: MouseEventHandler<HTMLElement>
  // refs
  selectedLineGroupsRef?: any
}

const QjeRowDropDownButtons: FC<QjeRowDropDownButtonsProps> = ({
  tab,
  onPostJEClick,
  onRejectJEClick,
  onClosePOClick
}: QjeRowDropDownButtonsProps): JSX.Element => {
  const globalSelectedLineGroups = useSelector(
    (state: any) => state.allQjes.selectedLineGroups
  )

  const RP = useRolesAndPermissions()

  return (
    <Space size={1}>
      {tab === 'review-je' ? (
        <>
          <GButton
            dataTestId='qje-btn-post-je'
            dataCy='qje-btn-post-je'
            btnText={'Post JE'}
            disabled={
              !RP.USER_HAS_POST_QJE_JE_PERMISSION ||
              globalSelectedLineGroups.length === 0
            }
            onClick={onPostJEClick}
          />
          <GButton
            dataTestId='qje-btn-reject-je'
            dataCy='qje-btn-reject-je'
            btnText={'Reject JE'}
            disabled={
              !RP.USER_HAS_REJECT_QJE_PERMISSION ||
              globalSelectedLineGroups.length === 0
            }
            onClick={onRejectJEClick}
          />
        </>
      ) : tab === 'unreviewed-po-closure' ? (
        <GButton
          dataTestId='qje-btn-close-po'
          dataCy='qje-btn-close-po'
          btnText={'Close PO'}
          disabled={
            !RP.USER_HAS_CLOSE_PO_QJE_PERMISSION ||
            globalSelectedLineGroups.length === 0
          }
          onClick={onClosePOClick}
        />
      ) : null}
    </Space>
  )
}

QjeRowDropDownButtons.defaultProps = {
  hasSelectedJE: false,
  hasSelectedUnreviewedPO: false
}

export default QjeRowDropDownButtons
