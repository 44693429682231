import { Modal } from 'antd'
import { FC } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import GButton from './gappify/GButton'

interface ErrorModalProps {
  visible: boolean
  status: number | null
  message: string
  reference: string
  onOk: () => void
}

const ErrorModal: FC<ErrorModalProps> = ({
  visible,
  status,
  message,
  reference,
  onOk
}: ErrorModalProps): JSX.Element => {
  return (
    <Modal
      open={visible}
      title={
        <>
          <CloseCircleOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} style={{ color: '#ff4d4f' }} />
 Whoops! We encountered an error :(
        </>
      }
      onOk={onOk}
      closable={false}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <GButton btnText='OK' type='primary' shape='default' onClick={onOk} />
        </div>
      }
    >
      {message && <h3>Message: {message}</h3>}
      <h4>Status: {status}</h4>
      <h4>Reference: {reference}</h4>
    </Modal>
  )
}

export default ErrorModal
