import { useState } from 'react'
import deactivateUser from '../services/deactivateUser'
import ResponseData from '../types/ResponseData'
import HttpStatus from '../../../../../helpers/constants/HttpStatus'
import store from '../../../../../context/store'
import setForbiddenError from '../../../../../context/actions/forbiddenErrorAction'

export default function useDeactivateUser() {
  const [success, setSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const deactivate = async (userId: number) => {
    try {
      const data: ResponseData = await deactivateUser(userId)

      setMessage(data.message)
      setSuccess(data.success)
      return data
    } catch (error: any) {
      const data: ResponseData = error.response.data

      if (error.response.status === HttpStatus.HTTP_FORBIDDEN) {
        store.dispatch(
          setForbiddenError({
            visible: true,
            message: error.response.data.message
          })
        )
      }

      setMessage(data.message)
      setSuccess(data.success)
      return data
    }
  }

  return { success, message, deactivate }
}
