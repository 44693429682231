/** Tanstack imports */
import { useMutation, useQuery } from '@tanstack/react-query'

/** Services imports */
import useAccrualManagerService from '../services/AccrualManagerService'
import useApiService from '../../../hooks/useApiService'
import useAccrualManagerstore from '../manager/tasks-store'
import { message } from 'antd'

// =================================================================
export default function useSyncAction() {
  /** API service */
  const { AccrualManagerAPI } = useAccrualManagerService()
  const { msUrl } = useApiService()

  /** Store */
  const { taskId, isModalOpen } = useAccrualManagerstore()

  const getSyncTableData = useQuery({
    queryKey: ['sync-table', taskId],
    queryFn: () =>
      AccrualManagerAPI({
        options: {
          baseURL: msUrl.taskmanager,
          endpoint: `api/vendor-tasks/sync/${taskId}`,
          method: `GET`
        }
      }),
    enabled: isModalOpen
  })

  // ** POST/PUT METHOD(S) **
  const {
    mutate: putSyncData,
    isLoading: isLoadingPut,
    isSuccess: isSuccessPut,
    isError: isErrorPut,
    data: putResponseData
  } = useMutation(AccrualManagerAPI, {
    onSuccess: (response) => {},
    onError: ({ data }) => {
      // console.log('POST/PUT method failed', data)
      message.error('An error occurred.')
    }
  })

  const handleOnSync = (ids: (number | string | null)[]) =>
    putSyncData({
      data: { ids: ids },
      options: {
        baseURL: msUrl.taskmanager,
        endpoint: 'api/vendor-tasks/sync',
        method: 'PUT',
        customHeaders: {
          'User-Token': `${localStorage?.getItem('authToken')}`
        }
      }
    })

  return {
    getSyncTableData,
    handleOnSync,
    isLoadingPut,
    isSuccessPut,
    isErrorPut,
    putResponseData
  }
}
