// ** Zustand Imports
import { create } from 'zustand'

// ** Type Imports
import ScheduleProps from '../types/ScheduleStore'
import TableRecord from '../../../../AccrualAnalytics/types/TableRecord'
import MonthlyDataType from '../types/MonthlyDataType'
import StandardDataType from '../types/StandardDataType'

// ** Third Party Imports
import moment from 'moment-timezone'
import {
  getCurrentPeriod,
  getCurrentPeriodFormatted
} from '../../../../../utils/Date'

type TRunTime = {
  year?: number
  date?: number
  month?: number
  hours?: number
  minute?: number
  second?: number
}

// =================================================================
const useScheduleStore = create<ScheduleProps>((set, get) => ({
  // Define initial state
  banner: [],
  collapsed: false,
  selectedItem: null,
  internalPage: 1,
  vendorPage: 1,
  timingPage: 1,
  volumePage: 1,
  accuracyPage: 1,
  completenessPage: 1,
  title: '',
  internalSort: 'desc',
  vendorSort: 'desc',
  timingSort: 'desc',
  volumeSort: 'desc',
  accuracySort: 'desc',
  completenessSort: 'desc',
  hovered: false,
  selectedRowKeys: {},
  sort: '0:asc',
  sortState: [],
  buttonClicked: '',
  isAnalytics: undefined,
  openModal: false,
  modalType: '',
  responseType: undefined,
  period: {
    key: moment(getCurrentPeriod()).format('YYYY-MM-01'),
    label: getCurrentPeriodFormatted()
  },
  permNotesVendorName: undefined,
  editStandard: false,
  openConfirmEditModal: false,
  confirmLoading: false,
  editingKeys: new Set(),
  monthlyData: [],
  standardData: [],
  editMonthly: false,
  monthlyEditPayload: [],
  standardEditPayload: [],
  callRunNow: false,
  callReset: false,
  taskScheduleId: undefined,
  loadingButtons: [],
  editLines: [],
  lineIdToBeSaved: undefined,
  errorOnMonthlyUpdate: undefined,

  // Define actions
  setBanner: (banner) => set({ banner }),
  setCollapsed: (collapsed) => set({ collapsed }),
  setSelectedItem: (selectedItem) => set({ selectedItem }),
  setInternalPage: (internalPage) => set({ internalPage }),
  setVolumePage: (volumePage) => set({ volumePage }),
  setVendorPage: (vendorPage) => set({ vendorPage }),
  setTimingPage: (timingPage) => set({ timingPage }),
  setAccuracyPage: (accuracyPage) => set({ accuracyPage }),
  setCompletenessPage: (completenessPage) => set({ completenessPage }),
  setTitle: (title) => set({ title }),
  setInternalSort: (internalSort) => set({ internalSort }),
  setVendorSort: (vendorSort) => set({ vendorSort }),
  setTimingSort: (timingSort) => set({ timingSort }),
  setVolumeSort: (volumeSort) => set({ volumeSort }),
  setAccuracySort: (accuracySort) => set({ accuracySort }),
  setCompletnessSort: (completenessSort) => set({ completenessSort }),
  setHovered: (hovered) => set({ hovered }),
  setSelectedRowKeys: (selectedRowKeys) => set({ selectedRowKeys }),
  setSort: (sort) => set({ sort }),
  setSortState: (sortState) => set({ sortState }),
  setButtonClicked: (buttonClicked) => set({ buttonClicked }),
  setIsAnalytics: (isAnalytics) => set({ isAnalytics }),
  setOpenModal: (value) => set({ openModal: value }),
  setModalType: (value) => set({ modalType: value }),
  setResponseType: (responseType) => set({ responseType }),
  setPeriod: (period) => set({ period: period }),
  setPermNotesVendorName: (value) => set({ permNotesVendorName: value }),
  setEditStandard: (editStandard) => set({ editStandard: editStandard }),
  setEditMonthly: (editMonthly) => set({ editMonthly: editMonthly }),
  setOpenConfirmEditModal: (value) => set({ openConfirmEditModal: value }),
  setConfirmLoading: (value) => set({ confirmLoading: value }),
  toggleEditingKey: (key: string) =>
    set((state) => {
      const newKeys = new Set(state.editingKeys)
      if (newKeys.has(key)) {
        newKeys.delete(key)
      } else {
        newKeys.add(key)
      }
      return { editingKeys: newKeys }
    }),

  toggleAllEditingKeys: (keys: string[]) =>
    set((state) => {
      if (state.editingKeys.size === 0) {
        return { editingKeys: new Set(keys) }
      } else {
        return { editingKeys: new Set() }
      }
    }),
  setMonthlyData: (newData) => set({ monthlyData: newData }),
  setStandardData: (newData) => set({ standardData: newData }),
  setCallRunNow: (callRunNow) => set({ callRunNow }),
  setCallReset: (callReset) => set({ callReset }),
  setTaskScheduleId: (taskScheduleId) => set({ taskScheduleId }),
  setLoadingButtons: (buttonId) =>
    set({ loadingButtons: [...get().loadingButtons, buttonId] }),
  removeFromLoadingButtons: (buttonId) =>
    set({
      loadingButtons: get().loadingButtons.filter((id: string | number) => {
        return id !== buttonId
      })
    }),
  setLineIdToBeSaved: (id: string | number) => set({ lineIdToBeSaved: id }),
  removeFromEditLines: (id: string | number) =>
    set({
      editLines: get().editLines.filter((line) => {
        return line !== id
      })
    }),
  setErrorOnMonthlyUpdate: (errors: Record<string, string | number>[]) => {
    set({ errorOnMonthlyUpdate: errors })
  },

  // Functions
  handleMenuClick: (key: string, record: TableRecord, button: string) => {
    set({ buttonClicked: button })
    set({ permNotesVendorName: record[0] })

    // ** For testing only
    switch (key) {
      case '1':
        set({ openModal: true, modalType: 'update' })
        break
      case '2':
        set({ openModal: true, modalType: 'perm' })
        break
      case '3':
        set({ openModal: true, modalType: 'history' })
        break
      default:
        break
    }
  },
  handleOnClickEdit: (key: string | number, monthlyDataKeys?: string[]) => {
    switch (key) {
      case 'monthly-edit': {
        set({ editMonthly: true })
        set((state) => {
          if (state.editingKeys.size === 0) {
            return { editingKeys: new Set(monthlyDataKeys) }
          } else {
            return { editingKeys: new Set() }
          }
        })
        break
      }
      case 'standard-edit': {
        set({ editStandard: true })
        break
      }
      default:
        break
    }
  },
  handleOnClickInlineEdit: (key: string | number) => {
    if (key === 'standard-edit') {
      set({ editStandard: true })
    } else set({ editLines: [...get().editLines, key] })
  },
  handleOnClickSave: (key: string | number) => {
    switch (key) {
      case 'monthly-save': {
        set({ openModal: true })
        break
      }
      case 'standard-save': {
        set({ openConfirmEditModal: true })
        break
      }
      default:
        break
    }
  },
  handleOnClickInlineSave: (id: string | number) => {
    set({ openModal: true, lineIdToBeSaved: id })
  },
  handleOnClickCancel: (key: string | number) => {
    switch (key) {
      case 'monthly-cancel': {
        set({
          editMonthly: false,
          editingKeys: new Set([]),
          monthlyEditPayload: [],
          errorOnMonthlyUpdate: []
        })
        break
      }
      case 'standard-cancel': {
        set({
          editStandard: false,
          standardEditPayload: [],
          errorOnMonthlyUpdate: []
        })
        break
      }
      default:
        set({ errorOnMonthlyUpdate: [] })
        break
    }
  },
  handleOnClickInlineCancel: (key: string | number) => {
    if (key === 'standard-cancel') {
      set({ editStandard: false, standardEditPayload: [] })
    } else
      set({
        editLines: get().editLines.filter((id: string | number) => {
          return id !== key
        })
      })
  },
  setMonthlyEditPayload: (data) => set({ monthlyEditPayload: data }),
  setStandardEditPayload: (data) => set({ standardEditPayload: data }),
  updateMonthlyEditPayload: (
    data,
    newNextRunTimeValues?: TRunTime,
    prevNextRunTime?: string
  ) => {
    let exists: MonthlyDataType | undefined = get().monthlyEditPayload.find(
      (item: MonthlyDataType) => data.taskScheduleId === item.taskScheduleId
    )
    if (exists) {
      let existsIndex: number = get().monthlyEditPayload.indexOf(exists)
      let newMonthlyEditPayload = get().monthlyEditPayload
      if (newNextRunTimeValues) {
        exists.nextRunTime = moment(exists.nextRunTime)
          .set(newNextRunTimeValues)
          .format('YYYY-MM-DDTHH:mm:ss')
      }
      newMonthlyEditPayload[existsIndex] = { ...exists, ...data }
      set({ monthlyEditPayload: newMonthlyEditPayload })
    } else {
      let newItem = data
      if (newNextRunTimeValues) {
        newItem.nextRunTime = moment(prevNextRunTime || moment())
          .set({
            ...newNextRunTimeValues
          })
          .format('YYYY-MM-DDTHH:mm:ss')
      }
      set({ monthlyEditPayload: [...get().monthlyEditPayload, newItem] })
    }
  },
  updateStandardEditPayload: (data) => {
    let exists = get().standardEditPayload.find(
      (item: StandardDataType) =>
        data.standardScheduleId === item.standardScheduleId
    )
    if (exists) {
      let existsIndex: number = get().standardEditPayload.indexOf(exists)
      let newStandardEditPayload = get().standardEditPayload
      newStandardEditPayload[existsIndex] = { ...exists, ...data }
      set({ standardEditPayload: newStandardEditPayload })
    } else {
      set({ standardEditPayload: [...get().standardEditPayload, data] })
    }
  },
  handleRunNow: (id: string | number) => {
    set({
      taskScheduleId: id,
      callRunNow: true,
      loadingButtons: [...get().loadingButtons, `run_now_${id}`]
    })
  },
  handleReset: (id: string | number) => {
    set({
      taskScheduleId: id,
      callReset: true,
      loadingButtons: [...get().loadingButtons, `reset_${id}`]
    })
  }
}))

export default useScheduleStore
