import _ from 'underscore'
class TxnLineSourceEnum {
  id: number
  label: string

  constructor(id: number, label: string) {
    this.id = id
    this.label = label
  }
}

const toReturn = {
  ORIGINAL: new TxnLineSourceEnum(0, 'Original'),
  RESPONDER_ADDED: new TxnLineSourceEnum(5, 'Responder Added'),
  RESPONDER_COPIED: new TxnLineSourceEnum(4, 'Responder Copied'),
  SYSTEM_ADDED: new TxnLineSourceEnum(1, 'System Added'),
  USER_ADDED: new TxnLineSourceEnum(3, 'User Added'),
  USER_COPIED: new TxnLineSourceEnum(2, 'User Copied')
}

const methods = {
  getLabelById: (id: number) => {
    let found = _.find(Object.values(toReturn), (item) => {
      if (item.id === id) {
        return true
      }
      return false
    })
    return found ? found.label : ''
  },
  getIdValueList: () => {
    let keyList: any = []
    Object.values(toReturn).forEach((o) => {
      keyList.push({ id: o.id, value: o.label })
    })
    return keyList
  }
}

export default Object.freeze({ ...toReturn, ...methods })
