import { ColumnsType } from 'antd/es/table'
import { TApiColumns } from '../types/api'
import generateChildren from './generateChildren'
import tableParentHeader from '../config/tableParentHeader'

/**
 * Generate the columns for the table
 * @param columns
 * @returns ColumnsType<any>
 */
const generateColumns = (columns: TApiColumns) => {
  const newColumns: ColumnsType<any> = tableParentHeader.map((header) => {
    const classHeader =
      header.title === '' ? 'cell-header-no-bg' : 'cell-header-center'
    return {
      title: header.title,
      children: generateChildren(columns[header.key], header.key),
      className: `cell-header ${classHeader}`
    }
  })

  return newColumns
}

export default generateColumns
