// ** AntD Imports
import { DownOutlined } from '@ant-design/icons'
import moment, { Moment } from 'moment'

// ** Custom Component Imports
import GDropdownBtn from '../../../../../../../components/gappify/GDropdownBtn'
import { getCurrentPeriod } from '../../../../../../../utils/Date'
import EditButton from '../../../components/EditButton'

// ** Hook Imports
import useDropdown from '../../../hooks/useDropdown'

// ** Zustand Imports
import useScheduleStore from '../../../manager/schedule-store'

// ** Data
import MonthlyData from '../../../mock/MonthlyData'

// =================================================================
const MonthlyPeriodDropdown = () => {
  // ** Hooks **
  const { handlePeriodClick } = useDropdown()

  // ** Store **
  const { period, editMonthly } = useScheduleStore()

  // ** Data **
  const { rowsFromBackend } = MonthlyData()

  // ** Adapted from GlobalFilterPanel
  const getTxnMonthsOptions = () => {
    const start = getCurrentPeriod()
    let mos = []
    for (let i = 0; i < 6; i++) {
      let period = start.format('MMMM YYYY')
      let key = start.startOf('month')
      mos.push({
        label: period,
        key: key.format('YYYY-MM-DD')
      })
      start.subtract(1, 'month')
    }
    return mos
  }

  let getStart: () => Moment = () => {
    if (moment(getCurrentPeriod()).date() >= 16) {
      return moment(getCurrentPeriod().set({ date: 16 }))
    } else
      return moment(getCurrentPeriod()).subtract(1, 'month').set({ date: 16 })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <GDropdownBtn
        className='g-btn-default'
        onClick={handlePeriodClick}
        menuItems={getTxnMonthsOptions()}
        btnText={`${period?.label}`}
        children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
        disabled={editMonthly}
      />

      {moment(getStart()).isSameOrBefore(
        moment(period?.key).set({ date: moment(getCurrentPeriod()).date() }),
        'D'
      ) &&
        !editMonthly && (
          <EditButton
            btnKey='monthly-edit'
            enabled={rowsFromBackend.length > 0}
          />
        )}
    </div>
  )
}

export default MonthlyPeriodDropdown
