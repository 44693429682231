// ** React Imports
import React from 'react'

// ** AntD Imports
import { Button, FormInstance } from 'vincent-antd'
import { Popconfirm } from 'antd'

// ** Style Imports
import styles from '../styles/global-filter.module.css'

// ** Zustand Imports
import useGFStore from '../store/GlobalFilterStore'

// ** Type Imports
import { EModalActions } from '../types/global-filter-types'

type TProps = {
  formRef: React.RefObject<FormInstance<any>>
  onCancel: () => void
  handleDeleteFilter: (id: number) => void
}

// =================================================================
const Footer = ({ formRef, onCancel, handleDeleteFilter }: TProps) => {
  // ** Zustand - Global Filtering Store **
  const activeFilterObj = useGFStore((state) => state.activeFilterObj)
  const filterModalAction = useGFStore((state) => state.filterModalAction)
  const setFilterModalAction = useGFStore((state) => state.setFilterModalAction)
  const setIsPreviewMode = useGFStore((state) => state.setIsPreviewMode)

  const handleDelete = () => {
    if (activeFilterObj) {
      handleDeleteFilter(activeFilterObj?.key || activeFilterObj?.id!)
      setFilterModalAction(EModalActions.DELETE)
    }
  }

  return (
    <div>
      <Button
        shape='round'
        className={styles.saveBtn}
        type='primary'
        htmlType='submit'
        onClick={() => {
          if (formRef.current) {
            setIsPreviewMode(0)
            formRef.current.submit()
          }
        }}
      >
        {filterModalAction === EModalActions.CREATE ? 'Save' : 'Update'} View
      </Button>

      {filterModalAction !== EModalActions.CREATE ? (
        <Popconfirm
          title='Please confirm if you want to delete this view.'
          onConfirm={handleDelete}
          okText='Yes'
          cancelText='No'
        >
          <Button shape='round'>Delete View</Button>
        </Popconfirm>
      ) : (
        <></>
      )}

      <Button
        shape='round'
        htmlType='submit'
        onClick={() => {
          if (formRef.current) {
            setIsPreviewMode(1)
            formRef.current.submit()
          }
        }}
      >
        Preview View
      </Button>

      <Button shape='round' onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

export default Footer
