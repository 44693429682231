/** 3rd party imports */
import { Collapse, Modal, Select, Table } from 'antd'
import download from 'downloadjs'

/** react imports */
import { ChangeEvent } from 'react'

/** constants imports */
import fileSources from '../constants/fileSources'

/** zustand imports */
import useUploadFileModalStore from '../manager/useUploadFileModalStore'

/** types imports */
import Source from '../types/Source'
import TableColumns from '../types/TableColumns'
import TableDataSource from '../types/TableDataSource'

/** hooks imports */
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'
import useDownloadCSV from './useDownloadCSV'

// =================================================================
export default function useUploadFileModal() {
  /** zustand store */
  const {
    isImportAccrualEnabled,
    isSourceDataEnabled,
    source,
    importedAccrualName,
    importedAccrualFile,
    sourceFile,
    setSource,
    setImportedAccrualFile,
    setSourceFile,
    setImportedAccrualName
  } = useUploadFileModalStore()

  const RP = useRolesAndPermissions()

  /** hooks */
  const { refetch: getSourceFileTemplate } = useDownloadCSV()

  const getSourceFileList = (): JSX.Element[] => {
    return fileSources.map((fileSource) => {
      if (fileSource.key === 1) {
        if (
          RP.USER_HAS_UPLOAD_IMPORTED_ACCRUALS_PERMISSION &&
          isImportAccrualEnabled
        ) {
          return (
            <Select.Option
              key={fileSource.key}
              value={fileSource.value}
              data-testid={`upload-file-option-${fileSource.value}`}
              data-cy={`upload-file-option-${fileSource.value}`}
            >
              Imported Accruals
            </Select.Option>
          )
        }
      } else {
        if (RP.USER_HAS_UPLOAD_FILE_PERMISSION && isSourceDataEnabled) {
          return (
            <Select.Option
              key={fileSource.key}
              value={fileSource.value}
              data-testid={`upload-file-option-${fileSource.value}`}
              data-cy={`upload-file-option-${fileSource.value}`}
            >
              {fileSource.value}
            </Select.Option>
          )
        }
      }
      return (
        <Select.Option key={fileSource.key} style={{ display: 'none' }}>
          test
        </Select.Option>
      )
    })
  }

  const handleSourceChange = (value: Source) => setSource(value)

  const handleSourceFileOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (!fileList) return

    setImportedAccrualFile(null)
    setSourceFile(fileList[0])
  }

  const getModalTitle = (): JSX.Element => {
    const title = (
      <span
        data-testid='upload-file-modal-title'
        data-cy='upload-file-modal-title'
      >
        Upload File Source
      </span>
    )

    if (source === 'imported_accruals') {
      return (
        <>
          <div
            style={{
              display: 'inline'
            }}
          >
            {title}
            <Select
              value={source}
              placeholder='Select source'
              style={{ width: '40%', marginLeft: '10px' }}
              size='large'
              onChange={handleSourceChange}
              data-testid='source-field'
              data-cy='source-field'
              allowClear
            >
              {getSourceFileList()}
            </Select>
          </div>
        </>
      )
    } else {
      return title
    }
  }

  const getAccrualPeriod = (): string => {
    const date = new Date()
    date.setDate(date.getDate() - 16)
    const formattedDate = date.toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric'
    })

    return formattedDate
  }

  const handleImportedAccrualNameOnChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value
    setImportedAccrualName(value)
  }

  const handleImportedAccrualFileOnChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = e.target.files

    if (!fileList) return

    setSourceFile(null)
    setImportedAccrualFile(fileList[0])
  }

  const downloadSampleCsv = async (): Promise<void> => {
    const { data, isSuccess } = await getSourceFileTemplate()

    if (isSuccess) {
      download(data, `${source}_template.csv`)
    }
  }

  const isUploadBtnDisabled = (): boolean => {
    if (source === 'imported_accruals') {
      if (!importedAccrualName) {
        return true
      }
      return source && importedAccrualFile && importedAccrualName!.length > 0
        ? false
        : true
    }
    return source && sourceFile ? false : true
  }

  const getForm = (): FormData => {
    const formData: FormData = new FormData()
    if (source) {
      formData.append('source_type', source)
    }

    if (source !== 'imported_accruals' && sourceFile) {
      formData.append('csv', sourceFile)
    }

    if (
      source === 'imported_accruals' &&
      importedAccrualName &&
      importedAccrualFile
    ) {
      formData.append('imported_accrual_name', importedAccrualName)
      formData.append('csv', importedAccrualFile)
    }

    return formData
  }

  const handleErrorReponse = (response: any) => {
    const { data } = response

    if (Array.isArray(data.error)) {
      const dataSource: TableDataSource[] = []
      data.error.forEach(
        (
          item: { attribute: string; message: string; row: number },
          index: number
        ) => {
          dataSource.push({
            key: index,
            attribute: item.attribute,
            message: item.message,
            row: item.row
          })
        }
      )

      const columns: TableColumns[] = [
        {
          title: 'Row number',
          dataIndex: 'row',
          key: 'row'
        },
        {
          title: 'Attribute',
          dataIndex: 'attribute',
          key: 'attribute'
        },
        {
          title: 'Error message',
          dataIndex: 'message',
          key: 'message'
        }
      ]

      Modal.error({
        title: (
          <span data-testid='error-title' data-cy='error-title'>
            Invalid CSV Fields
          </span>
        ),
        okText: 'Close',
        closable: true,
        width: 1000,
        centered: true,
        content: (
          <>
            <Table
              data-testid='error-table'
              data-cy='error-table'
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </>
        )
      })
    } else if (typeof data.error === 'string') {
      Modal.error({
        title: (
          <span data-testid='error-title' data-cy='error-title'>
            {data.error}
          </span>
        ),
        width: 500,
        centered: true
      })
    } else {
      let errorResponse: string = data.error.csv[0]
      errorResponse = errorResponse.replace('Invalid Headers: [', '')
      errorResponse = errorResponse.replace(']', '')
      errorResponse = errorResponse.replaceAll('"', '')

      const errors: string[] = errorResponse.split(',')

      Modal.error({
        title: (
          <span data-testid='error-title' data-cy='error-title'>
            Invalid CSV Headers
          </span>
        ),
        width: 500,
        content: (
          <>
            <Collapse bordered={false}>
              <Collapse.Panel
                header={
                  <span
                    data-testid='upload-file-error-message'
                    data-cy='upload-file-error-message'
                  >
                    Your CSV file contains unrecognized header(s)
                    <br />
                    expand to see the list
                  </span>
                }
                key={1}
              >
                <ul>
                  {errors.map((item, index) => (
                    <li
                      data-testid={`csv-error-${index}`}
                      data-cy={`csv-error-${index}`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </>
        ),
        centered: true
      })
    }
  }

  return {
    getSourceFileList,
    handleSourceChange,
    handleSourceFileOnChange,
    getModalTitle,
    getAccrualPeriod,
    handleImportedAccrualNameOnChange,
    handleImportedAccrualFileOnChange,
    downloadSampleCsv,
    isUploadBtnDisabled,
    getForm,
    handleErrorReponse
  }
}
