import { FormEvent, SetStateAction } from 'react'
import TransactionManagerColumn from '../../../types/TransactionManagerColumn'

export default function handleOnChangeLabelFieldColumn(
  recordId: number,
  dataSource: TransactionManagerColumn[],
  setDataSource: (value: SetStateAction<TransactionManagerColumn[]>) => void
) {
  return (e: FormEvent<HTMLInputElement>): void => {
    const rows = dataSource.map((item) => {
      if (item.id === recordId) {
        item.label = e.currentTarget.value
        return item
      }
      return item
    })
    setDataSource(rows)
  }
}
