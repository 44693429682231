/** antd imports */
import { InfoCircleOutlined } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'

/** types imports */
import GQuickSearchBarProps from './types/GQuickSearchBarProps'

/** styles imports */
import styles from './styles/main.module.css'

/** react imports */
import { useEffect, useState } from 'react'

/** zustand imports */
import useGQuickSearchBarStore from './manager/useGQuickSearchBarStore'

/** hooks imports */
import useDebounce from '../../../hooks/useDebounce'

// =================================================================
export default function GQuickSearchBar({
  placeholder,
  onKeyDown,
  tooltipTitle
}: GQuickSearchBarProps) {
  /** react states */
  const [localInputTextValue, setLocalInputTextValue] = useState<string>('')

  /** zustand states */
  const { setInputText } = useGQuickSearchBarStore()

  /** hooks */
  const debouncedValue = useDebounce(localInputTextValue, 600)

  useEffect(() => {
    setInputText(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <>
      <div className={styles.container}>
        <Input
          placeholder={placeholder}
          onChange={(e) => {
            setLocalInputTextValue(e.target.value)
          }}
          onKeyDown={onKeyDown}
          className={styles.input}
          data-testid='g-search-bar-input-field'
          data-cy='g-search-bar-input-field'
        />
        <Tooltip
          overlayInnerStyle={{
            fontSize: 11,
            width: '100%'
          }}
          className={styles.tooltip}
          title={tooltipTitle}
        >
          <InfoCircleOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
        </Tooltip>
      </div>
    </>
  )
}
