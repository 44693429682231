import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

const Error: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const isVersionDisplayed = searchParams.get('version')

  const version = localStorage.getItem('version')

  const getBrowserDetails = (): string => navigator.userAgent

  const getDateAndTime = (): string => {
    return new Date().toString()
  }

  return (
    <div
      className='main-error-page'
      data-testid='error-panel'
      data-cy='error-panel'
    >
      <img
        src='./assets/images/gappify-logo-black.svg'
        alt='gpfy-logo'
        style={{
          marginBottom: '50px'
        }}
        data-testid='error-gappify-logo'
        data-cy='error-gappify-logo'
      />

      <h1
        className='error-title'
        data-testid='error-title'
        data-cy='error-title'
      >
        Whoops! <br />
        We encountered an error :(
      </h1>
      <h2
        className='error-subtitle'
        data-testid='error-message'
        data-cy='error-message'
      >
        {searchParams.get('message') ? (
          searchParams.get('message')
        ) : (
          <>Page not found!</>
        )}
      </h2>
      <section
        data-testid='error-details-section'
        data-cy='error-details-section'
      >
        {searchParams.get('status') && (
          <>
            <span data-testid='error-status-code' data-cy='error-status-code'>
              Status code: {searchParams.get('status')}
            </span>
            <br />
          </>
        )}
        {searchParams.get('guid') && (
          <>
            <span data-testid='error-guid' data-cy='error-guid'>
              Reference: {searchParams.get('guid')}
            </span>
            <br />
          </>
        )}
        <span data-testid='error-date' data-cy='error-date'>
          Date: {getDateAndTime()}
        </span>
        <br />
        <span data-testid='error-user-agent' data-cy='error-user-agent'>
          User agent: {getBrowserDetails()}
        </span>
      </section>
      <div
        className='version'
        style={{
          display: isVersionDisplayed !== 'true' ? 'none' : ''
        }}
      >
        {version}
      </div>
    </div>
  )
}

export default Error
