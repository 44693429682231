// ** React Imports **
import React from 'react'

// ** AntD Imports **
import { Tabs, TabsProps } from 'antd'
import View from './tabs/View'
import Layout from './tabs/Layout'

// ** Config **
import constants from './config/constants'

interface Props {
  onChange?: (key: string) => void
}

// =============================================
const ReviewCenter: React.FC<Props> = (props) => {
  // ** Constants **
  const { VIEW_SUBTAB, LAYOUT_SUBTAB, DEFAULT_ACTIVE_TAB } = constants

  const items: TabsProps['items'] | any = [
    {
      key: VIEW_SUBTAB.key,
      label: VIEW_SUBTAB.label,
      children: <View />
    },
    {
      key: LAYOUT_SUBTAB.key,
      label: LAYOUT_SUBTAB.label,
      children: <Layout />
    }
  ]

  return (
    <Tabs
      defaultActiveKey={DEFAULT_ACTIVE_TAB}
      items={items}
      size='large'
      data-testid='review-center-settings-tabs'
    />
  )
}

export default ReviewCenter
