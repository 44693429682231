/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** services imports */
import useAuthService from '../../../services/useAuthService'
import useApiService from '../../../hooks/useApiService'

// =================================================================
export default function useGoogleLogin() {
  /** services */
  const { authAPI } = useAuthService()
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['login-google'],
    queryFn: () =>
      authAPI({
        params: {},
        data: {},
        options: {
          baseURL: msUrl.idms,
          endpoint: '/login/google',
          method: 'GET'
        }
      }),
    enabled: false
  })
}
