/** antd imports */
import { Table, TablePaginationConfig } from 'antd'

/** hooks imports */
import useLogsColumns from '../hooks/useLogsColumns'

import { useMemo } from 'react'
import useFetchIntegrationLogs from '../hooks/useFetchIntegrationLogs'
import useLogsTableStore from '../stores/useLogsTableStore'

// =================================================================
export default function LogsTable() {
  /** hooks */
  const columns = useLogsColumns()
  const { data, isFetching, isRefetching, isLoading } =
    useFetchIntegrationLogs()

  /** memoized states */
  const loading = useMemo(() => {
    return isFetching || isRefetching || isLoading
  }, [isFetching, isLoading, isRefetching])

  /** zustand states */
  const page = useLogsTableStore((state) => state.page)
  const per_page = useLogsTableStore((state) => state.per_page)
  const setPage = useLogsTableStore((state) => state.setPage)
  const setPerPage = useLogsTableStore((state) => state.setPerPage)
  const setSort = useLogsTableStore((state) => state.setSort)

  /** layout effect */
  const viewportHeight = window.innerHeight
  const selectedSettings = document.querySelector(
    '[data-testid="selected-settings-title"]'
  )

  // 62 is the height of the navbar
  const navBarHeight = 62

  // 36 is the padding of the page container
  const pageContainerPadding = 36

  // 20 is the padding of the content
  const contentPadding = 20

  // 41 is the height of the selected settings title
  const selectedSettingsTitleHeight = 41

  // 49 is the height of antd divider
  const antdDividerHeight = 49

  // totalSelectedSettingsHeight is the sum of the
  // selected settings title and antd divider
  const totalSelectedSettingsHeight = selectedSettings
    ? selectedSettingsTitleHeight + antdDividerHeight
    : 0

  // 52 is the height of the logs page header buttons
  const logsPageHeaderButtonsHeight = 52

  // 41 is the height of the table headers
  const tableHeadersHeight = 41

  // 40 is the height of the pagination
  const paginationHeight = 40

  const totalHeight =
    navBarHeight +
    pageContainerPadding +
    contentPadding +
    totalSelectedSettingsHeight +
    logsPageHeaderButtonsHeight +
    tableHeadersHeight +
    paginationHeight

  // const getSpaces = 62 + 36 + 20 + 41 + 49 + 52 + 41 + 40
  const height = 100 * (totalHeight / viewportHeight)
  const getTableHeight = `${100 - height}dvh`

  const onTableChange = (
    config: TablePaginationConfig,
    filter: any,
    sorter: any
  ) => {
    const sortOrder: 'asc' | 'desc' = sorter.order === 'ascend' ? 'asc' : 'desc'

    setPage(config.current!)
    setPerPage(config.pageSize!)

    if (sorter.field) {
      setSort({
        [`sort[${sorter.field as string}]`]: sortOrder
      })
    }
  }

  return (
    <Table
      data-testid='logs-table'
      data-cy='logs-table'
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={data ? data.data : []}
      pagination={{
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total}`,
        pageSize: per_page,
        defaultCurrent: 1,
        current: page,
        pageSizeOptions: [100],
        showSizeChanger: true,
        total: data?.total
      }}
      loading={loading}
      scroll={{ y: getTableHeight }}
      size='small'
      onChange={onTableChange}
    />
  )
}
