import { useEffect, useState } from 'react'

// Antd Imports
import { Button, Dropdown, Menu, message } from 'antd'
import { MenuOutlined, MoreOutlined } from '@ant-design/icons'

// Assets Imports
import styles from './view.module.css'

// Custom Component Imports
import ViewsTable from '../components/ViewsTable'
import ViewModal from '../components/ViewModal'
import DeleteModal from '../components/DeleteModal'
import ActionButtons from '../components/ActionButtons'

// Hook Imports
import useFetchViewsList from '../hooks/useFetchViewsList'
import useFetchView from '../hooks/useFetchView'
import useFetchReviewCenterDimensions from '../hooks/useFetchReviewCenterDimensions'
import useReorderView from '../hooks/useReorderView'

// Zustand Imports
import useStore from '../manager/rc-settings-store'

// Type Imports
import { ApiViewTableData } from '../types/api'

// Mock Imports
import mockColumns from '../mock/columns'

type Props = {}

const View = (props: Props) => {
  /** Local states */
  const [editId, setEditId] = useState(0)
  const [deleteId, setDeleteId] = useState(0)
  const [availableOptions, setAvailableOptions] = useState<
    Record<string, any>[]
  >([])
  const [isReorderMode, setIsReorderMode] = useState(false)
  const [reorderedData, setReorderedData] = useState<ApiViewTableData[]>()

  /** Global states */
  const { setShowViewModal, setViewModalType, setShowDeleteModal } = useStore()

  /** Tanstack Query states */
  const {
    data: fetchedViews,
    isLoading: isLoadingViews,
    isRefetching: isRefetchingViews,
    isError: isErrorViews
  } = useFetchViewsList()
  const {
    data: fetchedSingleView,
    isLoading: isLoadingSingleView,
    isRefetching: isRefetchingSingleView,
    isError: isErrorSingleView
  } = useFetchView(editId)
  const {
    data: fetchedDimensions,
    isLoading: isLoadingDimensions,
    isRefetching: isRefetchingDimensions,
    isError: isErrorDimensions
  } = useFetchReviewCenterDimensions()
  const {
    mutate: updateOrder,
    isLoading: isLoadingUpdateOrder,
    isSuccess: isSuccessUpdateOrder
  } = useReorderView()

  /** Quick actions menu */
  const generateMenuItems = (id: number) => {
    return [
      {
        label: 'Edit View',
        key: 1,
        onClick: () => {
          setViewModalType('edit')
          setShowViewModal(true)
          setEditId(id)
        }
      },
      {
        label: 'Delete View',
        key: 2,
        onClick: () => {
          setShowDeleteModal(true)
          setDeleteId(id)
        }
      }
    ]
  }

  /** Table config */
  const columns = Array.from(mockColumns)
  columns[0].render = (_, record) =>
    isReorderMode ? (
      <Button
        className={`${styles.primaryBtn} ${styles.dragBtn}`}
        icon={
          <MenuOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            className='drag-handle'
          />
        }
        data-testid={`drag-handle-${record.id}`}
      />
    ) : (
      <Dropdown
        overlay={<Menu items={generateMenuItems(record.id)} />}
        trigger={['click']}
      >
        <Button
          className={styles.primaryBtn}
          icon={
            <MoreOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              className={styles.outlineBtn}
            />
          }
        />
      </Dropdown>
    )

  /** Handlers */
  const handleReorderMode = () => {
    setIsReorderMode(true)
  }
  const handleCreateView = () => {
    setViewModalType('create')
    setShowViewModal(true)
  }
  const handleSaveReoder = () => {
    if (reorderedData) {
      // Re-structure the data to match the API's required format
      const reorderedDataPayload = reorderedData?.map((item, index) => ({
        view_id: item.id,
        weight: index + 1
      }))
      const finalPayload = {
        data: reorderedDataPayload
      }
      updateOrder(finalPayload)
    }
  }
  const handleCancelReorder = () => {
    setIsReorderMode(false)
    setReorderedData(fetchedViews?.data)
  }

  /** Side effects */
  useEffect(() => {
    if (isErrorViews || isErrorSingleView || isErrorDimensions) {
      message.error('Something went wrong. See logs for more details.')
    }
    // eslint-disable-next-line
  }, [isErrorViews, isErrorSingleView, isErrorDimensions])

  useEffect(() => {
    if (!isLoadingDimensions && !isRefetchingDimensions && !isErrorDimensions) {
      setAvailableOptions(fetchedDimensions.data)
    }
    // eslint-disable-next-line
  }, [isLoadingDimensions, isRefetchingDimensions, isErrorDimensions])

  useEffect(() => {
    if (fetchedViews && fetchedViews.data) {
      setReorderedData(fetchedViews.data)
    }
  }, [fetchedViews])

  useEffect(() => {
    if (!isLoadingUpdateOrder && isSuccessUpdateOrder) {
      handleCancelReorder()
    }
    // eslint-disable-next-line
  }, [isLoadingUpdateOrder, isSuccessUpdateOrder])

  return (
    <>
      <ActionButtons
        isReorderMode={isReorderMode}
        isLoadingUpdateOrder={isLoadingUpdateOrder}
        handleReorderMode={handleReorderMode}
        handleCreateView={handleCreateView}
        handleSaveReoder={handleSaveReoder}
        handleCancelReorder={handleCancelReorder}
      />
      <ViewsTable
        data={fetchedViews?.data}
        columns={columns}
        isLoading={isLoadingViews || isRefetchingViews || isLoadingUpdateOrder}
        isReorderMode={isReorderMode}
        reorderedData={reorderedData}
        setReorderedData={setReorderedData}
      />
      <ViewModal
        availableOptions={availableOptions}
        isLoadingSingleView={isLoadingSingleView || isRefetchingSingleView}
        fetchedSingleView={fetchedSingleView?.data}
        setEditId={setEditId}
      />
      <DeleteModal deleteId={deleteId} />
    </>
  )
}

export default View
