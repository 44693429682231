/** React Imports */
import { useEffect, useRef, useState } from 'react'

/** Antd imports */
import { message, Select, Spin, Typography } from 'antd'

/** Components */
import TooltipIcon from '../../TooltipIcon'

/** Hooks */
import useUnapprovedInvoicesStore from '../manager/unapproved-invoices-store'

/** Styles */

const { Text } = Typography

type TProps = {
  invoiceStatuses: string[]
  isLoading: boolean
}

const InvoiceStatuses = ({ invoiceStatuses, isLoading }: TProps) => {
  /** Local states */
  const [tempNewInvoiceStatuses, setTempNewInvoiceStatuses] =
    useState<string[]>(invoiceStatuses) // This is used to handle comma behavior

  /** Local refs */
  const isKeyboardDeleteRef = useRef<boolean>(false) // We use ref here to track keyboard delete action because using state is delayed when capturing new value

  /** Global store */
  const isEditMode = useUnapprovedInvoicesStore((state) => state.isEditMode)
  const isSavingCompanySettings = useUnapprovedInvoicesStore(
    (state) => state.isSavingCompanySettings
  )
  const invoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.invoiceStatusesValues
  )
  const newStatus = useUnapprovedInvoicesStore((state) => state.newStatus)
  const setInvoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.setInvoiceStatusesValues
  )
  const setNewStatus = useUnapprovedInvoicesStore((state) => state.setNewStatus)

  /** Helpers */
  const resetStates = () => {
    // Reset keyboard delete flag after handling
    isKeyboardDeleteRef.current = false

    // Reset local states
    setNewStatus('')
  }

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    const key = event.key

    // Allow only lowercase letters, spaces, and Backspace
    if (!/^[a-z\s]$/.test(key) && key !== 'Backspace') {
      event.preventDefault()
    }

    // Set keyboard delete flag if Backspace or Enter is pressed
    if (key === 'Backspace' || key === 'Enter') {
      isKeyboardDeleteRef.current = true
    }
    // This simulates the behavior of pressing Enter key
    else if (key === ',') {
      let newValuesArray = Array.from(tempNewInvoiceStatuses)

      if (newValuesArray.includes(newStatus)) {
        // Remove new status from the new array
        newValuesArray = newValuesArray.filter((value) => value !== newStatus)
      } else {
        // Push new value only if not empty string
        if (newStatus && newStatus.trim() !== '') {
          newValuesArray.push(newStatus)
        }
      }

      handleChange(newValuesArray)
    }
  }

  const handleSearch = (value: string) => {
    setNewStatus(value)
  }

  const handleChange = (values: string[]) => {
    // Detect removed value by comparing previous and current selected values
    const removedValue = invoiceStatusesValues.find(
      (value) => !values.includes(value)
    )

    // * Error handling of new status
    if (newStatus) {
      // ? This prevents empty status to be added
      if (newStatus.trim() === '') {
        message.error('Please provide a valid status.')
        resetStates()
        return
      }

      // ? This prevents duplicate status to be added
      if (removedValue) {
        message.error('Please provide a unique status.')
        resetStates()
        return
      }
    }

    // * Handling of valid input & action
    if (!removedValue) {
      setInvoiceStatusesValues(values) // Update selected values
      setTempNewInvoiceStatuses(values) // Update temp values
      resetStates()
    } else {
      /**
       * * Handling of delete actions
       * ? Only remove item if it was not triggered by keyboard delete / enter
       */
      if (!isKeyboardDeleteRef.current) {
        const newValues = values.filter((value) => /^[a-z\s]+$/.test(value))
        setInvoiceStatusesValues(newValues)
        setTempNewInvoiceStatuses(newValues)
      } else {
        /**
         * This else block detects if the item was removed by keyboard delete
         * Will retain this for possible future usage
         */
      }
    }

    resetStates()
  }

  const filterOption = (input: string, option: any) => {
    // Allow filtering based on exact match
    return option.value.toLowerCase() === input.toLowerCase()
  }

  /** Use effects */
  useEffect(() => {
    if (invoiceStatuses && invoiceStatuses.length) {
      setInvoiceStatusesValues(invoiceStatuses)
      setTempNewInvoiceStatuses(invoiceStatuses)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatuses])

  return (
    <>
      <Text style={{ flex: '0 0 150px' }}>
        Invoice Statuses
        <TooltipIcon
          data-testid='invoice-statuses-tooltip'
          data-cy='invoice-statuses-tooltip'
          message='Define the invoice statuses that should be leveraged for unrecorded invoices accruals. Multiple values can be provided but should be comma separated.'
        />
      </Text>
      {isLoading ? (
        <Spin />
      ) : (
        <Select
          data-testid='invoice-statuses-dropdown'
          data-cy='invoice-statuses-dropdown'
          disabled={!isEditMode || isSavingCompanySettings}
          mode='tags'
          style={{ width: '100%' }}
          placeholder='Invoice Status'
          popupClassName='invoice-statuses-options'
          value={invoiceStatusesValues}
          onChange={handleChange}
          onInputKeyDown={handleInputKeyDown}
          onSearch={handleSearch}
          searchValue={newStatus}
          filterOption={filterOption}
        />
      )}
    </>
  )
}

export default InvoiceStatuses
