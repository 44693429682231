import {
  axiosTxnManagerInstance,
  axiosTxnManagerUploadInstance
} from '../helpers/axios'
import type TxnLinesReqParams from '../types/TxnLinesReqParams'

export const getTransactions = (params: any) => {
  return axiosTxnManagerInstance
    .get('/api/transactions', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getTransaction = (id: string | number) => {
  return axiosTxnManagerInstance.get(`/api/transactions/${id}`)
}

export const getSourceCoas = (type: string) => {
  const params = {
    g_coa_type: type
  }

  return axiosTxnManagerInstance
    .get('api/source/chart-of-accounts', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getGlobalSettings = () => {
  return axiosTxnManagerInstance.get(`/api/global/settings`)
}

export const getTransactionLines = (
  transactionId: number | string,
  params?: TxnLinesReqParams
) => {
  return axiosTxnManagerInstance.get(
    `/api/transactions/${transactionId}/lines`,
    { params }
  )
}

export const exportTxn = (type: string, params: any) => {
  return axiosTxnManagerInstance
    .get(`api/transactions/export/${type}`, { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getTransactionSettings = (transactionId: number | string) => {
  return axiosTxnManagerInstance.get(
    `/api/transactions/${transactionId}/settings`
  )
}

export const updateComment = (commentId: number | string, payload: any) => {
  return axiosTxnManagerInstance.put(`/api/comments/${commentId}`, payload)
}

export const deleteComment = (commentId: number) => {
  return axiosTxnManagerInstance.delete(`/api/comments/${commentId}`)
}

export const getAttachments = (transactionId: string, period?: string) => {
  const params = {
    transactionId: transactionId,
    period: period
  }
  return axiosTxnManagerInstance.get(`/api/attachments`, { params })
}

export const uploadAttachment = (data: any) => {
  return axiosTxnManagerUploadInstance.post(`/api/attachments`, data)
}

export const downloadAttachment = (
  transactionId: string,
  attachmentId: string
) => {
  return axiosTxnManagerInstance.get(
    `/api/attachments/${transactionId}/download/${attachmentId}`,
    { responseType: 'arraybuffer' }
  )
}

export const deleteAttachment = (attachmentId: string) => {
  return axiosTxnManagerInstance.delete(`/api/attachments/${attachmentId}`)
}

export const getTxnLineExport = (
  transactionId: number | string,
  exportType: string
) => {
  return axiosTxnManagerInstance.get(
    `/api/transactions/${transactionId}/lines/export/${exportType}`,
    {
      responseType: 'arraybuffer'
    }
  )
}

export const postTransaction = (
  transactionId: number | string,
  additionalParams: any,
  isTrd: boolean = false
) => {
  const url = isTrd
    ? 'api/transactions/lines/add-consolidated'
    : 'api/transactions/lines/add'

  return axiosTxnManagerInstance.post(url, {
    transaction_id: transactionId,
    ...additionalParams
  })
}

export const overrideTransaction = (url: string, data: any) => {
  return axiosTxnManagerInstance.put(url, data)
}

export const getEmailTracker = (txnId: string) => {
  return axiosTxnManagerInstance.get(`/api/transactions/${txnId}/email-tracker`)
}

export const getTrdEmailTracker = (period: string, txnId?: string | number) => {
  const params = {
    transaction_id: txnId,
    period
  }

  return axiosTxnManagerInstance.get(
    `/api/transactions/email-tracker-consolidated`,
    { params }
  )
}

export const getAllAttachments = (transactionId: string) => {
  return axiosTxnManagerInstance.get(
    `api/attachments/${transactionId}/download/all`,
    { responseType: 'arraybuffer' }
  )
}

export const uploadSourceFile = (params: FormData) => {
  return axiosTxnManagerUploadInstance.post('/api/source-csv', params)
}

export const getSourceFileTemplate = (csvType: string) => {
  return axiosTxnManagerUploadInstance.get(`/api/source-csv/${csvType}`, {
    responseType: 'arraybuffer'
  })
}

export const getSavedFilters = (params: any) => {
  return axiosTxnManagerInstance.get('api/filters', { params })
}

export const saveFilter = (data: any) => {
  return axiosTxnManagerInstance.post('api/filters', data)
}

export const getSavedFiltersById = (id: string) => {
  return axiosTxnManagerInstance.get(`api/filters/${id}`)
}

export const getLookupTransactionCurrency = (payload: {
  vendor?: string
  subsidiary?: string
}) => {
  return axiosTxnManagerInstance.post(
    '/api/lookup/transaction-currency',
    payload
  )
}

export const getFxRateAmount = (payload: {
  fromCurrency: string
  toCurrency: string
  accrualAmount: number
}) => {
  return axiosTxnManagerInstance.post('/api/lookup/fxrate-amount', payload)
}

export const deleteSavedFiltersById = (id: string) => {
  return axiosTxnManagerInstance.delete(`api/filters/${id}`)
}

export const getTrdTxnForm = (appKey: string, formId?: string | number) => {
  if (formId) {
    return axiosTxnManagerInstance.get(
      `api/transactions/current-period-transaction/${appKey}/${formId}`
    )
  }
  return axiosTxnManagerInstance.get(
    `api/transactions/current-period-transaction/${appKey}`
  )
}

export const getAppTypeForm = (appKey: string) => {
  return axiosTxnManagerInstance.get(`api/transactions/forms/${appKey}`)
}

export const getChildren = (
  {
    id,
    from_g_period,
    to_g_period,
    task_id
  }: {
    id: string | number
    from_g_period: string
    to_g_period: string
    task_id: string | number
  },
  isConsolidated: boolean | null
) => {
  let url = 'lines-period'

  if (isConsolidated) {
    url = 'prior-period-consolidated'
  }

  return axiosTxnManagerInstance.get(
    `api/transactions/${url}?transaction_id=${id}&from_g_period=${from_g_period}&to_g_period=${to_g_period}&task_id=${task_id}`
  )
}
