/** zustand imports */
import { create } from 'zustand'

interface GQuickSearchBarProps {
  /** state */
  inputText: string

  /** actions */
  setInputText: (inputText: string) => void

  /** functions */
  resetInputText: () => void
}

const useGQuickSearchBarStore = create<GQuickSearchBarProps>((set) => ({
  /** state */
  inputText: '',

  /** actions */
  setInputText: (inputText: string) => set({ inputText }),

  /** functions */
  resetInputText: () => {
    set({ inputText: '' })
  }
}))

export default useGQuickSearchBarStore
