import { create } from 'zustand'

// ** Types/Constants Imports
import { TFilter } from '../types/Filter'
import { TApiField } from '../types/Api'
import { DEFAULT_FILTER, MODAL_MODE } from '../constants'

type TFilterStore = {
  selectedFilter: TFilter
  modalMode: MODAL_MODE | null
  isModalOpen: boolean
  isPreviewMode: boolean
  isReloadFilters: boolean
  allFields: TApiField[] | []
  allFilters: TFilter[]
  setSelectedFilter: (selectedFilter: TFilter) => void
  setModalMode: (modalMode: MODAL_MODE | null) => void
  setIsModalOpen: (isModalOpen: boolean) => void
  setIsPreviewMode: (isPreviewMode: boolean) => void
  setIsReloadFilters: (isReloadFilters: boolean) => void
  setAllFields: (allFields: TApiField[] | []) => void
  setAllFilters: (allFilters: TFilter[] | []) => void
}

const useFilterStore = create<TFilterStore>((set) => ({
  /**
   * State variables
   */
  selectedFilter: DEFAULT_FILTER,
  modalMode: null,
  isModalOpen: false,
  isPreviewMode: false,
  isReloadFilters: false,
  allFields: [],
  allFilters: [],

  /**
   * State setters
   */
  setSelectedFilter: (selectedFilter) => set({ selectedFilter }),
  setModalMode: (modalMode) => set({ modalMode }),
  setIsModalOpen: (isModalOpen) => set({ isModalOpen }),
  setIsPreviewMode: (isPreviewMode) => set({ isPreviewMode }),
  setIsReloadFilters: (isReloadFilters) => set({ isReloadFilters }),
  setAllFields: (allFields) => set({ allFields }),
  setAllFilters: (allFilters) => set({ allFilters })
}))

export default useFilterStore
