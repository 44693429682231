import ActionTypes from '../constants/action-types'

const initalState = {
  token: ''
}

export const loginReducer = (state = initalState, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.SET_TOKEN:
      return { ...state, tasks: payload }
    default:
      return state
  }
}
