// ** Types **
import { useQueryClient } from '@tanstack/react-query'
import PayloadFormat from '../types/PayloadFormat'

// =================================================================
export default function MonthlyData() {
  const queryClient = useQueryClient()
  let data: PayloadFormat | undefined =
    queryClient.getQueryData(['txn-enable-features']) ?? undefined
  let mockData: PayloadFormat = {
    prior_period_information_on_vendor_accrual_forms: false,
    prior_period_information_on_internal_accrual_forms: true,
    prior_period_information_on_transaction_lines: true
  }

  return { data, mockData }
}
