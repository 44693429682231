// ** AntD Imports
import { Table } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import GButton from '../../../../../../components/gappify/GButton'

// ** Data Imports
import useHistoryLogData from '../../../../mock/ActionModalsData/HistoryLogData'

// ** Hook Imports
import useAnalytics from '../../../../hooks/useAnalytics'
import useHandleDownloadPDF from '../../../../hooks/useHandleDownloadPDF'

// ** Zustand Imports
import useAnalyticsStore from '../../../../manager/analytics-store'

// =================================================================
const HistoryLog = () => {
  // ** Data **
  const { columns, rowsFromBackend } = useHistoryLogData()

  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** API Services **
  const { getHistoryLog } = useAnalytics({
    responseType: responseType
  })
  const { isLoading, isRefetching } = getHistoryLog

  // ** Hooks **
  const { handleDownloadPdf } = useHandleDownloadPDF()

  return (
    <>
      <GButton
        dataTestId='txn-line-history-modal-export-btn'
        dataCy='txn-line-history-modal-export-btn'
        type='primary'
        icon={<FilePdfOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>}
        onClick={handleDownloadPdf}
        style={{
          float: 'right',
          marginBottom: '20px'
        }}
      >
        Export
      </GButton>
      <Table
        columns={columns}
        dataSource={rowsFromBackend}
        pagination={false}
        scroll={{ x: 'max-content', y: 500 }}
        size='small'
        loading={isLoading || isRefetching}
      />
    </>
  )
}

export default HistoryLog
