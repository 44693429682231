import constants from '../config/constants'

const { CURRENCY } = constants

export default function ModalData() {
  const currencies = [
    {
      key: CURRENCY.FUNCTIONAL.key,
      label: CURRENCY.FUNCTIONAL.label
    },
    {
      key: CURRENCY.REPORTING.key,
      label: CURRENCY.REPORTING.label
    }
  ]

  return {
    currencies
  }
}
