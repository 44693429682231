import { FC } from 'react'
import { Button } from 'antd'
import GButtonProps from '../../types/GButtonProps'

const GButton: FC<GButtonProps> = ({
  dataTestId,
  dataCy,
  btnText,
  block,
  danger,
  disabled,
  ghost,
  href,
  htmlType,
  icon,
  loading,
  prefixCls,
  shape,
  size,
  target,
  type,
  style,
  className,
  children,
  onClick
}: GButtonProps): JSX.Element => {
  const isDefault = (): boolean => {
    if (
      type === 'primary' ||
      type === 'dashed' ||
      type === 'text' ||
      type === 'link' ||
      type === 'ghost' ||
      danger ||
      disabled
    ) {
      return false
    }
    return true
  }

  return (
    <Button
      data-testid={dataTestId}
      data-cy={dataCy}
      block={block}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      htmlType={htmlType}
      icon={icon}
      loading={loading}
      prefixCls={prefixCls}
      size={size}
      target={target}
      type={type}
      shape={shape}
      style={style}
      className={isDefault() ? 'g-btn-default' : className}
      onClick={onClick}
    >
      {btnText}
      {children}
    </Button>
  )
}

GButton.defaultProps = {
  block: false,
  danger: false,
  disabled: false,
  ghost: false,
  htmlType: 'button',
  loading: false,
  shape: 'round',
  size: 'middle',
  type: 'default'
}

export default GButton
