import { Modal, Result } from 'antd'
import { FC } from 'react'
import GButton from './gappify/GButton'
import { useSelector } from 'react-redux'

interface ForbiddenErrorModalProps {
  visible: boolean
  onOk: () => void
}

const ForbiddenErrorModal: FC<ForbiddenErrorModalProps> = ({
  visible,
  onOk
}: ForbiddenErrorModalProps): JSX.Element => {
  const { message } = useSelector((state: any) => state.allForbiddenErrors)

  return (
    <Modal
      data-testid='forbidden-error-modal'
      data-cy='forbidden-error-modal'
      open={visible}
      width={600}
      closable={false}
      bodyStyle={{ height: 600 }}
      onOk={onOk}
      footer={null}
      centered
    >
      <Result
        status='403'
        title='403'
        subTitle={
          message
            ? message
            : 'Sorry, you are not authorized to access this page or action.'
        }
        extra={
          <GButton btnText='OK' type='primary' shape='default' onClick={onOk} />
        }
      />
    </Modal>
  )
}

export default ForbiddenErrorModal
