import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  callDeleteApi,
  callGetApi,
  callPostApi,
  callPutApi
} from '../services/GenericAPI'
import {
  // getSavedFiltersById,
  deleteSavedFiltersById,
  getEmailTracker,
  getGlobalSettings,
  getSavedFilters,
  getTransaction,
  getTransactionLines,
  getTransactionSettings,
  getTxnLineExport,
  saveFilter
} from '../services/TxnManagerAPI'

import {
  CopyOutlined,
  DeleteOutlined,
  HistoryOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import {
  faCheck,
  faClone,
  faFileLines,
  faReply
} from '@fortawesome/free-solid-svg-icons'
import {
  Checkbox,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip
} from 'antd'
import AddMoreModal from '../components/AddMoreModal'
import ConfirmCreateQjeModal from '../components/ConfirmCreateQjeModal'
import GButton from '../components/gappify/GButton'
import GDynamicTable from '../components/gappify/GDynamicTable'
import GIcon from '../components/gappify/GIcon'
import GTransactionLinesActions from '../components/gappify/GTransactionLinesActions'
import OverrideModal from '../components/OverrideModal'
import ProcessingCreateQjeModal from '../components/ProcessingCreateQjeModal'
import SuccessCreateQjeModal from '../components/SuccessCreateQjeModal'
import TxnLineCalculationModal from '../components/TxnLineCalculationModal'
import TxnLineLookupModal from '../components/TxnLineLookupModal'
import TxnLineNudgeAllModal from '../components/TxnLineNudgeAllModal'
import TxnLineNudgeSelectedModal from '../components/TxnLineNudgeSelectedModal'
import TxnLinePassbackModal from '../components/TxnLinePassbackModal'
import TxnLinesActionTabsPanel from '../components/TxnLinesActionTabsPanel'
import TxnLinesHistoryModal from '../components/TxnLinesHistoryModal'

import download from 'downloadjs'
import moment from 'moment'
import TxnLineLevelActions from '../helpers/constants/TxnLineLevelActions'
import { qjeStore } from '../services/QjeManagerApi'
import { formatDate, getDateTimeMomentFormat } from '../utils/Date'
import TxnLineCalculationEnum from '../utils/enums/TxnLineCalculationEnum'
import TxnLineSourceEnum from '../utils/enums/TxnLineSourceEnum'
import TxnLineStatusEnum from '../utils/enums/TxnLineStatusEnum'
import TxnStatusEnum from '../utils/enums/TxnStatusEnum'
import getTracker from '../utils/txn/getTracker'

import type { MenuProps } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import type { TablePaginationConfig } from 'antd/lib/table'
import type { AxiosError } from 'axios'
import AttachmentsModal from '../components/AttachmentsModal'
import type DateTimeFormat from '../types/DateTimeFormat'
import type InvoiceDetail from '../types/InvoiceDetail'
import type TrackerFields from '../types/TrackerFields'
import type Transaction from '../types/Transaction'
import type TransactionField from '../types/TransactionField'
import type TransactionLine from '../types/TransactionLine'
import type TransactionProperties from '../types/TransactionProperties'
import type TxnLinesReqParams from '../types/TxnLinesReqParams'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'

// ** Zustand Imports
import useTxnEnableFeatureStore from './Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

// ** Custom Hooks Imports
import useTxnEnableFeatures from './Settings/pages/TxnEnableFeatures/hooks/useTxnEnableFeatures'

import useTxnGlobalSettingsStore from 'src/global/useTxnGlobalSettingsStore'
import { getTransactionHistory } from 'src/services/HistoryAPI'
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'

const TransactionLines: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [transaction, setTransaction] = useState<Transaction | null>(null)
  const [transactionProperties, setTransactionProperties] =
    useState<TransactionProperties | null>(null)

  const [transactionLines, setTransactionLines] = useState<TransactionLine[]>(
    []
  )
  const [columns, setColumns] = useState<TransactionField[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showHeader, setShowHeader] = useState(false)
  const [pageSize, setPageSize] = useState<number | undefined>(50)
  const [totalItems, setTotalItems] = useState<number | undefined>()

  const [isPassbackModalVisible, setIsPassbackModalVisible] =
    useState<boolean>(false)
  const [passbackUrl, setPassbackUrl] = useState<string>('')
  const [passbackVendorName, setPassbackVendorName] = useState<string>('')
  const [passbackEmail, setPassbackEmail] = useState<string>('')

  const [isNudgeAllModalVisible, setIsNudgeAllModalVisible] =
    useState<boolean>(false)
  const [isAddMoreModalVisible, setIsAddMoreModalVisible] =
    useState<boolean>(false)

  const [txnLineLookUpData, setTxnLineLookUpData] = useState<InvoiceDetail[]>(
    []
  )
  const [isTxnLineLookupModalLoading, setIsTxnLineLookupModalLoading] =
    useState<boolean>(false)
  const [isTxnLineLookupModalVisible, setIsTxnLineLookupModalVisible] =
    useState<boolean>(false)

  const [isNudgeSelectedModalVisible, setIsNudgeSelectedModalVisible] =
    useState<boolean>(false)

  const [txnLineId, setTxnLineId] = useState<number | null>(null)
  const [trackers, setTrackers] = useState<TrackerFields[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [checkboxCount, setCheckboxCount] = useState<number>(0)

  const [showHistory, setHistoryModalVisible] = useState(false)
  const [historyData, setHistoryData] = useState({})
  const [attachments, setAttachments] = useState<Array<any>>([])
  const [currentPage, setCurrentPage] = useState<number | undefined>(1)
  const [dateTimeFormat, setDateTimeFormat] = useState<DateTimeFormat>()
  const [showOverride, setOverrideModalVisible] = useState(false)
  const [overrideData, setOverrideData] = useState<any>({})

  const [columnFilter, setColumnFilter] = useState<any>()
  const [optionsList, setOptionsList] = useState<any>(null)

  const [isConfirmCreateQjeModalVisible, setIsConfirmCreateQjeModalVisible] =
    useState<boolean>(false)
  const [
    isProcessingCreateQjeModalVisible,
    setIsProcessingCreateQjeModalVisible
  ] = useState<boolean>(false)
  const [saveFilterOptions, setSaveFilterOptions] = useState<Array<any>>([])
  const [showSaveFilterModal, setShowSaveFilterModal] = useState<boolean>(false)
  const [formFields, setFormFields] = useState<any>()
  const [thresholdAmount, setThresholdAmount] = useState<number>(0)
  const [displayThresholdSupport, setDisplayThresholdSupport] =
    useState<boolean>(false)
  const [invoiceDetailsRange, setInvoiceDetailsRange] = useState<number>(90)

  const [isCalculationModalVisible, setIsCalculationModalVisible] =
    useState<boolean>(false)
  const [calculationUrl, setCalculationUrl] = useState<string>('')
  const [successQjeCount, setSuccessQjeCount] = useState<number>(0)
  const [processedQjeCount, setProcessedQjeCount] = useState<number>(0)
  const [failedQjeCount, setFailedQjeCount] = useState<number>(0)
  const [isSuccessCreateQjeModalVisible, setIsSuccessCreateQjeModalVisible] =
    useState<boolean>(false)
  const [reportingCurrency, setReportingCurrency] = useState<string | null>('')
  const [filterkey, setFilterKey] = useState<number>(0)
  const [showDelay, setModalDelay] = useState(false)
  const [lookUpApi, setLookUpApi] = useState('')
  const [createQjeErrors, setCreateQjeErrors] = useState<string[]>([])

  const [showAttachModal, setShowAttachModal] = useState(false)
  const [lineAttachments, setLineAttachments] = useState([])
  const [vendorName, setVendorName] = useState('')
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false)

  const { getTxnEnableFeatures } = useTxnEnableFeatures()

  const { txnGlobalSettings } = useTxnGlobalSettingsStore()

  const {
    isSuccess: isTxnEnableFeaturesSuccess,
    data: getTxnEnableFeaturesData
  } = getTxnEnableFeatures

  const setIsPrepaidEntryFeatureFlagEnabled = useTxnEnableFeatureStore(
    (state) => state.setNegativeAccrualAmountToPrepaidEntry
  )

  useEffect(() => {
    if (isTxnEnableFeaturesSuccess) {
      setIsPrepaidEntryFeatureFlagEnabled(
        getTxnEnableFeaturesData?.negative_accrual_amount_to_prepaid_entry!
      )
    }
  }, [
    isTxnEnableFeaturesSuccess,
    getTxnEnableFeaturesData,
    setIsPrepaidEntryFeatureFlagEnabled
  ])

  const RP = useRolesAndPermissions()

  //get global settings time format
  useEffect(() => {
    const getDateTimeFormat = async (): Promise<void> => {
      try {
        const { data } = await getGlobalSettings()
        const userDateTimeFormat = data['transactions.user.profile.settings']
        const featureFlags = data['transaction.feature_flags']
        const companyValues = data['transaction.company_values']

        setThresholdAmount(
          featureFlags.threshold_support_value
            ? featureFlags.threshold_support_value
            : 0
        )
        setDisplayThresholdSupport(featureFlags.display_threshold_support)
        setInvoiceDetailsRange(Number(featureFlags.invoice_details_range))
        setDateTimeFormat(userDateTimeFormat)
        setReportingCurrency(companyValues.g_reporting_currency)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getDateTimeFormat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get txn lines list
  useEffect(() => {
    getTransactionLinesList({
      page: 1,
      paginate_count: pageSize
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get txn data
  useEffect(() => {
    const getTransactionData = async (): Promise<void> => {
      try {
        const { data } = await getTransaction(id!)
        setTransaction(data)
        fetchSavedFilters(data?.form_id)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getTransactionData()
  }, [id])

  const fetchSavedFilters = async (form_id: number) => {
    const filterResponse = await getSavedFilters({
      form_id: form_id
    })
    let options: any[] = []

    filterResponse.data?.forEach((item: any) => {
      options.push({
        label: item.name,
        key: item.id,
        parameters: item.parameters
      })
    })
    setSaveFilterOptions(options)
  }

  // Get email tracking
  useEffect(() => {
    const getEmailTracking = async (): Promise<void> => {
      try {
        const { data } = await getEmailTracker(id!)

        if (Array.isArray(data)) {
          setTrackers(data)
        }
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getEmailTracking()
  }, [id])

  // Get column headers
  useEffect(() => {
    const getColumns = async (): Promise<void> => {
      try {
        const list: TransactionField[] = []
        const { data } = await getTransactionSettings(id!)
        const columnHeaders: TransactionField[] = data.transaction_fields
        const enums = data.transaction_properties?.enums

        columnHeaders.forEach((item, index) => {
          const apiName = item.api_name ?? 'no-api-name'
          if (index === 0 || index === 1) {
            //fix first 2 columns
            item.fixed = 'left'
          }
          if (item.api_name === 'g_created_at') {
            list.push({
              ...item,
              width: 230,
              render: (value: string, row: TransactionLine) => {
                const emailTracker = trackers.find(
                  (item) => row.token_id === item.token_id
                )

                return (
                  <Row className='created-column'>
                    <Col lg={18}>
                      {dateTimeFormat
                        ? value &&
                          getDateTimeMomentFormat(
                            value,
                            dateTimeFormat.date_format,
                            dateTimeFormat.time_format
                          )
                        : value && moment(value).format('MM/DD/YYYY hh:mm a')}
                    </Col>
                    {isLineOriginal(row.g_line_source!) && (
                      <>
                        {emailTracker && dateTimeFormat && (
                          <Col lg={1}>
                            {getTracker(
                              emailTracker!,
                              dateTimeFormat!.timezone_format
                            )}
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                )
              }
            })
          } else if (item.api_name === 'g_date_submitted') {
            list.push({
              ...item,
              render: (value: string, row: TransactionLine) => {
                return (
                  <Row className='created-column'>
                    {dateTimeFormat
                      ? value &&
                        getDateTimeMomentFormat(
                          value,
                          dateTimeFormat.date_format,
                          dateTimeFormat.time_format
                        )
                      : value && moment(value).format('MM/DD/YYYY hh:mm a')}
                  </Row>
                )
              }
            })
          } else if (item.api_name === 'g_status') {
            list.push({
              ...item,
              render: (value: number, row: TransactionLine) => {
                const label = TxnLineStatusEnum.getLabelById(Number(value))
                if (label === 'QJE Error') {
                  return (
                    <Tooltip title={row.qje_messages} color='#0b0a0a'>
                      {label}
                    </Tooltip>
                  )
                }
                return <>{label}</>
              }
            })
          } else if (item.api_name === 'g_line_source') {
            list.push({
              ...item,
              render: (value: number) => {
                return <>{TxnLineSourceEnum.getLabelById(value)}</>
              }
            })
          } else if (item.api_name === 'g_override_flag') {
            list.push({
              ...item,
              render: (value: string) => {
                if (value === '1') {
                  return <GIcon icon={faCheck} className='g-icon-primary' />
                }
                return <></>
              }
            })
          } else if (item.api_name === 'g_nocharges') {
            list.push({
              ...item,
              render: (value: number) => {
                if (value === 1) {
                  return <GIcon icon={faCheck} className='g-icon-primary' />
                }
                return <></>
              }
            })
          } else if (item.field_type === 'view_details_lookup') {
            list.push({
              ...item,
              render: (value: any, row: TransactionLine) => {
                return (
                  <GButton
                    dataTestId={`transaction-lines-lookup-btn-${row.id}`}
                    dataCy={`transaction-lines-lookup-btn-${row.id}`}
                    btnText='View'
                    size='small'
                    type='primary'
                    shape='round'
                    disabled={
                      !row.line_properties?.actions.lookup.enabled ||
                      !RP.USER_HAS_TXN_LINE_LOOKUP_PERMISSION
                    }
                    onClick={() =>
                      handleOnLookUpClick(
                        row.line_properties?.actions.lookup.url! +
                          `?api_name=${apiName}`,
                        item.api_name
                      )
                    }
                  />
                )
              }
            })
          } else if (item.api_name === 'g_calculation') {
            list.push({
              ...item,
              render: (value: number) => (
                <>{TxnLineCalculationEnum.getLabelFromId(Number(value))}</>
              )
            })
          } else if (item.api_name === 'g_accrual_amount') {
            list.push({
              ...item,
              render: (value: string | null, row: TransactionLine) => (
                <>
                  {RP.USER_HAS_LINE_CALCULATIONS_PERMISSION &&
                  value &&
                  row.line_properties?.actions.calculation.enabled ? (
                    <>
                      <GButton
                        dataTestId={`transaction-lines-accrual-amount-btn-${row.id}`}
                        dataCy={`transaction-lines-accrual-amount-btn-${row.id}`}
                        size='small'
                        type='link'
                        className={'btn-amount'}
                        children={
                          <div
                            style={{
                              color:
                                !row.line_properties?.actions.calculation
                                  .status || value === 'Error'
                                  ? 'red'
                                  : '#725bb4'
                            }}
                          >
                            {value === 'Error'
                              ? value
                              : value &&
                                parseFloat(value!)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        }
                        onClick={() => {
                          setIsCalculationModalVisible(true)
                          setCalculationUrl(
                            row.line_properties?.actions.calculation.url!
                          )
                        }}
                        disabled={!RP.USER_HAS_LINE_CALCULATIONS_PERMISSION}
                      />
                    </>
                  ) : (
                    <div style={{ textAlign: 'right' }}>
                      {value &&
                        parseFloat(value!)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  )}
                </>
              )
            })
          } else if (item.api_name === 'g_percentage_of_completion') {
            list.push({
              ...item,
              render: (value: number) => (
                <div
                  style={{
                    textAlign: 'right'
                  }}
                >
                  {value && `${value} %`}
                </div>
              )
            })
          } else if (
            item.api_name === 'g_close_po' ||
            item.data_type === 'boolean'
          ) {
            list.push({
              ...item,
              render: (value) => {
                if (value === '1' || value === 1) {
                  return 'Yes'
                }
                if (value === '0' || value === 0) {
                  return 'No'
                }
                return value
              }
            })
          } else if (item.api_name === 'g_threshold_support_document') {
            list.push({
              ...item,
              render: (value: string) => (
                <div>
                  {value && isValidURL(value) ? (
                    <a
                      href={value}
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: '#725bb4' }}
                    >
                      {value}
                    </a>
                  ) : (
                    value
                  )}
                </div>
              )
            })
          } else {
            list.push(item)
          }
        })

        list.push({
          key: 'attachments',
          api_name: 'attachments',
          label: 'Attachments',
          render: (attachments: any, row: any) => (
            <div>
              {attachments && attachments.length > 0 && (
                <div
                  data-testid={`transaction-lines-attachments-btn-${row.id}`}
                  data-cy={`transaction-lines-attachments-btn-${row.id}`}
                  style={{ color: '#725bb4', cursor: 'pointer' }}
                  onClick={() => {
                    setLineAttachments(attachments)
                    setShowAttachModal(true)
                    setVendorName(row.g_name)
                  }}
                >
                  <GIcon icon={faFileLines} />
                </div>
              )}
            </div>
          )
        })

        setFormFields(data.form_fields)
        setColumns(list)
        setTransactionProperties(data.transaction_properties)
        setOptionsList({
          g_line_source:
            enums &&
            Object.entries(enums['transactions.lines.g_line_source']).map(
              ([key, value]) => ({
                id: value,
                value: key.replace('_', ' ')
              })
            ),
          g_status:
            enums &&
            Object.entries(enums['transactions.lines.g_status']).map(
              ([key, value]) => ({
                id: value,
                value: key.replace('_', ' ')
              })
            ),
          g_calculation:
            enums &&
            Object.entries(enums['transactions.lines.g_calculation']).map(
              ([key, value]) => ({
                id: value,
                value: key
              })
            ),
          g_override_flag: [
            {
              id: 1,
              value: 'Yes'
            },
            {
              id: 0,
              value: 'No'
            }
          ],
          g_name: data.transaction_vendors.g_name.map(
            (item: string, index: number) => ({ id: item, value: item })
          )
        })
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getColumns() // eslint-disable-next-line
  }, [id, trackers, transactionLines, dateTimeFormat])

  useEffect(() => {
    if (!RP.USER_HAS_VIEW_TRANSACTION_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isValidURL = (text: string) => {
    try {
      new URL(text)
      return true
    } catch {
      return false
    }
  }

  const getTransactionLinesList = async (
    params?: TxnLinesReqParams
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const { headers, data } = await getTransactionLines(id!, params)

      const boxCount: number = data.reduce((total: number, item: any) => {
        if (
          Number(item.g_status) === TxnLineStatusEnum.SUBMITTED.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_ERROR.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_REJECTED.id
        ) {
          if (item.line_properties?.actions.qje.enabled) {
            return total + 1
          }
        }
        return total
      }, 0)
      setCheckboxCount(boxCount)
      setTransactionLines(data)
      setTotalItems(parseInt(headers['x-total-count']))
      setIsLoading(false)
      setShowHeader(true)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any
  ): void => {
    const order = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    const params = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort:
        sorter && sorter.field && `${sorter.field && sorter.field}:${order}`,
      ...columnFilter
    }
    setSelectedRows([])
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    getTransactionLinesList(params)
  }

  const handleFilterChange = async (
    values: any,
    allValues: any
  ): Promise<void> => {
    let newValues = allValues
    Object.keys(allValues).forEach((key) => {
      if (
        key === 'g_created_at' ||
        key === 'g_date_submitted' ||
        key === 'g_due_date' ||
        key === 'g_period'
      ) {
        if (allValues[key]) {
          newValues[key] = [
            formatDate(allValues[key][0]),
            formatDate(allValues[key][1])
          ]
        }
      }
    })
    const params = {
      ...newValues,
      page: 1,
      paginate_count: pageSize
    }
    setCurrentPage(1)
    setColumnFilter(newValues)
    getTransactionLinesList(params)
    setSelectedRows([])
    setCheckboxCount(0)
  }

  const showPassbackModal = (
    passbackUrl: string,
    vendorName: string,
    email: string,
    txnLineId: number
  ): void => {
    setIsPassbackModalVisible(true)
    setPassbackUrl(passbackUrl)
    setPassbackVendorName(vendorName)
    setPassbackEmail(email)
    setTxnLineId(txnLineId)
  }

  const handlePassbackOnSuccess = async (): Promise<void> => {
    await getTransactionLinesList({
      page: currentPage,
      paginate_count: pageSize
    })
    message.success('Passback notification has been sent.')
  }

  const handlePassbackOnError = (): void => {
    setIsPassbackModalVisible(false)
    message.error('Something went wrong.')
  }

  const handleOnNudgeAllClick = (): void => {
    setIsNudgeAllModalVisible(true)
  }

  const handleNudgeAllOnSuccess = (): void => {
    setIsNudgeAllModalVisible(false)
    message.success('Reminder emails have been sent')
  }

  const handleNudgeAllOnError = (): void => {
    setIsNudgeAllModalVisible(false)
    message.error('Something went wrong.')
  }

  const handleExportClick: MenuProps['onClick'] = ({ key }) => {
    downloadFile(key)
  }

  const showAddMoreModal = () => setIsAddMoreModalVisible(true)
  const handleCancelAddMore = () => setIsAddMoreModalVisible(false)

  const downloadFile = async (fileType: string) => {
    setIsExportLoading(true)
    try {
      const { data, headers } = await getTxnLineExport(id!, fileType)
      const content = headers['content-type']
      download(data, `${transaction?.name}.${fileType}`, content)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsExportLoading(false)
    }
  }

  const handleOnLookUpClick = async (url: string, api_name: string) => {
    try {
      setIsTxnLineLookupModalVisible(true)
      setIsTxnLineLookupModalLoading(true)
      const response = await callGetApi(url)
      setLookUpApi(api_name)
      setTxnLineLookUpData(response?.data.reverse())
      setIsTxnLineLookupModalLoading(false)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const isAllCheckboxSelected = (): boolean => {
    if (checkboxCount === 0) return false
    return selectedRows.length === checkboxCount
  }

  const handleGetSelectAllCheckbox = () => (
    <Checkbox
      data-testid='transaction-lines-table-select-all-checkbox'
      data-cy='transaction-lines-table-select-all-checkbox'
      checked={isAllCheckboxSelected()}
      onChange={onChangeCheckboxAll(transactionLines)}
    />
  )

  const onChangeCheckboxAll =
    (rows: TransactionLine[]) =>
    (e: CheckboxChangeEvent): void => {
      const isChecked = e.target.checked

      if (!isChecked) {
        setSelectedRows([])
        return
      }

      const allRowsToSelect: number[] = []

      rows.forEach((item) => {
        if (
          Number(item.g_status) === TxnLineStatusEnum.SUBMITTED.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_ERROR.id ||
          Number(item.g_status) === TxnLineStatusEnum.QJE_REJECTED.id
        ) {
          if (item.line_properties?.actions.qje.enabled) {
            allRowsToSelect.push(item.id!)
          }
        }
      })

      if (allRowsToSelect.length === 0) {
        message.info('No rows to select')
      }

      setSelectedRows(allRowsToSelect)
    }

  const handleGetLineCheckbox = (
    row: TransactionLine
  ): string | JSX.Element => {
    if (row.line_properties?.actions.qje.enabled) {
      return (
        <Checkbox
          data-testid={`transaction-lines-table-select-line-checkbox-${row.id}`}
          data-cy={`transaction-lines-table-select-line-checkbox-${row.id}`}
          checked={isRowSelected(row)}
          onChange={handleOnChangeCheckbox(row)}
        />
      )
    }

    if (
      Number(row.g_status) === TxnLineStatusEnum.QJE_POSTED.id ||
      Number(row.g_status) === TxnLineStatusEnum.QJE_CREATED.id
    ) {
      return (
        <GIcon
          data-testid={`transaction-lines-table-select-line-check-icon-${row.id}`}
          data-cy={`transaction-lines-table-select-line-check-icon-${row.id}`}
          icon={faCheck}
          className='g-icon-primary'
        />
      )
    }

    return ''
  }

  const handleOnChangeCheckbox =
    (row: TransactionLine) =>
    (e: CheckboxChangeEvent): void => {
      const isChecked = e.target.checked

      if (!isChecked) {
        setSelectedRows(selectedRows.filter((item) => item !== row.id!))
        return
      }

      setSelectedRows([...selectedRows, row.id!])
    }

  const isRowSelected = (row: TransactionLine): boolean =>
    selectedRows.includes(row.id!)

  const handleOnNudgeSelectedClick = (): void => {
    setIsNudgeSelectedModalVisible(true)
  }

  const handleOnNudgeSelectedSuccess = (): void => {
    Modal.success({
      content: 'Reminder emails have been sent'
    })
  }

  const handleOnNudgeSelectedError = (): void => {
    message.error('Something went wrong.')
  }

  const historyColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performed_by',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'old',
      width: 200
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'new',
      width: 200
    }
  ]

  const handleGetActions = (record: TransactionLine) => {
    const data = record.line_properties
    return (
      <div
        className='action-menu'
        data-testId={`transaction-lines-action-menu-${record.id}`}
        data-cy={`transaction-lines-action-menu-${record.id}`}
      >
        <GButton
          dataTestId={`transaction-lines-action-menu-passback-btn-${record.id}`}
          dataCy={`transaction-lines-action-menu-passback-btn-${record.id}`}
          type='text'
          className='g-btn-icon action-btn pass-icon'
          icon={<GIcon icon={faReply} />}
          onClick={() =>
            handleActionOnclick(record, TxnLineLevelActions.PASSBACK)
          }
          btnText={'Passback'}
          disabled={
            !data?.actions.passback.enabled ||
            !RP.USER_HAS_TXN_LINE_PASSBACK_PERMISSION ||
            transaction?.form_name === 'Imported Accruals'
          }
        />
        <GButton
          dataTestId={`transaction-lines-action-menu-override-btn-${record.id}`}
          dataCy={`transaction-lines-action-menu-override-btn-${record.id}`}
          type='text'
          className='g-btn-icon action-btn override-icon'
          onClick={() =>
            handleActionOnclick(record, TxnLineLevelActions.OVERRIDE)
          }
          icon={<GIcon icon={faClone} />}
          btnText={'Override'}
          disabled={
            !data?.actions.override.enabled ||
            !RP.USER_HAS_TXN_LINE_OVERRIDE_PERMISSION
          }
        />
        <GButton
          dataTestId={`transaction-lines-action-menu-copy-line-btn-${record.id}`}
          dataCy={`transaction-lines-action-menu-copy-line-btn-${record.id}`}
          type='text'
          className='g-btn-icon action-btn'
          onClick={() =>
            handleActionOnclick(record, TxnLineLevelActions.COPY_LINE)
          }
          icon={
            <CopyOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          btnText={'Copy Line'}
          disabled={
            !data?.actions.copy.enabled ||
            !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION
          }
        />
        <Popconfirm
          title='Are you sure you want to delete this item?'
          okText='Yes'
          cancelText='No'
          onConfirm={() =>
            handleActionOnclick(record, TxnLineLevelActions.DELETE_LINE)
          }
          placement='left'
          disabled={
            !data?.actions.delete.enabled ||
            !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION
          }
        >
          <GButton
            dataTestId={`transaction-lines-action-menu-delete-line-btn-${record.id}`}
            dataCy={`transaction-lines-action-menu-delete-line-btn-${record.id}`}
            type='text'
            className='g-btn-icon action-btn'
            icon={
              <DeleteOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            btnText={'Delete Line'}
            disabled={
              !data?.actions.delete.enabled ||
              !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION
            }
          />
        </Popconfirm>
        {moment(record?.g_period).isBefore(
          moment(
            (txnGlobalSettings as any)['transaction.history.cutoff_date'],
            'YYYY-MM-DD'
          )
        ) ? (
          <GButton
            dataTestId={`transaction-lines-action-menu-history-log-btn-${record.id}`}
            dataCy={`transaction-lines-action-menu-history-log-btn-${record.id}`}
            type='text'
            className='g-btn-icon action-btn'
            onClick={() =>
              handleActionOnclick(record, TxnLineLevelActions.HISTORY_LOG)
            }
            icon={
              <HistoryOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            btnText={'History Log'}
            disabled={
              !data?.actions.history.enabled ||
              !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION
            }
          />
        ) : (
          <GlobalHistoryModal
            reloadData={() => {
              return getTransactionHistory(
                record?.g_accrual_source!,
                record?.id!
              )
            }}
            modalTitle={'History'}
            overrideMainButton={
              <Space style={{ marginLeft: 13, marginTop: 4, marginBottom: 4 }}>
                <HistoryOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                <span>History Log</span>
              </Space>
            }
            columns={historyColumns}
          />
        )}
      </div>
    )
  }

  const handleGetActionItems = (record: TransactionLine) => {
    const data = record.line_properties
    const actions: MenuItemType[] = []
    if (data && data.actions) {
      actions.push(
        {
          label: 'Passback',
          key: TxnLineLevelActions.PASSBACK,
          disabled:
            !data.actions.passback.enabled ||
            !RP.USER_HAS_TXN_LINE_PASSBACK_PERMISSION,
          icon: <GIcon icon={faReply} />
        },
        {
          label: 'Override',
          key: TxnLineLevelActions.OVERRIDE,
          disabled:
            !data.actions.override.enabled ||
            !RP.USER_HAS_TXN_LINE_OVERRIDE_PERMISSION,
          icon: <GIcon icon={faClone} />
        },
        {
          label: 'Copy Line',
          key: TxnLineLevelActions.COPY_LINE,
          disabled:
            !data.actions.copy.enabled ||
            !RP.USER_HAS_TXN_LINE_COPY_LINE_PERMISSION,
          icon: (
            <CopyOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        },
        {
          label: 'Delete Line',
          key: TxnLineLevelActions.DELETE_LINE,
          disabled:
            !data.actions.delete.enabled ||
            !RP.USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION,
          icon: (
            <DeleteOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        },
        {
          label: 'History Log',
          key: TxnLineLevelActions.HISTORY_LOG,
          disabled:
            !data.actions.history.enabled ||
            !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION,
          icon: (
            <HistoryOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          )
        }
      )
    }

    if (
      moment(record?.g_period).isBefore(
        moment(
          (txnGlobalSettings as any)['transaction.history.cutoff_date'],
          'YYYY-MM-DD'
        )
      )
    )
      // OLD HISTORY
      actions.push({
        label: 'History Log',
        key: TxnLineLevelActions.HISTORY_LOG,
        disabled:
          !(record as any).actions?.history?.enabled ||
          !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION,
        icon: (
          <HistoryOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        )
      })
    // NEW HISTORY
    else
      actions.push({
        label: (
          <GlobalHistoryModal
            reloadData={() => {
              return getTransactionHistory(
                record?.g_accrual_source!,
                record?.id!
              )
            }}
            modalTitle={'History'}
            overrideMainButton={
              <>
                <HistoryOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                History Log
              </>
            }
            columns={historyColumns}
          />
        ),
        key: TxnLineLevelActions.NEW_HISTORY_LOG,
        disabled:
          !(record as any).actions?.history?.enabled ||
          !RP.USER_HAS_TXN_LINE_HISTORY_PERMISSION
      })
    return actions
  }

  const handleActionOnclick = async (record: TransactionLine, item: string) => {
    const action = record.line_properties && record.line_properties.actions
    if (item === TxnLineLevelActions.PASSBACK) {
      showPassbackModal(
        action?.passback.url!,
        record.g_name!,
        record.g_email!,
        record.id!
      )
    } else if (item === TxnLineLevelActions.COPY_LINE) {
      try {
        setIsLoading(true)
        const payload = {
          app_key: action?.copy.payload.app_key,
          line_id: action?.copy.payload.line_id,
          transaction_id: action?.copy.payload.transaction_id
        }

        await callPostApi(action?.copy.url!, payload)
        await getTransactionLinesList({
          page: currentPage,
          paginate_count: pageSize,
          ...columnFilter
        })
        message.success('Record was successfully copied')
      } catch (error) {
        setIsLoading(false)
        const { response } = error as AxiosError
        console.log(response)
        message.error('Something went wrong')
      }
    } else if (item === TxnLineLevelActions.DELETE_LINE) {
      try {
        setIsLoading(true)
        const dataList = [...transactionLines]
        const indexOfDataToBeRemove = transactionLines.indexOf(record)

        const response = await callDeleteApi(
          action?.delete.url!,
          action?.delete.payload!
        )

        if (indexOfDataToBeRemove > -1 && response.data) {
          dataList.splice(indexOfDataToBeRemove, 1)
          setTransactionLines(dataList)
          message.success('Record was successfully removed')
          setIsLoading(false)
        } else {
          setIsLoading(false)
          message.error('Something went wrong')
        }
      } catch (error) {
        setIsLoading(false)
        const { response } = error as AxiosError
        console.log(response)
        message.error('Something went wrong')
      }
    } else if (item === TxnLineLevelActions.HISTORY_LOG) {
      callGetApi(action?.history.url!, action?.history.payload!).then((res) => {
        setHistoryModalVisible(true)
        setModalDelay(true)
        setHistoryData(res && res.data)
      })
    } else if (item === TxnLineLevelActions.OVERRIDE) {
      setOverrideData(record)
      setOverrideModalVisible(true)
      setModalDelay(true)
    }
  }

  const handleHistoryModalCancel = () => {
    setHistoryModalVisible(false)
    setTimeout(() => {
      setModalDelay(false)
    }, 1000)
  }

  const isLineOriginal = (status: number): boolean => {
    return [
      TxnLineSourceEnum.ORIGINAL.id,
      TxnLineSourceEnum.SYSTEM_ADDED.id,
      TxnLineSourceEnum.USER_ADDED.id
    ].includes(status)
  }

  const handleOverrideModalCancel = () => {
    setOverrideModalVisible(false)
    setTimeout(() => {
      setModalDelay(false)
    }, 1000)
  }

  const getLinePropertyActionDetails = (
    id: any,
    action:
      | 'copy'
      | 'delete'
      | 'history'
      | 'lookup'
      | 'override'
      | 'passback'
      | 'qje'
  ) => {
    return transactionLines.find((txnLine: any) => txnLine.id === id)
      ?.line_properties?.actions[action]
  }

  const handleCreateQje = () => {
    if (selectedRows.length > 0) {
      setIsConfirmCreateQjeModalVisible(true)
    } else {
      Modal.info({
        title: 'Please select transaction first',
        centered: true
      })
    }
  }

  const createQje = async (): Promise<void> => {
    setIsConfirmCreateQjeModalVisible(false)
    setIsProcessingCreateQjeModalVisible(true)
    const errors: string[] = []

    for (const item of selectedRows) {
      setProcessedQjeCount((previousCount) => previousCount + 1)
      const payload = {
        app: {
          ...transactionProperties?.actions.create_qje?.payload.app!
        },
        transaction_id: transaction?.id!,
        line_ids: [item]
      }
      try {
        await qjeStore(payload)
        setSuccessQjeCount((previousCount) => previousCount + 1)
      } catch (error: any) {
        // const e: string[] = error.response.data.invalid || error.response.data
        const e: string[] =
          Object.values(error.response.data) || error.response.data
        e.forEach((item) => errors.push(item))
        setFailedQjeCount((previousCount) => previousCount + 1)
      }
    }

    setIsProcessingCreateQjeModalVisible(false)
    setIsSuccessCreateQjeModalVisible(true)
    setCreateQjeErrors(errors)
  }

  const handleOnSaveFilter = async (values: any, parameters: any) => {
    try {
      if (parameters.length > 0) {
        const { data } = await saveFilter({
          ...values,
          form_id: transaction?.form_id,
          parameters
        })
        if (data) {
          message.success('New filter successfully added')
          setShowSaveFilterModal(false)
          fetchSavedFilters(transaction?.form_id!)
        }
      } else {
        message.error('The parameters field is required.')
        setShowSaveFilterModal(false)
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  const handleDeleteFilterOption = async (item: any, form: any) => {
    deleteSavedFiltersById(item.key).then((res) => {
      fetchSavedFilters(transaction?.form_id!)
      message.success('Filter has been deleted')
      if (filterkey === item.key) {
        form.resetFields()
        getTransactionLinesList({
          page: 1,
          paginate_count: pageSize
        })
      }
    })
  }

  const handleClickOption = async (item: any, form: any) => {
    if (item.key === 'save') {
      setShowSaveFilterModal(true)
    } else if (
      item.key === 100000 ||
      item.key === 100001 ||
      item.key === 100002 ||
      item.key === 100003
    ) {
      let reqParams = {
        ...item.parameters[0]
      }
      form.resetFields()
      setColumnFilter(reqParams)
      getTransactionLinesList(reqParams)
    } else {
      form.resetFields()
      setFilterKey(item.key)
      let parameters = item.parameters
      let defaultValues: any = {}

      if (!Array.isArray(parameters)) {
        parameters = []
        Object.entries(item.parameters).forEach((data) => {
          parameters.push({
            [data[0]]: [data[1]]
          })
        })
      }

      parameters.forEach((item: any) => {
        const key: string = Object.keys(item)[0]
        const value = item[key]
        let apiKey = getApiName(key)

        defaultValues[apiKey] = value[0]
      })

      let newValues = defaultValues
      let reqParams = defaultValues
      Object.keys(defaultValues).forEach((key) => {
        if (
          key === 'g_created_at' ||
          key === 'g_date_submitted' ||
          key === 'g_due_date' ||
          key === 'g_period'
        ) {
          if (defaultValues[key] !== null) {
            //for column filter values
            newValues = {
              ...defaultValues,
              [key]: [
                moment(defaultValues[key][0]),
                moment(defaultValues[key][1])
              ]
            }
            //for request param values
            reqParams = {
              ...defaultValues,
              [key]: [
                moment(defaultValues[key][0], 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                ),
                moment(defaultValues[key][1], 'YYYY-MM-DD').format('YYYY-MM-DD')
              ]
            }
          }
        }
      })
      form.setFieldsValue(newValues)
      setColumnFilter(reqParams)
      getTransactionLinesList(reqParams)
    }
  }

  const getApiName = (label: string) => {
    let found = columns.find((item: any) => {
      if (item.label === label) {
        return true
      }

      return false
    })
    return found ? found.api_name : ''
  }

  const handleCancelFilterModal = () => {
    setShowSaveFilterModal(false)
  }

  const showConfirmVoidTxnModal = () => {
    Modal.confirm({
      centered: true,
      title: 'Void Transaction',
      icon: (
        <InfoCircleOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          style={{ color: '#1890ff' }}
        />
      ),
      content: (
        <>
          Please note that this action cannot be undone. Click 'Void' below to
          confirm your action.
        </>
      ),
      okText: 'Void',
      okType: 'danger',
      onOk: async () => {
        try {
          await callPutApi(
            transactionProperties?.actions.void_transaction?.url!
          )

          const { data: txn } = await getTransaction(id!)
          setTransaction(txn)

          const { data } = await getTransactionSettings(id!)
          setTransactionProperties(data.transaction_properties)
          getTransactionLinesList()
          message.success('Transaction has been voided')
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        }
      }
    })
  }

  const handleSuccessCreateQjeModalOnOk = () => {
    setIsSuccessCreateQjeModalVisible(false)
    setCreateQjeErrors([])
    setSelectedRows([])
    setSuccessQjeCount(0)
    setProcessedQjeCount(0)
    setFailedQjeCount(0)
    getTransactionLinesList({
      page: currentPage,
      paginate_count: pageSize,
      ...columnFilter
    })
  }

  return (
    <div
      className='txn-lines-container'
      data-testid='transaction-lines'
      data-cy='transaction-lines'
    >
      <div
        className='txn-parent-info'
        data-testid='transaction-lines-parent-info'
        data-cy='transaction-lines-parent-info'
      >
        <div
          data-testid='transaction-lines-parent-info-txn-name'
          data-cy='transaction-lines-parent-info-txn-name'
        >
          {transaction?.name}
        </div>
        <div
          data-testid='transaction-lines-parent-info-form-name'
          data-cy='transaction-lines-parent-info-form-name'
        >
          Form: {transaction?.form_name}
        </div>
        <div
          data-testid='transaction-lines-parent-info-status'
          data-cy='transaction-lines-parent-info-status'
        >
          Status: {TxnStatusEnum.getLabelById(transaction?.status!)}
        </div>
        <GButton
          dataTestId='transaction-lines-void-btn'
          dataCy='transaction-lines-void-btn'
          btnText='void'
          shape='default'
          onClick={showConfirmVoidTxnModal}
          disabled={
            !RP.USER_HAS_VOID_HEADER_TXN_PERMISSION ||
            !transactionProperties?.actions.void_transaction?.enabled
          }
        />
      </div>
      <GTransactionLinesActions
        formName={transaction?.form_name!}
        transactionId={id}
        txnNumber={transaction?.transaction_number!}
        onAddMoreClick={showAddMoreModal}
        onNudgeAllClick={handleOnNudgeAllClick}
        onExportClick={handleExportClick}
        onNudgeSelectedClick={handleOnNudgeSelectedClick}
        onCreatQjeClick={handleCreateQje}
        setAttachmentsList={setAttachments}
        hasAttachments={attachments.length > 0}
        isNudgeAllEnable={transactionProperties?.actions.nudge_all.enabled}
        isNudgeSelectedEnable={
          transactionProperties?.actions.nudge_selected.enabled
        }
        isAddMoreEnable={transactionProperties?.actions.add_more.enabled}
        isAddLineEnable={transactionProperties?.actions.add_line.enabled}
        isCreateQjeEnable={transactionProperties?.actions.create_qje.enabled}
        appType={transaction?.app!}
        isExportLoading={isExportLoading}
      />
      <GDynamicTable
        columnHeader={columns}
        dataSource={transactionLines}
        loading={isLoading}
        pageSize={pageSize}
        totalItems={totalItems}
        showHeader={showHeader}
        onChange={handleTableChange}
        getSelectAllCheckbox={handleGetSelectAllCheckbox}
        getLineCheckboxes={handleGetLineCheckbox}
        selectedRowsCount={selectedRows.length}
        getActions={handleGetActions}
        getActionItems={handleGetActionItems}
        onFilterChange={handleFilterChange}
        optionsList={optionsList}
        onClickReset={() => {
          getTransactionLinesList({
            page: 1,
            paginate_count: pageSize
          })
        }}
        hasActionColumns
        hasColumnFilters
        hasSelectAll
        hasSaveFilter
        saveFilterOptions={saveFilterOptions}
        onSaveFilter={handleOnSaveFilter}
        showSaveModal={showSaveFilterModal}
        onClickSaveOptions={handleClickOption}
        onCancelFilterModal={handleCancelFilterModal}
        onClickDeleteOptions={handleDeleteFilterOption}
        dateTimeFormat={dateTimeFormat}
        current={currentPage}
        isServerSideSort={true}
      />

      <AttachmentsModal
        visible={showAttachModal}
        list={lineAttachments}
        title={vendorName}
        handleClose={() => {
          setShowAttachModal(false)
        }}
      />
      <TxnLinesActionTabsPanel
        transactionId={id}
        historyApi={transactionProperties?.actions?.get_history}
        getCommentApi={transactionProperties?.actions?.get_comments}
        commentActionsApi={transactionProperties?.actions?.comments}
        trackers={trackers}
        setAttachmentsList={setAttachments}
        dateTimeFormat={dateTimeFormat}
        period=''
        tsdPeriod={transaction?.period}
      />
      <TxnLinePassbackModal
        visible={isPassbackModalVisible}
        setModalVisible={setIsPassbackModalVisible}
        vendorName={passbackVendorName}
        email={passbackEmail}
        url={passbackUrl}
        lineId={txnLineId}
        transactionId={id}
        onSuccess={handlePassbackOnSuccess}
        onError={handlePassbackOnError}
      />
      <TxnLineNudgeAllModal
        setModalVisible={setIsNudgeAllModalVisible}
        transactionId={id}
        visible={isNudgeAllModalVisible}
        url={transactionProperties?.actions.nudge_all?.url!}
        onSuccess={handleNudgeAllOnSuccess}
        onError={handleNudgeAllOnError}
      />
      <AddMoreModal
        transactionId={id!}
        showModal={isAddMoreModalVisible}
        onCancel={handleCancelAddMore}
        setIsAddMoreModalVisible={setIsAddMoreModalVisible}
        getTransactionLinesList={getTransactionLinesList}
        pageSize={pageSize!}
        formFields={formFields?.add_form_fields}
        isVisible={isAddMoreModalVisible}
        thresholdAmount={thresholdAmount}
        displayThresholdSupport={displayThresholdSupport}
        reportingCurrency={reportingCurrency}
        period={transaction?.period}
        appType={transaction?.app}
      />

      <TxnLineLookupModal
        data={txnLineLookUpData}
        visible={isTxnLineLookupModalVisible}
        setModalVisible={setIsTxnLineLookupModalVisible}
        setData={setTxnLineLookUpData}
        loading={isTxnLineLookupModalLoading}
        invoiceDetailsRange={invoiceDetailsRange}
        lookUpApi={lookUpApi}
      />

      <TxnLineNudgeSelectedModal
        visible={isNudgeSelectedModalVisible}
        setModalVisible={setIsNudgeSelectedModalVisible}
        onSuccess={handleOnNudgeSelectedSuccess}
        onError={handleOnNudgeSelectedError}
        nudgeSelected={transactionProperties?.actions.nudge_selected!}
      />
      {showDelay && (
        <TxnLinesHistoryModal
          showModal={showHistory}
          historyData={historyData}
          onCancel={handleHistoryModalCancel}
          labelList={columns}
          dateTimeFormat={dateTimeFormat}
        />
      )}
      {showDelay && (
        <OverrideModal
          transactionId={id!}
          showModal={showOverride}
          onCancel={handleOverrideModalCancel}
          transaction={overrideData}
          getLinePropertyActionDetails={getLinePropertyActionDetails}
          setShowOverride={setOverrideModalVisible}
          getTransactionLinesList={getTransactionLinesList}
          pageSize={pageSize!}
          formFields={formFields?.override_form_fields}
          isVisible={showOverride}
          thresholdAmount={thresholdAmount}
          displayThresholdSupport={displayThresholdSupport}
          reportingCurrency={reportingCurrency}
          filters={columnFilter}
          currentPage={currentPage}
        />
      )}
      <ConfirmCreateQjeModal
        visible={isConfirmCreateQjeModalVisible}
        setModalVisible={setIsConfirmCreateQjeModalVisible}
        onOk={createQje}
      />
      <ProcessingCreateQjeModal
        visible={isProcessingCreateQjeModalVisible}
        proccessedQjeItems={processedQjeCount}
        totalQjeItems={selectedRows.length}
      />
      <TxnLineCalculationModal
        visible={isCalculationModalVisible}
        calculationUrl={calculationUrl}
        setModalVisible={setIsCalculationModalVisible}
      />
      <SuccessCreateQjeModal
        visible={isSuccessCreateQjeModalVisible}
        successQjeCount={successQjeCount}
        failedQjeCount={failedQjeCount}
        onOk={handleSuccessCreateQjeModalOnOk}
        createQjeErrors={createQjeErrors}
      />
    </div>
  )
}

export default TransactionLines
