import { useEffect, useState } from 'react'

// ** AntD Imports
import { Button, Dropdown, Menu, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import useAdditionalOptionItems from './item'
import GButton from '../../../../components/gappify/GButton'
import EmailVendor from '../Modals/EmailVendor'
import ExecuteActionModal from '../Modals/ExecuteActionModal'

// ** Style Imports
import styles from '../../styles/additional-options.module.css'

// ** Third Party Imports
import moment from 'moment'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// ** Hooks Imports
import useAnalytics from '../../hooks/useAnalytics'
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'

// =================================================================
const AdditionalOptions = ({
  showAdditionalOptions = true,
  showEmailButton = true,
  showActionButton = true,
  hasShowCount = true,
  hasShowNonResponsiveVendors = true,
  hasShow12Months = true
}: {
  showAdditionalOptions?: boolean
  showEmailButton?: boolean
  showActionButton?: boolean
  hasShowCount?: boolean
  hasShowNonResponsiveVendors?: boolean
  hasShow12Months?: boolean
}) => {
  // ** State **
  const [openModal, setOpenModal] = useState(false)
  const [modalType, setModalType] = useState<string>('')
  const [openDropdown, setOpenDropdown] = useState(false)
  const [btnTitle, setBtnTitle] = useState('')

  // ** Custom Hooks (Dropdown menu items) **
  const { AdditionalOptionsItems } = useAdditionalOptionItems({
    hasShowCount,
    hasShowNonResponsiveVendors,
    hasShow12Months
  })

  // ** Store **
  const {
    selectedRows,
    setShowEmailVendor,
    selectedItem,
    period,
    nonCompliantSelectedRowKeys,
    increasingSpendSelectedRowKeys,
    increasingSpendHeader,
    title
  } = useAnalyticsStore()

  // ** Hooks **
  const RP = useRolesAndPermissions()
  const { getTableSpendCompliance } = useAnalytics({ responseType: undefined })
  const { data } = getTableSpendCompliance

  const handleOpenChange = (flag: boolean) => {
    setOpenDropdown(flag)
  }

  useEffect(() => {
    const subtitle = title === 'Internal' ? ' Partner' : ''
    setBtnTitle(`Email ${title}${subtitle}`)
  }, [title])

  return (
    <div
      className={styles.container}
      style={{
        justifyContent: showActionButton ? 'flex-end' : 'space-between'
      }}
    >
      {showAdditionalOptions && (
        <Dropdown
          overlay={<Menu items={AdditionalOptionsItems} />}
          trigger={['click']}
          // Prevents selection of menu items and closing of dropdown
          onOpenChange={handleOpenChange}
          open={openDropdown}
        >
          <Button
            icon={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            className={styles.button}
            shape='round'
          >
            Additional Options
          </Button>
        </Dropdown>
      )}

      {/* Disable temporarily for future use */}
      {false && (
        <Typography className={styles.periodCovered}>
          {(() => {
            /**
             * Returns the selected month based on the given period and data.
             * If period is truthy, it returns the moment object of the period.
             * Otherwise, it returns the moment object of the first key in data.period_drop_down.
             * @returns {moment.Moment | undefined} The selected month as a moment object, or undefined if data.period_drop_down is falsy.
             */
            const selectedMonth = period
              ? moment(period)
              : data?.period_drop_down &&
                moment(Object.keys(data.period_drop_down)[0])

            if (!selectedMonth) return null

            const firstRangeEnd = selectedMonth.format('MMMM YYYY')
            const firstRangeStart = selectedMonth
              .clone()
              .subtract(2, 'months')
              .format('MMMM YYYY')

            const secondRangeEnd = selectedMonth
              .clone()
              .subtract(3, 'months')
              .format('MMMM YYYY')
            const secondRangeStart = selectedMonth
              .clone()
              .subtract(5, 'months')
              .format('MMMM YYYY')

            return `(${firstRangeEnd} - ${firstRangeStart}) vs. (${secondRangeEnd} - ${secondRangeStart})`
          })()}
        </Typography>
      )}

      {showEmailButton && (
        <GButton
          btnText={btnTitle}
          shape='default'
          onClick={() => {
            setOpenModal(true)
            setShowEmailVendor(true)
          }}
          disabled={
            !RP.USER_HAS_UPDATE_ACCRUAL_MANAGER_AA_PERMISSION ||
            !!!selectedRows.length
          }
        />
      )}

      {selectedItem === 'Rising Expenses' && (
        <Typography className={styles.periodCovered}>
          {increasingSpendHeader}
        </Typography>
      )}

      {showActionButton && (
        <>
          <GButton
            btnText='Execute Action'
            shape='default'
            onClick={() => {
              setModalType('execute-action')
              setOpenModal(true)
            }}
            disabled={
              !RP.USER_HAS_UPDATE_ACCRUAL_MANAGER_AA_PERMISSION ||
              (!increasingSpendSelectedRowKeys.length &&
                !nonCompliantSelectedRowKeys.length)
            }
          />
          <ExecuteActionModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            modalType={modalType}
          />
        </>
      )}

      {/* Email Vendor modal */}
      {showEmailButton && (
        <EmailVendor
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalType=''
        />
      )}
    </div>
  )
}

export default AdditionalOptions
