import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

type Props = {
  columns: ColumnsType<any> | undefined
  data: readonly any[] | undefined
}

const ReviewCenterTable = (props: Props) => {
  const { columns, data } = props
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      size='small'
      scroll={{ x: 'calc(700px + 50%)', y: 'calc(100vh - 250px)' }}
      className='tbl-review-center'
      data-testid='tbl-review-center'
    />
  )
}

export default ReviewCenterTable
