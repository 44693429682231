import { FC } from 'react'
import GDynamicTable from '../components/gappify/GDynamicTable'
import { transColumns, transData } from '../utils/data'

const SuperGridPage: FC = (): JSX.Element => {
  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    console.log('onchange', pagination, filter, sorter)
    let order = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    let params = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort: `${sorter.field}, ${order}`
    }

    console.log('params', params)
  }
  return (
    <div className='txn-manager-container'>
      <GDynamicTable
        columnHeader={transColumns}
        dataSource={transData}
        hasColumnFilters={false}
        hasTransactionPanel
        hasGlobalFilter
        hasSelectAll
        isDraggableSort={false}
        loading={false}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default SuperGridPage
