// ** Third Party Imports
import { useQueryClient } from '@tanstack/react-query'

// ** Zustand Imports
import useAnalyticsStore from '../../manager/analytics-store'

// =================================================================
export default function useHistoryLogData() {
  // ** Store **
  const { permNoteVendorId } = useAnalyticsStore()

  // ** TanStack Query **
  const queryClient = useQueryClient()
  const historyLogData: Record<string, []> =
    queryClient.getQueryData([`history-log`, permNoteVendorId]) || {}

  const columns = [
    {
      title: 'User',
      dataIndex: 'Name',
      key: 'Name',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Date/Time',
      dataIndex: 'Date/Time',
      key: 'Date/Time',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Field',
      dataIndex: 'Field',
      key: 'Field',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Previous Value',
      dataIndex: 'old',
      key: 'old',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Current Value',
      dataIndex: 'new',
      key: 'new',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    },
    {
      title: 'CC Email',
      dataIndex: 'cc_email',
      key: 'cc_email',
      width: '100px',
      render: (text: string, record: any) => {
        return <div style={{ paddingLeft: 10 }}>{text}</div>
      }
    }
  ]

  const rowsFromBackend =
    (historyLogData?.data &&
      historyLogData?.data.length > 0 &&
      historyLogData?.data.map(
        (item: Record<string, string>, index: number) => ({
          key: (index + 1).toString(),
          Name: item.Name,
          'Date/Time': item['Date/Time'],
          Action: item.Action,
          Field: item.Field,
          old: item.old,
          new: item.new,
          Email: item.email ? item.email : '',
          cc_email: item.cc_email ? item.cc_email : ''
        })
      )) ||
    []

  return {
    columns,
    rowsFromBackend
  }
}
