/** tanstack imports */
import { useMutation, useQueryClient } from '@tanstack/react-query'

/** services imports */
import useApiService from '../../../../../hooks/useApiService'
import useApiKeyService from '../services/useApiKeyService'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'

/** types imports */
import CreateApiTokenPayload from '../types/CreateApiTokenPayload'
import CreateApiTokenReponse from '../types/CreateApiTokenReponse'

// =================================================================
export default function useCreateTokenApi() {
  /** services */
  const { apiKeyManagerAPI } = useApiKeyService()
  const { msUrl } = useApiService()

  /** zustand stores */
  const {
    setIsGenerateApiKeyModalOpen,
    setIsTokenModalOpen,
    setApiKey,
    setPage,
    setSortOrder
  } = useApiKeyStore()

  /** hooks */
  const queryClient = useQueryClient()

  const { mutate: createTokenApi, isLoading } = useMutation(apiKeyManagerAPI, {
    onSuccess: (response: CreateApiTokenReponse) => {
      setPage(1)
      setSortOrder('desc')
      queryClient.invalidateQueries(['api-keys'])
      setIsGenerateApiKeyModalOpen(false)
      setIsTokenModalOpen(true)
      setApiKey(response.data.token)
    },
    onError: (error) => console.log(error)
  })

  const handleSubmit = (data: CreateApiTokenPayload) => {
    createTokenApi({
      options: {
        baseURL: msUrl.idms,
        endpoint: '/api/api-manager',
        method: 'POST'
      },
      data
    })
  }

  return { handleSubmit, isLoading }
}
