// ** AntD Imports
import { Form, Table } from 'antd'

// ** API Services Imports
import useAnalytics from '../../../../hooks/useAnalytics'

// ** Data Imports
import usePermNotesData from '../../../../mock/ActionModalsData/PermNotesData'

// ** Zustand Imports
import useAnalyticsStore from '../../../../manager/analytics-store'

// =================================================================
const PermNotes = () => {
  // ** AntD **
  const [form] = Form.useForm()

  // ** Data **
  const { columns, rowsFromBackend } = usePermNotesData()

  // ** Store **
  const { responseType } = useAnalyticsStore()

  // ** API Services **
  const { getPermNotes } = useAnalytics({ responseType: responseType })
  const { isRefetching, isLoading } = getPermNotes

  return (
    <Form form={form} component={false}>
      <Table
        dataSource={rowsFromBackend}
        columns={columns}
        pagination={false}
        loading={isRefetching || isLoading}
      />
    </Form>
  )
}

export default PermNotes
