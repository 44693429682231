import { useEffect, useRef, useState } from 'react'

// ** AntD Imports
import { Input, Modal, Popconfirm, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

// ** Type Imports
import AnalyticsModalProps from '../../../types/Modal'

// ** Style Imports
import styles from '../../../styles/email-vendor.module.css'

// ** Zustand Imports
import useAnalyticsStore from '../../../manager/analytics-store'
import GButton from '../../../../../components/gappify/GButton'
import Vendors from './contents/Vendors'

// ** 3rd Party Imports
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import useAnalytics from '../../../hooks/useAnalytics'

const success = () => {
  message.success('Email sent successfully!')
}

const error = () => {
  message.error('Something went wrong when sending email!')
}

// =================================================================
const EmailVendor = ({
  openModal,
  setOpenModal,
  modalType
}: AnalyticsModalProps) => {
  // ** States **
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [openModalPreview, setOpenModalPreview] = useState(false)

  // ** States **
  const closeBtn = useRef<HTMLSpanElement>(null)

  // ** Store **
  const {
    setShowEmailVendor,
    additionalEmails,
    selectedRowsToEmail,
    setSelectedRowsToEmail,
    setSelectedRowsToEmailKeys,
    responseType,
    title,
    emailTemplate
  } = useAnalyticsStore()

  // ** API Services **
  const {
    handleSubmitEmail,
    postEmailLoading,
    postEmailSuccess,
    postEmailError
  } = useAnalytics({
    responseType: responseType
  })

  // React Quill custom toolbar options
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // heading text sizes

    ['bold', 'italic', 'underline'], // toggled buttons

    [{ list: 'ordered' }, { list: 'bullet' }], // bullets, numbered

    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme

    [{ align: [] }] // left, center, right alignments
  ]
  const modules = {
    toolbar: toolbarOptions
  }

  const subtitle = title === 'Internal' ? ' Partner' : ''
  const reportTitle = `Email ${title}${subtitle}`
  const content = <Vendors />

  const formatBodyAlignment = (bodyTemp: string) => {
    let finalBody = bodyTemp
      .replace('class="ql-align-center"', 'style="text-align:center;"')
      .replace('class="ql-align-right"', 'style="text-align:right;"')

    return finalBody
  }

  useEffect(() => {
    if (postEmailSuccess) {
      success()
      setSubject('')
      setBody('')
      setSelectedRowsToEmail([])
      setSelectedRowsToEmailKeys([])

      // Reset additional email fields
      let newRowsToEmail = [...selectedRowsToEmail]
      newRowsToEmail.forEach((value) => {
        delete value.key
      })
    }

    if (postEmailError) error()
    // eslint-disable-next-line
  }, [postEmailSuccess, postEmailError])

  useEffect(() => {
    if (emailTemplate) {
      /**
       * ! BUG WORKAROUND
       * * Issue with AntD modal & React Quill when rendering initial value using state
       * * Solution: Add a slight delay when setting new value of the state
       * ? Reference: https://github.com/zenoamaro/react-quill/issues/814#issuecomment-1625386985
       */
      setTimeout(() => {
        setBody(emailTemplate.body)
        setSubject(emailTemplate.subject)
      }, 100)
    }

    // eslint-disable-next-line
  }, [openModal])

  return (
    <Modal
      title={
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {reportTitle}
        </div>
      }
      centered
      destroyOnClose={true}
      open={openModal}
      onCancel={() => {
        closeBtn.current?.click()
      }}
      closeIcon={
        <Popconfirm
          title='Are you sure you want to cancel?'
          onConfirm={() => {
            setOpenModal(false)
            setShowEmailVendor(false)
            setSelectedRowsToEmailKeys([])
            setSubject('')
            setBody('')
          }}
          okText='Yes'
          cancelText='No'
        >
          <CloseOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} ref={closeBtn} />
        </Popconfirm>
      }
      width={2000}
      bodyStyle={{ maxHeight: '50vh' }}
      footer={
        <>
          <GButton
            btnText='Preview'
            shape='default'
            onClick={() => setOpenModalPreview(true)}
            style={{ width: 100 }}
            disabled={
              !subject.trim().length ||
              !body.trim().length ||
              !selectedRowsToEmail.length ||
              postEmailLoading
            }
          />
          <GButton
            btnText='Email'
            shape='default'
            onClick={() => {
              let newRowsToEmail = [...selectedRowsToEmail]
              newRowsToEmail.forEach((value) => {
                const index = Number(value.key - 1)
                value.additional_email = additionalEmails[index]
              })

              const newData = {
                subject,
                body: formatBodyAlignment(body),
                data: newRowsToEmail
              }

              handleSubmitEmail({ emailData: newData })
            }}
            style={{ width: 100 }}
            disabled={
              !subject.trim().length ||
              !body.trim().length ||
              !selectedRowsToEmail.length ||
              postEmailLoading
            }
          />
        </>
      }
    >
      {content}
      <Input
        className={styles.input}
        placeholder='Email Subject Line'
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <ReactQuill
        className={styles.textEditor}
        theme='snow'
        modules={modules}
        value={body}
        onChange={setBody}
        style={{ height: '250px' }}
      />

      {/* Preview modal */}
      <Modal
        title={
          <div
            style={{
              textAlign: 'center'
            }}
          >
            Preview Email
          </div>
        }
        centered
        destroyOnClose={true}
        onCancel={() => {
          setOpenModalPreview(false)
        }}
        open={openModalPreview}
        footer={<></>}
        width={1000}
      >
        <p>
          <strong>Subject:</strong> {subject}
        </p>
        <p>
          <strong>Body:</strong>
        </p>
        <div
          className='ql-editor'
          style={{ padding: 0 }}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </Modal>
    </Modal>
  )
}

export default EmailVendor
