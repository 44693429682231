import { useQuery } from '@tanstack/react-query'
import useApiService from 'src/hooks/useApiService'
import useGlobalService from 'src/services/globalService'

const useFetchSyncDetails = () => {
  /** API service */
  const { globalServiceApi } = useGlobalService()
  const { msUrl } = useApiService()

  return useQuery({
    queryKey: ['get-sync-details'],
    queryFn: () =>
      globalServiceApi({
        params: {},
        data: {},
        options: {
          baseURL: msUrl.taskmanager,
          endpoint: '/api/sync-all/statistics',
          method: 'GET'
        }
      }),
    enabled: false
  })
}

export default useFetchSyncDetails
