import React from 'react'
import ReviewCenterHelper from '../../helpers/review-center-helper'
import { Tooltip } from 'antd'
const SubTotal: React.FC<any> = ({
  subtotalLabel,
  values,
  fields,
  tier,
  stickycol,
  colspan,
  border,
  methodName,
  isLastSubtotal
}) => {
  const vendorSubTotal = (
    data: any,
    key: string,
    is_format_currency: boolean = true
  ) => {
    //calculate and return sub-total by vendors
    var sum = 0.0
    for (let vendor_id in data) {
      sum += +ReviewCenterHelper.getDefaultValue(data[vendor_id], key)
    }
    return is_format_currency
      ? ReviewCenterHelper.formatCurrency(sum)
      : ReviewCenterHelper.formatAmount(sum)
  }

  const departmentGlSubTotal = (
    data: any,
    key: string,
    is_format_currency: boolean = true
  ) => {
    //calculate and return sub-total by GL/Department
    var sum = 0.0
    for (let id in data) {
      sum += +vendorSubTotal(data[id]['vendor'], key, false)
    }
    return is_format_currency
      ? ReviewCenterHelper.formatCurrency(sum)
      : ReviewCenterHelper.formatAmount(sum)
  }

  const subsidiaryDepartmentSubTotal = (
    data: any,
    key: string,
    is_format_currency: boolean = true
  ) => {
    //calculate and return sub-total by department, used for Subsidiary > Department > GL > Vendor view
    var sum = 0.0
    for (let department_id in data) {
      sum += +departmentGlSubTotal(data[department_id]['gl'], key, false)
    }
    return is_format_currency
      ? ReviewCenterHelper.formatCurrency(sum)
      : ReviewCenterHelper.formatAmount(sum)
  }

  const subsidiaryGlSubTotal = (
    data: any,
    key: string,
    is_format_currency: boolean = true
  ) => {
    //calculate and return sub-total by GL, used for Subsidiary > GL > Department > Vendor view
    var sum = 0.0
    for (let gl_id in data) {
      sum += +departmentGlSubTotal(data[gl_id]['department'], key, false)
    }
    return is_format_currency
      ? ReviewCenterHelper.formatCurrency(sum)
      : ReviewCenterHelper.formatAmount(sum)
  }

  const td =
    fields &&
    Object.entries(fields).map(([key, columns]: any) =>
      columns.map((v: any, i: any) => (
        <td
          className={`border-t ${isLastSubtotal ? ' ' : ' border-b '} ${
            i === columns.length - 1 ? ' border-r ' : ' '
          }`}
          key={i}
        >
          {methodName === 'vendorSubTotal' && vendorSubTotal(values, v.key)}
          {methodName === 'departmentGlSubTotal' &&
            departmentGlSubTotal(values, v.key)}
          {methodName === 'subsidiaryDepartmentSubTotal' &&
            subsidiaryDepartmentSubTotal(values, v.key)}
          {methodName === 'subsidiaryGlSubTotal' &&
            subsidiaryGlSubTotal(values, v.key)}
        </td>
      ))
    )

  return (
    <tr className={`sub-total-tier-${tier}`}>
      <td
        className={`${border} font-semibold sticky-col sticky-col-${stickycol}`}
        colSpan={colspan}
        style={{
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <strong>Subtotal 
          { subtotalLabel && 
            <Tooltip title={subtotalLabel} placement='topLeft'>
            &nbsp;- {subtotalLabel}
            </Tooltip>
          }  
        
        </strong>
      </td>
      {td}
    </tr>
  )
}

export default SubTotal
