//  ** Antd Imports
import { message, Modal } from 'antd'
import useScheduleManager from '../../../hooks/useScheduleManager'

// ** Hooks Imports
import useScheduleStore from '../../../manager/schedule-store'

const ConfirmEditModal = () => {
  //  ** Global **
  const {
    openConfirmEditModal,
    setOpenConfirmEditModal,
    confirmLoading,
    setConfirmLoading,
    setEditStandard,
    standardEditPayload,
    setStandardEditPayload
  } = useScheduleStore()

  // ** Hooks **
  const { handleSubmit } = useScheduleManager()

  // ** Functions **
  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      await handleSubmit(
        standardEditPayload,
        '/schedule-manager/schedule-standard-update'
      )
      message.success('All changes have been applied.')
      setOpenConfirmEditModal(false)
      setEditStandard(false)
      setStandardEditPayload([])
    } catch (e) {
      console.log('Error', e)
      message.error('An error occurred.')
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setOpenConfirmEditModal(false)
  }

  // =================================================================
  return (
    <Modal
      title='Are you sure you want to save the changes?'
      open={openConfirmEditModal}
      onOk={handleOk}
      okText={'Save'}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>
        Once you click the Save button, any changes made to the Standard
        Schedule will be applied only to future Monthly Schedules.
      </p>
    </Modal>
  )
}

export default ConfirmEditModal
