export type TEmailTemplate = {
  subject: string
  body: string
}

export enum EReportTypes {
  ResponseRatesVendor = 'response-rates-vendor',
  ResponseRatesInternal = 'response-rates-internal',
  RisingExpenses = 'rising-expenses',
  POCDepartment = 'po-compliance-department',
  POCEmployee = 'po-compliance-employee',
  POCNonCompliant = 'po-compliance-non-compliant'
}
