import { FC } from 'react'
import { Modal } from 'antd'
import GButton from './gappify/GButton'
import { calldownloadFile } from '../services/GenericAPI'
import download from 'downloadjs'

import type { AxiosError } from 'axios'

interface AttachmentsModalProps {
  title: string
  list: { name: string; value: string }[]
  visible?: boolean
  handleClose?: () => void
}

const AttachmentsModal: FC<AttachmentsModalProps> = ({
  title,
  list,
  visible,
  handleClose
}): JSX.Element => {
  const downloadFile = async (fileName: string, url: string): Promise<void> => {
    try {
      const { data, headers } = await calldownloadFile(url)
      download(data, fileName, headers['content-type'])
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  return (
    <div className='okta-btn'>
      <Modal
        data-testid={`${title}-attachments`}
        data-cy={`${title}-attachments`}
        title={`${title} Attachments`}
        open={visible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleClose}
        closable={false}
        centered
      >
        {list &&
          list.map((item, index) => (
            <GButton
              dataTestId={`${title}-attachments-${item.name}-download-btn`}
              dataCy={`${title}-attachments-${item.name}-download-btn`}
              key={index}
              btnText={item.name}
              type='link'
              onClick={() => downloadFile(item.name, item.value)}
            />
          ))}
      </Modal>
    </div>
  )
}
export default AttachmentsModal
