import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import getActionItems from './getActionItems'
import { MenuOutlined } from '@ant-design/icons'
import AccrualRule from '../types/AccrualRule'
import { SetStateAction } from 'react'

const MAX_LENGTH = 110

export default function getAccrualRulesColumns(
  loadDataSource: () => Promise<void>,
  isEditMode: boolean,
  setModalVisible: (value: SetStateAction<boolean>) => void,
  setEditRecord: (value: SetStateAction<AccrualRule | undefined>) => void
): ColumnsType<AccrualRule> {
  const isMaxLength = (item: string) => {
    if (item.length >= MAX_LENGTH) {
      return true
    }
    return false
  }

  const accrualRulesColumns: ColumnsType<AccrualRule> = [
    {
      title: '',
      dataIndex: isEditMode ? 'sort' : 'actions',
      width: 30,
      render: (value, record) => {
        if (isEditMode)
          return <MenuOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} style={{ cursor: 'grab', color: '#999' }} />
        else
          return getActionItems(
            record,
            loadDataSource,
            setModalVisible,
            setEditRecord
          )
      }
    },
    {
      title: 'Order',
      dataIndex: 'weight',
      render: (value: number, record: any, index: number) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value: string, record) => {
        if (!value) {
          return `Test ${record.id}`
        }
        return value
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: boolean | number) => {
        if (value) {
          return 'Active'
        }
        return 'Inactive'
      }
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      width: 300,
      render: (value: string) => {
        return isMaxLength(value) ? (
          <Tooltip placement='topLeft' title={value}>
            <div className='ellipsis-div'>{value}</div>
          </Tooltip>
        ) : (
          value
        )
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (value: boolean | number) => {
        if (value) {
          return 'Yes'
        }
        return 'No'
      }
    },
    {
      title: 'Internal Accrual',
      dataIndex: 'internal_accrual',
      render: (value?: string) => {
        if (!value) {
          return 'None'
        }
        return value
      }
    },
    {
      title: 'External Accrual',
      dataIndex: 'external_accrual',
      render: (value?: string) => {
        if (!value) {
          return 'None'
        }
        return value
      }
    },
    {
      title: 'Calculation',
      dataIndex: 'calculation'
    },
    {
      title: 'Internal Form',
      dataIndex: 'internal_form_name'
    },
    {
      title: 'External Form',
      dataIndex: 'external_form_name'
    }
  ]

  accrualRulesColumns.push({
    title: 'Prepaid Account',
    dataIndex: 'prepaid_account'
  })

  return accrualRulesColumns
}
