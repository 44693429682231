import React, { useState } from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import {
  getTransactionData,
  getCalculatedAccrualData,
  getImportedAccrualData
} from '../../services/ReviewCenterAPI'
import TransactionModal from './modals/TransactionModal'
import CalculatedAccrualModal from './modals/CalculatedAccrualModal'
import ImportedAccrualModal from './modals/ImportedAccrualModal'
import { useGlobalStore } from '../../global/global-store'
import InternalAccrualModal from './modals/InternalAccrualModal'
import ManualAccrualModals from './modals/ManualAccrualModals'
import VendorAccrualModal from './modals/VendorAccrualModal'
import QuickActionsModal from './modals/QuickActionsModal'
import useTxnEnableFeatureStore from '../../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

const ReviewCenterTable: React.FC<any> = ({
  data,
  view,
  period,
  fields,
  loading,
  config,
  activeFilter
}) => {
  const {
    showImportedPopup,
    setShowImportedPopup,
    showCalculatedPopup,
    setShowCalculatedPopup,
    showInternalPopup,
    setShowInternalPopup,
    showManualPopup,
    setShowManualPopup,
    showVendorPopup,
    setShowVendorPopup,
    setSubdiaryIdParams,
    setDepartmentIdParams,
    setGlAccountIdParams,
    setVendorIdParams,
    setVendorNameParams,
    setPeriodParams,
    setTypeParams,
    setKeyParams,
    setViewParams,
    setFilterParams,
    setDisplay,
    setActiveKey,
    setHistoryData,
    isEditRowCalled,
    callReviewCenterReload,
    setCallReviewCenterReload,
    isCreateQjeCalled,
    showQuickActions,
    setShowQuickActions,
    setQuickActionsType,
    setRcUseNewHistoryTable
  } = useGlobalStore()
  const { consolidatedTxnView } = useTxnEnableFeatureStore()
  const [showTransactionsPopup, setShowTransactionsPopup] = useState(false)
  const [vendorName, setVendorName] = useState('')
  const [tablePopupData, setTablePopupData] = useState({})

  const isReportingCurrency = config.default_currency === 'Reporting'
  const currencyName = isReportingCurrency
    ? config.reporting_currency
    : 'Subsidiary'

  const clickHandler = (params: any) => {
    params['period'] = period
    params['view'] = view
    params['filter'] = activeFilter //ENG-9137 added filter onclick historicals
    if (activeFilter) params['preview_mode'] = false

    if (
      params['subsidiary_id'] === undefined ||
      params['subsidiary_id'] === null ||
      params['subsidiary_id'] === '' ||
      params['subsidiary_id'] === '00'
    ) {
      params['subsidiary_id'] = '0'
    }

    if (
      params['glaccount_id'] === undefined ||
      params['glaccount_id'] === null ||
      params['glaccount_id'] === '' ||
      params['glaccount_id'] === '00'
    ) {
      params['glaccount_id'] = '0'
    }

    if (
      params['department_id'] === undefined ||
      params['department_id'] === null ||
      params['department_id'] === '' ||
      params['department_id'] === '00'
    ) {
      params['department_id'] = '0'
    }

    setVendorName(params['vendor_name'])

    setSubdiaryIdParams(params['subsidiary_id'])
    setDepartmentIdParams(params['department_id'])
    setGlAccountIdParams(params['glaccount_id'])
    setVendorIdParams(params['vendor_id'])
    setVendorNameParams(params['vendor_name'])
    setPeriodParams(params['period'])
    if (['Unreviewed'].includes(params['key'])) {
      setTypeParams('TOTALS')
    } else setTypeParams(params['type'])
    setKeyParams(params['key'])
    setViewParams(params['view'])
    setFilterParams(params['filter'])

    if (
      params['type'] === 'HISTORICALS' ||
      params['type'] === 'MONTH_TO_DATE'
    ) {
      params['type'] = params['type'] === 'HISTORICALS' ? 'historic' : 'mtd'

      getTransactionData(params).then((res) => {
        setTablePopupData(res && res.data)
        setShowTransactionsPopup(true)
      })
    } else if (params['type'] === 'ACCRUALS') {
      // ** Calculated Accrual
      if (params['key'] === 'CalculatedAccrual') {
        if (consolidatedTxnView) {
          setShowCalculatedPopup(true)
        } else
          getCalculatedAccrualData(params).then((res) => {
            setTablePopupData(res && res.data)
            setShowCalculatedPopup(true)
          })
        // ** Imported Accrual
      } else if (params['key'] === 'ImportedAccrual') {
        if (consolidatedTxnView) {
          setShowImportedPopup(true)
        } else
          getImportedAccrualData(params).then((res) => {
            setTablePopupData(res && res.data)
            setShowImportedPopup(true)
          })
        // ** Internal Accrual
      } else if (params['key'] === 'Open') {
        setShowInternalPopup(true)
        // ** Manual Accrual
      } else if (params['key'] === 'Manual') {
        setShowManualPopup(true)
        // ** Vendor Accrual
      } else if (params['key'] === 'Confirms') {
        setShowVendorPopup(true)
      } else if (consolidatedTxnView) {
        // ** For quick actions for proposed/reviewed/posted
        setShowQuickActions(true)
        setQuickActionsType(params['key'])
      }
    }
  }

  const handleCancel = () => {
    if (isEditRowCalled || isCreateQjeCalled) {
      setCallReviewCenterReload(!callReviewCenterReload)
    }
    setShowTransactionsPopup(false)
    setShowCalculatedPopup(false)
    setShowImportedPopup(false)
    setShowInternalPopup(false)
    setShowManualPopup(false)
    setShowVendorPopup(false)
    setHistoryData({})
    setActiveKey('1')
    setDisplay(<></>)
    setShowQuickActions(false)
    setRcUseNewHistoryTable(false)
  }

  return (
    <>
      <div id='table-content-wrapper' className='bg-white relative'>
        <div className='table-wrapper custom-s-bar g-table-ui'>
          {data && (
            <table
              id='tblRC'
              className='w-full'
              data-cy='rc-table'
              data-testid='rc-table'
            >
              <TableHeader
                data={data}
                fields={fields}
                view={view}
              ></TableHeader>
              {!loading && (
                <tbody>
                  <TableRow
                    config={config}
                    subsidiaries={data}
                    fields={fields}
                    view={view}
                    clickHandler={clickHandler}
                  ></TableRow>
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>

      {showTransactionsPopup && tablePopupData && (
        <TransactionModal
          showModal={showTransactionsPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></TransactionModal>
      )}

      {/* Calculated */}
      {showCalculatedPopup && tablePopupData && (
        <CalculatedAccrualModal
          showModal={showCalculatedPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></CalculatedAccrualModal>
      )}

      {/* Imported */}
      {showImportedPopup && tablePopupData && (
        <ImportedAccrualModal
          showModal={showImportedPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></ImportedAccrualModal>
      )}

      {/* Internal */}
      {showInternalPopup && tablePopupData && (
        <InternalAccrualModal
          showModal={showInternalPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></InternalAccrualModal>
      )}

      {/* Manual */}
      {showManualPopup && tablePopupData && (
        <ManualAccrualModals
          showModal={showManualPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></ManualAccrualModals>
      )}

      {/* Vendor */}
      {showVendorPopup && tablePopupData && (
        <VendorAccrualModal
          showModal={showVendorPopup}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></VendorAccrualModal>
      )}

      {/* Reusable modal for proposed - reviewed - posted */}
      {showQuickActions && tablePopupData && (
        <QuickActionsModal
          showModal={showQuickActions}
          data={tablePopupData}
          onCancel={handleCancel}
          vendorName={vendorName}
          isReportingCurrency={isReportingCurrency}
          currencyName={currencyName}
        ></QuickActionsModal>
      )}
    </>
  )
}

export default ReviewCenterTable
