import { Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { FC, SetStateAction, useState } from 'react'
import useDeactivateUser from '../hooks/useDeactivateUser'
import useActivateUser from '../hooks/useActivateUser'

interface ActivateUserModalProps {
  visible: boolean
  id?: number
  email: string
  name: string
  status?: 'Active' | 'Inactive' | 'Pending'
  setIsModalVisible: (value: SetStateAction<boolean>) => void
  afterClose?: () => void
  onSubmitFinish: () => void
}

const ActivateUserModal: FC<ActivateUserModalProps> = ({
  visible,
  id,
  email,
  name,
  status,
  setIsModalVisible,
  afterClose,
  onSubmitFinish
}: ActivateUserModalProps): JSX.Element => {
  const { deactivate } = useDeactivateUser()
  const { activate } = useActivateUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getModalTitle = (): string => {
    if (status === 'Active') {
      return 'Deactivate User'
    }
    if (status === 'Inactive') {
      return 'Activate User'
    }
    return ''
  }

  const getModalOkText = (): string => {
    if (status === 'Active') {
      return 'Deactivate'
    }
    if (status === 'Inactive') {
      return 'Activate'
    }
    return ''
  }

  const getModalBody = (): JSX.Element => {
    if (status === 'Active') {
      return (
        <>
          <p>
            You are about to deactivate {name} ({email}) from this account. This
            action will prevent {name} from accessing Gappify.
          </p>
          <p>Are you sure you want to deactivate this user?</p>
        </>
      )
    }
    if (status === 'Inactive') {
      return <p>Are you sure you want to activate this user?</p>
    }
    return <></>
  }

  const getModalOkBtnProps = (): ButtonProps => {
    if (status === 'Active') {
      return {
        danger: true,
        loading: isLoading
      }
    }
    if (status === 'Inactive') {
      return {
        type: 'primary',
        loading: isLoading,
        danger: false
      }
    }
    return {}
  }

  const handleModalOnOk = async () => {
    setIsLoading(true)
    if (status === 'Active') {
      const response: { success: boolean; message: string } = await deactivate(
        id!
      )
      if (response.success) {
        onSubmitFinish()
      } else {
        setIsModalVisible(false)
      }
    }

    if (status === 'Inactive') {
      await activate(id!)
      onSubmitFinish()
    }
    setIsLoading(false)
  }

  return (
    <Modal
      destroyOnClose
      width={700}
      title={getModalTitle()}
      open={visible}
      okText={getModalOkText()}
      okButtonProps={getModalOkBtnProps()}
      afterClose={afterClose}
      onCancel={() => setIsModalVisible(false)}
      onOk={handleModalOnOk}
      closable={false}
      data-testid='activate-user-modal'
      data-cy='activate-user-modal'
    >
      {getModalBody()}
    </Modal>
  )
}

export default ActivateUserModal
