/** redux imports */
import { useSelector } from 'react-redux'
import { RootState } from '../../../context/store'

/** zustand store imports */
import useLoginStore from '../manager/useLoginStore'

/** custom components imports */
import InvalidToken from './InvalidToken'

// =================================================================
export default function LoginError() {
  /** zustand store */
  const { loginErrorMessage } = useLoginStore()

  /** redux store */
  const { invalidTokenMessage } = useSelector(
    (state: RootState) => state.userActivationForm
  )

  return (
    <>
      {loginErrorMessage ? (
        <div className='login-error-msg'>{loginErrorMessage}</div>
      ) : invalidTokenMessage ? (
        <InvalidToken />
      ) : (
        false
      )}
    </>
  )
}
