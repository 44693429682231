import { useMutation, useQueryClient } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import { message } from 'antd'
import constants from '../config/constants'

type Response = {
  message: string
}

type Error = {
  data: Response & { success: boolean }
}

type Params = {
  id: number
}

const useDeleteView = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()
  const queryClient = useQueryClient()

  return useMutation<Response, Error, Params>({
    mutationFn: ({ id }) =>
      globalApi({
        options: {
          baseUrl: msUrl.review_center,
          endpoint: `/api/review-center/admin/view/${id}`,
          method: 'DELETE'
        }
      }),
    onSuccess: (data) => {
      message.success(data.message)
      queryClient.invalidateQueries({
        queryKey: constants.REVIEW_CENTER_VIEWS_KEY
      })
    },
    onError: (err) => {
      message.error(err.data.message)
    }
  })
}

export default useDeleteView
