import { FC, useState } from 'react'
import { Modal, Button } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import ReviewCenterHelper from '../../../helpers/review-center-helper'

const TransactionModal: FC<any> = ({
  showModal,
  data,
  onCancel,
  vendorName,
  isReportingCurrency,
  currencyName
}): JSX.Element => {
  const [size] = useState<SizeType>('large')

  const transactionSubTotal = (key: string) => {
    let sum = data.data.reduce(
      (total: number, row: object) =>
        total + +ReviewCenterHelper.getDefaultValue(row, key),
      0
    )
    return ReviewCenterHelper.formatCurrency(sum)
  }

  return (
    <Modal
      data-cy='rc-transaction-modal'
      data-testid='rc-transaction-modal'
      title={
        <div
          data-cy='rc-transaction-modal-title'
          data-testid='rc-transaction-modal-title'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '-10px'
          }}
        >
          <h2>{vendorName}</h2>
        </div>
      }
      open={showModal}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{ height: 'auto' }}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right'
          }}
          data-cy='rc-transaction-modal-footer'
          data-testid='rc-transaction-modal-footer'
        >
          <Button
            data-cy='rc-transaction-modal-close-btn'
            data-testid='rc-transaction-modal-close-btn'
            shape='round'
            key='Close'
            type='primary'
            onClick={onCancel}
            size={size}
          >
            &nbsp; &nbsp; Close &nbsp; &nbsp;
          </Button>
        </div>
      }
    >
      <div id='popup-table-wrapper' className='g-table-ui'>
        <table
          className='w-full text-left'
          data-cy='rc-transaction-modal-table'
          data-testid='rc-transaction-modal-table'
        >
          <thead>
            <tr className='title-bg-color border-bt sticky-row sticky-row-1'>
              <th
                className='border-l'
                data-cy='rc-transaction-modal-type-header'
                data-testid='rc-transaction-modal-type-header'
              >
                Type
              </th>
              <th
                data-cy='rc-transaction-modal-date-header'
                data-testid='rc-transaction-modal-date-header'
              >
                Date
              </th>
              <th
                data-cy='rc-transaction-modal-document-number-header'
                data-testid='rc-transaction-modal-document-number-header'
              >
                Document Number
              </th>
              <th
                data-cy='rc-transaction-modal-description-header'
                data-testid='rc-transaction-modal-description-header'
              >
                Description
              </th>
              <th
                data-cy='rc-transaction-modal-transaction-currency-header'
                data-testid='rc-transaction-modal-transaction-currency-header'
              >
                Transaction Currency
              </th>
              <th
                data-cy='rc-transaction-modal-transaction-amount-header'
                data-testid='rc-transaction-modal-transaction-amount-header'
              >
                Transaction Amount
              </th>
              {!isReportingCurrency && (
                <th
                  data-cy='rc-transaction-modal-subsidiary-currency-header'
                  data-testid='rc-transaction-modal-subsidiary-currency-header'
                >
                  Subsidiary Currency
                </th>
              )}
              <th
                className='border-r'
                data-cy='rc-transaction-modal-amount-header'
                data-testid='rc-transaction-modal-amount-header'
              >
                {currencyName} Amount
              </th>
            </tr>
          </thead>
          {Object.keys(data.data).length > 0 ? (
            <tbody>
              <>
                {data &&
                  data.data &&
                  Object.entries(data.data).map(
                    ([id, transaction]: any, index: any) => (
                      <tr key={index}>
                        <td
                          className='border-l'
                          data-cy={`rc-transaction-modal-td-type${index}`}
                          data-testid={`rc-transaction-modal-td-type${index}`}
                        >
                          {transaction.g_type}
                        </td>
                        <td
                          className='ws-nowrap'
                          data-cy={`rc-transaction-modal-td-date${index}`}
                          data-testid={`rc-transaction-modal-td-date${index}`}
                        >
                          {transaction.g_date}
                        </td>
                        <td
                          data-cy={`rc-transaction-modal-td-document-number${index}`}
                          data-testid={`rc-transaction-modal-td-document-number${index}`}
                        >
                          {transaction.g_document_number}
                        </td>
                        <td
                          data-cy={`rc-transaction-modal-td-memo${index}`}
                          data-testid={`rc-transaction-modal-td-memo${index}`}
                        >
                          {transaction.g_memo}
                        </td>
                        <td
                          data-cy={`rc-transaction-modal-td-transaction-currency${index}`}
                          data-testid={`rc-transaction-modal-td-transaction-currency${index}`}
                        >
                          {transaction.g_transaction_currency}
                        </td>
                        <td
                          className='text-right'
                          data-cy={`rc-transaction-modal-td-transaction-amount${index}`}
                          data-testid={`rc-transaction-modal-td-transaction-amount${index}`}
                        >
                          {ReviewCenterHelper.formatCurrency(
                            transaction.g_transaction_amount
                          )}
                        </td>
                        {!isReportingCurrency && (
                          <td
                            data-cy={`rc-transaction-modal-td-subsidiary-currency${index}`}
                            data-testid={`rc-transaction-modal-td-subsidiary-currency${index}`}
                          >
                            {transaction.g_subsidiary_currency}
                          </td>
                        )}
                        <td
                          className='border-r text-right'
                          data-cy={`rc-transaction-modal-td-subsidiary-amount${index}`}
                          data-testid={`rc-transaction-modal-td-subsidiary-amount${index}`}
                        >
                          {ReviewCenterHelper.formatCurrency(
                            transaction.g_subsidiary_amount
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </>
              <tr className='sub-total-tier-2 font-semibold border-bt'>
                <td
                  className='border-l text-right'
                  colSpan={isReportingCurrency ? 5 : 6}
                  data-cy={`rc-transaction-modal-td-total`}
                  data-testid={`rc-transaction-modal-td-total`}
                >
                  Total
                </td>
                <td
                  data-cy={`rc-transaction-modal-td-currency`}
                  data-testid={`rc-transaction-modal-td-currency`}
                >
                  {isReportingCurrency
                    ? currencyName
                    : data.data[0].g_subsidiary_currency}
                </td>
                <td
                  className='border-r text-right'
                  data-cy={`rc-transaction-modal-td-subsidiary-amount`}
                  data-testid={`rc-transaction-modal-td-subsidiary-amount`}
                >
                  {transactionSubTotal('g_subsidiary_amount')}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  className='border-lr border-b'
                  colSpan={8}
                  data-cy={`rc-transaction-modal-no-transactions-available`}
                  data-testid={`rc-transaction-modal-no-transactions-available`}
                >
                  No transactions available
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Modal>
  )
}
export default TransactionModal
