/** Tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** Services imports */
import useGlobalService from '../services/globalService'
import useApiService from './useApiService'

/** zustand store imports */
// import useLoginStore from '../pages/Login/manager/useLoginStore'

// =================================================================
export default function useCompanySettings() {
  /** API service */
  const { globalServiceApi } = useGlobalService()
  const { msUrl } = useApiService()

  /** zustand store */
  // const { isCallCompanySettings } = useLoginStore()

  return useQuery({
    queryKey: ['company-settings'],
    queryFn: () =>
      globalServiceApi({
        params: {},
        data: {},
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/qje/settings',
          method: 'GET'
        }
      })
    // enabled: isCallCompanySettings
  })
}
