// ** AntD Imports
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'

// ** Third Party Imports
import { useQueryClient } from '@tanstack/react-query'

// ** Type Imports
import ClickParam from '../types/ClickParam'

// ** Zustand Store Imports
import useScheduleStore from '../manager/schedule-store'

// ** Util imports
import { getLast6MonthsData } from '../utils/dateUtil'

// =================================================================
export default function useDropdown() {
  // ** Store **
  const { setPeriod, setMonthlyEditPayload } = useScheduleStore()

  const queryClient = useQueryClient()

  // =================================================================
  const getPeriodMenu = () => {
    const menu: MenuItemType[] = getLast6MonthsData()
    return menu
  }

  const handlePeriodClick = async (param: ClickParam) => {
    setMonthlyEditPayload([])
    if (param?.key) {
      await setPeriod({
        key: param.key,
        label: `${
          getPeriodMenu().find((menu) => {
            return menu.key === param.key
          })?.label
        }`
      })
      await queryClient.invalidateQueries(['schedule-manager'])
    }
  }

  return {
    getPeriodMenu,
    handlePeriodClick
  }
}
