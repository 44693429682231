// ** AntD Imports
import { Card, Typography } from 'antd'
import { LockFilled } from '@ant-design/icons'

// ============================================================================

const PasswordRules = () => {
  return (
    <Card
      data-testid='PasswordRules'
      type='inner'
      title={
        <div className='password-rules-title-wrapper'>
          <LockFilled onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
          <Typography>Password Rules</Typography>
        </div>
      }
      className='password-rules-width'
    >
      <ul>
        <li>Minimum Length: 8 characters</li>
        <li>
          Must have 1 number, 1 upper case character, 1 lower case character
        </li>
        <li>Must not match last 3 used passwords</li>
      </ul>
    </Card>
  )
}

export default PasswordRules
