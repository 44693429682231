import { axiosIdentityManagerInstance } from '../../../../../helpers/axios'
import { AUTH_TOKEN } from '../../../../../helpers/constants/Auth'

export default async function getUserById(id: number) {
  const token = localStorage.getItem(AUTH_TOKEN)
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    ContentType: 'application/json'
  }

  try {
    const response = await axiosIdentityManagerInstance.get(
      `/api/admin/user/${id}`,
      { headers }
    )

    return response.data
  } catch (error) {
    throw error
  }
}
