const RCFieldsNewTRDEnums: any = {
  HISTORICALS: [
    { key: '', label: '', display: true, classes: 'amount' },
    { key: '', label: '', display: true, classes: 'amount' },
    { key: '', label: '', display: true, classes: 'amount' }
  ],
  MONTH_TO_DATE: [
    { key: 'Bill', label: 'Bills', display: true, classes: 'amount' },
    { key: 'Journal', label: 'JE', display: true, classes: 'amount' },
    { key: 'Other', label: 'Other', display: true, classes: 'amount' },
    {
      key: 'MtdTotal',
      label: 'MtdTotal',
      display: true,
      classes: 'font-semibold'
    },
    { key: 'Budget', label: 'Budget', display: true, classes: '' }
  ],
  ACCRUALS: [
    { key: 'Confirms', label: 'Vendor', display: true, classes: 'amount' },
    { key: 'Open', label: 'Internal', display: true, classes: 'amount' },
    {
      key: 'CalculatedAccrual',
      label: 'Calculated',
      display: true,
      classes: 'amount'
    },
    {
      key: 'ImportedAccrual',
      label: 'Imported',
      display: true,
      classes: 'amount'
    },
    { key: 'Manual', label: 'Manual', display: true, classes: 'amount' },
    {
      key: 'Unreviewed',
      label: 'Proposed',
      display: true,
      classes: ''
    },
    { key: 'Reviewed', label: 'Reviewed', display: true, classes: '' },
    { key: 'Posted', label: 'Posted', display: true, classes: '' }
  ],
  ANALYSIS: [
    { key: 'AnalysisOther', label: 'Other', display: true, classes: '' },
    { key: 'AnalysisTotal', label: 'Total', display: true, classes: '' },
    { key: 'Budget', label: 'Budget', display: true, classes: '' },
    { key: 'Variance', label: 'Variance', display: true, classes: '' }
  ]
}
export default RCFieldsNewTRDEnums
