// ** Zustand Imports
import { create } from 'zustand';

// ** Type Imports
interface ResetPasswordProps {
    // Defined state properties
    isResetPasswordModalOpen: boolean;
    responseMessage: string;
    // Defined actions
    setIsResetPasswordModalOpen: (isResetPasswordModalOpen: boolean) => void;
    setResponseMessage: (responseMessage: string) => void;
    // Functions
    handleOk: () => void;
    handleCancel: () => void;
}

// =================================================================
const useResetPasswordModalStore = create<ResetPasswordProps>((set) => ({
    // Define initial state
    isResetPasswordModalOpen: false,
    responseMessage: "",
    // Define actions
    setIsResetPasswordModalOpen: (isResetPasswordModalOpen) => set({ isResetPasswordModalOpen }),
    setResponseMessage: (responseMessage) => set({ responseMessage }),
    // Functions
    handleOk: () => set({ isResetPasswordModalOpen: true }),
    handleCancel: () => set({
        responseMessage: "",
        isResetPasswordModalOpen: false
    })
}))

export default useResetPasswordModalStore