// ** React Imports **
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'

// ** React Query imports
import { useQueryClient } from '@tanstack/react-query'

// ** Custom Component Imports
import GButton from '../../../../components/gappify/GButton'
import CheckboxRow from './components/CheckboxRow'

// ** Hook Imports
import useTxnEnableFeatures from './hooks/useTxnEnableFeatures'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useTxnGlobalSettingsStore from '../../../../global/useTxnGlobalSettingsStore'
import useFetchSettingsConsolidated from './hooks/useFetchSettingsConsolidated'

// ** Zustand Imports
import useTxnEnableFeatureStore from './manager/useTxnEnableFeatureStore'

// ** Antd imports
import {
  Skeleton,
  Space,
  Popconfirm,
  Select,
  Checkbox,
  Tooltip,
  message,
  Popover
} from 'antd'
import MonthlyData from '../ScheduleManager/mock/MonthlyData'
import useScheduleManager from '../ScheduleManager/hooks/useScheduleManager'

// ** Utils imports
import isGappifyAdministrator from '../../utils/isGappifyAdministrator'
import useTxnGlobalSettings from '../../../../hooks/useTxnGlobalSettings'
import { TOOLTIP_MSG } from './data/constants'

// ===================================================================
const TxnEnableFeatures = () => {
  // ** Hooks **
  const { getTxnEnableFeatures, handleSave, putIsLoading } =
    useTxnEnableFeatures()
  const { data, isSuccess, isLoading, isRefetching } = getTxnEnableFeatures
  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const { getScheduleManagerMonthly } = useScheduleManager()
  const { isLoading: isLoadingSchedule } = getScheduleManagerMonthly
  const { rowsFromBackend } = MonthlyData()
  const {
    isLoading: isLoadingTxnGlobalSettings,
    isRefetching: isRefetchingTxnGlobalSettings
  } = useTxnGlobalSettings()
  const {
    data: fetchedConsoSettings,
    isLoading: isLoadingFetchedConsoSettings
  } = useFetchSettingsConsolidated()
  const { Option } = Select

  // ** Zustand states **
  const { txnGlobalSettings }: Record<string, any> = useTxnGlobalSettingsStore()
  const {
    priorPeriodVendor,
    priorPeriodInternal,
    priorPeriodTransaction,
    validateQjeSources,
    consolidatedTxnView,
    propAccrualAmountHierarchy,
    negativeAccrualAmountToPrepaidEntry,
    setPriorPeriodVendor,
    setPriorPeriodInternal,
    setPriorPeriodTransaction,
    setValidateQjeSources,
    setConsolidatedTxnView,
    setPropAccrualAmountHierarchy,
    setNegativeAccrualAmountToPrepaidEntry,
    considerBothDebitAndCreditValues,
    setConsiderBothDebitAndCreditValues,
    accountTypesToShowInReviewCenter,
    setAccountTypesToShowInReviewCenter,
    displayInvoiceColumn,
    setDisplayInvoiceColumn
  } = useTxnEnableFeatureStore()

  const [showPopConfirm, setShowPopConfirm] = useState<boolean>(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const isConsoFlagDisabled = () => {
    const oldCondition = showPopConfirm || data?.consolidated_transaction_lines
    const newCondition =
      fetchedConsoSettings?.consolidated_transaction_lines_disabled // New condition from AC-1348
    return oldCondition || newCondition
  }

  const getConsoTooltip = () => {
    if (
      !data?.consolidated_transaction_lines && // Conso flag should not be enabled yet
      fetchedConsoSettings?.consolidated_transaction_lines_disabled // New settings flag to disable the conso flag & display tooltip
    )
      return TOOLTIP_MSG.CONSO.DISABLED
    return TOOLTIP_MSG.CONSO.DEFAULT_OLD
  }

  useEffect(() => {
    if (!isLoading && !isRefetching && isSuccess) {
      setPriorPeriodVendor(
        data?.prior_period_information_on_vendor_accrual_forms!
      )
      setPriorPeriodInternal(
        data?.prior_period_information_on_internal_accrual_forms!
      )
      setPriorPeriodTransaction(
        data?.prior_period_information_on_transaction_lines!
      )
      setValidateQjeSources(data?.validate_qje_sources!)
      setConsolidatedTxnView(data?.consolidated_transaction_lines!)
      setPropAccrualAmountHierarchy(data?.proposed_accrual_amount_hierarchy!)
      setNegativeAccrualAmountToPrepaidEntry(
        data?.negative_accrual_amount_to_prepaid_entry!
      )
      setConsiderBothDebitAndCreditValues(
        data?.consider_both_debit_and_credit_values!
      )
      setAccountTypesToShowInReviewCenter(
        data?.account_types_to_show_in_review_center!
      )
      setDisplayInvoiceColumn(data?.display_invoice_column || false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isRefetching, isLoading])

  useEffect(() => {
    const invalidateSchedManagerQueries = async () => {
      if (!isLoadingSchedule) {
        await queryClient.invalidateQueries(['schedule-manager-monthly'])
      }
    }
    invalidateSchedManagerQueries()
    // eslint-disable-next-line
  }, [queryClient])

  useEffect(() => {
    if (isFeatureFlagsLoading) return
    if (!isGappifyAdministrator()) {
      // if feature flag is off OR user has no permission
      // redirect to error page
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || isLoadingFetchedConsoSettings)
    return (
      <Space direction='vertical'>
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Input active />
        <Skeleton.Button active />
      </Space>
    )

  const isDisabledProposedAccAmountHierarchy = () => {
    const checkLaunchDate = isBeforeLaunchDate()

    return !consolidatedTxnView || !checkLaunchDate
  }

  const isBeforeLaunchDate = () => {
    const currentDatePST = moment().tz('America/Los_Angeles')

    // Calculate the 16th of the last month in PST
    const startOfLastMonthPST = currentDatePST
      .clone()
      .subtract(1, 'months')
      .date(16)

    //Get erliest date in scheduler launch dates
    const earLiestDate = getEarliestLaunchDate()

    return currentDatePST.isBetween(
      startOfLastMonthPST,
      earLiestDate!,
      null,
      '[]'
    ) // '[]' includes both the start and end dates
  }

  const getEarliestLaunchDate = () => {
    const schedules = rowsFromBackend

    if (schedules.length > 0) {
      const dates = schedules.map((schedule) =>
        new Date(schedule.nextRunDate!).getTime()
      )
      // Finding the latest date
      return new Date(Math.min(...dates))
    }
  }

  const handleOnChangeField = (value: string) => {
    setPropAccrualAmountHierarchy(value)
  }

  const handleHoverChange = (visible: boolean) => {
    setIsTooltipVisible(visible)
  }

  return (
    <>
      <p>
        <CheckboxRow
          label='Show prior period information on vendor accrual forms'
          defaultChecked={
            data?.prior_period_information_on_vendor_accrual_forms
          }
          checked={priorPeriodVendor}
          onChange={() => {
            setPriorPeriodVendor(!priorPeriodVendor)
          }}
        />
      </p>
      <p>
        <CheckboxRow
          label='Show prior period information on internal accrual forms'
          defaultChecked={
            data?.prior_period_information_on_internal_accrual_forms
          }
          checked={priorPeriodInternal}
          onChange={() => {
            setPriorPeriodInternal(!priorPeriodInternal)
          }}
        />
      </p>
      <p>
        <CheckboxRow
          label='Show prior period information on transaction lines'
          defaultChecked={data?.prior_period_information_on_transaction_lines}
          checked={priorPeriodTransaction}
          onChange={() => {
            setPriorPeriodTransaction(!priorPeriodTransaction)
          }}
        />
      </p>
      <p>
        <CheckboxRow
          label='Validate vendors and COAs when creating QJE'
          defaultChecked={data?.validate_qje_sources}
          checked={validateQjeSources}
          onChange={() => {
            setValidateQjeSources(!validateQjeSources)
          }}
        />
      </p>
      <>
        <p>
          <Popconfirm
            title='Once you turn on the “Enable Consolidated Transaction View", you can no longer turn it off. Are you sure you want to enable this feature?'
            okText='Yes'
            cancelText='Cancel'
            open={showPopConfirm}
            onConfirm={() => {
              setConsolidatedTxnView(true)
              setShowPopConfirm(false)
            }}
            onCancel={() => {
              setConsolidatedTxnView(data?.consolidated_transaction_lines!)
              setShowPopConfirm(false)
            }}
            placement='left'
          >
            <Checkbox
              data-testid='consolidated-txn-checkbox'
              data-cy='consolidated-txn-checkbox'
              defaultChecked={data?.consolidated_transaction_lines}
              disabled={isConsoFlagDisabled()}
              onClick={(e: any) => {
                setConsolidatedTxnView(!consolidatedTxnView)
                setShowPopConfirm(!consolidatedTxnView)

                if (e.target.checked === false) {
                  setConsiderBothDebitAndCreditValues(false)
                  setAccountTypesToShowInReviewCenter([])
                }
              }}
              checked={consolidatedTxnView}
            >
              <Popover content={getConsoTooltip()}>
                Enable Consolidated Transaction View
              </Popover>
            </Checkbox>
          </Popconfirm>
        </p>
        <p style={{ padding: '0px 26px' }}>
          <div>Proposed Accrual Amount Hierarchy</div>
          <Tooltip
            title={
              'This field is disabled because the accrual launch for this period is scheduled for tomorrow or it has started already.'
            }
            open={isDisabledProposedAccAmountHierarchy() && isTooltipVisible}
          >
            <Select
              style={{ width: '30%', minWidth: '300px' }}
              placeholder='Please select'
              disabled={isDisabledProposedAccAmountHierarchy()}
              defaultValue={data?.proposed_accrual_amount_hierarchy!}
              options={[
                {
                  label: 'Vendor -> Internal -> Calculated',
                  value: 'Vendor.Internal.Calculated'
                },
                {
                  label: 'Vendor -> Calculated -> Internal',
                  value: 'Vendor.Calculated.Internal'
                },
                {
                  label: 'Internal -> Vendor -> Calculated',
                  value: 'Internal.Vendor.Calculated'
                },
                {
                  label: 'Internal -> Calculated -> Vendor',
                  value: 'Internal.Calculated.Vendor'
                },
                {
                  label: 'Calculated -> Vendor -> Internal',
                  value: 'Calculated.Vendor.Internal'
                },
                {
                  label: 'Calculated -> Internal -> Vendor',
                  value: 'Calculated.Internal.Vendor'
                }
              ]}
              onChange={handleOnChangeField}
              onMouseEnter={() => handleHoverChange(true)}
              onMouseLeave={() => handleHoverChange(false)}
            />
          </Tooltip>
        </p>
      </>
      <p>
        <CheckboxRow
          label='Allow negative accrual amount and adjust automatically into a prepaid entry'
          defaultChecked={data?.negative_accrual_amount_to_prepaid_entry}
          checked={negativeAccrualAmountToPrepaidEntry}
          onChange={() => {
            setNegativeAccrualAmountToPrepaidEntry(
              !negativeAccrualAmountToPrepaidEntry
            )
          }}
          dataTestId='prepaid-entry-checkbox'
          dataCy='prepaid-entry-checkbox'
        />
      </p>
      <p>
        <CheckboxRow
          disabled={isLoading || isFeatureFlagsLoading || !consolidatedTxnView}
          label='Consider both debit and credit values in the Review Center'
          defaultChecked={data?.consider_both_debit_and_credit_values}
          checked={considerBothDebitAndCreditValues}
          onChange={() => {
            setConsiderBothDebitAndCreditValues(
              !considerBothDebitAndCreditValues
            )
          }}
          dataTestId='rc-debit-credit-checkbox'
          dataCy='rc-debit-credit-checkbox'
          popoverMessage={
            isLoading || isFeatureFlagsLoading || !consolidatedTxnView
              ? 'In order to turn on this feature, you need to enable first the Enable Consolidated Transaction View'
              : undefined
          }
        />
      </p>
      <p style={{ padding: '0px 26px' }}>
        <div>Account types to show in the Review Center</div>
        <Select
          loading={
            isLoadingTxnGlobalSettings ||
            isRefetchingTxnGlobalSettings ||
            isLoading ||
            isRefetching
          }
          mode='multiple'
          style={{ width: '30%', minWidth: '300px' }}
          placeholder='Please select'
          disabled={
            isLoading ||
            isFeatureFlagsLoading ||
            !considerBothDebitAndCreditValues
          }
          defaultValue={accountTypesToShowInReviewCenter ?? undefined}
          value={accountTypesToShowInReviewCenter ?? undefined}
          onChange={(value: string[]) =>
            setAccountTypesToShowInReviewCenter(value)
          }
        >
          {txnGlobalSettings['transaction.coa_account_type']?.map(
            (option: string) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            )
          )}
        </Select>
      </p>
      <p>
        <CheckboxRow
          label='Display Invoice column in Gappify Accruals'
          defaultChecked={data?.display_invoice_column}
          checked={displayInvoiceColumn}
          onChange={() => {
            setDisplayInvoiceColumn(!displayInvoiceColumn)
          }}
          dataTestId='rc-display-invoice-column'
          dataCy='rc-display-invoice-column'
        />
      </p>

      <GButton
        onClick={() => {
          if (
            considerBothDebitAndCreditValues &&
            (!accountTypesToShowInReviewCenter ||
              accountTypesToShowInReviewCenter?.length === 0)
          ) {
            message.error(
              'Cannot be saved. Must have at least one account type to show in Review Center.'
            )
          } else
            handleSave({
              prior_period_information_on_vendor_accrual_forms:
                priorPeriodVendor,
              prior_period_information_on_internal_accrual_forms:
                priorPeriodInternal,
              prior_period_information_on_transaction_lines:
                priorPeriodTransaction,
              validate_qje_sources: validateQjeSources,
              consolidated_transaction_lines: consolidatedTxnView,
              proposed_accrual_amount_hierarchy: propAccrualAmountHierarchy,
              negative_accrual_amount_to_prepaid_entry:
                negativeAccrualAmountToPrepaidEntry,
              consider_both_debit_and_credit_values:
                considerBothDebitAndCreditValues,
              account_types_to_show_in_review_center:
                accountTypesToShowInReviewCenter,
              display_invoice_column: displayInvoiceColumn
            })
        }}
        loading={putIsLoading}
      >
        Save
      </GButton>
    </>
  )
}

export default TxnEnableFeatures
