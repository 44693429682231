import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useCompanySettings from 'src/hooks/useCompanySettings'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import useRolesAndPermissions from 'src/hooks/useRolesAndPermissions'

// =================================================================
export default function useWorkdayFlag() {
  /** react router */
  const navigate = useNavigate()

  /** hooks */
  const RP = useRolesAndPermissions()
  const { isLoading: isFeatureFlagsLoading, data: featureFlags } =
    useFeatureFlags()
  const { isLoading: isCompanySettingsLoading, data: companySettings } =
    useCompanySettings()

  useEffect(() => {
    if (isFeatureFlagsLoading) return
    if (!featureFlags.vgateway || !RP.USER_HAS_API_MANAGER_GET_PERMISSION) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureFlagsLoading])

  useEffect(() => {
    if (isCompanySettingsLoading) return
    if (!companySettings.workday_enabled) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanySettingsLoading])
}
