import { Modal, Form, Input, Select, message, Button } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'
import GButton from '../gappify/GButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import GIcon from '../gappify/GIcon'
import {
  qjeFilterCreate,
  getSuggestedValues
} from '../../services/QjeManagerApi'
import { setSelectedFilter as setGlobalSelectedFilter } from '../../context/actions/qjeAction'
import { useDispatch } from 'react-redux'
import filterSort from '../../utils/filterSort'
import filterOption from '../../utils/filterOption'

interface CreateNewFilterModalProps {
  showModal: boolean
  onCancel: any
  setIsShowModal: (value: SetStateAction<boolean>) => void
  setCustomFilters: (value: SetStateAction<any>) => any
  setIsLoading: (value: SetStateAction<any>) => any
  period: any
  setIsPreviewMode: any
  setPreviewTriggeredFrom: any
  settings: any
  loadingSettings: any
  setDataPreviewed: any
  getFilterLabel: any
  reloadData: any
  // refs...
  selectedFilterRef: any
  selectedPeriodRef: any
}

const CreateNewFilterModal: FC<CreateNewFilterModalProps> = ({
  showModal,
  onCancel,
  setIsShowModal,
  setCustomFilters,
  setIsLoading,
  setIsPreviewMode,
  setPreviewTriggeredFrom,
  settings,
  loadingSettings,
  setDataPreviewed,
  getFilterLabel,
  reloadData,
  // refs
  selectedFilterRef,
  selectedPeriodRef
}: CreateNewFilterModalProps): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [newFilters, setNewFilters] = useState<any[]>([])
  const [countNewFilters, setCountNewFilters] = useState<number>(0)
  const [suggestedValues, setSuggestedValues] = useState<
    { id: any; value: any }[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [focusedApiName, setFocusedApiName] = useState<string>('')

  const handleChangeFilter = (filter: any, newField: any) => {
    setNewFilters(
      newFilters.map((newFilter) => {
        if (filter.key === newFilter.key) {
          form.setFieldValue(`${filter.key}-value`, [])
          return { ...newFilter, field: newField, value: [] }
        } else return newFilter
      })
    )
    setSuggestedValues([])
  }

  const renderAppropriateInputField = (type: string, filter: any) => {
    switch (type) {
      case 'short_text':
        return (
          <Input
            data-testid={`qje-filter-value-input-${filter.key}`}
            data-cy={`qje-filter-value-input-${filter.key}`}
            style={{ width: '90%' }}
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
          />
        )
      case 'picklist_multiple':
        return (
          <Select
            data-testid={`qje-filter-value-input-${filter.key}`}
            data-cy={`qje-filter-value-input-${filter.key}`}
            onClick={() => {
              getSuggestedValues(filter.field, '').then((res: any) =>
                setSuggestedValues(res.data)
              )
            }}
            mode='multiple'
            style={{ width: '90%' }}
            placeholder='Please select'
            defaultValue={[]}
            optionLabelProp='label'
            options={
              // for custom fields
              // filter.field === 'g_resubmitted'
              ['g_resubmitted', 'g_reviewed'].includes(filter.field)
                ? [
                    { key: 0, value: 1, label: 'Yes' },
                    { key: 1, value: 0, label: 'No' }
                  ]
                : suggestedValues.map((e: any) => {
                    return { ...e, key: e.value, label: e.value }
                  })
            }
            onChange={(e: any) => {
              setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
            }}
            onKeyUp={(e: any) => {
              if (e.target.value.length >= 3) {
                setFocusedApiName(filter.field)
                setSearchTerm(e.target.value)
              }
            }}
            onInputKeyDown={async (e: any) => {
              if (
                [e.key, e.code].includes('Enter') &&
                !['', null, undefined].includes(e.target.value)
              ) {
                await form.setFieldValue(`${filter.key}-value`, [
                  ...form.getFieldValue(`${filter.key}-value`),
                  e.target.value
                ])
                await setNewFilters(
                  newFilters.map((f: any) => {
                    return filter.key === f.key
                      ? {
                          ...f,
                          value: form.getFieldValue(`${filter.key}-value`)
                        }
                      : f
                  })
                )
                e.target.value = ''
              }
            }}
          />
        )
    }
  }

  const renderFilterInputComponents = (filter: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {loadingSettings ? (
          <Form.Item
            data-testid={`qje-filter-field-form-item-${filter.key}`}
            data-cy={`qje-filter-field-form-item-${filter.key}`}
            initialValue={'Loading...'}
            name={'loading'}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Input style={{ width: '90%' }} disabled />
          </Form.Item>
        ) : (
          <Form.Item
            data-testid={`qje-filter-field-form-item-${filter.key}`}
            data-cy={`qje-filter-field-form-item-${filter.key}`}
            initialValue={filter.field}
            name={`${filter.key}-field`}
            label='Field'
            rules={[{ required: true, message: 'Select field.' }]}
            style={{ width: '90%' }}
          >
            <Select
              data-testid={`qje-filter-field-selector-${filter.key}`}
              data-cy={`qje-filter-field-selector-${filter.key}`}
              showSearch
              placeholder='Please select'
              onChange={(e) => handleChangeFilter(filter, e)}
              style={{ width: '90%' }}
              optionFilterProp='children'
              filterOption={filterOption}
              filterSort={filterSort}
            >
              {settings
                ? Object.keys(settings).map((e: string, i: any) => {
                    return (
                      <Select.Option
                        data-testid={`qje-filter-field-option-${filter.key}-${e}`}
                        data-cy={`qje-filter-field-option-${filter.key}-${e}`}
                        value={e}
                        key={i}
                        disabled={newFilters.map((f) => f.field).includes(e)}
                      >
                        {settings[e].label}
                      </Select.Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          data-testid={`qje-filter-value-option-${filter.key}`}
          data-cy={`qje-filter-value-option-${filter.key}`}
          initialValue={filter.value}
          name={`${filter.key}-value`}
          label='Value'
          rules={[{ required: true, message: 'Enter value.' }]}
          style={{ width: '90%' }}
        >
          {renderAppropriateInputField('picklist_multiple', filter)}
        </Form.Item>

        <GButton
          dataTestId={`qje-remove-filter-${filter.key}`}
          dataCy={`qje-remove-filter-${filter.key}`}
          type='text'
          className='g-btn-icon'
          onClick={() => {
            form.setFieldValue(`${filter.key}-value`, [])
            form.setFieldValue(`${filter.key}-field`, null)
            setNewFilters(newFilters.filter((f: any) => f.key !== filter.key))
          }}
          icon={<GIcon icon={faTrash} />}
        />
      </div>
    )
  }

  const resetFields = (mode: string | number, clearTitle?: boolean) => {
    setCountNewFilters(0)
    setNewFilters([])
    if (mode === 'all') {
      Array.from(Array(countNewFilters), (_, index: number) => index).forEach(
        (index: number) => {
          form.setFieldValue(`${index}-field`, null)
          form.setFieldValue(`${index}-operator`, null)
          form.setFieldValue(`${index}-value`, null)
        }
      )
      setNewFilters([])
      setCountNewFilters(0)
    } else {
      form.setFieldValue(`${mode}-field`, null)
      form.setFieldValue(`${mode}-operator`, null)
      form.setFieldValue(`${mode}-value`, null)
    }

    if (clearTitle) {
      form.setFieldValue('name', null)
    }
  }

  const onPreview = () => {
    if (newFilters.length === 0) {
      message.error('You need to add at least one filter.')
    } else {
      setPreviewTriggeredFrom('new')
      setIsPreviewMode(true)

      let filterDictionary: any = {}
      newFilters.forEach((filter: any) => {
        if (Object.keys(filterDictionary).includes(filter.field)) {
          filterDictionary[filter.field] = [
            ...filterDictionary[filter.field],
            ...filter.value
          ]
        } else {
          filterDictionary[filter.field] = [...filter.value]
        }
      })

      console.log('new filters', newFilters)
      console.log('filter dict', filterDictionary)

      reloadData({ getAll: true, onlyRetrieveData: true })
        .then((data: any) => {
          let filtered = data.filter((entry: any) => {
            let numberOfFieldsSatisfied = 0
            Object.keys(entry)
              .filter((key: any) => Object.keys(filterDictionary).includes(key))
              .forEach((key: any) => {
                if (filterDictionary[key].includes(entry[key])) {
                  numberOfFieldsSatisfied += 1
                }
              })
            return (
              numberOfFieldsSatisfied === Object.keys(filterDictionary).length
            )
          })

          console.log('filtered', filtered)

          let filteredByPair = [...filtered]

          filtered.forEach((entry: any) => {
            let foundPair = data.find(
              (dataEntry: any) =>
                `${dataEntry.line_group}` === `${entry.line_group}` &&
                `${dataEntry.id}` !== `${entry.id}` &&
                !filtered.map((entry: any) => entry.id).includes(dataEntry.id)
            )

            if (foundPair) {
              filteredByPair = [...filteredByPair, foundPair]
            }
          })

          filteredByPair = filteredByPair.sort((a: any, b: any) => a.id - b.id)

          setDataPreviewed(filteredByPair)
          setIsShowModal(false)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const onFinish = async (e: any) => {
    let payload: any = {}
    payload.filter_name = e.name
    payload.filter_fields = newFilters.map((filter) => {
      return { column: filter.field, value: filter.value }
    })
    if (newFilters.length === 0)
      message.error('You need to add at least one filter.')
    else {
      try {
        const { data } = await qjeFilterCreate(payload)
        message.success(data.message)
        setCustomFilters(data.all_filters)
        setIsLoading(false)
        setIsShowModal(false)
        setIsPreviewMode(false)

        // update refs and global filters
        dispatch(
          setGlobalSelectedFilter({
            id: data.default_filter,
            label: e.name
          })
        )
        selectedFilterRef.current = {
          id: data.default_filter,
          label: e.name
        }
        resetFields('all', true)
      } catch (error) {
        message.error('An error occurred.')
        setIsLoading(false)
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    console.log('Failed:', errorInfo)
  }

  // for autocomplete backend calls...
  // after 1 sec, if user is not typing anything, fetch suggested values from BE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSuggestedValues(focusedApiName, searchTerm).then((res: any) => {
        setSuggestedValues(res.data)
      })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <Modal
      data-testid='qje-create-new-filter-modal'
      data-cy='qje-create-new-filter-modal'
      title='Create New Filter'
      open={showModal}
      onCancel={() => {
        onCancel()
        setNewFilters([])
        setCountNewFilters(0)
        form.resetFields()
        Array.apply(null, Array(countNewFilters)).forEach((_, i: any) => {
          form.setFieldValue(`${i}-value`, [])
          form.setFieldValue(`${i}-field`, null)
        })
        setNewFilters([])
        setCountNewFilters(0)
      }}
      onOk={form.submit}
      cancelButtonProps={{
        shape: 'round'
      }}
      okButtonProps={{
        shape: 'round'
      }}
      footer={[
        <Button
          data-testid='qje-save-filter-btn'
          data-cy='qje-save-filter-btn'
          shape='round'
          key='save-view'
          type='primary'
          onClick={form.submit}
        >
          Save View
        </Button>,
        <Button
          data-testid='qje-preview-filter-btn'
          data-cy='qje-preview-filter-btn'
          shape='round'
          key='preview'
          type='default'
          onClick={onPreview}
        >
          Preview
        </Button>,
        <Button
          data-testid='qje-cancel-filter-btn'
          data-cy='qje-cancel-filter-btn'
          shape='round'
          key='cancel'
          type='default'
          onClick={() => {
            onCancel()
            setNewFilters([])
            setCountNewFilters(0)
            form.resetFields()
            Array.apply(null, Array(countNewFilters)).forEach((_, i: any) => {
              form.setFieldValue(`${i}-value`, [])
              form.setFieldValue(`${i}-field`, null)
            })
            setNewFilters([])
            setCountNewFilters(0)
          }}
        >
          Cancel
        </Button>
      ]}
      width={900}
    >
      <Form
        data-testid='qje-create-new-filter-form'
        data-cy='qje-create-new-filter-form'
        form={form}
        layout='vertical'
        name='create-new-filter'
        initialValues={{ remember: true }}
        autoComplete='off'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          data-testid='qje-filter-name-form-item'
          data-cy='qje-filter-name-form-item'
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Enter name of the filter.' }]}
        >
          <Input
            data-testid='qje-filter-name-input'
            data-cy='qje-filter-name-input'
            maxLength={30}
          />
        </Form.Item>

        {newFilters.map((filter: any) => {
          return renderFilterInputComponents(filter)
        })}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <GButton
            dataTestId='qje-add-filter-field-btn'
            dataCy='qje-add-filter-field-btn'
            btnText='Add Filter'
            onClick={() => {
              setNewFilters([
                ...newFilters,
                {
                  key: countNewFilters,
                  field: null,
                  value: []
                }
              ])
              setCountNewFilters(countNewFilters + 1)
            }}
          />
          {newFilters.length > 0 ? (
            <GButton
              dataTestId='qje-remove-all-filter-fields-btn'
              dataCy='qje-remove-all-filter-fields-btn'
              btnText='Remove All'
              onClick={() => {
                resetFields('all')
              }}
            />
          ) : null}
        </div>
      </Form>
    </Modal>
  )
}

export default CreateNewFilterModal
