import tableParentHeader from '../config/tableParentHeader'
import { TApiData } from '../types/api'

/**
 * Generate the data source for the table
 * @param rows - TApiData[]
 * @returns - any[]
 */
const generateDataSource = (rows: TApiData[]) => {
  const finalDataSource: any[] = []
  let previousRowDimensions: any[] = []
  let rowSpanCounters: number[] = []
  const maxRowIndex = rows.length - 1

  rows.forEach((row, rowIndex) => {
    const data: any = { key: rowIndex }
    const { is_totals } = row

    tableParentHeader.forEach((header) => {
      const parent = header.key
      const cells = row[parent]

      if (parent === 'dimensions' && !rowIndex) {
        // ** Initialize rowSpanCounters
        rowSpanCounters = new Array(cells.length).fill(1)
      }

      cells.forEach((cell, cellIndex) => {
        const thisIndex = `${parent}_${cellIndex}`
        if (typeof cell === 'object') {
          data[thisIndex] = cell.value
          data[`${thisIndex}_url`] = cell.url
          return
        } else {
          if (parent === 'dimensions') {
            // ** Initialize values to handle colSpan logic for Subtotals on Dimensions grouping
            if (is_totals && cell) {
              data.is_totals = is_totals
              data.colSpan = cells.length - cellIndex // ** Set colSpan to the remaining cells
              data.colSpanIndex = cellIndex // ** Maps on which index the colSpan should
            }

            // ** Initialize values to handle rowSpan logic on Dimensions grouping
            const rowSpanIndex = `${thisIndex}_rowSpan`
            if (cell && previousRowDimensions.includes(cell)) {
              // ** Increment rowSpanCounters if the cell is included in the previousRowDimensions
              rowSpanCounters[cellIndex]++
              data[rowSpanIndex] = 0 // ** Set rowSpan for the current cell
            } else {
              // ** Skip the first row
              if (rowIndex) {
                const thisRowSpan = rowSpanCounters[cellIndex]
                const firstIndex = rowIndex - thisRowSpan // ** This is the first instance of the cell

                // ** Check if mapped previous dimension has a value
                if (previousRowDimensions[cellIndex]) {
                  // ** Set rowSpan of the first instance of the cell
                  finalDataSource[firstIndex][rowSpanIndex] = thisRowSpan
                } else {
                  // ** Set rowSpan of the empty cell in the previous row
                  finalDataSource[firstIndex][rowSpanIndex] = 0
                }

                // ** Reset rowSpanCounters
                rowSpanCounters[cellIndex] = 1

                // ** Set rowSpan of the last row
                if (rowIndex === maxRowIndex) {
                  data[rowSpanIndex] = cell ? 1 : 0
                }
              }

              // ** Initialize rowSpan of the first row
              else {
                data[rowSpanIndex] = 0
              }
            }
          }
          // ** Set the cell value
          data[thisIndex] = cell
        }
      })
    })

    finalDataSource.push(data)
    previousRowDimensions = row.dimensions
  })

  return finalDataSource
}

export default generateDataSource
