/** 3rd party imports */
import moment from 'moment'

/** react imports */
import { ReactNode } from 'react'

/** react router imports */
import { useNavigate } from 'react-router-dom'

/** custom components imports */
import GResultModal from '../components/gappify/GResultModal'

/** constants imports */
import {
  AUTH_TOKEN,
  EXPIRES_ON,
  SHOW_LOGOUT_MODAL
} from '../helpers/constants/Auth'

/** hooks imports */
import useLogout from '../hooks/useLogout'
import useCountDown from './hooks/useCountDown'
import useIdleTimer from './hooks/useIdleTimer'

/** zustand imports */
import useResultModalStore from './manager/useResultModalStore'

interface ProtectedRouteProps {
  children: ReactNode
}

// =================================================================
export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  /** react router */
  const navigate = useNavigate()

  /** zustand state */
  const { showModal } = useResultModalStore()

  /** hooks */
  const { refetch: logout } = useLogout()
  useIdleTimer()
  useCountDown()

  if (!(localStorage.getItem(SHOW_LOGOUT_MODAL) === 'true')) {
    if (
      moment(localStorage.getItem(EXPIRES_ON)) < moment() ||
      !localStorage.getItem(AUTH_TOKEN)
    ) {
      navigate('/login')
    }
  }

  return (
    <div>
      {localStorage.getItem(AUTH_TOKEN) && (
        <GResultModal
          title={'Your Session has expired due to inactivity'}
          status={'info'}
          subTitle={'Please log-in again to access your account.'}
          visible={showModal}
          height={400}
          onClickOk={logout}
        />
      )}
      {children}
    </div>
  )
}
