/** services imports */
import useApiService from 'src/hooks/useApiService'
import useToggleTokenStatusService from '../services/useToggleTokenStatusService'

/** type imports */
import ClickParams from '../types/ClickParams'

/** tanstack imports */
import { useMutation, useQueryClient } from '@tanstack/react-query'

/** zustand store imports */
import useApiKeyStore from '../manager/useApiKeyStore'

/** antd imports */
import { notification } from 'antd'

// =================================================================
export default function useToggleTokenStatus() {
  /** services */
  const { toggleTokenStatusAPI } = useToggleTokenStatusService()
  const { msUrl } = useApiService()

  /** zustand stores */
  const {
    setPage,
    setSortOrder,
    setIsActivateTokenModalOpen,
    setId,
    setActivateTokenModalMode
  } = useApiKeyStore()

  /** hooks */
  const queryClient = useQueryClient()

  const { mutate: toogleTokenStatus, ...rest } = useMutation(
    toggleTokenStatusAPI,
    {
      onSuccess: () => {
        setPage(1)
        setSortOrder('desc')
        queryClient.invalidateQueries(['api-keys'])
        setIsActivateTokenModalOpen(false)
        setId(null)
        notification.success({
          message: 'Success',
          description: 'Token status has been updated successfully'
        })
      },
      onError: (error) => {
        console.log(error)
        setIsActivateTokenModalOpen(false)
        setId(null)
      }
    }
  )

  const handleToogle = (data: { activate: boolean }, id: number) => {
    toogleTokenStatus({
      options: {
        baseURL: msUrl.idms,
        endpoint: `/api/api-manager/activate/${id}`,
        method: 'PUT'
      },
      data
    })
  }

  const handleToggleTokenStatus = (param: ClickParams, id: number) => {
    if (param.key === 'activate') {
      // ** Activate Token
      setIsActivateTokenModalOpen(true)
      setId(id)
      setActivateTokenModalMode('activate')
    } else {
      // ** Deactivate Token
      setIsActivateTokenModalOpen(true)
      setId(id)
      setActivateTokenModalMode('deactivate')
    }
  }

  return {
    handleToggleTokenStatus,
    handleToogle,
    ...rest
  }
}
