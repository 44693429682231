// ** AntD Imports **
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

// =================================================
const VirtualSpinner = () => (
  <Spin
    indicator={<LoadingOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} style={{ fontSize: 14, color: 'gray' }} spin />}
  />
)

export default VirtualSpinner
