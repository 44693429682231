/** zustand imports */
import { create } from 'zustand'

interface TxnGlobalSettingsProps {
  /** state */
  txnGlobalSettings: object
  isConsolidatedTxnLines: boolean | undefined

  /** actions */
  setTxnGlobalSettings: (txnGlobalSettings: object) => void
  setIsConsolidatedTxnLines: (
    isConsolidatedTxnLines: boolean | undefined
  ) => void
}

// =================================================================
const useTxnGlobalSettingsStore = create<TxnGlobalSettingsProps>((set) => ({
  /** state */
  txnGlobalSettings: {},
  isConsolidatedTxnLines: undefined,

  /** actions */
  setTxnGlobalSettings: (txnGlobalSettings) => set({ txnGlobalSettings }),
  setIsConsolidatedTxnLines: (isConsolidatedTxnLines) =>
    set({ isConsolidatedTxnLines })
}))

export default useTxnGlobalSettingsStore
