// ** React Components and Hooks **
import { FC } from 'react'

// ** Ant Design Components **
import { Pagination, PaginationProps } from 'antd'

// ** Types **
import QjeCustomPagerProps from '../types/QjeCustomPagerProps'

// ** Zustand Store **
import useQjeStore from '../manager/qje-store'

// ========================================================================
const QjeCustomPager: FC<QjeCustomPagerProps> = ({
  disabled
}: QjeCustomPagerProps): JSX.Element => {
  // ** Zustand store **
  const { page, recordsPerPage, tab, totalDataSize, handleTablePagination } =
    useQjeStore()

  // ** Functions **
  const renderPagerStats = (total: number, range: [number, number]) =>
    `Showing ${range[0]}-${range[1]} of ${total}`

  const onChange: PaginationProps['onChange'] = (page, pageSize) =>
    handleTablePagination(page, pageSize)

  return (
    <Pagination
      data-testid='qje-pager'
      data-cy='qje-pager'
      current={page}
      showSizeChanger={true}
      defaultPageSize={recordsPerPage}
      disabled={disabled}
      pageSize={recordsPerPage}
      pageSizeOptions={[100, 250, 500, 1000]}
      showTotal={renderPagerStats}
      total={
        // isPO --> tab !== 'review-je'
        tab !== 'review-je' ? totalDataSize ?? 0 : (totalDataSize ?? 0) / 2
      }
      onChange={onChange}
    />
  )
}

export default QjeCustomPager
