import { FC, MouseEventHandler } from 'react'
import GButton from '../gappify/GButton'

interface LoadMoreButtonProps {
  loading?: boolean
  onClick?: MouseEventHandler<HTMLElement>
}

const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  loading,
  onClick
}: LoadMoreButtonProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '10px'
      }}
    >
      <GButton
        dataTestId='load-more-btn'
        dataCy='load-more-btn'
        btnText='Load More'
        onClick={onClick}
        loading={loading}
      />
    </div>
  )
}

LoadMoreButton.defaultProps = {
  loading: false
}

export default LoadMoreButton
