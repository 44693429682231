import { FC, FormEvent, KeyboardEvent, useEffect, useState } from 'react'
import { Form, Input, Modal, Select, Tag } from 'antd'
import { callGetApi, callPostApi } from '../services/GenericAPI'
import type { SetStateAction } from 'react'
import type { AxiosError } from 'axios'
import type NudgeSelected from '../types/NudgeSelected'
import filterOption from '../utils/filterOption'

interface TxnLineNudgeSelectedModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  onSuccess: () => void
  onError: () => void
  nudgeSelected: NudgeSelected
}

interface NudgeList {
  id: number
  value: string
}

const TxnLineNudgeSelectedModal: FC<TxnLineNudgeSelectedModalProps> = ({
  visible,
  setModalVisible,
  onSuccess,
  onError,
  nudgeSelected
}: TxnLineNudgeSelectedModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [nudgeList, setNudgeList] = useState<NudgeList[]>([])
  const [nudgeUsers, setNudgeUsers] = useState<number[]>([])
  const [nudgeMessage, setNudgeMessage] = useState<string>('')
  const [additionalEmails, setAddtionalEmails] = useState<string[]>([])

  useEffect(() => {
    const getNudgeList = async (): Promise<void> => {
      try {
        const { data } = await callGetApi(nudgeSelected.dependency.url)
        setNudgeList(data)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    if (visible) {
      getNudgeList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleModalOnCancel = (): void => {
    form.resetFields()
    setNudgeList([])
    setNudgeUsers([])
    setNudgeMessage('')
    setAddtionalEmails([])
    setModalVisible(false)
  }

  const handleFormSubmit = (values: any): void => {
    handleModalOk()
  }

  const handleModalOk = async (): Promise<void> => {
    try {
      const payload = nudgeSelected.payload
      payload.line_ids_array = nudgeUsers
      payload.nudge_message = nudgeMessage
      if (nudgeUsers.length === 1) {
        payload.additional_emails = additionalEmails
      } else {
        payload.additional_emails = []
        setAddtionalEmails([])
      }

      const { data } = await callPostApi(nudgeSelected.url, payload)
      if (data) {
        handleModalOnCancel()
        onSuccess()
      } else {
        handleModalOnCancel()
        onError()
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
      handleModalOnCancel()
      onError()
    }
  }

  const handleOnChangeSelect = (values: string[]): void => {
    if (values.length === 0) {
      setNudgeUsers([])
    } else {
      setNudgeUsers(values.map((item) => parseInt(item)))
    }
  }

  const handleAdditionalMessageChange = (
    e: FormEvent<HTMLTextAreaElement>
  ): void => {
    const value = e.currentTarget.value
    setNudgeMessage(value)
  }

  const handleAdditionalEmailsOnKeyUp = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      if (!validateEmail(e.currentTarget.value)) {
        return
      }

      form.setFieldValue('additional_emails', '')
      if (!additionalEmails.includes(e.currentTarget.value)) {
        setAddtionalEmails([...additionalEmails, e.currentTarget.value])
      }
    }
  }

  const handleAdditionalEmailsOnBlur = (e: FormEvent<HTMLInputElement>) => {
    if (!validateEmail(e.currentTarget.value)) {
      return
    }

    form.setFieldValue('additional_emails', '')
    if (!additionalEmails.includes(e.currentTarget.value)) {
      setAddtionalEmails([...additionalEmails, e.currentTarget.value])
    }
  }

  const validateEmail = (email: string): RegExpMatchArray | null => {
    return email
      .toLocaleLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const handleRemoveAddtionalEmail = (emailToBeRemove: string) => {
    setAddtionalEmails(
      additionalEmails.filter((email) => email !== emailToBeRemove)
    )
  }

  return (
    <Modal
      data-testid='txn-line-nudge-selected-modal'
      data-cy='txn-line-nudge-selected-modal'
      title='Nudge Selected'
      open={visible}
      okText='Submit'
      onCancel={handleModalOnCancel}
      onOk={handleModalOk}
      okButtonProps={{
        shape: 'round',
        disabled: nudgeUsers.length === 0
      }}
      cancelButtonProps={{
        shape: 'round'
      }}
      destroyOnClose
    >
      <Form
        data-testid='txn-line-nudge-selected-modal-form'
        data-cy='txn-line-nudge-selected-modal-form'
        form={form}
        onFinish={handleFormSubmit}
        layout='vertical'
      >
        <Form.Item
          label='Nudge users'
          name='nudge_users'
          rules={[{ required: true, message: 'Please select users' }]}
        >
          <Select
            data-testid='txn-line-nudge-selected-modal-picklist-field'
            data-cy='txn-line-nudge-selected-modal-picklist-field'
            mode='multiple'
            placeholder='Search'
            onChange={handleOnChangeSelect}
            filterOption={filterOption}
            allowClear
            optionFilterProp='children'
          >
            {nudgeList
              .sort((a: any, b: any) => {
                return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
              })
              .map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.value}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {nudgeUsers.length > 0 && (
          <>
            <Form.Item
              label='Additional Emails'
              name='additional_emails'
              rules={[{ type: 'email', message: 'Not a valid email' }]}
            >
              <Input
                data-testid='txn-line-nudge-selected-modal-additional-email-input-field'
                data-cy='txn-line-nudge-selected-modal-additional-email-input-field'
                placeholder='Additional email to nudge'
                onBlur={handleAdditionalEmailsOnBlur}
                onKeyUp={handleAdditionalEmailsOnKeyUp}
                disabled={nudgeUsers.length > 1}
              />
            </Form.Item>
            {additionalEmails.length > 0 && (
              <>
                {nudgeUsers.length === 1 && (
                  <>
                    {additionalEmails.map((item) => (
                      <Tag
                        data-testid={`txn-line-nudge-selected-modal-tag-${item}`}
                        data-cy={`txn-line-nudge-selected-modal-tag-${item}`}
                        closable
                        key={item}
                        color='#87d068'
                        onClose={() => handleRemoveAddtionalEmail(item)}
                      >
                        {item}
                      </Tag>
                    ))}
                  </>
                )}
              </>
            )}
            <div
              style={{
                marginBottom: '30px'
              }}
            >
              <span
                style={{
                  color: '#26929c'
                }}
              >
                Please note additional email is only applicable to one user.
                Selecting more than one user will disable this section.
              </span>
            </div>
          </>
        )}
        <Form.Item label='Additional message' name='nudge_message'>
          <Input.TextArea
            data-testid='txn-line-nudge-selected-modal-additional-message-textarea-field'
            data-cy='txn-line-nudge-selected-modal-additional-message-textarea-field'
            size='middle'
            placeholder='A standard reminder will be sent. Add any additional custom message here'
            onChange={handleAdditionalMessageChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

TxnLineNudgeSelectedModal.defaultProps = {
  visible: false
}

export default TxnLineNudgeSelectedModal
