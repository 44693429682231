// ** AntD Imports
import {
  DatePicker,
  Form,
  Popconfirm,
  Select,
  TableColumnGroupType,
  TableColumnType,
  TimePicker,
  Tooltip
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import GButton from '../../../../../components/gappify/GButton'
import GlobalHistoryModal from '../../../../../components/GlobalHistoryModal'

// ** Third Party Imports
import moment, { Moment } from 'moment-timezone'
import { useQueryClient } from '@tanstack/react-query'

// ** Type Imports
import DataType from '../types/MonthlyDataType'

// ** Enums Imports
import TaskTypeEnum from '../enums/TaskTypeEnum'
import ActiveEnumObject from '../enums/ActiveEnum'
import useScheduleStore from '../manager/schedule-store'
import { getDatetimeDifference } from '../utils/dateUtil'

// ** Data Imports **
import UpdatesData from './UpdatesData'
import { getCurrentPeriod } from '../../../../../utils/Date'
import { getTaskScheduleHistory } from '../../../../../services/HistoryAPI'
import useTxnGlobalSettings from '../../../../../hooks/useTxnGlobalSettings'
import useRolesAndPermissions from '../../../../../hooks/useRolesAndPermissions'

// ** Style Imports **
import styles from '../styles/timepicker.module.css'

// ** Util Imports **
import { rearrangeArray } from '../utils/util'
import { getInstance } from '../../../../../utils/get-instance'

// =================================================================
export default function MonthlyData() {
  // ** For testing only
  // const now = moment()
  // console.log(`nowtime`, now.format('YYYY-MM-DDTHH:mm:ss'))

  // ** Hooks **
  const {
    updateMonthlyEditPayload,
    period,
    loadingButtons,
    handleRunNow,
    handleReset,
    errorOnMonthlyUpdate,
    editMonthly
  } = useScheduleStore()
  const RP = useRolesAndPermissions()

  const queryClient = useQueryClient()

  const {
    data: txnGlobalSettings,
    isLoading,
    isRefetching
  } = useTxnGlobalSettings()

  const monthly: Record<string, []> | undefined = queryClient.getQueryData([
    'schedule-manager-monthly',
    period?.key
  ])

  const isGappifyAdministrator = (): boolean => {
    let roles = JSON.parse(localStorage.getItem('rolesAndPermissions')!).roles
    return roles.includes('Gappify-Administrator')
  }

  // ** Commenting for future use
  // const getDisabledHours = (item?: DataType) => {
  //   let hours = []
  //   let currentHour = moment().hour()
  //   let existingInPayload = monthlyEditPayload.find(
  //     (_item: MonthlyDataType) => item?.taskScheduleId === _item.taskScheduleId
  //   )
  //   let current = moment()
  //   let selected = moment(existingInPayload?.nextRunTime || item?.nextRunTime)
  //   let currentClone = moment(current.format('YYYY-MM-DD'), 'YYYY-MM-DD')
  //   let selectedClone = moment(selected.format('YYYY-MM-DD'), 'YYYY-MM-DD')
  //   if (currentClone.isSame(selectedClone, 'day')) {
  //     for (let i = 0; i <= currentHour; i++) {
  //       hours.push(i)
  //     }
  //   } else hours = []
  //   return hours
  // }

  // ** COmmenting for future use
  // const getDisabledMinutes: (
  //   item?: DataType,
  //   seletedHours?: number
  // ) => number[] = (item?: DataType, selectedHours?: number) => {
  //   let minutes: number[] = []
  //   let currentHour = moment().hour()
  //   let currentMinute = moment().minute()
  //   let existingInPayload = monthlyEditPayload.find(
  //     (_item: MonthlyDataType) => item?.taskScheduleId === _item.taskScheduleId
  //   )
  //   let current = moment()
  //   let selected = moment(existingInPayload?.nextRunTime || item?.nextRunTime)
  //   let currentClone = moment(current.format('YYYY-MM-DD'), 'YYYY-MM-DD')
  //   let selectedClone = moment(selected.format('YYYY-MM-DD'), 'YYYY-MM-DD')
  //   if (currentClone.isSame(selectedClone, 'd')) {
  //     if (selectedHours! > currentHour + 1) {
  //       // Do nothing, minutes array remains empty
  //     } else if (selectedHours === currentHour + 1) {
  //       for (let i = 0; i < currentMinute; i++) {
  //         minutes.push(i)
  //       }
  //     } else {
  //       for (let i = 0; i <= 60; i++) {
  //         minutes.push(i)
  //       }
  //     }
  //   } else if (currentClone.add(1, 'd').isSame(selectedClone, 'd')) {
  //     if (selectedHours === 0) {
  //       for (let i = 0; i < currentMinute; i++) {
  //         minutes.push(i)
  //       }
  //     }
  //   }
  //   return minutes
  // }

  //  ** Data **
  const { columns: historyColumns } = UpdatesData()

  // ** This will add red border and help text to form items when update api returns an error
  const getErrorProps = (
    taskScheduleId: string | number,
    error_field: string
  ) => {
    if (
      errorOnMonthlyUpdate &&
      errorOnMonthlyUpdate?.length > 0 &&
      errorOnMonthlyUpdate?.find(
        (errorLine) =>
          errorLine.taskScheduleId === taskScheduleId &&
          errorLine.error_field === error_field
      )
    ) {
      return {
        help: `${errorOnMonthlyUpdate?.find(
          (errorLine) =>
            errorLine.taskScheduleId === taskScheduleId &&
            errorLine.error_field === error_field
        )?.message!}`,
        validateStatus: 'error' as 'error'
      }
    }
  }

  const rowsFromBackend =
    rearrangeArray(
      monthly?.content?.filter(
        (item: DataType) => item.workType !== 'VendorTask'
      ) as DataType[]
    )?.map((item: Record<string, any>, index: number) => {
      const nextRunDate = item.nextRunTime
        ? moment(item.nextRunTime.slice(0, 10)).format('MMMM D, YYYY')
        : null

      const nextRunTime = item.nextRunTime
        ? moment(item.nextRunTime.slice(10, 19), 'THH:mm:ss').format('hh:mm A')
        : null

      return {
        key: (index + 1).toString(),
        nextRunDate, // you can set a default value here instead of null if necessary
        nextRunTime, // you can set a default value here instead of null if necessary
        active: item.active ? 'Yes' : 'No',
        taskType: TaskTypeEnum.getDscpFromKey(item.taskType!),
        status: item.statusLabel,
        taskScheduleId: item.taskScheduleId,
        isDisabledRunNow: item.isDisabledRunNow,
        scheduleLock: item.scheduleLock,
        historyId: item.historyId
      }
    }) || []

  const editableRowsFromBackend =
    rearrangeArray(
      monthly?.content?.filter(
        (item: DataType) => item.workType !== 'VendorTask'
      ) as DataType[]
    )?.map((item: Record<string, any>, index: number) => ({
      key: item.taskScheduleId,
      nextRunDate: (
        <Form.Item
          name={`${item.taskScheduleId}-launch-date-picker`}
          rules={[{ required: true, message: 'This field is required.' }]}
          style={{ margin: 0 }}
          initialValue={
            item.nextRunTime ? moment(item.nextRunTime.slice(0, 10)) : undefined
          }
          {...getErrorProps(item?.taskScheduleId!, 'launch_date')}
        >
          <DatePicker
            format='MMMM D, YYYY'
            defaultValue={
              item.nextRunTime
                ? moment(item.nextRunTime.slice(0, 10))
                : undefined
            }
            onChange={(_, dateString) => {
              /**
               * ? BUGFIX: ENG-7056 & ENG-7361
               * ? Condition to not run if close button is clicked on the datepicker
               */
              if (dateString) {
                let selected = moment(dateString, 'MMMM D, YYYY')
                updateMonthlyEditPayload(
                  {
                    taskScheduleId: item.taskScheduleId,
                    customerId: item.customerId,
                    envName: item.envName
                  },
                  {
                    year: Number(selected.format('YYYY')),
                    month: Number(selected.format('MM')) - 1,
                    date: Number(selected.format('DD'))
                  },

                  item.nextRunTime
                )
              }
            }}
            disabled={
              Math.abs(
                getDatetimeDifference(
                  moment(new Date()),
                  moment(item.nextRunTime),
                  'minutes'
                )
              ) < 60 || item.scheduleLock
            }
            disabledDate={(currentDate: Moment) => {
              let currentPSTDate = moment()
              let calendarEntry = moment(
                currentDate.format('YYYY-MM-DD'),
                'YYYY-MM-DD'
              )
              let getEnd = () => {
                if (currentPSTDate.date() <= 15) return moment().set('date', 15)
                else return moment().add(1, 'month').set('date', 15)
              }

              return (
                calendarEntry.isBefore(
                  moment(currentPSTDate.format('YYYY-MM-DD'), 'YYYY-MM-DD')
                ) ||
                calendarEntry.isAfter(
                  moment(getEnd().format('YYYY-MM-DD'), 'YYYY-MM-DD')
                )
              )
            }}
          />
        </Form.Item>
      ),
      nextRunTime: (
        <div
          style={{
            height: '100%'
          }}
        >
          <Form.Item
            initialValue={
              item.nextRunTime
                ? moment(item.nextRunTime!.slice(10, 19), 'THH:mm:ss')
                : undefined
            }
            name={`${item.taskScheduleId}-launch-time-picker`}
            rules={[{ required: true, message: 'This field is required.' }]}
            style={{ margin: 0 }}
            // ** Can also be used on other columns, just change launch_time
            {...getErrorProps(item?.taskScheduleId!, 'launch_time')}
          >
            <TimePicker
              className={styles.timepicker}
              popupClassName={styles.timepickerPopup}
              // ** Commenting out for future use **
              // onSelect={(time: Moment) => console.log('selected time', time)}
              // disabledTime={() => ({
              //   disabledMinutes: (selectedHours) =>
              //     getDisabledMinutes(item, selectedHours) as number[],
              //   disabledHours: () => getDisabledHours(item) as number[]
              // })}
              use12Hours
              format='h:mm A'
              // defaultValue={moment(
              //   item.nextRunTime!.slice(10, 19),
              //   'THH:mm:ss'
              // )}
              defaultValue={
                item.nextRunTime
                  ? moment(item.nextRunTime!.slice(10, 19), 'THH:mm:ss')
                  : undefined
              }
              onChange={(e, timeString) => {
                let selected = moment(timeString, 'h:mm A')
                updateMonthlyEditPayload(
                  {
                    taskScheduleId: item.taskScheduleId,
                    customerId: item.customerId,
                    envName: item.envName
                  },
                  {
                    hours: Number(selected.format('HH')),
                    minute: Number(selected.format('mm')),
                    second: Number(selected.format('ss'))
                  },
                  item.nextRunTime
                )
              }}
              disabled={
                Math.abs(
                  getDatetimeDifference(
                    moment(new Date()),
                    moment(item.nextRunTime),
                    'minutes'
                  )
                ) < 60 || item.scheduleLock
              }
            />
          </Form.Item>
        </div>
      ),
      active: (
        <Form.Item name={`${item.taskScheduleId}-active-dropdown`}>
          <Select
            defaultValue={item.active}
            onChange={(value: boolean) => {
              updateMonthlyEditPayload({
                taskScheduleId: item.taskScheduleId,
                customerId: item.customerId,
                envName: item.envName,
                active: value
              })
            }}
            options={ActiveEnumObject.getKeyValueList().map((pair) => {
              return {
                value: pair.id,
                label: pair.value
              }
            })}
            disabled={
              Math.abs(
                getDatetimeDifference(
                  moment(new Date()),
                  moment(item.nextRunTime),
                  'minutes'
                )
              ) < 60 || item.scheduleLock
            }
          />
        </Form.Item>
      ),
      taskType: TaskTypeEnum.getDscpFromKey(item.taskType!),
      status: item.status,
      taskScheduleId: item.taskScheduleId,
      isDisabledRunNow: item.isDisabledRunNow,
      scheduleLock: item.scheduleLock,
      historyId: item.historyId
    })) || []

  let columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: '',
      key: 'action',
      width: '50px',
      render: (text: string, record: DataType) => (
        <GlobalHistoryModal
          reloadData={() => {
            return getTaskScheduleHistory(record.historyId!)
          }}
          columns={historyColumns}
          hasExportButton={false}
          disableHistoryModal={!RP.USER_HAS_HISTORY_SHOW_PERMISSION}
        />
      )
    },
    // ** Commenting out, this is used for inline editing **
    // {
    //   title: 'Action',
    //   key: 'edit',
    //   render: (text: string, record: DataType) => (
    //     <div style={{ display: 'flex', gap: 5 }}>
    //       {!editLines.includes(record.taskScheduleId!) ? (
    //         <InlineEditButton
    //           btnKey={record.taskScheduleId!}
    //           enabled={!record.scheduleLock}
    //         />
    //       ) : (
    //         <>
    //           <InlineSaveButton btnKey={record.taskScheduleId!} />
    //           <InlineCancelButton btnKey={record.taskScheduleId!} />
    //         </>
    //       )}
    //     </div>
    //   )
    // },
    {
      title: (
        <>
          Launch Date{' '}
          {editMonthly && (
            <small>
              <Tooltip
                overlayInnerStyle={{
                  fontSize: 11,
                  width: '100%'
                }}
                title='Please select a date within the current period. Date must not be in the past.'
              >
                <InfoCircleOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />
              </Tooltip>
            </small>
          )}
        </>
      ),
      dataIndex: 'nextRunDate',
      key: 'nextRunDate',
      width: '300px'

      // ** Commenting for reference **
      // render: (text: string, record: DataType) => {
      //   if (editLines.includes(record.taskScheduleId!))
      //     return editableRowsFromBackend.find(
      //       (row) => row.key === record.taskScheduleId
      //     )?.nextRunDate
      //   else return text
      // }
    },
    {
      title: 'Launch Time (PST)',
      dataIndex: 'nextRunTime',
      key: 'nextRunTime',
      width: '300px'

      // ** Commenting for reference **
      // render: (text: string, record: DataType) => {
      //   if (editLines.includes(record.taskScheduleId!))
      //     return editableRowsFromBackend.find(
      //       (row) => row.key === record.taskScheduleId
      //     )?.nextRunTime
      //   else return text
      // }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: '300px'

      // ** Commenting for reference **
      // render: (text: string, record: DataType) => {
      //   if (editLines.includes(record.taskScheduleId!))
      //     return editableRowsFromBackend.find(
      //       (row) => row.key === record.taskScheduleId
      //     )?.active
      //   else return text
      // }
    },
    {
      title: 'Accrual Type',
      dataIndex: 'taskType',
      key: 'taskType',
      width: '300px'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '300px'
    }
  ]

  let getStart: () => Moment = () => {
    if (moment(getCurrentPeriod()).date() >= 16) {
      return moment(getCurrentPeriod().set({ date: 16 }))
    } else
      return moment(getCurrentPeriod()).subtract(1, 'month').set({ date: 16 })
  }

  // ** Remove action column if previous period
  // ** Commented out, used for inline editing
  // if (
  //   !moment(getStart()).isBefore(
  //     moment(period?.key).set({ date: moment(getCurrentPeriod()).date() })
  //   )
  // ) {
  //   columns = columns.filter((col) => col.key !== 'edit')
  // }

  if (
    !isLoading &&
    !isRefetching &&
    // ** Check if selected period is the current period **
    moment(getStart()).isSameOrBefore(
      moment(period?.key).set({ date: moment(getCurrentPeriod()).date() }),
      'D'
    ) &&
    // ** Check if Gappify-Administrator and NOT customer ready **
    isGappifyAdministrator() &&
    !txnGlobalSettings?.['transaction.feature_flags']?.customer_ready
  ) {
    columns = [
      ...columns,
      {
        title: 'Execute',
        key: 'execute',
        width: '50px',
        render: (text: string, record: DataType) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5
              }}
            >
              <Popconfirm
                title='Do you want to proceed? This will create new transactions immediately.'
                onConfirm={() => handleRunNow(record.taskScheduleId!)}
                okText='Yes'
                cancelText='No'
              >
                <GButton
                  btnText='Run Now'
                  loading={loadingButtons.includes(
                    `run_now_${record.taskScheduleId}`
                  )}
                  disabled={record.isDisabledRunNow}
                />
              </Popconfirm>

              {/* only render button if not PROD */}
              {getInstance(window.location.hostname) !== 'prod' && (
                <Popconfirm
                  title='Are you sure you want to reset the schedule line? Once reset, it cannot be undone.'
                  onConfirm={() => handleReset(record.taskScheduleId!)}
                  okText='Yes'
                  cancelText='No'
                >
                  <GButton
                    btnText='Reset Scheduler'
                    loading={loadingButtons.includes(
                      `reset_${record.taskScheduleId}`
                    )}
                    disabled={
                      record.isDisabledRunNow ||
                      loadingButtons.includes(
                        `run_now_${record.taskScheduleId}`
                      )
                    }
                  />
                </Popconfirm>
              )}
            </div>
          )
        }
      }
    ]
  }

  // ** Mock Data **
  const createRows = (numRows: number) => {
    const baseRow = {
      launchDate: 'June 29th, 2023',
      launchTime: '09:51AM',
      active: 'Yes',
      accrualType: 'External Vendors',
      status: 'Scheduled'
    }

    const launchDates = ['June 29th, 2023']

    const launchTimes = ['09:51AM']

    const actives = ['Yes', 'No']

    const accrualTypes = [
      'External Vendors',
      'Calculated',
      'Internal Partners',
      'Manual'
    ]

    const statuses = ['Scheduled ', 'Inactive']

    return Array.from({ length: numRows }, (_, i) => {
      const randomLaunchDates =
        launchDates[Math.floor(Math.random() * launchDates.length)]
      const randomLaunchTimes =
        launchTimes[Math.floor(Math.random() * launchTimes.length)]
      const randomActives = actives[Math.floor(Math.random() * actives.length)]
      const randomAccruals =
        accrualTypes[Math.floor(Math.random() * accrualTypes.length)]
      const randomStatus = statuses[Math.floor(Math.random() * statuses.length)]
      return {
        key: (i + 1).toString(),
        ...baseRow,
        launchDate: randomLaunchDates,
        launchTime: randomLaunchTimes,
        active: randomActives,
        accrualType: randomAccruals,
        status: randomStatus
      }
    })
  }
  const rows = createRows(3)

  return { columns, rows, rowsFromBackend, editableRowsFromBackend }
}
