// ** Custom Component Imports
import EditButton from '../../../components/EditButton'
import StandardData from '../../../mock/StandardData'

// =================================================================
const TableHeader = () => {
  // ** Data **
  const { rowsFromBackend } = StandardData()

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginLeft: 'auto' }}>
        <EditButton
          btnKey='standard-edit'
          enabled={rowsFromBackend.length > 0}
        />
      </div>
    </div>
  )
}

export default TableHeader
