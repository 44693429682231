/** Antd imports */
import { Card, message, Space, Spin, Typography } from 'antd'
import Title from 'antd/lib/typography/Title'

/** Moment */
import moment from 'moment-timezone'

/** Components */
import TooltipIcon from '../TooltipIcon'
import SyncModal from './components/SyncModal'
import SyncDetailsModal from './components/SyncDetailsModal'

/** React Imports */
import { useEffect, useState } from 'react'

/** Hooks */
import useAccrualManagerStore from './manager/accrual-manager-store'
import useSubmitSyncAll from './hooks/useSubmitSyncAll'
import useFetchSyncAllStatus from 'src/hooks/useFetchSyncAllStatus'

/** Utils */
import { SYNC_STATUSES } from './constants/constants'
import { useQueryClient } from '@tanstack/react-query'

const { Text } = Typography

const AccrualManager = () => {
  /** Local states */
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false)

  const queryClient = useQueryClient()

  /** Global stores */
  const syncStatus = useAccrualManagerStore((state) => state.syncStatus)
  const setSyncStatus = useAccrualManagerStore((state) => state.setSyncStatus)

  /** React queries */
  const {
    data: dataFetchSyncAllStatus,
    error: errorFetchSyncAllStatus,
    isLoading: isLoadingFetchSyncAllStatus,
    isRefetching: isRefetchingFetchSyncAllStatus,
    isSuccess: isSuccessFetchSyncAllStatus,
    isError: isErrorFetchSyncAllStatus
  } = useFetchSyncAllStatus()

  const {
    mutate: submitSynAll,
    data: dataSubmitSyncAll,
    error: errorSubmitSyncAll,
    isLoading: isLoadingSubmitSyncAll,
    isSuccess: isSuccessSubmitSyncAll,
    isError: isErrorSubmitSyncAll
  } = useSubmitSyncAll()

  /** Helpers */
  const handleSyncAll = () => {
    submitSynAll()
  }

  const getLastSync = (timestamp: string | undefined) => {
    if (!timestamp) return 'Last sync time not available.'
    return moment(timestamp).format('YYYY-MM-DD, hh:mm:ss A [(]z[)]')
  }

  /** Use effects */
  useEffect(() => {
    if (isSuccessFetchSyncAllStatus && dataFetchSyncAllStatus?.data) {
      // Set sync status based on the response
      if (dataFetchSyncAllStatus.data.is_syncing) {
        setSyncStatus('IN_PROGRESS')
      } else {
        /**
         * Conditions for different status
         * - Success: processed_rows === total_rows
         * - Error: failed_rows === total_rows
         * - Partial: failed_rows > 0 (else block)
         */
        if (
          dataFetchSyncAllStatus.data.processed_rows ===
          dataFetchSyncAllStatus.data.total_rows
        ) {
          setSyncStatus('SUCCESS')
        } else if (
          dataFetchSyncAllStatus.data.failed_rows ===
          dataFetchSyncAllStatus.data.total_rows
        ) {
          setSyncStatus('ERROR')
        } else {
          setSyncStatus('PARTIAL')
        }
      }
    }

    if (isErrorFetchSyncAllStatus) {
      /**
       * ? DEV NOTES:
       * ? No expected error scenarios for this query
       * ? Add error handling here if needed
       */
      console.log('Error response from API:', errorFetchSyncAllStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFetchSyncAllStatus])

  useEffect(() => {
    if (isSuccessSubmitSyncAll) {
      if (dataSubmitSyncAll.success) {
        message.success(dataSubmitSyncAll.message)
      }

      // Set status as In Progress only with this exact message response
      // This is only applicable if there are out-of-sync lines found
      // This is the current BE response for this scenario
      if (dataSubmitSyncAll.message === 'Started sync all') {
        setSyncStatus('IN_PROGRESS')

        // Invalidate the query to trigger a re-fetch
        queryClient.invalidateQueries({ queryKey: ['sync-all-status'] })
      }

      setIsSyncModalOpen(false)
    }

    if (isErrorSubmitSyncAll) {
      let errorMsg =
        errorSubmitSyncAll.message || // Exact error message from the API (if any)
        'Failed to start sync all. Please try again.' // Fallback generic error message

      message.error(errorMsg)
      setIsSyncModalOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubmitSyncAll])

  return (
    <>
      {/* Title */}
      <Title
        level={3}
        data-testid='accrual-manager-header'
        data-cy='accrual-manager-header'
        style={{ marginBottom: 15 }}
      >
        Accrual Manager
      </Title>

      {/* Card */}
      <Card
        data-testid='accrual-manager-card'
        data-cy='accrual-manager-card'
        bodyStyle={{ display: 'flex', gap: 75 }}
      >
        <Text style={{ flex: '0 0 150px' }}>
          Sync All Lines
          <TooltipIcon
            data-testid='sync-all-tooltip'
            data-cy='sync-all-tooltip'
            message='Use this action to sync all Accrual Manager line data to match the source system.'
          />
        </Text>
        <Space direction='vertical'>
          {isLoadingFetchSyncAllStatus || isRefetchingFetchSyncAllStatus ? (
            <Spin />
          ) : (
            <>
              <SyncModal
                isLoadingSubmitSyncAll={isLoadingSubmitSyncAll}
                isSyncModalOpen={isSyncModalOpen}
                setIsSyncModalOpen={setIsSyncModalOpen}
                handleSyncAll={handleSyncAll}
              />

              {dataFetchSyncAllStatus?.data && (
                <Space align='start'>
                  {SYNC_STATUSES[syncStatus!]?.icon}
                  <Space direction='vertical'>
                    <Text
                      strong
                      data-testid='succesfully-synced-text'
                      data-cy='succesfully-synced-text'
                    >
                      {SYNC_STATUSES[syncStatus!]?.label}
                    </Text>
                    <Text
                      type='secondary'
                      data-testid='last-sync-text'
                      data-cy='last-sync-text'
                    >
                      Last Sync:{' '}
                      {getLastSync(dataFetchSyncAllStatus.data.last_sync_time)}
                    </Text>

                    {syncStatus !== 'IN_PROGRESS' && <SyncDetailsModal />}
                  </Space>
                </Space>
              )}
            </>
          )}
        </Space>
      </Card>
    </>
  )
}

export default AccrualManager
