import { LockOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Input } from 'antd'
import { FC } from 'react'

interface LoginFormProps {
  errorMessage: string
  form: FormInstance<any>
  submitForm: (values: { [name: string]: any }) => void
  loading: boolean
}

const LoginForm: FC<LoginFormProps> = ({
  errorMessage,
  form,
  submitForm,
  loading
}: LoginFormProps): JSX.Element => {
  return (
    <div
      className='login-form-container'
      data-testid='transaction-column-manager-login-form'
      data-cy='transaction-column-manager-login-form'
    >
      {errorMessage && <div className='login-error-msg'>{errorMessage}</div>}
      <Form form={form} onFinish={submitForm}>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            data-testid='transaction-column-manager-login-password-field'
            data-cy='transaction-column-manager-login-password-field'
            placeholder='Password'
            prefix={<LockOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}/>}
          />
        </Form.Item>
        <Form.Item>
          <Button
            data-testid='transaction-column-manager-login-btn'
            data-cy='transaction-column-manager-login-btn'
            type='primary'
            htmlType='submit'
            className='login-btn'
            loading={loading}
          >
            LOG IN
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginForm
