import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Button,
  InputNumber,
  DatePicker,
  Popconfirm
} from 'antd'
import { FC, useEffect, useState } from 'react'
import GButton from '../gappify/GButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import GIcon from '../gappify/GIcon'
import {
  getFilterFields,
  createFilter,
  updateFilter,
  getFilterDetails,
  deleteFilter,
  getOptions
} from '../../services/TaskManagerApi'
import {
  setTasks,
  setIsLoading,
  setCurrPage,
  setTsmPreviewMode,
  setTsmFilterFields
} from '../../context/actions/taskManagerAction'
import { useDispatch } from 'react-redux'
import {
  getNumericTypes,
  getNonNumericFields
} from '../../utils/numeric-fields'
import moment from 'moment'
import { getPredefinedOptions } from '../../utils/task-manager/getPredefinedOptions'
import filterSort from '../../utils/filterSort'
import filterOption from '../../utils/filterOption'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'
const { RangePicker } = DatePicker

export interface CreateNewFilterModalProps {
  overrideIsVisibleModal?: any
  overrideSetIsVisibleModal?: any
  overrideFinishLabel?: string | JSX.Element
  overrideLabel?: string | JSX.Element
  overrideHeader?: string | JSX.Element
  filterId?: any
  setIsLoading?: any
  callGetAllFilters: () => any
  activeFilterRef?: any
  getFilterLabel?: () => string
  setActiveFilter?: any
  reloadData?: any
  setIsPreviewMode?: any
  setPreviewTriggeredFrom?: any
  willResetFormOnSuccesfulFinish?: boolean
  setApplyToSelectedRows?: any
  setSelectedRows?: any
  setEditKey?: any
  filterModalType?: 'create' | 'update'
}

export interface CreateUpdateFilterPayload {
  filter_name?: string
  previewMode?: boolean
  filter_fields?: any[]
}

const CreateNewFilterModal: FC<CreateNewFilterModalProps> = ({
  overrideIsVisibleModal,
  overrideSetIsVisibleModal,
  overrideFinishLabel,
  overrideLabel,
  overrideHeader,
  filterId,
  callGetAllFilters,
  activeFilterRef,
  setActiveFilter,
  reloadData,
  setIsPreviewMode,
  setPreviewTriggeredFrom,
  willResetFormOnSuccesfulFinish,
  setApplyToSelectedRows,
  setSelectedRows,
  setEditKey,
  filterModalType
}: CreateNewFilterModalProps): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
  const [newFilters, setNewFilters] = useState<any[]>([])
  const [countNewFilters, setCountNewFilters] = useState<any>(0)

  const [suggestedValues, setSuggestedValues] = useState<
    { id: any; value: any }[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const [focusedApiName, setFocusedApiName] = useState<string>('')

  const [filterFields, setFilterFields] = useState<any>()
  const [filterDetails, setFilterDetails] = useState<any>()

  const RP = useRolesAndPermissions()

  const loadFilterFields = async () => {
    let filterFields: any = []
    if (RP.USER_HAS_GET_TM_FILTER_FIELDS_PERMISSION) {
      await getFilterFields().then((res) => {
        filterFields = res.data
      })
    }
    return filterFields
  }

  const onSubmit = async (e?: any, previewMode?: boolean) => {
    let payload: CreateUpdateFilterPayload = {}
    if (newFilters.length === 0)
      message.error('Please select at least one filter.')
    else {
      if (!previewMode && e) payload.filter_name = e.name

      let filterFields = newFilters.map((filter) => {
        let columnDetails: {
          column: string
          value: any
          operator?: string
        } = {
          column: filter.field,
          value: filter.value
        }
        if (filter.operator) columnDetails.operator = filter.operator
        return columnDetails
      })

      payload.filter_fields = filterFields
      dispatch(setTsmFilterFields(filterFields))

      payload.previewMode = previewMode || false

      let reloadDataPayload: any = {
        page: 1
      }

      if (previewMode) {
        setIsPreviewMode(true)
        dispatch(setTsmPreviewMode(true))
        setPreviewTriggeredFrom(filterModalType === 'update' ? 'update' : 'new')

        reloadData({
          ...reloadDataPayload,
          previewMode: true,
          filter_fields: filterFields
        })
      } else {
        try {
          const { data } =
            filterModalType === 'update'
              ? await updateFilter(filterId, payload)
              : await createFilter(payload)

          setIsPreviewMode(false)
          dispatch(setTsmPreviewMode(false))

          activeFilterRef.current = {
            id: data?.data?.data?.default_filter || filterId,
            label:
              data?.data?.data?.all_filters[data?.data?.data?.default_filter] ||
              payload.filter_name
          }
          setActiveFilter({
            id: data?.data?.data?.default_filter || filterId,
            label:
              data?.data?.data?.all_filters[data?.data?.data?.default_filter] ||
              payload.filter_name
          })

          reloadDataPayload.filter = `${
            data?.data?.data?.default_filter || filterId
          }`.trim()

          message.success(data.message)
          reloadData(reloadDataPayload)

          if (willResetFormOnSuccesfulFinish) resetFields('all', true)
        } catch (error) {
          message.error('An error occurred.')
        }
        callGetAllFilters()
      }
      if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
      else setIsVisibleModal(false)

      setApplyToSelectedRows([])
      setSelectedRows([])
      setEditKey('')

      dispatch(setIsLoading(false))
      dispatch(setCurrPage(1))
    }
  }

  // eslint-disable-next-line
  const onPreview = () => {
    if (newFilters.length === 0) {
      message.error('Please select at least one filter.')
    } else {
      dispatch(setIsLoading(true))
      let filterFields = newFilters.map((filter) => {
        let columnDetails: {
          column: string
          value: any
          operator?: string
        } = {
          column: filter.field,
          value: filter.value
        }
        if (filter.operator) columnDetails.operator = filter.operator
        return columnDetails
      })

      reloadData({
        filter: 'all',
        page: 1,
        previewMode: true,
        filterFields: filterFields
      })
        .then((res: any) => {
          dispatch(setTasks(res.data))
        })
        .finally(() => {
          if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
          else setIsVisibleModal(false)
          setPreviewTriggeredFrom(
            filterModalType === 'update' ? 'update' : 'new'
          )
          setIsPreviewMode(true)
        })
    }
  }

  // eslint-disable-next-line
  const onFinish = async (e: any) => {
    if (newFilters.length === 0)
      message.error('Please select at least one filter.')
    else {
      dispatch(setIsLoading(true))
      let payload: any = {}
      payload.filter_name = e.name
      payload.filter_fields = newFilters.map((filter) => {
        let columnDetails: { column: string; value: any; operator?: string } = {
          column: filter.field,
          value: filter.value
        }
        if (filter.operator) columnDetails.operator = filter.operator
        return columnDetails
      })

      try {
        const { data } =
          (filterModalType === 'update' &&
            (await updateFilter(filterId, payload))) ||
          (await createFilter(payload))

        let reloadDataPayload: { page?: number; filter?: number | string } = {
          page: 1
        }

        if (filterModalType === 'create') {
          // new...
          activeFilterRef.current = {
            id: data.data.data.default_filter,
            label: data.data.data.all_filters[data.data.data.default_filter]
          }
          setActiveFilter({
            id: data.data.data.default_filter,
            label: data.data.data.all_filters[data.data.data.default_filter]
          })
          reloadDataPayload.filter = `${data.data.data.default_filter}`.trim()
        } else {
          activeFilterRef.current = {
            id: filterId,
            label: payload.filter_name
          }
          setActiveFilter({
            id: filterId,
            label: payload.filter_name
          })
          reloadDataPayload.filter = `${filterId}`.trim()
        }

        message.success(data.message)
        dispatch(setIsLoading(false))
        if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
        else setIsVisibleModal(false)

        setIsPreviewMode(false)
        dispatch(setCurrPage(1))
        reloadData(reloadDataPayload)

        if (willResetFormOnSuccesfulFinish) resetFields('all', true)

        dispatch(setCurrPage(1))
        callGetAllFilters()
      } catch (error) {
        message.error('An error occurred.')
        dispatch(setIsLoading(false))

        callGetAllFilters()
      }
    }
  }

  const onDelete = () => {
    dispatch(setIsLoading(true))
    deleteFilter(filterId)
      .then((res: any) => {
        message.success(res.data.message)
        callGetAllFilters()
        if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
        else setIsVisibleModal(false)
        dispatch(setIsLoading(false))

        activeFilterRef.current = {
          id: 'unreviewed',
          label: 'Inactive Lines'
        }
        setActiveFilter({
          id: 'unreviewed',
          label: 'Inactive Lines'
        })
        reloadData()
      })
      .catch(() => {
        message.error('An error occurred.')
        callGetAllFilters()
        dispatch(setIsLoading(false))
      })
  }

  const onCancel = () => {
    if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
    else setIsVisibleModal(false)

    dispatch(setTsmPreviewMode(false))
    setIsPreviewMode(false)
    reloadData({ filter: filterId })

    // if `update modal`, reset filter fields
    // else clear all fields
    if (filterModalType === 'update') applyFilterToFields(filterId)
    else resetFields('all', true)
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    console.log('Failed:', errorInfo)
  }

  const renderFilterInputComponents = (filter: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'end'
        }}
      >
        <Form.Item
          initialValue={filter.field}
          name={`${filter.key}-field`}
          label='Field'
          rules={[{ required: true, message: 'Field is required.' }]}
          style={{ width: '90%' }}
        >
          <Select
            showSearch
            placeholder='Please select'
            onChange={(e) => handleChangeFilter(filter, e)}
            style={{ width: '90%' }}
            optionFilterProp='children'
            filterOption={filterOption}
            filterSort={filterSort}
            disabled={
              !RP.USER_HAS_GET_TM_FILTER_FIELDS_PERMISSION ||
              newFilters.map((f) => f.field).includes(filter)
            }
            data-cy='tsm-filtermodal-filter-select'
            data-testid='tsm-filtermodal-filter-select'
          >
            {Object.keys(filterFields).map((key: string, index: number) => {
              return (
                <Select.Option
                  value={key}
                  key={index}
                  disabled={newFilters.map((f) => f.field).includes(key)}
                >
                  {filterFields[key].label}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {!filter.optionsSource &&
          getNumericTypes().includes(filter.dataType) &&
          !getNonNumericFields().includes(filter.field) && (
            <Form.Item
              initialValue={filter.operator}
              name={`${filter.key}-operator`}
              rules={[{ required: true, message: 'Operator is required.' }]}
              style={{ width: '90%' }}
            >
              <Select
                showSearch
                placeholder='Select operator'
                onChange={(e) => handleChangeOperator(filter, e)}
                style={{ width: '90%' }}
                optionFilterProp='children'
                filterOption={filterOption}
                filterSort={filterSort}
                data-cy='tsm-filtermodal-operator-select'
                data-testid='tsm-filtermodal-operator-select'
              >
                {Object.keys(operatorMenu).map((key: string, index: number) => {
                  return (
                    <Select.Option value={key} key={index}>
                      {operatorMenu[key]}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          )}
        <Form.Item
          valuePropName={
            // valuePropName will be set to `date` if `filter.dataType` is either `date` or `datetime`
            // else, fallback to `undefined`
            (['date', 'datetime'].includes(filter.dataType) && 'date') ||
            undefined
          }
          initialValue={filter.value}
          name={`${filter.key}-value`}
          label='Value'
          rules={[{ required: true, message: 'Value is required.' }]}
          style={{ width: '90%' }}
        >
          {renderValueInput(filter)}
        </Form.Item>
        <div style={{ alignSelf: 'center' }}>
          <GButton
            type='text'
            className='g-btn-icon'
            onClick={() => {
              resetFields(filter.key)
              setNewFilters(newFilters.filter((f: any) => f.key !== filter.key))
            }}
            icon={<GIcon icon={faTrash} />}
            dataCy='tsm-filtermodal-delete-icon-btn'
            dataTestId='tsm-filtermodal-delete-icon-btn'
          />
        </div>
      </div>
    )
  }

  const resetValueBasedOnDataType = (
    apiName: string,
    hasOptionsSource?: boolean
  ) => {
    if (
      !hasOptionsSource &&
      getNumericTypes().includes(filterFields[apiName].data_type) &&
      !getNonNumericFields().includes(apiName)
    )
      return null
    else return []
  }

  const renderValueInput = (filter: any) => {
    // set return components for particular filter fields
    if (
      [
        'task_type',
        'external_accrual',
        'internal_accrual',
        'external_accrual_form',
        'internal_accrual_form',
        'calculated_accrual_form',
        'calculation',
        'form_name',
        'is_active',
        'task_inactive',
        'reviewed',
        'generate_accruals',
        'active',
        'is_out_of_sync'
      ].includes(filter.field)
    ) {
      return (
        <Select
          mode='multiple'
          style={{ width: '90%' }}
          placeholder='Select value'
          defaultValue={[]}
          optionLabelProp='label'
          optionFilterProp='label'
          options={getPredefinedOptions(filter.field)}
          onChange={(e: any) => {
            setNewFilters(
              newFilters.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: e }
                  : newFilter
              })
            )
          }}
          data-cy='tsm-filtermodal-value-select'
          data-testid='tsm-filtermodal-value-select'
        />
      )
    } else if (
      getNumericTypes().includes(filter.dataType) &&
      !filter.optionsSource
    ) {
      return (
        <InputNumber
          style={{ width: '90%' }}
          defaultValue={filter.value}
          onChange={(e: any) => {
            setNewFilters(
              newFilters.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: Number(e) }
                  : newFilter
              })
            )
          }}
          data-cy='tsm-filtermodal-input-number'
          data-testid='tsm-filtermodal-input-number'
        />
      )
    } else if (['date', 'datetime'].includes(filter.dataType)) {
      return (
        <RangePicker
          style={{ width: '90%' }}
          defaultValue={filter.value.map((e: any) => {
            return moment(e, 'YYYY-MM-DD')
          })}
          onChange={(e: any) => {
            setNewFilters(
              newFilters.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? {
                      ...newFilter,
                      value: e.map((selectedDate: any) =>
                        moment(selectedDate).format('YYYY-MM-DD')
                      )
                    }
                  : newFilter
              })
            )
          }}
          data-cy='tsm-filtermodal-filter-range-picker'
          data-testid='tsm-filtermodal-filter-range-picker'
        />
      )
    } else {
      return (
        <Select
          onClick={() => {
            setFocusedApiName(filter.field)
            getOptions(filter.field, ''.trim()).then((res: any) =>
              setSuggestedValues(res.data)
            )
          }}
          mode='multiple'
          style={{ width: '90%' }}
          placeholder='Select value'
          defaultValue={[]}
          optionLabelProp='label'
          optionFilterProp='label'
          options={suggestedValues.map((entry: any, index: number) => {
            return {
              key: index,
              value: entry.value,
              label: entry.value
            }
          })}
          onChange={(e: any) => {
            setNewFilters(
              newFilters.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: e }
                  : newFilter
              })
            )
          }}
          onKeyUp={(e: any) => {
            setFocusedApiName(filter.field)
            setSearchTerm(e.target.value)
          }}
          onInputKeyDown={async (e: any) => {
            if (
              [e.key, e.code].includes('Enter') &&
              !['', null, undefined].includes(e.target.value)
            ) {
              await form.setFieldValue(`${filter.key}-value`, [
                ...form.getFieldValue(`${filter.key}-value`),
                e.target.value
              ])
              await setNewFilters(
                newFilters.map((f: any) => {
                  return filter.key === f.key
                    ? {
                        ...f,
                        value: form.getFieldValue(`${filter.key}-value`)
                      }
                    : f
                })
              )
              e.target.value = ''
            }
          }}
          data-cy='tsm-filtermodal-filter-select-multiple'
          data-testid='tsm-filtermodal-filter-select-multiple'
        />
      )
    }
  }

  const handleChangeFilter = (filter: any, newField: any) => {
    setNewFilters(
      newFilters.map((newFilter) => {
        if (filter.key === newFilter.key) {
          form.setFieldValue(
            `${filter.key}-value`,
            resetValueBasedOnDataType(
              newField,
              filterFields[newField].options_source
            )
          )
          form.setFieldValue(`${filter.key}-operator`, null)
          return {
            ...newFilter,
            field: newField,
            operator: null,
            value: resetValueBasedOnDataType(
              newField,
              filterFields[newField].options_source
            ),
            dataType: filterFields[newField].data_type,
            optionsSource: filterFields[newField].options_source
          }
        } else return newFilter
      })
    )
    setSuggestedValues([])
  }

  const handleChangeOperator = (filter: any, newOperator: any) => {
    setNewFilters(
      newFilters.map((newFilter) => {
        if (filter.key === newFilter.key) {
          return { ...newFilter, operator: newOperator }
        } else return newFilter
      })
    )
    setSuggestedValues([])
  }

  const operatorMenu: any = {
    '=': 'Equals',
    '!=': 'Not equals',
    '>': 'Is greater than',
    '>=': 'Is greater than or equals',
    '<': 'Is less than',
    '<=': 'Is less than or equals'
  }

  const resetFields = (mode: string | number, clearTitle?: boolean) => {
    if (mode === 'all') {
      Array.from(Array(countNewFilters), (_, index: number) => index).forEach(
        (index: number) => {
          form.setFieldValue(`${index}-field`, null)
          form.setFieldValue(`${index}-operator`, null)
          form.setFieldValue(`${index}-value`, [])
        }
      )
      setNewFilters([])
      setCountNewFilters(0)
    } else {
      form.setFieldValue(`${mode}-field`, null)
      form.setFieldValue(`${mode}-operator`, null)
      form.setFieldValue(`${mode}-value`, [])
    }

    if (clearTitle) {
      form.setFieldValue('name', null)
    }
  }

  const applyFilterToFields = async (filterId: string) => {
    resetFields('all', true)
    await getFilterDetails(filterId).then(({ data }: any) => {
      setCountNewFilters(data.filter_fields.length)
      let extractedFilters = filterDetails.filter_fields.map(
        (filterField: any, index: number) => {
          return {
            field: filterField.column,
            value: filterField.value,
            operator: filterField.operator,
            dataType: filterField.operator
              ? 'int'
              : getDataType(filterField.column),
            key: index,
            optionsSource: filterFields[filterField.column].options_source
          }
        }
      )

      setNewFilters(extractedFilters)
      form.setFieldValue('name', data.name)
      Array.from(
        Array(data.filter_fields.length),
        (_, index: number) => index
      ).forEach((index: number) => {
        form.setFieldValue(`${index}-field`, data.filter_fields[index].column)
        form.setFieldValue(
          `${index}-operator`,
          data.filter_fields[index].operator
        )
        form.setFieldValue(`${index}-value`, data.filter_fields[index].value)
      })
    })
  }

  const getDataType = (apiName: string) => {
    return filterFields[apiName]?.data_type || ''
  }

  useEffect(() => {
    loadFilterFields().then((res) => setFilterFields(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !['all', 'active', 'unreviewed', '', undefined].includes(filterId) &&
      filterModalType === 'update'
    )
      getFilterDetails(filterId).then(async (res) => {
        setFilterDetails(res.data)
      })
    else setFilterDetails({})
    // eslint-disable-next-line
  }, [filterId])

  useEffect(() => {
    if (
      filterId &&
      filterDetails &&
      filterFields &&
      filterModalType === 'update'
    ) {
      setCountNewFilters(filterDetails.filter_fields.length)
      let extractedFilters = filterDetails.filter_fields.map(
        (filterField: any, index: number) => {
          return {
            field: filterField.column,
            value: filterField.value,
            operator: filterField.operator,
            dataType: filterField.operator
              ? 'int'
              : getDataType(filterField.column),
            key: index,
            optionsSource:
              filterFields[filterField.column]?.options_source || undefined
          }
        }
      )
      setNewFilters(extractedFilters)
      form.setFieldValue('name', filterDetails.name)
      extractedFilters.forEach((filter: any, index: number) => {
        form.setFieldValue(`${index}-field`, filter.field)
        // add special handling if date range
        if (
          Array.isArray(filter.value) &&
          moment(filter.value[0], 'YYYY-MM-DD', true).isValid() &&
          moment(filter.value[1], 'YYYY-MM-DD', true).isValid()
        ) {
          form.setFieldValue(`${index}-value`, [
            moment(filter.value[0], 'YYYY-MM-DD'),
            moment(filter.value[1], 'YYYY-MM-DD')
          ])
        } else form.setFieldValue(`${index}-value`, filter.value)
        form.setFieldValue(`${index}-operator`, filter.operator)
      })
    }
    // eslint-disable-next-line
  }, [filterDetails])

  // for autocomplete backend calls...
  // after 1 sec, if user is not typing anything, fetch suggested values from BE
  useEffect(() => {
    if (searchTerm && (`${searchTerm}`.length >= 3 || searchTerm === '')) {
      const delayDebounceFn = setTimeout(() => {
        getOptions(focusedApiName, `${searchTerm}`.trim()).then((res: any) => {
          setSuggestedValues(res.data)
        })
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <>
      <div
        onClick={() => {
          if (!overrideSetIsVisibleModal) setIsVisibleModal(true)
        }}
      >
        {overrideLabel || 'Create New Filter'}
      </div>
      <Modal
        title={overrideHeader || 'Create New Filter'}
        open={overrideIsVisibleModal ? overrideIsVisibleModal : isVisibleModal}
        onCancel={onCancel}
        onOk={form.submit}
        cancelButtonProps={{
          shape: 'round'
        }}
        okButtonProps={{
          shape: 'round'
        }}
        footer={[
          <>
            <Button
              shape='round'
              key='save-view'
              type='primary'
              onClick={form.submit}
              data-testid='tsm-filtermodal-save-view-btn'
              data-cy='tsm-filtermodal-save-view-view-btn'
            >
              {overrideFinishLabel || 'Save View'}
            </Button>
            {filterModalType === 'update' && (
              <Popconfirm
                title='Please confirm if you want to delete this view.'
                onConfirm={onDelete}
                okText='Yes'
                cancelText='No'
                disabled={!RP.USER_HAS_DELETE_TM_FILTER_PERMISSION}
                data-testid='tsm-filtermodal-popconfirm-delete-filter'
                data-cy='tsm-filtermodal-popconfirm-delete-filter'
              >
                <Button
                  shape='round'
                  key='delete-view'
                  disabled={!RP.USER_HAS_DELETE_TM_FILTER_PERMISSION}
                  data-testid='tsm-filtermodal-delete-filter-view-btn'
                  data-cy='tsm-filtermodal-delete-filter-view-btn'
                >
                  Delete View
                </Button>
              </Popconfirm>
            )}
          </>,
          <Button
            shape='round'
            key='preview'
            type='default'
            onClick={() => onSubmit(undefined, true)}
            data-testid='tsm-filtermodal-preview-filter-btn'
            data-cy='tsm-filtermodal-preview-filter-btn'
          >
            Preview
          </Button>,
          <Button
            shape='round'
            key='cancel'
            type='default'
            onClick={onCancel}
            data-cy='tsm-filtermodal-cancel-btn'
            data-testid='tsm-filtermodal-cancel-btn'
          >
            Cancel
          </Button>
        ]}
        width={900}
      >
        <Form
          form={form}
          layout='vertical'
          name='create-new-filter'
          initialValues={{ remember: true }}
          autoComplete='off'
          onFinish={(e: any) => onSubmit(e)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Enter name of the filter.' }]}
            initialValue={filterDetails?.name || ''}
          >
            <Input
              maxLength={30}
              data-cy='input-tsm-new-filter'
              data-testid='input-tsm-new-filter'
            />
          </Form.Item>

          {newFilters.map((filter: any) => {
            return renderFilterInputComponents(filter)
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <GButton
              btnText='Add Filter'
              onClick={() => {
                setNewFilters([
                  ...newFilters,
                  {
                    key: countNewFilters,
                    field: null,
                    value: []
                  }
                ])
                setCountNewFilters(countNewFilters + 1)
              }}
              dataCy='btn-tsm-add-filter'
              dataTestId='btn-tsm-add-filter'
            />
            {newFilters.length > 0 ? (
              <GButton
                btnText='Remove All'
                onClick={() => {
                  resetFields('all')
                }}
                dataCy='tsm-remove-all-filter-btn'
                dataTestId='tsm-rmemove-all-filter-btn'
              />
            ) : null}
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default CreateNewFilterModal
