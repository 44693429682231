interface ReviewCenterView {
  [index: string]: string
}

interface ReviewCenterViewsHeaderColumn {
  [index: string]: { label: string; classes: string }[]
}

const ReviewCenterViews: ReviewCenterView = Object.freeze({
  SUBSIDIARY_VENDORS: 'SUBSIDIARY > VENDORS',
  SUBSIDIARY_GL_DEPARTMENT_VENDOR: 'SUBSIDIARY > GL > DEPARTMENT > VENDOR',
  SUBSIDIARY_DEPARTMENT_GL_VENDOR: 'SUBSIDIARY > DEPARTMENT > GL > VENDOR',
  DEPARTMENT_GL_VENDOR: 'DEPARTMENT > GL > VENDOR',
  DEPARTMENT_VENDOR: 'DEPARTMENT > VENDOR'
})

const ReviewCenterViewsHeaderColumns: ReviewCenterViewsHeaderColumn =
  Object.freeze({
    SUBSIDIARY_VENDORS: [
      { label: 'Subsidiary', classes: 'border-l sticky-col sticky-col-1' },
      { label: 'Vendor', classes: 'border-r sticky-col sticky-col-2' }
    ],
    SUBSIDIARY_GL_DEPARTMENT_VENDOR: [
      { label: 'Subsidiary', classes: 'border-l sticky-col sticky-col-1' },
      { label: 'GL', classes: 'sticky-col sticky-col-2' },
      { label: 'Department', classes: 'sticky-col sticky-col-3' },
      { label: 'Vendor', classes: 'border-r sticky-col sticky-col-4' }
    ],
    SUBSIDIARY_DEPARTMENT_GL_VENDOR: [
      { label: 'Subsidiary', classes: 'border-l sticky-col sticky-col-1' },
      { label: 'Department', classes: 'sticky-col sticky-col-2' },
      { label: 'GL', classes: 'sticky-col sticky-col-3' },
      { label: 'Vendor', classes: 'border-r sticky-col sticky-col-4' }
    ],
    DEPARTMENT_GL_VENDOR: [
      { label: 'Department', classes: 'border-l sticky-col sticky-col-1' },
      { label: 'GL', classes: 'sticky-col sticky-col-2' },
      { label: 'Vendor', classes: 'border-r sticky-col sticky-col-3' }
    ],
    DEPARTMENT_VENDOR: [
      { label: 'Department', classes: 'border-l sticky-col sticky-col-1' },
      { label: 'Vendor', classes: 'border-r sticky-col sticky-col-2' }
    ]
  })

export { ReviewCenterViews, ReviewCenterViewsHeaderColumns }
