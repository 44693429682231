import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = {
  data: {
    success: boolean
    is_syncing: boolean
    last_sync_time: string
    total_rows: number
    processed_rows: number
    failed_rows: number
  }
}

const useFetchSyncAllStatus = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useQuery<Response>({
    queryKey: ['sync-all-status'],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.taskmanager,
          // endpoint: `/api/sync-all/status`,
          /**
           * Used the statistics endpoint as this has more complete response
           */
          endpoint: '/api/sync-all/statistics',
          method: 'GET'
        }
      }),
    cacheTime: 0,
    staleTime: 0
  })
}

export default useFetchSyncAllStatus
