import { useQuery } from '@tanstack/react-query'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

type Response = {
  consolidated_transaction_lines_disabled: boolean
}

const useFetchSettingsConsolidated = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()
  const isFlagEnabled = useIsFlagEnabled()

  const getBaseUrl = () => {
    // If the flag is enabled for Consolidated flag disabled API, return the url from MS: IDMS
    if (isFlagEnabled(FEATURE_FLAGS.etivac)) return msUrl.idms

    // Else, return the url from MS: Settings
    return msUrl.settings
  }

  const getEndpoint = () => {
    // If the flag is enabled for Consolidated flag disabled API, return the endpoint from MS: IDMS
    if (isFlagEnabled(FEATURE_FLAGS.etivac))
      return '/api/consolidated-transaction-lines-disabled'

    // Else, return the endpoint from MS: Settings
    return '/api/settings/ConsolidatedTransactionLinesDisabled'
  }

  const getEnabled = () => {
    // Enable this query if either Q3 2024 or Consolidated flag disabled API is enabled
    return (
      isFlagEnabled(FEATURE_FLAGS.edinburgh) ||
      isFlagEnabled(FEATURE_FLAGS.etivac)
    )
  }

  return useQuery<Response>({
    queryKey: ['settings-consolidated'],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: getBaseUrl(),
          endpoint: getEndpoint(),
          method: 'GET'
        }
      }),
    enabled: getEnabled()
  })
}

export default useFetchSettingsConsolidated
