import { FC, SetStateAction, useState, FormEvent } from 'react'
import { Modal, Input } from 'antd'
import { callPostApi } from '../services/GenericAPI'
import type { AxiosError } from 'axios'

interface TxnLinePassbackModalProps {
  visible: boolean
  setModalVisible: (value: SetStateAction<boolean>) => void
  vendorName: string
  email: string
  url: string
  lineId: number | null
  transactionId?: number | string
  onSuccess: () => void
  onError: () => void
}

const TxnLinePassbackModal: FC<TxnLinePassbackModalProps> = ({
  visible,
  setModalVisible,
  vendorName,
  email,
  url,
  lineId,
  transactionId,
  onSuccess,
  onError
}: TxnLinePassbackModalProps): JSX.Element => {
  const [passbackReason, setPassbackReason] = useState<string>('')

  const handlePassbackReasonChange = (
    e: FormEvent<HTMLTextAreaElement>
  ): void => {
    const value = e.currentTarget.value
    setPassbackReason(value)
  }

  const sumbitPassback = async (): Promise<void> => {
    const params = {
      line_id: lineId,
      transaction_id: transactionId,
      passback_reason: passbackReason
    }
    try {
      await callPostApi(url, params)
      handleModalCancel()
      onSuccess()
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
      onError()
    }
  }

  const handleModalCancel = (): void => {
    setPassbackReason('')
    setModalVisible(false)
  }

  return (
    <Modal
      data-testid='txn-line-passback-modal'
      data-cy='txn-line-passback-modal'
      title='Passback'
      open={visible}
      onCancel={handleModalCancel}
      onOk={sumbitPassback}
      okButtonProps={{
        shape: 'round',
        disabled: passbackReason === ''
      }}
      okText='Submit'
      cancelButtonProps={{
        shape: 'round'
      }}
      destroyOnClose
    >
      <div>
        <label>Name</label>
        <Input
          data-testid='txn-line-passback-modal-name-input-field'
          data-cy='txn-line-passback-modal-name-input-field'
          type='text'
          value={vendorName}
          disabled
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <label>Email</label>
        <Input
          data-testid='txn-line-passback-modal-email-input-field'
          data-cy='txn-line-passback-modal-email-input-field'
          type='text'
          value={email}
          disabled
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <label>
          Reason for passback (this will be included in the email notification)
        </label>
        <Input.TextArea
          data-testid='txn-line-passback-passback-reason-textarea-field'
          data-cy='txn-line-passback-passback-reason-textarea-field'
          size='middle'
          onChange={handlePassbackReasonChange}
        />
      </div>
    </Modal>
  )
}

TxnLinePassbackModal.defaultProps = {
  visible: false
}

export default TxnLinePassbackModal
