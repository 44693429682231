/** Tanstack imports */
import { useMutation } from '@tanstack/react-query'

/** jwt-decode imports */
import jwt_decode from 'jwt-decode'

/** react router imports */
import { useNavigate } from 'react-router-dom'

/** constants imports */
import { AUTH_TOKEN, MFA_TOKEN } from '../../../helpers/constants/Auth'

/** API services imports */
import useApiService from '../../../hooks/useApiService'
import useAuthService from '../../../services/useAuthService'

/** types imports */
import type DecodedJWTProps from '../types/DecodedJWTProps'

/** hooks imports */
import useLogin from './useLogin'

// =================================================================
export default function useLoginCredentials() {
  /** react router */
  const navigate = useNavigate()

  /** API services */
  const { authAPI } = useAuthService()
  const { msUrl } = useApiService()

  /** hooks */
  const { setLocalStorageData, handleError, redirectPage } = useLogin()

  const { mutate: loginCredentialsApi, isLoading } = useMutation(authAPI, {
    onSuccess: (data) => {
      if (data.mfa_token) {
        // enabled mutli factor
        localStorage.setItem(MFA_TOKEN, data.mfa_token)
        navigate('/multi-factor-auth')
        window.location.href = '/multi-factor-auth'
      } else {
        const jwtToken = window.atob(data.access_token)
        const decodedJwt: DecodedJWTProps = jwt_decode(jwtToken)

        localStorage.setItem(AUTH_TOKEN, data.access_token)

        setLocalStorageData(data, decodedJwt)
        redirectPage(decodedJwt)
      }
    },
    onError: (error) => {
      handleError(error)
    }
  })

  const handleSubmit = (values: object) => {
    loginCredentialsApi({
      data: {
        ...values,
        token_duration: msUrl.token_duration
      },
      options: {
        baseURL: msUrl.idms,
        endpoint: '/api/login',
        method: 'POST'
      }
    })
  }

  return { isLoading, handleSubmit }
}
