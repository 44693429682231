import axios from 'axios'
import getMsUrl from '../utils/getMsUrl'
import { AUTH_TOKEN } from './constants/Auth'
import handleErrorResponse from './handleErrorResponse'

const token = localStorage.getItem(AUTH_TOKEN)
const hostname = window.location.hostname
const msUrl = getMsUrl(hostname)

const headers = {
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  ContentType: 'application/json'
}

const uploadHeaders = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'multipart/form-data'
}

const axiosIdentityManagerInstance = axios.create({
  baseURL: msUrl.idms,
  headers
})

const axiosIdentityManagerUploadInstance = axios.create({
  baseURL: msUrl.idms,
  headers: uploadHeaders
})

const axiosTxnManagerInstance = axios.create({
  baseURL: msUrl.txn_manager,
  headers
})

const axiosGenericInstance = axios.create({
  headers
})

const axiosTaskManagerInstance = axios.create({
  baseURL: msUrl.taskmanager,
  withCredentials: true,
  headers
})

const axiosReviewCenterInstance = axios.create({
  baseURL: msUrl.review_center,
  withCredentials: true,
  headers
})

const axiosTxnManagerUploadInstance = axios.create({
  baseURL: msUrl.txn_manager,
  headers: uploadHeaders
})

const axiosQjeManagerInstance = axios.create({
  baseURL: msUrl.qje_manager,
  headers
})

const axiosMfaInstance = axios.create({
  baseURL: msUrl.idms
})

const axiosHistoryInstance = axios.create({
  baseURL: msUrl.history_logs,
  withCredentials: true,
  headers
})

const axiosGappifyApiInstance = axios.create({
  baseURL: msUrl.gappify_api,
  headers
})

axiosIdentityManagerInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosIdentityManagerInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken
  return config
})

axiosIdentityManagerUploadInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosIdentityManagerUploadInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken
  return config
})

axiosReviewCenterInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosReviewCenterInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken
  return config
})

axiosTxnManagerInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosTxnManagerInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosTaskManagerInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosTaskManagerInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosQjeManagerInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosQjeManagerInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosGenericInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosGenericInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosTxnManagerUploadInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosTxnManagerUploadInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosHistoryInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})

axiosGappifyApiInstance.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

axiosGappifyApiInstance.interceptors.request.use((config: any) => {
  let refreshToken = localStorage.getItem(AUTH_TOKEN)
  config.headers['Authorization'] = 'Bearer ' + refreshToken

  return config
})
export {
  axiosIdentityManagerInstance,
  axiosIdentityManagerUploadInstance,
  axiosTxnManagerInstance,
  axiosTaskManagerInstance,
  axiosReviewCenterInstance,
  axiosGenericInstance,
  axiosTxnManagerUploadInstance,
  axiosQjeManagerInstance,
  axiosMfaInstance,
  axiosHistoryInstance,
  axiosGappifyApiInstance
}
