import { FC, SetStateAction, useEffect, useState } from 'react'
import { Table, Modal } from 'antd'
import getHistoryTableColumns from '../helpers/getHistoryTableColumns'
import getUserHistoryById from '../services/getUserHistoryById'
import ReqParams from '../types/ReqParams'

interface UserHistoryModalProps {
  visible: boolean
  onCancelModal?: () => void
  setModalVisible?: (value: SetStateAction<boolean>) => void
  userId: number | undefined
}

const UserHistoryModal: FC<UserHistoryModalProps> = ({
  visible,
  onCancelModal,
  setModalVisible,
  userId
}): JSX.Element => {
  const [historyList, setHistoryList] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    const order = sorter && sorter.order === 'ascend' ? 'asc' : 'desc'
    const params = {
      page: pagination.current,
      paginate_count: pagination.pageSize,
      sort: sorter && sorter.field && `${sorter.field && sorter.field}:${order}`
    }

    setIsLoading(true)
    getHistoryList(userId!, params)
  }

  const getHistoryList = (userId: number, params?: ReqParams) => {
    try {
      getUserHistoryById(userId, params).then((response: any) => {
        if (response && response.data) {
          setHistoryList(response.data)
          setIsLoading(false)
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (userId && visible) {
      // setIsLoading(true)
      getHistoryList(userId)
    }
  }, [userId, visible])

  return (
    <Modal
      data-testid='user-history-modal'
      data-cy='user-history-modal'
      title='History'
      open={visible}
      onCancel={onCancelModal}
      width={2000}
      bodyStyle={{ height: 700 }}
      footer={null}
    >
      <div className='user-table'>
        <Table
          data-testid='user-history-table'
          data-cy='user-history-table'
          dataSource={historyList}
          columns={getHistoryTableColumns()}
          scroll={{ x: 'max-content', y: 500 }}
          loading={isLoading}
          onChange={handleTableChange}
          pagination={{
            pageSize: 100
          }}
        />
      </div>
    </Modal>
  )
}
export default UserHistoryModal
