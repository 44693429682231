const TableTag = ({ color, background, name }) => {
  return (
    <div
      className={'table-tag'}
      style={{
        color: color,
        background: background
      }}
    >
      {name}
    </div>
  )
}

export default TableTag
