import { FC, SetStateAction, useEffect, useState } from 'react'
import { callGetApi } from '../services/GenericAPI'
import type { AxiosError } from 'axios'
import { Modal, Table, Typography, Spin } from 'antd'

interface TxnLineCalculationModalProps {
  visible?: boolean
  calculationUrl: string
  setModalVisible: (value: SetStateAction<boolean>) => void
}

interface Calculation {
  error_message: string | null | []
  fields: {
    display_num_of_invoices: boolean
    g_accrual_amount: number
    g_calculation: string
    g_current_accrual_amount: number
    g_current_period: string
    g_invoiced_amount: number
    g_name: string
    g_po_line_amount: number
    g_po_line_amount_cancelled: number
    g_service_end_date: string
    g_service_start_date: string
    historical_avg_calc_transaction_type?: string
    is_accrual_amount_overridden: boolean
    line_id: number
    monthly_expenses_period_0: number
    monthly_expenses_0: number
    monthly_expenses_1: number
    monthly_expenses_2: number
    monthly_expenses_3: number
    monthly_expenses_invoices_0: number
    monthly_expenses_invoices_1: number
    monthly_expenses_invoices_2: number
    monthly_expenses_invoices_3: number
    monthly_expenses_period_1: string
    monthly_expenses_period_2: string
    monthly_expenses_period_3: string
    months_to_divide: number
    g_percentage_of_completion: string
    g_override_value: string
  }
  status: number
}

const TxnLineCalculationModal: FC<TxnLineCalculationModalProps> = ({
  visible,
  calculationUrl,
  setModalVisible
}: TxnLineCalculationModalProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [calculation, setCalculation] = useState<Calculation | null>(null)

  useEffect(() => {
    const getCalculation = async (): Promise<void> => {
      setIsLoading(true)
      try {
        const { data } = await callGetApi(calculationUrl)
        if (data.length === 0) {
          setCalculation(null)
        } else {
          setCalculation(data)
        }
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      } finally {
        setIsLoading(false)
      }
    }

    if (visible) {
      getCalculation()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const formatAmount = (amount: number | string) => {
    if (amount === '0' || amount === 0) {
      return '0.00'
    }
    const twoDecimal =
      typeof amount === 'number'
        ? amount?.toFixed(2)
        : amount && parseFloat(amount).toFixed(2)

    return amount && twoDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getFormula = (): JSX.Element => {
    let formula: JSX.Element = <></>

    if (calculation?.fields.g_calculation === 'Straightline') {
      formula = (
        <>
          <em>
            <sup>*</sup>
            ((C/(B-A))*(D-A))-E-F
          </em>
        </>
      )
    }

    if (calculation?.fields.g_calculation === 'Historical Average') {
      formula = (
        <>
          <em>
            <sup>*</sup>
            ((A+B+C)/D)-E
          </em>
        </>
      )
    }

    if (calculation?.fields.g_calculation === 'Percentage of Completion') {
      formula = (
        <>
          <em>
            <sup>*</sup>
            (A*D)-B-C
          </em>
        </>
      )
    }

    return formula
  }

  const getHeader = () => {
    if (calculation?.fields.g_calculation === 'Historical Average') {
      if (
        calculation.fields.historical_avg_calc_transaction_type ===
        'all_expenses'
      ) {
        return <div>{calculation.fields.g_calculation} (All Expenses)</div>
      }
      return <div>{calculation.fields.g_calculation} (Bills only)</div>
    }
    return <div>{calculation?.fields.g_calculation}</div>
  }

  const getData = () => {
    let data: any = []
    if (calculation?.fields.g_calculation === 'Straightline') {
      data = [
        {
          key: '1',
          col: 'PO Start Date',
          letter: 'A',
          values: calculation?.fields.g_service_start_date
        },
        {
          key: '2',
          col: 'PO End Date',
          letter: 'B',
          values: calculation?.fields.g_service_end_date
        },
        {
          key: '3',
          col: 'Total PO Balance',
          letter: 'C',
          values: formatAmount(calculation?.fields.g_po_line_amount)
        },
        {
          key: '4',
          col: 'End of Current Month',
          letter: 'D',
          values: calculation?.fields.g_current_period
        },
        {
          key: '5',
          col: 'PO Amount Cancelled',
          letter: 'E',
          values: formatAmount(calculation?.fields.g_po_line_amount_cancelled)
        },
        {
          key: '6',
          col: 'Invoices Processed to Date',
          letter: 'F',
          values: formatAmount(calculation?.fields.g_invoiced_amount)
        },
        {
          key: '7',
          col: (
            <Typography.Text strong italic>
              Calculated Accrual<sup>*</sup>
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_accrual_amount)
        }
      ]
      if (calculation?.fields.is_accrual_amount_overridden) {
        data.push({
          key: '8',
          col: (
            <Typography.Text strong italic>
              Override Value
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_current_accrual_amount)
        })
      }
    } else if (calculation?.fields.g_calculation === 'Historical Average') {
      data = [
        {
          key: '1',
          col: calculation?.fields.monthly_expenses_period_1,
          letter: 'A',
          values: formatAmount(calculation?.fields.monthly_expenses_1),
          expenses: formatAmount(calculation?.fields.monthly_expenses_1),
          invoice: calculation?.fields.monthly_expenses_invoices_1
        },
        {
          key: '2',
          col: calculation?.fields.monthly_expenses_period_2,
          letter: 'B',
          values: formatAmount(calculation?.fields.monthly_expenses_2),
          expenses: formatAmount(calculation?.fields.monthly_expenses_2),
          invoice: calculation?.fields.monthly_expenses_invoices_2
        },
        {
          key: '3',
          col: calculation?.fields.monthly_expenses_period_3,
          letter: 'C',

          values: formatAmount(calculation?.fields.monthly_expenses_3),
          expenses: formatAmount(calculation?.fields.monthly_expenses_3),
          invoice: calculation?.fields.monthly_expenses_invoices_3
        },
        {
          key: '4',
          col: 'Months Being Averaged',
          letter: 'D',
          values: calculation?.fields.months_to_divide
        },
        {
          key: '4',
          col: calculation?.fields.monthly_expenses_period_0,
          letter: 'E',
          values: formatAmount(calculation?.fields.monthly_expenses_0),
          expenses: formatAmount(calculation?.fields.monthly_expenses_0),
          invoice: calculation?.fields.monthly_expenses_invoices_0
        },
        {
          key: '7',
          col: (
            <Typography.Text strong italic>
              Calculated Accrual<sup>*</sup>
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_accrual_amount)
        }
      ]
      if (calculation?.fields.is_accrual_amount_overridden) {
        data.push({
          key: '8',
          col: (
            <Typography.Text strong italic>
              Override Value
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_current_accrual_amount)
        })
      }
    } else if (
      calculation?.fields.g_calculation === 'Percentage of Completion'
    ) {
      data = [
        {
          key: '1',
          col: 'Total PO Line Amount',
          letter: 'A',
          values: formatAmount(calculation?.fields.g_po_line_amount)
        },
        {
          key: '2',
          col: 'PO Invoices Processed to Date',
          letter: 'B',
          values: formatAmount(calculation?.fields.g_invoiced_amount)
        },
        {
          key: '3',
          col: 'PO Line Amount Cancelled',
          letter: 'C',
          values: formatAmount(calculation?.fields.g_po_line_amount_cancelled)
        },
        {
          key: '4',
          col: 'Percentage of Completion',
          letter: 'D',
          values: `${calculation?.fields.g_percentage_of_completion}%`
        },
        {
          key: '5',
          col: (
            <Typography.Text strong italic>
              Calculated Accrual<sup>*</sup>
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_accrual_amount)
        }
      ]

      if (calculation?.fields.is_accrual_amount_overridden) {
        data.push({
          key: '6',
          col: (
            <Typography.Text strong italic>
              Override Value
            </Typography.Text>
          ),
          values: formatAmount(calculation?.fields.g_override_value)
        })
      }
    }

    return data
  }

  const getErrorMessage = (errorMessage: any) => {
    if (typeof errorMessage === 'string') {
      return errorMessage
    }
    return errorMessage.map((element: any) => <div>{element}</div>)
  }

  const getColumnSettings = () => {
    let column = [
      {
        title: '',
        key: 'col',
        dataIndex: 'col'
      },
      {
        title: 'Letter',
        key: 'letter',
        dataIndex: 'letter'
      },
      {
        title: 'Values',
        key: 'values',
        dataIndex: 'values',
        align: 'right' as const,
        width: 170
      }
    ]
    if (calculation?.fields.g_calculation === 'Historical Average') {
      if (
        calculation.fields.historical_avg_calc_transaction_type ===
        'all_expenses'
      ) {
        column.push({
          title: 'Expenses',
          key: 'invoice',
          dataIndex: 'invoice'
        })
      } else {
        column.push({
          title: 'Invoice',
          key: 'invoice',
          dataIndex: 'invoice'
        })
      }
    }
    return column
  }

  return (
    <Modal
      data-testid='txn-line-calculation-modal'
      data-cy='txn-line-calculation-modal'
      open={visible}
      width={600}
      className='calc-modal'
      bodyStyle={{ maxHeight: '1500px' }}
      // title={}
      onCancel={() => {
        setModalVisible(false)
      }}
      onOk={() => {
        setModalVisible(false)
      }}
      okButtonProps={{
        loading: isLoading
      }}
      okText='Close'
      cancelButtonProps={{
        hidden: true
      }}
      centered
    >
      {isLoading ? (
        <div className='spin'>
          <Spin />
        </div>
      ) : (
        <>
          {!(calculation === null) ? (
            <>
              <div
                style={{
                  marginBottom: '5px',
                  fontWeight: '500',
                  textAlign: 'center'
                }}
              >
                <div style={{ fontSize: '20px', fontWeight: '500' }}>
                  {calculation?.fields.g_name}
                </div>
                {getHeader()}
              </div>
              {calculation?.status === 1 ||
              calculation?.error_message === null ? (
                <>
                  <Table
                    data-testid='txn-line-calculation-table'
                    data-cy='txn-line-calculation-table'
                    columns={getColumnSettings()}
                    dataSource={getData()}
                    pagination={false}
                    loading={isLoading}
                  />
                  <div style={{ margin: '15px 0px', textAlign: 'center' }}>
                    <Typography.Text strong type='danger'>
                      {calculation?.error_message &&
                        getErrorMessage(calculation?.error_message)}
                    </Typography.Text>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '5px'
                    }}
                  >
                    {getFormula()}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    padding: '20px'
                  }}
                >
                  This line was unable to be calculated due to below error:
                  <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <Typography.Text strong type='danger'>
                      {calculation?.error_message &&
                        getErrorMessage(calculation!.error_message)}
                    </Typography.Text>
                  </div>
                  Please override this calculation with the value you want to
                  accrue at the line level.
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                padding: '20px'
              }}
            >
              This line was unable to be calculated. Please override this
              calculation with the value you want to accrue at the line level.
            </div>
          )}
        </>
      )}
    </Modal>
  )
}

TxnLineCalculationModal.defaultProps = {
  visible: false
}

export default TxnLineCalculationModal
