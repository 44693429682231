import { FC, useEffect } from 'react'
import { logoutUserOkta } from '../services/AuthApi'
import logout from '../utils/logout'

const LogoutOkta: FC = (): JSX.Element => {
  useEffect(() => {
    logout(logoutUserOkta)
  }, [])

  return <></>
}

export default LogoutOkta
