import MsUrl from '../../../types/MsUrl'

const Prod: MsUrl = {
  idms: 'https://idms.prod.gappifyinc.com',
  txn_manager: 'https://tm.prod.gappifyinc.com',
  review_center: 'https://rc.prod.gappifyinc.com/',
  qje_manager: 'https://qje.prod.gappifyinc.com',
  taskmanager: 'https://tsm.prod.gappifyinc.com',
  history_logs: 'https://history.prod.gappifyinc.com',
  analytics: 'https://advanced-analytics.prod.gappifyinc.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://scheduler-backend.gappifyinc.com',
  schedule_manager_env: 'prod',
  search: 'https://search.prod.gappifyinc.com',
  period_manager: 'https://period-manager.prod.gappifyinc.com',
  settings: 'https://settings.prod.gappifyinc.com',
  gappify_api: 'https://api.prod.gappifyinc.com'
} as const

export default Prod
