import jwt_decode from 'jwt-decode'
import moment from 'moment'
import {
  AUTH_TOKEN,
  EXPIRATION,
  EXPIRES_ON,
  EXPIRY_COUNTER,
  IDLE_TIMER,
  ROLES_AND_PERMISSIONS
} from '../../../helpers/constants/Auth'
import { refreshToken } from '../../../services/AuthApi'
import MsUrl from '../../../types/MsUrl'

export default async function reset(
  msUrl: MsUrl,
  TO_SECONDS: number
): Promise<void> {
  const allowance = msUrl.token_exp_allowance * TO_SECONDS

  if (parseInt(localStorage.getItem(EXPIRY_COUNTER)!) < allowance) {
    const { data }: any = await refreshToken()
    if (data) {
      let jwtToken = window.atob(data.access_token)
      let decodedjwt = jwt_decode(jwtToken)
      let new_expires_on = moment(data.expires_on).add(
        data.expires_in,
        'minutes'
      )
      localStorage.setItem(AUTH_TOKEN, data.access_token)
      localStorage.setItem(EXPIRATION, data.expires_in)

      localStorage.setItem(EXPIRES_ON, new_expires_on.toString())
      localStorage.setItem(
        EXPIRY_COUNTER,
        (data.expires_in * TO_SECONDS).toString()
      )
      localStorage.setItem(
        IDLE_TIMER,
        (data.expires_in * TO_SECONDS).toString()
      )
      localStorage.setItem(ROLES_AND_PERMISSIONS, JSON.stringify(decodedjwt))
    }
  }
}
