import * as FE_URL from '../../../helpers/constants/fe-urls'
import Dev from '../../../helpers/constants/ms-urls/Dev'
import Local from '../../../helpers/constants/ms-urls/Local'
import Prod from '../../../helpers/constants/ms-urls/Prod'
import Qa from '../../../helpers/constants/ms-urls/Qa'
import Staging from '../../../helpers/constants/ms-urls/Staging'
import Uat from '../../../helpers/constants/ms-urls/Uat'

export default function getIdmsUrl(hostname: string): string {
  switch (hostname) {
    case FE_URL.DEV:
      return Dev.idms
    case FE_URL.QA:
    case FE_URL.QA_MSB:
      return Qa.idms
    case FE_URL.STAGING:
    case FE_URL.STAGING_MSB:
      return Staging.idms
    case FE_URL.UAT:
    case FE_URL.UAT_MSB:
      return Uat.idms
    case FE_URL.PRE_PROD:
    case FE_URL.PROD_NEXT:
    case FE_URL.PROD:
      return Prod.idms
    case FE_URL.DOCKER_BE:
      return Dev.idms
    default:
      return Local.idms
  }
}
