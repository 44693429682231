import moment, { Moment } from 'moment-timezone'

export const getCurrentPeriod = () => {
  const dayOfTheMonth = parseInt(moment().tz('America/Los_Angeles').format('D'))
  const lastDayOfCurrentPeriod = 15

  if (dayOfTheMonth <= lastDayOfCurrentPeriod) {
    return moment().tz('America/Los_Angeles').subtract(1, 'month')
  }

  return moment().tz('America/Los_Angeles')
}

export const getCurrentPeriodFormatted = () => {
  return getCurrentPeriod().format('MMMM YYYY')
}

export const getNextPeriod = () => {
  return getCurrentPeriod().add(1, 'month')
}

export const getNextPeriodFormatted = () => {
  return getNextPeriod().format('MMMM YYYY')
}

export const getDateFormat = (format: string) => {
  switch (format) {
    case 'm/d/Y':
      return 'MM/DD/YYYY'
    default:
      return format
  }
}

export const getMomentTimeFormat = (format: string) => {
  switch (format) {
    case 'H:i':
      return 'H:m'
    case 'h:i a':
      return 'hh:mm a'
    default:
      return format
  }
}

export const getMomentTimezoneFormat = (format: string) => {
  switch (format) {
    case 'US Pacific':
      return 'America/Los_Angeles'
    case 'US/Pacific':
      return 'America/Los_Angeles'
    default:
      return format
  }
}

export const formatDate = (obj: Moment): string => {
  return moment(obj, 'YYYY-MM-DD', true).format('YYYY-MM-DD')
}

export const formatDateSlash = (obj: Moment): string => {
  return moment(obj, 'MM/DD/YYYY', true).format('MM/DD/YYYY')
}

export const getDateTimeMomentFormat = (
  value: string | null | undefined,
  dateFormatString: string,
  timeFormatString: string
) => {
  const dateFormat = getDateFormat(dateFormatString)
  const timeFormat = getMomentTimeFormat(timeFormatString)
  return value && moment(value).format(`${dateFormat} ${timeFormat}`)
}

export const getDateMomentFormat = (
  value: string,
  dateFormatString: string
) => {
  const dateFormat = getDateFormat(dateFormatString)
  return moment(value).format(dateFormat)
}
