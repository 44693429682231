/** antd imports */
import { FilePdfOutlined } from '@ant-design/icons'
import { Modal, Table, TableColumnGroupType, TableColumnType } from 'antd'

/** 3rd party imports */
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment-timezone'

/** react imports */
import { FC, MutableRefObject, useEffect, useRef } from 'react'

/** zustand store imports */
import { useGlobalStore } from '../global/global-store'

/** utils imports */
import ActionLogEnum from '../utils/enums/ActionLogEnum'
import TxnLineCalculationEnum from '../utils/enums/TxnLineCalculationEnum'
import TxnLineSourceEnum from '../utils/enums/TxnLineSourceEnum'
import TxnLineStatusEnum from '../utils/enums/TxnLineStatusEnum'

/** custom components imports */
import GButton from './gappify/GButton'

const TxnLinesHistoryModal: FC<any> = ({
  showModal,
  onCancel,
  labelList,
  dateTimeFormat,
  historyData
}): JSX.Element => {
  /** refs */
  const pdfRef = useRef() as MutableRefObject<HTMLDivElement>

  /** zustand states */
  const {
    historyData: newHistoryData,
    setDisplay,
    activeKey,
    rcUseNewHistoryTable
  } = useGlobalStore()
  let data = historyData || newHistoryData

  const creatColumns = () => {
    const objectArray = Object.entries(data || {})
    const list: any = []

    list.push({
      key: 1,
      title: ``,
      dataIndex: 'key',
      fixed: 'left',
      width: 150
    })

    objectArray &&
      objectArray.forEach(([key, value]) => {
        let item: any = value
        let action_log_type: string = item ? item.action_log_type : ''
        let column = {
          key: key,
          title: (
            <>
              <div>
                {action_log_type &&
                  ActionLogEnum.getLabelByApiName(action_log_type)}
              </div>
              <div>
                {key &&
                  dateTimeFormat &&
                  moment
                    .unix(parseInt(key))
                    .tz(dateTimeFormat!.timezone_format)
                    .format('MM/DD/YYYY hh:mm a')}
              </div>
            </>
          ),
          dataIndex: key,
          width: 300,
          render: (value: string | number) => {
            return (
              <div
                style={
                  {
                    // textAlign: isAmount(value) ? 'right' : 'left'
                  }
                }
              >
                {value}
              </div>
            )
          }
        }
        list.push(column)
      })
    return list
  }

  const destructureData = () => {
    let keys: {}
    data &&
      Object.keys(data).forEach((item, i) => {
        keys = {
          ...data[Object.keys(data)[i]]
        }
      })
    const item = keys!
    const objectArray = item && Object.entries(item)
    const objectArray2 = data && Object.entries(data)
    const keyList: string[] = []
    const dataSource: object[] = []

    objectArray &&
      labelList &&
      labelList.forEach((item: any) => {
        keyList.push(item.api_name)
      })

    if (objectArray2?.length > 0) {
      if (!keyList.includes('override_reason')) {
        keyList.push('override_reason')
      }
      if (
        !keyList.includes('qje_error_reason') &&
        keyList.includes('qje_message')
      ) {
        keyList.push('qje_error_reason')
      }

      if (!keyList.includes('reject_reason')) {
        keyList.push('reject_reason')
      }

      objectArray &&
        objectArray.forEach(([key]: any) => {
          if (!keyList.includes(key)) keyList.push(key)
        })
    }

    keyList &&
      keyList.forEach((api) => {
        // search label from field list settings
        const labels: any = labelList.filter((obj: any) => obj.api_name === api)

        if (
          labels.length ||
          api === 'override_reason' ||
          api === 'qje_error_reason' ||
          api === 'reject_reason' ||
          api === 'action_message' ||
          api === 'action_user_email' ||
          api === 'action_user_name' ||
          api === 'qje_messages'
        ) {
          let label = ''

          if (api === 'override_reason') {
            label = 'Override Explanation'
          } else if (api === 'qje_error_reason' || api === 'qje_messages') {
            label = 'QJE Error'
          } else if (api === 'reject_reason') {
            label = 'Rejection Reason'
          } else if (api === 'action_message') {
            label = 'Action Message'
          } else if (api === 'action_user_email') {
            label = 'Email'
          } else if (api === 'action_user_name') {
            label = 'Action By'
          } else {
            label = labels[0] && labels[0].label
          }

          let data: any = {
            key: label
          }

          objectArray2 &&
            objectArray2.forEach(([key, value]: any) => {
              if (
                labels[0] &&
                labels[0].field_type &&
                labels[0].field_type === 'numeric_accounting'
              ) {
                if (value[api]) {
                  const amount =
                    typeof value[api] === 'string'
                      ? value[api]
                      : parseFloat(value[api]).toFixed(2).toString()

                  data[key] = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                } else {
                  data[key] = value[api]
                }
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'g_status'
              ) {
                let val = parseInt(value[api])
                if (isNaN(val)) {
                  data[key] =
                    TxnLineStatusEnum.getLabelById(value[api]) !== ''
                      ? TxnLineStatusEnum.getLabelById(value[api])
                      : value[api]
                } else {
                  data[key] = TxnLineStatusEnum.getLabelById(val)
                }
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'g_calculation'
              ) {
                let val = parseInt(value[api])
                if (isNaN(val)) {
                  data[key] =
                    TxnLineCalculationEnum.getLabelById(value[api]) !== ''
                      ? TxnLineCalculationEnum.getLabelById(value[api])
                      : value[api]
                } else {
                  data[key] = TxnLineCalculationEnum.getLabelById(val)
                }
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'g_line_source'
              ) {
                let val = parseInt(value[api])
                if (isNaN(val)) {
                  data[key] =
                    TxnLineSourceEnum.getLabelById(value[api]) !== ''
                      ? TxnLineSourceEnum.getLabelById(value[api])
                      : value[api]
                } else {
                  data[key] = TxnLineSourceEnum.getLabelById(val)
                }
              } else if (
                labels[0] &&
                labels[0].data_type &&
                labels[0].data_type === 'boolean'
              ) {
                if (value[api] === '1') {
                  data[key] = 'Yes'
                } else if (value[api] === '0') {
                  data[key] = 'No'
                } else {
                  data[key] = value[api]
                }
              } else if (
                labels[0] &&
                labels[0].field_type &&
                labels[0].field_type === 'date'
              ) {
                data[key] =
                  value[api] && moment(value[api]).format('MM/DD/YYYY')
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'g_accrual_amount_original'
              ) {
                data[key] =
                  value[api] &&
                  parseFloat(value[api])
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'g_reporting_amount_original'
              ) {
                data[key] =
                  value[api] &&
                  parseFloat(value[api])
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } else if (
                (labels[0] &&
                  labels[0].field_type &&
                  labels[0].data_type &&
                  labels[0].data_type === 'dateTime' &&
                  labels[0].field_type === 'date') ||
                (labels[0] &&
                  labels[0].data_type &&
                  labels[0].data_type === 'timestamp')
              ) {
                data[key] =
                  value[api] && moment(value[api]).format('MM/DD/YYYY hh:mm a')
              } else if (
                labels[0] &&
                labels[0].api_name &&
                labels[0].api_name === 'qje_messages'
              ) {
                data['qje_error_reason'] = value[api]
              } else if (api === 'reject_reason') {
                data[key] = value[api]?.replace('<br/>', ' - ')
              } else if (api === 'override_reason') {
                if (
                  value[api] === null &&
                  value['action_log_type'] === 'override'
                ) {
                  data[key] = value['action_message']
                } else {
                  data[key] = value[api]
                }
              } else {
                data[key] = value[api]
              }
            })

          dataSource.push(data)
        }
      })
    return dataSource
  }

  const getPdfTableColumns = (): { title: string }[] => {
    const objectArray = Object.entries(data)
    const list: { title: string }[] = [
      {
        title: ''
      }
    ]

    objectArray.forEach(([key, value]) => {
      const item: any = value
      const actionLogType: string = item ? item.action_log_type : ''

      list.push({
        title: `${
          (actionLogType && ActionLogEnum.getLabelByApiName(actionLogType)) ??
          ''
        }\n${key && moment.unix(parseInt(key)).format('MM/DD/YYY hh:mm a')}`
      })
    })
    return list
  }

  const handleDownloadPdf = async () => {
    const tableRows = destructureData().map((rowItem: any) => {
      return [
        ...Object.keys(rowItem)
          .filter((key) => key === 'key')
          .map((key) => rowItem[key]),
        ...Object.keys(rowItem)
          .filter((key) => key !== 'key')
          .map((key) => rowItem[key])
      ]
    })

    const columnLength = getPdfTableColumns().length
    const rowLength = tableRows.length
    const widthPixels = columnLength < 5 ? 200 : 100
    const heightPixels = columnLength < 5 ? 5 : 10

    /**
     * dynamically sets width and height of the
     * PDF's paper size based on the column length
     */
    const width = columnLength * widthPixels
    const height = rowLength * heightPixels
    const pdf = new jsPDF({
      orientation: 'landscape',
      format: [width, height],
      unit: 'px'
    })

    autoTable(pdf, {
      head: [getPdfTableColumns()],
      body: tableRows,
      columnStyles: {
        0: { minCellWidth: 100 },
        1: { minCellWidth: 100 },
        2: { minCellWidth: 100 },
        3: { minCellWidth: 100 },
        4: { minCellWidth: 100 },
        5: { minCellWidth: 100 },
        6: { minCellWidth: 100 }
      }
    })
    pdf.save('history-log.pdf')
  }

  const newHistoryColumns: (
    | TableColumnType<any>
    | TableColumnGroupType<any>
  )[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performedBy',
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 200
    },
    {
      title: 'Old Value',
      dataIndex: 'old',
      key: 'oldValue',
      width: 200
    },
    {
      title: 'New Value',
      dataIndex: 'new',
      key: 'newValue',
      width: 200
    }
  ]

  useEffect(() => {
    if (rcUseNewHistoryTable) {
      // NEW HISTORY
      setDisplay(
        <Table
          data-testid='rc-quick-actions-new-history-table'
          data-cy='rc-quick-actions-new-history-table'
          dataSource={data || []}
          columns={newHistoryColumns || []}
          pagination={false}
          scroll={{ x: 'max-content', y: 500 }}
          size='small'
        />
      )
    }
    // OLD HISTORY
    else
      setDisplay(
        <>
          <Table
            data-testid='txn-line-history-modal-table'
            data-cy='txn-line-history-modal-table'
            ref={pdfRef}
            dataSource={destructureData() || []}
            columns={creatColumns() || []}
            pagination={false}
            scroll={{ x: 'max-content', y: 500 }}
            size='small'
          />
        </>
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  return (
    <Modal
      data-testid='txn-line-history-modal'
      data-cy='txn-line-history-modal'
      title='View History'
      open={showModal}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{ height: 1000 }}
      footer={null}
      className='history-modal'
    >
      {JSON.stringify(data) !== JSON.stringify({}) && (
        <GButton
          dataTestId='txn-line-history-modal-export-btn'
          dataCy='txn-line-history-modal-export-btn'
          type='primary'
          icon={<FilePdfOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
          onClick={handleDownloadPdf}
          style={{
            float: 'right',
            marginBottom: '5px'
          }}
        >
          Export
        </GButton>
      )}
      <Table
        data-testid='txn-line-history-modal-table'
        data-cy='txn-line-history-modal-table'
        ref={pdfRef}
        dataSource={destructureData()}
        columns={creatColumns()}
        pagination={false}
        scroll={{ x: 'max-content', y: 500 }}
        size='small'
      />
    </Modal>
  )
}
export default TxnLinesHistoryModal
