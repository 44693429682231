import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Button,
  InputNumber,
  DatePicker,
  Popconfirm
} from 'antd'
import moment from 'moment'
import { FC, useEffect, useState, useRef } from 'react'
import GButton from '../gappify/GButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import GIcon from '../gappify/GIcon'
import {
  getFilterDetails,
  getFilterFields,
  createFilter,
  updateFilter,
  deleteFilter
} from '../../services/TrdManagerAPI'
import TxnLineStatusEnum from '../../utils/enums/TxnLineStatusEnum'
import TxnLineSourceEnum from '../../utils/enums/TxnLineSourceEnum'
import TxnLineCalculationEnum from '../../utils/enums/TxnLineCalculationEnum'
import { setIsLoading } from '../../context/actions/taskManagerAction'
import { useDispatch } from 'react-redux'
import { callGetApi } from '../../services/GenericAPI'
import filterSort from '../../utils/filterSort'
import filterOption from '../../utils/filterOption'

const { Option } = Select
const { RangePicker } = DatePicker
interface TrdCreateNewFilterModalProps {
  overrideIsVisibleModal?: any
  overrideSetIsVisibleModal?: any
  overrideFinishLabel?: string | JSX.Element
  overrideLabel?: string | JSX.Element
  overrideHeader?: string | JSX.Element
  filterId?: any
  setIsLoading?: any
  callGetAllFilters: () => any
  activeFilterRef?: any
  getFilterLabel?: (filterKey: string) => string
  setActiveFilter?: any
  reloadData?: any
  setIsPreviewMode?: any
  setPreviewTriggeredFrom?: any
  setTransactionLines?: any
  optionsList?: any
}

const TrdCreateNewFilterModal: FC<TrdCreateNewFilterModalProps> = ({
  overrideIsVisibleModal,
  overrideSetIsVisibleModal,
  overrideFinishLabel,
  overrideLabel,
  overrideHeader,
  filterId,
  callGetAllFilters,
  activeFilterRef,
  setActiveFilter,
  reloadData,
  setIsPreviewMode,
  setPreviewTriggeredFrom,
  setTransactionLines,
  optionsList
}: TrdCreateNewFilterModalProps): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
  const [newFilters, setNewFilters] = useState<any[]>([])
  const [countNewFilters, setCountNewFilters] = useState<any>(0)

  const [suggestedValues, setSuggestedValues] = useState<
    { id: any; value: any }[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [focusedApiName, setFocusedApiName] = useState<string>('')

  const [filterFields, setFilterFields] = useState<any>({})
  const [filterDetails, setFilterDetails] = useState<any>()
  const [groupedChoices, setGroupedChoices] = useState<any>([])
  const isPreviewMode = useRef(false)

  const loadFilterFields = async () => {
    let formatFields = {}

    await getFilterFields().then((res) => {
      const filterFields = res.data.fields
      if (filterFields && filterFields?.length > 0) {
        filterFields.forEach((item: any) => {
          formatFields = {
            ...formatFields,
            [item.api_name]: item
          }
        })
      }
    })

    return formatFields
  }

  const onPreview = async () => {
    isPreviewMode.current = true
    setIsPreviewMode(true)
    if (filterId) setPreviewTriggeredFrom('preview')
    else setPreviewTriggeredFrom('new')
    await form.submit()
  }

  const onFinish = async (e: any) => {
    if (newFilters?.length === 0)
      message.error('You need to add at least one filter.')
    else {
      dispatch(setIsLoading(true))
      let payload: any = {}
      payload.name = e.name
      payload.filter_fields = newFilters?.map((filter) => {
        let columnDetails: { column: string; value: any; op?: string } = {
          column: filter.field,
          value: filter.value
        }
        if (filter.op) columnDetails.op = filter.op
        return columnDetails
      })
      if (isPreviewMode.current) {
        payload = {
          ...payload,
          preview_mode: true
        }
      }

      try {
        const { data } =
          (filterId && (await updateFilter(filterId, payload))) ||
          (await createFilter(payload))

        if (['', undefined, null].includes(filterId)) {
          // new...
          activeFilterRef.current = {
            id: data.id,
            label: data.name
          }
          setActiveFilter({
            id: data.id,
            label: data.name
          })
          if (!isPreviewMode.current) {
            // setIsPreviewMode(false)
            setPreviewTriggeredFrom(() => undefined)
            form.resetFields()
            resetFields('all', true)
            message.success('Successfully created!')
          } else {
            if (filterId) {
              setPreviewTriggeredFrom('preview')
            } else setPreviewTriggeredFrom('new')
            setIsPreviewMode(true)
          }
        } else {
          activeFilterRef.current = {
            id: filterId,
            label: payload.name
          }
          setActiveFilter({
            id: filterId,
            label: payload.name
          })
          if (!isPreviewMode.current) message.success('Successfully updated!')
        }

        dispatch(setIsLoading(false))
        if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
        else setIsVisibleModal(false)

        if (!isPreviewMode.current) reloadData()
        else reloadData(true, true)
      } catch (error) {
        message.error('An error occurred.')
        dispatch(setIsLoading(false))
      }
      if (!isPreviewMode.current) callGetAllFilters()
    }
  }

  const onDelete = () => {
    dispatch(setIsLoading(true))
    deleteFilter(filterId)
      .then((res: any) => {
        message.success('Successfully deleted!')
        callGetAllFilters()
        if (overrideSetIsVisibleModal) overrideSetIsVisibleModal(false)
        else setIsVisibleModal(false)
        dispatch(setIsLoading(false))

        activeFilterRef.current = {
          id: 'all',
          label: 'All Lines'
        }
        setActiveFilter({
          id: 'all',
          label: 'All Lines'
        })
        reloadData()
      })
      .catch(() => {
        message.error('An error occurred.')
        callGetAllFilters()
        dispatch(setIsLoading(false))
      })
  }

  const onCancel = (isCancel?: boolean) => {
    if (overrideSetIsVisibleModal)
      overrideSetIsVisibleModal(
        (previousValue: boolean) => (previousValue = false)
      )
    else setIsVisibleModal((previousValue: boolean) => (previousValue = false))

    if (!filterId) {
      activeFilterRef.current = {
        id: 'all',
        label: 'All Lines'
      }
      setActiveFilter({
        id: 'all',
        label: 'All Lines'
      })
    }

    // if (filterId || isPreviewMode) applyFilterToFields(filterId)
    // else resetFields('all', true)

    if (isCancel) {
      if (!isPreviewMode.current) reloadData()
      else reloadData(true, true)

      isPreviewMode.current = false
      setIsPreviewMode(false)
      setPreviewTriggeredFrom(() => undefined)
    }
    setNewFilters([])
  }

  // const applyFilterToFields = async (filterId: string) => {
  //   await getFilterDetails(filterId).then(({ data }: any) => {
  //     setCountNewFilters(data.filter_fields?.length)
  //     let extractedFilters = filterDetails.filter_fields.map(
  //       (filterField: any, index: number) => {
  //         return {
  //           field: filterField.column,
  //           value: filterField.value,
  //           op: filterField.op,
  //           dataType: getDataType(filterField.column),
  //           key: index,
  //           fieldType: getFieldType(filterField.column),
  //           options_source: getOptionSource(filterField.column)
  //         }
  //       }
  //     )

  //     setNewFilters(extractedFilters)
  //     form.setFieldValue('name', data.name)
  //     Array.from(
  //       Array(data.filter_fields?.length),
  //       (_, index: number) => index
  //     ).forEach((index: number) => {
  //       form.setFieldValue(`${index}-field`, data.filter_fields[index].column)
  //       form.setFieldValue(`${index}-operator`, data.filter_fields[index].op)
  //       form.setFieldValue(`${index}-value`, data.filter_fields[index].value)
  //     })
  //   })
  // }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
    console.log('Failed:', errorInfo)
  }

  const renderFilterInputComponents = (filter: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'end'
        }}
      >
        <Form.Item
          initialValue={filter.field}
          name={`${filter.key}-field`}
          label='Field'
          rules={[{ required: true, message: 'Field is required.' }]}
          style={{ width: '90%' }}
        >
          <Select
            data-testid={`trd-create-new-filter-${filter.key}-field`}
            data-cy={`trd-create-new-filter-${filter.key}-field`}
            showSearch
            placeholder='Please select'
            onChange={(e) => handleChangeFilter(filter, e)}
            style={{ width: '90%' }}
            optionFilterProp='children'
            filterOption={filterOption}
            filterSort={filterSort}
          >
            {Object.keys(filterFields)?.map((key: string, index: number) => {
              return (
                <Select.Option
                  value={key}
                  key={index}
                  disabled={newFilters?.map((f) => f.field).includes(key)}
                >
                  {filterFields[key].label}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {filter.fieldType === 'numeric_accounting' && (
          <Form.Item
            initialValue={filter.op}
            name={`${filter.key}-operator`}
            rules={[{ required: true, message: 'Operator is required.' }]}
            style={{ width: '90%' }}
          >
            <Select
              data-testid={`trd-create-new-filter-${filter.fieldType}-${filter.key}-field`}
              data-cy={`trd-create-new-filter-${filter.fieldType}-${filter.key}-field`}
              showSearch
              placeholder='Select operator'
              onChange={(e) => handleChangeOperator(filter, e)}
              style={{ width: '90%' }}
              optionFilterProp='children'
              filterOption={filterOption}
              filterSort={filterSort}
            >
              {Object.keys(operatorMenu)?.map((key: string, index: number) => {
                return (
                  <Select.Option value={key} key={index}>
                    {operatorMenu[key]}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          valuePropName={
            ['date', 'datetime'].includes(filter.dataType) ||
            ['date', 'datetime'].includes(filter.fieldType)
              ? 'date'
              : undefined
          }
          initialValue={filter.value}
          name={`${filter.key}-value`}
          label='Value'
          rules={[{ required: true, message: 'Value is required.' }]}
          style={{ width: '90%' }}
        >
          {renderValueInput(filter)}
        </Form.Item>
        <div style={{ alignSelf: 'center' }}>
          <GButton
            dataTestId={`trd-create-new-filter-remove-field-${filter.key}`}
            dataCy={`trd-create-new-filter-remove-field-${filter.key}`}
            type='text'
            className='g-btn-icon'
            onClick={() => {
              resetFields(filter.key)
              setNewFilters(newFilters.filter((f: any) => f.key !== filter.key))
            }}
            icon={<GIcon icon={faTrash} />}
          />
        </div>
      </div>
    )
  }

  const resetValueBasedOnDataType = (apiName: string) => {
    switch (filterFields[apiName].dataType) {
      case 'int':
        return null

      case 'boolean':
      case 'varchar':
        return []

      default:
        return []
    }
  }

  const renderValueInput = (filter: any) => {
    if (filter.dataType === 'boolean' || filter.field === 'g_under_threshold') {
      return (
        <Select
          data-testid={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          data-cy={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          mode='multiple'
          style={{ width: '90%' }}
          placeholder='Select value'
          defaultValue={[]}
          optionLabelProp='label'
          options={[
            { key: 1, value: 1, label: 'Yes' },
            { key: 0, value: 0, label: 'No' }
          ]}
          onChange={(e: any) => {
            setNewFilters(
              newFilters?.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: e }
                  : newFilter
              })
            )
          }}
        />
      )
    } else if (filter.fieldType === 'numeric_accounting') {
      return (
        <InputNumber
          data-testid={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          data-cy={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          style={{ width: '90%' }}
          defaultValue={filter.value}
          step='0.01'
          onChange={(e: any) => {
            setNewFilters(
              newFilters?.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: Number(e) }
                  : newFilter
              })
            )
          }}
        />
      )
    } else if (
      filter.fieldType === 'picklist_single' ||
      filter.field === 'g_status' ||
      filter.field === 'g_calculation' ||
      filter.field === 'g_line_source' ||
      filter.field === 'form_name' ||
      filter.field === 'g_accrual_type'
    ) {
      return (
        <Select
          data-testid={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          data-cy={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          mode='multiple'
          onClick={async () => {
            if (
              filter.options_source &&
              filter.options_source.url &&
              !Object.keys(groupedChoices).includes(filter.field)
            ) {
              await callGetApi(filter.options_source.url).then((res) => {
                let copyGroupedChoices = { ...groupedChoices }
                copyGroupedChoices[filter.field] = res.data
                setGroupedChoices(copyGroupedChoices)
              })
            }

            setSuggestedValues([])
          }}
          style={{ width: '90%' }}
          placeholder='Select value'
          onChange={(e: any) => {
            setNewFilters(
              newFilters?.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: e }
                  : newFilter
              })
            )
          }}
          onKeyUp={(e: any) => {
            if (
              filter.options_source &&
              filter.options_source.url &&
              filter.options_source.url.includes('source_lines')
            ) {
              if (e.target.value?.length >= 3) {
                setFocusedApiName(filter.options_source.url + '&value=')
                setSearchTerm(e.target.value)
              }
            }
          }}
          onInputKeyDown={async (e: any) => {
            if (
              filter.options_source &&
              filter.options_source.url &&
              filter.options_source.url.includes('source_lines')
            ) {
              if (
                [e.key, e.code].includes('Enter') &&
                !['', null, undefined].includes(e.target.value)
              ) {
                await form.setFieldValue(`${filter.key}-value`, [
                  ...form.getFieldValue(`${filter.key}-value`),
                  e.target.value
                ])
                await setNewFilters(
                  newFilters?.map((f: any) => {
                    return filter.key === f.key
                      ? { ...f, value: [...f.value, e.target.value] }
                      : f
                  })
                )
                e.target.value = ''
              }
            }
          }}
          value={filter.value}
        >
          {filter.options_source &&
          filter.options_source.url &&
          filter.options_source.url?.includes('source_lines') &&
          suggestedValues?.length > 0 ? (
            suggestedValues?.map((item: any) => {
              return (
                <Option key={item.id} value={item.value}>
                  {item.value}
                </Option>
              )
            })
          ) : Array.isArray(filter.options_source) ? (
            filter.options_source &&
            filter.options_source?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id.toString()}>
                  {item.value}
                </Option>
              )
            })
          ) : Object.keys(groupedChoices).includes(filter.field) ? (
            groupedChoices[filter.field]?.map((option: any, index: any) => (
              <Option
                key={index}
                value={option.value}
                hidden_value={
                  option.g_source_system_id
                    ? option.g_source_system_id
                    : option.id
                }
              >
                {option.value}
              </Option>
            ))
          ) : (
            <Option key={'djkshfgakdsfh '} value={'seasrch'} disabled>
              Search...
            </Option>
          )}
        </Select>
      )
    } else if (
      filter.dataType === 'date' ||
      filter.dataType === 'datetime' ||
      filter.fieldType === 'date'
    ) {
      return (
        <RangePicker
          data-testid={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          data-cy={`trd-create-new-filter-${filter.field}-${filter.key}-field`}
          defaultValue={
            filter.value && filter.value.length > 1
              ? [
                  moment(filter.value[0], 'MM/DD/YYYY'),
                  moment(filter.value[1], 'MM/DD/YYYY')
                ]
              : null
          }
          style={{ width: '90%' }}
          format={'MM/DD/YYYY'}
          onChange={(e: any) => {
            e &&
              setNewFilters(
                newFilters?.map((newFilter: any) => {
                  return filter.key === newFilter.key
                    ? {
                        ...newFilter,
                        value: [
                          moment(e[0]).format('MM/DD/YYYY'),
                          moment(e[1]).format('MM/DD/YYYY')
                        ]
                      }
                    : newFilter
                })
              )
          }}
        />
      )
    } else {
      return (
        <Input
          data-testid={`trd-create-new-filter-${filter.key}-field`}
          data-cy={`trd-create-new-filter-${filter.key}-field`}
          style={{ width: '90%' }}
          onChange={(e: any) =>
            setNewFilters(
              newFilters?.map((newFilter: any) => {
                return filter.key === newFilter.key
                  ? { ...newFilter, value: e.target.value }
                  : newFilter
              })
            )
          }
        />
      )
    }
  }

  const handleChangeFilter = (filter: any, newField: any) => {
    setNewFilters(
      newFilters?.map((newFilter) => {
        if (filter.key === newFilter.key) {
          form.setFieldValue(
            `${filter.key}-value`,
            resetValueBasedOnDataType(newField)
          )
          form.setFieldValue(`${filter.key}-operator`, null)
          let optionSource: any = null

          if (newField === 'g_status') {
            optionSource = TxnLineStatusEnum.getIdValueList()
          } else if (newField === 'g_calculation') {
            optionSource = TxnLineCalculationEnum.getIdValueList()
          } else if (newField === 'g_line_source') {
            optionSource = TxnLineSourceEnum.getIdValueList()
          } else {
            optionSource = filterFields[newField].options_source
          }

          return {
            ...newFilter,
            field: newField,
            op: null,
            value: [],
            dataType: filterFields[newField].data_type,
            fieldType: filterFields[newField].field_type,
            options_source: optionSource
          }
        } else return newFilter
      })
    )
    setSuggestedValues([])
  }

  const handleChangeOperator = (filter: any, newOperator: any) => {
    setNewFilters(
      newFilters?.map((newFilter) => {
        if (filter.key === newFilter.key) {
          return { ...newFilter, op: newOperator }
        } else return newFilter
      })
    )
    // setSuggestedValues([])
  }

  const operatorMenu: any = {
    '=': 'Equals',
    '!=': 'Not equals',
    '>': 'Is greater than',
    '>=': 'Is greater than or equals',
    '<': 'Is less than',
    '<=': 'Is less than or equals'
  }

  const resetFields = (mode: string | number, clearTitle?: boolean) => {
    if (mode === 'all') {
      Array.from(Array(countNewFilters), (_, index: number) => index).forEach(
        (index: number) => {
          form.setFieldValue(`${index}-field`, null)
          form.setFieldValue(`${index}-operator`, null)
          form.setFieldValue(`${index}-value`, null)
        }
      )
      setNewFilters([])
      setCountNewFilters(0)
    } else {
      form.setFieldValue(`${mode}-field`, null)
      form.setFieldValue(`${mode}-operator`, null)
      form.setFieldValue(`${mode}-value`, null)
    }

    if (clearTitle) {
      form.setFieldValue('name', null)
    }
  }

  const getDataType = (apiName: string) => {
    return filterFields[apiName]?.data_type || ''
  }

  const getFieldType = (apiName: string) => {
    return filterFields[apiName]?.field_type || ''
  }

  const getOptionSource = (apiName: string) => {
    if (apiName === 'g_status') {
      return TxnLineStatusEnum.getIdValueList()
    } else if (apiName === 'g_calculation') {
      return TxnLineCalculationEnum.getIdValueList()
    } else if (apiName === 'g_line_source') {
      return TxnLineSourceEnum.getIdValueList()
    }

    return filterFields[apiName]?.options_source || ''
  }

  useEffect(() => {
    loadFilterFields().then((res) => setFilterFields(res))
  }, [])

  useEffect(() => {
    if (overrideIsVisibleModal || isVisibleModal) {
      if (!isPreviewMode.current) {
        if (
          ![
            'all',
            '100000',
            '100001',
            '100002',
            '100003',
            '',
            undefined
          ].includes(filterId)
        )
          getFilterDetails(filterId).then(async (res) => {
            setFilterDetails(res.data)
          })
        // else setFilterDetails({})
      }
    }
  }, [filterId, isVisibleModal, overrideIsVisibleModal, isPreviewMode])

  useEffect(() => {
    if (filterId && filterDetails) {
      setCountNewFilters(filterDetails?.filter_fields?.length)
      let extractedFilters = filterDetails?.filter_fields?.map(
        (filterField: any, index: number) => {
          return {
            field: filterField.column,
            value: filterField.value,
            op: filterField.op,
            dataType: getDataType(filterField.column),
            key: index,
            fieldType: getFieldType(filterField.column),
            options_source: getOptionSource(filterField.column)
          }
        }
      )
      setNewFilters(extractedFilters)

      form.setFieldValue('name', filterDetails.name)
      extractedFilters?.forEach((filter: any, index: number) => {
        form.setFieldValue(`${index}-field`, filter.field)
        form.setFieldValue(`${index}-value`, filter.value)
        form.setFieldValue(`${index}-operator`, filter.op)
      })
    }
    // eslint-disable-next-line
  }, [filterDetails, filterFields])

  // for autocomplete backend calls...
  // after 1 sec, if user is not typing anything, fetch suggested values from BE
  useEffect(() => {
    if (searchTerm?.length >= 3) {
      const delayDebounceFn = setTimeout(() => {
        callGetApi(focusedApiName + searchTerm).then((res: any) => {
          setSuggestedValues(res.data)
        })
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <>
      <div
        onClick={() => {
          if (!overrideSetIsVisibleModal) setIsVisibleModal(true)
        }}
      >
        {overrideLabel || 'Create New Filter'}
      </div>
      <Modal
        data-testid='trd-create-new-filter-modal'
        data-cy='trd-create-new-filter-modal'
        title={overrideHeader || 'Create New Filter'}
        open={overrideIsVisibleModal ? overrideIsVisibleModal : isVisibleModal}
        onCancel={() => onCancel(false)}
        onOk={form.submit}
        cancelButtonProps={{
          shape: 'round'
        }}
        okButtonProps={{
          shape: 'round'
        }}
        footer={[
          <>
            <Button
              data-testid='trd-create-new-filter-modal-save-view-btn'
              data-cy='trd-create-new-filter-modal-save-view-btn'
              shape='round'
              key='save-view'
              type='primary'
              onClick={() => {
                isPreviewMode.current = false
                setIsPreviewMode(false)
                form.submit()
              }}
            >
              {overrideFinishLabel || 'Save View'}
            </Button>
            {filterId && (
              <Popconfirm
                title='Are you sure to delete this filter?'
                onConfirm={onDelete}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  data-testid='trd-create-new-filter-modal-delete -view-btn'
                  data-cy='trd-create-new-filter-modal-delete -view-btn'
                  shape='round'
                  key='delete-view'
                >
                  Delete View
                </Button>
              </Popconfirm>
            )}
          </>,
          <Button
            data-testid='trd-create-new-filter-modal-preview-btn'
            data-cy='trd-create-new-filter-modal-preview-btn'
            shape='round'
            key='preview'
            type='default'
            onClick={onPreview}
          >
            Preview
          </Button>,
          <Button
            data-testid='trd-create-new-filter-modal-cancel-btn'
            data-cy='trd-create-new-filter-modal-cancel-btn'
            shape='round'
            key='cancel'
            type='default'
            onClick={() => onCancel(true)}
          >
            Cancel
          </Button>
        ]}
        width={900}
      >
        <Form
          data-testid='trd-create-new-filter-form'
          data-cy='trd-create-new-filter-form'
          form={form}
          layout='vertical'
          name='create-new-filter'
          initialValues={{ remember: true }}
          autoComplete='off'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Enter name of the filter.' }]}
            initialValue={filterDetails?.name || ''}
          >
            <Input
              data-testid='trd-create-new-filter-form-name-field'
              data-cy='trd-create-new-filter-form-name-field'
              maxLength={30}
            />
          </Form.Item>

          {newFilters?.map((filter: any) => {
            return renderFilterInputComponents(filter)
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <GButton
              dataTestId='trd-create-new-filter-add-filter-btn'
              dataCy='trd-create-new-filter-add-filter-btn'
              btnText='Add Filter'
              onClick={() => {
                resetFields(countNewFilters)
                setNewFilters([
                  ...newFilters,
                  {
                    key: countNewFilters,
                    field: null,
                    value: []
                  }
                ])
                setCountNewFilters(countNewFilters + 1)
              }}
            />
            {newFilters?.length > 0 ? (
              <GButton
                dataTestId='trd-create-new-filter-remove-all-filter-btn'
                dataCy='trd-create-new-filter-remove-all-filter-btn'
                btnText='Remove All'
                onClick={() => {
                  resetFields('all')
                }}
              />
            ) : null}
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default TrdCreateNewFilterModal
