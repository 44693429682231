/** tanstack imports */
import { useMutation } from '@tanstack/react-query'

/** antd imports */
import { message } from 'antd'

/** hooks imports */
import useApiService from '../../../hooks/useApiService'
import useUploadFileModal from './useUploadFileModal'

/** services imports */
import useTxnService from '../../../services/useTxnService'

/** zustand store imports */
import useUploadFileModalStore from '../manager/useUploadFileModalStore'

// =================================================================
export default function useUpload() {
  /** hooks */
  const { msUrl } = useApiService()
  const { getForm, handleErrorReponse } = useUploadFileModal()

  /** services */
  const { txnApi } = useTxnService()

  /** zustand store */
  const { sourceFile, setIsModalOpen } = useUploadFileModalStore()

  const { mutate: uploadFileApi, isLoading: isUploadLoading } = useMutation(
    txnApi,
    {
      onSuccess: (response) => {
        setIsModalOpen(false)
        message.success('Import Validation Successful!')
      },
      onError: (error) => {
        handleErrorReponse(error)
      }
    }
  )

  const handleUpload = () => {
    const fiftyMB = 1 * 1024 * 1024 * 50
    if (sourceFile?.size! > fiftyMB) {
      message.error('The file is too large to process.')
      return
    }

    const formData: FormData = getForm()

    uploadFileApi({
      data: formData,
      options: {
        baseURL: msUrl.txn_manager,
        endpoint: '/api/source-csv',
        method: 'POST',
        customHeaders: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  }

  return {
    handleUpload,
    isUploadLoading
  }
}
