import { Space, Typography } from 'antd'
import styles from '../../styles/collapsible.module.css'
import useAnalyticsStore from '../../manager/analytics-store'

const CollapsibleTitle = () => {
  const { collapsed } = useAnalyticsStore()
  return (
    <Space
      direction='horizontal'
      size={'large'}
      className={collapsed ? styles.hidden : styles.visible}
    >
      <Typography.Title level={2}>Insights</Typography.Title>
    </Space>
  )
}

export default CollapsibleTitle
