// ** React Imports
import React, { useEffect } from 'react'

// ** AntD Imports
import { Table } from 'antd'

// ** Data Imports
import { columns } from '../data'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Store Imports
import { RootState } from '../../../../../context/store'

// ** Hooks Imports
import useAPI from '../hooks/useAPI'
import useTable from '../hooks/useTable'

// =================================================================
const LoginAuditTrailTable = () => {
  // ** Redux **
  const { data, isLoading, page, sort } = useSelector(
    (state: RootState) => state.loginAuditTrail
  )

  // ** Hooks **
  const { fetchData } = useAPI()
  const { handleTableChange, paginationConfig } = useTable()

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort])

  return (
    <div data-testid='LoginAuditTrailTable'>
      <Table
        columns={columns}
        dataSource={data}
        rowKey='id'
        scroll={{ y: '55dvh' }}
        pagination={paginationConfig}
        onChange={handleTableChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        loading={isLoading}
      />
    </div>
  )
}

export default LoginAuditTrailTable
