// ** AntD Imports
import { Button } from 'vincent-antd'

// ** Style Imports
import styles from '../styles/global-filter.module.css'

// =================================================================
const Options = ({
  addFilter,
  removeAll
}: {
  addFilter: () => void
  removeAll: () => void
}) => {
  return (
    <div className={styles.addFilterBtnWrap}>
      <Button shape='round' className={styles.filterBtn} onClick={addFilter}>
        Add Filter
      </Button>
      <Button shape='round' className={styles.filterBtn} onClick={removeAll}>
        Remove All
      </Button>
    </div>
  )
}

export default Options
