// ** Custom Component Imports **
import GButton from '../../../../../components/gappify/GButton'

// ** ZUstand imports **
import useScheduleStore from '../manager/schedule-store'

const SaveButton = ({ btnKey }: { btnKey: string }) => {
  const { handleOnClickSave } = useScheduleStore()
  return (
    <GButton
      htmlType='submit'
      btnText='SAVE'
      onClick={() => handleOnClickSave(btnKey)}
      style={{ width: 150, height: 40 }}
    />
  )
}

export default SaveButton
