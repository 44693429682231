import { useEffect } from 'react'

// Antd Imports
import { Button, Modal } from 'antd'

// Hook Imports
import useDeleteView from '../hooks/useDeleteView'

// Zustand Imports
import useStore from '../manager/rc-settings-store'

// Constants Imports
import constants from '../config/constants'

type Props = {
  open?: boolean
  deleteId: number
}

const { DELETE_MODAL } = constants

const DeleteModal = (props: Props) => {
  const { open, deleteId } = props

  /** Tanstack Query states */
  const { mutate: deleteView, isLoading } = useDeleteView()

  /** Global states */
  const { showDeleteModal, setShowDeleteModal } = useStore()

  /** Event handlers */
  const handleDelete = () => {
    deleteId && deleteView({ id: deleteId })
  }
  const handleCancel = () => {
    setShowDeleteModal(false)
  }

  /** Side effects */
  useEffect(() => {
    if (!isLoading) {
      handleCancel()
    }
    // eslint-disable-next-line
  }, [isLoading])

  return (
    <Modal
      data-testid={DELETE_MODAL.TEST_ID}
      title={DELETE_MODAL.TITLE}
      open={open || showDeleteModal}
      onCancel={handleCancel}
      footer={[
        <Button
          key='cancel'
          onClick={handleCancel}
          data-testid='cancel-button'
          loading={isLoading}
        >
          Cancel
        </Button>,
        <Button
          danger
          key='delete'
          onClick={handleDelete}
          data-testid='delete-button'
          loading={isLoading}
        >
          Delete
        </Button>
      ]}
    >
      {DELETE_MODAL.MSG}
    </Modal>
  )
}

export default DeleteModal
