import { SetStateAction } from 'react'
import { getTxnManagerColumns } from '../../../services/TrdManagerAPI'
import TransactionManagerColumn from '../../../types/TransactionManagerColumn'

export default async function loadTxnManagerData(
  setIsLoading: (value: SetStateAction<boolean>) => void,
  setDataSource: (value: SetStateAction<TransactionManagerColumn[]>) => void,
  setDataSourceCopy: (value: SetStateAction<TransactionManagerColumn[]>) => void
) {
  setIsLoading(true)
  try {
    const { data } = await getTxnManagerColumns()
    const dataMap = data.map((item: TransactionManagerColumn) => {
      item.key = item.id
      return item
    })
    setDataSource(dataMap)
    setDataSourceCopy(dataMap)
  } catch (error) {
    console.log(error)
  } finally {
    setIsLoading(false)
  }
}
