import { axiosQjeManagerInstance } from '../helpers/axios'

interface CreateQjePayload {
  app: {
    key: string
    id: number
  }
  transaction_id: number
  line_ids: number[]
}

export const qjeStore = (payload: CreateQjePayload) => {
  return axiosQjeManagerInstance.post('/api/qje/store', payload)
}

export const qjeFetch = (params: any) => {
  return axiosQjeManagerInstance.get('/api/transactions', { params })
}

export const qjeFilterCreate = (params: any) => {
  return axiosQjeManagerInstance.post('/api/qje/filters', params)
}

export const qjeFiltersFetch = () => {
  return axiosQjeManagerInstance.get('/api/qje/filters')
}

export const qjeFilterDetails = (id: string) => {
  return axiosQjeManagerInstance.get(`/api/qje/filters/${id}`)
}

export const qjeFilterUpdate = (params: any, id: string | number) => {
  return axiosQjeManagerInstance.put(`/api/qje/filters/${id}`, params)
}

export const qjeFilterDelete = (id: string | number) => {
  return axiosQjeManagerInstance.delete(`/api/qje/filters/${id}`)
}

export const getQjeLogs = (line_group: string) => {
  return axiosQjeManagerInstance.get(`/api/qje-logs/${line_group}`)
}

export const qjeExport = (format: string, params: any) => {
  if (['pdf', 'excel'].includes(format)) {
    return axiosQjeManagerInstance.get(`api/qje/export/${format}`, {
      params: params,
      responseType: 'arraybuffer'
    })
  } else {
    return axiosQjeManagerInstance.get(`api/qje/export/${format}`, {
      params: params
    })
  }
}

export const postRejectJE = (payload: {
  line_groups: string[]
  je_reject_reason: string
}) => {
  return axiosQjeManagerInstance.post('/api/reject/je', payload)
}

export const postCloseUnreviewdPO = (payload: { line_groups: string[] }) => {
  return axiosQjeManagerInstance.post('/api/review/po', payload)
}

export const postJE = (payload: {
  line_groups: string[]
  je_date: string
  je_memo: string
  je_reversal_date: null | string
  je_approval_type: null | string
}) => {
  return axiosQjeManagerInstance.post('/api/review/je', payload)
}

export const qjeSettings = (type: string, period: string, subtype: string) => {
  return axiosQjeManagerInstance.get(
    `/api/qje/filter/getSelectedFields?qjetype=${type}&subtype=${subtype}&period=${period}`
  )
}

export const getGlobalSettings = () => {
  return axiosQjeManagerInstance.get(`/api/qje/settings`)
}

export const getSuggestedValues = (apiName: string, searchTerm: string) => {
  // used for autocomplete
  apiName = apiName === '' ? 'g_name' : apiName

  return axiosQjeManagerInstance.get(
    `/api/options/${apiName}?term=${searchTerm}`
  )
}

export const batchReviewQje = (
  qjeType: string,
  payload: { line_group: string | number; reviewed: number }[]
) => {
  return axiosQjeManagerInstance.post(`/api/flag/${qjeType}`, payload)
}
