import React from 'react'
import SubTotal from './SubTotal'
import Columns from './Columns'

const DepartmentVendor: React.FC<any> = ({
  subsidiaries,
  fields,
  view,
  clickHandler,
  config
}) => {
  return (
    <React.Fragment
      data-cy='rc-department-vendor'
      data-testid='rc-department-vendor'
    >
      {subsidiaries &&
        Object.entries(subsidiaries).map(
          ([department_id, department]: any, department_index: any) => (
            <React.Fragment
              key={department_index}
              data-cy={`rc-department-row${department_index}`}
              data-testid={`rc-department-row${department_index}`}
            >
              {department &&
                department.vendor &&
                Object.entries(department.vendor).map(
                  (
                    [vendor_id, vendor]: any,
                    vendor_index: any,
                    all_vendors
                  ) => (
                    <tr
                      key={vendor_index}
                      data-cy={`rc-vendor-row${vendor_index}`}
                      data-testid={`rc-vendor-row${vendor_index}`}
                    >
                      {vendor_index === 0 && (
                        <td
                          className='border-l header-column sticky-col sticky-col-1'
                          rowSpan={all_vendors.length}
                          title={department.name}
                          data-cy={`rc-department-name-row${department_index}`}
                          data-testid={`rc-department-name-row${department_index}`}
                        >
                          {department.name || 'Blank'}
                          <br />
                          <span
                            className='text-color-purple'
                            data-cy={`rc-department-currency-row${department_index}`}
                            data-testid={`rc-department-currency-row${department_index}`}
                          >
                            {department.currency}
                          </span>
                        </td>
                      )}
                      <td
                        className='vendor-column sticky-col sticky-col-2'
                        data-cy={`rc-vendor-name-row${vendor_index}`}
                        data-testid={`rc-vendor-name-row${vendor_index}`}
                      >
                        {vendor.name || 'Blank'}
                      </td>
                      <Columns
                        values={vendor}
                        fields={fields}
                        subsidiary_id={0}
                        department_id={department_id}
                        glaccount_id={0}
                        vendor_id={vendor_id}
                        clickHandler={clickHandler}
                      ></Columns>
                    </tr>
                  )
                )}
              <SubTotal
                data-cy={`rc-department-subtotal${department_index}`}
                data-testid={`rc-department-subtotal${department_index}`}
                subtotalLabel={
                  config.show_coa_names_on_subtotals && department.name
                }
                values={department.vendor}
                fields={fields}
                tier={1}
                stickycol={1}
                colspan={2}
                border='border-lr border-t border-b'
                methodName='vendorSubTotal'
              ></SubTotal>
            </React.Fragment>
          )
        )}
    </React.Fragment>
  )
}

export default DepartmentVendor
