import React from 'react'
import SubTotal from './SubTotal'
import Columns from './Columns'

const SubsidiaryVendor: React.FC<any> = ({
  subsidiaries,
  fields,
  view,
  clickHandler,
  config
}) => {
  return (
    <React.Fragment
      data-cy='rc-subsidiary-vendor'
      data-testid='rc-subsidiary-vendor'
    >
      {subsidiaries &&
        Object.entries(subsidiaries).map(
          ([subsidiary_id, subsidiary]: any, subsidiary_index: any) => (
            <React.Fragment
              key={subsidiary_index}
              data-cy={`rc-subsidiary-row${subsidiary_index}`}
              data-testid={`rc-subsidiary-row${subsidiary_index}`}
            >
              {subsidiary &&
                subsidiary.vendor &&
                Object.entries(subsidiary.vendor).map(
                  (
                    [vendor_id, vendor]: any,
                    vendor_index: any,
                    all_vendors
                  ) => (
                    <tr
                      key={vendor_index}
                      data-cy={`rc-vendor-row${vendor_index}`}
                      data-testid={`rc-vendor-row${vendor_index}`}
                    >
                      {vendor_index === 0 && (
                        <td
                          className='border-l header-column sticky-col sticky-col-1'
                          rowSpan={all_vendors.length}
                          title={subsidiary.name}
                          data-cy={`rc-subsidiary-name-row${subsidiary_index}`}
                          data-testid={`rc-subsidiary-name-row${subsidiary_index}`}
                        >
                          {subsidiary.name || 'Blank'}
                          <br />
                          <span
                            className='text-color-purple'
                            data-cy={`rc-subsidiary-currency-row${subsidiary_index}`}
                            data-testid={`rc-subsidiary-currency-row${subsidiary_index}`}
                          >
                            {subsidiary.currency}
                          </span>
                        </td>
                      )}
                      <td className='vendor-column sticky-col sticky-col-2'>
                        {vendor.name || 'Blank'}
                      </td>
                      <Columns
                        values={vendor}
                        fields={fields}
                        subsidiary_id={subsidiary_id}
                        department_id={0}
                        glaccount_id={0}
                        vendor_id={vendor_id}
                        clickHandler={clickHandler}
                      ></Columns>
                    </tr>
                  )
                )}
              <SubTotal
                data-cy={`rc-subsidiary-subtotal${subsidiary_index}`}
                data-testid={`rc-subsidiary-subtotal${subsidiary_index}`}
                subtotalLabel={
                  config.show_coa_names_on_subtotals && subsidiary.name
                }
                values={subsidiary.vendor}
                fields={fields}
                tier={1}
                stickycol={1}
                colspan={2}
                border='border-lr border-t border-b'
                methodName='vendorSubTotal'
              ></SubTotal>
            </React.Fragment>
          )
        )}
    </React.Fragment>
  )
}

export default SubsidiaryVendor
