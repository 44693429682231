import _ from 'underscore'

class TxnLineStatusEnum {
  id: number
  label: string

  constructor(id: number, label: string) {
    this.id = id
    this.label = label
  }
}

const toReturn = {
  VOIDED: new TxnLineStatusEnum(-1, 'Voided'),
  DELETED: new TxnLineStatusEnum(0, 'Deleted'),
  SENT: new TxnLineStatusEnum(1, 'Sent'),
  DRAFT: new TxnLineStatusEnum(2, 'Draft'),
  SUBMITTED: new TxnLineStatusEnum(3, 'Submitted'),
  PROCESSING: new TxnLineStatusEnum(4, 'Processing'),
  QJE_CREATED: new TxnLineStatusEnum(6, 'QJE Created'),
  INCOMPLETE: new TxnLineStatusEnum(7, 'Incomplete'),
  QJE_REJECTED: new TxnLineStatusEnum(8, 'QJE Rejected'),
  QJE_POSTED: new TxnLineStatusEnum(9, 'QJE Posted'),
  QJE_ERROR: new TxnLineStatusEnum(10, 'QJE Error'),
  NO_QJE: new TxnLineStatusEnum(11, 'No QJE')
}

const methods = {
  getLabelById: (id: number) => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.id === id) {
        return true
      }
      return false
    })
    return found ? found.label : ''
  },
  getIdValueList: () => {
    let keyList: any = []
    Object.values(toReturn).forEach((o) => {
      keyList.push({ id: o.id, value: o.label })
    })
    return keyList
  }
}

export default Object.freeze({ ...toReturn, ...methods })
