// export default function capitalize<T,>(s: T) {
//     if (typeof s !== 'string') return ''
//     return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
// }

export default function capitalize<T>(s: T) {
  if (typeof s !== 'string') return ''
  return s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
