import MsUrl from '../../../types/MsUrl'

const Qa: MsUrl = {
  idms: 'https://idms.qa.gappifyinc.com',
  txn_manager: 'https://tm.qa.gappifyinc.com',
  review_center: 'https://rc.qa.gappifyinc.com/',
  qje_manager: 'https://qje.qa.gappifyinc.com',
  taskmanager: 'https://tsm.qa.gappifyinc.com',
  history_logs: 'https://history.qa.gappifyinc.com',
  analytics: 'https://advanced-analytics.qa.gappifyinc.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.qa.gappifyinc.com',
  schedule_manager_env: 'qa',
  search: 'https://search.qa.gappifyinc.com',
  period_manager: 'https://period-manager.qa.gappifyinc.com',
  settings: 'https://settings.qa.gappifyinc.com',
  gappify_api: 'https://api.qa.gappifyinc.com'
} as const

export default Qa
