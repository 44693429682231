// ** Third Party Imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'

// ** API Services Imports
import useScheduleManagerService from '../services/ScheduleManagerService'

// ** Type Imports
import MonthlyDataType from '../types/MonthlyDataType'
import StandardDataType from '../types/StandardDataType'

// ** Zustand Imports
import useScheduleStore from '../manager/schedule-store'
import { message } from 'antd'

// =================================================================
export default function useScheduleManager() {
  // ** API Services **
  const { ScheduleManagerAPI } = useScheduleManagerService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** TanStack Query **
  const queryClient = useQueryClient()

  // ** Local Storage **
  const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  // ** Store **
  const {
    callRunNow,
    taskScheduleId,
    period,
    callReset,
    setOpenModal,
    setEditMonthly,
    setMonthlyEditPayload,
    setConfirmLoading,
    setErrorOnMonthlyUpdate
  } = useScheduleStore()

  // ** GET METHODS **
  const getScheduleManagerMonthly = useQuery({
    queryKey: ['schedule-manager-monthly', period?.key],
    queryFn: () =>
      ScheduleManagerAPI({
        params: {
          customerId: user.company_id,
          envName: msUrl.schedule_manager_env,
          period: `${period?.key}`
        },
        options: {
          baseURL: msUrl.schedule_manager,
          endpoint: '/schedule-manager',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      })
  })

  const getScheduleManagerStandard = useQuery({
    queryKey: ['schedule-manager-standard'],
    queryFn: () =>
      ScheduleManagerAPI({
        params: {
          customerId: user.company_id,
          envName: msUrl.schedule_manager_env
        },
        options: {
          baseURL: msUrl.schedule_manager,
          endpoint: '/schedule-manager/standard-schedule',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      })
  })

  const getRunNow = useQuery({
    queryKey: ['schedule-manager-run-now', taskScheduleId],
    queryFn: () =>
      ScheduleManagerAPI({
        options: {
          baseURL: msUrl.schedule_manager,
          endpoint: `/schedule-manager/runNow/${taskScheduleId}`,
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: callRunNow
  })

  const getResetSchedule = useQuery({
    queryKey: ['schedule-manager-reset-schedule', taskScheduleId],
    queryFn: () =>
      ScheduleManagerAPI({
        options: {
          baseURL: msUrl.schedule_manager,
          endpoint: `/schedule-manager/resetSchedule/${taskScheduleId}`,
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: callReset
  })

  // ** POST METHODS **
  const { mutate: postScheduleManager, isLoading: postIsLoading } = useMutation(
    ScheduleManagerAPI,
    {
      onSuccess: (response) => {
        console.log(`SUCCESS POST METHOD@@@`, response)
        queryClient.invalidateQueries(['schedule-manager-monthly', period?.key])
        queryClient.invalidateQueries(['schedule-manager-standard'])

        // ** after succesfull api call **
        setOpenModal(false)
        setEditMonthly(false)
        setMonthlyEditPayload([])
        setErrorOnMonthlyUpdate([])

        // ** Commenting out, these are used for the inline editing
        // setMonthlyEditPayload(
        //   monthlyEditPayload.filter((entry) => {
        //     return entry.taskScheduleId !== lineIdToBeSaved
        //   })
        // )
        // removeFromEditLines(lineIdToBeSaved!)
        // setLineIdToBeSaved('')

        message.success('All changes have been applied.')
        setConfirmLoading(false)
      },
      onError: ({ data }) => {
        // ** Commenting out, this is used for batch update
        // Modal.error({
        //   title: 'Cannot save changes',
        //   width: 600,
        //   content: (
        //     <List
        //       itemLayout='horizontal'
        //       dataSource={data}
        //       renderItem={(
        //         item: Record<string, string | number>,
        //         index: number
        //       ) => (
        //         <List.Item>
        //           <div style={{ display: 'flex', gap: 5 }}>
        //             <Typography.Text type='secondary'>
        //               [
        //               {getObjectPlacementByProperty(
        //                 rowsFromBackend,
        //                 'taskScheduleId',
        //                 item.taskScheduleId
        //               )}{' '}
        //               Row]
        //             </Typography.Text>
        //             {item.message}
        //           </div>
        //         </List.Item>
        //       )}
        //     />
        //   )
        // })
        message.error('Cannot save changes.')
        setErrorOnMonthlyUpdate(data)
        setConfirmLoading(false)
        setOpenModal(false)
      }
    }
  )

  const handleSubmit = (
    schedules: (StandardDataType | MonthlyDataType)[],
    endpoint: string
  ) => {
    postScheduleManager({
      data: schedules,
      options: {
        baseURL: msUrl.schedule_manager,
        endpoint: endpoint,
        method: 'POST',
        customHeaders: {
          'User-Token': `${localStorage?.getItem('authToken')}`
        }
      }
    })
  }

  return {
    getScheduleManagerMonthly,
    getScheduleManagerStandard,
    getRunNow,
    handleSubmit,
    postIsLoading,
    getResetSchedule
  }
}
