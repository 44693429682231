import { FC } from 'react'
import { Dropdown, Menu } from 'antd'
import GDropdownBtnProps from '../../types/GDropdownBtnProps'
import GButton from './GButton'

const GDropdownBtn: FC<GDropdownBtnProps> = ({
  align,
  arrow,
  autoFocus,
  className,
  disabled,
  destroyPopupOnHide,
  forceRender,
  getPopupContainer,
  mouseEnterDelay,
  mouseLeaveDelay,
  onVisibleChange,
  openClassName,
  overlayClassName,
  overlayStyle,
  placement,
  prefixCls,
  transitionName,
  trigger,
  visible,
  menuItems,
  btnText,
  btnIcon,
  onClick,
  children,
  onSelect,
  defaultSelectedKeys,
  btnClassName,
  menuClassName,
  loading,
  dataTestId,
  dataCy
}: GDropdownBtnProps): JSX.Element => {
  return (
    <Dropdown
      align={align}
      arrow={arrow}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      destroyPopupOnHide={destroyPopupOnHide}
      forceRender={forceRender}
      getPopupContainer={getPopupContainer}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      onOpenChange={onVisibleChange}
      openClassName={openClassName}
      overlay={
        <Menu
          items={menuItems}
          onClick={onClick}
          onSelect={onSelect}
          defaultSelectedKeys={defaultSelectedKeys}
          className={menuClassName}
        />
      }
      overlayClassName={overlayClassName}
      overlayStyle={overlayStyle}
      placement={placement}
      prefixCls={prefixCls}
      transitionName={transitionName}
      trigger={trigger}
      open={visible}
    >
      <GButton
        dataTestId={dataTestId}
        dataCy={dataCy}
        btnText={btnText}
        icon={btnIcon}
        children={children}
        className={btnClassName}
        loading={loading}
      />
    </Dropdown>
  )
}

GDropdownBtn.defaultProps = {
  arrow: false,
  autoFocus: false,
  destroyPopupOnHide: false,
  getPopupContainer: () => document.body,
  placement: 'bottomLeft',
  trigger: ['click']
}

export default GDropdownBtn
