import { FC, useEffect } from 'react'
import GButton from './GButton'
import GDropdownBtn from './GDropdownBtn'
import { Row, Col } from 'antd'
import {
  PlusOutlined,
  ExportOutlined,
  DownOutlined,
  LockOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import { getAllAttachments, getAttachments } from '../../services/TxnManagerAPI'
import download from 'downloadjs'

import type { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import type { AxiosError } from 'axios'
import type GTransactionLinesActionsProps from '../../types/GTransactionLinesActionsProps'
import useRolesAndPermissions from '../../hooks/useRolesAndPermissions'

const GTransactionLinesActions: FC<GTransactionLinesActionsProps> = ({
  appType,
  formName,
  txnNumber,
  transactionId,
  hasAttachments,
  isNudgeAllEnable,
  isNudgeSelectedEnable,
  isAddMoreEnable,
  isAddLineEnable,
  isCreateQjeEnable,
  onNudgeAllClick,
  onExportClick,
  onAddMoreClick,
  onNudgeSelectedClick,
  onCreatQjeClick,
  setAttachmentsList,
  isExportLoading
}: GTransactionLinesActionsProps): JSX.Element => {
  const RP = useRolesAndPermissions()
  const getExportMenu = (): MenuItemType[] => {
    const menu: MenuItemType[] = [
      {
        label: 'CSV',
        key: 'csv'
      },
      {
        label: 'PDF',
        key: 'pdf'
      }
    ]

    return menu
  }

  const isVdacOrOpen = (): boolean => {
    if (txnNumber) {
      return txnNumber.includes('VDAC') || txnNumber.includes('OPEN')
    }
    return false
  }

  const downloadAttachments = async () => {
    try {
      const { data, headers } = await getAllAttachments(transactionId!)
      const content = headers['content-type']
      download(data, `${txnNumber}.zip`, content)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    }
  }

  useEffect(() => {
    const getAttachmentsList = async () => {
      try {
        const response = await getAttachments(transactionId!)
        setAttachmentsList && setAttachmentsList(response.data)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      }
    }
    getAttachmentsList()
  }, [setAttachmentsList, transactionId])

  return (
    <div
      className='txn-panel-container'
      data-testid='txn-line-actions'
      data-cy='txn-line-actions'
    >
      <Row>
        <Col lg={3} className='txn-panel-left'></Col>
        <Col sm={24} lg={18} className='txn-panel-center'>
          <div className='txn-line-actions'>
            <span>Action options:</span>
            <GButton
              dataTestId='txn-line-actions-nudge-all-btn'
              dataCy='txn-line-actions-nudge-all-btn'
              shape='default'
              className='g-btn-default'
              btnText='Nudge all'
              disabled={!RP.USER_HAS_NUDGE_ALL_PERMISSION || !isNudgeAllEnable}
              onClick={onNudgeAllClick}
            />
            <GButton
              dataTestId='txn-line-actions-nudge-selected-btn'
              dataCy='txn-line-actions-nudge-selected-btn'
              shape='default'
              className='g-btn-default'
              btnText='Nudge selected'
              disabled={
                !RP.USER_HAS_NUDGE_SELECTED_PERMISSION || !isNudgeSelectedEnable
              }
              onClick={onNudgeSelectedClick}
            />
            {appType === 'VDAC' || appType === 'OPEN' ? (
              <GButton
                dataTestId='txn-line-actions-add-more-btn'
                dataCy='txn-more-actions-add-more-btn'
                shape='default'
                className='g-btn-default'
                btnText='Add more'
                icon={<PlusOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
                disabled={!RP.USER_HAS_ADD_LINE_PERMISSION || !isAddMoreEnable}
                onClick={onAddMoreClick}
              />
            ) : (
              <GButton
                dataTestId='txn-line-actions-add-line-btn'
                dataCy='txn-line-actions-add-line-btn'
                shape='default'
                className='g-btn-default'
                btnText='Add line'
                icon={<PlusOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
                disabled={!RP.USER_HAS_ADD_LINE_PERMISSION || !isAddLineEnable}
                onClick={onAddMoreClick}
              />
            )}
            <GButton
              dataTestId='txn-line-actions-download-attachments-btn'
              dataCy='txn-line-actions-download-attachments-btn'
              shape='default'
              className='g-btn-default'
              btnText='Download all attachments'
              icon={<DownloadOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
              onClick={downloadAttachments}
              disabled={
                !RP.USER_HAS_DOWNLOAD_ATTACHMENTS_PERMISSION ||
                !hasAttachments ||
                !isVdacOrOpen()
              }
            />
            <GButton
              dataTestId='txn-line-actions-create-qje-btn'
              dataCy='txn-line-actions-create-qje-btn'
              shape='default'
              className='g-btn-default'
              btnText='Create QJE'
              icon={<LockOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
              onClick={onCreatQjeClick}
              disabled={
                !RP.USER_HAS_CREATE_QJE_PERMISSION || !isCreateQjeEnable
              }
            />
          </div>
        </Col>
        <Col sm={24} lg={3} className='txn-panel-right'>
          <GDropdownBtn
            dataTestId='txn-line-actions-export-btn'
            dataCy='txn-line-actions-export-btn'
            className='g-btn-default'
            menuItems={getExportMenu()}
            btnText='Export to'
            btnIcon={<ExportOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            disabled={!RP.USER_HAS_EXPORT_TXN_LINES_PERMISSION}
            onClick={onExportClick}
            loading={isExportLoading}
          />
        </Col>
      </Row>
    </div>
  )
}

GTransactionLinesActions.defaultProps = {
  hasAttachments: false,
  isNudgeAllEnable: false,
  isNudgeSelectedEnable: false,
  isAddMoreEnable: false,
  isAddLineEnable: false
}

export default GTransactionLinesActions
