/** services imports */
import useApiService from '../../../../../hooks/useApiService'

/** types imports */
import CreateApiTokenPayload from '../types/CreateApiTokenPayload'
import SortOrder from '../types/SortOrder'

interface BaseProps<TParams, TData, TOptions> {
  params?: TParams
  data?: TData
  options: TOptions
}

interface ApiKeyParams {
  per_page?: number
  sort?: SortOrder
  page?: number
}

interface ApiKeyData extends CreateApiTokenPayload {}

interface ApiKeyOptions {
  baseURL: string | ''
  endpoint: string | ''
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  withCredentials?: boolean
  customHeaders?: Record<string, string>
}

// =================================================================
export default function useApiKeyService() {
  const { apiRequest } = useApiService()

  const apiKeyManagerAPI = (
    props: BaseProps<ApiKeyParams, ApiKeyData, ApiKeyOptions>
  ) =>
    apiRequest(
      props.options.baseURL,
      props.options.endpoint,
      props.options.method,
      props.options.customHeaders,
      props.params,
      props.data,
      props.options.withCredentials
    )

  return { apiKeyManagerAPI }
}
