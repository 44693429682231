import { FC, useState } from 'react'
import { Modal, Button, TabsProps, Tabs } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import TransactionLines from '../../../pages/TRDTransactionLines'
import { useGlobalStore } from '../../../global/global-store'
import ReviewCenterHelper from '../../../helpers/review-center-helper'
import useTxnEnableFeatureStore from '../../../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

const ImportedAccrualModal: FC<any> = ({
  showModal,
  data,
  onCancel,
  vendorName,
  isReportingCurrency,
  currencyName
}): JSX.Element => {
  const { consolidatedTxnView } = useTxnEnableFeatureStore()
  const [size] = useState<SizeType>('large')

  const { display, setActiveKey, activeKey, setModalDelay } = useGlobalStore()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Details`,
      children: <TransactionLines />
    },
    {
      key: '2',
      label: `History`,
      children: display
    }
  ]

  const items_consolidated: TabsProps['items'] = [
    {
      key: '1',
      label: `Details`,
      children: (
        <TransactionLines
          hasTransactionPanel={false}
          hasSelectAll={false}
          isExpandable={false}
          hasNetExpenseAmount
        />
      )
    },
    {
      key: '2',
      label: `History`,
      children: display
    }
  ]

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    setModalDelay(true)
  }

  const transactionSubTotal = (key: string) => {
    let sum = data.data.reduce(
      (total: number, row: object) =>
        total + +ReviewCenterHelper.getDefaultValue(row, key),
      0
    )
    return ReviewCenterHelper.formatCurrency(sum)
  }

  const checkPermissions = localStorage.getItem('quickActions')

  return (
    <Modal
      data-cy='rc-imported-accrual-modal'
      data-testid='rc-imported-accrual-modal'
      title={
        <div
          data-cy='rc-imported-accrual-modal-title'
          data-testid='rc-imported-accrual-modal-title'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '-10px'
          }}
        >
          <h2>{vendorName}</h2>
        </div>
      }
      open={showModal}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{ height: 'auto' }}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right'
          }}
          data-cy='rc-imported-accrual-modal-footer'
          data-testid='rc-imported-accrual-modal-footer'
        >
          <Button
            data-cy='rc-imported-accrual-modal-close-btn'
            data-testid='rc-imported-accrual-modal-close-btn'
            shape='round'
            key='Close'
            type='primary'
            onClick={onCancel}
            size={size}
          >
            &nbsp; &nbsp; Close &nbsp; &nbsp;
          </Button>
        </div>
      }
    >
      {checkPermissions === 'true' ? (
        <Tabs
          activeKey={activeKey}
          items={consolidatedTxnView ? items_consolidated : items}
          onChange={handleTabChange}
        />
      ) : (
        checkPermissions === 'false' && (
          <div id='popup-table-wrapper' className='g-table-ui'>
            <table
              className='w-full text-left'
              data-cy='rc-imported-accrual-modal-table'
              data-testid='rc-imported-accrual-modal-table'
            >
              <thead>
                <tr className='title-bg-color border-bt sticky-row sticky-row-1'>
                  <th
                    className='border-l'
                    data-cy='rc-imported-accrual-modal-imported-from-header'
                    data-testid='rc-imported-accrual-modal-imported-from-header'
                  >
                    Imported From
                  </th>
                  <th
                    data-cy='rc-imported-accrual-modal-date-created-header'
                    data-testid='rc-imported-accrual-modal-date-created-header'
                  >
                    Date Created
                  </th>
                  <th
                    data-cy='rc-imported-accrual-modal-currency-header'
                    data-testid='rc-imported-accrual-modal-currency-header'
                  >
                    Currency
                  </th>
                  <th
                    data-cy='rc-imported-accrual-modal-imported-accrual-header'
                    data-testid='rc-imported-accrual-modal-imported-accrual-header'
                  >
                    Imported Accrual
                  </th>
                  <th
                    className='border-r'
                    data-cy='rc-imported-accrual-modal-description-header'
                    data-testid='rc-imported-accrual-modal-description-header'
                  >
                    Description
                  </th>
                </tr>
              </thead>
              {Object.keys(data.data).length > 0 ? (
                <tbody>
                  <>
                    {data &&
                      data.data &&
                      Object.entries(data.data).map(
                        ([id, transaction]: any, index: any) => (
                          <tr key={index}>
                            <td
                              className='border-l'
                              data-cy={`rc-imported-accrual-modal-td-imported-from${index}`}
                              data-testid={`rc-imported-accrual-modal-td-imported-from${index}`}
                            >
                              {transaction.g_imported_from || ''}
                            </td>
                            <td
                              data-cy={`rc-imported-accrual-modal-td-date${index}`}
                              data-testid={`rc-imported-accrual-modal-td-date${index}`}
                            >
                              {transaction.g_date}
                            </td>
                            <td
                              data-cy={`rc-imported-accrual-modal-td-subsidiary-currency${index}`}
                              data-testid={`rc-imported-accrual-modal-td-subsidiary-currency${index}`}
                            >
                              {transaction.g_subsidiary_currency}
                            </td>
                            <td
                              className='text-right'
                              data-cy={`rc-imported-accrual-modal-td-subsidiary-amount${index}`}
                              data-testid={`rc-imported-accrual-modal-td-subsidiary-amount${index}`}
                            >
                              {ReviewCenterHelper.formatCurrency(
                                transaction.g_subsidiary_amount
                              )}
                            </td>
                            <td
                              className='border-r'
                              data-cy={`rc-imported-accrual-modal-td-accrual-description${index}`}
                              data-testid={`rc-imported-accrual-modal-td-accrual-description${index}`}
                            >
                              {transaction.g_accrual_description}
                            </td>
                          </tr>
                        )
                      )}
                  </>
                  <tr className='sub-total-tier-2 font-semibold border-bt'>
                    <td
                      className='border-l text-right'
                      colSpan={2}
                      data-cy={`rc-imported-accrual-modal-td-total`}
                      data-testid={`rc-imported-accrual-modal-td-total`}
                    >
                      Total
                    </td>
                    <td
                      data-cy={`rc-imported-accrual-modal-td-subsidiary-currency`}
                      data-testid={`rc-imported-accrual-modal-td-subsidiary-currency`}
                    >
                      {data.data[0].g_subsidiary_currency}
                    </td>
                    <td
                      className='text-right'
                      data-cy={`rc-imported-accrual-modal-td-subsidiary-amount`}
                      data-testid={`rc-imported-accrual-modal-td-subsidiary-amount`}
                    >
                      {transactionSubTotal('g_subsidiary_amount')}
                    </td>
                    <td className='border-r'></td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      className='border-lr border-b'
                      colSpan={5}
                      data-cy={`rc-imported-accrual-modal-no-transactions-available`}
                      data-testid={`rc-imported-accrual-modal-no-transactions-available`}
                    >
                      No transactions available
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        )
      )}
    </Modal>
  )
}
export default ImportedAccrualModal
