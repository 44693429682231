import MsUrl from '../../../types/MsUrl'

const Uat: MsUrl = {
  idms: 'https://idms.uat.gappifyinc.com',
  txn_manager: 'https://tm.uat.gappifyinc.com',
  review_center: 'https://rc.uat.gappifyinc.com/',
  qje_manager: 'https://qje.uat.gappifyinc.com',
  taskmanager: 'https://tsm.uat.gappifyinc.com',
  history_logs: 'https://history.uat.gappifyinc.com',
  analytics: 'https://advanced-analytics.uat.gappifyinc.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.uat.gappifyinc.com',
  schedule_manager_env: 'uat',
  search: 'https://search.uat.gappifyinc.com',
  period_manager: 'https://period-manager.uat.gappifyinc.com',
  settings: 'https://settings.uat.gappifyinc.com',
  gappify_api: 'https://internal-api.uat.gappifyinc.com'
} as const

export default Uat
