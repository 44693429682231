// ** React Imports
import { useNavigate } from 'react-router-dom'

// ** Zustand Imports
import useAnalyticsStore from '../manager/analytics-store'

// ** Type/Enum Imports
import { EReportTypes } from '../constants/Globals'

// =================================================================
export default function useCollapsible(paramName?: string) {
  // ** Router **
  const navigate = useNavigate()

  // ** Zustand **
  const {
    collapsed,
    setCollapsed,
    setHeader,
    setTitle,
    setSelectedItem,
    setHovered,
    setResponseType,
    setCostCuttingTabView,
    setSelectedRows,
    setNonCompliantSelectedRowKeys,
    setIncreasingSpendSelectedRowKeys,
    setReportType
  } = useAnalyticsStore()

  // ** Handle Collapsible **
  const handleToggle = () => {
    setCollapsed(!collapsed)
  }

  // ** Display data function **
  const handleClick = (item: string, header: string) => {
    setHeader(header)
    setTitle(item)
    setSelectedItem(item)

    setNonCompliantSelectedRowKeys([]) // Ensures that the execution action button is disabled when switching between tabs
    setIncreasingSpendSelectedRowKeys([]) // Ensures that the execution action button is disabled when switching between tabs
    setSelectedRows([]) // Ensures that the selected rows are cleared(Email Vendor button is disabled) when switching between tabs

    const newSearchParams = new URLSearchParams()
    newSearchParams.set(paramName as string, item)

    if (paramName === 'cost-cutting') {
      newSearchParams.set('view', 'department')
      setResponseType(undefined)
    } else {
      setResponseType(item.toLowerCase())
      setCostCuttingTabView('')

      if (item.toLowerCase() === 'internal') {
        setReportType(EReportTypes.ResponseRatesInternal)
      } else if (item.toLowerCase() === 'vendor') {
        setReportType(EReportTypes.ResponseRatesVendor)
      }
    }

    navigate(`.?${newSearchParams.toString()}`, { replace: true })
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  return {
    handleToggle,
    handleClick,
    handleMouseEnter,
    handleMouseLeave
  }
}
