import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SetStateAction } from 'react'
import AddLineScreenManagerColumn from '../types/AddLineScreenManagerColumn'

export default function handleOnChangeCheckbox(
  recordId: number,
  formProperty: 'show' | 'read_only' | 'required',
  dataSource: AddLineScreenManagerColumn[],
  setDataSource: (value: SetStateAction<AddLineScreenManagerColumn[]>) => void
) {
  return (e: CheckboxChangeEvent): void => {
    const rows = dataSource.map((item) => {
      if (item.id === recordId) {
        item.form_properties[formProperty] = !item.form_properties[formProperty]
        if (formProperty === 'required' && item.form_properties.required) {
          item.form_properties.show = true
          item.form_properties.read_only = false
        }
      }
      return item
    })
    setDataSource(rows)
  }
}
