const listInfo = [
  {
    title: 'Cost Monitoring',
    data: ['PO Compliance', 'Rising Expenses']
  },
  {
    title: 'Confirmation Rates',
    data: ['Internal', 'Vendor']
  }
]

export default listInfo
