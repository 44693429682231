import { axiosReviewCenterInstance } from '../helpers/axios'
import RCTransactionDataApiParams from '../types/RCTransactionDataApiParams'
import RCAccrualDataApiParams from '../types/RCAccrualDataApiParams'
import RCFilterApiParams from '../types/RCFilterApiParams'

export const getConfig = () => {
  return axiosReviewCenterInstance
    .get('/api/review-center', {})
    .catch((err) => {
      console.log(err)
    })
}

export const getDashboardData = (
  view: string,
  period: string,
  filter?: string,
  previewMode?: string
) => {
  return axiosReviewCenterInstance
    .get(
      `/api/review-center/data/${view}/${period}?filter=${filter || 'all'
      }&preview_mode=${previewMode || 'false'}`,
      {}
    )
    .catch((err) => {
      console.log(err)
    })
}

export const getTransactionData = (params: RCTransactionDataApiParams) => {
  return axiosReviewCenterInstance
    .get('/api/review-center/transaction-data', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getCalculatedAccrualData = (params: RCAccrualDataApiParams) => {
  return axiosReviewCenterInstance
    .get('/api/review-center/calculated-accrual-data', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getImportedAccrualData = (params: RCAccrualDataApiParams) => {
  return axiosReviewCenterInstance
    .get('/api/review-center/imported-accrual-data', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getFilterById = (filterId: string) => {
  return axiosReviewCenterInstance
    .get('/api/review-center/filters/' + filterId, {})
    .catch((err) => {
      console.log(err)
    })
}

export const createFilter = (params: RCFilterApiParams) => {
  return axiosReviewCenterInstance
    .post('/api/review-center/filters', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const getFilterFieldOptions = () => {
  return axiosReviewCenterInstance.get('/api/review-center/filters/prebuild')
}

export const postFilter = (params: any) => {
  return axiosReviewCenterInstance.post('/api/review-center/filters', {
    ...params
  })
}

export const previewFilter = (params: any) => {
  return axiosReviewCenterInstance.post('/api/review-center/preview-filters', {
    ...params
  })
}

export const getFilters = (id: any) => {
  return axiosReviewCenterInstance.get(`api/review-center/filters/${id}`)
}

export const editFilter = (id: any, params: any) => {
  return axiosReviewCenterInstance.put(`/api/review-center/filters/${id}`, {
    ...params
  })
}

export const deleteFilter = (id: any) => {
  return axiosReviewCenterInstance.delete(`/api/review-center/filters/${id}`)
}
