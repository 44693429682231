// ** AntD Imports
import { Tabs } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** react router imports */
import { useNavigate } from 'react-router-dom'

/** zustand imports */
import useScheduleStore from './manager/schedule-store'

// ** Custom Component Imports
import TabItems from './components/TabItems'

// ** React Query imports
import { useQueryClient } from '@tanstack/react-query'
import useScheduleManager from './hooks/useScheduleManager'

// ** hooks imports
import useRolesAndPermissions from '../../../../hooks/useRolesAndPermissions'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'

// =================================================================
const ScheduleManager = () => {
  /** react router */
  const navigate = useNavigate()

  /** zustand state */
  const {
    editMonthly,
    editStandard,
    setMonthlyEditPayload,
    setStandardEditPayload,
    taskScheduleId,
    removeFromLoadingButtons,
    setEditMonthly,
    setEditStandard
  } = useScheduleStore()

  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags()

  const queryClient = useQueryClient()
  const { getRunNow, getResetSchedule } = useScheduleManager()
  const { isSuccess, isLoading } = getRunNow
  const { isSuccess: isResetSuccess, isLoading: isResetLoading } =
    getResetSchedule

  const RP = useRolesAndPermissions()

  useEffect(() => {
    if (isFeatureFlagsLoading) return
    if (!RP.USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION) {
      // if feature flag is off OR user has no permission
      // redirect to error page
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      const currentPathname = window.location.pathname
      if (!currentPathname.includes('/settings/schedule-manager')) {
        setMonthlyEditPayload([])
        setStandardEditPayload([])
        setEditMonthly(false)
        setEditStandard(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const invalidateSchedManagerQueries = async () => {
      if (isSuccess && !isLoading) {
        await queryClient.invalidateQueries(['schedule-manager-monthly'])
        await queryClient.invalidateQueries(['schedule-manager-standard'])
        removeFromLoadingButtons(`run_now_${taskScheduleId}`)
      }
    }
    invalidateSchedManagerQueries()
  }, [
    isLoading,
    isSuccess,
    queryClient,
    removeFromLoadingButtons,
    taskScheduleId
  ])

  useEffect(() => {
    const invalidateSchedManagerQueries = async () => {
      if (isResetSuccess && !isResetLoading) {
        await queryClient.invalidateQueries(['schedule-manager-monthly'])
        await queryClient.invalidateQueries(['schedule-manager-standard'])
        removeFromLoadingButtons(`reset_${taskScheduleId}`)
      }
    }
    invalidateSchedManagerQueries()
  }, [
    isResetLoading,
    isResetSuccess,
    queryClient,
    removeFromLoadingButtons,
    taskScheduleId
  ])

  return (
    <Tabs
      defaultActiveKey='1'
      type='card'
      size='small'
      items={TabItems.map((tabItem) => {
        return { ...tabItem, disabled: editMonthly || editStandard }
      })}
      onTabClick={(key: string) => {
        switch (key) {
          case '1': {
            setStandardEditPayload([])
            break
          }
          case '2': {
            setMonthlyEditPayload([])
            break
          }
          default:
            break
        }
      }}
    />
  )
}

export default ScheduleManager
