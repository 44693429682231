import ActionTypes from '../constants/action-types'
import type ErrorPayload from '../../types/ErrorPayload'

interface Params {
  type: string
  payload: ErrorPayload
}

const initialState: ErrorPayload = {
  visible: false,
  status: null,
  guid: '',
  message: ''
}

export default function errorReducer(state = initialState, params: Params) {
  switch (params.type) {
    case ActionTypes.SET_ERROR:
      return params.payload
    default:
      return state
  }
}
