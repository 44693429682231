import ActionTypes from '../constants/action-types'
import { getCurrentPeriod } from '../../utils/Date'

const initalState = {
  qjeData: [],
  unreviewedPOData: [],
  reviewedPOData: [],
  qjePreviewData: [],
  selectedPeriod: { period: getCurrentPeriod() },
  selectedFilter: { id: 'all', label: 'All' },
  selectedLineGroups: [],

  selectedForBulkUpdate: [],
  isOnEditReviewed: [],
  reviewedEditKey: ''
}

export const qjeReducer = (state = initalState, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.SET_QJE_DATA: {
      return { ...state, qjeData: payload }
    }

    case ActionTypes.SET_UNREVIEWED_PO_DATA: {
      return { ...state, unreviewedPOData: payload }
    }

    case ActionTypes.SET_REVIEWED_PO_DATA: {
      return { ...state, reviewedPOData: payload }
    }

    case ActionTypes.SET_QJE_PREVIEW_DATA: {
      return { ...state, qjePreviewData: payload }
    }

    case ActionTypes.SET_SELECTED_LINE_GROUPS:
      return { ...state, selectedLineGroups: payload }

    case ActionTypes.SET_SELECTED_PERIOD: {
      return { ...state, selectedPeriod: payload }
    }

    case ActionTypes.SET_SELECTED_FILTER: {
      return { ...state, selectedFilter: payload }
    }

    case ActionTypes.SET_SELECTED_FOR_BULK_UPDATE: {
      return { ...state, selectedForBulkUpdate: payload }
    }

    case ActionTypes.SET_IS_ON_EDIT_REVIEWED: {
      return { ...state, isOnEditReviewed: payload }
    }

    case ActionTypes.SET_REVIEWED_EDIT_KEY: {
      return { ...state, reviewedEditKey: payload }
    }

    default:
      return state
  }
}
