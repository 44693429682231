import { useState, SetStateAction } from 'react'
import {
  getSourceCoaOptionsWithInactive,
  getSourceCoaOptionsWithInactiveDAC
} from '../../../../../services/TaskManagerApi'

export default function useGetSourceCoaOptions() {
  const [subsidiaryOptions, setSubsidiaryOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [glAccountOptions, setGlAccountOptions] = useState([])

  const getDataAccessOptions = async (
    field: string,
    setIsLoading: (value: SetStateAction<boolean>) => void,
    editRecordId?: number | undefined
  ) => {
    try {
      let response = editRecordId
        ? await getSourceCoaOptionsWithInactiveDAC(field)
        : await getSourceCoaOptionsWithInactive(field)

      if (response && response.data && response.data[field]) {
        if (field === 'Subsidiary') {
          setSubsidiaryOptions(response.data[field])
        } else if (field === 'Department') {
          setDepartmentOptions(response.data[field])
        } else if (field === 'GLAccount') {
          setGlAccountOptions(response.data[field])
        }
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    subsidiaryOptions,
    setSubsidiaryOptions,
    departmentOptions,
    setDepartmentOptions,
    glAccountOptions,
    setGlAccountOptions,
    getDataAccessOptions
  }
}
