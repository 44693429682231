// universal utils

export const getNumericTypes: () => string[] = () => {
  return [
    'bit',
    'tinyint',
    'smallint',
    'mediumint',
    'int',
    'integer',
    'bigint',
    'decimal',
    'dec',
    'float',
    'double'
  ]
}

export const getNonNumericFields = () => {
  return [
    'subsidiary',
    'department',
    'glaccount',
    'class',
    'project',
    'currency',
    'location',
    'region',
    'company',
    'intercompany',
    'channel',
    'product',
    'is_active',
    'task_inactive',
    'reviewed',
    'generatre_accruals',
    'task_type',
    'form_name',
    'external_accrual_form',
    'internal_accrual_form',
    'external_accrual',
    'internal_accrual',
    'calculation',
    'calculated_accrual_form',
    'accrual_type'
  ]
}
