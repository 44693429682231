import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import OktaError from './components/OktaError'
import getIdmsUrl from './utils/getIdmsUrl'

const LoginOkta: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const code: string | null = searchParams.get('code')
  const state: string | null = searchParams.get('state')
  const error: string | null = searchParams.get('error')
  const errorDescription: string | null = searchParams.get('error_description')

  const hostname: string = window.location.hostname

  const hasError = (): boolean => {
    if (error) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (code && state) {
      window.location.href = `${getIdmsUrl(
        hostname
      )}/login/okta/callback?code=${code}&state=${state}`
    }
  }, [code, hostname, state])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.href = '/login'
      }, 5000) // 5 seconds
    }
  }, [error])

  return (
    <>
      {hasError() && (
        <OktaError error={error} errorDescription={errorDescription} />
      )}
    </>
  )
}

export default LoginOkta
