import ReviewCenterQ3 from './ReviewCenter_q3'
// import ReviewCenter from './ReviewCenter'
// import Flag from '../features/Flag'
// import { FEATURE_FLAGS } from '../features/Flag/constants'

const ReviewCenterWrapper = () => {
  /**
   * TODO: Implement the following:
   * 1. Implement the feature flag + loading state
   */
  return (
    // <Flag
    //   authorizedFlags={[FEATURE_FLAGS.edinburgh]}
    //   renderOn={<ReviewCenterQ3 />}
    //   renderOff={<ReviewCenter />}
    // />
    <ReviewCenterQ3 />
  )
}

export default ReviewCenterWrapper
