// ** React Imports
import { useMemo } from 'react'

// ** AntD Imports
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig
} from 'antd/es/table/interface'

// ** Types Imports
import { TableRecord } from '../types/Table'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Reducer Imports
import { setPage, setSort } from '../redux/reducer'

// ** Store Imports
import { RootState } from '../../../../../context/store'

// =======================================================================================
export default function useTable() {
  // ** Redux **
  const dispatch = useDispatch()
  const { pageSize, totalItems, page } = useSelector(
    (state: RootState) => state.loginAuditTrail
  )

  // **  Handle Pagination and Sorting ===================================================
  const handleTableChange = <T,>(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: T
  ) => {
    dispatch(setPage(pagination.current))

    let sorterObject: SorterResult<TableRecord>
    // Check if sorter is an array and take first object.
    if (Array.isArray(sorter) && sorter.length > 0) {
      sorterObject = sorter[0]
    } else {
      sorterObject = sorter as SorterResult<TableRecord>
    }

    if (sorterObject.field === 'created_at' && sorterObject.order) {
      dispatch(setSort(sorterObject.order === 'ascend' ? 'asc' : 'desc'))
    }
  }

  // ** Table Page Configurations ========================================================
  const paginationConfig = useMemo(() => {
    return {
      pageSize: pageSize,
      total: totalItems,
      showSizeChanger: true,
      current: page,
      defaultCurrent: 1,
      pageSizeOptions: [100]
    }
  }, [pageSize, totalItems, page])

  return {
    handleTableChange,
    paginationConfig
  }
}
