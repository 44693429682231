import { FC, useState } from 'react'
import { Modal, Button } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import TransactionLines from '../../../pages/TRDTransactionLines'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useGlobalStore } from '../../../global/global-store'

const QuickActionsModal: FC<any> = ({
  showModal,
  data,
  onCancel,
  vendorName,
  isReportingCurrency,
  currencyName
}): JSX.Element => {
  const [size] = useState<SizeType>('large')
  const { activeKey, setActiveKey, display, setModalDelay, quickActionsType } =
    useGlobalStore()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Details`,
      children: (
        <TransactionLines
          actionGetPopupContainer={
            () => document.querySelector('.ant-modal-body') // Use the modal's body as the container
          }
          hasTransactionPanel={
            !['Reviewed', 'Posted'].includes(quickActionsType as string)
          }
          hasSelectAll={
            !['Reviewed', 'Posted'].includes(quickActionsType as string)
          }
          hasNetExpenseAmount
        />
      )
    },
    {
      key: '2',
      label: `History`,
      children: display
    }
  ]

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    setModalDelay(true)
  }

  return (
    <Modal
      data-cy='rc-quick-actions-modal'
      data-testid='rc-quick-actions-modal'
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '-10px'
          }}
          data-cy='rc-quick-actions-modal-title'
          data-testid='rc-quick-actions-modal-title'
        >
          <h2>{vendorName}</h2>
        </div>
      }
      open={showModal}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{ height: 'auto', padding: '0 20px', position: 'relative' }}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right'
          }}
        >
          <Button
            data-cy='rc-quick-actions-modal-close-btn'
            data-testid='rc-quick-actions-modal-close-btn'
            shape='round'
            key='Close'
            type='primary'
            onClick={onCancel}
            size={size}
          >
            &nbsp; &nbsp; Close &nbsp; &nbsp;
          </Button>
        </div>
      }
    >
      <Tabs activeKey={activeKey} items={items} onChange={handleTabChange} />
    </Modal>
  )
}
export default QuickActionsModal
