import { ColumnsType } from 'antd/es/table'
import RoleEnum from '../enums/RoleEnum'
import User from '../types/User'
import getUserDashboardActionItems from './getUserDashboardActionItems'
import moment from 'moment'
import { SetStateAction } from 'react'

export default function getTableColumns(
  setUserId: (value: SetStateAction<number | undefined>) => void,
  setUserEmail: (value: SetStateAction<string>) => void,
  setIsActivateModalVisible: (value: SetStateAction<boolean>) => void,
  setUserStatus: (
    value: SetStateAction<'Active' | 'Inactive' | 'Pending' | undefined>
  ) => void,
  setUserName: (value: SetStateAction<string>) => void,
  setIsUserModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResetModalVisible: (value: SetStateAction<boolean>) => void,
  setIsResendActionLinkModalVisible: (value: SetStateAction<boolean>) => void,
  setIsHistoryModalVisible: (value: SetStateAction<boolean>) => void
): ColumnsType<User> {
  return [
    {
      title: '',
      width: 30,
      render: (value, record) =>
        getUserDashboardActionItems(
          record,
          setUserId,
          setUserEmail,
          setIsActivateModalVisible,
          setUserStatus,
          setUserName,
          setIsUserModalVisible,
          setIsResetModalVisible,
          setIsResendActionLinkModalVisible,
          setIsHistoryModalVisible
        )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      showSorterTooltip: false,
      sorter: true
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Role/s',
      dataIndex: 'roles',
      width: 400,
      render: (value: number[]) => {
        return (
          <>
            {value &&
              value.length > 0 &&
              value.map((item, index, array) => {
                const role = RoleEnum.getLabelById(item)
                return index + 1 === array.length ? role : `${role}, `
              })}
          </>
        )
      }
    },
    {
      title: 'Data Access',
      dataIndex: 'data_access',
      width: 120,
      render: (value: string) => {
        return value
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (value: string) => {
        return value
      }
    },
    {
      title: 'Created Date (PST)',
      dataIndex: 'created_at',
      width: 170,
      render: (value: string) => {
        const date = moment(value)

        return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : false
      }
    },
    {
      title: 'Last Activity Date (PST)',
      dataIndex: 'last_activity_date',
      width: 170,
      render: (value: string) => {
        const date = moment(value)

        return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : false
      }
    }
  ]
}
