// ** AntD Imports
import { ColumnType } from 'antd/lib/table'
import { MenuOutlined } from '@ant-design/icons'

// ** Custom Component Imports
import Actions from '../components/Actions'

// ** Style Imports
import styles from '../styles/analytics-settings-table.module.css'

// ** Type Imports
import { IRules, IRulesCriteria } from '../types/table-record'

// ** Zustand Imports
import useAnalyticsSettingsStore from '../manager/analytics-settings-store'

// =================================================================
const getCriteria = (record: IRulesCriteria) => {
  const field = record.field
  const operator = record.operator

  // Format values object into array first, then join as string to display in the table
  const valuesArray = Object.entries(record.values).map(([index, value]) => {
    return value
  })
  const values = valuesArray.join(', ')

  return `${field} - ${operator} - ${values}`
}

const TableData = () => {
  const { showHeader } = useAnalyticsSettingsStore()

  const columns: ColumnType<IRules>[] = [
    {
      width: 30,
      className: showHeader ? '' : 'drag-visible',
      render: (record: IRules) =>
        showHeader ? (
          <Actions record={record} />
        ) : (
          <MenuOutlined
            onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
            className='drag-handle'
            style={{ cursor: 'grab', color: '#999' }}
          />
        )
    },
    {
      width: 250,
      title: 'Name',
      dataIndex: 'name'
    },
    {
      width: 100,
      title: 'Status',
      dataIndex: 'active',
      render: (record: string) => {
        return record ? 'Active' : 'Inactive'
      }
    },
    {
      className: styles.columnCriteria,
      title: 'Criteria',
      dataIndex: 'rules',
      render: getCriteria
    },
    {
      width: 200,
      title: 'Created Date',
      dataIndex: 'created_at'
    },
    {
      width: 200,
      title: 'Last Updated Date',
      dataIndex: 'updated_at'
    }
  ]

  return { columns }
}

export default TableData
