// ** React Imports
import React from 'react'

// ** TanStack React Query Imports
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

// ** hooks imports
import useHandleErrorResponse from '../hooks/useHandleErrorResponse'

// =================================================================
export default function QueryProvider({
  children
}: {
  children: React.ReactNode
}) {
  /** hooks */
  const handleErrorReponse = useHandleErrorResponse()

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // refetchOnWindowFocus: "always", // Set to false to disable
            refetchOnWindowFocus: false,
            retry: false
          }
        },
        queryCache: new QueryCache({
          onError: (err) => handleErrorReponse(err)
        })
      })
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
