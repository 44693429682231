import { useQuery } from '@tanstack/react-query'
import useApiService from 'src/hooks/useApiService'
import useIntegrationLogsService from '../services/useIntegrationLogsService'
import useLogsTableStore from '../stores/useLogsTableStore'

export default function useExportCsvLog() {
  const { exportLogsToCsvApi } = useIntegrationLogsService()
  const { msUrl } = useApiService()

  const sort = useLogsTableStore((state) => state.sort)
  const preview_mode = useLogsTableStore((state) => state.preview_mode)
  const custom_filter = useLogsTableStore((state) => state.custom_filter)

  return useQuery({
    queryKey: ['export-logs-to-csv', sort, preview_mode, custom_filter],
    queryFn: () =>
      exportLogsToCsvApi({
        options: {
          baseURL: msUrl.gappify_api,
          endpoint: '/api/logs/export',
          method: 'GET'
        },
        params: {
          ...sort,
          preview_mode: preview_mode ? 1 : 0,
          custom_filter
        }
      }),
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false, 
    staleTime: 0
  })
}
