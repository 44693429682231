// ** AntD Imports
import { Popover } from 'antd'
import { MoreOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'

// ** Import Types
import AccrualActionsType from '../types/AccrualActionsType'
import PopconfirmButton from './PopconfirmButton'

// ** Components Imports
import GButton from '../../../components/gappify/GButton'
import { useState } from 'react'
import SyncModal from './SyncModal'
import useAccrualManagerstore from '../manager/tasks-store'

// =================================================================
const AccrualActions = ({
  onClickSync,
  onClickCopy,
  onClickRemove,
  onClickHistory,
  disabledCopy,
  disabledDelete,
  historyModal,
  taskManagerColumns,
  taskId,
  isOutOfSync
}: AccrualActionsType) => {
  const [showPopover, setShowPopover] = useState(false)

  const { setTaskId } = useAccrualManagerstore()

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Popover
        placement='bottomLeft'
        trigger='click'
        zIndex={0}
        overlayClassName='action-pop'
        destroyTooltipOnHide={true}
        open={showPopover}
        onOpenChange={() => setShowPopover(!showPopover)}
        content={
          <>
            <SyncModal
              columns={taskManagerColumns}
              onClose={() => setShowPopover(false)}
              onClick={() => {
                setTaskId(taskId!)
                onClickSync && onClickSync()
              }}
              disabled={!isOutOfSync}
            />
            <PopconfirmButton
              onConfirm={() => {
                setShowPopover(false)
                onClickCopy && onClickCopy()
              }}
              onCancel={() => setShowPopover(false)}
              title={'Are you sure you want to copy row?'}
              icon={
                <CopyOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
              btnText={'Copy Line'}
              disabled={disabledCopy}
            />
            <GButton
              type='text'
              className='g-btn-icon action-btn'
              icon={historyModal(setShowPopover)}
              onClick={onClickHistory}
            />
            <PopconfirmButton
              onConfirm={() => {
                setShowPopover(false)
                onClickRemove && onClickRemove()
              }}
              onCancel={() => setShowPopover(false)}
              title={'Are you sure you want to delete?'}
              icon={
                <DeleteOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
              btnText={'Delete Line'}
              disabled={disabledDelete}
            />
          </>
        }
      >
        <MoreOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          data-cy={`task-manager-table-action-button`}
          data-testid={`task-manager-table-action-button`}
        />
      </Popover>
    </div>
  )
}

export default AccrualActions
