// ** Zustand Imports **
import { create } from 'zustand'

// ** Date Utility **
import moment from 'moment'
import {
  getCurrentPeriod,
  getCurrentPeriodFormatted
} from '../../../utils/Date'

// ** Type Imports **
import QjeProps from '../types/QjeProps'

// =================================================================
const useQjeStore = create<QjeProps>((set, get) => ({
  // Define initial state...
  // NOTE: some of these might be unused...
  test: true,
  selectedLineGroups: [],
  period: {
    key: moment(getCurrentPeriod()).startOf('month').format('YYYY-MM-DD'),
    label: getCurrentPeriodFormatted()
  },
  tab: 'review-je',
  reviewJeData: [],
  unreviewedPoClosureData: [],
  reviewedPoClosureData: [],

  filter: 'all',
  filter_fields: '',
  page: 1,
  previewMode: false,
  recordsPerPage: 500,
  sortField: 'created_at',
  sortDir: 'asc',
  reviewedEditLines: [],
  reviewedEditKey: null,

  // paginationPageSize: 500,
  totalDataSize: 0,
  exportFormat: undefined,
  searchTerm: '',
  focusedField: undefined,
  filterInfo: undefined,
  reviewJePreviewData: [],

  // Define actions...
  setSelectedLineGroups: (selectedLineGroups) => set({ selectedLineGroups }),
  setPeriod: (period) => set({ period }),
  setTab: (tab) => set({ tab }),
  setReviewJeData: (reviewJeData) => set({ reviewJeData }),
  setUnreviewedPoClosureData: (unreviewedPoClosureData) =>
    set({ unreviewedPoClosureData }),
  setReviewedPoClosureData: (reviewedPoClosureData) =>
    set({ reviewedPoClosureData }),
  setFilter: (filter) => set({ filter }),
  setFilterFields: (filter_fields) => set({ filter_fields }),
  setPage: (page) => set({ page }),
  setPreviewMode: (previewMode) => set({ previewMode }),
  setRecordsPerPage: (recordsPerPage) => set({ recordsPerPage }),
  setSortField: (sortField) => set({ sortField }),
  setSortDir: (sortDir) => set({ sortDir }),
  setReviewedEditLines: (reviewedEditLines) => set({ reviewedEditLines }),
  setReviewedEditKey: (reviewedEditKey) => set({ reviewedEditKey }),
  // setPaginationPageSize: (paginationPageSize) => set({ paginationPageSize }),
  setTotalDataSize: (totalDataSize) => set({ totalDataSize }),
  setExportFormat: (exportFormat) => set({ exportFormat }),
  setSearchTerm: (searchTerm) => set({ searchTerm }),
  setFocusedField: (focusedField) => set({ focusedField }),
  setFilterInfo: (filterInfo) => set({ filterInfo }),
  setReviewJePreviewData: (reviewJePreviewData) => set({ reviewJePreviewData }),

  // Functions...
  handleTablePagination: (page: number, recordsPerPage: number) =>
    set({ page, recordsPerPage }),
  handleTableSort: (sortField, sortDir) => set({ sortField, sortDir })
}))

export default useQjeStore
