// ** AntD Imports
import { Form, Input } from 'antd'

// ** Types Imports
import FormInputProps from '../types/input-form'

// =================================================================
const FormInput = ({
  wrapperCol,
  labelCol,
  label,
  name,
  required,
  message,
  className,
  isPassword,
  dependencies,
  isConfirmation,
  hasFeedback,
  customRequired
}: FormInputProps) => {
  return (
    <Form.Item
      wrapperCol={wrapperCol}
      labelCol={labelCol}
      label={label}
      name={name}
      required={customRequired}
      rules={[
        {
          required: required,
          message: message
        },
        {
          pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
          message:
            'Must be 8 characters, 1 uppercase and 1 lowercase letter, and 1 number'
        },
        isConfirmation
          ? ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match.')
                )
              }
            })
          : {}
      ]}
      className={className}
      dependencies={dependencies}
      hasFeedback={hasFeedback}
    >
      {isPassword ? <Input.Password /> : <Input />}
    </Form.Item>
  )
}

export default FormInput
